import {gql} from '@apollo/client';

export const CLIENTES_QUERY = gql`
    query clientes($sort:String ,$limit:Int,$start:Int,$where:JSON){
        clientes(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            correo
            empresa
            rfc
            tipo
            idbitrix
            color
            logo
            activo
            createdby{
                email
            }
            modifiedby{
                email
            }
            created_at
            updated_at
            user{
                id
                email
            }
        }
        clientesConnection(where:$where){
            aggregate{
                count
            }
        }
    }
`;

export const CREATE_CLIENTE = gql`
    mutation createCliente($input:createClienteInput){
        createCliente(input:$input){
            cliente{
                id
                nombre
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_CLIENTE = gql`
    mutation updateCliente($input: updateClienteInput){
        updateCliente(input:$input){
            cliente{
                id
                nombre
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                user
                {
                    id
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_CLIENTE = gql`
    mutation deleleteCliente($input: deleteClienteInput){
        deleteCliente(input:$input){
            cliente{
                id
                nombre
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const BUSCAR_USUARIO = gql`
    query ListaUsuario($where:JSON){
        users(where:$where){
            id
            username
            email 
            role{
                id
                name
            }
        }
    }
`;
