import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

/**
 * 
 * @param {Object} props
 * @param {string} props.name
 * @param {any} props.value
 * @param {any} props.style
 * @param {boolean} props.required
 * @param {number} props.rows
 * @param {number} props.tabIndex
 * @param {boolean} props.disabled
 * @param {(newValue: string) => void} props.onChange
 * @returns 
 */
const InputTextareaComponent = ({
    name,
    value,
    style,
    required = false,
    disabled = false,
    onChange = null,
    label,
    className = '', 
    rows = 0,
    tabIndex = 0,
}) => {
    return (
        <>
            {label ? (
                <div>
                    <span className="p-float-label">
                        <InputTextarea
                            rows={rows}
                            tabIndex={tabIndex}
                            required={required}
                            value={value}
                            name={name}
                            className={className}
                            style={style ? style : null}
                            onChange={e => onChange(e.target['value'])}
                            disabled={disabled}
                        />
                        {label ? (<label htmlFor="in">label</label>) : null}
                    </span>
                </div>
            ) : (
                <InputTextarea
                    required={required}
                    value={value}
                    name={name}
                    rows={rows}
                    className={className ? className : null}
                    style={style ? style : null}
                    onChange={e => onChange ? onChange(e.target['value']) : null}
                    disabled={disabled}
                />
            )}

        </>
    );
};

export default InputTextareaComponent;
