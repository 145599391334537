import React, { useContext, useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonLabel, IonPopover, IonRow } from '@ionic/react';
import { formatMoney } from '../../../utils/Utils';
import { Button } from 'primereact/button';
import styles from './dialogPauta.module.css';
import { ShoppingCartContext } from '../../../context/ShoppingCartContext';
import InfoSitio from '../../../pages/abcproductos/popOverInfoSitio';
import { gql, useQuery } from '@apollo/client';
import {InputTextComponent, InputTextareaComponent} from '../..';

const MEDIOS_QUERY = gql`
  query listaMedios($id:ID!){
        medio(id:$id){
            id
            clave
            tipos{
                id
                nombre
            }
            proveedor{
                id
                nombre
            }
            claveproveedor
            direccion
            ubicacion{
                nombre
            }
            iluminacion
            nicho
            niveldeimpacto
            referencias
            medida
            disponible
            activo
            bloqueado
            Vista_larga
            Vista_media
            Vista_corta
            comentarios
            precioventa
            male_users
            female_users
            a13_17_users
            a18_25_users
            a26_40_users
            a41_55_users
            a55_users
            income_a_users
            income_b_users
            income_c_users
            income_d_users
            income_e_users
            total_hits
            total_users
            reach
            frequency
            universo
            created_at
            updated_at
            latitud
            longitud
            especiales
        }
    } 
`;

export const DetalleSitios = (props) => {
    const [sitios,setSitios] = useState(props.sitios);
    const { deleteSitios, 
        setFecha_inicio_sitios, 
        setFecha_fin_sitios, 
        setDescuento, 
        deleteinC } = useContext(ShoppingCartContext);
    const [mostrarPopOverSitio, setMostrarPopOverSitio] = useState({ showPopover: false, event: undefined });
    const [SitioInfo, setSitioInf] = useState({
        Vista_corta:"",
        vista:"",
        tipo:"",
        clave:"",
        direccion:"",
        ubicacion:"",
        nicho:"",
        niveldeimpacto:"",
        iluminacion:false,
        referencias:"",
        especiales:false
    });

    const deleteProductSitios = (product) =>{
        var array = [...sitios]; //Usa operador spread para crear una copia separada
        var index = array.indexOf(product)
        if (index !== -1) {
            deleteSitios(product);
            array.splice(index, 1);
            setSitios( array );
            deleteinC(product);
        }
            
    }

    //Funciones de mostrar información de sitios
    const MostrarInformacionDeSitioSeparado =(props)=>{
        const {data} = useQuery(MEDIOS_QUERY,{
            variables:{
                id: parseInt(props.sitio.id??-1)
            }
        });
        return (
            <IonPopover cssClass={styles.infoWindow} event={mostrarPopOverSitio.event} isOpen={mostrarPopOverSitio.showPopover} 
                onDidDismiss={()=>setMostrarPopOverSitio({ showPopover: false, event: undefined })} animated={true}>
                {data && data.medio && <InfoSitio data={data.medio} />}
            </IonPopover>  
        )   
    }

    const CabeceraSitios = () =>{
        return(            
            <IonRow >
                <IonCard style={{width:"100%",textAlign:"center", fontSize:"14"}}>  
                    <IonRow>   
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width:"100%"}}>
                            Sitio
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{color:"black",textAlign:"center", width:"100%"}}>
                            Dirección
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width:"100%"}}>
                            Precio por mes
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width:"100%"}}>
                            Tipo
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width:"100%"}}>
                            Medida
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{color:"black",textAlign:"center", width:"100%"}}>
                            Fecha inicio
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{color:"black",textAlign:"center", width:"100%"}}>
                            Fecha fin
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width: "100%"}}>
                            Descuento
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width: "100%"}}>
                            Opciones
                        </IonCol>
                    </IonRow> 
                </IonCard>
            </IonRow>
        )
    }

    const CuerpoSitios = ()=>{
        return(
            <IonRow style={{textAlign:"center"}} >
                <MostrarInformacionDeSitioSeparado sitio={SitioInfo}/>
                {props.sitios && props.sitios.map((prod)=>(
                <IonCard style={{width:"100%",textAlign:"center"}} key={Math.random()}>  
                    <IonRow>  
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{width:"100%"}} >
                            <InputTextComponent
                                name={''}
                                required={false}
                                value={prod.clave}
                                style={{width:"100%",color:"black",textAlign:"center",border:"none",fontSize:"8"}}
                                disabled={true}
                                onChange={null}
                                type={'text'}
                                
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{textAlign:"center", width:"100%"}}>
                            <InputTextareaComponent
                                value={prod.direccion}
                                rows={3} 
                                style={{width:"100%",borderRadius: "25px",color:"black", resize: "none"}}
                                disabled={null}
                                name={null}
                                onChange={null}
                                required={null}
                                tabIndex={null}
                            />
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{textAlign:"center", width:"100%"}}>
                            <InputTextComponent 
                                value={'$'+formatMoney(prod.precioventa)}
                                style={{border:"0px",color:"black",textAlign:"center",width:"100%"}}
                                disabled={true}
                                name={null}
                                onChange={null}
                                required={null}
                                type={'text'}
                                
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{textAlign:"center", width:"100%"}}>
                            <InputTextComponent
                                value={prod.tipos}
                                style={{border:"0px",color:"black",textAlign:"center",width:"100%"}}
                                disabled={true}
                                name={null}
                                onChange={null}
                                required={null}
                                type={'text'}
                                
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{textAlign:"center"}}>
                            <InputTextComponent
                                value={prod.medida}
                                style={{border:"0px",color:"black",textAlign:"center",width:"100%"}}
                                disabled={true}
                                name={null}
                                onChange={null}
                                required={null}
                                type={'text'}
                                
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{width:"100%"}}>
                            <InputTextComponent
                                type="date"
                                value={prod.fecha_inicio}
                                required={true}
                                onChange={e=>{
                                        prod.fecha_inicio = e;
                                        setFecha_inicio_sitios(e);
                                }}
                                style={{width:"60%", borderRadius: "25px"}}
                                name={null}
                                disabled={null}
                                
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{width:"60%"}}>
                            <InputTextComponent 
                                type={"date"}
                                value={prod.fecha_fin}
                                required={true}
                                onChange={e =>{
                                        prod.fecha_fin = e;
                                        setFecha_fin_sitios(e);
                                    }}
                                style={{width:"60%", borderRadius: "25px"}}
                                disabled={null}
                                name={null}
                                
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{width:"50%"}}>
                            <InputTextComponent 
                                value={prod.descuento}
                                required={true}
                                onChange={e=>{
                                    prod.descuento=e;
                                    setDescuento(e);
                                }
                                }
                                style={{width:"50%", borderRadius: "25px"}}
                                disabled={null}
                                name={null}
                                type={'text'}
                                
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                            <IonLabel><b>%</b></IonLabel>
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{width:"75%"}} >
                            <span>
                                <Button
                                    type="button"
                                    icon="pi pi-search"
                                    className="p-button-success p-button-raised p-button-rounded"
                                    style={{ marginRight: ".5em"}}
                                    onClick={(event)=>{
                                        setMostrarPopOverSitio({ showPopover: true, event: event })
                                        setSitioInf(prod);
                                    }}

                                />
                            </span>
                            <span>
                                <Button
                                    type="button"
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-raised p-button-rounded"
                                    onClick={()=>{deleteProductSitios(prod)}}
                                    
                                />
                            </span>
                        </IonCol>
                    </IonRow>
                </IonCard> 
                ))}
            </IonRow>
        )
    }
    return (
        <IonCard style={{display:(props.sitios.length === 0 ? "none" : "block")}} >
            <IonCardHeader style={{textAlign:"center",display:((Array.isArray(props.sitios) && props.sitios.length ===0) ? "none" : "block")}}>
                <IonCardTitle className={styles.headerMedios}><h1>Sitios</h1></IonCardTitle>
            </IonCardHeader>
            <IonCardContent style={{display:(props.sitios.length ===0 ? "none" : "inline")}}>
                <CabeceraSitios />
                <CuerpoSitios />
            </IonCardContent>
        </IonCard>
    )
}
