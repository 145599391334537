/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useState} from "react";
import { IonRow, IonCol, IonGrid , IonTextarea,IonSpinner} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import {InputSwitch} from 'primereact/inputswitch';
import {InputTextComponent, MapaDireccion} from "../../../components";
import { useQuery} from "@apollo/client";
import { listaUbicaciones_Query,listaVistas_Query,listaTipos_Query} from "../querys/queries";
import {Imgfile64}  from '../../../components';
import {Dropdown} from 'primereact/dropdown';

export default function FormInventario(props) {
    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
            clave: props.datos.clave,
            direccion: props.datos.direccion,
            medida: props.datos.medida,
            disponible: props.datos.disponible,
            activo: props.datos.activo,
            vialidad: props.datos.vialidad,
            colonia: props.datos.colonia,
            niveldeimpacto: props.datos.niveldeimpacto,
            iluminacion: props.datos.iluminacion,
            nicho: props.datos.nicho,
            referencias: props.datos.referencias,
            comentarios: props.datos.comentarios,
            precioventa: props.datos.precioventa,
            Vista_corta: props.datos.Vista_corta,
            Vista_media: props.datos.Vista_media,
			Vista_larga: props.datos.Vista_larga,
			imagenmapaubicacion: props.datos.imagenmapaubicacion,
			material: props.datos.material,
			latitud: props.datos.latitud,
			longitud : props.datos.longitud,
            tipoacabado: props.datos.tipoacabado,
            ubicacion: props.datos.id, 
    		vista: props.datos.id,
    		tipos: props.datos.id
		}
	});

	let lat = (props.datos.latitud === undefined ?  0 : props.datos.latitud);
	let lng = (props.datos.longitud === undefined ? 0 : props.datos.longitud);
	
	const [location, setLocation] = useState({
        id: 0,
        name: 'Guadalajara, Jalisco',
        lat: lat,
        lng: lng
	});
   
    let objectNicho :any;
	const [fields,setValueFields] = useState(props.datos);
	const [selectubicaciones, setSelectUbicaciones] = useState<string>(fields.ubicacion === null ? "" : fields.ubicacion);
	const [selectVistas, setSelectVistas] = useState<string>(fields.vista === null ? "" : fields.vista);
	const [selectTipos, setSelectTipos] = useState<string>(fields.tipos ===  null ? "" : fields.tipos);
    console.log(props.datos.nicho);
	let sinEspaciosVar = (props.datos.nicho === undefined ?  "" : props.datos.nicho.replace(/ /g, ""));
	let opcion1 ={nombre:sinEspaciosVar,nicho:sinEspaciosVar};
	objectNicho=opcion1;
	const [selectNicho, setSelectNicho] = useState(objectNicho);


	const [ImgUrlCorta, setImgUrlCorta] = useState("");
	const [ImgUrlMedia,	 setImgUrlMedia] = useState("");
	const [ImgUrlLarga, setImgUrlLarga] = useState((fields.Vista_larga === undefined || fields.Vista_larga === null ? "" : fields.Vista_larga));
 
	const [ImgCorta, setImgCorta] = useState();
	const [ImgMedia, setImgMedia] = useState();
	const [ImgLarga, setImgLarga] = useState();
	
	const [idUbicacion, setidUbicacion] = useState(props.datos.ubicacion === null || props.datos.ubicacion === undefined ? 1: parseInt(props.datos.ubicacion.id) );
    const [idVista , setidVista ] = useState(props.datos.vista === null || props.datos.vista === undefined ? 1 : parseInt(props.datos.vista.id));
	const [idTipos,setidTipos] =useState(props.datos.tipos === null || props.datos.tipos === undefined ? 4 : parseInt(props.datos.tipos.id) );

	const [nichoSeleccionado, setNichoSeleccionado] = useState("");
	const [disponibleBoolean, setdisponibleBoolean] = useState(fields.disponible);
	
	const [activoBoolean, setactivoBoolean] = useState(fields.activo);
	const [iluminacionBoolean, setiluminacionBoolean] = useState(fields.iluminacion);
	const [textareaReferencias, settextareaReferencias] = useState(fields.referencias);
	const [textareaComentarios, settextareaComentarios] = useState(fields.comentarios)
	const [direccionform, setdireccionform] = useState(fields.direccion)
	const [direccionBuscar, setdireccionBuscar] = useState(fields.direccion);
	let listNichos = [ 
		 {nombre:'A,B,C+', nicho:'A,B,C+'}
		,{nombre:'B,C+,C', nicho:'B,C+,C'}
		,{nombre:'C+,C,D', nicho:'C+,C,D'}
	];

    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
		delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		fieldtemp.Vista_larga = (ImgUrlLarga === null ? "" : ImgUrlLarga);
		fieldtemp.vista = (isNaN(idVista) ? 1 : idVista);
		fieldtemp.ubicacion = ( isNaN(idUbicacion) ? 1 : idUbicacion );
		fieldtemp.tipos  = (isNaN(idTipos) ? 4 : idTipos );
		fieldtemp.precioventa =parseFloat(fieldtemp.precioventa) ;
		fieldtemp.direccion=direccionform;
		fieldtemp.disponible = (disponibleBoolean === undefined ? false: disponibleBoolean);
		fieldtemp.activo=(activoBoolean === undefined ? false : activoBoolean);
		fieldtemp.iluminacion=(iluminacionBoolean === undefined ? false : iluminacionBoolean);
		fieldtemp.nicho=selectNicho.nicho;
		return fieldtemp;
	};
	
    
	const {loading : loading_listaUbicaciones, error : error_listaUbicaciones, data:data_listaUbicaciones } = useQuery(listaUbicaciones_Query);

	const {loading : loading_listaVistas, error : error_listaVistas, data:data_listaVistas } = useQuery(listaVistas_Query);

	const {loading : loading_listaTipos, error : error_listaTipos, data:data_listaTipos } = useQuery(listaTipos_Query);

	
	const cargarlistaUbicaciones = () =>{
		if(loading_listaUbicaciones)return <IonSpinner name="dots" />;
		if(data_listaUbicaciones){
			if(data_listaUbicaciones !== undefined){
				return (
					<Dropdown 
						tabIndex={24}
						showClear={true}
						style={{minWidth:'200px'}}
						value={selectubicaciones} 
						options={data_listaUbicaciones.ubicacions} 
						onChange={onselectUbicacion} 
						dataKey='id'
						optionLabel='nombre'
						placeholder="Selecciona la ubicación"
						disabled
						required={true}/>
				)
			}
		}
	

	}

	const onselectUbicacion=(selectid) =>{
		
		if (selectid.value){
			setidUbicacion(parseInt(selectid.value.id));
			setSelectUbicaciones(selectid.value);
		}else{
			setidUbicacion(null);
			setSelectUbicaciones(null);
		}
		
	}

	const cargarlistaVistas =()=>{
		
		if(loading_listaVistas)return <IonSpinner name="dots"/>;
		
		if(data_listaVistas){
		
			
			return (
				<Dropdown 
					tabIndex={14}
					showClear={true}
					style={{minWidth:'200px'}}
					value={selectVistas} 
					options={data_listaVistas.vistas} 
					onChange={onselectVista} 
					dataKey='id'
					optionLabel='nombre'
					placeholder="Selecciona el tipo de vista"
					disabled
					required={true}/>
			)
		}

	}

	const onselectVista = (selectid)=>{
		if (selectid.value){
			setSelectVistas(selectid.value);
			setidVista(parseInt(selectid.value.id));
		}else{
			setSelectVistas(null);
			setidVista(null);
		}
	}

   const cargarlistaTipos =()=>{
	   if(loading_listaTipos) return <IonSpinner name="dots"/>;
	   if(data_listaTipos){
			
			return (
				<Dropdown 
					tabIndex={13}
					showClear={true}
					style={{minWidth:'200px'}}
					value={selectTipos} 
					options={data_listaTipos.tipos} 
					onChange={onselectTipos} 
					dataKey='id'
					optionLabel='nombre'
					placeholder="Selecciona el tipo"
					disabled
					required={true}/>
			)
		
	   }
   }

   const onselectTipos = (selectid) =>{
	   if (selectid.value){
			setidTipos(parseInt(selectid.value.id));
			setSelectTipos(selectid.value);
	   }else{
			setidTipos(null);
			setSelectTipos(null);
	   }
		
   }
    
	const ListaNichos = ()=>{
		return(
			<Dropdown 
				tabIndex={9}
				showClear={true}
				style={{minWidth:'100%'}}
				value={selectNicho} 
				options={listNichos} 
				onChange={onselectNicho}
				dataKey='nicho'
				optionLabel='nombre' 
				placeholder="Selecciona un nicho"
				disabled
				required={true}/>
		)
	}
	
	const onselectNicho =(selectId)=>{
		if (selectId){
			setNichoSeleccionado(selectId.value);
			setSelectNicho(selectId.value);
	   }else{
			setNichoSeleccionado(null);
			setSelectNicho(null);
	   }   
	}

  
	const onSubmit = data => { 

    }
    
   

    // const handleChange = (e) =>{
    //     setValueFields({...fields,[e.target.name]:e.target.value})
	// }

	const handleChange = (e, name = '') =>{
        setValueFields({...fields,[name]:e})
	}


	const getvalueTextareaComentarios =(value)=>{
		settextareaComentarios(value);
		fields.comentarios=value;
	}

	const getvalueTextareaReferncias =(value)=>{
		settextareaReferencias(value);
		fields.referencias=value;
	}

	
	const comversionImgenCorta = async (event) => {
		const file = event.target.files[0];
		const imageCorta = await Imgfile64(file);
		establecerUrlVistaCorta(imageCorta);
		fields.Vista_corta=imageCorta;
	}

	const establecerUrlVistaCorta = (imageCorta) =>{
		setImgUrlCorta(imageCorta);
	}
	
	
	const mostrarImgenCorta =() =>{
		if(ImgUrlCorta !== ""){
			

			return (
				<img src={ImgUrlCorta} alt='' style={{display:"block",
				margin:"auto"}} width="100%"  height="150"></img>
			)
		}else if(fields.Vista_corta !== undefined && fields.Vista_corta !== "" && fields.Vista_corta !== null && ImgUrlCorta ===""){
			return (
				<img src={fields.Vista_corta} alt='' style={{display:"block",
				margin:"auto"}} width="100%"  height="150"></img>
			)
		}else{
			
			return (
				<h5 style={{textAlign:'center'}}>Sin imagen</h5>
			)
		}
	}

	const comversionImgenMedia = async (event) => {
		const file = event.target.files[0];
		const imageMedia = await Imgfile64(file);
		establecerUrlVistaMedia(imageMedia);
		fields.Vista_media= imageMedia ;
	}

	const establecerUrlVistaMedia = (imageMedia) =>{
		setImgUrlMedia(imageMedia);
	}
	
	
	const mostrarImgenMedia =() =>{
		
		if(ImgUrlMedia !== "" ){
			return (
				<img src={ImgUrlMedia} alt='' style={{display:"block",
				margin:"auto"}} width="100%" height="150"></img>
			)
		}else if(fields.Vista_media !== undefined  && fields.Vista_media !== "" && fields.Vista_media !== null && ImgUrlMedia === ""){
			
			
			return (
				<img src={fields.Vista_media} alt='' style={{display:"block",
				margin:"auto"}} width="100%" height="150"></img>
			)
				
				
		}else{
			
			return (
				<h5 style={{textAlign:'center'}}>Sin imagen</h5>
			)
		}
	}

	const comversionImgenLarga = async (event) => {
		const file = event.target.files[0];
		const imageLarga = await Imgfile64(file);
		establecerUrlVistaLarga(imageLarga);
		
		//fields.Vista_larga = imageLarga;
	
	}

	const establecerUrlVistaLarga = (imageLarga) =>{
		setImgUrlLarga(imageLarga);
		
	}
	
	
	const mostrarImgenLarga =() =>{
		
		if(ImgUrlLarga !== ""  && ImgUrlLarga !== undefined){

			return (
				<img src={ImgUrlLarga} alt='' style={{display:"block",
				margin:"auto"}} width="100%"  height="150"></img>
			)
			
		}else if(fields.Vista_larga !== undefined &&  fields.Vista_larga !== "" && fields.Vista_larga !== null && ImgUrlLarga === ""){

			return (
				<img src={fields.Vista_larga} alt='' style={{display:"block",
				margin:"auto"}} width="100%"  height="150"></img>
			)
				
		}else{
		
			return (
				<h5 style={{textAlign:'center'}}>Sin imagen</h5>
			)
		}
	}

	const validardisponivilidad = (value) =>{
		fields.disponible=value;
		setdisponibleBoolean(value);
	}

	const validarActivo = (value) =>{
		fields.activo=value;
		setactivoBoolean(value);
	}

	const validarIluminacion= (value) =>{
		fields.iluminacion = value;
		setiluminacionBoolean(value);
	}
	
	
	const asignaImagenMapa = (imagen:any) =>{
		fields.imagenmapaubicacion = imagen;
	}
	 
	const buscarEnMapa = () =>{
		setdireccionBuscar(direccionform);
	}
	
	const getDireccion =(value) =>{
		setdireccionform(value);
		document.getElementById("direccionMp").onblur = function() {buscarEnMapa()};

	}

	const getCoordenadas = (lat:any,lng:any) =>{
		
		fields.latitud=lat;
		fields.longitud=lng;

	}

	const newDireccionGooglemaps = (direccion:string)=>{
		if(direccion === undefined){

		}else{
			setdireccionform(direccion);
		}
	}
	
	 
	const mostrarOcultarMapa = () =>{
		
		if(props.operacion === 'crear' || props.operacion === 'editar'){
			
			return(
				<MapaDireccion 
					locations={props.locations||[]} 
					mapCenter={location} 
					zoom={14} 
					ubicacionDireccion={direccionBuscar} 
					onLoadImage={asignaImagenMapa}
					OnLoadCoordenadas={getCoordenadas}
					OnloadDirecion={newDireccionGooglemaps}
				/>
			)
		}else{
			if(fields.imagenmapaubicacion !== undefined && fields.imagenmapaubicacion !== null && fields.imagenmapaubicacion !== ""){
				
					
				return (
					<img src={fields.imagenmapaubicacion} alt='' style={{display:"block",
					margin:"auto"}} ></img>
				)
				
			}else{
				return(
					<div>
	
					</div>
				)
			}
		}
	}


	const botones = () => {
		if (props.operacion === 'editar' || props.operacion === 'crear') {
			return (
				<div>
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>
						{/* Botón del carrito de compras */}
						
						
					</IonRow>	
				</div>			
			)
			
		}else{
			return(
				<div>

				</div>
			)
		}
		
	}

	return (
		<form   onSubmit={handleSubmit(onSubmit)}>
			<IonGrid>
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="clave"
								value={(fields.clave === null ? "" : fields.clave)}
								style={{ width: "100%" }}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'clave');
									setValue("clave", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Clave</label>
						</span>
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
							    // id="direccionMp"
								name="direccion"
								value={direccionform}
								style={{ width: "100%" }}
								required
								// tabIndex={2}
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									setdireccionform(e);
									getDireccion(e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Direccion</label>
						</span>
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="medida"
								value={(fields.medida === null ? "" : fields.medida)}
								style={{ width: "100%" }}
								required
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'medida');
									setValue("medida", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Medida</label>
						</span>
					</IonCol>
				</IonRow>
				<br></br>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="vialidad"
								value={(fields.vialidad === null ? "" : fields.vialidad)}
								style={{ width: "100%" }}
								required
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'vialidad');
									setValue("vialidad", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Vialidad</label>
						</span>
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="colonia"
								value={(fields.colonia === null ? "" : fields.colonia)}
								style={{ width: "100%" }}
								required
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'colonia');
									setValue("colonia", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Colonia *****</label>
						</span>
					</IonCol>
                </IonRow>
				<br></br>
                <IonRow>
					<IonCol  size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="tipoacabado"
								value={(fields.tipoacabado === null ? "" : fields.tipoacabado)}
								style={{ width: "100%" }}
								required
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'tipoacabado');
									setValue("tipoacabado", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Tipo de acabado</label>
						</span>
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="niveldeimpacto"
								value={(fields.niveldeimpacto === null ?  "" : fields.niveldeimpacto)}
								style={{ width: "100%" }}
								required
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'niveldeimpacto');
									setValue("niveldeimpacto", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Nivel de impacto</label>
						</span>
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						    
							<label htmlFor="in">Nicho</label>
							<br></br>
							<ListaNichos />
						
					</IonCol>
                </IonRow>
				<br></br>
                <IonRow>
					<IonCol  size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="material"
								value={(fields.material === null ?  "" : fields.material)}
								style={{ width: "100%" }}
								required
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'material');
									setValue("material", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Material</label>
						</span>
					</IonCol>
                    
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="precioventa"
								value={(fields.precioventa === null ?  "" : fields.precioventa)}
								style={{ width: "100%" }}
								required
								type={"number"}
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'precioventa');
									setValue("precioventa", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
							/>
							<label htmlFor="in">Precio venta</label>
						</span>
					</IonCol>
                </IonRow>
				<br></br>
				<IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4"  style={{textAlign:'center'}}>
						<br></br>
						<label htmlFor="in" >Tipo de sitio </label>
						{cargarlistaTipos()}
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="" sizeXl="4"  style={{textAlign:'center'}}>
						<br></br>
						<label htmlFor="in" >Tipo de Vista</label>
						{cargarlistaVistas()}
					</IonCol>
                </IonRow>
				<br></br>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						<label htmlFor="in">Referencias</label>
							<IonTextarea
								required
								tabIndex={16}
								rows={4}
								disabled={props.operacion === "detalles" ? true:false}
								style={{ width: "100%",border:" 1px solid gray" }}
								value={textareaReferencias} onIonChange={e => getvalueTextareaReferncias(e.detail.value!)}
							></IonTextarea>
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						<label htmlFor="in">Comentarios</label>
						<IonTextarea
							tabIndex={17}
							style={{ width: "100%",border:" 1px solid gray" }}
							rows={4}
							disabled={props.operacion === "detalles" ? true:false}
							value={textareaComentarios} onIonChange={e => getvalueTextareaComentarios(e.detail.value!)}
						></IonTextarea>
					</IonCol>
                </IonRow>
				<br></br>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<label htmlFor="in">Vista corta</label>
						<InputTextComponent
							name="Vista_corta"
							value={ImgCorta}
							style={{ width: "100%" }}
							type={"file"}
							accept="image/x-png,image/gif,image/jpeg"
							disabled={props.operacion === "detalles" ? true:false}
							onChange={event => comversionImgenCorta(event)}
							className={null}
							label={null}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
						/>
						{mostrarImgenCorta()}
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<label htmlFor="in">Vista media</label>
						<InputTextComponent
							name="Vista_media"
							value={ImgMedia}
							style={{ width: "100%" }}
							type="file"
							accept="image/x-png,image/gif,image/jpeg"
							disabled={props.operacion === "detalles" ? true:false}
							onChange={event => comversionImgenMedia(event)}
							className={null}
							label={null}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
						/>
						{mostrarImgenMedia()}
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<label htmlFor="in">Vista larga</label>
							<InputTextComponent
								name="Vista_larga"
								value={ImgLarga}
								style={{ width: "100%" }}
								type="file"
								accept="image/x-png,image/gif,image/jpeg"
								disabled={props.operacion === "detalles" ? true:false}
								onChange={event => comversionImgenLarga(event)}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
							/>
						{mostrarImgenLarga()}
					</IonCol>
                </IonRow>
				<br></br>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4"  style={{textAlign:'center'}}>
						<label htmlFor="in">Disponible</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true:false} checked={disponibleBoolean} onChange={(e) => validardisponivilidad(e.value)}/>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4"  style={{textAlign:'center'}}>
						<label htmlFor="in">Activo</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true:false} checked={activoBoolean} onChange={(e) => validarActivo(e.value)}/>
					
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4"  style={{textAlign:'center'}}>
						<label htmlFor="in">Iluminacion</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true:false} checked={iluminacionBoolean} onChange={(e) => validarIluminacion(e.value)}/>
					
					</IonCol>
                </IonRow>
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12"  style={{textAlign:'center'}}>
						
						<label htmlFor="in" >Ubicacion</label>
						<br></br>
						{cargarlistaUbicaciones()}
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol>
					  {mostrarOcultarMapa()}
					</IonCol>
				</IonRow>
				<IonRow>
					<img alt='' src=""></img>
				</IonRow>
					<br/><br/>
				<div>{botones()}</div>
			</IonGrid>

		</form>
	);
}
