import {gql} from '@apollo/client';

export const CAMPANIAS_URBANOS_QUERY = gql `
query listaUrbanoscampanias($sort: String, $limit: Int, $start: Int, $where: JSON){
    urbanoscampanias(sort: $sort, limit: $limit, start: $start, where: $where){
        id
        unidades_urbano{
            id
            numeco
            activo
            ruta{
                id
                nombre
                clave
                ubicacion{
                    id
                    nombre
                }
            }
            tipoContrato
            cartuchos
            permisionario{
                id
                permisionario
                numPermisionario
                costo
            }
            activo
            created_at
            vigencia
        }
        campania{
            id
            nombre
            clientes{
                color
            }
            estatus
        }
        inicia
        termina
    }
}  
`;

export const URBANOS_INCIDENCIAS_QUERY= gql `
query listaUrbanosIncidencias($sort: String, $limit: Int, $start: Int, $where: JSON){
    urbanosIncidencias(sort: $sort, limit: $limit, start: $start, where: $where){
        id
        unidades_urbano{
            id
            numeco
            cartuchos
            ruta{
                id
                nombre
                clave
                ubicacion{
                    id
                    nombre
                }
            }
            permisionario{
                id
                numPermisionario
                permisionario
                costo
            }
            activo
            created_at
        }
        tipoIncidencia
        inicia
        termina
        created_at
    }
}
`;

export const LISTAR_ECOS = gql `
query listaunidadesUrbanos($sort: String, $limit: Int, $start: Int, $where: JSON){
    unidadesUrbanos(sort: $sort, limit: $limit, start: $start, where: $where){
        id
        numeco
        tipoContrato
        cartuchos
        activo
        created_at
        vigencia
        ruta{
            id
            nombre
            clave
            ubicacion
            {
                id
                nombre
            }
        }
        permisionario{
            id
            numPermisionario
            permisionario
            costo
        }
    }
}
`;

export const LISTAR_PERMISIONARIOS= gql `
    query listaPermisionarios($sort: String,$limit: Int,$start: Int,$where: JSON){
        permisionarios(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            numPermisionario
            esquemaPago
            permisionario
            costo
            unidades_urbanos
            {
                id
            }
        }
    }
`;
export const LISTAR_RUTAS = gql `
query listaRutas($sort: String,$limit: Int,$start: Int,$where: JSON){
    rutas(sort:$sort,limit:$limit,start:$start,where:$where){
        id
        nombre
        clave
        ubicacion{
            id
            nombre
        }
    }
}
`;

export const LISTAR_CAMPANIAS = gql `
    query listaCampanias($sort: String,$limit: Int,$start: Int,$where: JSON){
        campanias(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            estatus
            inicia
            termina
            preciourbanos
        }
    }
`;

export const CREATE_CAMPANIASURBANOS = gql `
    mutation crearCampaniasurbano($input:createUrbanoscampaniaInput){
        createUrbanoscampania(input:$input){
            urbanoscampania{
                id
            }
        }
    }
`;

export const CREATE_INCIDENCIAS=gql `
    mutation crearUrbanosIncidencia($input:createUrbanosIncidenciaInput){
        createUrbanosIncidencia(input:$input){
            urbanosIncidencia{
                id
            }
        }
    }
`;

export const UPDATE_CAMPANIASURBANOS=gql `
    mutation updateUrbanoscampania($input:updateUrbanoscampaniaInput){
        updateUrbanoscampania(input:$input){
            urbanoscampania{
                id
                inicia
                termina
            }
        }
    }
`;

export const DELETE_CAMPANIASURBANOS=gql `
    mutation DELETE_CAMPANIASURBANOS($input:deleteUrbanoscampaniaInput){
        deleteUrbanoscampania(input:$input){
            urbanoscampania{
                id
            }
        }
    }
`;

export const DELETE_URBANOSINCIDENCIAS=gql `
    mutation DELETE_URBANOSINCIDENCIAS($input:deleteUrbanosIncidenciaInput){
        deleteUrbanosIncidencia(input:$input){
            urbanosIncidencia{
                id
            }
        }
    }
`;

