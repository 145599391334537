import { gql } from "@apollo/client";

export const PROVEEDORES_QUERY = gql`
    query proveedores($sort:String ,$limit:Int,$start:Int,$where:JSON){
        proveedors(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            telefono
            rfc
            nombre_comercial
            liquidara
            domicilio
            colonia
            cp
            email
            password
            estatus
            estado
            createdby{
            
            email
            }
            modifiedby{
            
            email
            }
            created_at
            updated_at
            users{
                id
                nombre
                email
                createdby{
                    id
                    email
                }
                modifiedby{
                    id
                    email
                }
            } 
        }     
    }
`;

export const CREATE_PROVEEDOR = gql`
    mutation crearProveedor($input:createProveedorInput){
        createProveedor(input:$input){
            proveedor{
                id
                nombre
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;


export const UPDATE_PROVEEDOR = gql`
    mutation actualizarProveedor($input:updateProveedorInput){
        updateProveedor(input:$input){
            proveedor{
                id
                nombre
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_ESTADO = gql`
    mutation actualizarProveedor($input:updateProveedorInput){
        updateProveedor(input:$input){
            proveedor{
                id
                estado
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;



export const DELETE_PROVEEDOR = gql`
    mutation eliminarProveedor($input:deleteProveedorInput){
        deleteProveedor(input:$input){
            proveedor{
            id
            nombre
            createdby{
                email
            }
            modifiedby{
                email
            }
            created_at
            updated_at
            }
        }
    }
`;

