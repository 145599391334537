import React, { useState } from "react";
import {IonRow, IonCol, IonGrid, IonPopover, IonLabel, IonHeader, IonSpinner, IonTitle} from "@ionic/react";
import { Button } from "primereact/button";
import {LISTAR_CAMPANIAS, CAMPANIAS_URBANOS_QUERY, UPDATE_CAMPANIASURBANOS, DELETE_CAMPANIASURBANOS, DELETE_URBANOSINCIDENCIAS} from "./query";
import { InputTextComponent } from "../../components";
import moment from 'moment';
import { useQuery,
    useMutation,
    useLazyQuery} from "@apollo/client";
import {Dropdown} from 'primereact/dropdown'
import { useNotification } from "../../hooks";
import MultiSelect from "react-multi-select-component";

const EliminarAsignacion=(props)=>{
    //Campaña
    const [campania, setCampania]=useState()
    const [idCampania, setIdCampania]=useState(0)
    const [campaniaSel, setCampaniaSel]=useState(null)
    //Multiselect
    const[opciones, setOpciones]=useState([])
    const[selected, setSelected]=useState([])

    const [refresh, setRefresh] = useState(false);
    const {
        notification,
        showSuccess,
        showInfo,
        showError,
    } = useNotification();
    
    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

    const onSubmit=()=>{
        selected.forEach(element=>{
            var id=parseInt(element.value)
            recorrerArray(id)
        })
    }
    const recorrerArray=(id)=>{
        deleteUrbanoscampania ({
            variables: {
                input: {
                    where: {
                        id:id
                    }
                }
            }
        })
    }

    const [deleteUrbanoscampania]=useMutation(DELETE_CAMPANIASURBANOS, {
        onCompleted(data){
            showSuccess('Operación exitosa', 'Se realizó la operación con éxito')
        }, onError(error){
            console.log("ERROR: ", error)
        }
    })
    
    const {loading:loadingCampanias, error:errorCampanias, data:dataCampanias}=useQuery(LISTAR_CAMPANIAS, {
        variables:{
            sort:"idbitrix:DESC",
            where:{
                _or:[
                    {estatus:"Cerrado Ganado"},
                    {estatus:"Cotización personalizada"},
                    {estatus:"Cotización informal"}
                    ],
                preciourbanos_gt:0 
            }
        },
        onCompleted:(dataCampanias)=>{
          //console.log("Data Campañas ",dataCampanias.campanias)
        }, onError(errorCampanias){
            console.log("Error: ",errorCampanias)
        }
    });

    const onselectCampania=(selectC)=>{
        if(selectC.value){
            setSelected([])
            setCampaniaSel(selectC.value)
            setCampania(selectC)
            setIdCampania(selectC.value.id)
            console.log(selectC.value.nombre)
        }else{
            setSelected([])
            setCampaniaSel(null)
            setCampania(null)
            setSelected([])
            setOpciones([])
        }
    }

    const campaniaDrop=()=>{
        if(errorCampanias) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingCampanias) return  <IonSpinner name="dots" />;
        if(dataCampanias)
        return(
            <Dropdown
                name="campanias"
                filter 
                tabIndex={1}
                showClear={true}
                style={{width:'95%',borderRadius: "25px"}}
                dataKey='id'
                optionLabel='nombre'
                value={campaniaSel} 
                options={dataCampanias.campanias} 
                onChange={onselectCampania}
                placeholder='Selecciona la campaña'
                required={true}
            />
        )
    }


    ///////////////////LLenado de ECO de camiones
    const {loading:loadingEco, data:dataEco, error:errorEco}=useQuery(CAMPANIAS_URBANOS_QUERY, {
        variables:{
            sort:"id:ASC",
            where:{
                "campania":idCampania
            }
        }, onCompleted(dataEco){
            console.log("Ecos disponibles ", dataEco.urbanoscampanias)
            const options=[]
            dataEco.urbanoscampanias.forEach(element => {
                var x={
                    label:element.unidades_urbano.numeco+' - '+element.unidades_urbano.permisionario.permisionario,
                    value:parseInt(element.id)
                }
                options.push(x)
            });
            setOpciones(options)
            
        }, onError(errorEco){
            console.log("Error Ecos ",errorEco)
        }
    });

    const   OverrideString = {
        "selectSomeItems": "Seleccionar...",
        "allItemsAreSelected": "Todos los ECO están seleccionados.",
        "selectAll": "Todos",
        "search": "Buscar",
        "clearSearch": "Limpiar búsqueda"
    }

    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => "✔️ " + label)
            : "Seleccionar ECO";
    };

    const listarNumEco=()=>{
        if(errorEco) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingEco) return  <IonSpinner name="dots" />;
        if(dataEco)   return(
            <>
            <MultiSelect
                options={opciones}
                value={selected}
                onChange={setSelected}
                labelledBy="numeco"
                overrideStrings={OverrideString}
                valueRenderer={customValueRenderer}
            />
            </>
		)
    }

    return(
        <div>
            {notification}
            <IonGrid>
                <IonTitle>Eliminar Asignaciones</IonTitle>
                <br/>
                <IonRow>
                    <IonCol>
                        <IonLabel>Seleccionar Campaña</IonLabel>
                    </IonCol>
                    <IonCol>
                        Seleccionar registros a eliminar
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {campaniaDrop()}
                    </IonCol>
                    <IonCol>
                        {listarNumEco()}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <Button
							label="Guardar"
							icon="pi pi-check"
							onClick={onSubmit}
							className="p-button-success ion-float-left"
							style={{ marginLeft: "20px"}}
							/>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
export default EliminarAsignacion