import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
	IonAlert
} from "@ionic/react";

import useNotification from "../../hooks/useNotification";
import useDialog from "../../hooks/useDialog";
import {Toolbar, DTableV2} from "../../components";
import {LISTAR_PAUTAS_QUERY} from './queries';
import useAuthentication from "../../hooks/useAuthentication";
import FormPorductos from "./Formpauta";
import {DataTable} from  "primereact/datatable";
import {Column} from "primereact/column";
import { renderCurrency} from "../../utils/dataTableAux";
import { clearLocalStorage } from "../../utils/Utils";
const columnas = [
	{
		header: "Nombre de cliente",
		field: "cliente",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Nombre de campaña",
		field: "campania.nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Nombre del Responsable",
		field: "responsable.nombre",
		sorteable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Fecha inicio",
		field: "fecha_inicio",
		sortable: true
	},
	{
        header: "Fecha fin ",
        field:"fecha_fin",
		sortable: true,
	},
	{
		header: "Estatus",
		field: "estatus",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Fecha de creación",
		field: "campania.estatusinicial",
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header:"Creado por",
		field: "createdby.nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header:"Modificado por",
		field:"modifedby.nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	}
];


const Pautas = (props) => {

	const [refresh, setRefresh] = useState(false);
	const {usuarioid,rol} = useAuthentication();
	const [titleHeader, settitleHeader] = useState('Titulo');

	//States del alert
	const [showAlert, setShowAlert]=useState(false)
	const [handler, setHandler]=useState(null)

	let datosMedio = []
	let datosRutas = [];
	const {
		notification,
		showSuccess,
		showInfo,
	} = useNotification();

	const [expandedRows, setExpandedRows] = useState(null);
	
	const rowExpansionTemplate = (data)=>{
	
		for (let index = 0; index < data.detailpautas.length; index++) {
			const element = data.detailpautas[index];
			if(element.medio == null){
				datosRutas.push(element);
			}else if(element.ruta == null ){
				datosMedio.push(element);
				
			}
		}
		
		return (
			<div className="p-grid p-fluid" style={{padding: '.5em .5em .5em .5em'}}>
			{data.detailpautas.length <= 0 && <p>Sin datos para mostrar</p>}
			{datosMedio.length > 0 &&
				<DataTable value={datosMedio}>
					<Column field="circulospauta.etiqueta" header="Etiqueta" />
					<Column field="medio.clave" header="Sitio" />
					<Column field="medio.direccion" header="Dirección" />
					<Column field="medio.precioventa" header="Precio" body={renderCurrency} />
					<Column field="descuento" header="Descuento" />
					<Column field="medio.tipos.nombre" header="Tipo" />
					<Column field="medio.ubicacion.nombre" header="Ubicación" />
					<Column field="medio.medida" header="Medidas" />
					<Column field="fecha_inicio" header="Fecha inicio" />
					<Column field="fecha_fin" header="Fecha fin"  />
				</DataTable>
			}

            <br></br>
			{datosRutas.length > 0 &&
				<DataTable value={datosRutas}>
					<Column field="circulospauta.etiqueta" header="Etiqueta" />
					<Column field="ruta.nombre" header="Nombre de ruta" />
					<Column field="ruta.ubicacion.tipoexhib" header="Tipo de exhibición" />
					<Column field="cantidad" header="Cantidad" />
					<Column field="ruta.ubicacion.preciourbano" header="Precio" body={renderCurrency} />
					<Column field="descuento" header="Descuento" />
					<Column field="fecha_inicio" header="Fecha inicio" />
					<Column field="fecha_fin" header="Fecha fin"  />
					<Column field="ruta.descripcion" header="Descripción" />
				</DataTable>
			}
		</div>
		); 
	}
	
	const {showDialog, FormDialog} = useDialog();
	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */
	
	const cambiarEstado = (data) => {
		//console.log("Data para cambiar estatus:");
	};

	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
	// const addProveedor = () => {
	// 	console.log("Nueva Pauta");
	// }
	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	//const [pauta, setPauta] =useState ();
	var paut:any;
	
	const onEdit = (data) =>{
		console.log(localStorage.length>0)
		if(localStorage.length>0){
			setHandler(data)
			setShowAlert(true)
		}
		else {
		paut=data;
		showDialog(paut,'pauta');
		settitleHeader("Edición de Pauta")
		}
	}

	const onView = (data)=>{
		showDialog(data,'detalles');
		settitleHeader("Detalles de pauta");
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
	
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>
			<IonContent>
				{notification}
				{/* <MToolBar addClick={addProveedor} /> */}
				<IonAlert
					isOpen={showAlert}
					onDidDismiss={() => setShowAlert(false)}
					header={'Registro en memoria detectado'}
					message={'¿Deseas limpiar la memoria y continuar'}
					buttons={[
						{
							text: 'Sí',
							handler: () => {
								console.log(handler);
								clearLocalStorage()
								paut=handler;
								showDialog(paut,'pauta');
								settitleHeader("Edición de Pauta")
							}
						},
						{
							text: 'No',
							handler: blah => {
								console.log('blah');
							}
						}
					]}
				/>
				<DTableV2
					filtro={rol()===('Administrador') || rol()===('Operaciones Fijos') || rol()===('Operaciones Urbanos') || rol()===('Operaciones')?{id_gt:0}:{createdby:usuarioid()}}
					query={LISTAR_PAUTAS_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					ordenarPor={'updated_at:desc'}
					element="pautas"
					onDelete={cambiarEstado}
					canView={false}
					canDelete={false}
					canEdit={true}
					onEdit={onEdit}
					onView={onView}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
					rowExpansionTemplate={rowExpansionTemplate}
					expandedRows={expandedRows} 
					onRowToggle={(e) => {
							console.log('Valor de e ',e)
							setExpandedRows(e.data)
					}}
					paginator={true}
				/> 
                <FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					< FormPorductos></FormPorductos>
				</FormDialog>
			</IonContent>
		</IonPage>
	);
}

export default Pautas;
