/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { IMJ_API_KEY } from '../../utils/Constanst';
import './MapaABCSitios.css';

const MapaABCSitios = (props) => {
    const [mapa, setMapa] = useState(null);
    const [draggable,setDraggable] = useState(true);
    const [lat,setLat] = useState(0);
    const [lng,setLng] = useState(0);
    const [marker,setMarker] = useState(null);
    const [generateImageFlag, setGenerateImageFlag] = useState(false);

    /**
     * Evento encargado de centrar el mapa y posicionar un marker
     * en los datos de dicho centro por medio de los input lat-lng
     */
    const centerEvent = () => {
        const latLngObject = {
            id: 0,
            name: '',
            lat: lat,
            lng: lng
        }
        // console.log('latLngObject', latLngObject);
        mapa.setCenter(latLngObject);
        setMarkerEvent(lat, lng, mapa);
    }

    /**
     * Evento encargado de agregar un solo marker en los datos
     * establecidos como parametros
     * @param latParam 
     * @param lngParam 
     * @param mapParam 
     */
    const setMarkerEvent = (latParam: number, lngParam: number, mapParam: any) => {
        let markerTemp = new google.maps.Marker({
            map:mapParam,
            draggable: true,
            position: {lat:latParam,lng:lngParam}
          });
        google.maps.event.addListener(markerTemp, 'dragend', function(evt){
            generaImagen();
            setLat(evt.latLng.lat());
            setLng(evt.latLng.lng());
            if (props.changeLatLng){
                props.changeLatLng(evt.latLng.lat(),evt.latLng.lng());
            }
        });

        setMarker(markerTemp);
    }

    /**
     * Evento encargado de cargar los datos
     * de la api de googleMapss
     */
    const apiIsLoaded = async (map, maps) => {       
        map.setCenter(props.mapCenter);
        if (props.latitud && props.longitud){
            setMarkerEvent(props.latitud,props.longitud, map);
        }
        setMapa(map);
        props.setMapReference(map);
    };

    useEffect(() => {
        if (props.latitud !== lat) {
            setLat(props.latitud);
        }
        if (props.longitud !== lng) {
            setLng(props.longitud);
        }
        if (lat !== 0 && lng !== 0) {
            setGenerateImageFlag(true);
        } else {
            setGenerateImageFlag(false);
        }
        
    }, [props, lng, lat]);

    useEffect(() => {
        if (marker){
            if (lat!==props.latitud && lng!==props.longitud){
                marker.setPosition({lat:props.latitud,lng:props.longitud});
                setLat(props.latitud);
                setLng(props.longitud);
                generaImagen();
            }
            
        }
    }, [props.latitud, props.longitud]);

    const generaImagen = ()=>{
        let ImageMapUrl='https://maps.googleapis.com/maps/api/staticmap?center='+lat+','+lng+'&zoom=18&size=600x400&maptype=roadmap\&markers=size:mid%7Ccolor:red%7C'+lat+','+lng+'&key=AIzaSyBop_VhCaJf7YviDGL7uIHLjPSJft1QmfQ';
        const  toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
        }))


        toDataURL(ImageMapUrl).then(dataUrl => {
        /*  varibale que continene el resultado de lacomversion a base 64 ,
            pendiente pasara a formulario para guardar
        */
            //this.mapimagen=dataUrl;
            if (props.onLoadImage) {
                props.onLoadImage(dataUrl);
            }
            
        });
    }

    return (
        <div id="map_canvas" className={'map-abcmedios-container'} 
            // style={{ height: "40vh", width: "100%" }}
        >
            <div onClick={() => {
                if (generateImageFlag) {
                    centerEvent();        
                }
            }} className={generateImageFlag ? 'button-form-abc-medios-map' : 'button-form-abc-medios-disable-map'}>
                Capturar Imagen
            </div>
            <GoogleMapReact
                key={'mapa'}
                bootstrapURLKeys={{ key: IMJ_API_KEY, libraries: ["places", "drawing"] }}
                defaultCenter={{ lat: 0, lng: 0 }}
                defaultZoom={18}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                options={{
                    mapTypeControl: false,
                    streetViewControl: true,
                    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }], },]

                }}

                draggable={draggable} 
            />
            <br></br>
            <br></br>
        </div>
    )
};

export default MapaABCSitios;
