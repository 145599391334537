import React from 'react'
import imjColors from './ImjColors';
import { Chart } from 'primereact/chart';
import NumberFormat from 'react-number-format';

const ChartGrupoEdad = (props) =>{
	let dataGraph = {
		labels: ['13-17','18-25','26-40','41-55','55 o más'],
		datasets:[
			{
				backgroundColor: imjColors,
				data: [props.data.a13_17_users,props.data.a18_25_users,props.data.a26_40_users,props.data.a41_55_users,props.data.a55_users],
				datalabels: {
					anchor: 'end'
				}
            }
		]
	}
	
	let options = {
		title:{
			display: true,
			text:'Afluencia por Grupo de edad (H y M)'
		},
		legend: {
            display: true,
          	position: 'bottom',
            labels: {
				fontColor: '#333',
				fontSize: props.fontSize || '12'
            }
		},
		plugins: {
			datalabels: {
				backgroundColor: function(context) {
					return context.dataset.backgroundColor;
				},
				borderColor: 'white',
				borderRadius: 25,
				borderWidth: 2,
				color: 'white',
				font: {
					weight: 'bold',
					size: props.fontSize || 12
				},
				formatter: function(value, context) {
                    var dataset = context.dataset;
                    var sum = dataset.data.reduce(function(a, b){
                        return a + b;
                    }, 0);
                    let value2 = Math.round((dataset.data[context.dataIndex] / sum) * 100);
					value = new Intl.NumberFormat().format(value2)+' %';
					return value;
				}
			}
		  }
	}
    return (
		<div style={{textAlign:'center', fontSize: '10px'}}>
        	<Chart type="pie" data={dataGraph} options={options}/>
			{props.showData && <div style={{display:'flex'}}>
			<div className="p-md-2">Alcance x Edad 13-17: <b><NumberFormat value={props.data.a13_17_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance x Edad 18-25: <b><NumberFormat value={props.data.a18_25_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance x Edad 26-40: <b><NumberFormat value={props.data.a26_40_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance x Edad 41-55: <b><NumberFormat value={props.data.a41_55_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance x Edad 55 o más: <b><NumberFormat value={props.data.a55_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
			</div>}
					
		</div>	
    )
}

export default ChartGrupoEdad
