/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React,{useState, useContext, useEffect} from  "react";
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";
import { 
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    IonCard,
    IonCardContent,
    IonLabel  
} from '@ionic/react';
import {Dropdown} from 'primereact/dropdown'
import {
    LISTAR_CLIENTES,
    REGISTRAR_DETALLE_PAUTA,
    REGISTRAR_PAUTA,
    LISTAR_RESPONSABLES,
    MEDIOS_QUERY_WHERE,
    RUTAS_QUERY_WHERE,
    REGISTRAR_CIRCULO,
    UPDATE_PAUTA, 
    DELETE_DETAILS,
    BUSCAR_DETALLES,
    DELETE_CIRCULO,
    LISTAR_CAMPANIAS,
    LISTA_PAUTAS,
    LISTA_UNIVERSO
} from "./queries"
import ReactLoading from 'react-loading';
import { useQuery,
     useMutation, 
     useLazyQuery} from "@apollo/client";
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import {ColorPicker} from 'primereact/colorpicker';
import {Imgfile64}  from '../';
import {UPDATE_CLIENTE} from '../../pages/clientes/queries';
import { Toolbar } from 'primereact/toolbar';
import { saveAs } from 'file-saver';
import KMLExport from '../../utils/KmlExport';
import {getLocalStorage} from '../../utils/Utils';
import { DetalleRutas } from "./detalles/DetalleRutas";
import moment from 'moment';
import { DetalleSitios } from "./detalles/DetalleSitios";
import { DetalleCirculo } from "./detalles/DetalleCirculo";
import { MESSAGE_COMPLETE_DOWNLOAD_PDF, MESSAGE_INFO_DONWLOAD_PDF } from "../../utils/Messages";
import useNotification from "../../hooks/useNotification";
import {useJSReportPDF} from '../../hooks'
import useDialog from "../../hooks/useDialog";
import {ArrayCirculos} from '../../pages/sitios/components/CiculoIncluencia/CirculoInfluencia'
import {exportPDF} from "./export/PdfExport";
import {exportExcel} from "./export/excelPauta";
import {exportExcelDetail} from "./export/excelDetail";
import {excelRutas} from "./export/excelRutas";
import useAuthentication from "../../hooks/useAuthentication";
import InputTextComponent from "../InputTextComponent/InputTextComponent";
import { InputText } from "primereact/inputtext";
import { Ubicaciones } from "../../pages";

let IdPauta: number;

export default function FormDialogPauta (props){

    const {showDialog, FormDialog} = useDialog();
    const {
        PermisoVaciarItems,
        setCountProducts,
        getSitios,
        getRutas,
        getCirculos,
        establecerFechaInicioProduct,
        establecerFechaFinProduct,
        establecerDescuento
    } = useContext(ShoppingCartContext);

    const {
		notification,
		showSuccess,
        showWarn,
		showInfo,
        showError,
	} = useNotification();

    const {
        _renderAsyncPautaMultipleDirectDownload: renderAsyncPautaMultipleDirectDownload,
        _renderAsyncPDFUrbanosMultipleDirectDownload: renderAsyncPDFUrbanosMultipleDirectDownload
    } = useJSReportPDF();

    //state del spinner
    const [spin, setSpinner]= useState(true);
    const [oculto, setOculto] =useState(true);

    const [cliente, setcliente] = useState();
    //Se obtiene la fecha actual para valor predeterminado
    const [fecha, setFecha]=useState(moment().format('YYYY-MM-DD'));
    const [tomorrow, setTomorrow]=useState(moment().add(1, 'month').format('YYYY-MM-DD'));
    const [fecha_inicio, setFecha_inicio] = useState(fecha);
    const [fecha_fin, setFecha_fin] = useState(tomorrow);
    const [marca, setmarca] = useState();
    const [ElementoSelCliente,setElementoSelCliente] = useState(null);
    const [colorVista, setcolorVista] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [idCliente, setIdCliente] = useState(0);
    const [ElementoSelResponsable, setElementoSelResponsable] = useState(null);
    const [responsable, setResponsable] = useState();
    const [idResponsable, setIdResponsable]= useState(0);
    const [opcionesClientes, setOpcionesClientes] = useState([]);
    const [ElementoSelCampania, setElementoSelCampania]=useState(null);
    const [campania, setCampania] = useState("");
    const [idCampania, setIdCampania]=useState(0);
    const [opcionesCampania, setOpcionesCampania]=useState([]);
    const [descuentoGlobal, setDescuentoGlobal]=useState(0)

    // Nuevo Flujo
    const sitiosObject = getSitios();
    const [idsPautas, setIdsPautas] = useState([]);
    const [flagPautasEffect, setFlagPautasEffect] = useState(true);
    const [pdfDataPauta, setPdfDataPauta] = useState([]);
    const [isLoadingPDFPauta, setIsLoadingPDFPauta] = useState(false);
    const [PDFDisablePautas, setPDFDisabledPautas] = useState(false);

    const rutasObject = getRutas();
    const [idsRutas, setIdsRutas] = useState([]);
    const [flagRutasEffect, setFlagRutasEffect] = useState(true);
    const [pdfDataRutas, setPdfDataRutas] = useState([]);
    const [isLoadingPDFRutas, setIsLoadingPDFRutas] = useState(false);
    const [PDFDisableRutas, setPDFDisabledRutas] = useState(false);

    const [circuloDataObject, setCirculoDataObject] = useState([]);
    const [tempRutas, setRutasTemp] = useState([]);

    //Almacenamiento local y recuperación de pauta
    const localStorageInfo=getLocalStorage("pautaEdit");
    const [localStorageState, setLocalStorageState]=useState({});
    const [idPautaState, setidPautaState] = useState(0);
    const [rutasDataObjet, setRutasDataObjet] = useState([]);
    const [circulos, setCirculos] = useState<ArrayCirculos>(new ArrayCirculos());

    //universo
    const [universos, setUniversos]=useState()
    const {usuarioid,rol} = useAuthentication();
    //////////////////////////////Llenado de DropDowns////////////////////////////////////////////////////////
    /*Query que carga el Dropdown de Campañas */
    const {loading:loadingCampanias, data:dataCampanias, error:errorCampanias}=useQuery(LISTAR_CAMPANIAS, {
        variables:{
            sort:"nombre:ASC",
            where:{
                "responsable":idResponsable,
                "clientes_gt":0,
                
                _or:[
                    {"estatus": "Cerrado Ganado"},
                    {"estatus": "Cotización personalizada"},
                    {"estatus": "Creada"},
                    {"estatus": "Personalizada"},
                ]
                
            }
        },
        onCompleted:(dataCampanias)=>{
            const opcionesCamp=dataCampanias.campanias
            setOpcionesCampania(opcionesCamp)
            console.log(dataCampanias)
        }
    });
    /*Query que obtiene universos*/
    const {data:dataUniverso}=useQuery(LISTA_UNIVERSO, {
        variables:{
            sort:"ubicacion.nombre:ASC",
            where:{
                "activo":true
            }
        },
        onCompleted(dataUniverso){
            setUniversos(dataUniverso.medios)
        }
    }) 
    /*Query que carga el Dropdown de Clientes*/
    const {loading:loadingClientes, data:dataClientes , error:errorClientes,refetch}= useQuery(LISTAR_CLIENTES, {
        variables:{
            sort:"empresa:ASC",
            where:{
                campanias:idCampania
            }
        },
        onCompleted:(datosClientes)=>{
            
            let tempClientes=[...datosClientes.clientes];
            tempClientes.forEach(cliente => {
                    if (cliente.empresa===null)
                    {
                        cliente.empresa=cliente.nombre;
                    }
                    else{
                        cliente.empresa=cliente.empresa+' - '+cliente.nombre
                    }
            });
            setOpcionesClientes(tempClientes);
        } 
    });
    //where: { _or: [{ name_contains: searchKeyword }, { fk_catgory: { name: searchKeyword } }] } 
    /*Query que carga el Dropdown de Responsables*/

    const {loading:loadingResponsables, data:dataResponsables , error:errorResponsables}= useQuery(LISTAR_RESPONSABLES, {
        variables:{
            sort:"nombre:ASC",
            where:{
                _or:[
                    {"role":7},
                    {"username":"juan.almada@imjmedia.com.mx"},
                    {"username":"jose.almada@imjmedia.com.mx"},
                    {"username":"ramon.ruiz@imjmedia.com.mx"}
                ]    
            }
        }
    });
    const ListarCampanias =()=>{
        if(errorCampanias) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingCampanias) return <IonSpinner name="dots"/>;
        if (dataCampanias) return(
            <Dropdown
                    id="Listaclientes"
                    tabIndex={2}
                    showClear={true}
                    style={{ width:'100%', minWidth: '100px', border: "none" }}
                    value={ElementoSelCampania}
                    options={opcionesCampania}
                    onChange={onSelectCampania}
                    dataKey='id'
                    filter 
                    filterBy="nombre"
                    optionLabel='nombre'
                    placeholder="Selecciona una campaña"
                    required={true} />
        )
    } 
    const ListarClientes =()=>{
        if(errorClientes) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingClientes) return  <IonSpinner name="dots" />;
        if(dataClientes) 
        if(dataClientes.clientes[0]!==undefined){
            const value=dataClientes.clientes[0].nombre + ' - '+dataClientes.clientes[0].empresa 
            //setElementoSelCliente(dataClientes.clientes[0])
        return(
            
            <InputTextComponent
                // id="clientes"
                
                // tabIndex={6}
                style={{ width:'150%', minWidth: '300px', border: "none" }}
                value={JSON.stringify(value)}
                onChange={onselectCliente}
                accept={null}
                className={null}
                disabled={true}
                label={null}
                name={null}
                onBlur={null}
                onKeyPress={null}
                placeholder={null}
                required={null}
                type={'text'}
            />
                //  <Dropdown
                //     id="Listaclientes"
                //     tabIndex={1}
                //     showClear={true}
                //     style={{ width:'100%', minWidth: '100px', border: "none" }}
                //     value={ElementoSelCliente}
                //     options={opcionesClientes}
                //     onChange={onselectCliente}
                //     dataKey='id'
                //     filter 
                //     filterBy="empresa"
                //     optionLabel='empresa'
                //     placeholder="Selecciona un cliente"
                //     required={true} />
        )
        }
    }
    
    const ListarResponsables =()=>{
        if(errorResponsables) return <h5 style={{color:"red"}}>Algo salió mal</h5>; 
        if(loadingResponsables) return <IonSpinner name="dots"></IonSpinner>; 
        if(rol()!=="Comercial"){
            if(dataResponsables) return (
                <Dropdown 
                    id="ListaResponsables"
                    tabIndex={6}
                    showClear={true}
                    style={{width:'100%',border: "none"}}
                    value={ElementoSelResponsable} 
                    options={dataResponsables.users}
                    onChange={onselectResponsable} 
                    dataKey='id'
                    optionLabel='nombre'
                    filter 
                    filterBy="nombre"
                    placeholder='Selecciona un responsable'
                    required={true}
                />
            )   
        }else{
            const comercial=dataResponsables.users
            const resultado = comercial.find( resp => parseInt(resp.id) === usuarioid());
            const res=[];
            res.push(resultado);
            console.log(comercial)
            //setElementoSelResponsable(comercial)
            if(dataResponsables) return(
            <Dropdown
                id="ListaResponsables"
                tabIndex={6}
                showClear={true}
                style={{width:'100%',border: "none"}}
                value={ElementoSelResponsable} 
                options={res}
                onChange={onselectResponsable}
                dataKey='id'
                optionLabel='nombre'
                placeholder='Selecciona un responsable'
                required={true}
            />
            )
        }
        
    } 

    const onselectResponsable = (selectIdR) =>{
        if(selectIdR.value){
            console.log("Responsable", selectIdR.value)
            setElementoSelResponsable(selectIdR.value);
            setResponsable(selectIdR.value.nombre);
            setIdResponsable(parseInt(selectIdR.value.id));
        }else{
            setElementoSelResponsable(null);
            setResponsable(null);
        }
    }
    const onselectCliente = (selectId) =>{
        if(selectId.value){
            console.log("Cliente ENTRÓ",selectId.value)
            setElementoSelCliente(selectId.value);
            setcliente(selectId.value.nombre);
            setImgUrl((selectId.value.logo === null  || selectId.value.logo === undefined ? "" : selectId.value.logo));
            setcolorVista((selectId.value.color === null || selectId.value.color === undefined ? "" : selectId.value.color));
            setIdCliente(parseInt(selectId.value.id));
        }else{
            setElementoSelCliente(null);
            setcliente(null);
        }
    }
    const onSelectCampania=(selectIdCampania)=>{
        if(selectIdCampania.value){
            setElementoSelCampania(selectIdCampania.value);
            setCampania(selectIdCampania.value.nombre);
            setIdCampania(parseInt(selectIdCampania.value.id));

            setElementoSelCliente(selectIdCampania.value.clientes)
            setcliente(selectIdCampania.value.clientes.nombre)
            setIdCliente(parseInt(selectIdCampania.value.clientes.id))
            
        }
        else{
            setElementoSelCampania(null);
            setCampania(null);
        }
    }

    //////////////////////////////////////////Flujo de exportar fichas////////////////////////////////////////////////////
    const [getMediosData] = useLazyQuery(MEDIOS_QUERY_WHERE, {
		onCompleted: (data) => {
            setPdfDataPauta(data);
            setFlagPautasEffect(false);
            setIsLoadingPDFPauta(false);
		}
	});

    const [getRutasData] = useLazyQuery(RUTAS_QUERY_WHERE, {
		onCompleted: (data) => {
            setPdfDataRutas(data);
            setFlagRutasEffect(false);
            setIsLoadingPDFRutas(false);
		}
	});

    /** Effect encargado de obtener la informacion de los circulos para mostrar */
    useEffect(() => {
        // Circulos
        const tempDataCirculos = getCirculos();
        const datos = [];
        const listIds = [];
        tempDataCirculos.forEach(c => {
            c.sitios.forEach(elemnt => datos.push(elemnt));
        });

        if (datos.length > 0) {
            
            datos.forEach(d => listIds.push(d.id));
        }
        if (sitiosObject.length > 0) {
            const datosSitios = [];
            
            sitiosObject.forEach(c => datosSitios.push(c));
            
            if (datosSitios.length > 0) {
                datosSitios.forEach(d => listIds.push(d.id));
            }
        }
        setIdsPautas(listIds);

        setCirculoDataObject(tempDataCirculos);
    }, [getCirculos, sitiosObject]);

    /** Effect encargado de obtener la informacion de los circulos para mostrar  pautas*/
    useEffect(() => {
        // Circulos
        const tempRutas= getRutas();
        setRutasTemp(tempRutas);
        const tempDataCirculos = getCirculos();
        const listIds = [];
        if (tempDataCirculos.length > 0) {
            tempDataCirculos.forEach(circulo => {
                if (circulo.urbanos.length > 0) {
                    circulo.urbanos.forEach(element => {
                        listIds.push(element.id);
                    });
                }
            });
        }

        if (tempRutas.length > 0) {
            tempRutas.forEach(element => {
                
                listIds.push(element.id);
            });
        }
        
        setIdsRutas(listIds);
    }, []);

    // UseEffect para medios
    useEffect(() => {debugger;
        if (idsPautas.length > 0 && flagPautasEffect) {
            setIsLoadingPDFPauta(true);
            getMediosData({
                variables:{
                    where: {
                        id_in: idsPautas
                    }
                }
            });
        }
    }, [flagPautasEffect, getMediosData, idsPautas]);

    // UseEffect para rutas
    useEffect(() => {
        if (idsRutas.length > 0 && flagRutasEffect) {
            
            setIsLoadingPDFRutas(true);
            getRutasData({
                variables:{
                    where: {
                        id_in: idsRutas
                    }
                }
            });
        }
    }, [flagRutasEffect, idsRutas]);

    const renderAsyncPautaDirectDownload = async (data) => {
        // Validacion de nombre
        let fileName = '';
        if (campania === undefined || campania===null || campania==='') {
            // fileName = 'Pautas-IMJ.pdf';
            fileName = 'Sitios-IMJ';
        } else {
            // fileName = 'Pautas-' + campania + '.pdf';
            //if(campania!==undefined){
            fileName = 'Sitios-' + campania.split("-")[0]
            //}
        }
        
        data.medios=data.medios.sort(function(a, b){
            if(a.ubicacion.nombre < b.ubicacion.nombre)
            return -1;
            if(a.ubicacion.nombre > b.ubicacion.nombre)
                return 1
            return 0
        })
        
        
        try {
            const responseRenderAsync = await renderAsyncPautaMultipleDirectDownload(data, fileName, showError);
            if (responseRenderAsync) {
                setPDFDisabledPautas(false);
                showSuccess(MESSAGE_COMPLETE_DOWNLOAD_PDF);
            } else {
                setPDFDisabledPautas(true); // en caso de error y para no saturar el servidor se forzara a desabilitar el servidor
            }
            setIsLoadingPDFPauta(false);
        } catch (error) {
            setIsLoadingPDFPauta(false);
        }
    };

    const renderAsyncUrbanoDirectDownload = async (data) => {
       debugger;
        // Validacion de nombre
        let fileName = '';
        if (campania === undefined || campania === null || campania==='') {
            // fileName = 'Urbanos-IMJ.pdf';
            fileName = 'Urbanos-IMJ';
        } else {
            // fileName = 'Urbanos-' + campania + '.pdf';
            fileName='Urbanos-'+campania.split("-")[0]
        }

        
        try {
            const responseRenderAsync = await renderAsyncPDFUrbanosMultipleDirectDownload(data, fileName, showError);
            if (responseRenderAsync) {
                setPDFDisabledRutas(false);
                showSuccess(MESSAGE_COMPLETE_DOWNLOAD_PDF);
            } else {
                setPDFDisabledRutas(true); // en caso de error y para no saturar el servidor se forzara a desabilitar el servidor
            }
            setIsLoadingPDFRutas(false);
        } catch (error) {
            setIsLoadingPDFRutas(false);
        }
    };
    ////////////////////////////////////////////////////////////////Fin nuevo flujo/////////////////////////////////////////////////////////
    const validacionCreaEdita=()=>{
        if(localStorageInfo){
            actualizarPauta();
        }
        else{
            registrarPauta();
        }
    }

   ///////////////////////////////////////////////////////////////EDICIÓN DE LA PAUTA/////////////////////////////////////////////////////////
   const actualizarPauta = ()=>{
    setSpinner(false)
    setOculto(false)
    updatePauta();
    
    } 

    const [listarCampanias]=useLazyQuery(LISTAR_CAMPANIAS,{
        onCompleted(data){
            setElementoSelCampania(data.campanias[0])
            setCampania(data.campanias[0].nombre);
            setIdCampania(data.campanias[0].id); 
            setElementoSelCliente(data.campanias[0].clientes)
            setcliente(data.campanias[0].clientes.nombre)
            setIdCliente(parseInt(data.campanias[0].clientes.id))
        }, onError(error){
            console.log('error',error)
        }
    })


   useEffect(() => {
        const InfoPauta=(pr)=>{
            console.log("Campaña",pr.datos.campania)
            const clienteR=pr.datos.cliente.id;
            const responsableR=pr.datos.responsable.nombre           
            const fechaIR=pr.datos.fecha_inicio        
            const fechaFR=pr.datos.fecha_fin
            const campaniaR=pr.datos.campania.id; 

            const recuperacampanias=()=>{
                listarCampanias({
                    variables:{
                        where:{
                            id:campaniaR
                        }
                    }
                })
            }
            setFecha_inicio(fechaIR);
            setFecha_fin(fechaFR);
            //Obtiene los datos de responsable
            const users=dataResponsables.users;
            var resultado =  users.filter(function(nombre) {
                return nombre.nombre ===responsableR;
            });
            resultado.forEach(element => {
                const idR=element.id
                const nombre=element.nombre
               let objectToInsert = {
                   id: idR,
                   nombre: nombre
               };
               setElementoSelResponsable(objectToInsert);
               setResponsable(nombre);
               setIdResponsable(idR); 
            });
               //Obtiene datos de cliente
               
            //    const cli=dataClientes.clientes
            //    var res=cli.filter(function(nombre){
            //         return nombre.nombre===clienteR
            //    });
            //    res.forEach(element => {
            //     const idC=element.id
            //     const nombre=element.nombre
            //    let object = {
            //        id: idC,
            //        nombre: nombre
            //    };
            //    setElementoSelCliente(object);
            //    setcliente(nombre);
            //    setIdCliente(idC);
            //});     
                // const cam=dataCampanias.campanias
                // var result=cam.filter(function(nombre){
                //     return nombre.nombre===campaniaR
                // });
                // result.forEach(element=>{
                //     const idCam=element.id
                //     const nombreC=element.nombre
                // let ob={
                //     id: idCam,
                //     nombre:nombreC
                // }
            //     setElementoSelCampania(ob)
            //     setCampania(ob.nombre)
            //     setIdCampania(ob.id)
            // });
            //Obtiene datos de campaña
            recuperacampanias()
        }
            if(!loadingResponsables && !loadingClientes){
            if(localStorageInfo){
                if (Object.keys(localStorageState).length===0) {
                    setLocalStorageState(localStorageInfo);
                    const pr=JSON.parse(localStorageInfo);
                    InfoPauta(pr);
                }
                if(localStorageInfo.localeCompare(localStorageState)!==0)
                {
                    setLocalStorageState(localStorageInfo);
                    const pr=JSON.parse(localStorageInfo);
                    InfoPauta(pr);
                }
            }  
        } else {
    
        }
    }, [ElementoSelCliente, ElementoSelResponsable, dataClientes, dataResponsables, loadingClientes, loadingResponsables, localStorageInfo, localStorageState]);
    
    useEffect (()=>{
        if(localStorageInfo)
        {
            setidPautaState(JSON.parse(localStorageInfo).datos.id);
        }
    },[localStorageInfo])
    
    const [updatePauta] =useMutation(UPDATE_PAUTA,{
        variables:{
            input:{
                where: {id:idPautaState},
                data:{
                    cliente: cliente,
                    nombre_campania: campania,
                    fecha_inicio: fecha_inicio,
                    fecha_fin: fecha_fin,
                    responsable: idResponsable,
                    estatus: "Creada",
                    //createdby: props.datos.createdby,
                    modifedby: props.datos.modifiedby,
                    campania: idCampania
                }
            }
        }, onCompleted(data){
            console.log("data pauta  ",data)
            setidPautaState(idPautaState);
            //actualizarCampania(idPautaState, idCampania);
            getDetails(idPautaState);
            updateCliente();
            localStorage.removeItem("pautaEdit"); //Para limpiar el local storage sólo de la llave de pauta
            localStorage.removeItem("shop")
            VaciaVariablesFormulario();
            //spinners
            setSpinner(true)
            setOculto(true)

            props.onSuccess();

        }
    });

    //A partir de esta función, hay un borrado de los detalles anteriores. 
    const getDetails=(idPautaState)=>{
        //Se buscan todos los detalles con ese id de pauta
        buscarDetalles({
            variables:{
                where:{
                    pauta:idPautaState
                }
            }
        })
    }

    const [buscarDetalles]=useLazyQuery(BUSCAR_DETALLES,{
        onCompleted(data){
            //Se borran los círculos para que no queden huérfanos
            data.detailpautas.forEach(detalle => {
                if (detalle.circulospauta){
                    if (detalle.circulospauta.id){
                        deleteCirculospauta({variables:{input:{where:{id:detalle.circulospauta.id}}}})
                    }
                } 
            });
            //Se recorre en un arreglo los id de pauta
            data.detailpautas.forEach(detalle => {
                let idDP=detalle.id;
                 deleteDetailpauta({variables:{input:{where:{id:idDP}}}})
            });
            RegistrarDetailPauta(idPautaState);
            registrarProductos();

        }, onError(error){
            console.log('error',error)
        }
    })

    const [deleteCirculospauta]=useMutation(DELETE_CIRCULO,{
        onCompleted(data){
            console.log("círculob orrado con éxito")
        }
    })

    const [deleteDetailpauta] =useMutation(DELETE_DETAILS,{    
         onCompleted(data){
            console.log("Borrado exitoso");
        }
    });

    
    const RegistrarDetailPauta = (IdPauta)=>{
        //let sumaArrays = parseInt(getSitios().length) + parseInt(getRutas().length)+parseInt(getCirculos().length)+parseInt(getCirculos().length);
        let sumaArrays = parseInt(getSitios().length) + parseInt(getRutas().length)+parseInt(getCirculos().length);
        for (let index = 1; index <= sumaArrays; index++) {
            if(sumaArrays === index){
                setTimeout(() => {
                    props.onSuccess();  
                }, 3000);   
            }
        }
        /*Sitios y rutas individuales*/ 
        if(getSitios().length > 0){
            getSitios().forEach(element => {
                createDetailPauta({
                    variables:{
                        input:{
                            data:{
                                producto: parseInt(element.id),
                                cantidad: 1,
                                descuento:parseFloat(element.descuento),
                                fecha_inicio: element.fecha_inicio,
                                fecha_fin: element.fecha_fin,
                                pauta: IdPauta,
                                medio: parseInt(element.id),
                                createdby: props.datos.createdby,
                                modifiedby: props.datos.modifiedby,
                            }
                        }
                    }
                }) 
            });
        }
        if(getRutas().length > 0){
            getRutas().forEach(element => {
                createDetailPauta({
                    variables:{
                        input:{
                            data:{
                                producto: parseInt(element.id),
                                cantidad: parseInt(element.cantidad),
                                fecha_inicio: element.fecha_inicio,
                                fecha_fin: element.fecha_fin,
                                pauta: IdPauta,
                                ruta: parseInt(element.id),
                                descuento:parseFloat(element.descuento),
                                createdby: props.datos.createdby,
                                modifiedby: props.datos.modifiedby,
                            }
                        }
                    }
                }); 
            });
        }
        /*Fin de Sitios y rutas individuales*/ 
        //For de círculos para obtener etiqueta y almacenar información
        if(getCirculos().length > 0){
            const circuloTemp = [];
            getCirculos().forEach(c => {
                crearCirculos(c);
                circuloTemp.push(c);
                const rutaToSend = [];
                const sitiosToSend = [];
                rutaToSend.push(c.urbanos);
                sitiosToSend.push(c.sitios);
                setRutasState(rutaToSend[0]);
                setSitiosState(sitiosToSend[0]);
            });
            setCircState(circuloTemp);
        }
    }
    
    //////////////////////////////////////////////////////////////////////////////Crear Pauta////////////////////////////////////
    const { 
        handleSubmit,
    } = useForm({
		defaultValues: {
            nombre_campania : campania,
            cliente  : cliente,
            fecha_inicio :fecha_inicio,
            fecha_fin  :fecha_fin,
            responsable: idResponsable,
            marca : marca
		}
    });

    /**
     * Funcion encarga de vaciar los datos del formualrio
     */
    const VaciaVariablesFormulario =()=>{
        setIdCliente(0)
        setcliente(null);
        setElementoSelCliente(null);
        setFecha_fin("");
        setFecha_inicio("");
        setmarca(null);
        setCampania(null);
        setElementoSelCampania(null);
        setResponsable(null);
        setElementoSelResponsable(null);
        setFecha("");
        setTomorrow("");
        setCirculos(new ArrayCirculos());
        const circuloData = [];
        setCirculoDataObject(circuloData);  
        setRutasTemp(tempRutas);
    }

    const registrarPauta = ()=> { 
        //Se valida que la campaña sea nula para no duplicar la pauta
        buscarPauta(idCampania);
          
    }

    //busca que la campaña no esté asignada a una pauta
    const buscarPauta=(idCampania)=>{
        listaPautas({
            variables:{
                where:{
                    campania:idCampania
                }
                
            }
        })        

    }
    const [listaPautas] = useLazyQuery(LISTA_PAUTAS,{
        onCompleted(data){
            console.log(data);
            if(data.pautas.length!==0){
                showWarn("Advertencia", "La pauta ya tiene campaña asignada. Ir a Listado de pautas")
            }
            else {
                createPauta();
            }
            
        }
    })
   //Crea pauta
    const [createPauta] = useMutation(REGISTRAR_PAUTA,{
        variables:{
            input:{
                data:{
                    cliente: cliente,
                    nombre_campania: campania,
                    fecha_inicio: fecha_inicio,
                    fecha_fin: fecha_fin,
                    responsable: idResponsable,
                    estatus: "Creada",
                    createdby: props.datos.createdby,
                    modifedby: props.datos.modifiedby,
                    linkkey: generateId(20),
                    campania: idCampania
                }
            }
        },onError(error){
            console.log(error)
        },onCompleted(data){
            IdPauta=parseInt(data.createPauta.pauta.id);
            setidPautaState(IdPauta);
            //actualizarCampania(IdPauta, idCampania);
            RegistrarDetailPauta(IdPauta);
            localStorage.removeItem("shop")
            registrarProductos();
            VaciaVariablesFormulario();
            updateCliente();
            
        }
    });

    //Función que crea el círculo
     const [createCirculospauta] = useMutation(REGISTRAR_CIRCULO,{
        onCompleted(data){
            const IdCirculo=parseInt(data.createCirculospauta.circulospauta.id);
            crearDetalles(IdCirculo, rutasState, sitiosState )
        },onError(error){
            console.log(error);
        }
     });

    const [createDetailPauta] = useMutation(REGISTRAR_DETALLE_PAUTA,{
        onCompleted(data){
			
		}
    });

    const [updateCliente] = useMutation(UPDATE_CLIENTE, {
		variables: {
			input: {
				where: {id:idCliente },
				data: {
                    color:colorVista,
                    logo:imgUrl
                }
				
			}
		},
		onCompleted(data){
            props.onSuccess();
            setImgUrl("");
            setcolorVista("");
            setIdCliente(0);
            refetch();
		}
    });

    const registrarProductos = ()=>{
        let sumaToArrays =0;
        for (let index = 1; index <= sumaToArrays; index++) {
            if(sumaToArrays === index){
                
                setIdCliente(0);
                setTimeout(() => {
                    props.onSuccess();  
                }, 3000);
                setIdResponsable(0);   
            }   
        }
    }    

    const [sitiosState, setSitiosState] = useState([]);
    const [rutasState, setRutasState] = useState([]);
    const [circState, setCircState] = useState([]);
   
    const crearCirculos = (circulo) => {
        createCirculospauta({
            variables:{
                input:{
                    data:{
                        etiqueta:circulo.etiqueta ? circulo.etiqueta : "Zona sin etiqueta",
                        lat:circulo.latitud,
                        lng:circulo.longitud,
                        radio:circulo.radio,
                        createdby: props.datos.createdby,
                        modifiedby: props.datos.modifiedby
                    }
                }
            }
        });
    }

    const crearDetalles = (IdCirculo, rutaToSend, sitiosToSend) => {
        if(rutaToSend.length>0){
            for (let i=0; i <rutaToSend.length; i++){
                const ruta=rutaToSend[i];
                createDetailPauta({
                    variables:{
                        input:{
                            data:{
                                producto: parseInt(ruta.id),
                                cantidad: parseInt(ruta.cantidad),
                                fecha_inicio: ruta.fecha_inicio,
                                fecha_fin: ruta.fecha_fin,
                                pauta: idPautaState,
                                ruta: parseInt(ruta.id),
                                descuento:parseFloat(ruta.descuento),
                                createdby: props.datos.createdby,
                                modifiedby: props.datos.modifiedby,
                                circulospauta: IdCirculo
                            }
                        }
                    }
                });  
            }
        }
        //ciclo de sitios
        if (sitiosToSend.length>0){
            for (let j=0; j <sitiosToSend.length; j++){
                const sitio=sitiosToSend[j];
                createDetailPauta({
                    variables:{
                        input:{
                            data:{
                                producto: parseInt(sitio.id),
                                descuento:parseFloat(sitio.descuento),
                                fecha_inicio: sitio.fecha_inicio,
                                fecha_fin: sitio.fecha_fin,
                                pauta: idPautaState,
                                medio: parseInt(sitio.id),
                                createdby: props.datos.createdby,
                                modifiedby: props.datos.modifiedby,
                                circulospauta: IdCirculo
                            }
                        }
                    }
                }) 
            }
        }
    }

    /******************************************Toolbar***********************************************************/
    // IMPORT KML
    const [refresh, setRefresh] = useState(false);
    /**
     * Funcion utilizada para abrir un modal que servira para subir el archivo que se desea importar
     */
    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

    /**
     * Funcion utilizada para cerrar un modal que imporat el KML
     */
	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}

    const openModal = () => {
        
        showDialog("Importar KML");
    };

     /**
     * funcion que recibe el parametro color
     * @param color que contiene en formato de letras y numeros 
     * el color elegido por el usuario, realiza un setter a la 
     * @const colorVista
     */
	const establecer_color = (color) =>{
		setcolorVista(color);
    }
    
    /**
     * funcion comversion de imagen que recibe como parametro
     * @param event contiene os datos de la imagen seleccionada del usuario
     * y realiza la comversion de la u¿imagen a base 64
     */
    const comversioImagen =async(event)=>{
        const file = event.target.files[0];
		const image = await Imgfile64(file);
        setImg(image);
		
    }
   
     /*  * @param imagen recibe como parametro la imagen ya comvertida a base 64
     * se realiza un setter a la @const imgUrl*/
     
    const setImg=(imagen)=>{
        setImgUrl(imagen);
    }
    
    /**
     * funcion que muestra la imagen , si @const imgUrl la variable no esta vacia 
     * de lo contrario la funcion retorna un mesaje al usuario
     */
    const showImg = ()=>{
        if(imgUrl !== ""){
            return( 
                <div>
                    <img src={imgUrl} style={{display:"block",
				    margin:"auto"}} width="50%" height="150" alt="Imagen de campaña"></img>
                </div>
            );
        }else{
            return (
                <div>
                    <br></br>
                    <br></br>
                    <h5 style={{textAlign:'center'}}>Sin imagen</h5>
                </div>
			)
        }
    }
    
    /**
     * Funcion que muestra el color elegido por el usuario.
     */
    const mostrarColor =()=>{
       if(colorVista !== ""){
        return(
            <div style={{width:"50%",height:"85%",backgroundColor:"#"+colorVista,alignContent:"center",alignItems:"center"}}>
                                
            </div>
        )
       }else{
           return(
            <div>
                <br></br>
                <br></br>
                <h5 style={{textAlign:'left'}}>No ha elegido un color</h5>
            </div>
           )
       } 
    }


    /* Botones del ToolBar */
    const ToolbarButtons = ()=>{ 
        return (
            <>
                <Button disabled={getSitios().length === 0 && getRutas().length===0 && getCirculos().length===0 } label="KML" icon="pi pi-download" className="p-button-raised p-button-rounded" onClick={exportKML}/>
                &nbsp;
                &nbsp;
                {/* <Button label="IMPORTAR KML" icon="pi pi-download" className="p-button-raised p-button-rounded" onClick={openModal}/>
                &nbsp;
                &nbsp; */}
                <Button disabled={getSitios().length === 0 && getRutas().length===0 && getCirculos().length===0} label="Excel Pauta" icon="pi pi-file-excel" onClick={ExcelPauta} float-right="true" className="p-button-raised p-button-rounded p-button-success" type="button" />
                &nbsp;
                &nbsp;
                <Button disabled={getSitios().length === 0 && getRutas().length===0 && getCirculos().length===0} label="Excel Medios" icon="pi pi-file-excel" onClick={ExcelDetail} float-right="true" className="p-button-raised p-button-rounded p-button-warning" />
                &nbsp;
                &nbsp;
                <Button disabled={getSitios().length === 0 && getRutas().length===0 && getCirculos().length===0} label="PDF Pauta" icon="pi pi-file-pdf" onClick={PDF} float-right="true" className="p-button-raised p-button-rounded p-button-danger"/>
                &nbsp;
                &nbsp;
                <Button disabled={pdfDataRutas.length === 0 || PDFDisableRutas} label={"PDF Rutas Urbanos"} icon="pi pi-file-pdf" onClick={pdfEventUrbanos} float-right="true" className="p-button-raised p-button-rounded p-button-help"  />
                {
                    isLoadingPDFRutas ? (
                        <div style={{marginLeft: 20}}>
                            <ReactLoading height={15} width={15} color={'dbc9fd'} type={'spin'} />
                        </div>
                    ) : null
                }
                &nbsp;
                &nbsp;
                <Button disabled={pdfDataPauta.length === 0 || PDFDisablePautas} label={"PDF Medios"} icon="pi pi-file-pdf" onClick={pdfEvent} float-right="true" className="p-button-raised p-button-rounded p-button-help"  />
                {
                    isLoadingPDFPauta ? (
                        <div style={{marginLeft: 20}}>
                            <ReactLoading height={15} width={15} color={'dbc9fd'} type={'spin'} />
                        </div>
                    ) : null
                }
                &nbsp;
                &nbsp;
                <Button disabled={getRutas().length===0 && getCirculos().length===0} label="Excel Rutas" icon="pi pi-file-excel" className="p-button-raised p-button-rounded p-button-success" onClick={exportExcelRutas} type="button"/>
            </>
        )
    };

    // generateId :: Integer -> String
    function generateId (len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, dec2hex).join('');
    }
    
    function dec2hex (dec) {
        return dec < 10
          ? '0' + String(dec)
          : dec.toString(16)
    }

    const cancelar = () => {
        
        props.onDiscard();
        VaciaVariablesFormulario();
        setIdCliente(0);
        setIdResponsable(0);
        setCountProducts(0);
        setCountProducts();
        PermisoVaciarItems();
        const circuloData = [];
        setCirculoDataObject(circuloData);
        setCirculos(new ArrayCirculos());
        setRutasTemp(tempRutas);
        localStorage.removeItem("pautaEdit");
    }
    
    /////////////////////////////////////////////LLENADO DE ARREGLOS/////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////EXCEL COTIZADOR////////////////////////////////////////////////////////////////////////
    let dataEXcel = [];
    const AddDataToExcel =()=>{
        
        //for de círculos
        getCirculos().forEach(circulos => {
            var rutas=circulos.urbanos
            var sitios=circulos.sitios
            var etiqueta=circulos.etiqueta
            rutas.forEach(ruta=>{
                dataEXcel.push({
                    zona:etiqueta,
                    tipo:"Urbanos "+ruta.tipoexhib,
                    producto:ruta.nombre,
                    cantidad:ruta.cantidad,
                    fecha_inicio:ruta.fecha_inicio,
                    fecha_fin:ruta.fecha_fin,
                    descripcion:'Inicia: '+ruta.inicio+ ' - Termina: ' + ruta.fin,
                    precio:ruta.preciourbano,
                    descuento:ruta.descuento
                })
            })
            sitios.forEach(sitio => {
                dataEXcel.push({
                    zona:etiqueta,
                    tipo:sitio.tipos,
                    producto:sitio.clave,
                    cantidad:1,
                    fecha_inicio:sitio.fecha_inicio,
                    fecha_fin:sitio.fecha_fin,
                    descripcion:sitio.direccion,
                    precio:sitio.precioventa,
                    descuento:sitio.descuento
                })
                
            });
        });
        //for para ítems sin círculo
        for (let index = 0; index < getSitios().length; index++) {
            const element = getSitios()[index];
            dataEXcel.push({
                zona:"",
                tipo:element.tipos,
                producto:element.clave,
                cantidad: 1,
                fecha_inicio:element.fecha_inicio,
                fecha_fin:element.fecha_fin,
                descripcion:element.direccion,
                precio:element.precioventa,
                descuento:element.descuento
            });  
        }

        for (let index = 0; index < getRutas().length; index++) {
            const element = getRutas()[index];
            dataEXcel.push({
                zona:"",
                tipo:"Urbanos "+element.tipoexhib,
                producto:element.nombre,
                cantidad:element.cantidad,
                fecha_inicio:element.fecha_inicio,
                fecha_fin:element.fecha_fin,
                descripcion:'Inicia: '+element.inicio+ ' - Termina: ' + element.fin,
                precio:element.preciourbano,
                descuento:element.descuento,
                
            });
        }
    }
    /////////////////////////////////////////FIN EXCEL COTIZADOR///////////
    /////////////////////////////EXCEL MEDIOS/////////////////////////////
    let dataDetails = [];
    let derroteroDetails=[];
    const AddDetails =()=>{
        //Círculos
        getCirculos().forEach(circulos => {
            var rutas=circulos.urbanos
            var sitios=circulos.sitios
            rutas.forEach(ruta=>{
                derroteroDetails.push({
                disponible:"N/A",
                clave:ruta.nombre,
                ubicacion:ruta.ubicacion,
                inicio:ruta.inicio,
                fin:ruta.fin,
                direccion:ruta.descripcion,
                vista:"N/A",
                iluminacion:"N/A",
                medida:"N/A",
                tipos:ruta.tipoexhib,
                comentarios:"N/A",
                referencias:"N/A",
                latitud:"N/A",
                longitud:"N/A",
                precioventa:ruta.preciourbano,
                impactos:ruta.impactos,
                alcance: ruta.alcance,
                total_hits:ruta.total_hits,
                frecuencia:ruta.frecuencia,
                kilometraje:ruta.kilometraje,
                maleU:ruta.maleU,
                femaleU:ruta.femaleU
                })
            })
            sitios.forEach(sitio => {
                dataDetails.push({
                disponible:sitio.disponible,
                clave:sitio.clave,
                ubicacion:sitio.ubicacion,
                inicio:"N/A",
                fin:"N/A",
                direccion:sitio.direccion,
                vista:sitio.vista,
                iluminacion:sitio.iluminacion,
                medida:sitio.medida,
                tipos:sitio.tipos,
                comentarios:sitio.comentarios,
                referencias:sitio.referencias,
                latitud:sitio.lat,
                longitud:sitio.lng,
                precioventa:sitio.precioventa,
                impactos:sitio.impactos,
                alcance:sitio.alcance,
                total_hits:sitio.total_hits,
                frecuencia:sitio.frecuencia,
                kilometraje:"N/A",
                maleU:sitio.maleU,
                femaleU:sitio.femaleU,
                income_a_users:sitio.income_a_users,
                income_b_users:sitio.income_b_users,
                income_c_users:sitio.income_c_users,
                income_d_users:sitio.income_d_users,
                income_e_users:sitio.income_e_users,
                a13_17_users:sitio.a13_17_users,
                a18_25_users:sitio.a18_25_users,
                a26_40_users:sitio.a26_40_users,
                a41_55_users:sitio.a41_55_users,
                a55_users:sitio.a55_users,
                universo:sitio.universo
                })
            });
        })
        //Sitios
        for (let index = 0; index < getSitios().length; index++){
            const element = getSitios()[index];
            dataDetails.push({
                disponible:element.disponible,
                clave:element.clave,
                ubicacion:element.ubicacion,
                inicio:"N/A",
                fin:"N/A",
                direccion:element.direccion,
                vista:element.vista,
                iluminacion:element.iluminacion,
                medida:element.medida,
                tipos:element.tipos,
                comentarios:element.comentarios,
                referencias:element.referencias,
                latitud:element.lat,
                longitud:element.lng,
                precioventa:element.precioventa,
                impactos:element.impactos,
                alcance:element.alcance,
                total_hits:element.total_hits,
                frecuencia:element.frecuencia,
                kilometraje:"N/A",
                maleU:element.maleU,
                femaleU:element.femaleU,
                income_a_users:element.income_a_users,
                income_b_users:element.income_b_users,
                income_c_users:element.income_c_users,
                income_d_users:element.income_d_users,
                income_e_users:element.income_e_users,
                a13_17_users:element.a13_17_users,
                a18_25_users:element.a18_25_users,
                a26_40_users:element.a26_40_users,
                a41_55_users:element.a41_55_users,
                a55_users:element.a55_users,
                universo:element.universo
            });
            
        }
        for (let index = 0; index < getRutas().length; index++){
            const element = getRutas()[index];
            derroteroDetails.push({
                disponible:"N/A",
                clave:element.nombre,
                ubicacion:element.ubicacion,
                inicio:element.inicio,
                fin:element.fin,
                direccion:element.descripcion,
                vista:"N/A",
                iluminacion:"N/A",
                medida:"N/A",
                tipos:element.tipoexhib,
                comentarios:"N/A",
                referencias:"N/A",
                latitud:"N/A",
                longitud:"N/A",
                precioventa:element.preciourbano,
                impactos:element.impactos,
                alcance: element.alcance,
                total_hits:element.total_hits,
                frecuencia:element.frecuencia,
                kilometraje:element.kilometraje,
                maleU:element.maleU,
                femaleU:element.femaleU
            });
        } 
        
    }
    /////////////////////////////////////////FIN DETALLES DE MEDIOS/////////
    ///////////////////////////////////FUNCIONES PARA EXPORTAR///////////////////////////////////////
    ///////////////////////////////////PDF MEDIOS///////////////////////////////////////////////////
    const onlyUnique = (value, index, self) => { 
        return self.indexOf(value) === index;
    }

    const pdfEvent = () => {debugger;
        showInfo(MESSAGE_INFO_DONWLOAD_PDF);
        setIsLoadingPDFPauta(true);
        setPDFDisabledPautas(true);
        renderAsyncPautaDirectDownload(pdfDataPauta);
    };

    const pdfEventUrbanos = () => {
        showInfo(MESSAGE_INFO_DONWLOAD_PDF);
        setIsLoadingPDFRutas(true);
        setPDFDisabledRutas(true);
        renderAsyncUrbanoDirectDownload(pdfDataRutas);
    };
    ///////////////////////////////////fin pdf medios////////////////////////////////////////
    const PDF=()=>{
        exportPDF(dataEXcel, imgUrl, campania, colorVista, fecha_fin, fecha_inicio, cliente, responsable)
    }
    //////////////////////////////KML//////////////////////////////////////////////////////  
    const exportKML = () =>{     
        let kml = new KMLExport();
        let arregloSitios=[...getSitios()];
        getCirculos().forEach((circulo)=>{
            var cs=circulo.sitios;
            cs.forEach(element=>{
                arregloSitios.push(element);
            })
        })
        let arregloRutas=[...getRutas()]
        getCirculos().forEach((circulo)=>{
            var cu=circulo.urbanos;
            cu.forEach(element=>{
                arregloRutas.push(element);
            })
        })
        kml.sitios(arregloSitios);
        kml.rutasPauta(arregloRutas);
        var blob = new Blob([kml.stringify()], { type: "text/plain;charset=utf-8" });
        saveAs(blob, 'Pautas_Export_'+ new Date().getTime()+'.kml');
    }
    //////////////////////////////FIN KML///////////////////////////////////////////////
    //////////////////////EXCEL PAUTA////////////////////////////////////////////////////
    const ExcelPauta=()=>{
        exportExcel(dataEXcel, cliente, campania, responsable)
    }
    ////////////////////////////FIN EXCEL PAUTA/////////////////////////////////////////
    /////////////////////////////EXCEL MEDIOS//////////////////////////////////////////
    const ExcelDetail=()=>{
        var plazas=[]
        derroteroDetails.forEach(element=>{
            plazas.push(element.ubicacion)
        })
        var uniqs = plazas.filter(function(item, index, array) {
            return array.indexOf(item) === index;
        })
        let universodup=[]
        if(dataUniverso){
            dataUniverso.medios.forEach(element=>{
                for(var i=0; i<uniqs.length; i++)
                {
                    if(element.ubicacion.nombre===uniqs[i])
                    {
                        var x={
                            ubicacion: element.ubicacion.nombre,
                            universo: element.universo
                        }
                        universodup.push(x)
                    }
                }
            })
            let hash = {};
            universodup = universodup.filter(o => hash[o.ubicacion] ? false : hash[o.ubicacion] = true);
        }
        
        exportExcelDetail(dataDetails, derroteroDetails, campania, universodup)
    }
    /////////////////////////////////FIN EXCEL MEDIOS////////////////////////////////////////////////////////
    ////////////////////////////////EXCEL RUTAS/////////////////////////////////////////////////////////////
    const exportExcelRutas=()=>{
        excelRutas(derroteroDetails)
    }
    ////////////////////////////////////////FIN EXCEL RUTAS
    //////////////////////////////////RENDER DE VISUALIZA TU PAUTA///////////////////////////////////////////
    return(
        <IonGrid>
            {notification}
            <div style={{width: '500px', height: '500px', display: 'none'}} id='placeholder'></div>
            <Toolbar right={ToolbarButtons}></Toolbar>
            <form  onSubmit={
                    handleSubmit(validacionCreaEdita)
                }>
                
                { AddDetails()}
                <IonCard style={{ overflow: "visible"}} >
                    <IonCardContent>
                    <IonRow>
                        <IonCol size-md='12' size-sm='12' size='3' size-lg='3' size-xl='3'>
                            <br></br>
                            <IonLabel position="stacked">
                              <h2>Responsable</h2>  
                            </IonLabel>
                            {ListarResponsables()}
                        </IonCol>
                        <IonCol size-md='6' size-sm='6' size='4' size-lg='3' size-xl='4'>
                            <br></br>
                            <IonLabel position="stacked">
                                <h2>Campaña</h2>
                            </IonLabel>
                            {ListarCampanias()}
                        </IonCol>
                        <IonCol size-md="6" size-sm='6' size-lg='2' size-xl='2'>
                            <br></br>
                            <IonLabel><h2>Fecha inicio</h2></IonLabel>
                            <InputTextComponent
                                name={"fecha_inicio"}
                                type={"date"}
                                value={fecha_inicio}
                                style={{width:'100%',borderRadius: "25px"}}
                                required={true}
                                onChange={e =>{
                                    setFecha_inicio(e);
                                    establecerFechaInicioProduct(e);
                                }}
                                className={null}
                                disabled={null}
                                onKeyPress={null}
                                placeholder={null}
                                label={null}
                                accept={null}
                                onBlur={null}
                            />
                        </IonCol>
                        <IonCol size-md='6' size-sm='6' size-lg='2' size-xl='2'>
                            <br></br>
                            <IonLabel><h2>Fecha fin</h2></IonLabel>
                            <InputTextComponent
                                name={"fecha_fin"}
                                type={"date"}
                                value={fecha_fin}
                                style={{width:'100%',borderRadius: "25px"}}
                                required={true}
                                accept={null}
                                onChange={e =>{
                                    setFecha_fin(e);
                                    establecerFechaFinProduct(e);
                                }}
                                className={null}
                                disabled={null}
                                onKeyPress={null}
                                placeholder={null}
                                label={null}
                                onBlur={null}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-md='6' size-sm='6' size='4' size-lg='2' size-xl='3'>
                            <br></br>
                            <IonLabel position="fixed">
                              <h2>Empresa</h2>  
                            </IonLabel>
                            {ListarClientes() }
                        </IonCol>
                        <IonCol>
                            <br></br>
                            <IonLabel position="fixed">
                                <h2>Descuento</h2>
                            </IonLabel>
                            <InputText 
                            style={{width:'25%',borderRadius: "25px"}}
                            type="number"
                            step="1"
                            max="40"
                            min="0"
                           value={descuentoGlobal}
                            onChange={(e)=>{
                                setDescuentoGlobal(parseInt(e.target.value));
                                console.log(e.target.value)
                                establecerDescuento(e.target.value);
                            }}
                            />
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            <IonLabel>%</IonLabel>
                        </IonCol>
                    </IonRow>
                    <br></br>
                    <IonRow >
                        <IonCol style={{display:(idCliente === 0 ? "none" : "inline"),textAlign:'center'}} size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
                        <label htmlFor="in">Color</label>
                        <br></br>
                        <ColorPicker  value={colorVista} 
                            onChange={(e) => establecer_color(e.value)}
                            inline={true}
                        ></ColorPicker>
                        </IonCol>
                        <IonCol style={{display:(idCliente === 0 ? "none" : "inline"),textAlign:'left'}} size="12" size-Xs="12" sizeMd="6" sizeLg="3" sizeXl="3" >
                            <label>Color elegido</label>
                            {mostrarColor()}
                        </IonCol>
                        <IonCol style={{display:(idCliente === 0 ? "none" : "inline"),textAlign:'center'}} size="12" size-Xs="12" sizeMd="6" sizeLg="5" sizeXl="5">
                            <label style={{textAlign:'left'}}>Logo</label>
                            <br></br>
                            <input 
                                tabIndex={2}
                                name="logo" 
                                style={{width:'50%',border:"1px solid black"}}
                                type="file"
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={event => comversioImagen(event)}
                            />
                            {showImg()}
                        </IonCol>
				    </IonRow>
                    <br></br>
                    <br></br>
                    </IonCardContent>
                </IonCard>
                <br></br>
                <br></br>
                {/*cartCirculo()*/}
                {/* <DetalleCirculo circulos={getCirculos()} /> */}
                {circuloDataObject.length > 0 ? (<DetalleCirculo circulos={circuloDataObject} />) : null}
                <br></br>
                <br></br>
                <DetalleSitios sitios={getSitios()} />
                <br></br>
                <br></br>
                {/* <DetalleRutas style={{display:(getRutas().length === 0 ? "none" : "block")}} rutas={getRutas()} /> */}
                {tempRutas.length > 0 ? (<DetalleRutas rutas={tempRutas}/>) : null}
                <br></br>
                <br></br>
                <IonGrid>

                    <IonRow className={"ion-justify-content-center"}>
                        <IonCol className={"ion-text-center"} size="12">
                            <Button 
                                label="Guardar Pauta"
                                icon="pi  pi-save"
                                className="p-button-raised p-button-rounded"
                                type="submit"
                            />
                            <Button 
                                label="Vaciar Pauta"
                                icon="pi pi-times"
                                className="p-button-raised p-button-rounded p-button-danger"
                                onClick={()=>cancelar()}
                            />
                             <IonSpinner hidden={oculto} name="crescent" paused={spin}/>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                { AddDataToExcel()}
            </form>
          
        </IonGrid>
    )  
}