import {IMJ_COLORS} from './Constanst';
import axios from "axios"

export const randomInteger = function (pow) {
  return Math.floor(Math.random() * pow);
};

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

// Encuentra los elementos de A que no están en B
export const findDiff = (A, B, field) => {
  return A.filter(i => !B.some((item) => item[field] === i[field]))
}

/**
 * Funcion que retorna un color aleatorio
 * @returns {string}
 */
 export const generateRandomColor = () => {
  var randomColor = '#'+Math.floor(Math.random()*16777215).toString(16);
  return randomColor;
}

/**
 * Funcion que retorna un color aleatorio
 * @returns {string}
 */
 export const generateRandomColorFromIMJColors = (colors_param) => {
  const colores = colors_param.length > 0 ? colors_param : [];
  const validColors = [];
  
  const nuevoArray = IMJ_COLORS.filter(i => !colores.includes(i))
  IMJ_COLORS.forEach(i => {
    const includes = !colores.includes(i);
    console.log('INCLUDES', includes);
    if (includes) {
      validColors.push(i);
    }
  });
  console.log('validColors', validColors);
  const randomNumber = Math.floor(Math.random() * validColors.length);
  // const randomColor = IMJ_COLORS[randomNumber];
  const randomColor = nuevoArray[randomNumber];
  return randomColor;
}

export const generateOrderColorFromIMJColors = (index) => {
  return IMJ_COLORS[index];
};

/**
 * 
 * @param {[]} POI_JSON
 * @param {string} value
 */
export const checkCategorPOI = (POI_JSON, value) => {
  // const response = POI_JSON.filter
};

//LOCALSTORAGE
/**
 * Funcion encargada de Setear un objeto de tipo JSON
 * a una objeto almacenado en el local storage de la app
 * definido por una llave de tipo string.
 * @param {string} key 
 * @param {any} data 
 */
export const setLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

/**
 * Funcion encargada de obtener la informacion de un objeto
 * almanecado en el localStorage por su llave
 * @param {string} key 
 * @returns JSON
 */
export const getLocalStorage = (key) => {
  const response = localStorage.getItem(key);
  return JSON.parse(response);
}
/**
 * Funcion encargada de eliminar toda la data en el localStorage
 */
export const clearLocalStorage = () => {
  localStorage.clear();
}

/**
 * Funcion que limpia un espacio en el LocalStorage
 * en base a la llave que se envia como parametro
 * @param {string} key 
 */
export const clearLocalStorageByKey = (key) => {
  localStorage.removeItem(key);
}

/**
 * Funcion que espera a que se termina una condicion para volver a ejecutarse
 * @param {() => void} func 
 * @param {number} timeout 
 * @returns 
 */
export const debounce = (func, timeout = 300) => {
  console.log('Estoy en debounce', func);
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

/**
 * Función que genera un password aleatorio dada una longitud y un tipo siendo 'num' de sólo números, 'alf' de sólo letras y 'rand' de todo
 */
 export const generatePasswordRand=(length,type)=>{
  let characters
  switch(type){
      case 'num':
          characters = "0123456789";
          break;
      case 'alf':
          characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
          break;
      case 'rand':
          //FOR ↓
          break;
      default:
          characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
          break;
  }
  var pass = "";
  for (let i=0; i < length; i++){
      if(type === 'rand'){
          pass += String.fromCharCode((Math.floor((Math.random() * 100)) % 94) + 33);
      }else{
          pass += characters.charAt(Math.floor(Math.random()*characters.length));   
      }
  }
  return pass;
}

/**
 * Funcion que retorna un objeto JSON con la llave cambiada
 * 
 */
export const renameKey = ( obj, oldKey, newKey ) => {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
  return obj;
}

/**
 * Función que devuelve si un objeto está vacío o no (true/false)
 * @param {*} obj 
 * @returns 
 */
export function isEmptyObject(obj) {
  return !Object.keys(obj).length;
}

/**
 * Función que carga una imagen mediante el plugin Upload y axios a una tabla determinada
 * Esta función se debe colocar después del Insert del registro, ya que funciona de forma asíncrona y requiere datos como el id del registro
 * @param {number} id
 * @param {*} imagen
 * @param {string} ref
 * @param {string} field
 * id es el id del registro al que se asociará
 * imagen es el objeto que devuelve el uploader
 * ref es la tabla a la que se asociará el registro
 * field es el campo de la tabla reservado al registro de la imagen.  
 */
export const uploadPlugin=async(id, imagen, ref, field)=>{
  const data=new FormData()
  data.append('files', imagen)
  data.append('refId', id)
  data.append('ref', ref)
  data.append('field', field)
  const upload_res = await axios({
    method: 'POST',
    url: 'https://services.imjmedia.com.mx/upload',
    data
  })
  console.log('Éxito:',upload_res)
}