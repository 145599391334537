/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
	IonPopover,
	IonRow,
    IonCol
} from "@ionic/react";

import { DateField, EmailField, renderBoolean } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import useDialog from "../../hooks/useDialog";
import { useMutation } from "@apollo/client";
import {PROVEEDORES_QUERY,UPDATE_ESTADO} from './queries';
import FormProveedores from './FormProveedores';
import useAuthentication from "../../hooks/useAuthentication";
import {CREAR_USUARIO,ELIMINAR_USUARIO} from "../registrarUsuario/queries";
import {DataTable} from  "primereact/datatable";
import {Column} from "primereact/column";
import { Button } from "primereact/button";
import { Panel } from 'primereact/panel';

import {
	DTableV2,
	InputTextComponent,
	MToolBar,
	Toolbar,
} from "../../components";

const columnas = [
	{
		header: "Nombre de ejecutivo",
		field: "nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Nombre comercial",
		field: "nombre_comercial",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "e-mail",
		field: "email",
		sortable: true,
		body: EmailField
	},
	{
		header: "Teléfono",
		sortable: true,
		field: "telefono"
	},
	{
		header: "Estatus",
		field: "estatus",
		body: renderBoolean,
		sortable: true,
		type: 'boolean',
		name:'estatus'
	},
	{
		header: "Creado por",
		field: "createdby.email",
		sortable: true,
		body: EmailField
	},
	{
		header: "Actualizado por",
		field: "modifiedby.email",
		sortable: true,
		body: EmailField
	},
	{
		header: "Última actualización",
		field: "updated_at",
		sortable: true,
		body: DateField
	}
];


const Proveedores = (props) => {
	
	const [refresh, setRefresh] = useState(false);
	const {usuarioid} = useAuthentication();
	const [titleHeader, settitleHeader] = useState('Titulo');
	const [expandedRows, setexpandedRows] = useState(null);
    const [tempUsers, setTempUsers] = useState([]);
    const [tempIdProveedor, settempIdProveedor] = useState(0);
	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
	
	const [updateProveedor] = useMutation(UPDATE_ESTADO, {
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});
  
	const [CrearUser] = useMutation(CREAR_USUARIO,{
		onCompleted:(data) =>{
			setShowDialog1(false);
			showSuccess('El usuario se registro exitosamente');
			tempUsers.push(data.createUser.user.id);
		    AgregarUsuariosProveedor();
		}
	});

	const [EliminarUsuario] = useMutation(ELIMINAR_USUARIO,{
		onCompleted:(data) =>{
			showSuccess('Se elimino el registro con exito');
			setRefresh(true);
		}
	});

	const {showDialog, FormDialog} = useDialog();
	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */
	const cambiarEstado = (data) => {
		if (data){
			updateProveedor({ 
				variables: { 
					input: { 
						where: { 
							id: data.id 
						}, 
						data:{
							estatus:!data.estatus
						} } 
					} 
			});
		}
	
	};


	const AgregarUsuariosProveedor =()=>{
		updateProveedor({
			variables:{
				input:{
					where:{
						id:tempIdProveedor
					},
					data:{
						users:tempUsers
					}
				}
			}
		});
	}
   
	
	 
	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
	const addProveedor = () => {
	
		let data = {nombre:'',createdby:usuarioid(),modifiedby:usuarioid()}
		showDialog(data, "crear");
		settitleHeader("Nuevo proveedor");
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) =>{
         
		if (data.createdby===null){
			data.createdby = usuarioid();
			console.log(data.createdby);
		}else{
			var idcreatedby =data.createdby.id;
			console.log(idcreatedby);
			data.createdby=parseInt(idcreatedby);
		}
    
		data.modifiedby=usuarioid();

		showDialog(data,'editar');	
		settitleHeader("Editar informaciòn de proveedor");
	}

	const onView = (data)=>{
		showDialog(data,'detalles');
		settitleHeader("Detalles de proveedor");
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}

	const [showDialog1, setShowDialog1] = useState(false);

	
	const rowsExpandedData = (data)=>{
	
		settempIdProveedor(parseInt(data.id));
	
        for (let index = 0; index < data.users.length; index++) {
			const element = data.users[index];
			tempUsers.push(parseInt(element.id));
		}

	    const actiontemplate = (row,column)=>{

			const eliminarUsurario =(row)=>{
				EliminarUsuario({variables:{
					id:parseInt(row.id)
				}})
			}
			  
			return(
				<Button 
					icon="pi pi-trash" 
					type="button"
					className="p-button-danger p-button-raised p-button-rounded"
					onClick={()=>eliminarUsurario(row)}
				/> 
			)
		}

		
		const crearUsuarioProveedor =(nombre,email,password)=>{
            if(nombre !== "" &&  email !== "" && password !== ""){
				CrearUser({variables:{
					input:{
						data:{
							blocked: false,
							createdby: usuarioid(),
							email: email,
							modifiedby: usuarioid(),
							nombre:nombre,
							password: password,
							role: 8,
							username: email
						}
					}
				}});
			}else{
				showInfo("los campos son obligatorios");
			}		
		}
		
		const PopUpRegistrarUsuario =()=>{
			const [nombre, setnombre] = useState("");
			const [email, setEmail] = useState("");
			const [password, setPassword] = useState("");
			return (
				<div style={{width:"100%"}}>
					<IonPopover
					isOpen={showDialog1}
					cssClass='my-custom-class'
					onDidDismiss={e => setShowDialog1(false)}
					>
					 <Panel header='Crear usuario'>
						<IonRow>
						<IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name="nombre"
								type="text"
								value={nombre}
								style={{ width: "100%" }}
								onChange={e => {
									setnombre(e);
								}}
								accept={null}
								className={null}
								disabled={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={true}
							/>
							<label htmlFor="in">Nombre</label>
						</span>    
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
							<br></br>
							<span className="p-float-label">
								<InputTextComponent
									name="email"
									type="email"
									value={email}
									style={{width:"100%"}}
									onChange={e  =>{
										setEmail(e);
									}}
									accept={null}
									className={null}
									disabled={null}
									label={null}
									onBlur={null}
									onKeyPress={null}
									placeholder={null}
									required={null}
								/>
									<label htmlFor="in">Email</label>
							</span>    
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
							<br></br>
							<span className="p-float-label">
								<InputTextComponent 
									name="password"
									type="password"
									value={password}
									style={{width:"100%"}}
									onChange={e =>{
										setPassword(e);
									}}
									accept={null}
									className={null}
									disabled={null}
									label={null}
									onBlur={null}
									onKeyPress={null}
									placeholder={null}
									required={null}
								/>
								<label htmlFor="in">Password</label>
							</span>    
						</IonCol>
						</IonRow>
						<br></br>
						<IonRow>
							<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
								<Button 
									label="Registrar"
						            type="button"
									icon="pi  pi-save"
									className="p-button-success ion-float-right"
									style={{ marginLeft: "20px"}}
									onClick={()=>crearUsuarioProveedor(nombre,email,password)}

								/>
							</IonCol>
							<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
								<Button 
									label="Cancelar"
									icon="pi pi-times"
									float-right="true"
									className="p-button-danger ion-float-left"
									onClick={()=>setShowDialog1(false)}
									style={{marginLeft:"20px"}}
								/>
							</IonCol>
						</IonRow>
					</Panel>	
					</IonPopover>
				</div>
			)	
		}

		return(
			<>
				<Button 
					icon="pi pi-user-plus" 
					type="button"
					className="p-button-success p-button-raised p-button-rounded"
					onClick={()=>setShowDialog1(true)}
					style={{marginLeft:"20px"}}
				/>
				<PopUpRegistrarUsuario />
				<div className="p-grid p-fluid" style={{padding: '.5em .5em .5em .5em'}}>
				{data.users.length <= 0 && <p style={{textAlign:"center"}}>Sin datos para mostrar</p>}
				{data.users.length > 0 &&
					<DataTable value={data.users}>
						<Column field="nombre" header="Nombre" />
						<Column field="email" header="email" />
						<Column field="createdby.email" header="Creado por" />
						<Column field="modifiedby.email" header="Modificado por" />
						<Column
							header="Acción"
							body={actiontemplate}
							style={{ textAlign: "center", width: "14em" }}
						/>
					</DataTable>
	            }	
				</div>			
			</>
		);
	}

	return (
		<IonPage>
			{notification}
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				<MToolBar addClick={addProveedor} />
				<DTableV2
					query={PROVEEDORES_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element="proveedors"
					ordenarPor={'nombre:asc'}
					onDelete={cambiarEstado}
					canView={true}
					canDelete={true}
					canEdit={true}
					onEdit={onEdit}
					onView={onView}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
					rowExpansionTemplate={rowsExpandedData}
					expandedRows={expandedRows} 
					onRowToggle={(e) => {
							console.log('Valor de e ',e)
							setexpandedRows(e.data)
					}}
					responsive={false}
					autoLayout={true}
				/>
				<FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormProveedores />
				</FormDialog>
			</IonContent>
		</IonPage>
	);
}

export default Proveedores;
