/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";

import { DateField, renderBoolean } from "../../utils/dataTableAux";
import {useNotification} from "../../hooks";
import useDialog from "../../hooks/useDialog";
import { useMutation } from "@apollo/client";
import {Toolbar, MToolBar, DTableV2} from "../../components";
import {CLIENTES_QUERY,UPDATE_CLIENTE} from './queries';
import FormClientes from './FormClientes';
import useAuthentication from "../../hooks/useAuthentication";


const columnas = [
	{
		header: "Nombre",
		field: "nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Empresa",
		field: "empresa",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"

	},
	{
		header: "Tipo",
		field: "tipo",
		sortable: true
	},
	{
		header: "Activo",
		field: "activo",
		sortable:true,
		body:renderBoolean,
		type: 'boolean',
		name:'activo'
	},
	{
		header: "Correo",
		field: "correo",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Última actualización",
		field: "updated_at",
		body: DateField,
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	}
];


const Clientes = (props) => {
	const [refresh, setRefresh] = useState(false);
	const {usuarioid} = useAuthentication();
	const [titleHeader, settitleHeader] = useState("Titulo");

	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
	
	const [updateCliente] = useMutation(UPDATE_CLIENTE, {
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});

    const {showDialog, FormDialog} = useDialog();

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */
	const cambiarEstatus = (data) => {
		if (data){
			updateCliente({
				variables: { 
					input: { 
						where: { 
							 id: data.id 
						},data:{
							activo:!data.activo
					    }
				    } 
				} 
			});
		}
	};

	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
	const addCliente = () => {
		
		let data = {nombre:'',createdby:usuarioid(),modifiedby:usuarioid()}
		showDialog(data, "crear");
		settitleHeader('Crear cliente');
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) =>{
	
		if (data.createdby===null){
			data.createdby = usuarioid();
		
		}else{
			var idcreatedby =data.createdby.id;
		
			data.createdby=parseInt(idcreatedby);
		}

		data.modifiedby=usuarioid();

		showDialog(data,'editar');

		settitleHeader('Editar informaciòn del cliente');
		
	}

	const onView = (data) => {
		showDialog(data,"detalles");
		settitleHeader("Detalles del cliente");
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				<MToolBar />
				<DTableV2
					autoLayout={true}
					paginable={true}
					ordenarPor='nombre:asc'
					query={CLIENTES_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element="clientes"
					onDelete={cambiarEstatus}
					canView={true}
					canDelete={true}
					canEdit={true}
					onEdit={onEdit}
					onView={onView}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
				/>
				<FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormClientes />
				</FormDialog>
			</IonContent>
		</IonPage>
	);
}

export default Clientes;
