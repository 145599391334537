import {gql} from '@apollo/client';

export const PERMISIONARIOS_QUERY = gql `
    query listaPermisionarios($sort: String, $limit: Int, $start: Int, $where: JSON){
      permisionarios(sort: $sort, limit: $limit, start: $start, where: $where){
        id
        permisionario
        esquemaPago
        numPermisionario
        costo
        unidades_urbanos{
            id
            numeco
            tipoContrato
            cartuchos
            activo
            ruta{
                id
                nombre
            }
        }
      }
    } 
`;

export const CREATE_PERMISIONARIO = gql `
    mutation CrearPermisionario($input:createPermisionarioInput){
        createPermisionario(input:$input){
            permisionario{
                id  
                permisionario
                esquemaPago
                numPermisionario     
                costo
            }
        }
    }
`;

export const UPDATE_PERMISIONARIO = gql `
    mutation UpdatePermisionario($input:updatePermisionarioInput){
        updatePermisionario(input:$input){
            permisionario{
                id
                permisionario
                esquemaPago
                costo
                numPermisionario 
                costo
            }
        }
    }
`;

