// JS REPORT
export const JS_REPORT_USER = 'admin';
export const JS_REPORT_PASSWORD = 's1st3m4s20$22';
export const JS_REPORT_TEMPLEATE_PAUTA_PATH = '/PDF/Pauta/pauta-main-multiple';
export const JS_REPORT_TEMPLEATE_SINGLE_PAUTA_PATH = '/PDF/Pauta/pauta-main';
export const JS_REPORT_TEMPLEATE_URBANO_PATH = '/PDF urbano/PDF-Urbanos-Multiple';
export const JS_REPORT_TEMPLEATE_SINGLE_URBANO_PATH = '/PDF urbano/PDF-Urbanos';
export const URL_JS_REPORT_IMJ = 'https://imjmedia.com.mx:5489';
export const URL_JS_REPORT_IMJ_SERVICES = 'https://services.imjmedia.com.mx:5489';
export const URL_JS_REPORT_DEVELOP = 'http://localhost:5488';

// MAPS
export const IMJ_API_KEY = 'AIzaSyBop_VhCaJf7YviDGL7uIHLjPSJft1QmfQ';
export const URL_GOOGLE_PLACES_TEXT_SEARCH = 'https://maps.googleapis.com/maps/api/place/textsearch/json?query=';
export const ZOOM_MAP_DEFAULT = 14;
export const ZOOM_MAP_POI = 13;

// Interest Points
export const CATEGORIE_FOOD = 'Food';
export const CATEGORIE_FINANCIAL = 'Financial';
export const CATEGORIE_STORE = 'Store';
export const CATEGORIE_ENTERITANMENT = 'Enteritanment';
export const CATEGORIE_NATURE = 'Nature';
export const CATEGORIE_SECURITY = 'Security';
export const CATEGORIE_TRANSPORT = 'Transport';
export const CATEGORIE_PET = 'Pet';
export const CATEGORIE_SERVICES = 'Services';
export const CATEGORIE_SCHOOL = 'School';
export const CATEGORIE_MEDICAL = 'Medical';
export const CATEGORIE_AUTOMOVIL = 'Automovil';
export const CATEGORIE_GOVERMENT = 'Government';
export const CATEGORIE_TOURISM = 'Tourism';
export const CATEGORIE_RELIGIOUS = 'Religious';

export const CATEGORIES = [
    {
        value: CATEGORIE_TRANSPORT,
        translate: 'Transporte'
    },
    {
        value: CATEGORIE_FOOD,
        translate: 'Comida'
    },
    {
        value: CATEGORIE_FINANCIAL,
        translate: 'Financiero'
    },
    {
        value: CATEGORIE_STORE,
        translate: 'Tienda'
    },
    {
        value: CATEGORIE_ENTERITANMENT,
        translate: 'Entretenimiento'
    },
    {
        value: CATEGORIE_NATURE,
        translate: 'Natural'
    },
    {
        value: CATEGORIE_SECURITY,
        translate: 'Seguridad'
    },
    {
        value: CATEGORIE_PET,
        translate: 'Mascotas'
    },
    {
        value: CATEGORIE_SERVICES,
        translate: 'Servicios'
    },
    {
        value: CATEGORIE_SCHOOL,
        translate: 'Escuela'
    },
    {
        value: CATEGORIE_MEDICAL,
        translate: 'Medico'
    },
    {
        value: CATEGORIE_AUTOMOVIL,
        translate: 'Automotriz'
    },
    {
        value: CATEGORIE_GOVERMENT,
        translate: 'Gobierno'
    },
    {
        value: CATEGORIE_TOURISM,
        translate: 'Turismo'
    },
    {
        value: CATEGORIE_RELIGIOUS,
        translate: 'Religioso'
    },
];
export const INVALID_VALUE_POI_OXXO = 'oxxo';
export const INVALID_VALUE_POI_7_ELEVEN = '7-eleven';
export const INVALID_CATEGORY_LOCALITY = 'locality';
export const INVALID_VALUE_TELEFONO_PUBLICO_ACENTOS = 'teléfono público';
export const INVALID_VALUE_TELEFONO_PUBLICO = 'telefono publico';
// MENÚ
export const VERSION = 'v 1.14.0'; 
export const IMJ_COLORS = ['#126efa', '#fc3d38', '#1cb5b2','#d11787','#fcb32b','#874afa','#bfeb08','#1cbf59'];

// Cube.js
export const CUBEJS_TOKEN_DEV =
'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MTc5MDU4MjksImV4cCI6MTYxNzk5MjIyOX0.6DN6-os-BNvkxACO6NdJWwmCqvL1gp7tH0KXEWuv2cs';
export const CUBEJS_TOKEN_PROD =
'3280d8a9ac2b44d6ea99653b8b17cfef0de4cce0030d51d20407d73488d6e4d8f4df5fc6c8dd0c04123ea418874d267b653f6ee6897578667e8002a2e8ae3614';
export const API_URL_CUBEJS_DEV = 'http://localhost:4000';
// export const API_URL_CUBEJS_PROD = 'wss://bi.imjmedia.com.mx';
export const API_URL_CUBEJS_PROD = 'wss://bi.imjmedia.com.mx/cubo';

// export const API_URL_CUBEJS_PROD = 'https://103.195.100.146:4000';

export const CAMPANIA_KEY = 'Campanias';
export const MEDIOS_INFINIA_KEY = 'MediosInfinia';
export const PAUTAS_KEY = 'Pautas';
export const PROVEEDORES_KEY = 'Proveedors';
export const RUTAS_KEY = 'Rutas';
export const TIPOS_KEY = 'Tipos';
export const UBICACIONES_KEY = 'Ubicaciones';
export const VISTAS_KEY = 'Vistas';
export const CLIENTES_KEY= 'Clientes';
export const BITACORAS_KEY= 'Bitacoras';
export const USERS_PERMISSIONS_KEY= 'UsersPermissionsUser';
export const NEGOCIACIONES_KEY = 'Negociaciones';
export const ADMIN_KEY = 'Administrador';

export const CAMPANIA_LABEL = 'Negociaciones';
export const NEGOCIACIONES_LABEL = 'Negociaciones';
export const MEDIOS_INFINIA_LABEL = 'Sitios';
export const PAUTAS_LABEL = 'Pautas';
export const PROVEEDORES_LABEL = 'Proveedors';
export const RUTAS_LABEL = 'Rutas';
export const TIPOS_LABEL = 'Tipos';
export const UBICACIONES_LABEL = 'Plazas';
export const VISTAS_LABEL = 'Vistas';
export const CLIENTES_LABEL= 'Clientes';
export const USERS_PERMISSIONS_LABEL= 'Comercial';
export const BITACORAS_LABEL= 'Bitacoras';
export const ADMIN_LABEL= 'Administradores';
// export const _KEY= '';

// LOCAL STORAGE
export const CUBES_LOCAL_STORAGE = 'cubes';
// GOOGLE RESPONSES
export const GOOGLE_PLACES_ZERO_RESULTS = 'ZERO_RESULTS';
export const GOOGLE_PLACES_ZERO_RESULTS_RESPONSE = 'Sin resultados favor de verificar la posicion de lat-lng';

// CUBE JS VALUES
export const ORDER_ASC_VALUE = '"asc"}';
export const ORDER_DESC_VALUE = '"desc"}';
export const ORDER_ASC_VALUE_PARSE = 'asc';
export const ORDER_DESC_VALUE_PARSE = 'desc';
export const CUBE_DEFAULT_DATE_VALUE = 'FECHA DEFAULT';
export const CUBE_EMPTY_TITLE = 'Selecciona una medida para iniciar';
export const CUBE_DIMENSION_TITLE = 'Dimensiones';
export const CUBE_DIMENSION_PLACEHOLDER = 'Selecciona una dimension';
export const CUBE_MEASURE_PLACEHOLDER = 'Selecciona una medida';
export const CUBE_MEASURES_TITLE = 'Medidas';
export const CUBE_ADD_VALUE = 'add';
export const CUBE_GRANULARYTY_OPTIONS = [
    {
        name: 'day',
        title: 'Dia'
    },
    {
        name: 'week',
        title: 'Semana'
    },
    {
        name: 'month',
        title: 'Mes'
    },
    {
        name: 'year',
        title: 'Año'
    }
];

export const OPERATOS_OPTIONS = [
    
    {
        name: 'equals',
        title: 'Igual'
    },
    {
        name: 'notEquals',
        title: 'No igual'
    },
    {
        name: 'contains',
        title: 'Contiene'
    },
    {
        name: 'notContains',
        title: 'No Contiene'
    },
    {
        name: 'set',
        title: 'Datos No Vacios'
    },
    {
        name: 'notSet',
        title: 'Datos Vacios'
    },
    {
        name: 'gt',
        title: '>'
    },
    {
        name: 'gte',
        title: '>='
    },
    {
        name: 'lt',
        title: '<'
    },
    {
        name: 'lte',
        title: '<='
    },
];

export const OPERATOS_DATE_OPTIONS = [
    {
        name: 'inDateRange',
        title: 'En Fecha'
    },
    {
        name: 'notInDateRange',
        title: 'No En Fecha'
    },
    {
        name: 'beforeDate',
        title: 'Antes De Fecha'
    },
    {
        name: 'afterDate',
        title: 'Despues De Fecha'
    },
];

export const MEDIOS_INFINIA_TITLE = 'Medios'

export const LIST_TRANSLATE_CUBE_MEASURES_KEYS = [
    {
        name: CAMPANIA_KEY,
        translate: 'Negociaciones'
    },
    {
        name: NEGOCIACIONES_KEY,
        translate: 'Negociaciones'
    },
    {
        name: MEDIOS_INFINIA_TITLE,
        translate: 'Sitios'
    },
    {
        name: PAUTAS_KEY,
        translate: 'Pautas'
    },
    {
        name: PROVEEDORES_KEY,
        translate: 'Proveedores'
    },
    {
        name: RUTAS_KEY,
        translate: 'Rutas'
    },
    {
        name: TIPOS_KEY,
        translate: 'Tipos'
    },
    {
        name: UBICACIONES_KEY,
        translate: 'Plazas'
    },
    {
        name: VISTAS_KEY,
        translate: 'Vistas'
    },
    {
        name: CLIENTES_KEY,
        translate: 'Clientes'
    },
    {
        name: USERS_PERMISSIONS_KEY,
        translate: 'Comercial'
    }
];

export const ADMIN_ROL_VALUE = 'Administrador';
// export const AVAILABLE_DIMENSION = '';  