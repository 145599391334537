import {gql} from "@apollo/client";

export const LISTA_PRODUCTOS = gql `
    query detallePauta ($sort: String,$limit: Int,$start: Int,$where: JSON){
        detailpautas(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            producto
            cantidad
            fecha_inicio
            fecha_fin
            descuento
            pauta{
                id
                cliente
                responsable 
            }
            medio{
                id
                clave
            }
            ruta{
                id
                nombre
            }   
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
    }
`;

export const LISTA_UNIVERSO = gql `
    query listaUniverso($sort: String,$limit: Int,$start: Int,$where: JSON){
        medios(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            universo
            ubicacion{
                id
                nombre
            }
            activo
        }
    }
`;

export const BUSCAR_DETALLES = gql`
    query detallePauta($where:JSON){
        detailpautas(where:$where){
            id
            producto
            cantidad
            fecha_inicio
            fecha_fin
            descuento
            pauta{
                id 
            }
            medio{
                id
                clave
            }
            ruta{
                id
                nombre
            }
            circulospauta{
                id
                etiqueta
            }  
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
    }
`;

export const REGISTRAR_DETALLE_PAUTA = gql `
    mutation REGISTRAR_DETALLE_PAUTA($input:createDetailpautaInput){
        createDetailpauta(input:$input){
            detailpauta{
                id
                createdby{
                    email
                }
                modifiedby{
                    email
                }
            }
        }
    }
`;

export const REGISTRAR_PAUTA = gql `
    mutation REGISTRAR_PAUTA($input:createPautaInput){
        createPauta(input:$input){
            pauta{
                id
                createdby{
                    email
                }
                modifedby{
                    email
                }
            }
        } 
    }
`;


export const UPDATE_PAUTA =gql `
    mutation UPDATE_PAUTA($input:updatePautaInput){
        updatePauta(input:$input){
            pauta{
                id
            }
        }
    }
`;

export const UPDATE_CAMPANIA=gql`
    mutation UPDATE_CAMPANIA($input:updateCampaniaInput){
        updateCampania(input:$input){
            campania{
                id
                pauta{
                    id
                }
            }
        }
    }

`;

export const DELETE_DETAILS=gql `
    mutation DELETE_DETAILS($input:deleteDetailpautaInput){
        deleteDetailpauta(input:$input){
            detailpauta{
                id
            }
        }
    }
`;

export const DELETE_CIRCULO=gql `
mutation DELETE_CIRCULO($input:deleteCirculospautaInput){
    deleteCirculospauta(input:$input){
        circulospauta{
            id
        }
    }
}
`;

export const REGISTRAR_CIRCULO = gql `
    mutation REGISTRAR_CIRCULO($input:createCirculospautaInput){
        createCirculospauta(input:$input){
            circulospauta{
                id
                etiqueta
                lat
                lng
                radio
                createdby{
                    email
                }
                modifiedby{
                    email
                }
            }
        }
    }
`;


export const LISTAR_CLIENTES = gql `
    query ListarClientes($sort: String,$limit: Int,$start: Int,$where: JSON){
        clientes(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            idbitrix
            empresa  
            color
            logo
            campanias{
                id
                nombre
            }
        }
    }
`;

export const LISTAR_RESPONSABLES = gql `
query ListarResponsables($sort: String,$limit: Int,$start: Int,$where: JSON){
    users(sort:$sort,limit:$limit,start:$start,where:$where){
        id
        nombre
    }
}
`;

export const  MEDIOS_QUERY_WHERE = gql `
query listaMedio($where: JSON){
    medios(where: $where){
        id
        clave
        direccion
        vialidad
        colonia
        medida
        referencias
        medida
        imagenmapaubicacion
        material
        tipoacabado
        vista{
            nombre
        }
        nicho
        Vista_media
        latitud
        longitud
        iluminacion
        female_users
        male_users
        a13_17_users
        a18_25_users
        a26_40_users
        a41_55_users
        a55_users
        income_a_users
        income_b_users
        income_c_users
        income_d_users
        income_e_users
        total_hits
        total_users
        reach
        frequency
        universo
        especiales
        tipos{
            nombre
        }
        ubicacion{
            nombre
            estado
        }
    }
} 
`;

export const  RUTAS_QUERY_WHERE = gql `
query listaRutas($where: JSON){
    rutas(where: $where){
        id
        nombre
        descripcion
        inicio
        fin
        activo
        kilometraje
        clave
        total_hits
        reach
        frequency
        female_users
        male_users
        ubicacion{
            nombre
            estado
            activo
            tipoexhib
            imagenexhib
            preciourbano
            estatus
            lat
            lng
        }
        createdby{
            email
        }
        modifiedby{
            email
        }
        puntos(sort:"id:asc"){
            id
            lat
            lng
        }
    }
} 
`;

export const LISTAR_CAMPANIAS = gql `
    query listaCampanias($sort: String,$limit: Int,$start: Int,$where: JSON){
        campanias(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            estatus
            clientes{
                id
                nombre
                empresa
            }
            responsable{
                id
                nombre
            }
        }
    }
`;

export const LISTA_PAUTAS = gql `
    query listaPautas($sort: String,$limit: Int,$start: Int,$where: JSON){
        pautas(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            campania{
                id
                nombre
                estatus
            }
            responsable{
                id
                nombre
            }
        }
    }
`;

