import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { CATEGORIES } from '../../../../utils/Constanst';
import './InfoWindowGooglePlace.style.css';

const InfoWindowGooglePlaceComponent = ({infoProp}) => {
    const [addres, setAddres] = useState('');
    const [categoryTranslate, setCategoryTranslate] = useState('');
    const [valueTranslate, setValueTranslate] = useState('');
    const [text, setText] = useState('');
    const [latLng, setLatLng] = useState('');
    const [icon, setIcon] = useState('');
    useEffect(() => {
        if (Object.keys(infoProp).length > 0) {
            if (infoProp.data.categoryTranslate) {
                setValueTranslate(infoProp.data.categoryTranslate);
            }
    
            if (infoProp.text) {
                setText(infoProp.text);
            }
    
            if (infoProp.data.formatted_address) {
                setAddres(infoProp.data.formatted_address);
            }
    
            if (infoProp.lat && infoProp.lng) {
                setLatLng('lat: ' +infoProp.lat + ' - lng:' + infoProp.lng);
            }

            if (infoProp.category) {
                const category = infoProp.category;
                const categoryTranslate = CATEGORIES.filter(c => c.value === category);
                setCategoryTranslate(categoryTranslate[0].translate);
            }

            if (infoProp.data.icon) {
                setIcon(infoProp.data.icon);
                
            }
        }
        
    }, [infoProp]);
    return(
        <IonCard>
            <IonCardHeader>
                <div className={'header-container'} >
                    <div>
                        <IonCardSubtitle>{valueTranslate}</IonCardSubtitle>
                        <IonCardTitle>{text}</IonCardTitle>
                    </div>
                    <div>
                        <img alt={'img-info-window-google-place'} src={icon} />
                    </div>
                </div>
            </IonCardHeader>

            <IonCardContent className={'container'}>
                <div className={'item'}>
                    <h2 className={'title'}>Direccion:</h2> <h4 className={'value'}> {addres}</h4>
                </div>

                <div className={'item'}>
                    <h2 className={'title'}>Localizacion:</h2> <h4 className={'value'}> {latLng}</h4>
                </div>

                <div className={'item'}>
                    <h2 className={'title'}>Categoria:</h2> <h4 className={'value'}> {categoryTranslate}</h4>
                </div>
            </IonCardContent>
        </IonCard>
    );
};

export default InfoWindowGooglePlaceComponent;
