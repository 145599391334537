import {gql} from '@apollo/client';

export const TIPOS_QUERY = gql`
    query tipos($sort: String, $limit: Int, $start: Int, $where: JSON){
        tipos(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            nombre
            tipomedio{
                id
                nombre
            }
            estatus
            Createdby{
                email
            }
            Modifiedby{
                email
            }
           
        }
    }
`;

export const CREATE_TIPO = gql `
    mutation CrearTipo($input:createTipoInput){
        createTipo(input:$input){
            tipo{
                id
                nombre
                    Createdby{
                        email
                }
                Modifiedby{
                        email
                }
            }
        }
    }
`;

export const UPDATE_TIPO = gql `
    mutation UpdateTipo($input:updateTipoInput){
        updateTipo(input:$input){
            tipo{
                id
                nombre
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
            }
        }
    }
`;

export const UPDATE_ESTATUS = gql `
    mutation UpdateTipo($input:updateTipoInput){
        updateTipo(input:$input){
            tipo{
                id
                estatus
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                
            }
        }
    }
`;

export const DELETE_TIPO = gql `
    mutation Eliminartipo($input:deleteTipoInput){
        deleteTipo(input:$input){
            tipo{
                id
                nombre
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const TIPOMEDIO=gql `
    query tipomedios ($sort: String, $limit: Int, $start: Int, $where: JSON){
        tipomedios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            nombre
        }
    }
`;