/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/** 
 * Project: OOH Bit Planner
 * File: /src/components/DesktopTable/dTableV2.tsx
 * Created Date: Saturday, February 29th 2020, 12:24:05 pm
 * Author: Jorge Correa
 * -----
 * Last Modified: 30/09/2021
 * Modified By: Jorge Correa
 * -----
 * 
 * Functionality: 
 * 
 * Recibe como props :
 * dataGrid : Un arreglo de los datos que mostrará
 * columnas: Un arreglo con las columnas que mostrará el grid
 * onView: Función que deberá ser llamada cuando el usuario oprima el botón ver (ir al detalle del registro)
 * onEdit: Función que deberá ser llamada cuando el usuario oprima el botón editar.
 * onDelete: Función a ser llamada cuando el usuario oprime el botón delete. Previo a la llamada muestra un dialog de confirmación.
 * canEdit: Boolean para indicar si el usuario puede editar. Debería venir de los permisos
 * canDelete: Boolean para indicar si el usuario puede editar. Debería venir de los permisos
 * exportFilename: Nombre del archivo a exportar default datos.csv
 * 
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	----------------------------------------------------------
 * 06/05/2020	JC	Se eliminan start: first, limit: rows del query para no paginar, daba problemas con el sort, hay que mejorarlo.	
 * 10/05/2020	JC	Se agrega la posibilidad de tener filtro de estatus (activo/inactivo) se debe poner al cmapo boolean las siguiente propiedades: 
 * 		filter: true,
 *		type: 'boolean',
 *		name:'estatus'
 * 21/10/2020	JC	Se agrega posibilidad de pasar un filtro para el query principal en props filtro
 * 30/09/2021   JC  Se rehace la versión 2
 */

import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useState } from 'react'
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import { useNotification } from '../../hooks';
import useTranslateGQLError from '../../utils/grapqlErrors';
import { saveAs } from 'file-saver';
import './dTable.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Paginator } from 'primereact/paginator';
import { isEmptyObject } from '../../utils/Utils';
import useDebounce from '../../hooks/useDebounce';
import {Spinner} from '../../components';

const Excel = require('exceljs');

/**
 * Columnas de demo para el pintado inicial
 */
const demoColumns = [
    { header: "Name", field: "name" },
    { header: "Surname", field: "surname" },
    { header: "Birth Year", field: "birthYear", type: "numeric" },
    {
        header: "Birth Place",
        field: "birthCity",
        lookup: { 34: "İstanbul", 63: "Şanlıurfa" }
    }
];

/**
 * Convierte el filtro de primereact a formato strapi graphql
 * Por el momento no se usa, dejar el filtrado al table
 * @param filter 
 */

const strapiFilter = (filter): any => {
    //where: { email_contains: "@strapi.io" }
    // field: {value}
    const keys = Object.keys(filter);
    let filtro = {};

    for (let i = 0; i < keys.length; i++) {
        filtro[keys[i] + "_contains"] = filter[keys[i]].value;
    }
    return filtro;
}

const booleanOptions = [
    { label: 'Todos', value: '' },
    { label: 'Activo', value: 'true' },
    { label: 'Inactivo', value: 'false' }
]

/**
 * DTableV2 : componente DataTable con la funcionalidad de filtros y paginación integrada
 * a graphql
 * 
 * @component
 * @example
 * <DTableV2 query={ALMACENES_QUERY}
 *		style={{ margin: "15px" }}
 *		columnas={columnas}
 *		element='almacenes'
 *		onDelete={deleteAlmacenes}
 *		canDelete={true}
 *		canEdit={true}
 *	/>
 *
 * element: String que corresponde al modelo donde se regresa la dataGrid por graphql 
 * por ejemplo: 'almacenes'
 * 
 * query: graphql tag representando el query
 * exportFilename: string para definir el nombre del archivo al exportar a csv
 * onDelete: función a ejecutar cuando se selecciona delete y se confirma la acción con el dialog
 * onView: función a llamar cuando se hace click en ver en el registro
 * canEdit: true|false mostrar u ocultar el botón de edición
 * canDelete: true|false mostrar u ocultar el botón de borrado
 * 
 * TODO: Implementar lógica de canDelete y canEdit para los registros
 */
const DTableV2 = (props) => {
    const [confirm, setConfirm] = useState({ visible: false });
    const [rows, setRows] = useState(props.rows || 20);
    const [first, setFirst] = useState(0);
    const [deleteData, setDeleteData] = useState();
    const [selectedBoolean, setSelectedBoolean] = useState(true);
    const { addSitios } = useContext(ShoppingCartContext);
    //const { showError, notification } = useNotification();
    const { translate } = useTranslateGQLError();
    const [paginable] = useState(props.paginable || false);
    const [totalRecords, setTotalRecords] = useState(10)
    const [pageLinkSize, setPageLinkSize] = useState(0);
    const [filtros, setFiltros] = useState({});
    const [paginadorVisible, setPaginadorVisible] = useState(true);
    const [lastFiltro, setLastFiltro] = useState(null); //registra el último filtro usado en el query para saber si hay que ejecutar de nuevo
    const { notification, showError, showSuccess, showWarn } = useNotification();
    const [loadingGrid, setLoadingGrid] = useState(false);

    let dt: any = null;
    const debouncedSearchTerm = useDebounce(filtros, 1000);

    const actionHeader = (
        <React.Fragment>
            <Button icon="pi pi-download" className="p-button-success p-button-raised p-button-rounded" 
                onClick={() => { 
                    setLoadingGrid(true);
                    exportExcel() 
                }} />
        </React.Fragment>
    );

    const renderFooter = () => {
        return (
            <div>
                <Button
                    label="Si"
                    icon="pi pi-check"
                    onClick={() => {
                        setDeleteData(null);
                        setConfirm({ visible: false });
                        props.onDelete(deleteData);
                    }}
                />
                <Button
                    label="No"
                    icon="pi pi-times"
                    onClick={() => setConfirm({ visible: false })}
                    className="p-button-secondary"
                />
            </div>
        );
    }

    const onSuccess = () => {
        showSuccess("Operación exitosa", "Se agrego el producto con exito");
    }
    const onWarning = () => {
        showWarn("Producto ya añadido");
    }

    /**
     * Definición de acciones del DTable pre definidas
     * 
     * canView : Booleano para indicar si puede ver los detalles del registro y mostrar el botón
     * canEdit : Booleano para indicar si el registro puede ser editado y motrar el botón
     * canDelete : Booleano para indicar si se permite el borrado y motrar el botón
     * canAdd : Booleano para indicar si se muestra el botón de shopping cart y se pueden agregar "productos"
     * canDownload: Booleano para indicar si se puede descargar información de ese registro (por ahora sólo se usa en Testigos)
     * @param rowData 
     * @param column 
     * @returns 
     */
    const actionTemplate = (rowData, column) => {
        return (
            <div style={{ width: 'max-content' }}>
                {props.canView &&
                    <Button
                        type="button"
                        icon="pi pi-search"
                        className="p-button-success p-button-raised p-button-rounded"
                        style={{ marginRight: ".5em" }}
                        onClick={() => { props.onView(rowData) }}></Button>}
                {props.canEdit &&
                    <Button
                        type="button"
                        icon="pi pi-pencil"
                        className="p-button-warning p-button-raised p-button-rounded"
                        style={{ marginRight: ".5em" }}
                        onClick={() => { props.onEdit(rowData) }}></Button>}
                {props.canDelete &&
                    <Button
                        type="button"
                        icon="pi pi-trash"
                        className="p-button-danger p-button-raised p-button-rounded"
                        onClick={() => { onDelete(rowData) }}></Button>}
                {props.canAdd &&
                    <Button
                        type="button"
                        icon="pi pi-shopping-cart"
                        className="p-button-secondary p-button-raised p-button-rounded"
                        onClick={() => {
                            addSitios(rowData, onWarning, onSuccess)
                        }}></Button>
                }
                {
                    props.canDownload &&
                    <Button
                        type='button'
                        icon="pi pi-download"
                        className='p-button-secondary p-button-raised p-button-rounded'
                        style={{ marginRight: ".5em" }}
                        onClick={() => { props.onDownload(rowData)}}
                    >
                    </Button>
                }
            </div>
        );
    };

    /**
     * Función para capturar el error en query
     * @param error 
     */
    const onError = (error: any) => {
        let errorMSG = { title: 'Error de comunicación', message: 'Ocurrió un error de comunicación con la BD' };
        if (error.graphQLErrors.length > 0) {
            errorMSG = translate(error.graphQLErrors);
        }
        showError(errorMSG.title, errorMSG.message);
    }

    /**
     * Función para generar las opciones del query
     * 
     * @returns options: QueryHookOptions<any, Record<string, any>>
     */
    const optionsQuery = () => {
        let options: QueryHookOptions<any, Record<string, any>> = {}
        if (paginable) {
            options =
            {
                variables: {
                    start: first,
                    limit: rows,
                    sort: props.ordenarPor || 'id:asc'
                }
            }
        } else {
            options = {
                variables: {
                    // sort: props.ordenarPor || 'id:asc',
                    sort: 'id:desc',
                    // limit: 10, //rows, // comentar si se pasa a produccion
                }
            }
        }
        return options;
    }

    /*
    *   Función de definición de ejecución del query recibido en props
    */
    const [datosGrid, { data: dataGrid, loading, error }] = useLazyQuery(props.query, {
        errorPolicy: 'all',
        onError: onError,
        onCompleted: () =>{
            setLoadingGrid(false);
        }
    });

    /**
     * Función para convertir el filtro del grid en filtro de query
     * @param filtro 
     * @returns 
     */
    const filtroLlaves = (filtro) => {
        let filtroObject = {
            start: first,
            limit: rows,
            
        }
        if (filtro){
            (filtroObject as any).where = {};
            for (var [key, value] of Object.entries(filtro)) {
                (filtroObject as any).where[key] = value;
            }
        }
        
        return filtroObject;
    }

    const funcionFiltro = (filter) => {
        setFiltros(filter.filters);

    }

    let columnas = props.columnas || demoColumns;

    /**
     * Función para generar las columnas de la tabla de manera dinámica
     */
    let dynamicColumns = columnas.map((col: ColumnProps, i: number) => {
        if (col['type'] === 'boolean') {
            return <Column key={i} {...col} filterElement={
                <Dropdown style={{ width: '100%' }}
                    name={col['name']}
                    placeholder="Seleccione un estatus"
                    value={selectedBoolean}
                    options={booleanOptions}
                    onChange={(event) => {
                        dt.filter(event.value, col['name'], 'equals');
                        setSelectedBoolean(event.value);
                    }} showClear />
            } />;
        } else {
            return <Column key={i} {...col} />;
        }

    });

    /**
     * Función para borrado lógico de datos.
     * @param rowData 
     */
    const onDelete = (rowData) => {
        setDeleteData(rowData);
        setConfirm({ visible: true });
    }

    /**
     * Función para evolver los valores a exportar dependiendo de la fuente de datos
     * @param source 
     * @returns 
     */
    const objectValues = (source) =>{
        let values = null;
        let cols = dt.props.columnas.filter((columna) => columna.exportable === undefined || columna.exportable !== false);
        cols = cols.filter((col) => col !== undefined);
        values = source.map((registro) => {
            let registroExport = {}
            cols.map((columna) => {
                if (columna.field) {
                    if (columna.field.indexOf('.') === -1) {
                        if (registro[columna.field] === true || registro[columna.field] === false) {
                            if (registro[columna.field] === false) {
                                registroExport[columna.field] = 'No';
                            } else {
                                registroExport[columna.field] = 'Sí';
                            }
                        } else {
                            registroExport[columna.field] = registro[columna.field];
                        }

                    } else {
                        let anidado = columna.field.split('.');
                        if (registro[anidado[0]] !== null && registro[anidado[0]] !== undefined) {
                            registroExport[columna.header] = registro[anidado[0]][anidado[1]];
                        } else {
                            registroExport[columna.header] = null;
                        }
                    }
                }
            })
            return registroExport;
        })
        return values;
    }

    /*
    *   Función de definición de ejecución del query recibido en props
    *   en grid paginable
    */
    const [datosExportar, { data: dataExport, loading: loadingExport, error: errorExport }] = useLazyQuery(props.query, {
        errorPolicy: 'all',
        onError: onError,
        onCompleted:  (data) =>{
            exportPaginableExcel(objectValues(data[props.element]));
        }
    });
    
    /**
     * Función para exportar a excel los datos recibidos como parámetro
     * @param data datos a exportar
     */
    const exportPaginableExcel = async (data)=>{
        let dRows = data;
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Hoja 1');
        //Column Declaration
        let arr = dRows[1];
        let head = Object.keys(arr);
        var columns = [];
        let cols = dt.props.columnas.filter((columna) => columna.exportable === undefined || columna.exportable !== false);
        cols = cols.filter((col) => (col !== undefined && col.field));
        head.forEach((h) => {
            let colActual = cols.filter((element)=>{
                if (element.field.includes('.')){
                    return element.field.toLocaleUpperCase().includes(h.toLocaleUpperCase())
                }else{
                    return element.field===h
                }
            })
            //columns.push({ header: h, key: h });
            if (colActual.length>0){
                columns.push({ header: colActual[0].header, key: h });
            }
        });
        ws.columns = columns;
        //Styles
        //Column styles
        ws.columns.forEach(column => {
            column.border = {
                top: { style: "thick" },
                left: { style: "thick" },
                bottom: { style: "thick" },
                right: { style: "thick" }
            };
            column.width = column.header.length < 40 ? 40 : column.header.length;
        });
        //Row Styles
        ws.getRow(1).font = { bold: true, size: 18, color: { argb: 'ffffffff' } };
        ws.getRow(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffff0000' }, bgColor: { argb: 'ffff0000' } };
        ws.getRow(1).height = 25;

        // Row filling
        for (var key in dRows) {
            if (dRows.hasOwnProperty(key)) {
                console.log('value', dRows[key]);
                ws.addRow(dRows[key]);
            }
        }

        //Saving file
        const buffer = await wb.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const blob = new Blob([buffer], { type: fileType });
        setLoadingGrid(false);
        saveAs(blob, 'dataGrid_Export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    //Al exportar un DTable paginado no va a traer todos los registros! ver que hacer.
    /**
     * Función para exportar a excel el contenido de un Dtable
     */
    const exportExcel = async () => {
        if (paginable) {
            let strapi_filter = null;
            if (!isEmptyObject(debouncedSearchTerm)) {
                strapi_filter = strapiFilter(debouncedSearchTerm);
            }
            let filtrarPor = filtroLlaves(strapi_filter);
            filtrarPor.start = 0;
            filtrarPor.limit = 10000;
            datosExportar({ variables: filtrarPor })
        } else {
            exportPaginableExcel(objectValues(dt.props.value));
        }   
    }

    /**
     * Efectos de recuperación de datos
     */

    useEffect(() => {
        if (props.refresh) {
            setLoadingGrid(true);
            //Traer optionsQuery y si props.filtro no es vacío agregar el where
            let options = optionsQuery();
            if (props.filtro) {
                options.variables.where = props.filtro;
            }
            datosGrid(options);
            props.onEndRefresh();
        }
    }, [props.refresh]);

    useEffect(() => {
        if (dataGrid && dataGrid[props.element]) {
            if (props.setListRutas) {
                props.setListRutas(dataGrid.rutas);
            }

            if (dataGrid[props.element + 'Connection']){
                setTotalRecords(dataGrid[props.element + 'Connection'].aggregate.count);
                setPageLinkSize(Math.ceil(dataGrid[props.element + 'Connection'].aggregate.count / rows));
                setPaginadorVisible(true);
            }else{
                setTotalRecords(dataGrid[props.element].length);
                setPageLinkSize(Math.ceil(dataGrid[props.element].length) / rows);
                setPaginadorVisible(true);
            }
            

            props.onEndRefresh();
        }
    }, [dataGrid]);

    useEffect(() => {
        let options = optionsQuery();
        if (props.filtro) {
            options.variables.where = props.filtro;
        }
        datosGrid(options);
    }, [])

    useEffect(() => {
        if (!loading) {
            if (!isEmptyObject(debouncedSearchTerm)) {
                let strapi_filter = strapiFilter(debouncedSearchTerm);
                if (paginable) {
                    setLoadingGrid(true);
                    datosGrid({ variables: filtroLlaves(strapi_filter) });
                }
            }else{
                let options = optionsQuery();
                if (props.filtro) {
                    options.variables.where = props.filtro;
                }
                setLoadingGrid(true);
                datosGrid(options);
            }
        }
    }, [debouncedSearchTerm]);
    /**
     * Fin de los efectos
     */

    const pageChange = (e) => {
        //{first: 20, rows: 10, page: 2, pageCount: 13}
        setFirst(e.first);
        setRows(e.rows);
        if (paginable) {
            setLoadingGrid(true);
            let options = optionsQuery();
            options.variables.start = e.first;
            if (props.filtro) {
                options.variables.where = props.filtro;
            }
            if (!isEmptyObject(debouncedSearchTerm)) {
                let strapi_filter = strapiFilter(debouncedSearchTerm);
                let filtrarPor = filtroLlaves(strapi_filter);
                filtrarPor.start = e.first;
                filtrarPor.limit = e.rows;
                if (paginable) {
                    datosGrid({ variables: filtrarPor });
                }
            } else {
                datosGrid(options);
            }

        }
    }

    return (
        <div>
            {paginadorVisible && <Paginator rows={rows} totalRecords={totalRecords} first={first} onPageChange={(e) => pageChange(e)}
                template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                rowsPerPageOptions={[10, 50, 100, 200, 500]}></Paginator>}
            {!paginable &&
                <>
                    <Spinner loading={loadingGrid} />
                    <DataTable
                        {...props}
                        rows={rows}
                        first={first}
                        totalRecords={totalRecords}
                        value={(dataGrid && dataGrid[props.element]) || null}
                        style={props.style || { margin: 15 }}
                        paginator={false}
                        emptyMessage="No hay registros para mostrar"
                        exportFilename={props.exportFilename || "datos"}
                        ref={el => {
                            dt = el;
                        }}
                        loading={loading || props.loadingFlag}
                        responsive={false}
                        onPage={e => {
                            setFirst(e.first);
                            setRows(e.rows);
                        }}>
                        {props.rowExpansionTemplate && <Column expander={true} style={{ width: '3em' }} />}
                        {dynamicColumns}
                        <Column
                            header={actionHeader}
                            body={actionTemplate}
                            style={{ textAlign: "center", width: "14em !important" }}
                        />
                    </DataTable>
                </>}
            {paginable &&
                <>
                    <Spinner loading={loadingGrid} />
                    <DataTable
                        {...props}

                        value={(!loading && dataGrid && dataGrid[props.element]) || null}
                        style={props.style || { margin: 15 }}
                        emptyMessage="No hay registros para mostrar"
                        exportFilename={props.exportFilename || "datos"}
                        ref={el => {
                            dt = el;
                        }}
                        onFilter={funcionFiltro}
                        filters={filtros}
                        loading={loading || props.loadingFlag}
                        responsive={false}
                    >
                        {props.rowExpansionTemplate && <Column expander={true} style={{ width: '3em' }} />}
                        {dynamicColumns}
                        <Column
                            header={actionHeader}
                            body={actionTemplate}
                            style={{ textAlign: "center", width: "14em !important" }}
                        />
                    </DataTable>
                </>}
            {paginadorVisible && <Paginator rows={rows} totalRecords={totalRecords} first={first} onPageChange={(e) => pageChange(e)}
                template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                rowsPerPageOptions={[10, 50, 100, 200, 500]}></Paginator>}
            <Dialog
                header="Borrado de datos"
                visible={confirm.visible}
                style={{ width: "50vw" }}
                modal={true}
                footer={renderFooter()}
                onHide={() => setConfirm({ visible: false })}>
                Está operación volverá inactivo el registro, desea continuar?.
            </Dialog>
            {notification}
        </div>
    )
}
export default DTableV2;