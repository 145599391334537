import { IonImg } from '@ionic/react';
import React from 'react';

const IMJMarker = (tipo: string, disponibilidad: boolean) => {
    let disponibleString = '';
    if (!disponibilidad) {
        disponibleString = '_desactivado';
    }
    switch (tipo) {
        case 'Arte en Muro':
            return `/assets/markers/arte_en_muro${disponibleString}.png`
        case 'Bajo Puente':
            return `/assets/markers/bajo_puente${disponibleString}.png`
        case 'Cabecera':
            return `/assets/markers/cabecera${disponibleString}.png`
        case 'Espectaculares':
            //return `/assets/markers/espectacular${disponibleString}.png`
             return `/assets/markers/espectaculares${disponibleString}.png`
        case 'Muro':
            return `/assets/markers/muro${disponibleString}.png`
        case 'Mupi':
            return `/assets/markers/mupi${disponibleString}.png`
        case 'N/A':
            return `/assets/markers/general-medios${disponibleString}.png`
        case 'Pantalla Digital':
            return `/assets/markers/pantalla${disponibleString}.png`
        case 'Pendones':
            return `/assets/markers/pendon${disponibleString}.png`
        case 'Puente':
            return `/assets/markers/puente_peatonal${disponibleString}.png`
        case 'Puente peatonal':
            return `/assets/markers/puente_peatonal${disponibleString}.png`
        case 'Totem':
            return `/assets/markers/totem${disponibleString}.png`
        case 'Valla':
            return `/assets/markers/valla${disponibleString}.png`
        case 'Valla Móvil':
            return `/assets/markers/vallamovil${disponibleString}.png`
        case 'Vitral':
            return `/assets/markers/vitral${disponibleString}.png`
        default:
            return `/assets/markers/general-medios${disponibleString}.png`
    }
}

export const IMJMarkerIcon = (tipo: string, disponibilidad: boolean = true) => {
    let disponibleString = '';
    if (!disponibilidad) {
        disponibleString = '_desactivado';
    }
    // Hardcode
    if (tipo === 'Espectaculares' && !disponibilidad) {
        disponibleString = 'es_desactivado';
    }
    switch (tipo) {
        case 'Arte en Muro':
            return (<IonImg src={`/assets/markers/arte_en_muro${disponibleString}.png`} alt="Arte en muro" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Bajo Puente':
            return (<IonImg src={`/assets/markers/bajo_puente${disponibleString}.png`} alt="Bajo Puente" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Cabecera':
            return (<IonImg src={`/assets/markers/cabecera${disponibleString}.png`} alt="Cabecera" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Espectaculares':
            return (<IonImg src={`/assets/markers/espectacular${disponibleString}.png`} alt="espectacular" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Muro':
            return (<IonImg src={`/assets/markers/muro${disponibleString}.png`} alt="Muro" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Mupi':
            return (<IonImg src={`/assets/markers/mupi${disponibleString}.png"`} alt="Mupi" style={{ width: 40, height: 40 }}></IonImg>)
        case 'N/A':
            return (<IonImg src={`/assets/markers/general-medios${disponibleString}.png`} alt="N/A" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Pantalla Digital':
            return (<IonImg src={`/assets/markers/pantalla${disponibleString}.png`} alt="Pantalla Digital" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Pendones':
            return (<IonImg src={`/assets/markers/pendon${disponibleString}.png`} alt="Pendones" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Puente':
            return (<IonImg src={`/assets/markers/puente_peatonal${disponibleString}.png`} alt="Puente" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Puente peatonal':
            return (<IonImg src={`/assets/markers/puente_peatonal${disponibleString}.png`} alt="Puente Peatonal" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Totem':
            return (<IonImg src={`/assets/markers/totem${disponibleString}.png`} alt="Totem" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Valla':
            return (<IonImg src={`/assets/markers/valla${disponibleString}.png`} alt="Valla" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Valla Móvil':
            return (<IonImg src={`/assets/markers/valla_movil${disponibleString}.png`} alt="Valla Móvil" style={{ width: 40, height: 40 }}></IonImg>)
        case 'Vitral':
            return (<IonImg src={`/assets/markers/vitral${disponibleString}.png`} alt="Vitral" style={{ width: 40, height: 40 }}></IonImg>)
        default:
            return (<IonImg src={`/assets/markers/general-medios${disponibleString}.png`} alt="Otros" style={{ width: 40, height: 40 }}></IonImg>)
    }
  }

export default IMJMarker;