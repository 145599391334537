import React, { useState } from "react";
import {Toolbar, MToolBar, DTableV2} from "../../components";
import useAuthentication from "../../hooks/useAuthentication";
import useNotification from "../../hooks/useNotification";
import {LISTAR_TESTIGOS, LISTA_PRODUCTOS } from "./queries";
import { DateField } from "../../utils/dataTableAux";
import FormDetalles from "./FormDetalles"; //Form que mostrará los testigos asociados
import useDialog from "../../hooks/useDialog";

const TablaSitios=(props)=>{
    const [refresh, setRefresh] = useState(false);
    const [titleHeader, settitleHeader] = useState("Ver Testigos");
    const {showDialog, FormDialog} = useDialog();
    const {usuarioid, rol} = useAuthentication();

    const {
        notification,
        showSuccess,
        showInfo,
        showError
    } = useNotification();

    const vistaTestigos=(row)=>{
        //Aquí se abre un form que muestra los Testigos asociados al detalle
        console.log(row)
        const medio="sitios"
        let datos = {row, medio, created_by:usuarioid(),updated_by:usuarioid()}
		showDialog(datos, "crear");
		settitleHeader('Ver Testigos');
    }

    const columnas=[
        {
            header: "Sitio",
            sotrable: true,
            filter: true,
            field:"medio.clave",
            filterMatchMode: "contains"
        },
        {
            header: "Fecha de Inicio",
            field: "fecha_inicio",
            bodyRender:DateField,
            sortable:true
        },
        {
            header: "Fecha de Fin",
            field: "fecha_fin",
            bodyRender:DateField,
            sortable:true
        },
        {
            header: "Dirección",
            field: "medio.direccion"
        },
        {
            header: "Tipo",
            field: "medio.tipos.nombre",
            sortable:true,
            filter: true,
            filterMatchMode:"contains"
        },
        {
            header:"Ubicación",
            field:"medio.ubicacion.nombre",
            sortable:true,
            filter: true,
            filterMatchMode:"contains"
        },
        {
            header: "Medidas",
            field: "medio.medida"
        }
    ]
    return(
        <>
        <DTableV2
            filtro={{"pauta":props.pauta.id}}
            element="detailpautas"
            query={LISTA_PRODUCTOS}
            style={{margin: "15px"}}
            paginator={true}
            refresh={refresh}
			onEndRefresh={() => setRefresh(false)}
            columnas={columnas}
            canView={true}
            onView={vistaTestigos}
        />
        <br/>
        <FormDialog
			header={titleHeader}
            >
			<FormDetalles /> 
		</FormDialog>
        </>
    )
    
}
export default TablaSitios;