/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { IonSelect, IonSelectOption, IonSpinner, IonText } from "@ionic/react";
import Select from "react-dropdown-select";
import './Estados.css';
import { useQuery, gql } from "@apollo/client";

const UBICACIONES_QUERY = gql `
    query listaUbicaciones($sort: String, $limit: Int, $start: Int, $where: JSON){
        ubicacions(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            nombre
            activo
            estado
            estatus
            lat
            lng
            Createdby{
                email
            }
            Modifiedby{
                email
            }
            created_at
            updated_at
        }
    }
`;

function Estados(props) {
  const {data,loading} = useQuery(UBICACIONES_QUERY,{
    variables:{
      sort: 'nombre:asc',
      where:{
        activo:true
      }
    }
  });
  const [elementoSel,setElementoSel] = useState([
    {Createdby: {email: "jorge.correa@imjmedia.com.mx", __typename: "UsersPermissionsUser"},
      Modifiedby: {email: "jorge.correa@imjmedia.com.mx", __typename: "UsersPermissionsUser"},
      activo: true,
      created_at: "2019-10-30T13:34:28.000Z",
      estado: "CDMX",
      estatus: null,
      id: "9",
      lat: 19.43411066622587, 
      lng: -99.1326554135682,
      nombre: "CDMX",
      updated_at: "2020-07-10T17"
    }
  ]);
  
  if (loading){
    return <IonSpinner />
  }

  if (data) {
    return (
      <div>
        <IonText>Seleccione la plaza de búsqueda</IonText>
        <Select
          options={data.ubicacions} // Options to display in the dropdown
          values={elementoSel} // Preselected value to persist in dropdown
          labelField="nombre" // Property name to display in the dropdown options
          valueField='id'
          onChange={(e)=>{
            setElementoSel(e);
            props.onSelected(e);
          }}
          searchable={true}
          placeholder='Seleccione localidad'
          searchBy='nombre'
          sortBy='nombre'
          backspaceDelete={true}
          clearable={true}
          style={props.comboStyle || {backgroundColor:'white', borderRadius:'25px'}}
        />
      </div>
      
    )
  }
}

export default Estados;

/*
const estados = [
  {
    cve_agee: "01",
    nom_agee: "Aguascalientes",
    nom_abrev: "Ags.",
    capital: "Aguascalientes", 
    lat: "21.88234", 
    lng: "-102.282586", 
    pob: "1184996",
    pob_fem: "608358",
    pob_mas: "576638",
    viv: "290877"
  },
  {
    cve_agee: "02",
    nom_agee: "Baja California",
    nom_abrev: "BC",
    capital: "Tijuana",
    lat: "32.502698", 
    lng: "-117.003714", 
    pob: "3155070",
    pob_fem: "1563460",
    pob_mas: "1591610",
    viv: "870769"
  },
  {
    cve_agee: "03",
    nom_agee: "Baja California Sur",
    nom_abrev: "BCS",
    capital: "La Paz", 
    lat: "24.144373", 
    lng: "-110.300501", 
    pob: "637026",
    pob_fem: "311593",
    pob_mas: "325433",
    viv: "178271"
  },
  {
    cve_agee: "04",
    nom_agee: "Campeche",
    nom_abrev: "Camp.",
    capital: "Campeche", 
    lat: "19.843857", 
    lng: "-90.525535", 
    pob: "822441",
    pob_fem: "414720",
    pob_mas: "407721",
    viv: "213727"
  },
  {
    cve_agee: "05",
    nom_agee: "Coahuila de Zaragoza",
    nom_abrev: "Coah.",
    capital: "Saltillo",
    lat: "25.423213", 
    lng: "-101.005299", 
    pob: "2748391",
    pob_fem: "1384194",
    pob_mas: "1364197",
    viv: "727613"
  },
  {
    cve_agee: "06",
    nom_agee: "Colima",
    nom_abrev: "Col.",
    capital: "Colima", 
    lat: "19.249972", 
    lng: "-103.727137", 
    pob: "650555",
    pob_fem: "327765",
    pob_mas: "322790",
    viv: "180488"
  },
  {
    cve_agee: "07",
    nom_agee: "Chiapas",
    nom_abrev: "Chis.",
    capital: "Tuxtla Gutiérrez", 
    lat: "16.759727", 
    lng: "-93.113081", 
    pob: "4796580",
    pob_fem: "2443773",
    pob_mas: "2352807",
    viv: "1091100"
  },
  {
    cve_agee: "08",
    nom_agee: "Chihuahua",
    nom_abrev: "Chih.",
    capital: "Chihuahua",
    lat: "28.633333", 
    lng: "-106.083333", 
    pob: "3406465",
    pob_fem: "1713920",
    pob_mas: "1692545",
    viv: "944681"
  },
  {
    cve_agee: "09",
    nom_agee: "Ciudad de México",
    nom_abrev: "CDMX",
    capital: "Ciudad de México",
    lat: "19.434167", 
    lng: "-99.138611", 
    pob: "8851080",
    pob_fem: "4617297",
    pob_mas: "4233783",
    viv: "2453770"
  },
  {
    cve_agee: "10",
    nom_agee: "Durango",
    nom_abrev: "Dgo.",
    capital: "Durango", 
    lat: "24.019419", 
    lng: "-104.66069", 
    pob: "1632934",
    pob_fem: "829044",
    pob_mas: "803890",
    viv: "407784"
  },
  {
    cve_agee: "11",
    nom_agee: "Guanajuato",
    nom_abrev: "Gto.",
    capital: "León de los Aldama",
    lat: "21.129079", 
    lng: "-101.673741", 
    pob: "5486372",
    pob_fem: "2846947",
    pob_mas: "2639425",
    viv: "1276913"
  },
  {
    cve_agee: "12",
    nom_agee: "Guerrero",
    nom_abrev: "Gro.",
    capital: "Chilpancingo", 
    lat: "17.550599", 
    lng: "-99.505783", 
    pob: "3388768",
    pob_fem: "1743207",
    pob_mas: "1645561",
    viv: "810596"
  },
  {
    cve_agee: "13",
    nom_agee: "Hidalgo",
    nom_abrev: "Hgo.",
    capital: "Pachuca", 
    lat: "20.117362", 
    lng: "-98.742915", 
    pob: "2665018",
    pob_fem: "1379796",
    pob_mas: "1285222",
    viv: "669514"
  },
  {
    cve_agee: "14",
    nom_agee: "Jalisco",
    nom_abrev: "Jal.",
    capital: "Guadalajara",
    lat: "20.666823", 
    lng: "-103.391824", 
    pob: "7350682",
    pob_fem: "3750041",
    pob_mas: "3600641",
    viv: "1831205"
  },
  {
    cve_agee: "15",
    nom_agee: "México",
    nom_abrev: "Mex.",
    capital: "Toluca",
    lat: "19.287311", 
    lng: "-99.644979", 
    pob: "15175862",
    pob_fem: "7778876",
    pob_mas: "7396986",
    viv: "3749499"
  },
  {
    cve_agee: "16",
    nom_agee: "Michoacán de Ocampo",
    nom_abrev: "Mich.",
    capital: "Morelia", 
    lat: "19.70179", 
    lng: "-101.183962", 
    pob: "4351037",
    pob_fem: "2248928",
    pob_mas: "2102109",
    viv: "1082772"
  },
  {
    cve_agee: "17",
    nom_agee: "Morelos",
    nom_abrev: "Mor.",
    capital: "Cuernavaca", 
    lat: "18.933725", 
    lng: "-99.228488", 
    pob: "1777227",
    pob_fem: "918639",
    pob_mas: "858588",
    viv: "469091"
  },
  {
    cve_agee: "18",
    nom_agee: "Nayarit",
    nom_abrev: "Nay.",
    capital: "Tepic", 
    lat: "21.509505", 
    lng: "-104.895691", 
    pob: "1084979",
    pob_fem: "543972",
    pob_mas: "541007",
    viv: "291163"
  },
  {
    cve_agee: "19",
    nom_agee: "Nuevo León",
    nom_abrev: "NL",
    capital: "Monterrey",
    lat: "25.675065", 
    lng: "-100.318465",
    pob: "4653458",
    pob_fem: "2333273",
    pob_mas: "2320185",
    viv: "1210893"
  },
  {
    cve_agee: "20",
    nom_agee: "Oaxaca",
    nom_abrev: "Oax.",
    capital: "Oaxaca", 
    lat: "17.065418", 
    lng: "-96.723645", 
    pob: "3801962",
    pob_fem: "1982954",
    pob_mas: "1819008",
    viv: "941814"
  },
  {
    cve_agee: "21",
    nom_agee: "Puebla",
    nom_abrev: "Pue.",
    capital: "Puebla",
    lat: "19.037926", 
    lng: "-98.203457", 
    pob: "5779829",
    pob_fem: "3009974",
    pob_mas: "2769855",
    viv: "1392053"
  },
  {
    cve_agee: "22",
    nom_agee: "Querétaro",
    nom_abrev: "Qro.",
    capital: "Querétaro", 
    lat: "20.600014", 
    lng: "-100.399715", 
    pob: "1827937",
    pob_fem: "940749",
    pob_mas: "887188",
    viv: "454392"
  },
  {
    cve_agee: "23",
    nom_agee: "Quintana Roo",
    nom_abrev: "Q. Roo",
    capital: "Chetumal", 
    lat: "18.514126", 
    lng: "-88.303813", 
    pob: "1325578",
    pob_fem: "652358",
    pob_mas: "673220",
    viv: "369326"
  },
  {
    cve_agee: "24",
    nom_agee: "San Luis Potosí",
    nom_abrev: "SLP",
    capital: "San Luis Potosí", 
    lat: "22.149825", 
    lng: "-100.979161", 
    pob: "2585518",
    pob_fem: "1325152",
    pob_mas: "1260366",
    viv: "639265"
  },
  {
    cve_agee: "25",
    nom_agee: "Sinaloa",
    nom_abrev: "Sin.",
    capital: "Culiacán", 
    lat: "24.790325", 
    lng: "-107.387816", 
    pob: "2767761",
    pob_fem: "1391560",
    pob_mas: "1376201",
    viv: "713296"
  },
  {
    cve_agee: "26",
    nom_agee: "Sonora",
    nom_abrev: "Son.",
    capital: "Hermosillo", 
    lat: "29.102602", 
    lng: "-110.977324", 
    pob: "2662480",
    pob_fem: "1322868",
    pob_mas: "1339612",
    viv: "712402"
  },
  {
    cve_agee: "27",
    nom_agee: "Tabasco",
    nom_abrev: "Tab.",
    capital: "Villahermosa", 
    lat: "17.986893", 
    lng: "-92.930277", 
    pob: "2238603",
    pob_fem: "1137845",
    pob_mas: "1100758",
    viv: "567233"
  },
  {
    cve_agee: "28",
    nom_agee: "Tamaulipas",
    nom_abrev: "Tamps.",
    capital: "Cd. Victoria", 
    lat: "23.741744", 
    lng: "-99.145993", 
    pob: "3268554",
    pob_fem: "1652353",
    pob_mas: "1616201",
    viv: "901387"
  },
  {
    cve_agee: "29",
    nom_agee: "Tlaxcala",
    nom_abrev: "Tlax.",
    capital: "Tlaxcala", 
    lat: "19.319055", 
    lng: "-98.19982", 
    pob: "1169936",
    pob_fem: "604161",
    pob_mas: "565775",
    viv: "274243"
  },
  {
    cve_agee: "30",
    nom_agee: "Veracruz de Ignacio de la Llave",
    nom_abrev: "Ver.",
    capital: "Xalapa", 
    lat: "19.54386", 
    lng: "-96.92155", 
    pob: "7643194",
    pob_fem: "3947515",
    pob_mas: "3695679",
    viv: "2014588"
  },
  {
    cve_agee: "31",
    nom_agee: "Yucatán",
    nom_abrev: "Yuc.",
    capital: "Mérida", 
    lat: "20.975368", 
    lng: "-89.616959", 
    pob: "1955577",
    pob_fem: "992244",
    pob_mas: "963333",
    viv: "507248"
  },
  {
    cve_agee: "32",
    nom_agee: "Zacatecas",
    nom_abrev: "Zac.",
    capital: "Zacatecas", 
    lat: "22.769005", 
    lng: "-102.571563", 
    pob: "1490668",
    pob_fem: "763771",
    pob_mas: "726897",
    viv: "377018"
  }
];

    */
