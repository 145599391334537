import React, { useState } from "react";
import {IonRow, IonCol,IonText, IonGrid, IonPopover, IonLabel, IonHeader, IonCardContent, IonAlert, IonSpinner, IonFooter} from "@ionic/react";
import { Button } from "primereact/button";
import {URBANOS_INCIDENCIAS_QUERY, LISTAR_ECOS, LISTAR_PERMISIONARIOS, CAMPANIAS_URBANOS_QUERY } from "./query";
import moment, { duration } from 'moment';
import { useQuery, useLazyQuery} from "@apollo/client";
import {Dropdown} from 'primereact/dropdown'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import {formatMoney} from '../../utils/Utils';
import { saveAs } from 'file-saver';
import { AddSheet, AltoFila, CombinarCeldas, EXCEL_EXTENSION, FormatoFila, FormatoMultiplesCeldas, HojaActiva, ValorCelda, ValoresFila, workBook } from '../../components/Pauta/export/funcionesExport';
import { exportPDF } from "../../components/Pauta/export/PdfExport";

const Excel = require('exceljs');
const imjColors = ['#126efa', '#fc3d38', '#1cb5b2','#d11787','#fcb32b','#874afa','#bfeb08','#1cbf59'];

const Disponibilidad =(props)=>{
    //States que almacenan los resultados de las query
    const [permisionarios, setPermisionarios]=useState([])
    const [campanias, setCampanias]=useState([])
    const [incidencias, setIncidencias]=useState([])
    const [ecos, setEcos]=useState([])
    //Queries que obtienen información de base de datos
    const {data:dataCampanias, loading:loadingCampanias}=useQuery(CAMPANIAS_URBANOS_QUERY ,{
        variables:{
            sort:"id:ASC"
        },
        onCompleted:(data)=>{
            setCampanias(dataCampanias.urbanoscampanias)
        }
    });
    const {data:dataIncidencias, loading:loadingIncidencias}=useQuery(URBANOS_INCIDENCIAS_QUERY, {
        variables:{
        sort:"id:ASC"
        },
        onCompleted:(data)=>{
        setIncidencias(dataIncidencias.urbanosIncidencias)
        }
    });
    const {data:dataUnidades, loading:loadingUnidades}=useQuery(LISTAR_ECOS, {
        variables:{
            sort:"ruta.id:ASC",
            where:{
                "activo":true,
                "ruta.ubicacion":1
            }
        },
        onCompleted:(data)=>{
            setEcos(dataUnidades.unidadesUrbanos)
        }
    });
    const {data:dataPermisionarios, loading:loadingPermisionarios}=useQuery(LISTAR_PERMISIONARIOS,{
        variables:{
            sort:"numPermisionario:ASC"
        },
        onCompleted:(data)=>{
            setPermisionarios(dataPermisionarios.permisionarios)
        }
    });
    const header=[
        {id: 1, title: 'Ruta'},
        {id: 2, title:'Número Económico'},
        {id: 3, title: 'Permisionario'},
        {id: 4, title: 'Días De Merma'}
        //{id: 5, title: 'Porcentaje de '}
    ]
    const cabecera=header.map((element)=>{
        return(
            <IonCol key={element.id}>
                <IonHeader><h4>{element.title}</h4></IonHeader>
            </IonCol>
        )
    })
    if(props.anio && props.mes && props.porcentaje)
    {
        if(loadingCampanias || loadingIncidencias || loadingUnidades)
        {
            return(
                <IonSpinner name="dots"/>
            )
        }
        const fechaInicio=props.anio+'-'+props.mes.id+'-01'
        const fechaFin=moment(fechaInicio).endOf('month').format('YYYY-MM-DD')
        const novendidas=[]
        const arrayaux=[]
        campanias.forEach(element => {
            if(element.inicia>=fechaInicio && element.termina<=fechaFin)
            {   
                arrayaux.push(element.unidades_urbano)
            }
            if(element.inicia>=fechaInicio && element.inicia<=fechaFin && element.termina>fechaFin)
            {
                arrayaux.push(element.unidades_urbano)  
            }
            if(element.inicia<fechaInicio && element.termina>fechaFin)
            {
                arrayaux.push(element.unidades_urbano)
            }
        });
        var array = [];
        for (var i = 0; i < dataUnidades.unidadesUrbanos.length; i++) 
        {
            var igual=false;
            for (var j = 0; j < arrayaux.length && !igual; j++) 
            {
                if(dataUnidades.unidadesUrbanos[i]['id'] === arrayaux[j]['id'] && 
                dataUnidades.unidadesUrbanos[i]['numeco'] === arrayaux[j]['numeco']) 
                igual=true;
            }
            if(!igual)array.push(dataUnidades.unidadesUrbanos[i]);
        }
        
        /////////////////Array es el nuevo arreglo que settearemos para mostrar solo lo vacío
        array.forEach(element=>{
            let diasmerma=0
            let intervaloMax=Math.floor(props.porcentaje*30.4)
            let intervaloMin=Math.floor((props.porcentaje-0.9)*30.4)
            campanias.forEach(e=>{
                if(e.unidades_urbano.numeco===element.numeco && e.termina<fechaFin && e.termina>fechaInicio)
                {
                    //diasmerma=moment(fechaFin, "YYYY-MM-DD").diff(e.termina).asDays()
                    const ff=moment(fechaFin)
                    const et=moment(e.termina)
                    diasmerma=ff.diff(et)
                    console.log (moment(diasmerma).days())
                }
            })

            if(moment(diasmerma).days()>intervaloMin && moment(diasmerma).days()<=intervaloMax)
            {
                var z={
                    ruta: element.ruta.nombre,
                    numeco: element.numeco,
                    permisionario: element.permisionario.permisionario,
                    merma:moment(diasmerma).days()
                }
                novendidas.push(z)
                console.log(novendidas)
            }
            
        });
        
        const mapeo=novendidas.map((element)=>{
                return(
                    <div key={element.numeco}>
                        <IonRow>
                            <IonCol>
                                {element.ruta}
                            </IonCol>
                            <IonCol>
                                {element.numeco}
                            </IonCol>
                            <IonCol>
                                {element.permisionario}
                            </IonCol>
                            <IonCol>
                                {element.merma}
                            </IonCol>
                        </IonRow>
                    </div>
                    )
        })

        return(
            <>
                <br/>
                <IonGrid>
                    <IonRow>
                        {cabecera}
                    </IonRow>
                        {mapeo}
                </IonGrid>
            </>
        )
    }
    else {
        return(
            <h5>Seleccionar valores faltantes </h5>
        )
    }
    
}
export default Disponibilidad