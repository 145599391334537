import React, { useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

let IluminacionList = [
    "Si",
    "No",
    "Todos"
]


export const Iluminacion = (props) => {
    const [selected,setSelected] = useState(props.value);
    const handleOnChange = (e) => {
        setSelected(e.value);   
        if (props.onChange){
            props.onChange(e);
        }
    }

    return(
        <div>
            <Dropdown 
                style={{fontSize: '10px'}}
                value={selected}
                options={IluminacionList} 
                onChange={handleOnChange} 
                placeholder="Seleccione una opción"/>
        </div>
    )
}

export const NichosFiltro = (props) => {
    let listNichos = [ 
        "A"
       ,"B"
       ,"C"
       ,"D"
       ,"E"
       ,"N/A"
    ];

    const [nicho,setNicho] = useState(props.nicho);

    const onChangeNicho = (data) =>{
        setNicho(data.value);
        if (props.onSelectNicho){
            props.onSelectNicho(data.value);
        }
    }
    
    return (
        <MultiSelect
            style={{ minWidth: '200px' }}
            value={nicho}
            options={listNichos}
            display="chip"
            onChange={onChangeNicho}
            placeholder="Selecciona un nicho"
            required={true} />
    )
}

export const VistasFiltro = (props) => {
    let listVistas = [ 
        "Natural"
       ,"Cruzada"
    ];

    const [vista,setVista] = useState(props.vista);

    const onChangeVista = (data) =>{
        setVista(data.value);
        if (props.onSelectVista){
            props.onSelectVista(data.value);
        }
    }
    
    return (
        <MultiSelect
            style={{ minWidth: '200px' }}
            value={vista}
            options={listVistas}
            display="chip"
            onChange={onChangeVista}
            placeholder="Selecciona el tipo de vista"
            required={true} />
    )
}