/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {IonRow, IonCol,IonText, IonGrid, IonSpinner, IonPopover} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import {LISTAR_RUTAS,
        URBANOS_INCIDENCIAS_QUERY,
        CREATE_INCIDENCIAS} from "./query";
import { InputTextComponent } from "../../components";
import {Dropdown} from 'primereact/dropdown'
import { useQuery,
        useMutation,
        useLazyQuery} from "@apollo/client";
import moment from 'moment';
import { UNIDADES_URBANOS_QUERY } from "../unidades-urbanos/queries";
import { useNotification } from "../../hooks";
import MultiSelect from "react-multi-select-component"; 
import { Botones } from "../abcmedios/components";

const FormIncidencias =(props)=>{
    //SetStates
    const [fields,setValueFields] = useState(props.datos);
    const [refresh, setRefresh] = useState(false);
    const [ruta, setRuta]=useState();
    const [idRuta, setIdRuta]=useState(0);
    const [elementoSelRuta, setElementoSelRuta]=useState(null)

    const [unidadesurbano, setUnidadesUrbano]=useState();
    const [idUnidadesUrbano, setIdUnidadesUrbano]=useState(0);
    const [elementoSelUnidadesUrbano, setElementoSelUnidadesUrbano]=useState(null);

    const [incidencia, setIncidencia]=useState(null);

    const [fecha, setFecha]=useState(moment().format('YYYY-MM-DD'));
    const [tomorrow, setTomorrow]=useState(moment().add(1, 'month').format('YYYY-MM-DD'));
    const [fecha_inicio, setFecha_inicio] = useState(fecha);
    const [fecha_fin, setFecha_fin] = useState(tomorrow);
    
    //Multiselect
    const[opciones, setOpciones]=useState([])
    const[selected, setSelected]=useState([])

    const incidentes=props.data;
    
    const incidencias=['Cartucho', 'Unidad Nueva', 'Salió de circulación', 'Se encuentra en el taller', 'Se encuentra en el corralón', 'Cambio de dueño', 'Falta Chofer', 'No tiene documentos para permiso'];
    
    //Notificaciones
    const {
        notification,
        showWarn
    } = useNotification();
    
    //Llenado de Dropdowns
    //Rutas
    const {loading:loadingRuta, data:dataRuta , error:errorRuta}= useQuery(LISTAR_RUTAS, {
        variables:{
            sort:"clave:ASC",
            where:{
                "activo":true,
                "ubicacion":1
            }
        }, onCompleted(dataRuta){
           // console.log("Rutas",dataRuta)
        }, onError(errorRuta){
            console.log("Error RUTAS: ",errorRuta)
        }
    });

    //Funciones OnSelect
    const onselectRuta=(selectR)=>{
        if(selectR.value){
            setElementoSelRuta(selectR.value);
            setRuta(selectR.value.nombre);
            setIdRuta(parseInt(selectR.value.id));
            console.log(selectR.value.nombre)
        }else{
            setElementoSelRuta(null);
            setRuta(null);
            setElementoSelUnidadesUrbano(null);
            setUnidadesUrbano(null)
        }
    }
    //Ecos
    const {loading:loadingEco, data:dataEco, error:errorEco}=useQuery(UNIDADES_URBANOS_QUERY, {
        variables:{
            sort:"id:ASC",
            where:{
                "activo":true,
                "ruta":idRuta
            }
        }, onCompleted(dataEco){
            //console.log("Ecos disponibles ", dataEco.unidadesUrbanos)
            const options=[]
            dataEco.unidadesUrbanos.forEach(element => {
                var x={
                    label:element.numeco,
                    value:element.id
                }
                options.push(x)
            });
            setOpciones(options)
            
        }, onError(errorEco){
            console.log("Error Ecos ",errorEco)
        }
    });

    //Funciones de Dropdown
    //Rutas
    const listarRutas=()=>{
        if(errorRuta) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingRuta) return  <IonSpinner name="dots" />;
        if(dataRuta)   return(
			<Dropdown
			name="rutas" 
			tabIndex={1}
			showClear={true}
			style={{width:'100%',borderRadius: "25px"}}
            dataKey='id'
            optionLabel='nombre'
            filter
            filterBy='nombre'
			value={elementoSelRuta} 
			options={dataRuta.rutas} 
			onChange={onselectRuta}
			placeholder='Selecciona la ruta'
			required={true}
		/>
		)
    }
    //Incidencias
    const listarIncidencias=()=>{
		return(
			<Dropdown
			name="listarIncidencias" 
			tabIndex={1}
			showClear={true}
			style={{width:'100%', borderRadius:"25px"}}
			value={incidencia} 
			options={incidencias} 
			onChange={e=>{
				setIncidencia(e.target["value"]);
			}}
			placeholder='Selecciona una incidencia'
			required={true}
		/>
		)
	}
    //NumEco
    const   OverrideString = {
        "selectSomeItems": "Seleccionar...",
        "allItemsAreSelected": "Todos los ECO están seleccionados.",
        "selectAll": "Todos",
        "search": "Buscar",
        "clearSearch": "Limpiar búsqueda"
    }

    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => "✔️ " + label)
            : "Seleccionar ECO";
    };

    const listarNumEco=()=>{
        if(errorEco) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingEco) return  <IonSpinner name="dots" />;
        if(dataEco)   return(
            <>
            <MultiSelect
                options={opciones}
                value={selected}
                onChange={setSelected}
                labelledBy="numeco"
                overrideStrings={OverrideString}
                valueRenderer={customValueRenderer}
            />
            </>
		)
    }
    const submit=()=>{
        selected.forEach(element=>{
            var id=parseInt(element.value)
            recorrerArray(id)
        })  
    }

    const recorrerArray=(id)=>{
        console.log("Recorrer Array", id)
        crearUrbanosIncidencias({
            variables:{
                input:{
                    data:{
                        unidades_urbano:id,
                        tipoIncidencia:incidencia,
                        inicia:fecha_inicio,
                        termina:fecha_fin
                    }
                }
            }
        })
    }

    const [crearUrbanosIncidencias]=useMutation(CREATE_INCIDENCIAS, {
        onCompleted(data){
            console.log(data)
            //props.onSuccess()
            cierraPopover()
        }, onError(error){
            console.log("Error ",error)
        }
    });

    //Componente de botones
	const botones = ()=>{
		return (
			<div>
				<IonRow>
					<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
						<div style={{ marginRight: "40px"}}></div>
						<Button
							label="Guardar"
							icon="pi pi-check"
							onClick={submit}
							className="p-button-success ion-float-right"
							style={{ marginLeft: "20px"}}
						/>
					</IonCol>
				</IonRow>
			</div>
		)
	}

    const cierraPopover = () => {
        props.onClose(incidentes)
    }

    //Render
    return(
            <IonGrid>
                {notification}
                <IonRow>
                    <IonCol>
                        <IonText>Ruta</IonText> 
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    {listarRutas()}
                    </IonCol>
                </IonRow> 
                <IonRow>
                    <IonCol>
                        <IonText>Incidencia</IonText>
                    </IonCol>
                    <IonCol>
                        <IonText>Fecha de Inicio de Incidencia</IonText>
                    </IonCol>
                    <IonCol>
                        <IonText>Fecha de Fin de Incidencia</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {listarIncidencias()}
                    </IonCol>
                    <IonCol>
                        <InputTextComponent
                            name={"fecha_inicio"}
                            type={"date"}
                            value={fecha_inicio}
                            style={{width:'100%',borderRadius: "25px"}}
                            required={true}
                            onChange={e =>{
                                setFecha_inicio(e);
                            }}
                            className={null}
                            disabled={null}
                            onKeyPress={null}
                            placeholder={null}
                            label={null}
                            accept={null}
                            onBlur={null}
                        />
                    </IonCol>
                    <IonCol>
                        <InputTextComponent
                            name={"fecha_fin"}
                            type={"date"}
                            value={fecha_fin}
                            style={{width:'100%',borderRadius: "25px"}}
                            required={true}
                            onChange={e =>{
                                setFecha_fin(e);
                            }}
                            className={null}
                            disabled={null}
                            onKeyPress={null}
                            placeholder={null}
                            label={null}
                            accept={null}
                            onBlur={null}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>NumEco</IonText>
                    </IonCol>
                </IonRow> 
                <IonRow>
                    <IonCol>
                        {listarNumEco()}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div>
                            {botones()}
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
    );
}

export default FormIncidencias;