import { gql } from "@apollo/client";

export const VISTA_QUERY=gql`
    query vistas($sort: String
        $limit: Int
        $start: Int
        $where: JSON){
            vistas(sort: $sort
                limit: $limit
                start: $start
                where: $where){
                    id
                    nombre
                    activo
                    createdby{
                        email
                    }
                    modifiedby{
                        email
                    }
                    created_at
                    updated_at
            }
    }
`;

export const CREATE_VISTA=gql`
    mutation crearVista($input:createVistaInput){
        createVista(input: $input){
            vista{
                id
                nombre
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
    
`;

export const UPDATE_VISTA=gql`
    mutation updateVista($input: updateVistaInput){
        updateVista(input:$input){
            vista{
                id
                nombre
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    } 
`;

export const DELETE_VISTA=gql`
mutation deleteVista($input: deleteVistaInput){
    deleteVista(input:$input){
        vista{
            id
            nombre
            createdby{
                email
            }
            modifiedby{
                email
            }
            created_at
            updated_at
        }
    }
}
`;
