import { gql } from '@apollo/client';

export const  MEDIOS_QUERY_WHERE = gql `
query listaMedio($where: JSON){
    medios(where: $where){
        id
        clave
        direccion
        vialidad
        colonia
        medida
        claveproveedor
        disponible
        activo
        comentarios
        referencias
        medida
        imagenmapaubicacion
        material
        tipoacabado
        vista{
            nombre
        }
        nicho
        Vista_media
        latitud
        longitud
        female_users
        male_users
        a13_17_users
        a18_25_users
        a26_40_users
        a41_55_users
        a55_users
        income_a_users
        income_b_users
        income_c_users
        income_d_users
        income_e_users
        total_hits
        total_users
        reach
        frequency
        tipos{
            id
            nombre
        }
        proveedor{
            id
            nombre
        }	
        ubicacion{
            nombre
            estado
        }
       
    }
} 
`;

export const  RUTAS_QUERY_WHERE = gql `
query listaRutas($where: JSON){
    rutas(where: $where){
        id
        nombre
        descripcion
        inicio
        fin
        activo
        kilometraje
        clave
        ubicacion{
            nombre
            estado
            imagenexhib
            activo
            tipoexhib
            preciourbano
            estatus
            lat
            lng
        }
        createdby{
            email
        }
        modifiedby{
            email
        }
        puntos(sort:"id:asc"){
            id
            lat
            lng
        }
    }
} 
`;

export const NEW_RUTAS_QUERY_WHERE = gql`
    query listaRutas($where: JSON){
        rutas(where: $where){
            nombre
            descripcion
            inicio
            fin
            activo
            kilometraje
            clave
            impactos_ruta
            reach
            frequency
            male_users
            female_users
            total_hits
            ubicacion{
                nombre
                estado
                imagenexhib
                activo
                tipoexhib
                preciourbano
                estatus
                lat
                lng
            }
            createdby{
                email
            }
            modifiedby{
                email
            }
            puntos(sort:"id:asc"){
                id
                lat
                lng
            }
        }
    }
    
`;

export const  LISTA_RUTAS_QUERY = gql`
    query listarRutas($sort: String, $limit: Int, $start: Int, $where: JSON){
        rutas(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            ubicacion{
                id
                nombre
            }
            nombre
            descripcion
            inicio
            fin
            activo
            clave
            kilometraje
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
        rutasConnection(where:$where){
            aggregate{
                count
            }
        }
    }
`;
