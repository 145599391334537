/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NumberFormat from 'react-number-format';
import { IonItem, IonText, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonGrid } from '@ionic/react';
import QRCode from 'qrcode.react';

const InfoSitio =(props)=>{
    const place = props.data;
    const urlStreetView = "http://www.google.com/maps?layer=c&cbll=" + place.latitud + "," + place.longitud;
    const getSticker = (tipo) =>{
        switch (tipo) {
            case 'Pantalla Digital':
                return '/assets/stickers/digital.png';
            case 'Espectaculares':
                return '/assets/stickers/espectacular.png';
            case 'Puente Peatonal':
                return '/assets/stickers/puente.png';
            case 'Puente':
                return '/assets/stickers/puente.png';
            case 'Valla':
                return '/assets/stickers/vallafija.png';
            case 'Valla Móvil':
                return '/assets/stickers/vallamovil.png';
            case 'Muro':
                return '/assets/stickers/muro.png';
            case 'Bajo Puente':
                return '/assets/stickers/bajo_puentes.png';
            case 'Cabecera':
                return '/assets/stickers/cabecera.png';
            case 'Arte en Muro':
                return '/assets/stickers/arte_en_muro.png';    
            case 'Mupi':
                return '/assets/stickers/mupi.png';
            case 'Pendones':
                return '/assets/stickers/pendones.png';
            case 'Puente digital':
                return '/assets/stickers/puente_digital.png';
            case 'Totem':
                return '/assets/stickers/totem.png';
            default:
                return '/assets/stickers/sitio.png';
        }
    }

    const sumaIngresos = place.income_a_users + place.income_b_users + place.income_c_users + place.income_d_users + place.income_e_users;
    const porcentajeIngreso = (ingreso) =>{
        return (ingreso*100)/sumaIngresos;
    }

    const sumaEdades=place.a13_17_users + place.a18_25_users + place.a26_40_users + place.a41_55_users + place.a55_users;
    const porcentajeEdades=(edades) =>{
        return (edades*100)/sumaEdades;
    }

    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol size='5' style={{ marginTop: '35px' }}>
                        <img src={place && place.Vista_media || '/assets/IMJ.png'} />
                    </IonCol>
                    <IonCol size='7'>
                        <IonCard  color="resaltar">
                            <IonCardHeader>
                                <IonCardSubtitle>{place && place.tipos && place.tipos.nombre || ''}</IonCardSubtitle>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol>
                                            <IonCardTitle>{place && place.clave || ''} <a href={urlStreetView} target="_blank"><img src="assets/street_view2.png" style={{ height: '30px' }} /></a></IonCardTitle>
                                        </IonCol>
                                        <IonCol style={{textAlign:'end'}}>
                                            {place && place.tipos && <img src={getSticker(place.tipos.nombre)} height={100} />}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                                
                            </IonCardHeader>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol className="ion-float-left" size='10'>
                                        <IonText color="secondary">
                                            <h1>{place && place.direccion || ''}-{place && place.ubicacion && place.ubicacion.nombre || ''}</h1>
                                        </IonText>
                                    </IonCol>
                                    <IonCol className="ion-float-left" size='10'>
                                        <IonText color="secondary">
                                            <h3>Latitud: {place.latitud} , Longitud: {place.longitud}</h3>
                                        </IonText>
                                    </IonCol>
                                </IonRow>

                                <IonRow className="ion-align-items-center height40">
                                    <IonCol >
                                        <IonText>
                                            <h3>Iluminación</h3>
                                        </IonText>
                                    </IonCol>
                                    <IonCol>
                                        {place && place.iluminacion && <b><p>Si</p></b>}
			                            {place && !place.iluminacion && <b><p>No</p></b>}
                                        {place && place.iluminacion===null && <b><p>No Disponible</p></b>}
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center height40">
                                    <IonCol >
                                        <IonText>
                                            <h3>Nivel:</h3>
                                        </IonText>
                                    </IonCol>
                                    <IonCol>
                                        <IonText>
                                            <b><p>{place && place.niveldeimpacto || ''}</p></b>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center height40">
                                    <IonCol >
                                        <IonText>
                                            <h3>Nicho:</h3>
                                        </IonText>
                                    </IonCol>
                                    <IonCol>
                                        <IonText>
                                            <b><p>{place && place.nicho || ''}</p></b>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonGrid>
                <IonRow>
                    <IonCol size='9' style={{ marginTop: '35px' }}>
                        <IonItem color="resaltar">
                            <img src='/assets/infinia/man.png' alt="hombres" height={50} /><b>&nbsp;&nbsp;<NumberFormat value={place.male_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b>&nbsp;&nbsp;
                            <img src='/assets/infinia/woman.png' alt="mujeres" height={50} /><b>&nbsp;&nbsp;<NumberFormat value={place.female_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b><br /><br />&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>Alcance: <b><NumberFormat value={place.reach * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;
                            <div>Frecuencia: <b><NumberFormat value={place.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div>&nbsp;&nbsp;
                            <div>Impactos: <b><NumberFormat value={place.total_hits} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div>
                        </IonItem>
                        <IonItem color="resaltar">
                            <div>NSE A : <b><NumberFormat value={porcentajeIngreso(place.income_a_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>NSE B : <b><NumberFormat value={porcentajeIngreso(place.income_b_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>NSE C : <b><NumberFormat value={porcentajeIngreso(place.income_c_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>NSE D : <b><NumberFormat value={porcentajeIngreso(place.income_d_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>NSE E : <b><NumberFormat value={porcentajeIngreso(place.income_e_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                        </IonItem>
                        <IonItem color="resaltar">
                            <div>Rango de edades: &nbsp;&nbsp;<br/></div>
                            <div>13 a 17: <b><NumberFormat value={porcentajeEdades(place.a13_17_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>18 a 25: <b><NumberFormat value={porcentajeEdades(place.a18_25_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>26 a 40: <b><NumberFormat value={porcentajeEdades(place.a26_40_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>41 a 55: <b><NumberFormat value={porcentajeEdades(place.a41_55_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <div>55 y más: <b><NumberFormat value={porcentajeEdades(place.a55_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;
                        </IonItem>
                        <IonItem color="resaltar">
                            <IonText>
                                <p><b>Referencias:</b> {place && place.referencias || ''}</p>
                            </IonText>
                        </IonItem>
                    </IonCol>
                    <IonCol size='3'>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <QRCode value={urlStreetView}/>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
}

export default InfoSitio;