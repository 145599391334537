import {gql} from '@apollo/client';


export const LISTA_INDOOR = gql `
    query listaIndoor($sort: String, $limit: Int, $start: Int, $where: JSON) {
        indoors(sort: $sort, limit: $limit, start: $start, where: $where) {
            id
            clave
            descripcion
            ficha_tecnica 
            {
                id
                created_at
                name
                url
                hash
                mime
                ext
            }
            claveproveedor
            base
            altura
            nivel
            proveedor{
                id
                nombre_comercial
            }
            precioventa
            costo
            piso
            comentarios
            imagen1 
            {
                id
                created_at
                name
                url
                hash
                mime
                ext
            }
            imagen2 
            {
                id
                created_at
                name
                url
                hash
                mime
                ext
            }
            tipo 
            {
                id
                nombre
                tipomedio
                {
                    nombre
                }
            }
            medio 
            {
                id
                clave
                proveedor
                {
                    nombre_comercial
                }
                claveproveedor
                latitud
                longitud
                ubicacion
                {
                    nombre
                }
                referencias
                comentarios
            }
            activo
            created_at
        }
    }
`;

export const UPDATE_ESTADO= gql `
    mutation cambiarIndoorEstatus($input: updateIndoorInput){
        updateIndoor(input:$input){
            indoor{
                id
                activo
            }
        }
    }

`;

export const LISTA_TIPO=gql `
    query listaTipos ($sort: String, $limit: Int, $start: Int, $where: JSON){
        tipos(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            nombre
            tipomedio{
                nombre
            }
        }
    }
`;

export const LISTA_MEDIOS=gql `
    query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            ubicacion{
                nombre
            }
            tipos{
                id
                nombre
                tipomedio{
                    nombre
                }
            }
            indoors{
                id
                clave
            }
        }
    }
`;

export const BUSCAR_INDOOR_POR_ID = gql `
    query listaIndoor($sort: String, $limit: Int, $start: Int, $where: JSON){
        indoors(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
        }
    }
`;

export const CREATE_INDOOR=gql `
    mutation crearIndoor($input:createIndoorInput){
        createIndoor(input:$input){
            indoor{
                id
                clave
            }
        }
    }
`;

export const UPDATE_INDOOR=gql `
    mutation updateIndoor($input:updateIndoorInput){
        updateIndoor(input:$input){
            indoor{
                id
                clave
            }
        }
    }
`;