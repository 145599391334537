import { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
  IonGrid,
  IonRow,
  IonLabel,
  IonCol
} from "@ionic/react";
import {LISTAR_ECOS, 
      CAMPANIAS_URBANOS_QUERY,
      URBANOS_INCIDENCIAS_QUERY} from './query';
import { useQuery} from "@apollo/client";
import { Toolbar } from "../../components";
import {Dropdown} from 'primereact/dropdown'
import moment from 'moment'
import "./style.css";
import FormResumenAnual from "./FormResumenAnual";
import useNotification from "../../hooks/useNotification";
import { TabPanel, TabView } from 'primereact/tabview';
import Asignaciones from "./Asignaciones"
import CorteMensual from "./CorteMensual"
import Filtros from "./Filtros";
import Disponibilidad from "./Disponibilidad";



const CampaniasUrbanos=(props)=>{
  const [activeIndex, setActiveIndex] = useState(0);
  const [incide, setIncide]=useState([])
  const [opcion, setOpcion]=useState(null)
  
  const {
    notification,
    showSuccess,
    showInfo,
    showError,
    } = useNotification();
/////////////////////Funciones que convierten al español la fecha. 
moment.updateLocale('es', {
  months:[
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ]
})
moment.updateLocale('es',{
  weekdaysShort: [
    "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
  ]
})
moment.updateLocale('es',{
  weekdaysMin: [
    "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
  ]
})

  ///ECOS
  const {loading: loadingUnidades, error:errorUnidades, data:dataUnidades}=useQuery(LISTAR_ECOS, {
    variables:{
        sort:"ruta.id:ASC",
        where:{
          "ruta.ubicacion":1
          // "activo":true,
          // "created_at_lt":moment().locale('es-mx').startOf("month").toDate()
        }
    },
    onCompleted:(data)=>{
      console.log(dataUnidades.unidadesUrbanos)
    }
  });
  //Incidencias
  const {loading:loadingIncidencias, error:errorIncidencias,data:dataIncidencias}=useQuery(URBANOS_INCIDENCIAS_QUERY, {
    variables:{
      sort:"id:ASC",
      where:{
        "unidades_urbano.activo":true
      }
    },
    onCompleted:(dataIncidencias)=>{
      setIncide(dataIncidencias.urbanosIncidencias)
    }
  });

    //Campanias Urbanos
    const {loading:loadingCampaniasUrbanos, error:errorCampaniasUrbanos, data:dataCampaniasUrbanos}=useQuery(CAMPANIAS_URBANOS_QUERY, {
      variables:{
        sort:"id:ASC",
        where:{
          "unidades_urbano.ruta.ubicacion":1
        }
      },
      onCompleted:(dataCampaniasUrbanos)=>{
      },
      onError(errorCampaniasUrbanos){
        console.log("ERROR: ", errorCampaniasUrbanos)
      }
    });

  
  const onSelOpc=(sel)=>{
    console.log(sel.value)
    if(sel){
      setOpcion(sel.value)
    }
    else{
      setOpcion(null)
    }
  }

    const año=moment().format('YYYY');
    const [anioSel, setAnioSel]=useState(null)
    const [mesSel, setMesSel]=useState(null)
    const [mesActual, setMesActual]=useState(moment().month('MM').format('MMMM'));
    
    const mesesOpc=[];
    for(var j=0;j<12; j++)
    {
        var y={
            id:moment().month(j).format('MM'),
            mes:moment.months(j),
            num:j
        }
        mesesOpc.push(y)
    }

    const opciones=[]
    for(var i=5; i>=0; i--){
        var resta=moment(año).subtract(i, "year").format('YYYY')
        var z={
            id:i,
            value:resta
        }
        opciones.push(z)
    }
    const porcentaje=[
      {
        percentage: 0.1,
        label: "10%"
      },
      {
        percentage: 0.2,
        label: "20%"
      },
      {
        percentage: 0.3,
        label: "30%"
      },
      {
        percentage: 0.4,
        label: "40%"
      },
      {
        percentage: 0.5,
        label: "50%"
      },
      {
        percentage: 0.6,
        label: "60%"
      },
      {
        percentage: 0.7,
        label: "70%"
      },
      {
        percentage: 0.8,
        label: "80%"
      },
      {
        percentage: 0.9,
        label: "90%"
      },
      {
        percentage: 1,
        label: "100%"
      },
    ]
    const [selPercentage, setSelPercentage]=useState(null)
    const [percentage, setPercentage]=useState(0)

    const onSelectAnio=(anio)=>{
      if(anio.value){
          setAnioSel(anio.value)
      }
      else{
          setAnioSel(null)
      }
  }

  const onselectMes=(mes)=>{
      if(mes.value){
          setMesSel(mes.value)
      }
      else{
          setMesSel(null)
      }
  }

  const onSelectPercentage=(pSel)=>{
    if(pSel.value){
      setSelPercentage(pSel.value)
      setPercentage(pSel.value.percentage)
    }
    else{
      setSelPercentage(null)
      setPercentage(0)
    }
  }


  return(
    <IonPage>
      <IonHeader>
        <Toolbar
          title={props.title}
          setMenuEnabled={props.setMenuEnabled}
          menuEnabled={props.menuEnabled}
        >
        </Toolbar>
      </IonHeader>
      <IonContent>
        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Consulta" disabled={false}>
            <Filtros incidencias={incide} campanias={dataCampaniasUrbanos} unidadesUrbanos={dataUnidades}/>
          </TabPanel>
          <TabPanel header="Asignación">
            <h2>Seleccionar la operación deseada</h2>
            <Dropdown
              name="tipoOperacion"
              options={["Asignar campaña", "Editar campaña", "Asignar incidencia", "Eliminar asignación"]}
              value={opcion}
              onChange={onSelOpc}
              style={{width:'50%', borderRadius:'25px'}}
              placeholder="Seleccione la operación"
              showClear={true}
            />
            <br/>
            <Asignaciones operacion={opcion}/>
          </TabPanel>
          <TabPanel header="Corte">
            <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonLabel>Seleccionar el mes:</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonLabel>Seleccionar el año:</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Dropdown
                                name="mes"
                                style={{borderRadius: "25px"}}
                                tabIndex={1}
                                showClear={true}
                                required={true}
                                placeholder={'Selecciona el mes de corte'}
                                options={mesesOpc}
                                value={mesSel}
                                optionLabel='mes'
                                onChange={onselectMes}
                            />
                        </IonCol>
                        <IonCol>
                            <Dropdown
                                name="año"
                                style={{borderRadius: "25px"}}
                                tabIndex={2}
                                showClear={true}
                                required={true}
                                optionLabel='value'
                                options={opciones}
                                value={anioSel}
                                onChange={onSelectAnio}
                                placeholder={'Selecciona el año del corte'}
                            />
                        </IonCol>
                    </IonRow>
                  </IonGrid>
            <br></br>
            <CorteMensual anio={anioSel} mes={mesSel}/>
          </TabPanel>
          <TabPanel header="Disponibilidad">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonLabel>
                    Seleccionar el mes:
                  </IonLabel>
                </IonCol>
                <IonCol>
                  <IonLabel>
                    Seleccionar el año:
                  </IonLabel>
                </IonCol>
                <IonCol>
                  Seleccionar el porcentaje de días de mes-calendario desocupados:
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <Dropdown
                    name="mes"
                    style={{borderRadius: "25px"}}
                    tabIndex={1}
                    showClear={true}
                    required={true}
                    placeholder={'Selecciona el mes de corte'}
                    options={mesesOpc}
                    value={mesSel}
                    optionLabel='mes'
                    onChange={onselectMes}
                  />
                </IonCol>
                <IonCol>
                  <Dropdown
                    name="año"
                    style={{borderRadius: "25px", maxWidth: '100%'}}
                    tabIndex={2}
                    showClear={true}
                    required={true}
                    optionLabel='value'
                    options={opciones}
                    value={anioSel}
                    onChange={onSelectAnio}
                    placeholder={'Selecciona el año del corte'}
                  />
                </IonCol>
                <IonCol>
                  <Dropdown
                    name="porcentaje"
                    style={{borderRadius: "25px"}}
                    tabIndex={3}
                    showClear={true}
                    required={true}
                    optionLabel='label'
                    options={porcentaje}
                    value={selPercentage}
                    onChange={onSelectPercentage}
                    placeholder={'Selecciona el porcentaje'}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <br/>
                <Disponibilidad anio={anioSel} mes={mesSel} porcentaje={percentage}/>
              </IonRow>
            </IonGrid>
          </TabPanel>
          <TabPanel header="Resumen Anual">
            <FormResumenAnual incidencias={incide} campanias={dataCampaniasUrbanos} unidadesUrbanos={dataUnidades}/>
          </TabPanel>
        </TabView>
      </IonContent>
    </IonPage>
  )
}
export default CampaniasUrbanos;