/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import noimage from './noimage.png';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import Resizer from 'react-image-file-resizer';
import { Slider } from 'primereact/slider';
import './ImageUpload.css';
import ReactLoading from 'react-loading';

const resizeFile = (file, width, height) => new Promise(resolve => {
    Resizer.imageFileResizer(file, width, height, 'PNG', 90, 0,
        uri => {
            resolve(uri);
        },
        'base64'
    );
});

const ImageUpload = (props) => {
    const [imgTemp, setImgTemp] = useState(props.imagen || '');
    const [zoom, setZoom] = useState(1);
    const [msg, setMsg] = useState(null);
    const editor = useRef(null);

    const setToastRef = (toast) => setMsg(toast);

    const [width, setWidth] = useState(0);
    useEffect(() => {
        if (props.width) {
            setWidth(props.width);
        }
    });

    const onChange = async (event) => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.click();

        input.onchange = e => {
            const file = e.target.files[0];
            const reader = new FileReader();
            const url = reader.readAsDataURL(file);

            reader.onloadend = (e) => {
                let img: string = reader.result as string;
                setImgTemp(img);
            }
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        // debugger;
        resizeFile(acceptedFiles[0], width !== 0 ? width : 600, props.height || 450).then((data) => {
            let img: string = data as string;
            setImgTemp(img); 
        })
    }, []);

    const onClickSave = () => {
        if (editor.current) {
            try {
                const canvas = editor.current.getImage().toDataURL();
                if (props.onSave) {
                    setImgTemp(canvas);
                    props.onSave(canvas);
                }
            } catch (error) {
                console.log('Error', error);
            }
        }
    }

    return (
        <div style={{ width: width !== 0 ? width : 300 }}>
            <Toast ref={(el) => setToastRef(el)}></Toast>
            <div>
                <Dropzone maxFiles={1} onDrop={onDrop} noClick={true}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <AvatarEditor ref={editor}
                                width={width !== 0 ? width : 300}
                                height={props.height || 225}
                                scale={zoom}
                                image={imgTemp} />
                            <input {...getInputProps()} />

                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <p>Arrastre y suelte aquí la foto</p>
                                    <Button type="button" label='Aceptar' onClick={onClickSave} />
                                </div>

                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </div>
    )
}

export default ImageUpload;
