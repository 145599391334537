/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { IonIcon, IonImg } from '@ionic/react'
import { IMJ_API_KEY } from '../../utils/Constanst'

type MarkerRutasProps = { key: number; id: number; lat: number; lng: number}
const MarkerRutas = (props: MarkerRutasProps) => {
    return (<div style={{ width: 50, height: 50, position: 'absolute', transform: 'translate(-50%, -80%)' }}>
        <IonImg src='/assets/markers/bus.png' style={{ width: 50, height: 50 }} />
    </div>)
}

export default function MapaVistaRutas(props) {
    const [map,setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [apiLoaded,setApiLoaded] = useState(false)
    const [defaultCenter,setDefaultCenter] = useState(props.locations[Math.floor(props.locations.length/2)]);

    const creaRuta = () =>{
        new maps!.Polyline({
            path: props.locations,
            strokeColor: '#' +  Math.random().toString(16).substr(-6),
            strokeOpacity: 1.0,
            strokeWeight: 2
        }).setMap(map);
    }

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        setMap(map);
        setMaps(maps);
        setApiLoaded(true);
        
        
    };
    
    useEffect(() => {
        if (props.locations && apiLoaded){
            if (props.locations.length>0){
                map.setCenter(props.locations[Math.floor(props.locations.length/2)]);
            }
            creaRuta();
            mostrarRuta();
        }
        
    }, [props.locations])

    const mostrarRuta =()=>{
        
        var infowindow = new google.maps.InfoWindow();
        let marker;
        let index ;

        let icon = {
            url: '/assets/icon8_bus.png', // url
            scaledSize: new google.maps.Size(30, 30), // scaled size
        };

        for (index = 0; index < props.locations.length; index++) {
            const element = props.locations[index];
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(element.lat,element.lng),
                map: map,
                icon: icon
              });
        
              google.maps.event.addListener(marker, 'click', (function(marker, i) {
                return function() {
                  let info ="<h4>latitud: "+element.lat +", longitud: "+element.lng+"</h4>";
                  infowindow.setContent(info);
                  infowindow.open(map, marker);
                }
              })(marker, index));
        }  
    }

    return (
        <div id="map_canvas" style={{ height: "60vh", width: "100%"}}>
          <GoogleMapReact
            key={'mapa'}
            bootstrapURLKeys={{ key: IMJ_API_KEY, libraries: ["places", "drawing"] }}
            defaultCenter={{lat:0,lng:0}}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            options={{
              mapTypeControl: false,
              streetViewControl: true,
              styles: [ { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }], }, ]
            }}
           
          >
            
          </GoogleMapReact>
          <br></br>
          <br></br>
      </div>
    )
}
