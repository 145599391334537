/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
} from "@ionic/react";

import { EmailField, renderBoolean } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import { QueryHookOptions, useLazyQuery, useMutation } from "@apollo/client";
import { Toolbar, MToolBar, UploadKML, DTableV2 } from "../../components";
import {
	LISTA_RUTAS_QUERY,
	UPDATE_RUTA_MUTATION,
	CREATE_RUTA_BY_KML_MUTATION,
	CREAR_PUNTO_KML_ARRAY,
	BORRA_PUNTOS_RUTA
} from './queries';

import client from '../../apollo/client';
import FormRutas from "./components/FormRutas";
import useAuthentication from "../../hooks/useAuthentication";
import { Button } from 'primereact/button';
import { useDialog } from "../../hooks";
import { FormImportRuta } from './components';
import './rutas.css'
import ReactLoading from 'react-loading';



const Rutas = (props) => {
	const { _FormDialogCustom: FormDialogKML } = useDialog();
	const [refresh, setRefresh] = useState(false);
	const { usuarioid } = useAuthentication();
	const [bandera, setbandera] = useState(false);
	const [datos, setdatos] = useState({});
	const [op, setop] = useState("");
	const [banderFormKML, setBanderFormKML] = useState(false);
	const [listRutas, setListRutas] = useState<any>([]);
	const [listNewRutas, setListNewRutas] = useState([]);
	const [loadingFlag, setLoadingFlag] = useState(false);
	const [errorUploadKML, setErrorUploadKML] = useState('');
	const [dataUserObject, setDataUserObject] = useState(null);
	const [banderaCrear, setBanderaCrear] = useState(false);
	const [, setEdit] = useState(false);
	const [visibleModalKML, setVisibleModalKML] = useState(false);
	const [buttonKMLDisable, setButtonKMLDisable] = useState(true);

	const {
		notification,
		showSuccess,
		showInfo,
		showError,
		showWarn,
	} = useNotification();

	/**
	 * Función para ordenar un arreglo con objetos por su id
	 * @param a Elemento a del arreglo
	 * @param b Elemento b del arreglo
	 * @returns 
	 */
	const compare = ( a, b ) => {
		if ( parseInt(a.id) < parseInt(b.id) ){
		  return -1;
		}
		if ( parseInt(a.id) > parseInt(b.id) ){
		  return 1;
		}
		return 0;
	  }

	/**
	 * Effecto que se encarga de obtener un lista con los registros de
	 * las rutas en el sistema y el cual se usa para filtrar las rutas
	 * ya creados en caso de que se intente importar nuevas.
	 */
	useEffect(() => {
		if (listRutas.length > 0) {
			setButtonKMLDisable(false);
		}
	}, [listRutas]);

	//Función para el switch de activo/inactivo
	const cambiarEstatus = (e) => {
		if (e.target.name === 'activo') {
			CambiarEstatusActivo(parseInt(e.target.id), e.target.value)
		}
	}
	const CambiarEstatusActivo = (id, activo) => {
		if (id) {
			updateRuta({
				variables: {
					input: {
						where: {
							id: id
						}, data: {
							activo: activo
						}
					}
				}
			})
		}
	}

	/////////////////////////////////////////***************Columnas para el grid*****************//////////////////////////////////////////////
	const columnas = [
		{
			header: "Id",
			field: "id",
			sortable: true
		},
		{
			header: "Clave",
			field: "clave",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Nombre",
			field: "nombre",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Ubicacion",
			field: "ubicacion.nombre",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Incio",
			field: "inicio",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		// {
		// 	header: "Fecha Creacion",
		// 	field: "created_at",
		// 	sortable: true
		// },
		{
			header: "Destino",
			field: "fin",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Kilometraje",
			field: "kilometraje"
		},
		{
			header: "Estatus",
			field: "activo",
			body: renderBoolean,
			customChange: cambiarEstatus,
			sortable: true
		},
		{
			header: "Creado por",
			field: "createdby.email",
			body: EmailField,
			sortable: true
		},
		{
			header: "Actualizado por",
			field: "codifiedby.email",
			body: EmailField,
			sortable: true
		}
	];
	////////////////////////////////////////////////FIN COLUMNAS DEL GRID//////////////////////////////////////////////////////////////////////


	const [listResponseLength, setListResponseLength] = useState(0);

	/**
	 * Efecto encargado de hacer las mutaciones necesarias
	 * para crear un ruta y sus respectivos puntos de localizacion
	 * 
	 * Dicho efecto cuenta con 2 eventos de mutacion cada uno encargado
	 * de dar de alta su respectivo registro: 
	 *  	@Ruta
	 * 		@Punto
	 */
	useEffect(() => {
		/**
		 * Funcion que arregla la nueva ruta al arreglo que valida si existe o no esa ruta
		 * @param newRoute 
		 */
		const updateListaRutas = (newRoute) => {
			const newListTemp = listRutas;
			newListTemp.push(newRoute);
			setListRutas(newListTemp);
		};

		/**
		 * Funcion encargada de ejecutar ambos metodos de mutacion
		 * internamente crea la ruta en base al objeto de estado listNewRoutes
		 * el cual debe de contener un arreglo con las rutas y sus puntos a dar de alta.
		 * 
		 * Despues de crear cada ruta itera el arreglo de puntos que contiene cada ruta y 
		 * las da de alta con su respectivo metodo
		 * @returns {boolean}
		 */
		const mutateEventsEffect = async () => {
			return new Promise(async (resolve, reject) => {
				try {
					setBanderaCrear(false);
					// Se itera el arreglo
					listNewRutas.forEach(async (kml, index) => {
						try {
							//Revisar si el objeto trae un id , si trae un id se trata de una edición de puntos
							if (kml.id) {
								//trae un id, actualizar los puntos de esa ruta
								let id = parseInt(kml.id); // Se obtiene el id de la ruta recien creada
								await promiseMutationPoint(kml.puntos, id)
								.then((data) => { // Termina la promesa anterior
									if (index === (listResponseLength - 1)) {
										// se setean las banderas para informar el estado de la mutacion para el usuario
										setBanderaCrear(false);
										setLoadingFlag(false);
									}
								})
								.catch(error => { // se cacha el error en caso de haberlo
									showError('No se creo la ruta: ', kml.nombre);
									setErrorUploadKML('No se creo la ruta: ' + kml.nombre)
									console.log('await client.mutate => error:', error)
								});
							updateListaRutas(kml); // se agrega la nueva ruta a la lista que valida si existe o no un registro.
							} else {
								// Se ejecuta la mutacion para crear la ruta
								await client.mutate({
									variables: {
										input: {
											data: {
												nombre: kml.nombre,
												descripcion: kml.descripcion,
												inicio: "",
												fin: "",
												activo: true,
												kilometraje: 0,
												createdby: dataUserObject.createdby,
												modifiedby: dataUserObject.modifiedby
											}
										}
									},
									mutation: CREATE_RUTA_BY_KML_MUTATION,
								}) // Despues de que se cree la ruta se ejecutan las mutaciones que crean los puntos ligados a la ruta.
									.then(async (result: any) => {
										let id = parseInt(result.data.createRuta.ruta.id); // Se obtiene el id de la ruta recien creada
										await promiseMutationPoint(kml.puntos, id); // se ejecuta la promesa que creara los registros de tipo puntos en base al arreglo de puntos y id que se envia como parametro
									}).then(() => { // Termina la promesa anterior
										if (index === (listResponseLength - 1)) {
											// se setean las banderas para informar el estado de la mutacion para el usuario
											setBanderaCrear(false);
											setLoadingFlag(false);
										}
									})
									.catch(error => { // se cacha el error en caso de haberlo
										showError('No se creo la ruta: ', kml.nombre);
										setErrorUploadKML('No se creo la ruta: ' + kml.nombre)
										console.log('await client.mutate => error:', error)
									});
								updateListaRutas(kml); // se agrega la nueva ruta a la lista que valida si existe o no un registro.
							}


						} catch (error) {
							console.log('error', error);
						}
					});
					resolve(true)
				} catch (error) {
					console.log('error', error);
					reject();
				}
			});
		}

		/**
		 * Funcion encarga de dar de alta en el sistema 1 punto de localización a la vez
		 * asociado a su ruta en especifico
		 * 
		 * Dicha funcion es una promesa que devuelve una respuesta que servira para
		 * informar al usuario sobre el estatus de subida
		 * @param kmlPointsParam
		 * @param idParam
		 * @returns {boolean}
		 */
		const promiseMutationPoint = async (kmlPointsParam: [any], idParam: number) => {
			return new Promise(async (resolve, reject) => {
				try {
					const kmlArrayToSend = [];
					for (let i = 0; i < kmlPointsParam.length; i++) {
						const kml = kmlPointsParam[i]; // Objeto que contiene la lat y lng de dicho punto de localizacion

						const kmlToSend = {
							lat: kml.lat,
							lng: kml.lng,
							ruta: idParam,
							createdby: dataUserObject.createdby,
							modifiedby: dataUserObject.modifiedby
						};
						kmlArrayToSend.push(kmlToSend);
					}
					/**
					 * Se ejecuta la mutacion de forma asincrona y 
					 * haciendo 1 sola solicitud por ruta enviando
					 * un arreglo de puntos.
					 */
					await client.mutate({
						variables: {
							input: {
								data: kmlArrayToSend
							}
						},
						mutation: CREAR_PUNTO_KML_ARRAY,
					});
					// Temporizador para darle un poco de tiempo de sobra para mostrar al usuario
					setTimeout(function () {
						//Código a ejecutar
						resolve(true) // En caso de que se finalice sin error devuelve un valor true la promesa
					}, 5000);
					// resolve(true) // En caso de que se finalice sin error devuelve un valor true la promesa
				} catch (error) { // Cacha el error en caso de que ocurra y devuelve la respuesta
					console.log('Error', error);
					reject();
				}
			});
		};
		// Valida que la bandera sea true para no ciclar la pantalla
		if (banderaCrear) {
			setBanderFormKML(true);
			setBanderaCrear(false); //  fuerza a la variable como false
			mutateEventsEffect().then(() => setLoadingFlag(true)); // Se ejecuta el evento que creara todos los registros
		}
	}, [banderaCrear, listNewRutas, loadingFlag, banderFormKML, showError, dataUserObject, listResponseLength, listRutas]);

	/**
	 * Metodo de prueba que permite agregar opciones a un query
	 * @returns {@QueryHookOptions<any, Record<string, any>>}
	 */
	const optionsQuery = () => {
		let options: QueryHookOptions<any, Record<string, any>> = {}
		options = {
			variables: {
				// sort: props.ordenarPor || 'id:asc',
				sort: 'id:desc',
				limit: 10,
			},
			errorPolicy: 'all',
		}
		return options;
	}


	/**
	 * Funcion que abre el modal que permitira
	 * la importacion de archivos KML
	 */
	const openModalKML = () => {
		setVisibleModalKML(true);
		setBanderaCrear(false);
	};

	const [updateRuta] = useMutation(UPDATE_RUTA_MUTATION, {
		onCompleted(data) {
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});

	const [borrarPuntosRuta] = useMutation(BORRA_PUNTOS_RUTA, {
		onCompleted: (result) => {

		}
	});

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */
	const CambiarEstatusRuta = (data) => {
		if (data) {
			updateRuta({
				variables: {
					input: {
						where: {
							id: data.id
						}, data: {
							activo: !data.activo
						}
					}
				}
			})
		}
	};

	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
	const AgregarRuta = () => {
		//showWarn("Título", "Mensaje de la notificación");
		let data = { createdby: usuarioid(), modifiedby: usuarioid() }
		let ope = "crear";
		setdatos(data);
		setop(ope);
		setbandera(true);
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) => {
		data.createdby = usuarioid();
		data.modifiedby = usuarioid();

		let ope = 'editar';
		setdatos(data);
		setop(ope);
		setbandera(true);
	}

	const onView = (data) => {
		let ope = 'detalles';
		setdatos(data);
		setop(ope);
		setbandera(true);
	}

	const onSuccess = () => {
		setRefresh(true);
		setbandera(false);
		console.log('onSucces');
	}

	const onDiscard = () => {
		console.log('onDiscard');
		setBanderFormKML(false);
		setbandera(false);
		// setEdit(false);
		showInfo('Acción cancelada', 'Se abortó correctamente la operación')
	}

	const onCloseModalKML = () => {
		setVisibleModalKML(false);
		setLoadingFlag(false);
		setBanderFormKML(false);
		setbandera(false);
		setErrorUploadKML('');
		// setEdit(false);
		console.log('onDiscarfModalKML');
	}

	///////////KML
	/**
	 * Funcion que da un formato manejable de la informacion que contiene el archivo KML
	 * @param arrayLatLng 
	 * @param dataUser
	 * @returns 
	 */
	const formatKMLData = (arrayLatLng, dataUser) => {
		let arrayLatLngObject = [];
		// Se itera el arreglo recibido para crear objetos manejables para el sistema
		for (let i = 0; i < arrayLatLng.length; i++) {

			let latLngObject = {
				lat: 0,
				lng: 0,
				createdby: "",
				modifiedby: "",
			}
			// Se separa el valor de lat y lng
			const separateLatLng = arrayLatLng[i].split(',');
			// Se valida que no este en blanco y se agrega a un arreglo
			if (separateLatLng.length > 1) {
				latLngObject.lat = parseFloat(separateLatLng[1]);
				latLngObject.lng = parseFloat(separateLatLng[0]);
				latLngObject.createdby = dataUser.createdby + '';
				latLngObject.modifiedby = dataUser.modifiedby + '';
				arrayLatLngObject.push(latLngObject);
			}
		}
		// Se retorna un arreglo formateado con los valores de lat y lng de cada punto
		return arrayLatLngObject;
	}

	/**
	 * Funcion que se ejecuta cuando pasa el evento OnDrop en el componente UploadKML
	 * el cual obtiene la informacion que viene en el KML y se procesa para su uso
	 * @param {[any]} response 
	 */
	const onCompleteImportKML = async (response: [any]) => {debugger;
		setLoadingFlag(true);
		let stringLatLng = ''; // String usado para manejar las cordenas obtenidas como una sola cadena
		const kmlArray = []; // Arreglo que almacenara cada ruta en particular

		let data = { createdby: usuarioid(), modifiedby: usuarioid() }  // Se obtiene los datos del usuario logeado
		setDataUserObject(data); // se setan dichos datos
		// console.log('onCompleteImportKML response:', response);

		if (response.length > 0) {
			setListResponseLength(response.length);
			// Se itera la respuesta que se obtuvo del archivo
			response.forEach(async r => {
				// Variables donde vienen las cordenadas
				if (r.LineString) {
					// Un forma en la que viene
					stringLatLng = r.LineString.coordinates._text;
				} else if (r.MultiGeometry) {
					// otra forma en la que viene
					const polygon = r.MultiGeometry.Polygon;
					polygon.forEach(p => {
						stringLatLng = p.outerBoundaryIs.LinearRing.coordinates._text;
					});
				}

				// Validar en caso de que venga vacio algun KML 
				if (stringLatLng === undefined) {
					return;
				}
				// console.log('stringLatLng', stringLatLng);
				const nameRoute = r.name._text.trim(); // Obteniendo el nombre de la ruta
				// Se verifica que el nombre de la ruta (Identificador) no se encuentre ya en la lista de rutas que viene de la BD
				let filtro = listRutas.filter(lr => lr.nombre === nameRoute + '');
				filtro = filtro.sort(compare);
				let descripcionRuta = '';
				// Se Obtiene la descripcion (Derroteo) de la ruta
				if (r.description) {
					if (r.description._text) {
						descripcionRuta = r.description._text;
					} else if (r.description._cdata) {
						descripcionRuta = r.description._cdata + '';
						if (descripcionRuta.includes('<')) {
							const response = descripcionRuta.split('width="auto" /><br><br>');

							if (response.length > 1) {
								descripcionRuta = response[1];
							} else {
								descripcionRuta = response[0];
							}
						}
					}
				} else {
					descripcionRuta = 'Sin Derrotero';
				}
				let dataJSON = {};
				// Se asignan las variables al nuevo objeto JSON que manejara la informacion de cada ruta 
				Object.assign(dataJSON, { nombre: nameRoute });
				const descripcionArray = descripcionRuta.split('<br>');
				Object.assign(dataJSON, { descripcion: descripcionArray[1] });
				// Si el filtro contiene almenos un elemento significa que ya existen en la BD.
				//Borrar los puntos de ese id de ruta para provocar la "edición"
				if (filtro.length > 0) {
					if (descripcionArray.length > 1 && filtro[0].ubicacion && filtro[0].ubicacion.nombre) {
						const ubicacionFiltro = filtro[0].ubicacion.nombre.toLowerCase();
						let estado = descripcionArray[0].toLowerCase();
						// Validacion por medio de la descripcion
						//Se debe validar por el nombre de la ruta
						if (dataJSON['nombre'] === filtro[0].nombre) {
							//La ruta ya existe, borrar los puntos actuales
							borrarPuntosRuta({
								variables: {
									idruta: parseInt(filtro[0].id),
								}
							}).then((data) => {
								Object.assign(dataJSON, { id: filtro[0].id });
								const arrayLatLng = stringLatLng.split('            ');
								const kmlResult = formatKMLData(arrayLatLng, data);
								Object.assign(dataJSON, { puntos: kmlResult });
								kmlArray.push(dataJSON); // Se agrega al arreglo que contienen las rutas a mutar
								setListNewRutas(kmlArray);
								setBanderaCrear(true);
							})
						} else {
							const arrayLatLng = stringLatLng.split('            ');
							const kmlResult = formatKMLData(arrayLatLng, data);
							Object.assign(dataJSON, { puntos: kmlResult });
							kmlArray.push(dataJSON); // Se agrega al arreglo que contienen las rutas a mutar
							setListNewRutas(kmlArray);
							setBanderaCrear(true);
						}
					} else {
						borrarPuntosRuta({
							variables: {
								idruta: parseInt(filtro[0].id),
							}
						}).then((data) => {
							Object.assign(dataJSON, { id: filtro[0].id });
							const arrayLatLng = stringLatLng.split('            ');
							const kmlResult = formatKMLData(arrayLatLng, data);
							Object.assign(dataJSON, { puntos: kmlResult });
							kmlArray.push(dataJSON); // Se agrega al arreglo que contienen las rutas a mutar
							setListNewRutas(kmlArray);
							setBanderaCrear(true);
						})

						//showWarn('La ruta ya se encuentra registrada y se omito O no se agrego su estado en la descripción');
						//setErrorUploadKML('La ruta ya se encuentra registrada y se omito O no se agrego su estado en la descripción');
					}
				}
				else {
					// Si no, se agregan al arreglo y este se mostrara al usuario final para su aprovacion.
					// o en caso de que ya se haya agregado a la BD solo mostrar el resultado.
					const arrayLatLng = stringLatLng.split('            ');
					const kmlResult = formatKMLData(arrayLatLng, data);
					Object.assign(dataJSON, { puntos: kmlResult });
					kmlArray.push(dataJSON); // Se agrega al arreglo que contienen las rutas a mutar
					// console.log('KML-ARRAY:',kmlArray);
					setListNewRutas(kmlArray);
					setBanderaCrear(true);
				}
			});
			// }

		} else {
			showWarn('El KML que se intenta subir esta vacio, favor de revisarlo');
			setErrorUploadKML('El KML que se intenta subir esta vacio, favor de revisarlo');
		}

	};

	///////////KML

	const mostrarContenido = () => {
		if (bandera === true) {
			return (
				<div style={{ display: (bandera === true ? "contents" : "none") }}>
					<br></br>
					<Button icon="pi pi-arrow-left" style={{ marginLeft: "20px" }} onClick={() => setbandera(false)} />
					<FormRutas
						datos={datos}
						operacion={op}
						onSuccess={onSuccess}
						onDiscard={onDiscard}
					/>
				</div>
			)
		}
	}

	return (
		<IonPage>
			<IonHeader>
				<Toolbar
					title={props.title}
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				<FormDialogKML
					onDiscard={onCloseModalKML}
					onSuccess={onSuccess}
					setVisible={setVisibleModalKML}
					visible={visibleModalKML}
					onHideEvent={onCloseModalKML}
					header={'Importar KML'}
				>
					<div className={'modal-content'}>
						{errorUploadKML.length === 0 ? (
							<>
								{!banderFormKML ? (
									<UploadKML
										setEdit={setEdit}
										width={680}
										height={388}
										onComplete={onCompleteImportKML}
									/>
								) : (
									<>
										{loadingFlag ? (
											<div style={{ marginLeft: 20 }}>
												<ReactLoading
													color={'#fcb32b'}
													type={'spin'}
												/>
											</div>
										) : (
											<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px', flexDirection: 'column' }}>
												{listNewRutas && listNewRutas.map((lr, index) => {
													return (
														<div key={index} style={{ display: "flex" }}>
															<FormImportRuta
																descripcion={lr.descripcion}
																nombre={lr.nombre}
																puntos={lr.puntos}
																errorMsj={errorUploadKML}
																key={index}
															/>
														</div>

													)
												})}
											</div>
										)}
									</>
								)}
							</>
						) : (
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}>
								<h1>{errorUploadKML}</h1>
							</div>
						)}
					</div>
				</FormDialogKML>

				<div style={{ display: (bandera === false ? "contents" : "none") }}>
					<MToolBar
						secondButtonDisable={buttonKMLDisable}
						addSecondClick={openModalKML}
						addClick={AgregarRuta}
					/>
					<DTableV2
						autoLayout={true}
						paginable={false}
						// ordenarPor='clave:asc'
						ordenarPor={'id:desc'}
						query={LISTA_RUTAS_QUERY}
						style={{ margin: "15px" }}
						columnas={columnas}
						element="rutas"
						onDelete={CambiarEstatusRuta}
						canView={true}
						canDelete={true}
						canEdit={true}
						onEdit={onEdit}
						onView={onView}
						refresh={refresh}
						onEndRefresh={() => setRefresh(false)}
						responsive={false}
						setListRutas={setListRutas}
					/>
				</div>
				{mostrarContenido()}
			</IonContent>
		</IonPage>
	);
}

export default Rutas;