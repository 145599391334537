import {gql} from "@apollo/client";

export const LISTAR_PAUTAS_QUERY = gql `
    query listar_pautas($sort: String,$limit: Int,$start: Int,$where: JSON){
        pautas(sort:$sort,limit:$limit,start: $start ,where: $where){
            id
            cliente
            nombre_campania
            fecha_inicio
            fecha_fin
            updated_at
            responsable
            {
                id
                nombre
            }
            campania{
                id
                nombre
                estatusinicial
            }
            estatus
            created_at
            createdby{
                nombre
            }
            modifedby{
                nombre
            }
            detailpautas{
                producto
                cantidad
                fecha_inicio
                fecha_fin
                descuento
                medio{
                    id
                    clave
                    tipos{
                        nombre
                    }
                    medida 
                    precioventa
                    direccion
                    ubicacion
                    {
                        nombre
                    } 
                    vista{
                        nombre
                    }
                }
                ruta{
                    id
                    nombre
                    descripcion
                    inicio
                    fin
                    ubicacion{
                        nombre
                        tipoexhib
                        preciourbano
                        }
                }
                circulospauta{
                    id
                    etiqueta
                    lat
                    lng
                    radio
                }
                
            }
        }
    }
`;


export const LISTA_PRODUCTOS_PAUTA_QUERY = gql `
     query listar_pautas($sort: String,$limit: Int,$start: Int,$where: JSON){
        pautas(sort:$sort,limit:$limit,start: $start ,where: $where){
            id
            cliente
            nombre_campania
            fecha_inicio
            fecha_fin
            responsable{
                id
                nombre
            }
            estatus
            campania{
                id
                nombre
            }
            updated_at
            detailpautas{
                producto
                cantidad
                fecha_inicio
                fecha_fin
                descuento
                medio{
                    id
                    clave
                    tipos{
                        nombre
                    }
                    precioventa
                    direccion
                    ubicacion
                    {
                        nombre
                    }
                    medida 
                    latitud
                    longitud
                }
                ruta{
                    id
                    nombre
                    descripcion
                    inicio
                    fin
                    ubicacion{
                        nombre
                        tipoexhib
                        preciourbano
                        }
                    puntos
                    {
                        lat
                        lng
                    }
                    reach
                    total_hits
                    impactos_ruta
                    male_users
                    female_users
                    kilometraje
                    frequency
                }
                circulospauta{
                    id
                    etiqueta
                    lat
                    lng
                    radio
                }
                
            }
        }
    }
`;

export const LISTAR_CLIENTES = gql `
    query ListarClientes($sort: String,$limit: Int,$start: Int,$where: JSON){
        clientes(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            idbitrix
            empresa  
        }
    }
`;

export const LISTAR_RESPONSABLES = gql `
query ListarResponsables($sort: String,$limit: Int,$start: Int,$where: JSON){
    users(sort:$sort,limit:$limit,start:$start,where:$where){
        id
        nombre
    }
}
`;

export const REGISTRO_BITACORAS = gql `
    mutation REGISTRO_BITACORAS($input:createBitacoraInput){
        createBitacora(input:$input){
            bitacora{
                id
                pauta{
                    id
                }
                Motivo
                Comentarios
                TipoRetrabajo
                created_at
            }
        }
    }
`

export const DELETE_DETAILS=gql `
    mutation DELETE_DETAILS($input:deleteDetailpautaInput){
        deleteDetailpauta(input:$input){
            detailpauta{
                id
            }
        }
    }
`;

export const BUSCAR_DETALLES = gql`
    query detallePauta($where:JSON){
        detailpautas(where:$where){
            id
            producto
            cantidad
            fecha_inicio
            fecha_fin
            descuento
            pauta{
                id 
            }
            medio{
                id
                clave
            }
            ruta{
                id
                nombre
            }
            circulospauta{
                id
                etiqueta
            }  
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
    }
`;

export const DELETE_CIRCULO=gql `
mutation DELETE_CIRCULO($input:deleteCirculospautaInput){
    deleteCirculospauta(input:$input){
        circulospauta{
            id
        }
    }
}
`;