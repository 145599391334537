import { gql } from "@apollo/client";
import client from "../../apollo/client";

const SITIOS = gql`
query medios($distancia: Float, $longitud: Float, $latitud: Float, $nombre:String, $iluminacion:String, $vistas:String, $nicho:String, $p_pmin:Float, $p_pmax:Float) {
    mediosCercanos(
      distancia: $distancia
      latitud: $latitud
      longitud: $longitud
      nombre: $nombre
      iluminacion: $iluminacion
      vistas: $vistas
      nicho: $nicho,
      p_pmin: $p_pmin,
      p_pmax: $p_pmax
    ) {
      id
      clave
    	ubicacion{
        nombre
      }
    	vista{
        nombre
      }
    	tipos{
        nombre
      }
      proveedor{
        nombre
      }
      female_users
      male_users
      a13_17_users
      a18_25_users
      a26_40_users
      a41_55_users
      a55_users
      income_a_users
      income_b_users
      income_c_users
      income_d_users
      income_e_users
      total_hits
      total_users
      reach
      frequency
      direccion
      medida
      claveproveedor
      latitud
      longitud
      disponible
      activo
      vialidad
      colonia
      niveldeimpacto
      iluminacion
      nicho
      referencias
      comentarios
      costo
      precioventa
      material
      tipoacabado
      imagenmapaubicacion
      largo
      ancho
      universo
    }
  }
`;

export type MediosMarkerProps = {
  distancia: number;
  latitud: number;
  longitud: number;
  nombres: string;
  iluminacion?: string;
  nicho?: string;
  vistas?: string;
  p_pmin?: number;
  p_pmax?: number;
};

export type MediosMarker = {
  key: number,
  nombre: string,
  tipo: string,
  lat: number,
  lng: number,
  medio: object,
  show: boolean,
  callback: any
}

export async function MediosSitios(props: MediosMarkerProps) {
  if (props.latitud>0) {
    const { data } = await client.query({
      query: SITIOS,
      variables: {
        distancia: props.distancia || 0,
        latitud: props.latitud || 0,
        longitud: props.longitud || 0,
        nombre: JSON.stringify(props.nombres.toString()) || JSON.stringify(['Espectaculares'].toString())
      }
    });
    return data.mediosCercanos;
  } else {
    return []
  }
}

async function MediosQuery(props: MediosMarkerProps) {
  const convertNichos = (nichos) =>{
    let stringTemp = '';
    nichos.forEach((nicho,index)=>{
        stringTemp += "'"+nicho+"'";
        if (index<nichos.length-1){
            stringTemp +=';'
        }
    })
    return stringTemp;
  }
  if (props.latitud>0) {
    const { data } = await client.query({
      query: SITIOS,
      variables: {
        distancia: props.distancia || 0,
        latitud: props.latitud || 0,
        longitud: props.longitud || 0,
        nombre: JSON.stringify(props.nombres.toString()) || JSON.stringify(['Espectaculares'].toString()),
        iluminacion: props.iluminacion || JSON.stringify('todos'),
        vistas: JSON.stringify(props.vistas) || JSON.stringify("\"Natural,Cruzada\""),
        nicho: JSON.stringify(props.nicho) || convertNichos(['B, C+, C','C, D, E','A, B, C+']),
        p_pmin: props.p_pmin || 0,
        p_pmax: props.p_pmax || 3000000
      }
    });
    return data.mediosCercanos;
  } else {
    return []
  }

}

export default MediosQuery;
