import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";

import { DateField, renderBoolean, renderCurrency } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import {
	DTableV2,
	MToolBar,
	Toolbar,
} from "../../components";import useDialog from "../../hooks/useDialog";
import { useMutation } from "@apollo/client";
import {UBICACIONES_QUERY,UPDATE_ESTATUS} from './queries';
import FormUbicaciones from './FormUbicaciones';
import useAuthentication from "../../hooks/useAuthentication";

const columnas = [
	{
		header: "Nombre",
		field: "nombre",
		filter: true,
		sortable: true,
		filterMatchMode: "contains"
	},
	{
		header: "Estado",
		field: "estado",
		filter: true,
		sortable: true,
		filterMatchMode: "contains"
	},
	{
		header: "Latitud",
		field: "lat",
		sortable: true
	},
	{
		header: "Longitud",
		field: "lng",
		sortable: true
	},
	{
		header: 'Tipo Exhibición',
		field: 'tipoexhib'
	},
	{
		header: 'Precio',
		field: 'preciourbano',
		body: renderCurrency
	},
	{
		header: 'Costo',
		field: 'costourbano',
		body: renderCurrency
	},
	{
		header: "Activo",
		field: "activo",
		body:renderBoolean,
		sortable: true
	},
	{
		header: "Última actualización",
		field: "updated_at",
		body: DateField,
		sortable: true
	}
];


const Ubicaciones = (props) => {
	
	const [refresh, setRefresh] = useState(false);
	const {usuarioid, rol} = useAuthentication();
	const [titleHeader, settitleHeader] = useState("Titulo");
	

	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();

	const [updateUbicacion] = useMutation(UPDATE_ESTATUS ,{
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});

    const {showDialog, FormDialog} = useDialog();

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */
	const Cambiar_estatus = (data) => {
	
		if (data){
			updateUbicacion({
				variables:{
					input:{
						where:{
							id:data.id
						},
						data:{ 
							activo:!data.activo
						}
					}
				}
			})
		}
		console.log(data.activo);
        
	};

	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
	const addUbicaciones = () => {
		let data = {nombre:'',Createdby:usuarioid(),Modifiedby:usuarioid()}
		showDialog(data, "crear");
		settitleHeader('Crear Ubicación');
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) =>{
		if (data.Createdby===null){
			data.Createdby = usuarioid();

		}else{
			var idcreatedby =data.Createdby.id;
			data.Createdby=parseInt(idcreatedby);
		}

		data.Modifiedby=usuarioid();

		showDialog(data,'editar');
		settitleHeader('Editar informaciòn de la ubicación');
		
	}

	const onView = (data) => {
		showDialog(data,"detalles");
		settitleHeader("Detalles de la ubicación");
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				<MToolBar addClick={addUbicaciones} />
				<DTableV2
					query={UBICACIONES_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element="ubicacions"
					ordenarPor={'nombre:asc'}
					onDelete={Cambiar_estatus}
					canView={true}
					canDelete={true}
					canEdit={
						rol()===('Administrador') ? true : false
					}
					onEdit={onEdit}
					onView={onView}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
					responsive={false}
					autoLayout={true}
				/>
				<FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormUbicaciones />
				</FormDialog>
			</IonContent>
		</IonPage>
	);
}

export default Ubicaciones;
