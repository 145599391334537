/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";

import { renderBoolean } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";

import {
	DTableV2,
	MToolBar,
	Toolbar,
} from "../../components";

import { useMutation } from "@apollo/client";
import {LISTA_USUARIOS,ACTUALIZAR_USUARIO} from './queries';
import FormUsuario from "./formusuario";
import useAuthentication from "../../hooks/useAuthentication";
import useDialog from "../../hooks/useDialog";

const columnas = [
    {
		header: "Nombre",
		field: "nombre",
		filter: true,
		sortable: true,
		filterMatchMode: "contains"
	},
    {
		header: "Nombre de Usuario",
		field: "username",
		filter: true,
		sortable: true,
		filterMatchMode: "contains"
	},
	{
		header: "Email",
		field: "email",
		filter: true,
		sortable: true,
		filterMatchMode: "contains"
    },
    {
		header: "rol",
		field: "role.name",
		filter: true,
		sortable: true,
		filterMatchMode: "contains"
    },
    {
		header: "Restringido",
		field: "blocked",
		sortable: true,
		body: renderBoolean
    },
];


const Usuarios = (props) => {
	
	const [refresh, setRefresh] = useState(false);
	const {usuarioid,rol} = useAuthentication();
	const {showDialog, FormDialog} = useDialog();
	const [titleHeader, settitleHeader] = useState('Titulo');
	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
    
   

	const AgregarUsuario = () => {
		let data = {createdby:usuarioid(),modifiedby:usuarioid()}
		
		showDialog(data, "crear");
		settitleHeader("Crear nuevo usuario");

    };

    const [ActualizarEstadoUsuario] = useMutation(ACTUALIZAR_USUARIO, {
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});
    
    const CambiarStatus = (data)=>{
        if(data){
            ActualizarEstadoUsuario({
				variables:{
					input:{
						where:{
							id:data.id
						},data:{
                            blocked:!data.blocked
						}
					}
				}
			})
        }
    }
    
	
	const onEdit = (data) =>{

        if (data.createdby===null){
			data.createdby = usuarioid();
		}else{
			var idcreatedby =data.createdby.id;
			data.createdby=parseInt(idcreatedby);
        }
        
        data.modifiedby = usuarioid();
        
		showDialog(data, "editar");
		settitleHeader("Editar información de usuario");
	}


	
	
	const onView = (data) => {
	
	}

	const onSuccess = () => {
		setRefresh(true);
		
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
	
	
	

	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				
			 
					<MToolBar addClick={AgregarUsuario} />
					<DTableV2
						query={LISTA_USUARIOS}
						style={{ margin: "15px" }}
						columnas={columnas}
						element="users"
						onDelete={CambiarStatus}
						canView={false}
						canDelete={true}
						canEdit={true}
						onEdit={onEdit}
						onView={onView}
						refresh={refresh}
						onEndRefresh={() => setRefresh(false)}
					/>
					<FormDialog
						contentStyle={{overflow:'visible'}}
						header={titleHeader}
						onSuccess={onSuccess}
						onDiscard={onDiscard}
					>
					<FormUsuario />
				</FormDialog>
			
			</IonContent>
		</IonPage>
	);
}

export default Usuarios;
