import {gql} from '@apollo/client';

export const LISTAR_CAMPANIA = gql `
    query listar_campania($sort: String,$limit: Int,$start: Int,$where: JSON){
        campanias(sort:$sort,limit:$limit,start: $start ,where: $where){
            id
            nombre
            inicia
            termina
            estatus
            responsable{
                id
                nombre
            }
            clientes{
                id
                nombre
                color
            }
        }
    }
`;