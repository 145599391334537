import React from 'react'
import { Chart } from 'primereact/chart';
import imjColors from './ImjColors';
import NumberFormat from 'react-number-format';

const ChartNSE = (props) =>{
	let dataGraph = {
		labels: ['NSE A','NSE B','NSE C','NSE D','NSE E'],
		datasets:[
			{
				backgroundColor: imjColors,
				data: [props.data.income_a_users,props.data.income_b_users,props.data.income_c_users,props.data.income_d_users,props.data.income_e_users],
				datalabels: {
					anchor: 'end'
				}
            }
		]
	}
	
	let options = {
		title:{
			display: true,
			text:'Afluencia por NSE'
		},
		legend: {
            display: true,
          	position: 'bottom',
            labels: {
				fontColor: '#333',
				fontSize: props.fontSize || '12'
            }
		},
		plugins: {
			datalabels: {
				backgroundColor: function(context) {
					return context.dataset.backgroundColor;
				},
				borderColor: 'white',
				borderRadius: 25,
				borderWidth: 2,
				color: 'white',
				font: {
					weight: 'bold',
					size: props.fontSize || 12
				},
				formatter: function(value, context) {
                    var dataset = context.dataset;
                    var sum = dataset.data.reduce(function(a, b){
                        return a + b;
                    }, 0);
                    let value2 = Math.round((dataset.data[context.dataIndex] / sum) * 100);
					value = new Intl.NumberFormat().format(value2)+' %';
					return value;
				}
			}
		  }
	}
    return (
		<div style={{textAlign:'center', fontSize: '10px'}}>
        	<Chart type="pie" data={dataGraph} options={options} />
			{props.showData && <div style={{display:'flex'}}>
			<div className="p-md-2">Alcance NSE A: <b><NumberFormat value={props.data.income_a_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance NSE B: <b><NumberFormat value={props.data.income_b_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance NSE C: <b><NumberFormat value={props.data.income_c_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance NSE D: <b><NumberFormat value={props.data.income_d_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
				<div className="p-md-2">Alcance NSE E: <b><NumberFormat value={props.data.income_e_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
			</div>}
					
		</div>	
    )
}

export default ChartNSE;
