import {gql} from '@apollo/client';

export const BORRA_PUNTOS_RUTA = gql`
    mutation borrarPuntos($idruta:Int!){
        borrarPuntos(idruta:$idruta){
            affected_rows
        }
    }
`;

export const UBICACIONES_QUERY = gql`
    query listaUbicaciones{
        ubicacions{
            id
            nombre
        }
    }
`;

export const  LISTA_RUTAS_QUERY = gql`
    query listarRutas($sort: String, $limit: Int, $start: Int, $where: JSON){
        rutas(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            ubicacion{
                id
                nombre
            }
            # created_at
            # updated_at
            nombre
            descripcion
            inicio
            fin
            activo
            clave
            kilometraje
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
        rutasConnection(where:$where){
            aggregate{
                count
            }
        }
    }
`;

export const GET_RUTA_BY_NAME =  gql`
    query listarRutas($where: JSON){
        ruta(where: $where){
            id
            ubicacion{
                id
                nombre
            }
            nombre
            descripcion
            inicio
            fin
            activo
            clave
            kilometraje
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
        rutasConnection(where:$where){
            aggregate{
                count
            }
        }
    }
`;

export const CREATE_RUTA_MUTATION = gql`
    mutation CrearRuta ($input:createRutaInput){
        createRuta(input:$input){
            ruta{
                id
                ubicacion{
                    id
                    nombre
                }
                nombre
                descripcion
                inicio
                fin
                activo
                clave
                kilometraje
                createdby{
                    email
                }
                modifiedby{
                    email
                }
            }
        }
    }
`;

export const CREATE_RUTA_BY_KML_MUTATION = gql`
    mutation CrearRuta ($input:createRutaInput){
        createRuta(input:$input){
            ruta{
                id
                nombre
                descripcion
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                inicio
                fin
                activo
                kilometraje
            }
        }
    }
`;

export const UPDATE_RUTA_MUTATION = gql`
    mutation ActualizarRuta ($input:updateRutaInput){
        updateRuta(input:$input){
            ruta{
                id
                ubicacion{
                    id
                    nombre
                }
                nombre
                descripcion
                inicio
                fin
                activo
                clave
                kilometraje
                createdby{
                    email
                }
                modifiedby{
                    email
                }
            }
        }
    }
`;

export const CREATE_RUTA_BY_KML_MUTATION_2 = gql`
    mutation CrearRuta ($input:createRutaInput){
        createRuta(input:$input){
            ruta{
                id
                nombre
                descripcion
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                inicio
                fin
                activo
                kilometraje
            }
        }
    }
`;

export const CREATE_POINTS_BY_KML = gql`
    mutation CrearPunto ($input:createPuntoInput){
        createPunto(input:$input){
            punto{
            id
            lat
            lng
            createdby{
                email
            }
            modifiedby{
                email
            }
            }
        }
    }
`;

export const LISTAR_PUNTOS_RUTA_QUERY = gql`
    query ListarPuntosRuta($id:ID!){
        ruta(id:$id){
            id
            puntos(sort:"id:asc"){
                id
                lat
                lng
            }
        }
    }
`;

export const INSERT_KML_ROUTE_AND_POINTS = gql`
    mutation insertKMLRouteAndPoints($inputRuta:createRutaInput!, $inputPoints: [createPuntoInput]){
        createRuta(input:$inputRuta){
            ruta{
                id
                nombre
                descripcion
                createdby{
                    email
                }
                modifiedby{
                    email
                }
                inicio
                fin
                activo
                kilometraje
            }
        }
        createPunto(input:$inputPoints){
            punto{
                id
                lat
                lng
                createdby{
                    email
                }
                modifiedby{
                    email
                }
            }
            
        }
    }
`;


export const CREAR_PUNTO = gql`
    mutation CrearPunto ($input:createPuntoInput){
        createPunto(input:$input){
            punto{
                id
                lat
                lng
                createdby{
                    email
                }
                modifiedby{
                    email
                }
            }
        }
    }
`;

export const CREAR_PUNTO_KML = gql`
    mutation CrearPunto ($input:createPuntoInput){
        createPunto(input:$input){
            punto{
            id
            lat
            lng
            createdby{
                email
            }
            modifiedby{
                email
            }
            }
        }
    }
`;


export const CREAR_PUNTO_KML_ARRAY = gql`
    mutation CrearPuntos ($input: createPuntoInputArray!){
        crearPuntos(input:$input){
            punto{
            id
            lat
            lng
            createdby{
                email
            }
            modifiedby{
                email
            }
            }
        }
    }
`;

export const UPDATE_PUNTO_MUTATION = gql`
    mutation ActualizaPunto($input: updatePuntoInput){
        updatePunto(input:$input){
            punto{
                id
                lat
                lng
            }
        }
    }
`;

export const DELETE_PUNTO_MUTATION = gql`
mutation deletePunto($id:ID!){
    deletePunto(input:{where:{id:$id}}){
        punto{
        id
        }
    }
}
`;
