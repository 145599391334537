import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";
import {Toolbar, MToolBar, DTableV2} from "../../components";
import {UNIDADES_URBANOS_QUERY, UPDATE_UNIDADES_URBANOS} from './queries';
import useAuthentication from "../../hooks/useAuthentication";
import useNotification from "../../hooks/useNotification";
import useDialog from "../../hooks/useDialog";
import FormUnidadesUrbano from "./FormUnidadesUrbano";
import { DateField, renderBoolean } from "../../utils/dataTableAux";
import { useMutation} from "@apollo/client";
import moment from 'moment';

const UnidadesUrbanos = (props) => {
    
    const [refresh, setRefresh] = useState(false);
    const {usuarioid} = useAuthentication();
    const [titleHeader, settitleHeader] = useState("Titulo");
    const {showDialog, FormDialog} = useDialog();

    const cambiarEstatus = (e) =>{
        if (e.target.name==='activo'){
            CambiarEstatusActivo(parseInt(e.target.id),e.target.value)
        }
    }

    const vigencia=moment().format('YYYY-MM-DD')
    const columnas = [
        {
            header: "Número ECO",
            field: "numeco",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Tipo de Contrato",
            field: "tipoContrato",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Cartuchos",
            field: "cartuchos",
            filter: true,
            sortable: true,
            filterMatchMode: "contains"
        },
        {
            header: "Activo",
            field: "activo",
            body: renderBoolean,
            customChange: cambiarEstatus
        },
        {
            header: "Ruta",
            field: "ruta.nombre",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Ciudad",
            field: "ruta.ubicacion.nombre",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Permisionario",
            field: "permisionario.permisionario",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Fecha de alta",
            field: "created_at",
            body: DateField
        },
        {
            header: "Vigencia hasta",
            field: "vigencia",
            body:DateField
        }
    ];

    
    const CambiarEstatusActivo =(id,activo) =>{
        if(id && activo===false){
            updateEstatus({
                variables:{
                    input:{
                        where:{
                            id:id
                        },data:{
                            activo:activo,
                            vigencia:vigencia
                        }
                    }
                }
            })
        }
        if(id && activo===true){
            updateEstatus({
                variables:{
                    input:{
                        where:{
                            id:id
                        },data:{
                            activo:activo,
                            vigencia:null
                        }
                    }
                }
            })
        }
    }

    const [updateEstatus] = useMutation(UPDATE_UNIDADES_URBANOS, {
        onCompleted(data) {
            setRefresh(true);
            showSuccess('Se actualizó el registro exitosamente');
        }
    });

    const {
        notification,
        showSuccess,
        showInfo,
        } = useNotification();

    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}

    const addUnidadesUrbanos=()=>{
        let data = {created_by:usuarioid(),updated_by:usuarioid()}
		showDialog(data, "crear");
		settitleHeader('Crear unidades urbanos');
    }
    const onEdit=(data)=>{
        showDialog(data,'editar');
		settitleHeader('Editar información de permisionario');
    }

    return(
        <IonPage>
            <IonHeader>
                <Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
            </IonHeader>
            <IonContent>
            <MToolBar addClick={addUnidadesUrbanos}/>
                {notification}
                <DTableV2
					autoLayout={true}
					paginable={false}
					ordenarPor='numeco:asc'
					query={UNIDADES_URBANOS_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element='unidadesUrbanos'
					canView={false}
					canDelete={false}
					canEdit={true}
					onEdit={onEdit}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
				/>
                <FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormUnidadesUrbano /> 
				</FormDialog>
            </IonContent>
        </IonPage>
    )
}
export default UnidadesUrbanos