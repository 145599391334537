import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";

import { DateField, EmailField,renderImageURL,renderBoolean } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import useDialog from "../../hooks/useDialog";
import { useMutation } from "@apollo/client";
import {Toolbar, MToolBar, DTableV2} from "../../components";
import {LISTA_MARCAS_QUERY,UPDATE_MARCA_MUTATION} from './queries';
import  Formabcmarcas from './formabcmarcas';
import useAuthentication from "../../hooks/useAuthentication";

const columnas = [
	{
		header: "Nombre",
		field: "nombre",
		sortable: true,
		filter: true
	},
	{
		header: "Logo",
		field: "logo",
		body: renderImageURL
	},
	{
		header: "Estatus",
		field: "estatus",
		body: renderBoolean
	},
	{
		header: "Creado por",
		field: "createdby.email",
		body: EmailField
	},
	{
		header: "Actualizado por",
		field: "modifiedby.email",
		body: EmailField
	},
	{
		header: "Última actualización",
		field: "updated_at",
		body: DateField
	}
];


const Abcmarcas = (props) => {
	const [refresh, setRefresh] = useState(false);
	const {usuarioid} = useAuthentication();
	const [titleHeader, settitleHeader] = useState("Titulo");

	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
	
	const [updateMarca] = useMutation(UPDATE_MARCA_MUTATION, {
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});
    
    const {showDialog, FormDialog} = useDialog();

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */

	const EliminarMarca = (data) => {
        if (data){
			updateMarca({ variables: { input: { where: { id: data.id }, data:{estatus:!data.estatus} } } });
		}
    };
    

	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
    
	const addmarca = () => {

		let data = {nombre:'',createdby:usuarioid(),modifiedby:usuarioid()}
		showDialog(data, "crear");
		settitleHeader('Crear marca');
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) =>{
		
		if (data.createdby===null){
			data.createdby = usuarioid();
		}else{
			var idcreatedby =data.createdby.id;
			data.createdby=parseInt(idcreatedby);
		}
        

		data.modifiedby=usuarioid();

		showDialog(data,'editar');
		settitleHeader('Editar informaciòn de la marca');
		
	}

	const onView = (data) => {
		showDialog(data,"detalles");
		settitleHeader("Detalles de la marca");
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				<MToolBar addClick={addmarca} />
				<DTableV2
					query={LISTA_MARCAS_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element="marcas"
					onDelete={EliminarMarca}
					canView={true}
					canDelete={true}
					canEdit={true}
					onEdit={onEdit}
					onView={onView}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
				/>
				<FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<Formabcmarcas />
				</FormDialog>
			</IonContent>

		</IonPage>
	);
}

export default Abcmarcas;