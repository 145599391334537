import React from 'react'

const DatosInfinia = (props) => {
    return (
        <div style={{width:props.width, 
            height:props.height, 
            left:props.left, 
            right:props.right, 
            top:props.top, 
            
            position:'absolute'
            }}>
            {/*<div style={{width:'100%', height:'23px',backgroundColor:'black',color:'white', paddingLeft:'5px'}}>{props.title}</div>*/}
            <div id='infinia' style={{marginLeft:'5px', maxHeight:props.height}}>{props.children}</div>
        </div>
    )
}

export default DatosInfinia;
