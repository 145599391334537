import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";

import { EmailField ,renderBoolean} from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import {
	DTableV2,
	MToolBar,
	Toolbar,
} from "../../components";
import useDialog from "../../hooks/useDialog";
import { useMutation } from "@apollo/client";
import {TIPOS_QUERY,UPDATE_ESTATUS} from './queries';
import FormTipos from './FormTipos';
import useAuthentication from "../../hooks/useAuthentication";


const Tipos = (props) => {
	
	const cambiarSwitch=(e)=>{
		console.log(e.target)
		console.log(e.target.id)
		if (e.target.name==='estatus'){
			CambiarEstatus(e.target)
		}
	}

	const columnas = [
		{
			header: "Nombre",
			field: "nombre",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Tipo de Medio",
			field: "tipomedio.nombre",
			sortable: true,
			filter: true,
			filterMatchMode:"contains"
		},
		{
			header: "Creado por",
			field: "Createdby.email",
			body: EmailField,
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Actualizado por",
			field: "Modifiedby.email",
			body: EmailField,
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Estatus",
			field: "estatus",
			body: renderBoolean,
			sortable: true,
			name:'estatus',
			customChange: cambiarSwitch
		},
	];
	
	const [refresh, setRefresh] = useState(false);
	const {usuarioid} = useAuthentication();
	const [titleHeader, settitleHeader] = useState("Titulo");

	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
	
	const [updateTipo] = useMutation(UPDATE_ESTATUS, {
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		},onError(error){
			console.log(error, 'ERROR')
		}
	});

    const {showDialog, FormDialog} = useDialog();

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */

	const CambiarEstatus = (data) => {
	
		if (data){
			updateTipo({ 
				variables: { 
					input: { 
						where: { 
							id: parseInt(data.id )
						}, data:{
							estatus:!data.estatus
						} 
					} 
				} 
			});
		}
	
	};



	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
	const addTipo = () => {
		
		let data = {nombre:'',Createdby:usuarioid(),Modifiedby:usuarioid()}
		showDialog(data, "crear");
		settitleHeader('Crear tipo');
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) =>{
    
    
		if (data.Createdby===null){
			data.Createdby = usuarioid();
		}else{
			var idcreatedby =data.Createdby.id;
			data.Createdby=parseInt(idcreatedby);
		}
        
		data.Modifiedby=usuarioid();

		showDialog(data,'editar');
		settitleHeader('Editar información de tipo');
		
	}

	const onView = (data) => {
		showDialog(data,"detalles");
		settitleHeader("Detalles del tipo");
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				<MToolBar addClick={addTipo} />
				<DTableV2
					query={TIPOS_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element="tipos"
					onDelete={CambiarEstatus}
					canView={true}
					canDelete={true}
					canEdit={true}
					onEdit={onEdit}
					onView={onView}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
				/>
				<FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormTipos />
				</FormDialog>
			</IonContent>
		</IonPage>
	);
}

export default Tipos;
