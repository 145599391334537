import { useCallback, useState } from "react";
import {
    IonCol
} from "@ionic/react";
import moment from 'moment'
import "./style.css";
import "react-calendar-timeline/lib/Timeline.css";
import Timeline, 
    {
        TimelineHeaders,
        SidebarHeader,
        DateHeader
    } from "react-calendar-timeline/lib";

    const Calendario=(props)=>{
        /////////////////////Funciones que convierten al español la fecha. 
        moment.updateLocale('es', {
            months:[
                "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
            ]
        })
        moment.updateLocale('es',{
            weekdaysShort: [
                "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
            ]
        })
        moment.updateLocale('es',{
            weekdaysMin: [
                "Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"
            ]
        })

        var keys = {
            groupIdKey: "id",
            groupTitleKey: "title",
            groupRightTitleKey: "rightTitle",
            itemIdKey: "id",
            itemTitleKey: "title",
            itemDivTitleKey: "title",
            itemGroupKey: "group",
            itemTimeStartKey: "start",
            itemTimeEndKey: "end",
            groupLabelKey: "title"
        };

        return(
            <div>
                <Timeline
                    groups={props.groups}
                    items={props.items}
                    itemHeightRatio={0.75}
                    stackItems={true}
                    itemsSorted
                    showCursorLine
                    defaultTimeStart={props.inicia}
                    defaultTimeEnd={props.termina}
                    height='100%'
                    keys={keys}
                    onItemDoubleClick={props.doubleClic}
                    sidebarContent={<div></div>}
                    itemTouchSendsClick={true}
                    canChangeGroup={false}
                    sidebarWidth={340}
                    rightSidebarContent={<div><h3>Cartuchos</h3></div>}
                    rightSidebarWidth={171}
                >
                    <TimelineHeaders className="sticky">
                        <SidebarHeader>
                            {({ getRootProps }) => {
                                return <div {...getRootProps()}>
                                            <h3>ECO</h3>
                                            <IonCol>
                                                Ruta
                                            </IonCol>
                                            <IonCol>
                                                Numeco
                                            </IonCol>
                                            <IonCol>
                                                Permisionario
                                            </IonCol>
                                            <IonCol>
                                                Contrato
                                            </IonCol>
                                    </div>;
                            }}
                        </SidebarHeader>
                        <DateHeader 
                            unit="primaryHeader"
                            className="header"
                            />
                        <DateHeader />
                    </TimelineHeaders>
                </Timeline>
            </div>
        )

    }
    export default Calendario
