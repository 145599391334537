/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect ,useContext} from 'react'
import { gql } from '@apollo/client';
import { ApolloProvider, useLazyQuery } from '@apollo/client';
import styles from './RutaSelect.module.css';
import MultiSelect from "react-multi-select-component";
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import ReactDOM from 'react-dom';
import ReactDOMServer from "react-dom/server";
import cliente from "../../apollo/client";
import { InfoWindowRutas } from '../../pages/sitios/components';

const RUTAS=gql`
    query listarRutas($sort: String, $limit: Int, $start: Int, $where: JSON){
        rutas(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            ubicacion{
                id
                nombre
                tipoexhib
                preciourbano
            }
            nombre
            descripcion
            inicio
            fin
            activo
            # puntos(sort:"id:asc"){
            #     id
            #     lat
            #     lng
            # }
            impactos_ruta
            reach
            frequency
            male_users
            female_users
            total_hits
            kilometraje
        }
    }
`;

const RUTAS_SELEC=gql`
    query listarRutas($sort: String, $limit: Int, $start: Int, $where: JSON){
        rutas(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            ubicacion{
                id
                nombre
                tipoexhib
                preciourbano
            }
            nombre
            descripcion
            inicio
            fin
            activo
            puntos(sort:"id:asc"){
                id
                lat
                lng
            }
            impactos_ruta
            reach
            frequency
            male_users
            female_users
            total_hits
            kilometraje
        }
    }
`;

const   OverrideString = {
    "selectSomeItems": "Seleccionar...",
    "allItemsAreSelected": "Todas las rutas están seleccionadas.",
    "selectAll": "Todas",
    "search": "Buscar",
    "clearSearch": "Limpiar búsqueda"
  }

export default function RutasSelect(props) {
    const [rutasBusqueda,setRutasBusqueda] = useState([]);
    const [selectValues,setSelectedValues] = useState(props.preSelectedRutas||[]);
    const [labels,setLabels] = useState([]);
    const [polys,setPolys] = useState([]);
    const [ciudad,setCiudad] = useState(0);
    const [loading,setLoading] =useState(false);
    const {addRutas} = useContext(ShoppingCartContext);
     // Fin estados para InfoWindow
    let r = document.querySelector(':root');
    r['style'].setProperty('--rmsc-radius','25px !important');

    const [refetch] = useLazyQuery(RUTAS,{
        onCompleted: (datos) =>{
            let rutasOptions = datos.rutas.map((ruta)=>{
                return {label:ruta.nombre,value:{...ruta}}
            });
            setRutasBusqueda(rutasOptions);
            if (props.selectAll){
                onSelect(rutasOptions);
                //Pintar el cuadro de seleccionadas y las rutas
            }
            setLoading(false);
        }
    });

    useEffect(() => {
        if (props.ciudad && ciudad !== props.ciudad && parseInt(props.ciudad)>0){
            setCiudad(props.ciudad);
            setLoading(true);
            refetch({
                variables:{
                    sort:'nombre:ASC',
                    where:{
                        activo:true,
                        ubicacion: parseInt(props.ciudad)
                    }
                }
            });
        }
    }, [props.ciudad]);

    useEffect(()=>{
        if (props.preSelectedRutas){
            reDrawRutas(props.preSelectedRutas);
        }
    },[props.preSelectedRutas])

    const limpiaPolys = () =>{
        polys.map((polyline)=>{
            polyline.setMap(null);
        });
        setPolys([]);
        setLabels([]);
    }

    const createInfoWindow= (poly, ruta) => {
        let InformacionRuta = ReactDOMServer.renderToString(<div id='infocontainer' />);
        
        const infowindow = new google.maps.InfoWindow();
        google.maps.event.addListener(poly, 'click', function (event) {
            infowindow.close();

            infowindow.setContent(InformacionRuta);

            infowindow.setPosition(this.map.getCenter());

            infowindow.open(this.map);

            const divElement = document.getElementById('infocontainer');
            if (divElement) {
                ReactDOM.render(<ApolloProvider client={cliente}>
                    <InfoWindowRutas ruta={ruta}/>
                </ApolloProvider>, document.getElementById('infocontainer'))
            } else {
                this.observer = new MutationObserver(() => {
                    const divElement = document.getElementById('infocontainer');
                    if (divElement) {
                        if (this.observer) {
                            this.observer.disconnect();
                            this.observer = null;
                        }
                        ReactDOM.render(<ApolloProvider client={cliente}>
                            <InfoWindowRutas ruta={ruta}/>
                        </ApolloProvider>, divElement)
                    }
                });
                this.observer.observe(document, { subtree: true, childList: true });
            }


        });
        google.maps.event.addListener(poly, 'dblclick', function (event) {
            infowindow.close();
        });
    }

    const pintaRutas = (rutasPoly)=>{
        let tempPolys = [];
        rutasPoly.map((ruta)=>{
            let poly = new google.maps.Polyline({
                path: ruta.ruta,
                strokeColor: ruta.color,
                strokeOpacity: 1.0,
                strokeWeight: 3
            });
            poly.setMap(props.mapa);
            createInfoWindow(poly,ruta.rutaOriginal);
            tempPolys.push(poly);
        })
        setPolys(tempPolys)
    }

    const [fetchQuery] = useLazyQuery(RUTAS_SELEC,{
        onCompleted: (data) =>{
            if (props.addToShopping){
                addRutas(data.rutas);
            }
            let rutasTemp = [];
            data.rutas.map((ruta)=>{
                let color = '#' +  Math.random().toString(16).substr(-6);
                rutasTemp.push(    {
                    id: ruta.id,
                    color: color,
                    label: ruta.nombre,
                    ruta: ruta.puntos,
                    descripcion: ruta.descripcion,
                    inicio: ruta.inicio,
                    fin: ruta.fin,
                    precio:ruta.ubicacion.preciourbano,
                    tipoexhib:ruta.tipoexhib,
                    ubicacion:ruta.ubicacion.nombre,
                      rutaOriginal: ruta, //se requiere para el infowindow
                    impactos: ruta.impactos_ruta,
                    alcance: ruta.reach,
                    frecuencia: ruta.frequency,
                    maleu: ruta.male_users,
                    femaleu: ruta.female_users,
                    alcancetotal:ruta.total_hits
                    }
                )
                //Registrar el color y crear un objeto que contenga {color:#333,label:"texto ruta"}
            })
            
            setLabels(rutasTemp);
            pintaRutas(rutasTemp);
        }
    });

    const customValueRenderer = (selected, _options) => {
        return selected.length
        ? selected.map(({ label }) => "✔️ " + label)
        : "Seleccionar ruta";
    };


    const reDrawRutas = (selectedList)=>{
        console.log("RUTASELECCIONADAS", selectedList)
        
        let rutasSeleccionadas = selectedList.map((ruta)=>{
            if(ruta.value){
                if(ruta.value.id){
                    console.log("Entra a value id")
                    return parseInt(ruta.value.id)
                }
            }
            else {
                console.log("Entra a id???")
                return parseInt(ruta.id)
                
            }
        });
        limpiaPolys();
        if (rutasSeleccionadas.length>0){
            fetchQuery({
                variables:{
                    where:{
                        id_in: [...rutasSeleccionadas]
                    }
                }
            });
        }
        if (props.onSelectedList){
            props.onSelectedList(selectedList);
        }
    }
    const onSelect=(selectedList) => {
        let rutasShop = selectedList.map((ruta)=>ruta.value);
        // RutasSelect solo debe agregar las rutas  a pautas cuando se ejecuta en sitios, pero no en urbanos
        
        
        let rutasSeleccionadas = selectedList.map((ruta)=>{return parseInt(ruta.value.id)});
        limpiaPolys();
        if (rutasSeleccionadas.length>0){
            fetchQuery({
                variables:{
                    where:{
                        id_in: [...rutasSeleccionadas]
                    }
                }
            });
        }
        if (props.onSelectedList){
            props.onSelectedList(selectedList);
        }
        setSelectedValues(selectedList);
    }
    
    if (rutasBusqueda){
        return (
            <div style={{display:'flex',flexDirection:'column',  position:'absolute',
            zIndex:10000}}>
                  <MultiSelect value={selectValues} 
                    options={rutasBusqueda} 
                    onChange={onSelect} 
                    labelledBy={"Selector de rutas"}
                    valueRenderer={customValueRenderer}
                    selectAllLabel='Todas'
                    isLoading={loading}
                    overrideStrings={OverrideString}
                    />
                {(props.showLabels || false) && 
                    <div className={styles.listaRutas} style={{maxHeight:window.innerHeight-180}}>
                        {labels.map((etiqueta)=>{
                            return (
                                <div key={Math.random()} style={{display:'flex',flexDirection:'row',textAlign:'left', marginLeft:'10px'}}>
                                    <div style={{backgroundColor:etiqueta.color,width:'10px',height:'10px',margin:'auto 5px auto 5px'}}></div>
                                    <p>{etiqueta.label}</p>
                                </div>
                            )
                        })}
                    </div>}
                  
            </div>
        )
    }
    
}
