import React, { useState, useEffect } from "react";
import { IonRow, IonCol, IonGrid, IonSpinner } from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";

import { useMutation, useQuery } from "@apollo/client";
import {TIPOS_QUERY,CREATE_TIPO,UPDATE_TIPO, TIPOMEDIO} from "./queries";
import { InputTextComponent } from "../../components";
import {Dropdown} from 'primereact/dropdown'

export default function FormTipos(props){
	console.log(props.datos)
    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
			nombre: props.datos.nombre,
			tipomedio:props.datos.tipomedio
		}
    });
	const [fields,setValueFields] = useState(props.datos);
    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
        delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		return fieldtemp;
	};
	const [tipoMedioSel, setTipoMedioSel]=useState(null)
	const [tipoMedioId, setTipoMedioId]=useState(0)
	const [nombre, setNombre]=useState("")
	//////////////////////////////////////////////////////////TIPO DE MEDIOS/////////////////////////////////
	const {loading: loadingTiposMedios, error: errorTiposMedios, data: dataTiposMedios}=useQuery(TIPOMEDIO, {
		onCompleted:(dataTiposMedios)=>{
			
		},
		onError(errorTiposMedios){
			console.log('ERROR: ',errorTiposMedios)
		}
	})

	const onTipoMedioChange=(tmSel)=>{
		if(tmSel){
			setTipoMedioSel(tmSel.value)
			setTipoMedioId(parseInt(tmSel.value.id))
			console.log(tmSel.value)
			console.log(tmSel.value.id)
		}
		else{
			setTipoMedioSel(null)
			setTipoMedioId(0)
		}
		
	}
	const dropdownTipoMedios=()=>{
		if(loadingTiposMedios) return <IonSpinner name="dots"></IonSpinner>
		if(errorTiposMedios) return <h5 color="red">Algo salió mal</h5>
		//fields.tipomedio
		if(dataTiposMedios) return(
			<span className="p-float-label ">
				<Dropdown
					id='tipos'
					value={(tipoMedioSel === null || tipoMedioSel===undefined ? fields.tipomedio : tipoMedioSel)}
					tabIndex={1}
					showClear={true}
					options={dataTiposMedios.tipomedios}
					dataKey='id'
					style={{borderRadius: "25px", width:"90%"}}					
					optionLabel='nombre'
					onChange={onTipoMedioChange}
				/>
				<label htmlFor="in">Tipo Medio</label>
			</span>
		)
	}


    const [updateTipo] = useMutation(UPDATE_TIPO, {
		variables: {
			input: {
				where: { id: fields.id },
				//data: getFieldsUpdate()
				data: {
					nombre:fields.nombre,
					tipomedio:fields.tipoMedioId,
					modifiedby:props.modifiedby
				}
				
			}
		},
		onCompleted(data){
            props.onSuccess();
		}
	});


    const [createTipo] = useMutation(CREATE_TIPO, {
		variables: {
			input: {
				data: {
					nombre:nombre,
					tipomedio:tipoMedioId,
					createdby:props.Createdby,
					modifiedby:props.Modifiedby,
					estatus: true
				}
			}
		},
		refetchQueries: [{ query: TIPOS_QUERY }],
		onCompleted(data) {
			props.onSuccess();
		}
	});
    
	const onSubmit = data => { 
		
        if (props.operacion==='editar'){
		
			updateTipo();
			
		}else if (props.operacion === 'crear'){
			
		
            createTipo();
        }else{
		
		}
   
    }

    useEffect(() => {
		register({ name: "nombre" });
	}, [register]);
    
    const handleChange = (e, name) =>{
		setValueFields({...fields,[name]:e})
	}
	
	const botones = ()=>{
		if(props.operacion === 'editar' || props.operacion === 'crear'){
			return (
				<div >
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>
			)
		}else{
			return (<div></div>)
		}
	}
	
	
	return (
		<form onSubmit={handleSubmit(onSubmit)} >
			<IonGrid >
				<IonRow >
					<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						{dropdownTipoMedios()}
					</IonCol>
					<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						<span className="p-float-label ">
							<InputTextComponent
								name="nombre"
								value={(nombre === "" ? fields.nombre : nombre)}
								style={{ width: "100%",color:'black', borderRadius: '25px'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'nombre');
									setValue("nombre", e);
									setNombre(e)
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Nombre</label>
						</span>
					</IonCol>
				</IonRow>
				<br/><br/>
					<div>{botones()}</div>
				<IonRow>
					
				</IonRow>
			</IonGrid>
		</form>
	);

}