/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@apollo/client';
import { IonSpinner } from '@ionic/react';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { listaProveedores_Query, listaTipos_Query } from '../queries/queries';

export const ListaTipos = (props) => {
    const { loading: loading_listaTipos, data: data_listaTipos } = useQuery(listaTipos_Query, {
        variables:{
            where:{
                "tipomedio":{
                    "nombre":"OOH"
                }
            }
        }
    });
    const [tipo,setTipo] = useState(props.tipo);
    const onSelectTipos= (selected)=>{
        if (props.onSelectTipos){
            console.log(selected.value)
            props.onSelectTipos(selected.value)
        }
        setTipo(selected.value);
    }
    if (loading_listaTipos) return <IonSpinner name="dots" />;
    if (data_listaTipos) {
        //tipos label es una copia de listaTipos.tipos pero se le agrega el campo label para concatenar el tipomedio y el nombre sólo para el OptionLabel. No afecta captura de sitios.
        var tiposLabel=[]
        data_listaTipos.tipos.forEach(element=>{
            var x={
                id:element.id,
                nombre:element.nombre,
                tipomedio:element.tipomedio.nombre,
                label: element.nombre+' - '+element.tipomedio.nombre
            }
            tiposLabel.push(x)
        })
        return (
            <Dropdown
                tabIndex={13}
                showClear={true}
                style={{ minWidth: '200px', borderRadius: '25px' }}
                value={tipo}
                options={tiposLabel}
                //options={data_listaTipos.tipos}
                onChange={onSelectTipos}
                dataKey='id'
                optionLabel='label'
                //optionLabel={'nombre'}
                filter
                placeholder="Selecciona el tipo"
                required={true} />
        )
    }
}

export const ProveedoresLkUp = (props) => {
    const { loading: loading_listaProveedores, data: data_listaProveedores } = useQuery(listaProveedores_Query);
    const [proveedor,setProveedor] = useState(props.proveedor);

    const onChangeProveedor = (data) =>{
        setProveedor(data.value);
        if (props.onSelectProveedor){
            props.onSelectProveedor(data.value);
        }
    }

    const [dataToShow, setDataToShow] = useState([]);

    useEffect(() => {
        const orderByName = (a, b) => {
            const bandA = a.nombre.toUpperCase();
            const bandB = b.nombre.toUpperCase();

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        }

        if (dataToShow.length === 0 && data_listaProveedores) {
            const tempArray = [];
            data_listaProveedores.proveedors.forEach(d => {
                const temp = {
                    id: d.id,
                    nombre: d.nombre_comercial !== null ? d.nombre_comercial.substring(0,30) : d.nombre.substring(0,30)
                }
                tempArray.push(temp);
            });
            setDataToShow(tempArray.sort(orderByName));
        }
    });

    if (loading_listaProveedores) return <IonSpinner name="dots" />;
    if (dataToShow) {
        return (
            <Dropdown
                tabIndex={15}
                showClear={true}
                style={{ minWidth: '200px', borderRadius: '25px'  }}
                value={proveedor}
                options={dataToShow}
                onChange={onChangeProveedor}
                dataKey='id'
                optionLabel='nombre'
                filter
                filterBy='nombre'
                placeholder="Selecciona un proveedor"
                required={true} />
        )
    }
}

export const Nichos = (props) => {
    let listNichos = [ 
        {nombre:'A,B,C+', nicho:'A,B,C+'}
       ,{nombre:'B,C+,C', nicho:'B,C+,C'}
       ,{nombre:'C+,C,D', nicho:'C+,C,D'}
    ];

    const buscaNicho = (nicho)=>{
        let nichoTemp = listNichos.find(o => o.nombre === nicho);
        return nichoTemp;
    }
    const [nicho,setNicho] = useState(buscaNicho(props.nicho));

    const onChangeNicho = (data) =>{
        setNicho(data.value);
        if (props.onSelectNicho){
            props.onSelectNicho(data.value);
        }
    }
    
    return (
        <Dropdown
            showClear={true}
            style={{ minWidth: '200px', borderRadius: '25px'  }}
            value={nicho}
            options={listNichos}
            onChange={onChangeNicho}
            dataKey='nicho'
            name={'nicho'}
            optionLabel='nombre'
            placeholder="Selecciona un nicho"
            required={true} />
    )
}
