import React, {useEffect, useState} from "react";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
	IonContent,
	IonGrid, IonHeader, IonRow
} from "@ionic/react";
import {Toolbar, MToolBar, DTableV2} from "../../components";
import useAuthentication from "../../hooks/useAuthentication";
import {useJSReportPDF} from '../../hooks';
import useNotification from "../../hooks/useNotification";
import { Button } from "primereact/button";
import { formatMoney } from "../../utils/Utils";


const TablaDetalles=(props)=>{
    const {
        notification,
        showSuccess,
        showInfo,
        showError
    } = useNotification();
    
    const vistaTestigos=(row)=>{
        //Aquí se abre un form que muestra los Testigos asociados al detalle
        console.log(row)
    }

    const headerSitio = ["Sitio", "Inicio", "Fin", "Dirección", "Precio", "Descuento", "Tipo", "Ubicación", "Medidas"];
    const headerRuta=["Nombre", "Clave", "Inicio", "Fin", "Cantidad", "Exhibición", "Precio", "Descuento"]
    
    const sitioTemp=[];
    const rutaTemp=[];
    props.data.forEach(element => {
        if(element.ruta!==null && element.medio===null)
        {
            var x={
                id:element.producto,
                Nombre: element.ruta.nombre,
                Clave:element.ruta.clave,
                Inicio: element.fecha_inicio,
                Fin: element.fecha_fin,
                Cantidad:element.cantidad,
                Exhibición:element.ruta.ubicacion.tipoexhib,
                Precio:'$'+formatMoney(element.ruta.ubicacion.preciourbano),
                Descuento: element.descuento+'%',
                //Descripción: element.ruta.descripcion
            }
            rutaTemp.push(x)
        }
        if(element.medio!==null){
            var y={
                id: element.producto,
                Sitio: element.medio.clave,
                Inicio: element.fecha_inicio,
                Fin: element.fecha_fin,
                Dirección:element.medio.direccion,
                Precio:'$'+formatMoney(element.medio.precioventa),
                Descuento: element.descuento+'%',
                Tipo:element.medio.tipos.nombre,
                Ubicación: element.medio.ubicacion.nombre,
                Medidas: element.medio.medida
            }
            sitioTemp.push(y)
        }
    });
    
    if(rutaTemp.length>0)
    {
        return (
            <IonGrid>
                {notification}
                <IonCardHeader>
                    <IonRow>
                        {headerRuta.map(headerRuta => (
                            <IonCol style={{textAlign:"center", width:"100%"}}><h4>{headerRuta}</h4></IonCol>
                            
                        ))}
                        <IonCol style={{textAlign:"center", width:"100%"}}><h4>Testigos</h4></IonCol>
                        <IonCol style={{textAlign:"center", width:"100%"}}><h4>Ficha</h4></IonCol>
                    </IonRow>
                </IonCardHeader>  
                {rutaTemp.map(row => (
                    <IonRow>
                        {headerRuta.map(element => (
                            <IonCol style={{textAlign:"center", width:"100%"}}>{row[element]}</IonCol>
                        ))}
                        <IonCol style={{textAlign:"center", width:"100%"}}>
                        <Button
                        type="button"
                        icon="pi pi-search"
                        className="p-button-success p-button-raised p-button-rounded"
                        style={{ marginRight: ".5em" }}
                        onClick={() => { console.log(row) }}></Button>
                        </IonCol>
                    </IonRow>
                ))}
            </IonGrid>
        )
    }
    if(sitioTemp.length>0)
    {
        return (
            <IonGrid>
                {notification}
                <IonCardHeader>
                    <IonRow>
                        {headerSitio.map(headerSitio => (
                            <IonCol style={{textAlign:"center", width:"100%"}}><h4>{headerSitio}</h4></IonCol>
                            
                        ))}
                        <IonCol style={{textAlign:"center", width:"100%"}}><h4>Testigos</h4></IonCol>
                        <IonCol style={{textAlign:"center", width:"100%"}}><h4>Ficha</h4></IonCol>
                    </IonRow>
                    </IonCardHeader>
                    <IonCardContent style={{textAlign:"center", width:"100%"}}>
                        {sitioTemp.map(row => (
                            <IonRow>
                                {headerSitio.map(element => (
                                    <IonCol style={{textAlign:"center", width:"100%"}}>{row[element]}</IonCol>
                                ))}
                                <IonCol style={{textAlign:"center", width:"100%"}}>
                                <Button
                                    type="button"
                                    icon="pi pi-search"
                                    className="p-button-success p-button-raised p-button-rounded"
                                    style={{ marginRight: ".5em" }}
                                    onClick={() => { vistaTestigos(row) }}> {/*Esto abre un form donde salen todas las fotos*/}
                                </Button>
                                </IonCol>
                            </IonRow>
                        ))}
                    </IonCardContent>        
            </IonGrid>
        )
    }

    
}
export default TablaDetalles;
