import {gql} from '@apollo/client';

export const  MEDIOS_QUERY = gql`
  query listaMediosProv($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            claveproveedor
            vista{
                nombre
            }
            direccion
            medida
            iluminacion
            disponible
            activo
            bloqueado
            tipos{
                id
                nombre
            }
            ubicacion{
                nombre
            }
            proveedor{
                id
                nombre
            }
            created_at
            updated_at
            especiales
        }
        mediosConnection(where: $where){
            aggregate{
                count
            }
        }
    } 
`;

export const CREATE_MEDIO = gql`
    mutation CrearMedio($input:createMedioInput){
        createMedio(input:$input){
            medio{
                id
                clave
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
               
            }
        }
    }
`;

export const UPDATE_MEDIO = gql`
    mutation UpdateMedio($input:updateMedioInput){
        updateMedio(input:$input){
            medio{
                id
                claveproveedor
                direccion
                medida
                disponible
                activo
                bloqueado
                Vista_larga
                tipos{
                    id
                    nombre
                }
                ubicacion{
                    nombre
                }
                proveedor{
                    id
                    nombre
                }
                especiales
            }
        }
    }
`;

export const UPDATE_ESTADO = gql`
    mutation Cambiar_estaus($input:updateMedioInput){
        updateMedio(input:$input){
            medio{
                id
                activo
                bloqueado
                disponible
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                
            }
        }
    }
`;

export const DELETE_MEDIO = gql`
    mutation DeleteMedio($input: deleteMedioInput){
        deleteMedio(input:$input){
            medio{
                id
                clave
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                
            }
        }
    }
`;


export const listaUbicaciones_Query = gql`
    query listaUbicaciones{
        ubicacions{
            id
            nombre
        }
    }
`;


export const listaVistas_Query = gql`
    query listaVistas{
        vistas{
            id
            nombre
        }
    }
`;

export const listaTipos_Query = gql`
    query listaTipos{
        tipos{
            id
            nombre
        }
    }
`;

export const listaProveedores_Query = gql`
    query listaProveedor{
        proveedors{
            id
            nombre
        }
    }
`;

export const BUSCAR_UBICACION_BY_ID_QUERY = gql`
    query BuscarUbicacionPorId($id:ID!){
        ubicacion(id:$id){
                nombre    
        }
    }
`;

export const BUSCAR_TIPO_BY_ID_QUERY = gql`
    query BuscarTipoPorId($id:ID!){
        tipo(id:$id){
            nombre
        }
    }
`;

export const BUSCAR_VISTA_BY_ID_QUERY = gql`
    query buscarVistaPorId($id:ID!){
        vista(id:$id){
            nombre
        }
    }
`;

export const BUSCAR_POR_ID_MEDIO = gql`
    query BuscarMedioPorId ($id:ID!){
        medio(id:$id){
            Vista_corta
            Vista_media
            Vista_larga
            imagenmapaubicacion
        }
    }
`;

export const  MEDIOS_QUERY_UPDATE = gql`
  query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            direccion
            medida
            disponible
            activo
            vialidad
            colonia
            niveldeimpacto
            iluminacion
            nicho
            referencias
            comentarios
            precioventa
            material
            tipoacabado
            latitud
            longitud
            Vista_larga
            Vista_corta
            Vista_media
            imagenmapaubicacion
            especiales
            ubicacion{
              	id
                nombre
            }
            vista{
                id
                nombre
            }
            tipos{
                id
                nombre
            }
            Createdby{
                id
                
              
            }
            Modifiedby{
                id
            

            }
           
        }
    } 
`;