import { gql, useQuery } from '@apollo/client';
import { IonContent } from '@ionic/react';
import React from 'react';
import NumberFormat from 'react-number-format';
import ChartGrupoEdad from '../../components/infinia/ChartGrupoEdad';
import ChartNSE from '../../components/infinia/ChartNSE';
import {
    CuadrosFicha,
    CuadroSinBorde,
    DatosInfinia,
    Footer,
    ImagenSitio,
    Logotipo,
    TituloSitio,
    ImagenMapa,
} from './components/';
import styles from  './ficha.module.css';

export const  MEDIOS_QUERY = gql `
query listaMedio($id:ID!){
    medio(id:$id){
        id
        clave
        direccion
        vialidad
        colonia
        medida
        claveproveedor
        disponible
        activo
        comentarios
        referencias
        medida
        imagenmapaubicacion
        material
        tipoacabado
        vista{
            nombre
        }
        nicho
        Vista_media
        latitud
        longitud
        female_users
        male_users
        a13_17_users
        a18_25_users
        a26_40_users
        a41_55_users
        a55_users
        income_a_users
        income_b_users
        income_c_users
        income_d_users
        income_e_users
        total_hits
        total_users
        reach
        frequency
        tipos{
            id
            nombre
        }
        proveedor{
            id
            nombre
        }	
        ubicacion{
            nombre
            estado
        }
       
    }
} 
`;
const FichaSitiosPdf = (props)=>{
    const cuadroFichaWeigth = '160px';
    const leftPos = '130px';
    const leftPosLogo = '80px';
    const specialFontSize = '9.5px';
    const grapsTop = '3.6in';
    const dataBelowGraps = '5.9in';
    const topImageMap = '660px';
    const leftPosMap = '832px';
    const divInfoWidth = 550;
    let urlMapa = '';

    const {data, loading} = useQuery(MEDIOS_QUERY,{
        variables:{
            id: props.match.params.id
        }
    });
    if (!loading && data && data.medio){
        urlMapa = "http://google.com/maps/place/"+data.medio.latitud+","+data.medio.longitud;
    }
     
    return (
        <IonContent slot='fixed'>
            {data && data.medio && 
                <div className={styles.principal}>
                    <Logotipo top='0px' left={leftPosLogo} width='355.6px' height='200px' />
                            <TituloSitio height='100px' width='270px' top='200px' left={leftPos} sitio={data.medio.clave} tipo={data.medio.tipos.nombre}/>

                            <ImagenMapa width='200px' height='100px' left={leftPosMap} top={topImageMap} imagen={data.medio.imagenmapaubicacion}/>

                            <CuadrosFicha title="Ubicación" top='600px' left='13px' width='260px' height={cuadroFichaWeigth}>
                                <b>Dirección:</b> {data.medio.direccion}<br></br>
                                Colonia: {data.medio.colonia}<br></br>
                                Ciudad y Estado: {data.medio.ubicacion && data.medio.ubicacion.nombre},{data.medio.ubicacion && data.medio.ubicacion.estado}<br></br>
                                Vialidad: {data.medio.vialidad}<br></br>
                                <br></br>
                            </CuadrosFicha>

                            <CuadrosFicha title="Datos técnicos" top='600px' left='286px' width='260px' height={cuadroFichaWeigth}>
                                Medidas: {data.medio.medida}<br></br>
                                Material: {data.medio.material}<br></br>
                                Acabado: {data.medio.tipoacabado}<br></br>
                                Iluminación: {data.medio.iluminacion?'Si':'No'}<br></br>
                                Tipo de vista: {data.medio.vista && data.medio.vista.nombre}<br></br>
                                Nicho: {data.medio.nicho}<br></br>
                                <br></br>
                            </CuadrosFicha>
                            
                            <CuadrosFicha title="Referencias" top='600px' left='559px' width='260px' height={cuadroFichaWeigth}>
                                Referencia Comercial: {data.medio.referencias}<br></br>
                            </CuadrosFicha>

                            <CuadrosFicha color='#000' title="Coordenadas" top='600px' left='832px' width='200px' height={cuadroFichaWeigth}>
                                <a href={urlMapa + data.medio.latitud.toString() +"," + data.medio.longitud.toString()}>Lat: {data.medio.latitud} <br></br>Long:{data.medio.longitud}</a>
                            </CuadrosFicha>
        
                            <CuadroSinBorde title="Datos de mercado" top='350px' right='25px' width='200px' height='250px'>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 5, marginBottom:10}}>
                                    Impactos: <b><NumberFormat value={data.medio.total_hits} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b><br/><br/>
                                </div>
                                <img src='/assets/infinia/man.png' alt='' height={90} /><b><NumberFormat value={data.medio.male_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b>
                                <img src='/assets/infinia/woman.png' alt='' height={90} /><b><NumberFormat value={data.medio.female_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b><br/><br/>
                                <div style={{marginBottom: 5}}>Alcance: <b><NumberFormat value={data.medio.reach*100} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>
                                <div>Frecuencia: <b> <NumberFormat value={data.medio.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
                                <br></br>
                                <div className='.fontSizeCuadroSinBorde' style={{fontSize: '9.5px', marginTop: 3}}>*Datos actualiados mensualmente</div>
                            </CuadroSinBorde>

                            <DatosInfinia title="Datos Geográficos" top={grapsTop} left='-0.8cm' width='11cm' height='9.5cm'>
                                <ChartGrupoEdad data={data.medio} fontSize={10}/>
                            </DatosInfinia>
                            <DatosInfinia title="Datos Geográficos" top={grapsTop} left='9.5cm' width='11cm' height='6.5cm'>
                                <ChartNSE data={data.medio} fontSize={10}/>
                            </DatosInfinia>

                            <CuadroSinBorde title="Datos de mercado footer" top={dataBelowGraps} left='10px' width='750px' height='100px'>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}> 
                                    <div style={{width: divInfoWidth}}>
                                        <div style={{fontSize: specialFontSize}}>Alcance: <b>Porcentaje de personas alcanzadas dentro del universo poblacional total de la plaza</b></div>
                                    </div>
                                    
                                    <div style={{width: divInfoWidth}}>
                                        <div style={{fontSize: specialFontSize}}>Frecuencia: <b>Número promedio de veces que estuvo un grupo de personas en un mismo periodo de tiempo y ubicados dentro del radio del espacio urbano</b></div>                                
                                    </div>
                                </div>
                                
                            </CuadroSinBorde>


                            <Footer className={styles.footer}/> 
                            {/* Si quito el parametro width, se muestra el margen de la imagen
                            si forzo el tamaño de la imagen se evitan dichos problemas aunque puede que se estire la imagen
                            Actualmente se calcularon las medidas para tener un Ratio de 16:9 en proporcion a las medidas proporcionadas */}
                            <ImagenSitio 
                                width='561.78px' 
                                height='316px'
                                right='30px'
                                top='24px' imagen={data.medio.Vista_media}/>
                </div>
            }
            
        </IonContent>
    )
}

export default FichaSitiosPdf;