import React, { Fragment } from 'react';
import InfoWindow from '../mapa/InfoWindow';
import { IMJMarkerIcon,  } from '../../utils/IMJMarker';
import {
  PointIcon,
  PetIconComponent,
  AutomovilIconComponent,
  EnteritanmentIconComponent, 
  FinancialIconComponent,
  FoodIconComponent,
  GovernmentIconComponent,
  MedicalIconComponent,
  NatureIconComponent,
  ReligiousIconComponent,
  SchoolIconComponent,
  SecurityIconComponent,
  ServicesIconComponent,
  StoreIconComponent,
  TourismIconComponent,
  TransportIconComponent,
} from '../../utils/SVGS';
import {
  CATEGORIE_TRANSPORT,
  CATEGORIE_FOOD,
  CATEGORIE_FINANCIAL,
  CATEGORIE_STORE,
  CATEGORIE_ENTERITANMENT,
  CATEGORIE_NATURE,
  CATEGORIE_SECURITY,
  CATEGORIE_PET,
  CATEGORIE_SERVICES,
  CATEGORIE_SCHOOL,
  CATEGORIE_MEDICAL,
  CATEGORIE_AUTOMOVIL,
  CATEGORIE_GOVERMENT,
  CATEGORIE_TOURISM,
  CATEGORIE_RELIGIOUS,
} from '../../utils/Constanst';
export type MediosMarker = {
    key: number,
    nombre: string,
    tipo: string,
    lat: number,
    lng: number,
    medio: any,
    show: boolean
}

const MarkerFourSq = (props) => {
    let COMPONENT;
    const category = props.category;
    const color = props.color;
    switch (category) {
      case CATEGORIE_TRANSPORT :
        COMPONENT = <TransportIconComponent fill={color} />;
        break;
      case CATEGORIE_FOOD :
        COMPONENT = <FoodIconComponent fill={color} />;
        break;
      case CATEGORIE_FINANCIAL :
        COMPONENT = <FinancialIconComponent fill={color} />;
        break;
      case CATEGORIE_STORE :
        COMPONENT = <StoreIconComponent fill={color} />;
        break;
      case CATEGORIE_ENTERITANMENT :
        COMPONENT = <EnteritanmentIconComponent fill={color} />;
        break;
      case CATEGORIE_NATURE :
        COMPONENT = <NatureIconComponent fill={color} />;
        break;
      case CATEGORIE_SECURITY :
        COMPONENT = <SecurityIconComponent fill={color} />;
        break;
      case CATEGORIE_PET :
        COMPONENT =  <PetIconComponent fill={color} />;
        break;
      case CATEGORIE_SERVICES :
        COMPONENT = <ServicesIconComponent fill={color} />;
        break;
      case CATEGORIE_SCHOOL :
        COMPONENT = <SchoolIconComponent fill={color} />;
        break;
      case CATEGORIE_MEDICAL :
        COMPONENT = <MedicalIconComponent fill={color} />;
        break;
      case CATEGORIE_AUTOMOVIL :
        COMPONENT = <AutomovilIconComponent fill={color} />;
        break;
      case CATEGORIE_GOVERMENT :
        COMPONENT = <GovernmentIconComponent fill={color} />;
        break;
      case CATEGORIE_TOURISM :
        COMPONENT = <TourismIconComponent fill={color} />;
        break;
      case CATEGORIE_RELIGIOUS :
        COMPONENT = <ReligiousIconComponent fill={color} />;
        break;
      default:
        COMPONENT = <PointIcon fill={color}/>;
        break;
    }
    return (
      <Fragment>
        {/* <div style={{ width: 25, height: 25, backgroundColor: '#FFFFFF' }}> */}
        <div style={{ width: 30, height: 30}}>
          {COMPONENT}
        </div>
      </Fragment>
    )
}

export const MarkerIMJ = (props: MediosMarker) => {
    return (
      <Fragment>
        <div style={{ width: 40, height: 40, position:'absolute',transform: 'translate(-50%, 0)' }}>
          { IMJMarkerIcon(props.tipo, props.medio.disponible) }
        </div>
        {props.show && <InfoWindow place={props.medio} />}
      </Fragment>
    )
  }
export default MarkerFourSq