import React from 'react';
import { MapaABCSitios } from '../../../../components';

/**
 * 
 * @param {Object} props
 * @param {string} props.operacion
 * @param {React.Dispatch<any>} props.setMapReference
 * @param {(imagen: any) => void} props.asignaImagenMapa
 * @param {{id: number, name: string; lat: number, lng: number}} props.location
 * @param {number} props.latitud
 * @param {number} props.longitud
 * @param {React.Dispatch<number>} props.setLatitud
 * @param {React.Dispatch<number>} props.setLongitud
 * @param {any} props.setValue
 * @param {any} props.imagenmapaubicacion
 * @returns 
 */
const MostrarOcultarMapa = ({
    operacion,
    setMapReference,
    asignaImagenMapa,
    location,
    latitud,
    longitud,
    setLatitud,
    setLongitud,
    setValue,
    imagenmapaubicacion
}) => {

    if (operacion === 'crear' || operacion === 'editar') {

        return (
            <div>
                <MapaABCSitios
                    setMapReference={setMapReference}
                    onLoadImage={asignaImagenMapa}
                    mapCenter={location}
                    latitud={latitud || 0}
                    longitud={longitud || 0}
                    changeLatLng={(latitud, longitud) => {
                        setLatitud(latitud);
                        setLongitud(longitud);
                        setValue("latitud", latitud);
                        setValue("longitud", longitud);
                    }} />
            </div>
        )
    } else {
        if (imagenmapaubicacion !== undefined && imagenmapaubicacion !== null && imagenmapaubicacion !== "") {
            return (
                <img alt='' src={imagenmapaubicacion} style={{
                    display: "block",
                    margin: "auto"
                }} ></img>
            )
        } else {
            return null;
        }
    }
}

export default MostrarOcultarMapa;