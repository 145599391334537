/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useLazyQuery } from '@apollo/client';
import { IonContent, IonFab, IonFabButton, IonFabList, IonHeader, IonIcon, IonPage, IonPopover, IonAlert } from '@ionic/react';
import { TiposMedio, ToolbarMapa } from '../../components/mapa/Controls';
import { Toolbar, RutasSelect, Spinner, InfoWindowSitios, FormDialogPauta } from '../../components';
import useNotification from '../../hooks/useNotification';
import { MediosSitios } from '../../components/mapa/mediosQuery';
import { TabPanel, TabView } from 'primereact/tabview';
import { informationCircle, newspaper, settings } from 'ionicons/icons';
import { ArrayCirculos, CirculoInfluencia, CrearCirculoConlatLng } from './components/CiculoIncluencia/CirculoInfluencia';
import { clearLocalStorage, formatMoney, getLocalStorage, randomInteger } from '../../utils/Utils';
import MarkerFourSq, { MarkerIMJ } from '../../components/markers/Markers'
import Slider from 'react-slide-out';
import {
    PopUpCirculos,
    ButtonTraffic,
    PopupInputsCirculo,
    InfoWindowGooglePlace,
} from './components';
import { MEDIOS_QUERY } from './querys'
import useAuthentication from '../../hooks/useAuthentication';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import moment from 'moment';
import './sitios.css';
import { IMJ_API_KEY } from '../../utils/Constanst';

import { Splitter, SplitterPanel } from 'primereact/splitter';

var { jStat } = require('jstat');

export const Sitios = (props) => {
    //Estado para la analítica de costos
    const [analiticaCostos, setAnaliticaCostos] = useState({
        //tag del circulo
        circuloActivo: "",
        //////////////////////////////
        costoPromedio: 0,
        mediana: 0,
        max: 0,
        min: 0,
        costoxm: 0,
        mediaprecio: 0,
        medianaprecio: 0,
        maxprecio: 0,
        minprecio: 0,
        m2precio: 0
    });

    //Estado para mostrar el spinner
    const [loading, setLoading] = useState(false);
    const [map, setMap] = useState<any>();
    const [medioBuscado, setMedioBuscado] = useState<any>([]); //Medio Buscado
    const { notification, showSuccess, showInfo, showError } = useNotification(); //ventanas de notificaciones
    const [centro, setCentro] = useState<any>({ centro: { lat: 19.43569693370444, lng: -99.13175137016965 } }); //Centro del mapa
    const [ciudadActual, setCiudadActual] = useState<any>(122); //122 es el id de CDMX
    //Descripción de los sitios seleccionados: Espectaculares, Mupi etc
    const [mediosSeleccionados, setMediosSeleccionados] = useState<any>(); //son los medios que se seleccionaron a través del combo de sitios
    const [distancia, setDistancia] = useState(0);
    // Lista de todos los medios que existen de acuerdo a los seleccionados
    const [medios, setMedios] = useState<any>();
    //Puntos de foursqaure
    //TODO:cambiar por google
    const [fourSquarePoints, setFourSquarePoints] = useState<any>([]);
    //Tab activo de sitios
    const [activeIndex, setActiveIndex] = useState(0);
    //Estado para la capa de tráfico
    const [traffic, setTraffic] = useState(null);
    //Shopping Cart
    const { deleteCircle, addCircle, setCountProducts, PermisoVaciarItems, rutasEdit, sitiosEdit } = useContext(ShoppingCartContext);
    //Arreglo de círculos creados
    const [circulos, setCirculos] = useState<ArrayCirculos>(new ArrayCirculos());
    // para mostrar el menú de círculo anterior
    const [showPopover, setshowPopover] = useState(false);
    // Estados para mostrar el InfoWindow del sitio seleccionado
    const [sitioInfoWindow, setSitioInfoWindow] = useState();
    const [showInfoSitio, setShowInfoSitio] = useState(false);
    // Fin estados para InfoWindow
    // Estado de círculo activo
    const [circuloActivo, setCirculoActivo] = useState<CirculoInfluencia>(null);
    // para mostrar el menú de círculo nuevo
    const [showModal, setShowModal] = useState(false);
    //Estado para el zoom del mapa
    const [zoom, setZoom] = useState(14);
    //Estado que conserva el DrawingManager
    // const [drawingManager, setDrawingManager] = useState<any>();
    //Estado del mapLibrary del componente de google maps
    // const [mapLibrary, setMapLibrary] = useState<any>();
    //Otros marcadores (FourSquare())
    const [otherMarker, setOtherMarker] = useState<any>();
    //Infowindows de otherMarker
    const [showInfoWindowMarkers, setShowInfoWindowMarkers] = useState<any>(false);
    //Context de autenticación
    const { usuarioid, rol } = useAuthentication();
    //Datos para infowindow de pauta
    // const [datos, setDatos] = useState({ "createdby": usuarioid(), "modifiedby": usuarioid() });
    const datos = { "createdby": usuarioid(), "modifiedby": usuarioid() };
    //Estado para conservar las rutas selccionadas
    const [rutasSelected, setRutasSelected] = useState(null);
    //const [flagBorrar, setFlagBorrar] =useState(false)
    //Estado para poder agregar un circulo a Shopping Cart
    const [circuloCambiado, setCirculoCambiado] = useState(null);

    const stateRefCirculoActivo = useRef<CirculoInfluencia>();

    stateRefCirculoActivo.current = circuloActivo;

    //Almacenamiento local y recuperación de pauta
    const localStorageInfo = getLocalStorage("pautaEdit");
    const [localStorageState, setLocalStorageState] = useState({});
    const shope=getLocalStorage("shop")
    const [shopeState, setShopeState]=useState({});
    const [showAlert, setShowAlert] = useState(false);
    
    // Visibilidad del splitter basada en rol
    const [analiticaVisible, setAnaliticaVisible] = useState(rol() === "Administrador" || rol() === "Operaciones Urbanos" || rol() === "Operaciones")
    //Tab de estadísitcos
    const [activeStat,setActiveStat] = useState(0);

    /*Listener de círculos a editar*/
    const listenersCirc = (cpauta, tag, fechaI, fechaF) => {
        let contextMenu = document.getElementById('contextMenu');
        cpauta.circulo.map.controls[google.maps.ControlPosition.TOP_CENTER].push(contextMenu);
        cpauta.circulo.tag = randomInteger(10000);
        cpauta.callbackInfoWindow = handleClickInfoWindow;
        cpauta.callbackDelete = handleCircleDelete;
        cpauta.callback = setLoading;
        circulos.circulos.push(cpauta);
        //Cambiar creaCirculoShopping por una función que agregue lo que ya existe al shopping cart
        cpauta.buscarRutasYMedios().then(() => {
            let ct = {
                identificador: cpauta.identificador,
                etiqueta: tag,
                latitud: cpauta.circulo.center.lat(),
                longitud: cpauta.circulo.center.lng(),
                radio: cpauta.circulo.radius,
                sitios: [...cpauta.sitios],
                urbanos: [...cpauta.rutas]
            }
            //Agrega los sitios al shopping cart
            let arraySitios = [];
            cpauta.sitios.forEach(sitio => {
                let objectSitio = {
                    id: sitio.id,
                    clave: sitio.clave,
                    fecha_inicio: fechaI,
                    fecha_fin: fechaF,
                    cantidad: 1,
                    descuento: 0,
                    precioventa: sitio.precioventa,
                    tipos: sitio.tipos.nombre,
                    iluminacion: sitio.iluminacion,
                    niveldeimpacto: sitio.niveldeimpacto,
                    nicho: sitio.nicho,
                    direccion: sitio.direccion,
                    ubicacion: sitio.ubicacion.nombre,
                    referencias: sitio.referencias,
                    Vista_corta: sitio.Vista_corta,
                    vista: sitio.vista.nombre,
                    medida: sitio.medida,
                    lat: sitio.latitud,
                    lng: sitio.longitud,
                    impactos: sitio.total_hits,
                    alcance: sitio.reach,
                    maleU: sitio.male_users,
                    femaleU: sitio.female_users,
                    income_a_users: sitio.income_a_users,
                    income_b_users: sitio.income_b_users,
                    income_c_users: sitio.income_c_users,
                    income_d_users: sitio.income_d_users,
                    income_e_users: sitio.income_e_users,
                    a13_17_users: sitio.a13_17_users,
                    a18_25_users: sitio.a18_25_users,
                    a26_40_users: sitio.a26_40_users,
                    a41_55_users: sitio.a41_55_users,
                    a55_users: sitio.a55_users,
                    universo:sitio.universo
                };
                arraySitios.push(objectSitio);
            });
            ct.sitios = [...arraySitios];
            //Agrega las rutas al Shopping cart
            let arrayRutas = [];
            cpauta.rutas.forEach(ruta => {
                let x = {
                    id: ruta.id,
                    nombre: ruta.nombre,
                    tipoexhib: ruta.ubicacion.tipoexhib,
                    descripcion: ruta.descripcion,
                    cantidad: 1,
                    preciourbano: ruta.ubicacion.preciourbano,
                    ubicacion: ruta.ubicacion.nombre,
                    descuento: 0,
                    inicio: ruta.inicio,
                    fin: ruta.fin,
                    fecha_inicio: fechaI,
                    fecha_fin: fechaF,
                    ruta: ruta.puntos,
                    impactos: ruta.impactos_ruta,
                    alcance: ruta.reach,
                    impactosT: ruta.total_hits,
                    frecuencia: ruta.frequency,
                    kilometraje: ruta.kilometraje,
                    maleU: ruta.male_users,
                    femaleU: ruta.female_users
                }
                arrayRutas.push(x);
            })
            ct.urbanos = [...arrayRutas]
            setCirculoCambiado(ct);
            setCircFlag(true)
        });
        //Configurar el callback para mostrar el spinner
        cpauta.circulo.addListener('leftclick', function (event) {
            stateRefCirculoActivo.current = circulos.findCircle(this);
            setCirculoActivo(circulos.findCircle(this));
        })
        cpauta.circulo.addListener('rightclick', function (event) {
            event.stop();
            stateRefCirculoActivo.current = circulos.findCircle(this);
            setCirculoActivo(circulos.findCircle(this));
            setShowModal(true);
        })
        cpauta.circulo.addListener('radius_changed', async function () {
            stateRefCirculoActivo.current = circulos.findCircle(this);
            setCirculoActivo(circulos.findCircle(this));
            await stateRefCirculoActivo.current.buscarRutasYMedios();
            let circulo = circulos.findCircle(this);
            creaCirculoShopping(circulo);
        });
        cpauta.circulo.addListener('dragend', async function () {
            let c = this;
            stateRefCirculoActivo.current = circulos.findCircle(c);
            setCirculoActivo(stateRefCirculoActivo.current);
            await stateRefCirculoActivo.current.buscarRutasYMedios();
            let circulo = circulos.findCircle(this);
            creaCirculoShopping(circulo);
        });
        cpauta.circulo.addListener('mouseover', async function (event) {
            event.stop();
            this.map.getDiv().setAttribute('title', "Botón derecho para opciones");
        });
        cpauta.circulo.addListener('mouseout', function () {
            this.map.getDiv().removeAttribute('title');
        });
    }
    /*Fin de Listener de círculos a editar*/

    const calculaEstadisticos = (circulo) => {
        let temp = {...analiticaCostos}
        temp.costoPromedio = jStat.mean(circulo.sitios.map((sitio) => sitio.costo));
        temp.mediana = jStat.median(circulo.sitios.map((sitio) => sitio.costo));
        temp.max = jStat.max(circulo.sitios.map((sitio) => sitio.costo));
        temp.min = jStat.min(circulo.sitios.map((sitio) => sitio.costo));
        temp.costoxm = jStat.min(circulo.sitios.map((sitio) => sitio.costo/(sitio.largo*sitio.ancho)));
        temp.costoxm = (isNaN(temp.costoxm) || !Number.isFinite(temp.costoxm))?0:temp.costoxm;
        //Estadísticas de precio
        temp.mediaprecio = jStat.mean(circulo.sitios.map((sitio) => sitio.precioventa));
        temp.medianaprecio = jStat.median(circulo.sitios.map((sitio) => sitio.precioventa));
        temp.maxprecio = jStat.max(circulo.sitios.map((sitio) => sitio.precioventa));
        temp.minprecio = jStat.min(circulo.sitios.map((sitio) => sitio.precioventa));
        temp.m2precio = jStat.min(circulo.sitios.map((sitio) => sitio.precioventa/(sitio.largo*sitio.ancho)));
        temp.m2precio = (isNaN(temp.m2precio) || !Number.isFinite(temp.m2precio))?0:temp.m2precio;

        //Se agrega el identificador para saber qué círculo es o en su caso, la etiqueta
        temp.circuloActivo=(circulo.etiqueta!==undefined ? circulo.etiqueta : circulo.identificador)

        setAnaliticaCostos(temp);
    }

    //Evalúa si existe un localStorage pero no si éste cambió. Por lo tanto, no ejecuta las funciones de shopping cart.
    //Considerar reestructurar el useEffect con la pauta o forzar a re renderizar
    useEffect(() => {
        if (map) {
            const InfoDetailPauta = (pr) => {
                for (let index = 0; index < circulos.circulos.length; index++) {
                    const element = circulos.circulos[index];
                    element.removeSitios();
                    element.removeRutas();
                    element.circulo.setMap(null);
                }
                setCirculos(new ArrayCirculos());
                PermisoVaciarItems();
                const detail = pr.datos.detailpautas;
                let circEdit = [];
                let sitioEdit = [];
                let rutaEdit = [];
                const fechaI = pr.datos.fecha_inicio;
                const fechaF = pr.datos.fecha_fin;
                //Se recorren los detalles de la Pauta
                detail.forEach(detalle => {
                    if (detalle.circulospauta !== null) {
                        const unidad = detalle.circulospauta;
                        circEdit.push(unidad);
                    }
                    else {
                        if (detalle.circulospauta === null && detalle.ruta === null) {
                            sitioEdit.push(detalle.medio);
                            sitiosEdit(detalle);
                        }
                        if (detalle.circulospauta === null && detalle.medio === null) {
                            rutaEdit.push(detalle.ruta)
                            //rutasEdit(detalle);
                            setRutasSelected(rutaEdit)
                        }
                    }
                });
                setRutasSelected(rutaEdit)
                setMedioBuscado(sitioEdit)
                //Se filtran los círculos circEdit  
                var hash = {};
                circEdit = circEdit.filter(function (current) {
                    var exists = !hash[current.id];
                    hash[current.id] = true;
                    return exists;
                });
                //CircEdit ya tiene información de círculos en un arreglo de objetos
                circEdit.forEach(element => {
                    const tag = element.etiqueta
                    const lat = element.lat
                    const lng = element.lng
                    const radio = element.radio

                    let cpauta = CrearCirculoConlatLng(lat, lng, radio, map);

                    listenersCirc(cpauta, tag, fechaI, fechaF)
                    circulos.agregarCirculo(cpauta);
                });
            }
            if(shope)
            {
                if(Object.keys(shopeState).length===0)
                {
                    setShopeState(shope);
                    console.log(shopeState)
                }
                else{
                    console.log(shopeState)
                    // if(shope.localeCompare(shopeState)!==0){
                    //     console.log("cambió")
                    // }
                }
            }
            if (localStorageInfo) {
                if (Object.keys(localStorageState).length === 0) {
                    setLocalStorageState(localStorageInfo);
                    const pr = JSON.parse(localStorageInfo);
                    console.log(pr)
                    if (pr.datos.detailpautas) {
                        InfoDetailPauta(pr);
                    }
                }
                else {
                    //Compara el localstorage existente con el que viene de Pautas
                    if (localStorageInfo.localeCompare(localStorageState) !== 0) {
                        console.log("cambió, entro")
                        setLocalStorageState(localStorageInfo);
                        const pr = JSON.parse(localStorageInfo);
                        console.log(pr)
                        if (pr.datos.detailpautas) {
                            InfoDetailPauta(pr);
                        }

                    }
                }
            }

        }
    }, [
        localStorageInfo,
        localStorageState,
        map,
        rutasEdit,
        sitiosEdit,
        circulos,
        PermisoVaciarItems,
    ]);

    useEffect(()=>{
        /*

        */
        document.addEventListener("contextmenu", function(evt){
            console.log(evt);
            evt.preventDefault();
            evt.stopPropagation();
        });
    });

    //Creación del div para el menú de pintado
    let controlDiv = document.createElement('div');
    controlDiv.setAttribute("id", "controles");

    const [circFlag, setCircFlag] = useState(false);

    useEffect(() => {
        //if (circuloCambiado !== null) {
        if (circFlag) {
            setCircFlag(false)
            addCircle(circuloCambiado)

        }

    }, [circuloCambiado, addCircle, circFlag]);

    const creaCirculoShopping = (circulo) => {
        let ct = {
            identificador: circulo.identificador,
            etiqueta: circulo.etiqueta,
            latitud: circulo.circulo.center.lat(),
            longitud: circulo.circulo.center.lng(),
            radio: circulo.circulo.radius,
            sitios: [...circulo.sitios],
            urbanos: [...circulo.rutas]
        }
        //Crear los sitios como arreglo para shopping cart
        let arraySitios = [];
        circulo.sitios.forEach(sitio => {
            let objectSitio = {
                id: sitio.id,
                clave: sitio.clave,
                fecha_inicio: moment().format('YYYY-MM-DD'),
                fecha_fin: moment().add(1, 'month').format('YYYY-MM-DD'),
                cantidad: 1,
                descuento: 0,
                precioventa: sitio.precioventa,
                tipos: sitio.tipos.nombre,
                iluminacion: sitio.iluminacion,
                niveldeimpacto: sitio.niveldeimpacto,
                nicho: sitio.nicho,
                direccion: sitio.direccion,
                ubicacion: sitio.ubicacion.nombre,
                referencias: sitio.referencias,
                Vista_corta: sitio.Vista_corta,
                vista: sitio.vista.nombre,
                medida: sitio.medida,
                lat: sitio.latitud,
                lng: sitio.longitud,
                impactos: sitio.total_users,
                total_hits: sitio.total_hits,
                frecuencia: sitio.frequency,
                alcance: sitio.reach,
                disponible: sitio.disponible,
                maleU: sitio.male_users,
                femaleU: sitio.female_users,
                income_a_users: sitio.income_a_users,
                income_b_users: sitio.income_b_users,
                income_c_users: sitio.income_c_users,
                income_d_users: sitio.income_d_users,
                income_e_users: sitio.income_e_users,
                a13_17_users: sitio.a13_17_users,
                a18_25_users: sitio.a18_25_users,
                a26_40_users: sitio.a26_40_users,
                a41_55_users: sitio.a41_55_users,
                a55_users: sitio.a55_users,
                universo:sitio.universo
            };
            arraySitios.push(objectSitio);
        });
        ct.sitios = [...arraySitios];
        //Crear las rutas como objeto para shopping cart
        let arrayRutas = [];
        circulo.rutas.forEach(ruta => {
            let x = {
                id: ruta.id,
                nombre: ruta.nombre,
                tipoexhib: ruta.ubicacion.tipoexhib,
                descripcion: ruta.descripcion,
                cantidad: 1,
                preciourbano: ruta.ubicacion.preciourbano,
                ubicacion: ruta.ubicacion.nombre,
                descuento: 0,
                inicio: ruta.inicio,
                fin: ruta.fin,
                fecha_inicio: moment().format('YYYY-MM-DD'),
                fecha_fin: moment().add(1, 'month').format('YYYY-MM-DD'),
                ruta: ruta.puntos,
                impactos: ruta.impactos_ruta,
                alcance: ruta.reach,
                total_hits: ruta.total_hits,
                frecuencia: ruta.frequency,
                kilometraje: ruta.kilometraje,
                maleU: ruta.male_users,
                femaleU: ruta.female_users
            }
            arrayRutas.push(x);
        })
        ct.urbanos = [...arrayRutas]

        setCirculoCambiado(ct);
        setCircFlag(true);
        calculaEstadisticos(circulo);
    }
    const closeInfoWindow = () => {
        setSitioInfoWindow(null);
        setShowInfoSitio(false);
    }

    const onSuccess = () => {
        showSuccess("Operación exitosa", "Se agregaron los productos con exito");
    }

    const onDiscard = () => {
        showInfo('Acción cancelada', 'Se abortó correctamente la operación')
    }

    /**
     * Query para traer un sitio
     */
    const [traeSitio] = useLazyQuery(MEDIOS_QUERY, {
        onCompleted: (data) => {
            setSitioInfoWindow(data.medios[0]);
            setShowInfoSitio(true);
        }
    });


    /**
     * Se agregan los medios que se buscaron por clave
     * @param medios 
     */
    const addSearchMedios = (medios) => {

        //Falta mostrar data loading y cuantos se encontraron
        if (medios && medios.medios) {
            if (medios.medios.length > 1) {
                map.setCenter(new google.maps.LatLng(medios.medios[0].latitud, medios.medios[0].longitud));
                map.setZoom(12);
                setZoom(12);
            }
            setMedioBuscado(medios.medios);
            showSuccess("Operación exitosa", "Se encontraron " + medios.medios.length + " sitios");
        } else {
            setMedioBuscado([]);
        }
    }

    /**
     * Agrega los medios que se seleccionan a través del selector de todos los medios
     * @param lat 
     * @param lng 
     */
    const addMedios = async (lat, lng) => {
        if (mediosSeleccionados) {
            let arregloMedios = mediosSeleccionados.map((medio) => medio.nombre);
            setLoading(true);
            let medios = await MediosSitios({
                latitud: lat,
                longitud: lng,
                distancia: distancia / 2,
                nombres: arregloMedios.toString()
            });
            setMedios(medios);
            setLoading(false);
        }
    }

    /**
     * posiciona el mapa en la ciudad seleccionada en el toolbar
     * @param ciudad
     * @returns
     */
    const cambiaCiudad = (ciudad) => {
        if (ciudad.length === 0) {
            return;
        }
        ciudad = ciudad[0];
        map.setCenter(new google.maps.LatLng(Number(ciudad.lat), Number(ciudad.lng)));
        map.setZoom(14);
        setZoom(14);
        setCentro({
            centro: {
                lat: Number(ciudad.lat),
                lng: Number(ciudad.lng)
            }
        });
        setCiudadActual(ciudad.id);
        addMedios(ciudad.lat, ciudad.lng);
    }

    /**
     * Funcioj que ordena la lista de puntos de interes por 
     * su nombre
     * @param {Object} a 
     * @param {Object} b 
     * @returns {Object[]}
     */
    const compare = (a, b) => {
        const bandA = a.text.toUpperCase();
        const bandB = b.text.toUpperCase();

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    };

    const POIPromise = async (data) => {
        return new Promise((resolve, reject) => {
            try {
                if (data !== null) {
                    const POIArrayTemp = [];
                    data.forEach(poi => {
                        const objectTemp = {
                            key: poi.place_id,
                            text: poi.name,
                            lat: poi.geometry.location.lat(),
                            lng: poi.geometry.location.lng(),
                            icono: poi.icon,
                            color: poi.color,
                            category: poi.category,
                            categoryTranslate: poi.categoryTranslate,
                            data: poi,
                        };
                        POIArrayTemp.push(objectTemp);
                    });
                    const POIArray = POIArrayTemp.sort(compare);
                    setFourSquarePoints(POIArray);
                } else {
                    setFourSquarePoints([]);
                }
                resolve(true);
            } catch (error) {
                console.log('Error: ', error);
                reject();
            }
        });
    };

    /**
     * Los puntos encontrados a través del componente FourSquare en toolbar
     * @param data 
     */
    const onFourSquarePoints = (data: any[]) => {
        POIPromise(data);
    }

    const [valuesPOI, setValuesPOIS] = useState([]);

    /**
     * Mostrar los sitios que se seleccionaron con el combo en mapa
     * @param sitios 
     */
    const MostrarSitios = async (sitios) => {
        let arregloMedios = sitios.map((medio) => medio.nombre);
        setMediosSeleccionados(sitios);

        const bounds = map.getBounds();

        let distancia = getDistance(bounds.getSouthWest(), bounds.getNorthEast());
        setDistancia(Math.trunc(distancia));

        if (arregloMedios) {
            try {
                setLoading(true);
                let medios = await MediosSitios({
                    latitud: centro.centro.lat,
                    longitud: centro.centro.lng,
                    distancia: distancia / 2,
                    nombres: arregloMedios.toString()
                });
                setMedios(medios);
                setLoading(false);
            } catch (error) {
                console.log('error', error);
            }
        }
    }

    /**
     * Botón para limpiar todo e iniciar una nueva pauta
     */
    const ButtonNuevaPauta = () => {
        setCountProducts();
        for (let index = 0; index < circulos.circulos.length; index++) {
            const element = circulos.circulos[index];
            element.removeSitios();
            element.removeRutas();
            element.circulo.setMap(null);
        }
        setCirculos(new ArrayCirculos());
        PermisoVaciarItems();
        localStorage.removeItem("pautaEdit");
    }

    /**
     * Callback que trae los datos del sitio, se usa en círculos
     * @param marker 
     */
    const handleClickInfoWindow = (marker) => {
        traeSitio({
            variables: {
                where: {
                    clave: marker.title
                }
            }
        });
    }

    /**
     * Función para manejar el borrado de circulos eliminandolo del arreglo
     * @param circle 
     */
    const handleCircleDelete = (circle) => {
        //Funciones del shopping cart
        deleteCircle(circle.identificador);
        // Fin funciones shopping cart

        //encontrar el index en el arreglo de circulos, eliminarlo y asignar el nuevo arreglo de circulos
        let index = circulos.circulos.findIndex((c) => c.identificador === circle.identificador);
        let circulosTemp = [...circulos.circulos];
        circulosTemp.splice(index, 1);
        circulos.circulos = [...circulosTemp];
    }

    /**
     * Listeners para los círculos
     * @param circle 
     */
    const circleListener = (circle) => {
        let circuloCreado = new CirculoInfluencia(circle, map);
        if(shope && shope.length>2){
            setShowAlert(true)
        }
        let contextMenu = document.getElementById('contextMenu');
        circuloCreado.circulo.map.controls[google.maps.ControlPosition.TOP_CENTER].push(contextMenu);
        circuloCreado.circulo.tag = randomInteger(10000);
        circuloCreado.callbackInfoWindow = handleClickInfoWindow;
        circuloCreado.callbackDelete = handleCircleDelete;

        circulos.circulos.push(circuloCreado);
        
        //Configurar el callback para mostrar el spinner
        circuloCreado.callback = setLoading;

        circuloCreado.buscarRutasYMedios().then(() => {
            creaCirculoShopping(circuloCreado);
            setCircFlag(true);
            calculaEstadisticos(circuloCreado);
        });

        //Revisar esto, stateRef
        setCirculoActivo(circuloCreado);
        circuloCreado.circulo.addListener('leftclick', function (event) {
            
            stateRefCirculoActivo.current = circulos.findCircle(this);
            setCirculoActivo(circulos.findCircle(this));
            
            calculaEstadisticos(circulos.findCircle(this));
        })
        circuloCreado.circulo.addListener('rightclick', function (event) {
            stateRefCirculoActivo.current = circulos.findCircle(this);
            setCirculoActivo(circulos.findCircle(this));
            setShowModal(true);
        })

        circuloCreado.circulo.addListener('radius_changed', async function () {
            // let c = this;
            stateRefCirculoActivo.current = circulos.findCircle(this);
            setCirculoActivo(circulos.findCircle(this));
            await stateRefCirculoActivo.current.buscarRutasYMedios();
            let circulo = circulos.findCircle(this);
            creaCirculoShopping(circulo);
            calculaEstadisticos(circulo);
        });
        circuloCreado.circulo.addListener('dragend', async function () {
            let c = this;
            stateRefCirculoActivo.current = circulos.findCircle(c);
            setCirculoActivo(stateRefCirculoActivo.current);
            await stateRefCirculoActivo.current.buscarRutasYMedios();
            let circulo = circulos.findCircle(this);
            creaCirculoShopping(circulo);
            calculaEstadisticos(circulo);
        });
        circuloCreado.circulo.addListener('mouseover', async function (event) {
            event.stop();
            // let c = this;
            this.map.getDiv().setAttribute('title', "Botón derecho para opciones. Círculo: "+(circuloCreado.etiqueta!==undefined ? circuloCreado.etiqueta : circuloCreado.identificador));
        });
        circuloCreado.circulo.addListener('mouseout', function () {
            // let c = this;
            this.map.getDiv().removeAttribute('title');
        });

    }

    /* 
    * Obtener los grados radianes
    */
    const rad = function (x) {
        return x * Math.PI / 180;
    };

    /*
    * Calcular la distancia entre 2 puntos.
    * Se usa con los límites del mapa para estimar un área de búsqueda para INEGI
    * 
    * */
    const getDistance = function (p1, p2) {
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat() - p1.lat());
        var dLong = rad(p2.lng() - p1.lng());
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
    };

    /**
    * Cuando se termina de hacer un drag del mapa se obtienen nuevamente los límites y la distancia entre límites
    */
    const _onDragEnd = async (map) => {
        const bounds = map.getBounds();
        const center = bounds.getCenter();

        let distancia = getDistance(bounds.getSouthWest(), bounds.getNorthEast());
        setDistancia(Math.trunc(distancia));
        map.setCenter(center);
        setCentro({
            centro: {
                lat: center.lat(),
                lng: center.lng()
            }
        });
        if (mediosSeleccionados) {
            let arregloMedios = mediosSeleccionados.map((medio) => medio.nombre);
            // buscar sitios por tipo al hacer un drag del mapa
            if (arregloMedios) {
                try {
                    setLoading(true);
                    let medios = await MediosSitios({
                        latitud: centro.centro.lat,
                        longitud: centro.centro.lng,
                        distancia: distancia / 2,
                        nombres: arregloMedios.toString()
                    });
                    setMedios(medios);
                    setLoading(false);
                } catch (error) {

                }

            }
        }

    }

    /**
     * Calcular la distancia de los límites del mapa
     */
    const calculaDistancia = () => {
        if (map) {
            const bounds = map.getBounds();
            let distancia = getDistance(bounds.getSouthWest(), bounds.getNorthEast());
            setDistancia(Math.trunc(distancia));
        }
    }

    /**
     * Cuando hay un cambio en el mapa
     * @param e 
     */
    const _onChange = (e) => {
        if (map) {
            if (map.center.lat() === 0) {
                map.setCenter(new google.maps.LatLng(centro.centro.lat, centro.centro.lng));
                map.setZoom(zoom);
                setZoom(zoom);
            } else {
                const bounds = map.getBounds();
                // const center = bounds.getCenter();
                let distancia = getDistance(bounds.getSouthWest(), bounds.getNorthEast());
                setDistancia(Math.trunc(distancia));
            }
            calculaDistancia();
        }

    }

    /**
     * 
     * @param e 
     */
    const _onChangeZoom = async (e) => {
        if (map) {
            map.setZoom(e);
            setZoom(e);
            calculaDistancia();
        }
        if (mediosSeleccionados) {
            let arregloMedios = mediosSeleccionados.map((medio) => medio.nombre);
            // buscar sitios por tipo al hacer un drag del mapa
            if (arregloMedios) {
                try {
                    setLoading(true);
                    let medios = await MediosSitios({
                        latitud: centro.centro.lat,
                        longitud: centro.centro.lng,
                        distancia: distancia / 2,
                        nombres: arregloMedios.toString()
                    });
                    setMedios(medios);
                    setLoading(false);
                } catch (error) {

                }
            }
        }
    }

    const onSelectedRutas = (rutas) => {
        setRutasSelected(rutas);
    }

    /**
     * Obtener los límites del mapa
     * @param map 
     * @param maps 
     * @returns 
     */
    const getMapBounds = (map, maps) => {
        const bounds = new maps.LatLngBounds();
        // setBoudsMap(bounds);
        return bounds;
    };

    /**
     * Re-center map when resizing the window
     * @param map 
     * @param maps 
     * @param bounds 
     */
    const bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, "idle", () => {
            maps.event.addDomListener(window, "resize", () => {
                map.fitBounds(bounds);
            });
        });
    };

    /**
     * Fit map to its bounds after the api is loaded
     * @param map 
     * @param maps 
     */
    const apiIsLoaded = async (map, maps) => {
        const bounds = getMapBounds(map, maps);
        // Fit map to bounds
        map.fitBounds(bounds);

        // Agregar div para custom controls
        map.controls[google.maps.ControlPosition.LEFT_TOP].push(controlDiv);

        //Fin custom control
        map.panTo(centro.centro);
        // Bind the resize listener
        bindResizeListener(map, maps, bounds);
        map.setZoom(zoom);
        // map = map; // ?

        setCentro({
            centro: {
                lat: centro.centro.lat,
                lng: centro.centro.lng
            }
        });

        var drawingManager = new maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.CIRCLE]
            },
            circleOptions: {
                draggable: true,
                editable: true
            }
        });
        drawingManager.setMap(map);
        // setDrawingManager(drawingManager);
        drawingManager.addListener('circlecomplete', circleListener);


        // setMapLibrary(maps);
        setMap(map);
        //Reconstruir los circulos
        if (circulos.circulos.length > 0) {
            circulos.circulos.forEach((circulo) => {
                circulo.rebuildCircle(map);
            })
        }
    };

    /**
     * Función para detectar un click en un marcador,
     * onChildClick callback can take two arguments: key and childProps
     * @param key
     * @param props 
     */
    const onChildClickCallback = (key, props) => {
        //TODO: Cambiar por una función que pase las props del sitio y muestre el infoWindow
        if (props && props.medio) {

            //Colocar el componente InfoWindow en el mapa y abrirlo aquí
            //pasar props a un state de medio seleccionado para pasarselo al infowindow
            setSitioInfoWindow(props.medio);
            setShowInfoSitio(true);

        } else {
            setShowInfoWindowMarkers(true);
            setOtherMarker(props);
        }
        //aquí el infowindows del resto de marcadores que no son sitios
    };

    return (
        <IonPage>
            <IonHeader>
                <Toolbar
                    title={props.title}
                    setMenuEnabled={props.setMenuEnabled}
                    menuEnabled={props.menuEnabled}>
                </Toolbar>
                <IonAlert
                    isOpen={showAlert}
					onDidDismiss={() => setShowAlert(false)}
					header={'Registro en memoria detectado'}
					message={'¿Deseas limpiar la memoria y continuar'}
					buttons={[
						{
							text: 'Sí',
							handler: () => {
								clearLocalStorage()
							}
						},
						{
							text: 'No',
							handler: blah => {
								console.log('blah');
							}
						}
					]}
                />
                <ToolbarMapa
                    mapReference={map}
                    loading={setLoading} //Para mostrar el overlay de buscando datos
                    onSearchResult={addSearchMedios} //cuando buscan por clave
                    onSelected={cambiaCiudad} //para centrar el mapa cuando se cambia de ciudad
                    onPoints={onFourSquarePoints} // Evento que crea los markers relacionados a los puntos de interes
                    centro={centro.centro.lat + ',' + centro.centro.lng}
                    distancia={distancia}
                    values={valuesPOI}
                    setValues={setValuesPOIS}
                    zoom={zoom}
                    alertEvent={showError}
                    setZoom={setZoom}
                />

            </IonHeader>
            <IonContent>
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Mapa">
                        <div>
                            <RutasSelect
                                mapa={map}
                                showLabels={true}
                                ciudad={ciudadActual}
                                addToShopping={true}
                                onSelectedList={onSelectedRutas}
                                preSelectedRutas={rutasSelected}
                            />
                            {/*<InfoWindowRutas ruta={rutaInfoWindow} visible={showInfoRutas} onClose={closeInfoWindow} /> */}
                        </div>
                        <div>
                            <TiposMedio
                                onMediosSelected={MostrarSitios}
                                seleccion={mediosSeleccionados}
                                style={{
                                    minHeight: '40px',
                                    marginTop: '5px',
                                    borderRadius: '25px',
                                    backgroundColor: '#FFFFFF',
                                    opacity: '90%',
                                    maxWidth: '170px',
                                    minWidth: '170px',
                                    fontSize: '14px',
                                    position: 'absolute',
                                    marginLeft: "225px",
                                    zIndex: 10000,
                                }}
                            />
                        </div>
                        <div>
                            <IonFab horizontal="end" vertical="center" edge slot="fixed">
                                <div className="tooltipPrincipal">
                                    <IonFabButton>
                                        <IonIcon icon={settings} />
                                    </IonFabButton>
                                    <span className="tooltiptext">Opciones</span>
                                </div>
                                <IonFabList side="start">
                                    <ButtonTraffic traffic={traffic} map={map} setTraffic={setTraffic} />
                                    <div className="tooltip">
                                        <IonFabButton onClick={ButtonNuevaPauta} color="secondary" placeholder="nombre"><IonIcon icon={newspaper} /></IonFabButton>
                                        <span className="tooltiptext">Nueva Pauta</span>
                                    </div>
                                    <div className="tooltip">
                                        <IonFabButton onClick={() => setshowPopover(true)} color="tertiary" placeholder="nombre"><IonIcon icon={informationCircle} /></IonFabButton>
                                        <span className="tooltiptext">Crear Círculo</span>
                                    </div>

                                </IonFabList>
                            </IonFab>
                        </div>
                        <div>
                            <PopupInputsCirculo
                                showPopover={showPopover}
                                setshowPopover={setshowPopover}
                                map={map}
                                CrearCirculoConlatLng={CrearCirculoConlatLng}
                                circleListener={circleListener}
                                circulos={circulos}
                            />
                        </div>
                        {notification}
                        <Spinner loading={loading} />
                        <Splitter>
                            <SplitterPanel size={80}>
                                <div id="map_canvas" style={{ height: window.innerHeight - 190, width: "100%" }}>
                                    <div style={{ textAlign: 'right', zIndex: 9000, position: 'absolute', bottom: 0 }}><img src='/assets/IMJ.png' width="100" /></div>
                                    <div style={{ textAlign: 'right', zIndex: 9000, position: 'absolute', right: 10 }}><img src='/assets/IMJ.png' width="100" /></div>
                                    <GoogleMapReact
                                        key={'mapa'}
                                        bootstrapURLKeys={{ key: IMJ_API_KEY, libraries: ["places", "drawing"] }}
                                        defaultCenter={{ lat: 19.43569693370444, lng: -99.13175137016965 }}
                                        defaultZoom={16}
                                        zoom={zoom}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                                        onDragEnd={_onDragEnd}
                                        onChange={_onChange}
                                        onZoomAnimationEnd={_onChangeZoom}
                                        onChildClick={onChildClickCallback}
                                        options={{
                                            mapTypeControl: false,
                                            streetViewControl: true,
                                            fullscreenControl: false,
                                            styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }], },]
                                        }}
                                    >
                                        {medioBuscado && medioBuscado.length > 0 && medioBuscado.map(medio => (
                                            <MarkerIMJ
                                                key={medio.id}
                                                nombre={medio.clave}
                                                lat={medio.latitud}
                                                lng={medio.longitud}
                                                tipo={medio.tipos ? medio.tipos.nombre : 'Sin tipo'}
                                                medio={medio}
                                                show={medio.show}
                                            />
                                        ))}

                                        {medios && medios.length > 0 && medioBuscado.length <= 0 && medios.map(medio => (
                                            <MarkerIMJ
                                                key={medio.id}
                                                nombre={medio.clave}
                                                lat={medio.latitud}
                                                lng={medio.longitud}
                                                tipo={medio.tipos ? medio.tipos.nombre : 'Sin tipo'}
                                                medio={medio}
                                                show={medio.show}
                                            />
                                        ))}

                                        {fourSquarePoints && fourSquarePoints.length > 0 && fourSquarePoints.map((fsPoint) => (
                                            <MarkerFourSq
                                                category={fsPoint.category}
                                                key={fsPoint.id}
                                                text={fsPoint.text}
                                                lat={fsPoint.lat}
                                                lng={fsPoint.lng}
                                                icono={fsPoint.icono}
                                                color={fsPoint.color ? fsPoint.color : '#0'}
                                                codigoact={'fs'}
                                                data={fsPoint.data}
                                            />
                                        ))}

                                        {otherMarker && <IonPopover
                                            isOpen={showInfoWindowMarkers}
                                            onDidDismiss={e => {
                                                setShowInfoWindowMarkers(false);
                                                setOtherMarker(null);
                                            }}
                                        >
                                            <InfoWindowGooglePlace infoProp={otherMarker} />

                                            {/* <IonCard>
                                        <IonCardHeader>
                                            {otherMarker.text}
                                        </IonCardHeader>
                                    </IonCard> */}
                                        </IonPopover>}
                                        <InfoWindowSitios sitio={sitioInfoWindow} visible={showInfoSitio} onClose={closeInfoWindow} />
                                        {/* <InfoWindowRutas ruta={rutaInfoWindow} visible={showInfoRutas} onClose={closeInfoWindow} /> */}
                                    </GoogleMapReact>
                                </div>
                            </SplitterPanel>
                            <SplitterPanel size={20}>
                            <TabView activeIndex={activeStat} onTabChange={(e) => setActiveStat(e.index)}>
                                    <TabPanel header="Precio">
                                        <div style={{ flexDirection: "row" }}>

                                            <div style={{ marginTop: 100 }}>
                                                Círculo Activo : <b>{analiticaCostos.circuloActivo}</b>
                                            </div>
                                            <div>
                                            {/* <div style={{ marginTop: 100 }}> */}
                                                <p>Precio promedio : $ <b>{formatMoney(analiticaCostos.mediaprecio)}</b></p>
                                            </div>
                                            <div>
                                                <p>Mediana del precio : $ <b>{formatMoney(analiticaCostos.medianaprecio)}</b></p>
                                            </div>
                                            <div>
                                                <p>Precio máximo : $ <b>{formatMoney(analiticaCostos.maxprecio)}</b></p>
                                            </div>
                                            <div>
                                                <p>Precio mínimo : $ <b>{formatMoney(analiticaCostos.minprecio)}</b></p>
                                            </div>
                                            <div>
                                                <p>Precio promedio x m2 : $ <b>{formatMoney(analiticaCostos.m2precio)}</b></p>
                                            </div>
                                            <br></br>
                                            <p><b>Un valor de cero en m2 significa que no todos los sitios seleccionados contienen longitud y largo</b></p>
                                            <p><b>Promedio :</b>El promedio es un número representativo que puede obtenerse a partir de una lista de cifras. Usualmente se relaciona con el concepto de media aritmética.</p>
                                            <p><b>Mediana: </b>La mediana es un estadístico de posición central que parte la distribución en dos, es decir, deja la misma cantidad de valores a un lado que a otro.</p>
                                        </div>
                                    </TabPanel>
                                    <TabPanel header="Costo" disabled={!analiticaVisible}>
                                        <div style={{ flexDirection: "row" }}>
                                            <div style={{ marginTop: 100 }}>
                                                Círculo Activo : <b>{analiticaCostos.circuloActivo}</b>
                                            </div>
                                            <div>
                                                <p>Costo promedio : $ <b>{formatMoney(analiticaCostos.costoPromedio)}</b></p>
                                            </div>
                                            <div>
                                                <p>Mediana del costo : $ <b>{formatMoney(analiticaCostos.mediana)}</b></p>
                                            </div>
                                            <div>
                                                <p>Costo máximo : $ <b>{formatMoney(analiticaCostos.max)}</b></p>
                                            </div>
                                            <div>
                                                <p>Costo mínimo : $ <b>{formatMoney(analiticaCostos.min)}</b></p>
                                            </div>
                                            <div>
                                                <p>Costo promedio x m2 : $ <b>{formatMoney(analiticaCostos.costoxm)}</b></p>
                                            </div>
                                            <br></br>
                                            <p><b>Un valor de cero en m2 significa que no todos los sitios seleccionados contienen longitud y largo</b></p>
                                            <p><b>Promedio :</b>El promedio es un número representativo que puede obtenerse a partir de una lista de cifras. Usualmente se relaciona con el concepto de media aritmética.</p>
                                            <p><b>Mediana: </b>La mediana es un estadístico de posición central que parte la distribución en dos, es decir, deja la misma cantidad de valores a un lado que a otro.</p>
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </SplitterPanel>
                        </Splitter>
                        <Slider
                            title='Filtros de búsqueda'
                            style={{ width: '400px' }}
                            isOpen={showModal}
                            onOutsideClick={() => setShowModal(false)}>
                            <div style={{ margin: '50px 15px', }}>
                                <PopUpCirculos circulo={stateRefCirculoActivo.current} onFinish={creaCirculoShopping} />
                            </div>
                        </Slider>
                    </TabPanel>
                    <TabPanel header="Visualiza tu Pauta">
                        {notification}
                        <FormDialogPauta
                            datos={datos}
                            operacion={""}
                            onSuccess={onSuccess}
                            onDiscard={onDiscard}
                        //setFlagBorrar={setFlagBorrar}
                        ></FormDialogPauta>
                    </TabPanel>
                </TabView>
            </IonContent>
        </IonPage>
    )
}

export default Sitios;