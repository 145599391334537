import React from 'react';

const Footer = (props) => {
    return (
        <div id={'footer'} 
            style={{
                position: 'absolute',
                display: 'table',
                left: 0,
                bottom: 0,
                height: '50px',
                width: '100%',
                color: 'white',
                padding: '10px',
                backgroundColor: 'black',
                fontSize: '12px',
            }}
            >
            <div style={{display:'flex', justifyContent:'space-around'}} >
                <div>Corporativo GDL Av. Manuel Ávila Camacho #2247, Col. Country Club CP 44610</div>
                <div style={{marginLeft:'auto'}}>Oficinas CDMX Paseo de las Palmas #239, int. 204, Col. Lomas de Chapultepec</div>
            </div>
            <div style={{display:'flex', justifyContent:'space-around'}} >
                <div>Guadalajara, Jalisco, 01(33) 3640-0944 - 01(33)1813-4067 - 800-055-33-33</div>
                <div style={{marginLeft:'auto'}}>C.P. 11000, CDMX (55)-5293-8260 - (55)-5293-8265</div>
            </div>
        </div>
    )
}
export default Footer;
