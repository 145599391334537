/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react'
import Estados from '../estados/Estados';
import { createPortal } from 'react-dom';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';

import {GoogleMapsPlaces} from './';
import BuscaClave from './BuscaClave';
import {Panel} from 'primereact/panel';
import Select from 'react-dropdown-select';
import { Redirect } from "react-router";
import './Controls.css';
import './mapa.styles.css';

export const CustomControl = ({ children }) => {
    let control = document.getElementById('controles');
    if (control) {
        return createPortal(children, control);
    } else {
        return (<div></div>)
    }
}

export const TiposMedio = (props) =>{
    const TIPOS = gql`
        query tipos($sort: String, $limit: Int, $start: Int, $where: JSON){
            tipos(sort: $sort, limit: $limit, start: $start, where: $where){
                id
                nombre
                tipomedio{
                    id
                    nombre
                }
            }
        }
    `
    const { loading, error, data, refetch } = useQuery(TIPOS, {
        variables:{
            where:{
                "tipomedio":
                {
                    "nombre":"OOH"
                }
            }
        }
    });
    const [elementoSel,setElementoSel] = useState(props.seleccion || []);
    
    useEffect(()=>{
        if (props.seleccion){
            setElementoSel(props.seleccion);
        }
    },[props.seleccion]);
    
    return (
        <Select
            {...props}
            options={(data && data.tipos)?data.tipos:null} // Options to display in the dropdown
            values={elementoSel} // Preselected value to persist in dropdown
            labelField="nombre" // Property name to display in the dropdown options
            valueField='id'
            multi={true}
            onDropdownClose={(e)=>{if (props.onMediosSelected){
                props.onMediosSelected(elementoSel);
            }}}
            onChange={(e)=>{
                setElementoSel(e);
            }}
            searchable={true}
            placeholder='Seleccione sitios'
            searchBy='nombre'
            sortBy='nombre'
        />
    )
}


export const ToolbarMapa = (props) => {
    const [panelCollapsed, setPanelCollapsed ] = useState(true)
    const [redirect, setRedirect] = useState(false);

    return (
        <Panel header="Herramientas de búsqueda" 
            toggleable={true}
            collapsed={panelCollapsed} 
            onToggle={(e) => setPanelCollapsed(e.value)}
            style={{backgroundColor:'white',overflow:'visible'}}>
            <div className={'row'}>
                    {redirect && <Redirect to="/page/InformacionCliente" />}
                    <div 
                        style={{flex: 1}}
                    >
                        <GoogleMapsPlaces
                            onPoints={props.onPoints||null}
                            centro={props.centro}
                            distancia={props.distancia}
                            mapReference={props.mapReference}
                            setValues={props.setValues}
                            values={props.values}
                            zoom={props.zoom}
                            setZoom={props.setZoom}
                            alertEvent={props.alertEvent}
                            setLoading={props.loading}
                        />
                    </div>

                    <div 
                        style={{flex: 1}}
                    ><BuscaClave onSearchResult={props.onSearchResult} loading={props.loading}/></div>
                    <div 
                        style={{flex: 1}}
                    ><Estados onSelected={props.onSelected} /></div>
            </div>
        </Panel>
    )
}