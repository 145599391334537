import { IonCol, IonRow } from '@ionic/react';
import { Button } from 'primereact/button';
import React from 'react';

/**
 * 
 * @param {Object} props
 * @param {() => void} props.onDiscard
 * @returns 
 */
const Botones = ({onDiscard}) => {
    return (
        <div>
            <IonRow>
                <IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
                    <div style={{ marginRight: "40px" }}></div>
                    <Button
                        label="Grabar"
                        icon="pi pi-check"
                        type="submit"
                        className="p-button-success ion-float-right"
                        style={{ marginLeft: "20px" }}
                    />
                </IonCol>
                <IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
                    <Button
                        label="Salir  "
                        icon="pi pi-times"
                        className="p-button-danger ion-float-left"
                        float-right="true"
                        onClick={onDiscard}
                        style={{ marginLeft: "20px" }}
                    />
                    <div style={{ marginRight: "40px" }}></div>
                </IonCol>
            </IonRow>
        </div>
    )
}

export default Botones;