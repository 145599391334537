import React, { useEffect, useState } from 'react'
import PulseLoader from "react-spinners/PulseLoader";
import styles from './Spinner.module.css';

const Spinner = (props) => {
    const [loading,setLoading] = useState(props.loading);
    
    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])
    return (
        <div className={loading?styles.overlay:styles.ovelayHide}>
            <div style={{position: "fixed", top: "55%", left: "50%", transform: "translate(-50%, -50%)", margin:'0 auto'}}>
                <div className={styles.textoSpinner}>Consultando datos</div>
                <PulseLoader
                    size={30}
                    color={"#FCB23B"}
                    loading={loading}
                />
            </div>    
        </div>
    )
}

export default Spinner;