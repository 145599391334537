/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState,useEffect,useContext} from "react";
import {LISTAR_MEDIOS,LISTAR_RUTAS} from './queries';

import {useQuery } from '@apollo/client';
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";
import { IonGrid,
         IonRow,
         IonCol,
         IonSpinner,
} from '@ionic/react';
import {Dropdown} from 'primereact/dropdown';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import {Redirect } from "react-router";
import { InputTextComponent } from "../../components";




export default function FormProductos(props){
  
    const {addProduct,addProductRuta} = useContext(ShoppingCartContext);
    const [redirectProducts, setredirectProducts] = useState(false);
    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
            producto : props.datos.producto,
           
            fecha_inicio : props.datos.fecha_inicio,
            fecha_fin : props.datos.fecha_fin,
            pauta : props.datos.cliente,
            medio : props.datos.medio,
            ruta  : props.datos.ruta
		}
    });



    const [fields,setValueFields] = useState(props.datos);

 

    const onSubmit = data => { 
        RegistrarProductos();

        setTimeout(() => {
            setredirectProducts(true);
        }, 2000);

        setTimeout(() => {
            setredirectProducts(false);
        }, 2000);
    }

    /* Variables */
  
    const [IdListaMedios, setIdListaMedios] = useState(props.datos.medios ===  null ? null : props.datos.medios.id);
    const [IdListaRutas, setIdListaRutas] = useState(props.datos.rutas === null ? null : props.datos.rutas.id)
    const [elementoSelMedio,setElementoSelMedio] = useState<string>(fields.medios === null ? "" : fields.medios);
    const [elementoSelRutas, setElementoSelRutas] = useState<string>(fields.rutas === null ? "" : fields.rutas);
    const [contador, setContador] = useState(0);
    const [InputController, setInputController] = useState([]);
    const [VariablesFromInput,setVariablesFromInput] = useState([]);
    const [cantidad, setCantidad] = useState("");
    const [producto, setproducto] = useState("");
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");
    const [IdtempProducto, setIdtempProducto] = useState();
    const [claveSitio, setClaveSitio] = useState("");
    const [NombreRuta, setNombreRuta] = useState("");
    const [nombreProducto, setNombreProducto] = useState<string>();
  

    function RegistrarProductos(){
        let objects_rutas =[];

        if(elementoSelMedio === ""){

        }else{
           
            let objectSitio={
                    id: IdListaMedios,
                    clave: claveSitio,
                    referencia:"",
                    fecha_inicio: fechaInicio,
                    fecha_fin: fechaFin,
                    
                    precio:""
                }
                addProduct(objectSitio);   
        }

        if(elementoSelRutas === ""){

        }else{
         
         
            let objectRuta ={
                id: IdListaRutas,
                nombre_producto: NombreRuta,
                descripcion: "",
                fecha_inicio: fechaInicio,
                fecha_fin: fechaFin,
               
            };
            objects_rutas.push(objectRuta);

        }
        
        
        
        for (let index = 0; index < VariablesFromInput.length; index++) {
            const element = VariablesFromInput[index];
            

            if(element !== undefined){
                
                if( typeof(element.VarMedio) === 'object' ){
                    
                    let objectSitio={  
                        id: element.VarMedio.id,
                        clave: element.VarMedio.clave,
                        referencia:"",
                        fecha_inicio: element.varfechaInicio,
                        fecha_fin: element.varfechaFin,
                        
                        precio:""
                    };
                    addProduct(objectSitio);
                }
                 
                

                if( typeof(element.VarRuta) === 'object' ){
                  
                    let objectRuta ={
                        id: VariablesFromInput[index].VarRuta.id,
                        nombre_producto: VariablesFromInput[index].VarRuta.nombre,
                        descripcion: "",
                        fecha_inicio: element.varfechaInicio,
                        fecha_fin: element.varfechaFin,
                        
                    };
                   
                    objects_rutas.push(objectRuta);
                }

            }
        }
        addProductRuta(objects_rutas);
    
    }

    
    const {loading:loadingMedio,  error:errorMedio, data: dataMedio} = useQuery(LISTAR_MEDIOS);
    const {loading:loadingRutas , error:errorRutas , data: dataRuta} = useQuery(LISTAR_RUTAS);
    

    const ListarMedios = () =>{
        if (errorMedio) return <h5 style={{color:"red"}}>Algo salio mal</h5>;
        if (loadingMedio) return <IonSpinner name="dots" />
        if (dataMedio) return (

            <Dropdown 
                id="ListaSitios_1"
                tabIndex={2}
                showClear={true}
                style={{width:'100%'}}
                value={elementoSelMedio} 
                options={dataMedio.medios} 
                onChange={onselectMedio} 
                dataKey='id'
                optionLabel='clave'
                placeholder="Selecciona un sitio"
              
            />
        )

    }

    const ListarRutas =()=>{
        if (errorRutas) return <h5 style={{color:"red"}}>Algo salio mal</h5>;
        if (loadingRutas) return <IonSpinner name="dots" />
        if (dataRuta) return(
            <Dropdown
                id="ListaRutas_1" 
                tabIndex={3}
                showClear={true}
                style={{width:'100%'}}
                value={elementoSelRutas}
                options={dataRuta.rutas}
                onChange={onselectRutas}
                dataKey="id"
                optionLabel="nombre"
                placeholder="Selecciona una ruta"
                
            />
        )
    }
   
    const onselectMedio=(selectId)=>{
        if(selectId.value){
            setIdListaMedios(parseInt(selectId.value.id));
            setElementoSelMedio(selectId.value);
            setClaveSitio(selectId.value.clave);
        }else{
            setIdListaMedios(null);
            setElementoSelMedio(null);
            setClaveSitio("");
        }
    }

    const onselectRutas =(selectId)=>{
        if(selectId.value){
            setIdListaRutas(parseInt(selectId.value.id))
            setElementoSelRutas(selectId.value);
            setNombreRuta(selectId.value.nombre);
        }else{
            setIdListaRutas(null);
            setElementoSelRutas(null);
            setNombreRuta("");
        }
    }


    /**
     * @AgregarInput agrega la palabra @num_ seguido 
     * de un numero que va incrementando conforme se 
     * agreguen inputs al formulario ,la const @contador 
     * es parseado a @string para ser concatenado con
     * number, se agrega a un array llamado @InputController
     * el cual contine el control de los inputs renderizados.
     * /////////////////////////////////////////////////////
     * Se crea un @objeto nuevo con los nombres 
     * de los inputs que con contendran los valores 
     * registrados en ellos.
     * se agrega ese objeto al array @vVariablesFromInput
     */
    const AgregarInput =()=>{
        InputController.push("num_"+String(contador));
        setContador(contador+1);
        let VarInput ={
                       VarMedio : "",
                       VarRuta : "",
                      
                       varfechaInicio : "" , 
                       varfechaFin:""
                      };
        VariablesFromInput.push(VarInput);
    }

    /**
     * @EliminarInputs obtiene la fila(row) dentro 
     * del formulario por medio del id establecido
     * en la fila ya creada.Se extrae el numero de 
     * la candena string del id de la fila y se 
     * procede a eliminar del arreglo en la posicion
     * espeficicada que se extrajo de la cadena. 
     */
    const EliminarInputs =(id)=>{
        document.getElementById(id).style.display = "none";
        let GuionBajo = "_";
        let arrayDeCadena = id.split(GuionBajo);
        let element =  parseInt(arrayDeCadena[1]);
        delete InputController[element];
        delete VariablesFromInput[element];
    }

   
    const Item =(props)=> {
        
        /**
         * variable @regex contiene caracteres especiales
         * y letras .
         */
        let regex = /(\d+)/g;

        /**
         * variable @numberVar contine la comversion en 
         * entero de lo que contiene props y con regex 
         * se descartan caracteres especiales y letras 
         * para solo dejar numero(s) si es que los hay.
         */
        let numberVar=parseInt(props.message.match(regex));
        
        /**
         * variables que son usadas por los inputs
         * continen el alias temp para diferenciar
         * las variables que los inputs contienen
         * por defecto , que son los que se renderizan
         * al iniciar la pagina de agregar porductos.
         */
        const [tempSitio, setTempSitio] = useState( VariablesFromInput[numberVar] === undefined ? "" :VariablesFromInput[numberVar].VarMedio );
        const [tempRuta, setTempRuta] = useState( VariablesFromInput[numberVar] === undefined ? "" : VariablesFromInput[numberVar].VarRuta);
        const [tempCantidad, settempCantidad] = useState( VariablesFromInput[numberVar] === undefined ? "" : VariablesFromInput[numberVar].varCantidad);
        const [tempFechaInicio, setTempFechaInicio] = useState( VariablesFromInput[numberVar] === undefined ? "" : VariablesFromInput[numberVar].varfechaInicio);
        const [tempFechaFin, setTempFechaFin] = useState( VariablesFromInput[numberVar]  === undefined ? "": VariablesFromInput[numberVar].varfechaFin);

        /**
         * @listarMedios contine la lista de sitios
         * existentes en base de datos ,se creo una 
         * nueva lista para direnciar la lista ya 
         * renderizada de las listas creadas en  los
         * nuevos inputs.
         */
        const ListaDeMedios = ()=>{
            if (loadingMedio) return <IonSpinner name="dots" />
            if (dataMedio) return (
                <Dropdown 
                    showClear={true}
                    style={{width:'100%'}}
                    value={tempSitio} 
                    options={dataMedio.medios} 
                    onChange={e =>{
                        setTempSitio(e.target["value"]);
                        VariablesFromInput[numberVar].VarMedio=e.target["value"]; 
                    }} 
                    dataKey='id'
                    optionLabel='clave'
                    placeholder="Selecciona un sitio"
                 
                />
            )
        }

        /**
         * @listaRutas contine la lista de rutas
         * existentes en base de datos ,se creo una 
         * nueva lista para direnciar la lista ya 
         * renderizada de las listas creadas en los
         * nuevos inputs.
         */
        const ListaDeRutas=()=>{
            if (loadingRutas) return <IonSpinner name="dots" />
            if(dataRuta) return(
                <Dropdown 
                    showClear={true}
                    style={{width:'100%'}}
                    value={tempRuta}
                    options={dataRuta.rutas}
                    onChange={e =>{
                        setTempRuta(e.target["value"]);
                        VariablesFromInput[numberVar].VarRuta=e.target["value"];
                    }}
                    dataKey="id"
                    optionLabel="nombre"
                    placeholder="Selecciona una ruta"
                    
                />
            )
        }


        /**
         * nuevos inputs que seran creados cuando el 
         * usuario de click a el  @Button +
         * estos inputs continen su value y onchange
         * con variables direfentes y posiciones en 
         * arreglo para separar la informacion de los
         * inputs.
         */
        return <IonRow id={props.message+"_inputs"} >
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                        <br></br>
                        <label htmlFor="in">Sitios</label>
                        <ListaDeMedios />
                    </IonCol>

                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                        <br></br>
                        <label htmlFor="in">Rutas</label>
                        <ListaDeRutas />
                    </IonCol>

                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                        <br></br>
                        <label htmlFor="in">Fecha inicio</label>
                        <InputTextComponent 
                            type={"date"}
                            value={tempFechaInicio}
                            style={{width:"100%"}}
                            required
                            onChange={e =>{
                                setTempFechaInicio(e);
                                VariablesFromInput[numberVar].varfechaInicio = e ;
                            }}
                            accept={null}
                            className={null}
                            disabled={null}
                            label={null}
                            name={null}
                            onKeyPress={null}
                            placeholder={null}
                            onBlur={null}

                        />
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                        <br></br>
                        <label htmlFor="in">Fecha fin</label>
                        <InputTextComponent
                            type={"date"}
                            value={tempFechaFin}
                            style={{width:"100%"}}
                            required
                            onChange={e =>{
                                setTempFechaFin(e);
                                VariablesFromInput[numberVar].varfechaFin = e;
                            }}
                            accept={null}
                            className={null}
                            disabled={null}
                            label={null}
                            name={null}
                            onKeyPress={null}
                            placeholder={null}
                            onBlur={null}
                        />
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2"> 
                        <br></br>
                        <br></br>
                        <Button 
                            id={props.message+"button_danger"}
                            icon="pi pi-trash"
                            className="p-button-danger"
                            type="button"
                            onClick={()=>
                                EliminarInputs(props.message+"_inputs")
                            }
                            style={{marginLeft:"20PX"}}
                        />
                    </IonCol>
                </IonRow>;
    }

   
    /**
     * @MostrarInputs es el que lleva el control de los 
     * inputs que han sido creados.con la funcion map 
     * renderiza los inputs para que el usuario los visualize.
     */
    const MostrarInputs =() =>{
        return (
            <div>
                {InputController.map((message) =>
                    <Item key={message} message={message} />
                )}
            </div>
        );
    }
   
    /**
     * @useEffect verifica si hay cambios en el arreglo
     * @InputsController
     */
    useEffect(() => {
        if(InputController){
        
            MostrarInputs();
        }

        if(VariablesFromInput){
            MostrarInputs();
        }
    },[InputController]);

   

    return ( 
        <IonGrid>
            <form onSubmit={handleSubmit(onSubmit)}>
                {redirectProducts && <Redirect to="/page/InformacionCliente" />}
                <ul>
                    <IonRow>
                        <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="6" sizeXl="6">
                        <br></br>
                            <label htmlFor="in">Producto sitio</label>
                            <InputTextComponent
                                value={nombreProducto}
                                onChange={(e) => {
                                    setNombreProducto(e);
                                }}
                                style={{border:"1px gray solid",height:"37%"}}
                                onBlur={null}
                                name={null}
                                required={null}
                                type={null}
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>

                    </IonRow>
             
                    <IonRow>
                        <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                            <br></br>
                            <label htmlFor="in">Sitios</label>
                           
                            <ListarMedios />
                        </IonCol>
                        <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                            <br></br>
                            <label htmlFor="in">Rutas</label>
                             
                            <ListarRutas />
                        </IonCol>
                        <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                            <br></br>
                            <label htmlFor="in">Fecha inicio</label>
                            <InputTextComponent 
                                name="fecha_inicio"
                                type={"date"}
                                value={fechaInicio}
                                style={{width:"100%"}}
                                required
                                onChange={e =>{
                                    setFechaInicio(e);
                                    //handleChange(e);
                                    //setValue("cantidad", e.target["value"]);
                                }}
                                onBlur={null}
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                            <br></br>
                            <label htmlFor="in">Fecha fin</label>
                            <InputTextComponent 
                                name="fecha_fin"
                                type={"date"}
                                value={fechaFin}
                                style={{width:"100%"}}
                                required
                                onChange={e =>{
                                    setFechaFin(e);
                                    //handleChange(e);
                                    //setValue("fecha_fin", e.target["value"]);
                                }}
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                                onBlur={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2">
                            <br></br>
                            <br></br>
                            <Button 
                                tabIndex={7}
                                id="button_plus"
                                icon="pi pi-plus"
                                className="p-button-primary"
                                type="button"
                                onClick={()=>
                                    AgregarInput()
                                }
                                style={{marginLeft:"20PX"}}
                            />
                        </IonCol>
                    </IonRow>
                    {MostrarInputs()}
                </ul>
             
                <br></br>
                <br></br>
                <IonRow>
                    <IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
                        <br></br>
                        <Button 
                            label="Registrar"
                            icon="pi  pi-save"
                            className="p-button-success ion-float-right"
                            type="submit"
                            style={{ marginLeft: "20px"}}
                        />
                    </IonCol>
                    
                </IonRow>
            </form>
        </IonGrid>
            
     
    )

}