/* eslint-disable @typescript-eslint/no-unused-vars */
import Excel, { FillPattern, Workbook, Worksheet } from 'exceljs';


let currentWorkBook:Workbook = null;
let currentSheet:Worksheet = null;
export const EXCEL_EXTENSION='.xlsx';
/** 
 *  Bloque de constantes de colores para el relleno de celdas. Los colores oficiales IMJ tienen el sufijo IMJ. Ir añadiendo según necesidad.
 * **/
const rellenoRojo:FillPattern = {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'FFFF0000'},
  };

const rellenoAzul:FillPattern = {
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb:'73A7F8'}
}  ;

const rellenoGris:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb:'cccccc'}
} ;

const rellenoAzulIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: '5D97FD'}
};

const rellenoRojoIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: 'FE514B'}
};

const rellenoTurquesaIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: '76D3D2'}
};

const rellenoMagentaIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: 'E15AAD'}
};

const rellenoAmarilloIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: 'FCCA7B'}
};

const rellenoMoradoIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: 'B792FD'}
};

const rellenoVerdeAmarilloIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: 'ECF9B5'}
};

const rellenoVerdeIMJ:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: '8AE1AC'}
};

const rellenoBlanco:FillPattern={
    type: 'pattern',
    pattern: 'solid',
    fgColor:{argb: 'ffffffff'}
}

/**
 * Función para activar un libro de trabajo de excel
 * @param wb 
 */
export const ActivarLibro = (wb) =>{
    currentWorkBook = wb;
}

/**
 * 
 * @returns Función que devuelve el libro de trabajo actual
 */
export const LibroActual = () =>{
    return currentWorkBook;
}

/**
 * Función que crea un libro de trabajo de Excel y lo devuelve haciéndolo el libro activo actual.
 * @returns 
 */
export const workBook = () =>{
    let workbook =  new Excel.Workbook();
    workbook.creator = 'IMJ Media';
    workbook.lastModifiedBy = 'IMJ Media';
    workbook.created = new Date();
    workbook.modified = new Date();
    currentWorkBook = workbook
    return currentWorkBook;
}

/**
 * Función para agregar una hoja de trabajo al libro actual
 * @param title 
 * @returns 
 */
export const AddSheet = (title) =>{
    currentSheet = currentWorkBook.addWorksheet(title);
    return currentSheet;
}

/**
 * Función para devolver la hoja activa actual
 * @returns Devuelve la hoja activa actual
 */
export const HojaActiva = () =>{
    return currentSheet;
}
/**
 * Función para activar una hoja en el libro activo
 * @param hoja 
 */
export const UsarHoja = (hoja)=>{
    currentWorkBook.getWorksheet(hoja)
}

/**
 * Función para hacer un merge de celdas en la hoja activa
 * @param rango 
 */
export const CombinarCeldas = (rango) =>{
    currentSheet.mergeCells(rango);
}

/**
 * Función para asignar el valor de una celda
 * @param celda Celda a la que se quiere asignar el valor
 * @param valor Valor que se asignará a la celda
 * @param formatoNum Formato numérico que se aplicará a la celda, default null
 * @param color Color del texto de la celda en formato argb, default negro
 * @param fontSize Tamaño de la fuente de la celda, default negro
 */
export const ValorCelda = (celda,valor,formatoNum=null, color='#00000000', fontSize=12) =>{
    let c = currentSheet.getCell(celda);
    if (formatoNum){
        c.numFmt = formatoNum;
    }
    c.font = {
        color:{argb:color},
        size:fontSize
    }
    c.value = valor;
}

/**
 * Función para cambiar el formato de una fila
 * @param fila Número de fila a cambiar el formato
 * @param formatoFila Objeto conteniendo las propiedades de formato
 * @example formatoFila = {height:40,font:{bold:true,size:18,name:'Dia Regular'},alignment:{vertical:'middle',horizontal:'center'}}
 */
export const FormatoFila = (fila,formatoFila) =>{
    let cr = currentSheet.getRow(fila);
    cr = Object.assign(cr,formatoFila);
}

/**
 * Función para rellenar una celda
 * @param celda String celda a rellenar. Ejemplo: 'A1'
 * @param {String|FillPattern} color - con el color a rellenar o el objeto FillPattern
 */
export const RellenoCelda = (celda:string,color:string|FillPattern='Rojo')=>{
    switch (color) {
        case 'Rojo':
            currentSheet.getCell(celda).fill = rellenoRojo;
            break;
        case 'Azul':
            currentSheet.getCell(celda).fill = rellenoAzul;
            break;
        case 'Gris':
            currentSheet.getCell(celda).fill=rellenoGris;
            break;
        case 'AzulIMJ':
            currentSheet.getCell(celda).fill=rellenoAzulIMJ;
            break;
        case 'RojoIMJ':
            currentSheet.getCell(celda).fill=rellenoRojoIMJ;
            break;
        case 'TurquesaIMJ':
            currentSheet.getCell(celda).fill=rellenoTurquesaIMJ;
            break;
        case 'MagentaIMJ':
            currentSheet.getCell(celda).fill=rellenoMagentaIMJ;
            break;
        case 'AmarilloIMJ':
            currentSheet.getCell(celda).fill=rellenoAmarilloIMJ;
            break;
        case 'MoradoIMJ':
            currentSheet.getCell(celda).fill=rellenoMoradoIMJ;
            break;
        case 'VerdeAmarilloIMJ':
            currentSheet.getCell(celda).fill=rellenoVerdeAmarilloIMJ;
            break;
        case 'VerdeIMJ':
            currentSheet.getCell(celda).fill=rellenoVerdeIMJ;
            break;
        case 'Blanco':
            currentSheet.getCell(celda).fill=rellenoBlanco;
            break;
        default:
            break;
    }
}

/**
 * Función para cambiar el borde de una celda en la hoja activa
 * @param celda número de celda o identificador de celda a cambiar
 * @param borde objeto definiendo el borde
 * 
 * @example top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}
 */
export const BordeCelda = (celda,borde) =>{
    currentSheet.getCell(celda).border = borde;
}

/**
 * Función para cambiar la altura de la fila
 * @param fila Número de fila para cambiar su altura
 * @param altura Altura de la fila
 */
export const AltoFila = (fila,altura=0) => {
    currentSheet.getRow(fila).height = altura;
}

export const FuenteCelda = (celda,fuente) =>{
    currentSheet.getCell(celda).font = fuente;
}

export const AlineacionCelda = (celda,alineacion)=>{
    currentSheet.getCell(celda).alignment = alineacion;
}
/**
 * 
 * @param fila Número de fila a cambiar
 * @param valores Arreglo con los valores para la fila
 */
export const ValoresFila = (fila,valores:Array<any>) =>{
    currentSheet.getRow(fila).values = valores;
}

/**
 * 
 * @param celdas celda a formatear
 * @param {String|FillPattern} relleno - con el color a rellenar o el objeto FillPattern
 * @param borde estilo del borde @example{
            top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}
        }
 * @param alineacion alineación del contenido @example {
            vertical: 'middle', horizontal: 'center'
        }
 * @param fuente definición de la fuente para la celda @example {
            bold: true, 
            size:16, 
            name:'Dia Regular'
        }
 */
export const FormatoMultiplesCeldas = (celdas:Array<any>,relleno='Rojo',borde={},alineacion={},fuente={}) =>{
    celdas.forEach( celda =>{
        RellenoCelda(celda,relleno);
        BordeCelda(celda,borde);
        AlineacionCelda(celda,alineacion);
        FuenteCelda(celda,fuente);
    })
}