import React from 'react';
import { randomInteger } from "../../../../utils/Utils";
import { ApolloProvider } from '@apollo/client';
import cliente from "../../../../apollo/client";
import MediosQuery from '../../../../components/mapa/mediosQuery';
import { Spinner } from '../../../../components';
import './CirculoInfluencia.css';
import ReactDOM from 'react-dom';
import ReactDOMServer from "react-dom/server";
import { InfoWindowRutas } from '../';
import {RUTASENAREA} from '../../querys/';
import IMJMarker from '../../../../utils/IMJMarker';

async function RutasQuery(props) {
    if (props.latitud > 0) {
        const { data } = await cliente.query({
            query: RUTASENAREA,
            variables: {
                distancia: props.distancia || 0,
                latitud: props.latitud || 0,
                longitud: props.longitud || 0
            }
        });
        return data.rutasEnArea;
    } else {
        return []
    }
}

interface CirculoInfluenciaInterface {
    identificador: number,
    etiqueta: string;
    circulo: google.maps.Circle;
    sitios: Array<any>;
    rutas: Array<any>;
    showRutas: Boolean;
    showSitios: Array<any>;
    constructor: Function;
    addSitios: Function;
    removeSitios: Function;
    removeCircle: Function;
    addRutas: Function;
    removeRutas: Function;
}
export const CrearCirculoConlatLng = (latitudC, longitudc, radioC, map) => {
    const InformacionDeCirculo = {
        center: { lat: parseFloat(latitudC), lng: parseFloat(longitudc) },
        diametro: parseFloat(radioC)
    };
    
    var cityCircle = new google.maps.Circle({
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35,
        draggable: true,
        editable: true,
        center: InformacionDeCirculo.center,
        radius: parseFloat(radioC)
    });
    cityCircle.setMap(map);
    let circuloLatLng = new CirculoInfluencia(cityCircle, map);
    circuloLatLng.circulo.tag = randomInteger(1000);
    return circuloLatLng;

} 



export class ArrayCirculos {
    circulos: Array<CirculoInfluencia> = [];
    // constructor() {

    // }
    agregarCirculo = (circulo: CirculoInfluencia) => {
        this.circulos.push(circulo);
    }
    findCircle = (circle): CirculoInfluencia => {
        return this.circulos.find((elemento: CirculoInfluencia) => elemento.circulo.tag === circle.tag);
    }
}

export class CirculoInfluencia implements CirculoInfluenciaInterface {
    identificador:number;
    callback: Function;
    callbackObjectCirculotemp:Function;
    callbackInfoWindow: Function;
    callbackDelete: Function;
    etiqueta: string;
    circulo = null;
    sitios = [];
    rutas = [];
    polyLines = [];
    markers = [];
    showRutas = false;
    showSitios = [];
    mapa: any = null;
    showModal = false;
    iluminacion = 'Todos';
    nicho = ['A','B','C','D','E','N/A'];
    vistas = ['Natural', 'Cruzada'];
    rprecios = { min: 3900, max: 100000 }
    loading = false;
    observer: any = null;

    constructor(circulo: google.maps.Circle, mapa: any) {
        this.showRutas = true;
        this.showSitios = [{ id: "10", nombre: "Espectaculares", __typename: "Tipos" }];
        this.circulo = circulo;
        this.mapa = mapa;
        this.identificador = randomInteger(100000);
        this.circulo.tag = randomInteger(1000);
    }

    addSitios = (sitios: Array<any>) => {
        this.sitios = sitios.concat();
        this.pintaSitios();
    }

    setCheckedRutas = (valor) => {
        this.showRutas = valor;
        if (!valor) {
            this.removeRutas();
        }
    }
    
    /**
     * Funcion que remueve los elementos marcados cuando se dibuja un circulo en el mapa
     */
    removeSitios = () => {
        this.markers.map((marker) => marker.setMap(null));
        this.sitios = [];
    }

    pintaSitios = () => {
        this.sitios.forEach((sitio) => {
            // console.log('CirculoINfluencia = sitio', sitio);
            let m = new google.maps.Marker({
                position: { lat: sitio.latitud, lng: sitio.longitud },
                draggable: false,
                animation: google.maps.Animation.DROP,
                map: this.circulo.map,
                title: sitio.clave,
                icon: {
                    url: IMJMarker(sitio.tipos.nombre, sitio.disponible),
                    size: new google.maps.Size(37, 37),
                    scaledSize: new google.maps.Size(37, 37)
                }
            });
            this.markers.push(m);
            m.addListener("click", () => {
                if (this.callbackInfoWindow) {
                    this.callbackInfoWindow(m);
                }
            });
        })
    }

    removeCircle = () => {
        // console.log('Ando por CirculoInfluencia.tsx removeCircle =>');
        this.removeSitios();
        this.removeRutas();
        this.circulo.setMap(null);
        if (this.callbackDelete){
            this.callbackDelete(this);
            
        };

    }

    addRutas = (rutas: Array<any>) => {
        if (rutas) {
            this.rutas = rutas.concat();
            this.pintaRuta();
        }
    }



    removeRutas = () => {
        this.limpiaPolys();
        this.rutas = [];
        
    }

    limpiaPolys = () => {
        this.polyLines.forEach((polyline) => {
            polyline.setMap(null);
            
        });
        this.polyLines = [];
    }

    pintaRuta = () => {
        let tempPolys = [];
        this.rutas.forEach((ruta) => {
            let color = '#' + Math.random().toString(16).substr(-6);
            let poly = new google.maps.Polyline({
                path: ruta.puntos,
                strokeColor: color,
                strokeOpacity: 1.0,
                strokeWeight: 3
            });
            poly.setMap(this.circulo.map);
            this.createInfoWindow(poly, ruta)

            tempPolys.push(poly);
        })
        this.polyLines = tempPolys.concat();
    }

    createInfoWindow(poly, ruta) {
        let InformacionRuta = ReactDOMServer.renderToString(<div id='infocontainer' />);
        
        const infowindow = new google.maps.InfoWindow();
        google.maps.event.addListener(poly, 'click', function (event) {
            
            infowindow.close();

            infowindow.setContent(InformacionRuta);

            infowindow.setPosition(this.map.getCenter());

            infowindow.open(this.map);

            const divElement = document.getElementById('infocontainer');
            if (divElement) {
                ReactDOM.render(<ApolloProvider client={cliente}>
                    <InfoWindowRutas ruta={ruta} />
                </ApolloProvider>, document.getElementById('infocontainer'))
            } else {
                this.observer = new MutationObserver(() => {
                    const divElement = document.getElementById('infocontainer');
                    if (divElement) {
                        if (this.observer) {
                            this.observer.disconnect();
                            this.observer = null;
                        }
                        ReactDOM.render(<ApolloProvider client={cliente}>
                            <InfoWindowRutas ruta={ruta} />
                        </ApolloProvider>, divElement)
                    }
                });
                this.observer.observe(document, { subtree: true, childList: true });
            }


        });
        google.maps.event.addListener(poly, 'dblclick', function (event) {
            infowindow.close();
        });
    }

    rebuildCircle(map) {
        this.circulo.setMap(map);
        this.buscarRutasYMedios()
    }

    convertNichos = () => {
        let stringTemp = '';
        this.nicho.forEach((nicho, index) => {
            stringTemp += "'" + nicho + "'";
            if (index < this.nicho.length - 1) {
                stringTemp += ';'
            }
        })
        return stringTemp;
    }

    buscarMedios = async () => {
        let circuloTemp = this;

        //Remover Sitios
        circuloTemp.removeSitios();

        //Si hay sitios por buscar ejecutar la búsqueda
        //falta iluminacion, vistas y nicho
        if (circuloTemp.showSitios.length > 0) {
            let mediosBusqueda = await MediosQuery({
                latitud: circuloTemp.circulo.center.lat(),
                longitud: circuloTemp.circulo.center.lng(),
                distancia: Math.round(circuloTemp.circulo.radius),
                nombres: circuloTemp.showSitios.map((medio) => medio.nombre).toString(),
                iluminacion: circuloTemp.iluminacion,
                vistas: circuloTemp.vistas.toString(),
                nicho: circuloTemp.convertNichos(),
                p_pmin: circuloTemp.rprecios.min,
                p_pmax: circuloTemp.rprecios.max

            });
            if (mediosBusqueda !== null) {
                circuloTemp.addSitios(mediosBusqueda);
            }
        }

    }

    buscarRutas = async () => {
        let circuloTemp = this;
        circuloTemp.loading = true;
        //Remover Sitios y rutas
        circuloTemp.removeRutas();
        if (circuloTemp.showRutas) {

            let rutasBusqueda = await RutasQuery({
                latitud: circuloTemp.circulo.center.lat(),
                longitud: circuloTemp.circulo.center.lng(),
                distancia: Math.round(circuloTemp.circulo.radius)
            });
            circuloTemp.addRutas(rutasBusqueda);
            
        }
        circuloTemp.loading = false;
    }

    //Configuración del callback para mostrar el spinner cuando está leyendo
    onLoading = (callback: Function) => {
        this.callback = callback;
    }

    buscarRutasYMedios = async () => {
        this.loading = true;
        this.callback(true);
        await this.buscarMedios();
        if (this.showRutas) {
            await this.buscarRutas();
        }
        this.loading = false;
        this.callback(false);
    }

    spinner = () => {
        return (<Spinner loading={this.loading} />)
    }
}
