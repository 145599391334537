import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonText} from '@ionic/react';
import NumberFormat from 'react-number-format';
import { gql, useQuery } from "@apollo/client";
import './InfoWindowRutas.style.css';

const IMAGENEXHIB = gql`
    query fotos($id: ID!){
        ruta(id:$id){
            ubicacion{
                id
                imagenexhib
            }
        }
    }
`
/**
 * 
 * @param {Object} props
 * @param {any} props.ruta 
 * @returns 
 */
const InfoWindowRutas = ({
    ruta,
}) => {
    const {data, loading}=useQuery(IMAGENEXHIB, {
        variables:{
            id: ruta? ruta.id:0
        }
    })

    const CardComponent = () => (
        <div className={'column'} style={{marginTop: '0%'}}> 
            <IonCard color="resaltar">
                <IonCardHeader>
                    <div className={'info-window-card-header'}>
                        <div className={'row'}>
                            <div className={'column card-title'}>
                                <IonCardTitle>{ruta?ruta.clave:''}</IonCardTitle>
                            </div>
                            <div className={'column'} style={{ textAlign: 'end' }}>
                                <img alt={'img-info-window-ruta'} src='/assets/stickers/bus.png' height={100} />
                            </div>
                        </div>
                    </div>
                    <IonCardSubtitle style={{fontSize:'20px'}}>{ruta ? ruta.nombre : ''}</IonCardSubtitle>
                    <IonCardSubtitle style={{fontSize:'18px'}}>Tipo de exhibición: {ruta && ruta.ubicacion.tipoexhib}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                    <div className={'row'}>
                        <div className="column ion-float-left" size='10'>
                            <IonText color="secondary">
                                <h4>Inicia en {ruta && ruta.inicio}</h4>
                                <h4>Termina en {ruta && ruta.fin}</h4>
                            </IonText>
                        </div>
                    </div>
                </IonCardContent>
            </IonCard>
    </div>
    );

    const InfoWindowRutasComponent = () => {
        return (
            <div
                id={ruta.id}
                className={'container-info-window-rutas'} >
                {ruta && (
                    <div>
                        <div className={'row header-center'}>
                            <div className={'column'}> 
                                {data && data.ruta && data.ruta.ubicacion.imagenexhib && <img src={data.ruta.ubicacion.imagenexhib?data.ruta.ubicacion.imagenexhib:'/assets/IMJ.png'} alt='Imagen del Sitio' width="310" /> }
                                {loading && <img src='/assets/IMJ.png' alt='Imagen del Sitio' />}
                            </div>
                            {/* <IonCol size='2'>
                            </IonCol> */}

                            <CardComponent />
                        </div>

                        <div className={'row'}> 
                            <div lines="none">
                                <IonText color="secondary">
                                    <p style={{textAlign:"justify"}}>{ruta && ruta.descripcion}</p>
                                </IonText>
                            </div>
                        </div>

                        <div className={'row-info-window-ruta info-window-data-ruta'} style={{innerWidth:"800%"}}>
                            <div className={'center-images-div'}>
                                <div className={'item-imagen-info-window-ruta'}>
                                    <img src='/assets/infinia/man.png' alt="hombres" height={45} />
                                </div>
                                <b>&nbsp;&nbsp;<NumberFormat value={ruta.male_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b>
                            </div>
                            <div className={'center-images-div'}>
                                <div className={'item-imagen-info-window-ruta'}>
                                    <img src='/assets/infinia/woman.png' alt="mujeres" height={45} />
                                </div>
                                <b>&nbsp;&nbsp;<NumberFormat value={ruta.female_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b>
                            </div>
                            <div className={'column-info-window-ruta'}>
                                <div className={'item-info-window-ruta'}>Kilometraje: <b><NumberFormat value={ruta.kilometraje} displayType={'text'} thousandSeparator={true} decimalScale={2}></NumberFormat></b></div>
                            </div>
                            <div className={'column-info-window-ruta'}>
                                <div className={'item-info-window-ruta'}>Alcance: <b><NumberFormat value={ruta.reach * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>
                            </div>
                            <div className={'column-info-window-ruta'}>
                                <div className={'item-info-window-ruta'}>Frecuencia: <b><NumberFormat value={ruta.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div>
                            </div>
                            <div className={'column-info-window-ruta'}>
                                <div className={'item-info-window-ruta'}>Impactos de ruta: <b><NumberFormat value={ruta.impactos_ruta} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div>   
                            </div>
                            <div className={'column-info-window-ruta'}>
                                <div className={'item-info-window-ruta'}>Impactos totales: <b><NumberFormat value={ruta.total_hits} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div>
                            </div>
                        </div>

                    </div>
                )}

            </div>
        );
    }

    return(
        <div id={ruta.id} className={'container-info-window-rutas'} >
            <InfoWindowRutasComponent />
        </div>
    );
}; 
export default InfoWindowRutas;
