import React, {useState} from "react";
import { IonPage, IonHeader, IonContent } from "@ionic/react";
import { DTableV2, MToolBar, 
        Toolbar 
} from "../../components";
import {MEDIOS_QUERY, UPDATE_ESTADO} from "./query";
import useAuthentication from "../../hooks/useAuthentication";
import useDialog from "../../hooks/useDialog";
import Formplazasindoor from './Formplazasindoor';
import useNotification from "../../hooks/useNotification";
import { renderBoolean } from "../../utils/dataTableAux";
import { useMutation } from "@apollo/client";
import {DataTable} from  "primereact/datatable";
import { Column } from "primereact/column";

const Plazasindoor=(props)=>{
    const [titleHeader, settitleHeader] = useState("Titulo");
	const [refresh, setRefresh] = useState(false);
	const { usuarioid, rol } = useAuthentication();
    const { showDialog, FormDialog } = useDialog();
	// const [datos, setdatos] = useState({});
	const [expandedRows, setExpandedRows] = useState();
    

    const cambiarEstatus = (e) =>{
		if (e.target.name==='activo'){
			CambiarEstatusActivo(parseInt(e.target.id),e.target.value)
		}
	}


    const CambiarEstatusActivo =(id,activo) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						    activo:activo
						}
					}
				}
			})
		}
	}
    
    const [updateMedio] = useMutation(UPDATE_ESTADO, {
		onCompleted(data) {
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});
    
    const columnas=[
        {
            header: "Clave",
            field: "clave",
            sortable: true,
            filter: true, 
            filterMatchMode: "contains"
        },
        {
            header: "Tipo",
            field:"tipos.nombre",
            sortable: true,
            filter:true,
            filterMatchMode: "contains"
        },
        {
            header: "Latitud",
            field: "latitud"
        },
        {
            header: "Longitud",
            field: "longitud"
        },
        {
            header:"Vialidad",
            field: "vialidad"
        },
        {
            header: "Colonia",
            field: "colonia"
        },
        {
            header: "Direccion",
            field: "direccion",
            sortable: true, 
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Ubicación",
            field: "ubicacion.nombre",
            filter: true,
            filterMatchMode:"contains"
        },
        {
            header: "Referencias",
            field: "referencias"
        },
        {
            header: "Sitios",
            field: "indoors.length"
        },
        {
            header: "Activo",
            field: "activo",
            sortable: true,
            body:renderBoolean,
            customChange: cambiarEstatus
        },
        {
            header: "Comentarios",
            field: "comentarios"
        }
        
    ]
    
    const {
		notification,
		showSuccess,
		showInfo,
		showError
	} = useNotification();

    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada', 'Se abortó correctamente la operación')
	}

    const addMedio=()=>{
        let data={Createdby: usuarioid()}
        showDialog(data, "crear");
        settitleHeader("Crear nuevo Indoor")
    }

    const onEdit=(data)=>{
        
        showDialog(data, "editar");
        console.log(data)
        settitleHeader("Editar Indoor")
    }

    const rowExpansionTemplate=(data)=>{
        const datosIndoor=[]
        for(let index = 0; index<data.indoors.length; index++)
        {
            const element=data.indoors[index];
            if(element.indoors!==null){
                datosIndoor.push(element)
            }
        }

        return(
            <div className="p-grid p-fluid" style={{padding: '.5em .5em .5em .5em'}}>
                {data.indoors.length <= 0 && <p>Sin datos para mostrar</p>}
                {datosIndoor.length>0 &&
                    <DataTable value={datosIndoor}>
                        <Column field="clave" header="Clave" />
                        <Column field="proveedor.nombre_comercial" header="Proveedor"/>
                        <Column field="descripcion" header="Descripción" />
                        <Column field="colindancia" header="Colindancia" />
                        <Column field="precioventa" header="Precio"/>
                        <Column field="costo" header="Costo"/>
                        <Column field="base" header="Base" />
                        <Column field="altura" header="Altura" />
                        <Column field="created_at" header="Fecha de Creación"/>
                    </DataTable>
                }
            </div>
        )
    }

    return(
        <IonPage>
            <IonHeader>
                {notification}
				<Toolbar
					title={props.title}
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>
            <IonContent style={{overflow:'scroll'}}>
                <MToolBar
                    addClick={addMedio}
                />
                <DTableV2
                    filtro={{
                        _or:[
                            {"tipos.nombre":"Centro Comercial"},
                            {"tipos.nombre":"Aeropuertos"},
                            {"tipos.nombre":"Trenes Suburbanos"}
                        ] 
                    }}
                    autoLayout={true}
                    paginable={true}
                    query={MEDIOS_QUERY}
                    columnas={columnas}
                    element="medios"
                    refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
                    canView={false} //pendiente el form para ver
                    canEdit={true}
                    onEdit={onEdit}
                    canDelete={false}
                    rowExpansionTemplate={rowExpansionTemplate}
                    expandedRows={expandedRows}
                    onRowToggle={(e)=>{
                        console.log('Valor de e', e)
                        setExpandedRows(e.data)
                    }}
                />
                <FormDialog
                    header={titleHeader}
                    onSuccess={onSuccess}
                    onDiscard={onDiscard}
                >
                    <Formplazasindoor/>
                </FormDialog>
            </IonContent>
        </IonPage>
    )
}

export default Plazasindoor