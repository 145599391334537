/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { IonRow, IonCol, IonGrid } from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";

import { useMutation } from "@apollo/client";
import {LISTA_MARCAS_QUERY,CREATE_MARCA_MUTATION,UPDATE_MARCA_MUTATION} from "./queries";
import {Imgfile64, InputTextComponent}  from '../../components';

export default function Formabcmarcas(props){
    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
            nombre: props.datos.nombre,
            logo: props.datos.logo
        
		}
    });
    const [ImgUrl, setImgUrl] = useState("");
    const [img, setimg] = useState();
	const [fields,setValueFields] = useState(props.datos);
    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
        delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		return fieldtemp;
	};

    const [updateMarca] = useMutation(UPDATE_MARCA_MUTATION, {
		variables: {
			input: {
				where: { id: fields.id },
				data: getFieldsUpdate()
				
			}
		},
		onCompleted(data){
            props.onSuccess();
		}
	});


    const [createMarca] = useMutation(CREATE_MARCA_MUTATION, {
		variables: {
			input: {
				data: getFieldsUpdate()
			}
		},
		refetchQueries: [{ query: LISTA_MARCAS_QUERY }],
		onCompleted(data) {
			props.onSuccess();
		}
	});
    
	const onSubmit = data => { 
		
        if (props.operacion==='editar'){
           updateMarca();
			
		}else if (props.operacion === 'crear'){
			createMarca();
        }
    }

    useEffect(() => {
		register({ name: "nombre" });
	}, [register]);
    
    // const handleChange = (e) =>{
	// 	setValueFields({...fields,[e.target.name]:e.target.value})
    // }

	const handleChange = (e, name = '') =>{
		setValueFields({...fields,[name]:e})
    }
    

	const comversionImgen = async (event) => {
		const file = event.target.files[0];
		const imagen = await Imgfile64(file);
		establecerUrlVista(imagen);
		fields.logo=imagen;
	}

	const establecerUrlVista= (imagen) =>{
		setImgUrl(imagen);
	}
	
	
	const mostrarImgen =() =>{
		if(ImgUrl !== ""){
			return (
				<img src={ImgUrl} style={{display:"block",
				margin:"auto"}} width="100%"  height="200"></img>
			)
		}else if(fields.logo !== null && fields.logo !== "" && fields.logo !==  undefined){
			
			return (
				<img src={fields.logo} style={{display:"block",
				margin:"auto"}} width="100%"  height="200"></img>
			)
		}else{
			return (
				<h5 style={{textAlign:'center'}}>Sin imagen</h5>
			)
		}
	}

	
	const botones = ()=>{
		if(props.operacion === 'editar' || props.operacion === 'crear'){
			return (
				<div >
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>
			)
		}else{
			return (<div></div>)
		}
	}
	
	
	return (
		<form onSubmit={handleSubmit(onSubmit)} >
			<IonGrid >
				<IonRow >
					<IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
						<br></br>
						<span className="p-float-label ">
							<InputTextComponent
								name="nombre"
								value={(fields.nombre === null ? "" : fields.nombre)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'nombre');
									setValue("nombre", e);
								}}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								required={true}
								accept={null}
								type={'text'}
								onBlur={null}
							/>
							<label htmlFor="in">Nombre</label>
						</span>
					</IonCol>
				</IonRow>
                <IonRow>
                    
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
						<br></br>
						<label htmlFor="in">Logo</label>
						<InputTextComponent
							name="Vista_corta"
							value={img}
							style={{ width: "100%" }}
							type="file"
							accept="image/x-png,image/gif,image/jpeg"
							disabled={props.operacion === "detalles" ? true:false}
							onChange={event => comversionImgen(event)}
							className={null}
							label={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
							onBlur={null}
						/>
						{mostrarImgen()}
					</IonCol>
                </IonRow>
				<br/><br/>
					<div>{botones()}</div>
				<IonRow>
					
				</IonRow>
			</IonGrid>
		</form>
	);

}