/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonCard, IonCardHeader, IonCardTitle } from '@ionic/react';
import React, { useState } from 'react';
import { DetalleRutas } from './DetalleRutas';
import { DetalleSitios } from './DetalleSitios';
import styles from './dialogPauta.module.css';

export const DetalleCirculo = (props) => {
    const [circulos,setCirculos] = useState(props.circulos);
    const CabeceraCirculos = (props) =>{
        return (
            <IonCardHeader style={{textAlign:"center",display:"block"}}>
                <IonCardTitle className={styles.headerTags}><h1>{props.etiqueta}</h1></IonCardTitle>
            </IonCardHeader>
        )
    }

    return (
        <IonCard>
            {circulos && circulos.map((circulo)=>
                <div key={Math.random()}>
                    <CabeceraCirculos etiqueta={circulo.etiqueta||'Zona sin etiqueta'}/>
                    <DetalleSitios sitios={circulo.sitios} />
                    <DetalleRutas rutas={circulo.urbanos} />
                </div>
            )}
        </IonCard>
    )
}
