import React, {useState} from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader
} from "@ionic/react";
import {Toolbar} from "../../components";
import { IonSpinner } from '@ionic/react';
import { Dropdown } from 'primereact/dropdown'
import {
    LISTA_PAUTAS,
    LISTA_PRODUCTOS
} from "./queries"
import { useLazyQuery, useQuery } from "@apollo/client";
import Tabulador from "./Tabulador";

const Testigos=(props)=>{
    const [elementoSelCampania, setElementoSelCampania]=useState(null);
    const [campania, setCampania] = useState("");
    const [idCampania, setIdCampania]=useState(0);
    const [detalles, setDetalles]=useState([]);

    const {loading:loadingCampanias, error:errorPautas, data:dataPautas}=useQuery(LISTA_PAUTAS, {
        variables:{
        sort:"id:desc"
        },
        onCompleted:(dataPautas)=>{
            console.log(dataPautas.pautas)
        }, onError(errorPautas){
        console.log("Error: ",errorPautas)
        }
    });

    const getDetails=(idPauta)=>{
        buscarDetalles({
            variables:{
                where:{
                    pauta:idPauta
                }
            }
        })
    }

    const [buscarDetalles]=useLazyQuery(LISTA_PRODUCTOS,{
        onCompleted(data){
            console.log("Detalles encontrados",data)
            setDetalles(data.detailpautas)
        }, onError(error){
            console.log('error',error)
        }
    })

    const onSelectCampania=(campaniaSel)=>{
        if(campaniaSel.value){
        setElementoSelCampania(campaniaSel.value)
        setIdCampania(parseInt(campaniaSel.value.id))
        setCampania(campaniaSel.value)
        console.log('campaniaser',campaniaSel.value)
        getDetails(campaniaSel.value.id)
        }
        else{
        setElementoSelCampania(null)
        setIdCampania(0)
        setCampania(null)
        setDetalles([])
        return <IonSpinner name="dots" />
        }
    }

    const listaCampania=()=>{
        if(errorPautas) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingCampanias) return  <IonSpinner name="dots" />;
        if(dataPautas)
        return(
            <Dropdown
            id='lista'
            tabIndex={1}
            showClear={true}
            style={{ width: '50%', minWidth: '100px', borderRadius: "25px" }}
            value={elementoSelCampania}
            options={dataPautas.pautas}
            onChange={onSelectCampania}
            dataKey='id'
            filter
            filterBy="nombre_campania"
            optionLabel='nombre_campania'
            placeholder="Selecciona una campaña"
        />
        )
    }

    return(
        <IonPage>
            <IonHeader>
                <Toolbar
                    title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
                </Toolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonCardHeader>
                        <h4>Selecciona tu campaña</h4>
                    </IonCardHeader>
                    <IonCardContent>
                        {listaCampania()}
                    </IonCardContent> 
                </IonCard>
                <Tabulador pauta={campania} detalles={detalles}/>
            </IonContent>
        </IonPage>
    )
}
export default Testigos