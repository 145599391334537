import React from 'react'

const Logotipo = (props) => {
    return (
        <div style={{backgroundImage: 'url("/assets/IMJ.png")', 
            left:props.left, 
            top:props.top, 
            width:props.width, 
            height:props.height, 
            position:'absolute',
            backgroundSize: 'cover'}}>
            
        </div>
    )
}
export default Logotipo;
