import React, { useState } from "react";
import {IonRow, IonCol,IonText, IonGrid, IonPopover, IonLabel, IonHeader, IonCardContent, IonAlert, IonSpinner, IonFooter} from "@ionic/react";
import { Button } from "primereact/button";
import {URBANOS_INCIDENCIAS_QUERY, LISTAR_ECOS, LISTAR_PERMISIONARIOS, CAMPANIAS_URBANOS_QUERY } from "./query";
import moment from 'moment';
import { useQuery, useLazyQuery} from "@apollo/client";
import {Dropdown} from 'primereact/dropdown'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import {formatMoney} from '../../utils/Utils';
import { saveAs } from 'file-saver';
import { AddSheet, AltoFila, CombinarCeldas, EXCEL_EXTENSION, FormatoFila, FormatoMultiplesCeldas, HojaActiva, ValorCelda, ValoresFila, workBook } from '../../components/Pauta/export/funcionesExport';
import { exportPDF } from "../../components/Pauta/export/PdfExport";

const Excel = require('exceljs');
const imjColors = ['#126efa', '#fc3d38', '#1cb5b2','#d11787','#fcb32b','#874afa','#bfeb08','#1cbf59'];

const CorteMensual=(props)=>{
    //States que almacenan los resultados de las query
    const [permisionarios, setPermisionarios]=useState([])
    const [campanias, setCampanias]=useState([])
    const [incidencias, setIncidencias]=useState([])
    const [ecos, setEcos]=useState([])
    //Queries que obtienen información de base de datos
    const {data:dataCampanias, loading:loadingCampanias}=useQuery(CAMPANIAS_URBANOS_QUERY ,{
        variables:{
            sort:"id:ASC"
        },
        onCompleted:(data)=>{
            setCampanias(dataCampanias.urbanoscampanias)
            
        }
    });
    const {data:dataIncidencias, loading:loadingIncidencias}=useQuery(URBANOS_INCIDENCIAS_QUERY, {
        variables:{
        sort:"id:ASC"
        },
        onCompleted:(data)=>{
        setIncidencias(dataIncidencias.urbanosIncidencias)
        }
    });
    const {data:dataUnidades, loading:loadingUnidades}=useQuery(LISTAR_ECOS, {
        variables:{
            sort:"ruta.id:ASC",
            where:{
                "activo":true,
                "ruta.ubicacion":1
            }
        },
        onCompleted:(data)=>{
            setEcos(dataUnidades.unidadesUrbanos)
        }
    });
    const {data:dataPermisionarios, loading:loadingPermisionarios}=useQuery(LISTAR_PERMISIONARIOS,{
        variables:{
            sort:"numPermisionario:ASC"
        },
        onCompleted:(data)=>{
            setPermisionarios(dataPermisionarios.permisionarios)
        }
    });


    const header=[
        {id: 1, title:'Unidades Vendidas'},
        {id: 2, title: 'No Vendidas'},
        {id: 3, title: 'Unidades A Pagar'},
        {id: 4, title: 'Merma'},
        {id: 5, title: 'Incidencias'}
    ]
    const cabecera=header.map((element)=>{
        return(
            <IonCol key={element.id}>
                <IonHeader><h4>{element.title}</h4></IonHeader>
            </IonCol>
        )
    })
    const headerP=[
        {id: 1, title:'Número de Permisionario'},
        {id: 2, title: 'Permisionario'},
        {id: 3, title: 'Tipo de Contrato'},
        {id: 4, title: 'Pago Unitario'},
        {id: 5, title: 'Unidades a Pagar'},
        {id: 6, title: 'Pago Total'}
    ]
    const cabeceraP=headerP.map((element)=>{
        return(
            <IonCol key={element.id}>
                <IonHeader><h4>{element.title}</h4></IonHeader>
            </IonCol>
        )
    })
    if(props.anio && props.mes)
    {
        const fechaInicio=props.anio+'-'+props.mes.id+'-01'
        const fechaFin=moment(fechaInicio).endOf('month').format('YYYY-MM-DD')
        //Aquí se hace el corte
        let vendidas=[]
        let novendidas=[]
        let porpagar=[]
        let incidenciasmensuales=[]
        let arregloFinal=[]
        let arrayPerm=[]
        let resta=0
        let merma=0
        if(loadingCampanias || loadingIncidencias || loadingUnidades)
        {
            return(
                <IonSpinner name="dots"/>
            )
        }
        campanias.forEach(element=>{
            if((element.inicia>=fechaInicio && element.termina<=fechaFin) || 
            (element.inicia>=fechaInicio && element.inicia<=fechaFin && element.termina>=fechaFin) ||
            (element.inicia<fechaInicio && element.termina>fechaFin)
            )
            {
                vendidas.push(element.unidades_urbano)
            }
        });
        //no vendidas
        let array=[]
        for (var n = 0; n < dataUnidades.unidadesUrbanos.length; n++) 
        {
            var same=false;
            for (var m = 0; m < vendidas.length && !same; m++) 
            {
                if(dataUnidades.unidadesUrbanos[n]['id'] === vendidas[m]['id'] && 
                dataUnidades.unidadesUrbanos[n]['numeco'] === vendidas[m]['numeco']) 
                same=true;
            }
        if(!same)array.push(dataUnidades.unidadesUrbanos[n]);
        }

        array.forEach(element=>{
            if(element.created_at.split('T')[0]<fechaFin && element.activo===true)
            {
                novendidas.push(element)
                merma+=parseFloat(element.permisionario.costo)
            }
        })
        //Por pagar
        dataUnidades.unidadesUrbanos.forEach(element=>{
            if(element.created_at.split('T')[0]<fechaFin && element.activo===true)
            {
                porpagar.push(element)
            }
        })
        //Incidencias
        dataIncidencias.urbanosIncidencias.forEach(element=>{
            if((element.inicia>=fechaInicio && element.termina<=fechaFin) || (element.inicia>=fechaInicio && element.inicia<=fechaFin && element.termina>=fechaFin) || (element.inicia<fechaInicio && element.termina>=fechaInicio && element.termina<=fechaFin) || (element.inicia<fechaInicio && element.termina>fechaFin))
            {
                incidenciasmensuales.push(element)
                resta+=element.unidades_urbano.permisionario.costo
            }
        })
        console.log(novendidas)
        var x={
            id:1,
            vendidas:vendidas.length,
            porpagar:porpagar.length-incidenciasmensuales.length,
            incidenciasmensuales:incidenciasmensuales.length,
            novendidas:novendidas.length,
            mermaM:merma-resta
        }
        arregloFinal.push(x)
        const mapeoHeaders=arregloFinal.map((element)=>{
            return(
                <div key={element.id}>
                    <IonRow>
                        <IonCol>
                            <h4>{element.vendidas}</h4>
                        </IonCol>
                        <IonCol>
                            <h4>{element.novendidas}</h4>
                        </IonCol>
                        <IonCol>
                            <h4>{element.porpagar}</h4>
                        </IonCol>
                        <IonCol>
                            <h4>{'$'+formatMoney(element.mermaM)}</h4>
                        </IonCol>
                        <IonCol>
                            <h4>{element.incidenciasmensuales}</h4>
                        </IonCol>
                    </IonRow>
                </div>
            )
        })

        //Cálculo de pago a permisionarios por mes
        permisionarios.forEach(element=>{
            let unidadesAux=[]
            let incidenciasAux=[]
            ecos.forEach(ele=>{
                if(ele.created_at.split('T')[0]<fechaFin  && ele.permisionario.permisionario===element.permisionario && ele.activo===true)
                {
                    unidadesAux.push(ele)
                }
            })
            incidencias.forEach(e=>{
                if((e.inicia>=fechaInicio && e.termina<=fechaFin) || 
                    (e.inicia>=fechaInicio && e.inicia<=fechaFin && e.termina>=fechaFin) || 
                    (e.inicia<fechaInicio && e.termina>=fechaInicio && e.termina<=fechaFin) || 
                    (e.inicia<fechaInicio && e.termina>fechaFin)
                )
                    {
                        if(element.permisionario===e.unidades_urbano.permisionario.permisionario)
                        {
                            incidenciasAux.push(e)
                        }
                    }
            })
            let aPagarxPerm=unidadesAux.length
            if(aPagarxPerm<0){
                aPagarxPerm=0
            }
            const pago=aPagarxPerm*element.costo
            let w={
                numPermisionario:element.numPermisionario,
                nombre:element.permisionario,
                esquema:element.esquemaPago,
                pagoUnitario:element.costo,
                unidades: aPagarxPerm,
                pagoTotal: pago
            }
            arrayPerm.push(w)
        })

        const mapeoPerm=arrayPerm.map((element)=>{
            return(
                <div key={element.numPermisionario}>
                    <IonRow>
                        <IonCol>
                            {element.numPermisionario}
                        </IonCol>
                        <IonCol>
                            {element.nombre}
                        </IonCol>
                        <IonCol>
                            {element.esquema}
                        </IonCol>
                        <IonCol>
                            {'$'+formatMoney(element.pagoUnitario)}
                        </IonCol>
                        <IonCol>
                            {element.unidades}
                        </IonCol>
                        <IonCol>
                            {'$'+formatMoney(element.pagoTotal)}
                        </IonCol>
                    </IonRow>
                </div>
            )
        })


const exportXLS =async(arregloFinal, arrayPerm)=>{
        
        let wb=workBook();
        AddSheet('Resumen de corte mensual')
        let ws=HojaActiva();
        CombinarCeldas('A1:F1');
        FormatoMultiplesCeldas(['A1'],
            'Rojo',
            {top: {style:'none'}, left: {style:'none'}, bottom: {style:'thin'}, right: {style:'thin'}},
            {vertical: 'middle', horizontal: 'center', wrapText: true},
            {bold: true, name:'Dia Regular'}
        )
        AltoFila(3, 30)
        ValorCelda('A1', "Corte Urbanos ")
        FormatoFila(1, {height: 40, font:{bold: true, size:18, name:'Dia Regular'}, alignment:{vertical: 'middle', horizontal: 'center'}})
        ValoresFila(3, ['Unidades Vendidas', 'Sin Usar', 'Por Pagar', 'Merma', 'Incidencias'])
        FormatoMultiplesCeldas(['A3', 'B3', 'C3', 'D3', 'E3'],
            'Rojo',
            {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
            {vertical: 'middle', horizontal: 'center', wrapText: true},
            {size: 16, bold: true, name:'Dia Regular'})
        ws.columns=[
            {key: 'unidadesVendidas', width:30},
            {key: 'SinUsar', width: 20},
            {key: 'PorPagar', width: 20},
            {key: 'Merma', width: 20},
            {key: 'Incidencias', width: 30}
        ];
            ValorCelda(`A${4}`, arregloFinal[0].vendidas);
            ValorCelda(`B${4}`, arregloFinal[0].novendidas);
            ValorCelda(`C${4}`, arregloFinal[0].porpagar);
            ValorCelda(`D${4}`, arregloFinal[0].mermaM);
            ValorCelda(`E${4}`, arregloFinal[0].incidenciasmensuales);
            FormatoMultiplesCeldas([`A${4}`, `B${4}`, `C${4}`, `D${4}`, `E${4}`],
                'Gris',
                {top: {style:'thin'}, left: {style:'none'}, bottom: {style:'thin'}, right: {style:'thin'}},
                {vertical: 'middle', horizontal: 'center', wrapText: true},
                {bold: false, name:'Dia Regular'})
        AltoFila(10, 30)
        ValoresFila(10, ['Num Permisionario', 'Permisionario', 'Tipo Contrato', 'Pago Unitario', 'Unidades a Pagar', 'Pago Total'])
        ws.columns=[
            {key: 'numPermisionario', width:25},
            {key: 'nombre', width: 25},
            {key: 'esquema', width: 20},
            {key: 'pagoUnitario', width: 20},
            {key: 'unidades', width: 15},
            {key: 'pagoTotal', width: 20}
        ];
        FormatoMultiplesCeldas(['A10', 'B10', 'C10', 'D10', 'E10', 'F10'],
                                'Rojo',
                                {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
                                {vertical: 'middle', horizontal: 'center', wrapText:true},
                                { bold: true, size:16, name:'Dia Regular', color: {argb: 'ffffffff'}}
        )
        FormatoMultiplesCeldas(['A10', 'B10', 'C10', 'D10', 'E10', 'F10'],
            'Rojo',
            {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
            {vertical: 'middle', horizontal: 'center', wrapText:true},
            { bold: true, size:16, name:'Dia Regular', color: {argb: 'ffffffff'}}
        )
        for(let i=0; i<arrayPerm.length; i++){
        const rowValues=[]
        const rowIndex=i+10;
        rowValues[1]=(arrayPerm[i]['numPermisionario'])
        rowValues[2]=(arrayPerm[i]['nombre'])
        rowValues[3]=(arrayPerm[i]['esquema'])
        rowValues[4]=('$'+formatMoney(arrayPerm[i]['pagoUnitario']))
        rowValues[5]=(arrayPerm[i]['unidades'])
        rowValues[6]=('$'+formatMoney(arrayPerm[i]['pagoTotal']))

        ws.addRow(rowValues)
        }
        const totalNumberOfRows=ws.rowCount;
        for (var i=totalNumberOfRows; i>10; i--){
            FormatoMultiplesCeldas([`A${i}`, `B${i}`, `C${i}`, `D${i}`, `E${i}`, `F${i}`],
                'Gris',
                {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
                {vertical: 'middle', horizontal: 'center', wrapText:true},
                {size:12, name:'Dia Regular'}
            )
        }
        const buffer = await wb.xlsx.writeBuffer(); 
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const blob= new Blob([buffer], {type: fileType});
        saveAs(blob, 'Resumen_Urbanos_Corte_Export_'+ new Date().getTime()+EXCEL_EXTENSION);
    }
    
    const exportPDF=(arregloFinal, arrayPerm)=>{
        const doc = new jsPDF('landscape');
        doc.setFontSize(22);
        doc.text("Resumen de corte mensual ", 50, 15);
        autoTable(doc, {startY: 20});
        autoTable(doc, {   
            columnStyles: {0: {halign: 'center'}, 1: {halign: 'center'}, 2: { halign: 'center' }, 3:{halign: 'center'}, 4: {halign: 'center'}, 5: {halign:'center'}}, // Cells in first column centered
            margin: { top: 10 },
            //color de los headers
            didParseCell: function (table) {
                if (table.section === 'head') {
                    table.cell.styles.fillColor = '#126efa';
                }
            }, 
            columns: [
                {dataKey:'vendidas'},
                {dataKey: 'novendidas'},
                {dataKey: 'porpagar'},
                {dataKey: 'mermaM'},
                {key: 'incidenciasmensuales'}
            ],   
            head: [['Unidades Vendidas', 'Sin Usar No Vendidas', 'Unidades a Pagar', 'Merma', 'Incidentes']],
            body: 
                arregloFinal
            ,
        });
        
        var col=['Número de Permisionario', 'Permisionario', 'Tipo de Contrato', 'Pago Unitario', 'Unidades a Pagar', 'Pago Total'];
        autoTable(doc, {
            didParseCell: function (table) {
                if (table.section === 'head') {
                    table.cell.styles.fillColor = '#126efa';
                }
            },
            columns:[
                {dataKey:'numPermisionario'},
                {dataKey: 'nombre'},
                {dataKey: 'esquema'},
                {dataKey: 'pagoUnitario'},
                {dataKey: 'unidades'},
                {dataKey: 'pagoTotal'}
            ],
            head:[col],
            body:
                arrayPerm,
            columnStyles:{
                0:{halign: 'center'},
                1:{halign: 'center'},
                2:{halign: 'center'},
                3:{halign: 'center'},
                4:{halign: 'center'},
                5:{halign: 'center'},
            },
            margin:{top:10}
        })
        var pageNumber = doc.internal.getNumberOfPages()
        doc.setPage(pageNumber);
        doc.save('Resumen_Urbanos_Anual_GDL_Export_'+ new Date().getTime()+'.pdf')
    }
        return(
            <>
                <br/>
                <IonGrid>
                    <IonRow>
                        {cabecera}
                    </IonRow>
                    {mapeoHeaders}
                </IonGrid>
                <br/>
                <IonGrid>
                    <IonRow>
                        {cabeceraP}
                    </IonRow>
                        {mapeoPerm}
                    <IonFooter>
                        <IonRow>
                            <IonCol>
                                <Button
                                    label="Exportar XLS"
                                    icon="pi pi-file-excel"
                                    className='p-button-raised p-button-rounded p-button-success ion-float-left'
                                    onClick={()=>exportXLS(arregloFinal, arrayPerm)}
                                />
                            </IonCol>
                            <IonCol>
                                <Button
                                    label="Exportar PDF"
                                    icon="pi pi-file-pdf"
                                    className="p-button-raised p-button-rounded p-button-danger ion-float-right"
                                    onClick={()=>exportPDF(arregloFinal, arrayPerm)}
                                />
                            </IonCol>
                        </IonRow>
                    </IonFooter>
                </IonGrid>
            </>
        )
    }
    else{
        return(
            <>
                
            </>
        )
    }
    
}
export default CorteMensual