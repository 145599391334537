import React from 'react'

const ImagenMapa = (props) => {
    return (
        <div style={{width:props.width, 
            backgroundImage: 'url("' + props.imagen +'")',
            height:props.height, 
            left:props.left, 
            right:props.right, 
            top:props.top, 
            position:'absolute',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'black'}}>
            {/* <img alt='' src={props.imagen} style={{width: '100%'}}/> */}
        </div>
    )
}

export default ImagenMapa;
