/* eslint-disable @typescript-eslint/no-unused-vars */
import React ,{useState}from 'react'
import { IonGrid, 
         IonCol, 
         IonRow ,
         IonSpinner,
         IonCard,
         IonCardContent} from '@ionic/react'
import { InputTextareaComponent, InputTextComponent } from '../../../components';
import {UBICACIONES_QUERY,
       UPDATE_RUTA_MUTATION,
       LISTAR_PUNTOS_RUTA_QUERY,
       CREAR_PUNTO, CREATE_RUTA_MUTATION,
       CREAR_PUNTO_KML_ARRAY,
       LISTA_RUTAS_QUERY,UPDATE_PUNTO_MUTATION,DELETE_PUNTO_MUTATION} from '../queries/'; 
import { useMutation ,useQuery} from "@apollo/client";
import {MapaRutas, MapaVistaRutas} from '../../../components';
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import client from '../../../apollo/client';
import {Dropdown} from 'primereact/dropdown';
import { useNotification } from '../../../hooks';

export default function FormRutas(props){
    const { notification, showWarn } = useNotification();
    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
           ubicacion: props.datos.ubicacion,
           nombre:  props.datos.nombre,
           inicio: props.datos.inicio,
           fin: props.datos.fin,
           descripcion: props.datos.descripcion,
           clave: props.datos.clave,
           kilometraje: props.datos.kilometraje
		}
    });
    
    const [fields,setValueFields] = useState(props.datos);

    
    const [location, setLocation] = useState({
        id: 9,
        name: 'CDMX',
        lat: 19.43411066622587,
        lng: -99.1326554135682,
    });
    const [latlngArray,setLatlngArray] = useState([]);
    const [idUbicacion,setidUbicacion] =useState(props.datos.ubicacion === null || props.datos.ubicacion === undefined ? 1 : parseInt(props.datos.ubicacion.id));

    const [Bandera, setBandera] = useState(false);
    const [elementoSel,setElementoSel] = useState<string>((fields.ubicacion === null ? "" : fields.ubicacion));
    const [descripcion, setdescripcion] = useState(fields.descripcion);
    const [inicioform, setinicioform] = useState(fields.inicio)
    let id = (props.datos.id === undefined ? 0 : parseInt(props.datos.id));
    const [NewRutaId, setNewRutaId] = useState(id);
    const [NewPunto, setNewPunto] = useState();
   
    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
		delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
        delete fieldtemp.updated_at;
        if( fieldtemp.clave== null ){
          delete fieldtemp.clave;
        }
        fieldtemp.activo=(fieldtemp.activo === null ? true :  fieldtemp.activo);
        fieldtemp.ubicacion = (isNaN(idUbicacion) ? 1 : idUbicacion);
        fieldtemp.kilometraje = parseFloat(fieldtemp.kilometraje);
		return fieldtemp;
	};

    const getCenterOfRoute = (puntos: []) => {
        const halfArrayLength = Math.trunc(puntos.length / 2); // Se obtiene el punto medio de la ruta
        // console.log('halfArrayLength', halfArrayLength);
        const puntosMedios : any = puntos[halfArrayLength];
        // console.log('puntosMedios', puntosMedios);
        const objectTemp = {
            id: 0,
            name: 'TEMPORAL',
            lat: parseFloat(puntosMedios.lat + ''),
            lng: parseFloat(puntosMedios.lng + ''),
        }
        // console.log('objectTemp', objectTemp);
        setLocation(objectTemp);
    };

    /*Queries*/
    const {loading : loading_listaUbicaciones, error : error_listaUbicaciones, data:data_listaUbicaciones } = useQuery(UBICACIONES_QUERY);
  
    const {loading : loading_listaRutasPuntos, error : error_listaRutasPuntos, data:data_listaRutas_Puntos} = useQuery(LISTAR_PUNTOS_RUTA_QUERY,{
        variables:{
            id:id
        },
        onCompleted: (data) =>{
            if (data.ruta){
                setLatlngArray(data.ruta.puntos);
                if (data.ruta.puntos.length && data.ruta.puntos.length > 0) {
                    getCenterOfRoute(data.ruta.puntos);
                } else {
                    showWarn('La ruta no cuenta con puntos registrados')
                }
                // getCenterOfRoute(data.ruta.puntos);
            }else{
                setLatlngArray([]);
            }
            
        }
    });



       
    const [updateRuta] = useMutation(UPDATE_RUTA_MUTATION, {
		variables: {
			input: {
				where: { id: fields.id },
				data: getFieldsUpdate()
			}
		},
		onCompleted(data){
            props.onSuccess();
        }
    });
    


    const [createRuta] = useMutation(CREATE_RUTA_MUTATION, {
		variables: {
			input: {
				data: getFieldsUpdate()
			}
		},
		refetchQueries: [{ query:LISTA_RUTAS_QUERY }],
		onCompleted(data) {

            let id =parseInt(data.createRuta.ruta.id);
            props.onSuccess(); 
		}
    });

    
    const crearPuntoEvent = async (
        lat,
        lng,
        ruta,
        createdby,
        modifiedby,
    ) => {
        const kmlArrayToSend = [];
        // for (let i = 0; i < kmlPointsParam.length; i++) {
            // const kml = kmlPointsParam[i]; // Objeto que contiene la lat y lng de dicho punto de localizacion
            
            const kmlToSend = {
                    lat: lat,
                    lng: lng,
                    ruta: ruta,
                    createdby: createdby,
                    modifiedby: modifiedby
            };
            kmlArrayToSend.push(kmlToSend);
        // }
        console.log('crearPuntoEvent kmlArrayToSend', kmlArrayToSend);
        /**
         * Se ejecuta la mutacion de forma asincrona y 
         * haciendo 1 sola solicitud por ruta enviando
         * un arreglo de puntos.
         */
        const record = await client.mutate({
            variables: {
                input: {
                    data: kmlArrayToSend
                }
            },
            mutation: CREAR_PUNTO_KML_ARRAY,
        });
        console.log('record', record)

        console.log('onComplete data', kmlArrayToSend);
        setNewPunto(kmlArrayToSend[0]);

        let tempArray = [...latlngArray];
        tempArray.push(kmlToSend);
        console.log('kmlToSend', kmlToSend);
        setLatlngArray(tempArray);
    }
    
    const [createPunto] =  useMutation(CREAR_PUNTO,{
        onCompleted(data){
            console.log('onComplete data', data);
            setNewPunto(data);

            let tempArray = [...latlngArray];
            tempArray.push(data.createPunto.punto);
            setLatlngArray(tempArray);
		}
       
    });

    const [CrearNuevaRuta] = useMutation(CREATE_RUTA_MUTATION,{
		onCompleted:(data)=>{
            let id =parseInt(data.createRuta.ruta.id);
            setNewRutaId(id);
            fields.id=id;
            
		}
    });
    
    const [updatePunto] = useMutation(UPDATE_PUNTO_MUTATION,{
        onCompleted(data){
           if (data.updatePunto.punto){
                let tempArray = [...latlngArray];
                tempArray[tempArray.findIndex((elemento)=>elemento.id===data.updatePunto.punto.id)] = data.updatePunto.punto;
                setLatlngArray(tempArray);
            }
        }
    });

    const [deletePunto] = useMutation(DELETE_PUNTO_MUTATION,{
        onCompleted:(data) =>{
            if (data.deletePunto.punto){
                let tempArray = [...latlngArray];
                tempArray.splice(tempArray.findIndex((elemento)=>elemento.id===data.deletePunto.punto.id),1);
                setLatlngArray(tempArray);
            }
        }
    });

    const NuevaRuta =() =>{
        let nombre = (fields.nombre === undefined ? "" : fields.nombre);
        let inicio = (inicioform === undefined  ? "" : inicioform);
        let fin = (fields.fin  === undefined ? "" : inicioform);
        let descripcion  = (fields.descripcion === undefined ? "" : fields.descripcion);
        let kilometraje = (fields.kilometraje === undefined) ? 0 : parseFloat(fields.kilometraje);
        CrearNuevaRuta({
            variables:{
                input:{
                 
                    data:{
                        nombre:nombre,
                        descripcion: descripcion,
                        inicio:inicio,
                        fin:fin,  
                        ubicacion:idUbicacion,
                        activo:true,
                        kilometraje: kilometraje,
                        createdby: fields.createdby,
                        modifiedby: fields.modifiedby
                    }
                    
                }
            }

        }); 
    }
    
    const EliminarPorIdPunto = (id:Number)=>{
        deletePunto({
            variables:{id}
        });
    }

    const EditarPunto = (id,lat,lng) =>{
        updatePunto({
            variables:{
                input:{
                    where:{
                        id:parseInt(id)
                    },data:{
                        lat: lat,
                        lng: lng,
                        ruta: NewRutaId,
                        createdby: fields.createdby,
                        modifiedby: fields.modifiedby
                    }
                }
            }
        });
    
    } 
    
    
    
    const cargarlistaUbicaciones = () =>{
        if (loading_listaUbicaciones) return <IonSpinner name="dots" />
        if (data_listaUbicaciones) return (
            <Dropdown 
                tabIndex={1}
                showClear={true}
                style={{minWidth:'230px',width:"100%"}}
                value={elementoSel} 
                options={data_listaUbicaciones.ubicacions} 
                onChange={onselect} 
                dataKey='id'
                optionLabel='nombre'
                placeholder="Selecciona una ubicaciÃ²n"
                required={true}
            />
        )
    }

    const onselect =(selectid)=>{
        if(selectid.value){
            setidUbicacion(parseInt(selectid.value.id));
            setElementoSel(selectid.value);
        }else{
            setidUbicacion(null);
            setElementoSel(null);
        }
        
    }  
   
    const handleChange = (e: any, name: string) =>{
        setValueFields({...fields,[name]:e})
    }

    const onSubmit = data => { 
        if (props.operacion==='editar' || props.operacion === "crear"){
			updateRuta();
        }
       
    }
    
     
    const buscarEnMapa = () =>{
		fields.inicio = inicioform;
    }
    
    const botones = () => {
		if (props.operacion === 'editar' || props.operacion === 'crear') {
			return (
				<div>
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>			
			)
			
		}else{
			return(
				<div>
				</div>
			)
		}
		
    }
    
    // const getvalueTextareaDescripcion =(value)=>{
	// 	setdescripcion(value);
    //     console.log(descripcion)
	// 	fields.descripcion=value;
	// }
    
    const getInicio = (value) =>{
       setinicioform(value);
       fields.inicio = value;
    }

    const NuevoPunto =(lat:number,lng:number)=>{
        crearPuntoEvent(
            parseFloat(lat.toFixed(6)),
            parseFloat(lng.toFixed(6)),
            NewRutaId,
            fields.createdby,
            fields.modifiedby
        );

    }

    const orderLatLngArray = (arrayList) => {
        return arrayList.sort((x, y) => {
            return x.lat - y.lat
        });
    }

    const MostrarOcultarMapa = () =>{
        if(props.operacion === "crear" ){  
            return (
                <div style={{display:(Bandera ===  false ? "none" :"contents" )}}>
                    <MapaRutas 
                        locations={latlngArray} 
                        mapCenter={location} 
                        zoom={14} 
                        ubicacion={fields.inicio} 
                        OnLoadId={EliminarPorIdPunto}
                        OnloadPunto={NuevoPunto}
                        SetDataPunto={NewPunto}
                        EditPunto={EditarPunto}
                    >
                    </MapaRutas>

                </div>
            );

        }else if(props.operacion === "editar"){
            return(
                <div>
                    <MapaRutas 
                        locations={latlngArray} 
                        mapCenter={location} 
                        zoom={14} 
                        ubicacion={fields.inicio} 
                        OnLoadId={EliminarPorIdPunto}
                        OnloadPunto={NuevoPunto}
                        SetDataPunto={NewPunto}
                        EditPunto={EditarPunto}
                    >
                    </MapaRutas>
                </div>
            );
        }else{

            return(
                <div>
                    <MapaVistaRutas locations={latlngArray} />
                    {/* <MapaVistaRutas locations={orderLatLngArr} /> */}
                </div>
            );

        }
    }


    return(
        <form onSubmit={handleSubmit(onSubmit)}>

            <IonGrid>
                <IonCard>
                <IonCardContent>
                    {notification}
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <br></br>
						{cargarlistaUbicaciones()}
                    </IonCol>
              
                    <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                // tabIndex={2}
                                name="clave"
                                value={(fields.clave === null ? "" : fields.clave)}
                                style={{ width: "100%" }}
                                disabled={props.operacion === "detalles" ? true:false}
                                onChange={e => {
                                    handleChange(e, 'clave');
                                    setValue("clave", e);
                                }}
                                accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
                                required={null}
                            />
                            <label htmlFor="in">Clave</label>
                        </span>
						
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
							<InputTextComponent
                                // tabIndex={3}
								name="inicio"
								value={inicioform}
								style={{ width: "100%" }}
								disabled={props.operacion === "detalles" ? true:false}
								required
                                onChange={e => getInicio(e)}
								onBlur={()=>buscarEnMapa()}
                                accept={null}
								className={null}
								label={null}
								// onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">inicio</label>
						</span>
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                name="fin"
                                value={(fields.fin === null ? "" : fields.fin)}
                                style={{ width: "100%" }}
                                disabled={props.operacion === "detalles" ? true:false}
                                required
                                onChange={e => {
                                    handleChange(e, 'fin');
                                    setValue("fin", e);
                                }}
                                accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
                            />
                            <label htmlFor="in">Destino</label>
						</span>
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
							<InputTextComponent
								name="nombre"
								value={(fields.nombre === null ? "" : fields.nombre)}
								style={{ width: "100%" }}
								disabled={props.operacion === "detalles" ? true:false}
                                required
                                onChange={e => {
                                    handleChange(e, 'nombre');
									setValue("nombre", e);
                                }}
                                accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Nombre de la ruta</label>
						</span>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
                        <br></br>
						<label htmlFor="in">Descripcion</label>
						<InputTextareaComponent
                            name="descripcion"
                            tabIndex={6}
							style={{ width: "100%",border:" 1px solid gray" }}
							rows={2}
							disabled={props.operacion === "detalles" ? true:false}
							value={ fields.descripcion }  
                            onChange={e=>{
                                handleChange(e, 'descripcion');
                                setValue("descripcion", e)
                            }}
                            required={null}
						/>
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                name="kilometraje"
                                value={(fields.kilometraje === null ? 0 : fields.kilometraje)}
                                style={{ width: "100%" }}
                                disabled={props.operacion === "detalles" ? true:false}
                                required
                                onChange={e => {
                                    handleChange(e, 'kilometraje');
                                    setValue("kilometraje", e);
                                }}
                                accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
                            />
                            <label htmlFor="in">Kilometraje</label>
						</span>
                    </IonCol>
                </IonRow>
                </IonCardContent>
                </IonCard>
                <IonRow>
                    <IonCol  size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
                        <div style={{display:(props.operacion === 'crear' ?"contents": "none")}}>
                            <Button label="Empezar a registrar puntos" 
                                    tabIndex={7}
                                    onClick={() => {
                                        setBandera(true)
                                        NuevaRuta()
                                    }} 
                                    type="button"
                                    icon="pi pi-map-marker" 
                            />
                        </div>    
                        {MostrarOcultarMapa()}
                    </IonCol>
                </IonRow>
            </IonGrid>
            <br/><br/>
			<div>{botones()}</div>
        </form>
    );
}

/*
<MapaRutas 
    locations={latlngArray} 
    mapCenter={location} 
    zoom={14} 
    ubicacion={fields.inicio} 
    OnLoadId={EliminarPorIdPunto}
    OnloadPunto={NuevoPunto}
    SetDataPunto={NewPunto}
    EditPunto={EditarPunto}
>
</MapaRutas>
*/

 /*
<IonSelect
    disabled={props.operacion === "detalles" ? true:false} 
    value={(fields.ubicacion !== undefined && elementoSel =="" ? setElementoSel(String((fields.ubicacion.id === null ? "" :fields.ubicacion.id))) : elementoSel)} 
    placeholder="Seleccione una ubicacion" 
    onIonChange={e => {
        setElementoSel(e.detail.value);
    }}
    style={{backgroundColor:'white',color:'black'}}>
    {data_listaUbicaciones.ubicacions.map((elemento) => (
        <IonSelectOption 
            key={elemento.id}
            value={elemento.id} 
            className="ion-text-wrap">
                {elemento.nombre}
        </IonSelectOption>
    ))}
</IonSelect>
**/