import { IonSpinner } from '@ionic/react';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';

/**
 * 
 * @param {Object} props
 * @param {boolean} props.loading_listaVistas
 * @param {any} props.selectVistas
 * @param {any} props.dataListaVistas
 * @param {(selectid: any) => void} props.onSelectVista
 * @returns 
 */
const CargarListaVistas = ({
    loading_listaVistas,
    selectVistas,
    dataListaVistas,
    onSelectVista,
}) => {
    if (loading_listaVistas) return <IonSpinner name="dots" />;
    
    if (dataListaVistas) {
        return (
            <Dropdown
                tabIndex={14}
                showClear={true}
                style={{ minWidth: '200px', borderRadius: '25px'  }}
                value={selectVistas}
                options={dataListaVistas.vistas}
                onChange={onSelectVista}
                dataKey='id'
                optionLabel='nombre'
                placeholder="Selecciona el tipo de vista"
                required={true} />
        )
    }
};

export default CargarListaVistas;
