import React, {useEffect, useRef, useState} from "react";
import {
    IonCol,
    IonGrid,
    IonHeader,
    IonRow
} from "@ionic/react";
import useAuthentication from "../../hooks/useAuthentication";
import useNotification from "../../hooks/useNotification";
import {LISTAR_TESTIGOS, LISTAR_PRESENTACIONES, CREATE_PRESENTACIONES} from './queries';
import axios from "axios"
import { Button } from "primereact/button";
import { FileUpload } from 'primereact/fileupload';
import { Toast } from "primereact/toast";
import { useMutation, useQuery } from "@apollo/client";

const Presentacion=(props)=>{
    const {usuarioid, rol} = useAuthentication();
    const [imagenes, setImagenes]=useState([])
    const [slider, setSlider]=useState(null)
    const [campaniaId, setCampaniaId]=useState(parseInt(props.pauta.campania.id))
    const toast = useRef(null);
    
    const {
        notification,
        showSuccess,
        showInfo,
        showError
    } = useNotification();

    const subeSlider =async(id) => {
        const data=new FormData()
        data.append('files', slider)
        data.append('refId', id)
		data.append('ref', 'presentacion')
		data.append('field', 'presentacion')
        const upload_res=await axios({
            method:'POST',
            url: 'https://services.imjmedia.com.mx/upload',
            data
        })
        console.log(upload_res)
        showSuccess('Operación exitosa', 'Se realizó la operación exitosamente')
    }

    const onUpload=()=>{
        console.log('iiiii',imagenes)
        imagenes.forEach(element=>{
            console.log(element)
            setSlider(element)
            CrearPresentacion()
        })
    }

    const [CrearPresentacion]=useMutation(CREATE_PRESENTACIONES, {
        variables:{
            input:{
                data:{
                    campania:campaniaId
                }
            }
        },
        onCompleted(data){
            console.log(data)
            subeSlider(data.createPresentacion.presentacion.id)
        }
    })

    const onError=()=>{
        console.log("ERROR: ", Error)
		showError('Error', 'Error al subir los archivos')
    }


    if(rol()==="Cliente"){
        const descarga=()=>{
            alert("a")
        }
        return(
            <>
            <h3>Descargar presentaciones</h3>
            <Button
                className="p-button-info p-button-raised p-button-rounded"
				icon="pi pi-info p-button-raised p-button-rounded" 
                style={{ marginRight: ".25em"}}
                onClick={descarga}
            />
            </>
        )
    }
    else{
        return(
            <>
                <h3>Sube la presentación de la campaña {props.pauta.nombre_campania}</h3>
                <br></br>
                {notification}
                <Toast ref={toast}></Toast>
                <div>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <FileUpload
                                    name="demo[]" 
                                    mode="basic"
                                    url=''
                                    chooseLabel="Elegir Presentación"
                                    onSelect={e=>{
                                        console.log(e.files)
                                        var temp=[]
                                        for(var i=0; i<e.files.length; i++)
                                        {
                                            temp.push(e.files[i])
                                        }
                                        console.log("EEEEEE",temp)
                                        setImagenes(temp);
                                    }}
                                    onError={onError}
                                />
                            </IonCol>
                            <IonCol>
                                <Button
                                label="Subir Presentación"
                                icon="pi pi-upload p-button-raised p-button-rounded"
                                className="p-button-success ion-float-left"
                                onClick={onUpload}
                                />
                            </IonCol>
                        </IonRow> 
                    </IonGrid>
                </div>
            </>
        )
    }
}
export default Presentacion;