/* eslint-disable @typescript-eslint/no-unused-vars */
import React , {useState}from 'react'
import { IonGrid, 
         IonCol, 
         IonRow ,
         IonSpinner,
         IonCard,
         IonCardContent
} from '@ionic/react'
import {
    listaUbicaciones_Query,
    LISTAR_PUNTOS_RUTA_QUERY
} from '../querys/queries'; 
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import {Dropdown} from 'primereact/dropdown';
import { useQuery } from '@apollo/client';
import {InputTextComponent, MapaVistaRutas, InputTextareaComponent} from '../../../components';
// import { ShoppingCartContext } from '../../../context/ShoppingCartContext';


export default function FormDialogRutas(props){

    // const {addProduct, ButtonAdd} = useContext(ShoppingCartContext);

    const {handleSubmit, setValue } = useForm({
		defaultValues: {
           ubicacion: props.datos.ubicacion,
           nombre:  props.datos.nombre,
           inicio: props.datos.inicio,
           fin: props.datos.fin,
           descripcion: props.datos.descripcion,
           clave: props.datos.clave
		}
    });
    
    const [fields,setValueFields] = useState(props.datos);

    
    const [location, setLocation] = useState({
        id: 0,
        name: 'Guadalajara, Jalisco',
        lat: 20.6759,
        lng: -103.3375
    });
    const [latlngArray,setLatlngArray] = useState([]);
    const [idUbicacion,setidUbicacion] =useState(props.datos.ubicacion === null || props.datos.ubicacion === undefined ? 1 : parseInt(props.datos.ubicacion.id));

    const [Bandera, setBandera] = useState(false);
    const [elementoSel,setElementoSel] = useState<string>((fields.ubicacion === null ? "" : fields.ubicacion));
    const [descripcion, setdescripcion] = useState(fields.descripcion);
    const [inicioform, setinicioform] = useState(fields.inicio)
    let id = (props.datos.id === undefined ? 0 : parseInt(props.datos.id));
   
    // const getFieldsUpdate = () => {
	// 	let fieldtemp = { ...fields };
	// 	delete fieldtemp.id;
	// 	delete fieldtemp.__typename;
	// 	delete fieldtemp.created_at;
    //     delete fieldtemp.updated_at;
    //     if( fieldtemp.clave== null ){
    //       delete fieldtemp.clave;
    //     }
    //     fieldtemp.activo=(fieldtemp.activo === null ? true :  fieldtemp.activo);
    //     fieldtemp.ubicacion = (isNaN(idUbicacion) ? 1 : idUbicacion);
     
	// 	return fieldtemp;
	// };

    /*Queries*/
    
    const {loading : loading_listaUbicaciones, error : error_listaUbicaciones, data:data_listaUbicaciones } = useQuery(listaUbicaciones_Query);
  
    const {loading : loading_listaRutasPuntos, error : error_listaRutasPuntos, data:data_listaRutas_Puntos} = useQuery(LISTAR_PUNTOS_RUTA_QUERY,{
        variables:{
            id:id
        },
        onCompleted: (data) =>{
            if (data.ruta){
                setLatlngArray(data.ruta.puntos);
            }else{
                setLatlngArray([]);
            }
            
        }
    });


    const cargarlistaUbicaciones = () =>{
    
        if (loading_listaUbicaciones) return <IonSpinner name="dots" />
        if (data_listaUbicaciones) return (
            <Dropdown 
                tabIndex={1}
                showClear={true}
                style={{minWidth:'230px',width:"100%"}}
                value={elementoSel} 
                options={data_listaUbicaciones.ubicacions} 
                onChange={onselect} 
                dataKey='id'
                optionLabel='nombre'
                placeholder="Selecciona una ubicaciòn"
                required={true}
                disabled
            />
        )
    }

    const onselect =(selectid)=>{
        if(selectid.value){
            setidUbicacion(parseInt(selectid.value.id));
            setElementoSel(selectid.value);
        }else{
            setidUbicacion(null);
            setElementoSel(null);
        }
        
    }  
   
    const handleChange = (e, name = '') =>{
        setValueFields({...fields,[name]:e.target.value})
    }

    const onSubmit = data => {};
     
    const buscarEnMapa = () =>{
		fields.inicio = inicioform;
    }
    
    const botones = () => {
		if (props.operacion === 'editar' || props.operacion === 'crear') {
			return (
				<div>
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>			
			)
			
		}else{
			return(
				<div>

				</div>
			)
		}
		
    }
    
    const getvalueTextareaDescripcion =(value)=>{
		setdescripcion(value);
		fields.descripcion=value;
	}
    
    const getInicio = (value) =>{
       setinicioform(value);
       fields.inicio = value;
    }

    const MostrarOcultarMapa = () =>{
        return(
            <div>
                <MapaVistaRutas locations={latlngArray} />
            </div>
        );
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>

            <IonGrid>
                <IonCard>
                <IonCardContent>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <br></br>
						{cargarlistaUbicaciones()}
                    </IonCol>
              
                    <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                name={"clave"}
                                value={(fields.clave === null ? "" : fields.clave)}
                                style={{ width: "100%" }}
                                disabled={props.operacion === "detalles" ? true:false}
                                onChange={e => {
                                    handleChange(e, 'clave');
                                    setValue("clave", e);
                                }}
                                accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
								type={null}
                                onBlur={null}
                            />
                            <label htmlFor="in">Clave</label>
                        </span>
						
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
							<InputTextComponent
								name="inicio"
								value={inicioform}
								style={{ width: "100%" }}
								disabled={props.operacion === "detalles" ? true:false}
                                onChange={e => getInicio(e)}
								onBlur={()=>buscarEnMapa()}
                                accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								required={true}
								type={null}
							/>
							<label htmlFor="in">inicio</label>
						</span>
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                name="fin"
                                value={(fields.fin === null ? "" : fields.fin)}
                                style={{ width: "100%" }}
                                disabled={props.operacion === "detalles" ? true:false}
                                onChange={e => {
                                    handleChange(e, 'fin');
                                    setValue("fin", e);
                                }}
                                accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								required={true}
								type={null}
                                onBlur={null}

                            />
                            <label htmlFor="in">Destino</label>
						</span>
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
							<InputTextComponent
								name={"nombre"}
								value={(fields.nombre === null ? "" : fields.nombre)}
								style={{ width: "100%" }}
								disabled={props.operacion === "detalles" ? true:false}
                                onChange={e => {
                                    handleChange(e, 'nombre');
									setValue("nombre", e);
                                }}
                                accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								required={true}
								type={null}
                                onBlur={null}
							
							/>
							<label htmlFor="in">Nombre de la ruta</label>
						</span>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
                        <br></br>
						<label htmlFor="in">Descripcion</label>
						<InputTextareaComponent
                            tabIndex={6}
							style={{ width: "100%",border:" 1px solid gray" }}
							rows={2}
							disabled={props.operacion === "detalles" ? true:false}
							value={ descripcion }
                            onChange={e => getvalueTextareaDescripcion(e)}
                            name={null}
                            required={null}
						/>
                    </IonCol>
                </IonRow>
                </IonCardContent>
                </IonCard>
                <IonRow>
                    <IonCol  size="12" size-Xs="12" sizeMd="12" sizeLg="12" sizeXl="12">
                        <div style={{display:(props.operacion === 'crear' ?"contents": "none")}}>
                            <Button label="Empezar a registrar puntos" 
                                    tabIndex={7}
                                    onClick={() => {
                                        setBandera(true)
                                    }} 
                                    type="button"
                                    icon="pi pi-map-marker" 
                            />
                        </div>    
                        {MostrarOcultarMapa()}
                    </IonCol>
                </IonRow>
            </IonGrid>
            <br/><br/>
			<div>{botones()}</div>
        </form>
    );
}
