import React from 'react'

 const CuadrosFicha = (props) => {
    
    return (
        <div style={{width:props.width, 
            height:props.height, 
            left:props.left, 
            right:props.right, 
            top:props.top, 
            position:'absolute',
            fontSize: props.fontSize || '14px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'black'}}>
            <div style={{width:'100%', height:'23px',backgroundColor: props.color ? props.color : 'black',color:'white', paddingLeft:'5px'}}>{props.title}</div>
            <div style={{marginLeft:'5px'}}>{props.children}</div>
        </div>
    )
}

export default CuadrosFicha;
