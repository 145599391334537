import Resizer from 'react-image-file-resizer';


const Imgfile64Cliente=(file)=> new Promise(resolve =>{

    var fileInput = false
    if(file) {
        fileInput = true
    }
    if(fileInput) {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            'PNG',
            80,
            0,
            uri => {
                resolve(uri);
                
            },
            'base64'
        );
        
    }
});


export default Imgfile64Cliente;