/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from 'react'
import {isMobile} from 'react-device-detect';
import { Button } from 'primereact/button';
import { getLocalStorage, setLocalStorage } from "../utils/Utils";
import moment from 'moment';
import { ArrayCirculos, CirculoInfluencia } from '../pages/sitios/components/CiculoIncluencia/CirculoInfluencia';


export const ShoppingCartContext = createContext(null);

const ShoppingCartProvider = (props) => {
    const storage = isMobile ? localStorage : sessionStorage;
    //Estados
    //const [sitios, setSitios]=useState(getLocalStorage("shop"))
    const localStorageShop=getLocalStorage("shop");
    const [sitios, setSitios]=useState(localStorageShop===null || localStorageShop===undefined ? []: localStorageShop) //Esto settea todos los sitios en localstorage, no importa de dónde viene
    //const [sitios, setSitios] = useState([]); //Estado de sitios agregados desde infowindow
    const [rutas, setRutas] = useState([]); //Estado de rutas agregadas desde rutas select
    const [circShop, setCircShop] = useState([]); //Estado de círculos agregados
    const [test,setTest] = useState([]);
    const [sitiosLength, setSitiosLength]=useState(sitios.length===null ? 0: sitios.length);
    
    //Estados de uso
    const [count, setCount] = useState(0);
    const [countRutas, setCountRutas] = useState(0);
    
    /*SetFecha_inicio y setFecha_fin son las que manejan cada fecha individual en la pauta*/ 
    //Variables para cart item sitios @fecha_inicio, @fecha_fin
    const [fecha_inicio_sitios, setFecha_inicio_sitios] = useState("");
    const [fecha_fin_sitios, setFecha_fin_sitios] = useState("");
    //Variables para cart item rutas
    const [fecha_inicio_rutas, setFecha_inicio_rutas] = useState("");
    const [fecha_fin_rutas, setFecha_fin_rutas] = useState("");
    //Variables para cambiar la cantidad @cantidad
    const [cant, setCant] = useState(0);
    const [descuento, setDescuento] = useState(0); //sitios

    //Se obtiene la fecha actual para valor predeterminado
    const fecha = moment().format("YYYY-MM-DD");

    //Obtiene un mes después
    const tomorrow = moment().add(1, 'month').format('YYYY-MM-DD');
    //Función que cambia todas las fechas de cada elemento de la pauta
    const establecerFechaInicioProduct =(date:string)=>{
        /* Sitios */
        for (let index = 0; index < sitios.length; index++) {
            const element = sitios[index];//objeto
            element.fecha_inicio=date;//propiedad
        }
        /* Rutas */
        for (let index = 0; index < rutas.length; index++) {
            const element = rutas[index];//obejeto
            element.fecha_inicio=date;//ṕropiedad
        }
        /* Círculos */
        getCirculos().forEach(circulos=>{
            var rutas=circulos.urbanos
            var sitios=circulos.sitios
            rutas.forEach(element => {
                element.fecha_inicio=date;
            });
            sitios.forEach(element => {
                element.fecha_inicio=date;
            });
        })
    }
    const establecerFechaFinProduct =(date:string)=>{
        /* Sitios */
        for (let index = 0; index < sitios.length; index++) {
            const element = sitios[index];//objeto
            element.fecha_fin=date;//propiedad
        }
        /* Rutas */
        for (let index = 0; index < rutas.length; index++) {
            const element = rutas[index];//obejeto
            element.fecha_fin=date;//ṕropiedad
        }
         /* Círculos */
        getCirculos().forEach(circulos=>{
            var rutas=circulos.urbanos
            var sitios=circulos.sitios
            rutas.forEach(element => {
                element.fecha_fin=date;
            });
            sitios.forEach(element => {
                element.fecha_fin=date;
            });
        })
    }
    //Descuento global
    const establecerDescuento=(desc:number)=>{
        /* Sitios */
        for (let index=0; index < sitios.length; index++)
        {
            const element=sitios[index];//objeto
             element.descuento=desc; //propiedad
            console.log(sitios[index].descuento)
        }
        /* Rutas */
        for (let index = 0; index < rutas.length; index++) {
            const element = rutas[index];//obejeto
            element.descuento=desc;//ṕropiedad
        }
        /* Círculos */
        getCirculos().forEach(circulos=>{
            var rutas=circulos.urbanos
            var sitios=circulos.sitios
            rutas.forEach(element => {
                element.descuento=desc;
            });
            sitios.forEach(element => {
                element.descuento=desc;
            });
        })
    }
    
    const addSitios = (product, onWarning, onSuccess) => {
        let objectSitio = {
            id: product.id,
            clave: product.clave,
            fecha_inicio: fecha,
            fecha_fin: tomorrow,
            cantidad: 1,
            descuento: 0,
            disponible:product.disponible,
            precioventa: product.precioventa,
            tipos: product.tipos.nombre,
            iluminacion: product.iluminacion,
            niveldeimpacto: product.niveldeimpacto,
            nicho: product.nicho,
            direccion: product.direccion,
            ubicacion: product.ubicacion.nombre,
            referencias: product.referencias,
            Vista_corta: product.Vista_corta,
            vista: product.vista.nombre,
            medida: product.medida,
            lat: product.latitud,
            lng: product.longitud,
            frecuencia: product.frequency,
            total_hits: product.total_hits,
            impactos:product.total_users,
            alcance: product.reach,
            maleU:product.male_users,
            femaleU:product.female_users,
            income_a_users:product.income_a_users,
            income_b_users:product.income_b_users,
            income_c_users:product.income_c_users,
            income_d_users:product.income_d_users,
            income_e_users:product.income_e_users,
            a13_17_users:product.a13_17_users,
            a18_25_users:product.a18_25_users,
            a26_40_users:product.a26_40_users,
            a41_55_users:product.a41_55_users,
            a55_users:product.a55_users,
            universo:product.universo
            
        };
        if (sitios.find((sitio) => sitio.clave === objectSitio.clave)) {
        //     //Si ya se encuentra un sitio agregado con la misma clave se manda el warning y no se agrega
        //     //Solamente círculos pueden agregar elementos duplicados porque son propuestas distintas
            onWarning();     
            return
        } else {
            onSuccess();
        }

        //Flujo normal
        let sitiosTemp = [...sitios];
        sitiosTemp.push(objectSitio);
        setSitios(sitiosTemp);
        setSitiosLength(sitiosTemp.length)
        //LOCALSTORAGE
        if(getLocalStorage("shop"))
        {
            let shopTemp=[...sitios]
            shopTemp.push(objectSitio)
            setLocalStorage("shop", shopTemp)
            setSitios(shopTemp)
        }
        else{
            setLocalStorage("shop", objectSitio)
        }
        //para la persistencia del local storage
        setSitiosLength(sitios.length)
    }
    let sitiosT=[];
    //Función para sitios individuales (sin círculo)
    const sitiosEdit = (product) => {
        //setSitios([]);
        //let sitiosT=[...sitios]
        //let sitiosT=[]
        let medioEdit={
            id: product.id,
            clave: product.medio.clave,
            fecha_inicio: product.fecha_inicio,
            fecha_fin: product.fecha_fin,
            cantidad: 1,
            descuento: product.descuento,
            disponible:product.medio.disponible,
            precioventa: product.medio.precioventa,
            tipos: product.medio.tipos.nombre===undefined ? "" : product.medio.tipos.nombre,
            iluminacion: product.medio.iluminacion,
            niveldeimpacto: product.medio.niveldeimpacto,
            nicho: product.medio.nicho,
            direccion: product.medio.direccion,
            ubicacion: product.medio.ubicacion.nombre,
            referencias: product.medio.referencias,
            Vista_corta: product.medio.Vista_corta===undefined ? "" : product.medio.Vista_corta,
            vista: product.medio.vista.nombre===undefined ? "":product.medio.vista.nombre,
            medida: product.medio.medida,
            lat: product.medio.latitud,
            lng: product.medio.longitud,
            frecuencia: product.medio.frequency,
            total_hits: product.medio.total_hits,
            impactos:product.medio.total_users,
            alcance: product.medio.reach,
            maleU:product.medio.male_users,
            femaleU:product.medio.female_users,
            income_a_users:product.medio.income_a_users,
            income_b_users:product.medio.income_b_users,
            income_c_users:product.medio.income_c_users,
            income_d_users:product.medio.income_d_users,
            income_e_users:product.medio.income_e_users,
            a13_17_users:product.medio.a13_17_users,
            a18_25_users:product.medio.a18_25_users,
            a26_40_users:product.medio.a26_40_users,
            a41_55_users:product.medio.a41_55_users,
            a55_users:product.medio.a55_users,
            universo:product.universo
        };
        
        sitiosT.push(medioEdit);

        //LOCALSTORAGE
        if(getLocalStorage("shop"))
        {  
            setLocalStorage("shop", sitiosT)

        
        }
        else{
            setLocalStorage("shop", medioEdit)
        }
        setSitios(sitiosT)
        setSitiosLength(sitiosT.length)
    }

    var rutasT=[...rutas]
    const addRutas = (rutasShop) => {
        
        let misproductos = rutasShop.map((producto) => {
            let x = {
                id: producto.id,
                nombre: producto.nombre,
                tipoexhib: producto.ubicacion.tipoexhib,
                descripcion: producto.descripcion,
                cantidad: producto.cantidad ? producto.cantidad: 1,
                preciourbano: producto.ubicacion.preciourbano,
                ubicacion: producto.ubicacion.nombre,
                descuento: producto.descuento ? producto.descuento: 0,
                inicio: producto.inicio,
                fin: producto.fin,
                fecha_inicio: fecha,
                fecha_fin: tomorrow,
                ruta: producto.puntos,
                impactos: producto.impactos_ruta,
                alcance:producto.reach,
                total_hits:producto.total_hits,
                frecuencia:producto.frequency,
                kilometraje:producto.kilometraje,
                maleU:producto.male_users,
                femaleU:producto.female_users
            }
            return x;
        });
        //original
        //setRutas(misproductos);
        //modificado
        var rutasE=rutasT.concat(misproductos)
        var hash={};
        rutasE=rutasE.filter(function(current){
            var exists=!hash[current.id];
            return exists;
        })
        setRutas(rutasE)
    }

    //Arreglo que recibe las rutas para editar y las envía al shopping cart
    
    const rutasEdit = (rutasShop) => {
        let x={
            id: rutasShop.ruta.id,
            nombre: rutasShop.ruta.nombre,
            tipoexhib: rutasShop.ruta.ubicacion.tipoexhib,
            descripcion: rutasShop.ruta.descripcion,
            cantidad: rutasShop.cantidad ? rutasShop.cantidad: 1 ,
            preciourbano: rutasShop.ruta.ubicacion.preciourbano,
            ubicacion: rutasShop.ruta.ubicacion.nombre,
            descuento: rutasShop.descuento ? rutasShop.descuento : 0,
            inicio: rutasShop.ruta.inicio,
            fin: rutasShop.ruta.fin,
            fecha_inicio: rutasShop.fecha_inicio,
            fecha_fin: rutasShop.fecha_fin,
            ruta: rutasShop.ruta.puntos,
            impactos: rutasShop.ruta.impactos_ruta,
            alcance:rutasShop.ruta.reach,
            total_hits:rutasShop.ruta.total_hits,
            frecuencia:rutasShop.ruta.frequency,
            kilometraje:rutasShop.ruta.kilometraje,
            maleU:rutasShop.ruta.male_users,
            femaleU:rutasShop.ruta.female_users
        }
        rutasT.push(x)
        setRutas(rutasT)

        //modificado
        var hash={};
        rutasT=rutasT.filter(function(current){
            var exists=!hash[current.id];
            return exists;
        })
        setRutas(rutasT)
        // let misproductos = rutasShop.map((producto) => {
        //     let x={
        //         id: producto.ruta.id,
        //         nombre: producto.ruta.nombre,
        //         tipoexhib: producto.ruta.ubicacion.tipoexhib,
        //         descripcion: producto.ruta.descripcion,
        //         cantidad: producto.cantidad ? producto.cantidad: 1 ,
        //         preciourbano: producto.ruta.ubicacion.preciourbano,
        //         ubicacion: producto.ruta.ubicacion.nombre,
        //         descuento: producto.descuento ? producto.descuento : 0,
        //         inicio: producto.ruta.inicio,
        //         fin: producto.ruta.fin,
        //         fecha_inicio: producto.fecha_inicio,
        //         fecha_fin: producto.fecha_fin,
        //         ruta: producto.ruta.puntos,
        //         impactos: producto.ruta.impactos_ruta,
        //         alcance:producto.ruta.reach,
        //         total_hits:producto.ruta.total_hits,
        //         frecuencia:producto.ruta.frequency,
        //         kilometraje:producto.ruta.kilometraje,
        //         maleU:producto.ruta.male_users,
        //         femaleU:producto.ruta.female_users
        //     }
        //     return x;
        // });
        //console.log(misproductos)
        
        // rutasT.push(misproductos);
        //rutasT.push(rutasShop) 
        //setRutas('RutasT',rutasShop);   
        //console.log('Lo que se va',rutasT)
    }

    const deleteRutas = (product) =>{
        setCount(count-1);
        var array = [...rutas]; 
        var index = array.indexOf(product)
        if (index !== -1) {
            array.splice(index, 1);
            setRutas( array );
        }
    }

    const deleteSitios = (product) =>{
        setCount(count-1);
        var array = [...sitios]; //Usa operador spread para crear una copia separada
        var index = array.indexOf(product)
        if (index !== -1) {
            array.splice(index, 1);
            setSitios( array );
        }
    }

    /*
    CirculoShopping {
    etiqueta: string;
    identificador: string;
    latitud: number;
    longitud: number;
    radio: number;
    urbanos: [];
    sitios: []
}*/
    const addCircle = (circulo) => {
        //buscar círculo en arreglo
        let index = circShop.map(function (e) { return e.identificador; }).indexOf(circulo.identificador);
        if (index > -1) {
            //Si el círculo ya estaba creado lo actualiza con los nuevos valores
            let circulosTemp = [...circShop];
            circulosTemp[index] = circulo;
            setCircShop(circulosTemp);
        } else {
            //Si no estaba creado lo agrega al arreglo
            setCircShop(oldArray => [...oldArray, circulo]);
        }
    }

    const deleteCircle = (identificador) => {
        let circulosTemp = [...circShop];
        //buscar círculo en arreglo
        let index = circulosTemp.map(function (e) { return e.identificador; }).indexOf(identificador);
        if (index > -1) {
            //Si encuentra el índice eliminar el círculo del arreglo.
            circulosTemp.splice(index, 1);
            setCircShop(circulosTemp);
        }
    }
    //función que elimina sitios de círculo
    const deleteinC = (product) => {
        var circCopy=[...circShop]; //Se hace una copia del círculo original del Shopping Cart
        circCopy.forEach(circulo=>{
            var sit=[...circulo.sitios]; //copia del arreglo de circulo.sitios
            var index = sit.indexOf(product)
            if(index > -1){
                sit.splice(index, 1);
                circulo.sitios=sit;
            }
        })
    }

    //Botón de añadir al carrito de compras
    const ButtonAddSitios = (props) => {
        const onClick = () => {
            addSitios(props.sitio, props.onWarning, props.onSuccess)
        }
        return (

            <Button
                type="button"
                icon="pi pi-shopping-cart"
                className={`p-button-secondary $props.className`}
                onClick={onClick}
                {...props}>

            </Button>

        )
    }

    //Variables para key de arreglo
    let RutasNum = 0;

    //getters de estados para pintar pauta y exportación
    const getRutas = ()=>{
        var rutasTemp=[...rutas];
        var hash = {};
        rutasTemp = rutasTemp.filter(function(current) {
            var exists = !hash[current.id];
            hash[current.id] = true;
            return exists;
        });
        return rutasTemp;
    }

    const getSitios = () => {
        return sitios;
    }

    const getCirculos = ()=>{
        return circShop;
    }
    // Fin de getters

    const cartItemsRutas = () => {
        
    }
    /**
     * Hook que vacia la pauta
     */
    const PermisoVaciarItems =()=>{
        setCountProducts();
        setRutas([]);
        setSitios([]);
        for (let index = 0; index < circShop.length; index++) {
            const element = circShop[index];
            setRutas([]);
            setSitios([]);
        }
        setCircShop([]);
        localStorage.removeItem("shop")
    }

    const setCountProducts =()=>{
        setCount(sitiosLength);
    }
    
    //Función para mostrar la cantidad de sitios agregadas SOLAMENTE en inventario comercial y abcsitios
    const getCountItems = ()=>{
        return sitiosLength;
        //return sitios.length ;
    }

    return (
        <ShoppingCartContext.Provider value={{
            ButtonAddSitios,
            addRutas,
            addCircle,
            deleteCircle,
            deleteRutas,
            deleteSitios,
            cartItemsRutas,
            getRutas,
            getSitios,
            getCirculos,
            setFecha_fin_rutas,
            setFecha_inicio_rutas,
            setFecha_fin_sitios,
            setFecha_inicio_sitios,
            setCant,
            setDescuento,
            establecerFechaInicioProduct,
            establecerFechaFinProduct,
            addSitios,
            PermisoVaciarItems, 
            setCountProducts,
            deleteinC,
            getCountItems,
            rutasEdit,
            sitiosEdit,
            establecerDescuento
        }}>
            {props.children}
        </ShoppingCartContext.Provider>
    )
}

export default ShoppingCartProvider;