/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from "react";
import {IonRow, IonCol,IonText} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useMutation } from "@apollo/client";
import {PERMISIONARIOS_QUERY, CREATE_PERMISIONARIO, UPDATE_PERMISIONARIO} from "./queries";
import {Dropdown} from "primereact/dropdown";
import { InputTextComponent } from "../../components";


export default function FormPermisionarios (props){
	
    console.log("Lo que llega",props.datos);
	

    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
            id:props.datos.id,
			permisionario:props.datos.permisionario,
            esquemaPago:props.datos.esquemaPago,
            numPermisionario:props.datos.numPermisionario,
			costo: parseFloat(props.datos.costo)
		}
    });
    const [fields,setValueFields] = useState(props.datos);
    const [esquema, setEsquemaPago] = useState<string>(fields.esquemaPago === null ? "" : fields.esquemaPago);
    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
        delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		return fieldtemp;
	};

    
    const onSubmit= (data) =>{
        if (props.operacion==='editar'){
			updatePermisionario();
		}else if (props.operacion === 'crear'){
            createPermisionario();
        }else{
		
		}
	}

    const handleChange = (e, name) =>{
        setValueFields({...fields,[name]:e})
	}

    const [updatePermisionario] = useMutation(UPDATE_PERMISIONARIO, {
		variables: {
			input: {
				where: { id: fields.id },
				data: {
					permisionario:fields.permisionario,
					esquemaPago:esquema,
					numPermisionario:fields.numPermisionario,
					costo: parseFloat(fields.costo),
					createdby: props.datos.createdby,
					modifedby: props.datos.modifiedby
				}
				
			}
		},
		onCompleted(data){
            console.log(data)
            props.onSuccess();
		}
	});

    const [createPermisionario] = useMutation(CREATE_PERMISIONARIO, {
		variables: {
			input: {
				data:{
					permisionario:fields.permisionario,
					esquemaPago:esquema,
					numPermisionario:fields.numPermisionario,
					costo: parseFloat(fields.costo),
					createdby: props.datos.createdby,
					modifedby: props.datos.modifiedby
				}
			}
		},
		refetchQueries: [{ query: PERMISIONARIOS_QUERY }],
		onCompleted(data) {
            console.log(data)
			props.onSuccess();
		}, onError(error){
			console.log("Error: ",error)
		}
	});

   

	//Componente de botones
	const botones = ()=>{
		if(props.operacion === 'editar' || props.operacion === 'crear'){
			return (
				<div >
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>
			)
		}else{
			return (<div></div>)
		}
	}
	
	const opciones=['Fijo', 'Variable'];

	const esquemaPago=()=>{
		return(
			<Dropdown
			name="esquemaPago" 
			tabIndex={1}
			showClear={true}
			style={{width:'300%'}}
			value={esquema} 
			options={opciones} 
			onChange={e=>{
				setEsquemaPago(e.target["value"]);
				console.log(esquema)
                handleChange(e, 'esquemaPago');
			}}
			placeholder='Selecciona un esquema de pago'
			required={true}
		/>
		)
	}

	return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <div>
                <IonRow>
                    <IonCol>
                        <IonText>Permisionario</IonText>	
                    </IonCol>
					<IonCol>
						<IonText>Costo</IonText>
					</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <InputTextComponent
                            name="permisionario"
                            value={fields.permisionario === null ? "" : fields.permisionario}
                            onChange={(e)=> {
                                setValue("permisionario", e);
                                handleChange(e, 'permisionario');
                            }}
							accept={null}
							className={null}
							disabled={null}
							label={null}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							type={null}
                            required={true} 
                            style={{ width: "100%",color:'black'}}
                        />
                    </IonCol>
					<IonCol>
						<InputTextComponent
                            name="costo"
                            value={fields.costo === null ? 0 : parseFloat(fields.costo)}
                            onChange={(e)=> {
                                setValue("costo", e);
                                handleChange(e, 'costo');
                            }}
							accept={null}
							className={null}
							disabled={null}
							label={null}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							type={null}
                            required={true} 
                            style={{ width: "100%",color:'black'}}
                        />
					</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="4" sizeXl="4">
                        <IonText>Esquema de pago</IonText>
                        {esquemaPago()}		 
                    </IonCol>
                </IonRow>
                <br></br>
                <IonRow>
                    <IonCol>
                            <IonText>Número de permisionario</IonText>
                            <br></br>
                            <InputTextComponent
                                name="numPermisionario"
                                value={fields.numPermisionario === null ? "" : fields.numPermisionario}
                                onChange={(e)=> {
                                    setValue("numPermisionario", e)
                                    handleChange(e, 'numPermisionario');
                                }}
                                style={{ width: "100%",color:'black'}}
								accept={null}
								className={null}
								disabled={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
								type={'string'}
							/>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <br></br>
                    <br></br>
                    <div>
                        {botones()} 
                    </div>
                </IonRow>
            </div>
        </form>
	);

}