import React from 'react'
import { Chart } from 'primereact/chart';
import imjColors from './ImjColors';

export const ChartHM = (data,field)=>{
	let dataGraph = {
		labels: ['Mujeres','Hombres'],
		datasets:[
			{
				backgroundColor: imjColors,
                data: [data.female_users,data.male_users]
            }
		]
	}
	
	let options = {
		legend: {
            display: false,
          position: 'bottom',
            labels: {
                fontColor: '#333',
            }
		}
	}
    return (
		<div style={{textAlign:'center'}}>
        	<Chart type="horizontalBar" data={dataGraph} options={options} />
		</div>	
    )
}

const ChartHYM = (props) => {
	let dataGraph = {
		labels: ['Mujeres','Hombres'],
		datasets:[
			{
				backgroundColor: imjColors,
				data: [props.data.female_users,props.data.male_users],
				datalabels: {
					anchor: 'end'
				}
            }
		]
	}
	
	let options = {
		scales: {
			xAxes: [{
			  ticks: {
				min: (props.data.female_users<props.data.male_users)?Math.round((props.data.female_users*0.9)):Math.round((props.data.male_users*0.9)),
				max: (props.data.female_users>props.data.male_users)?Math.round(props.data.female_users+(props.data.female_users*0.2)):Math.round(props.data.male_users+(props.data.male_users*0.2))
			  }
			}]
		  },
		title:{
			display: true,
			text:'Hombres y Mujeres'
		},
		legend: {
            display: false,
          	position: 'bottom',
            labels: {
				fontColor: '#333',
				fontSize: props.fontSize || '12'
            }
		},
		plugins: {
			datalabels: {
				backgroundColor: function(context) {
					return context.dataset.backgroundColor;
				},
				borderColor: 'white',
				borderRadius: 25,
				borderWidth: 2,
				color: 'white',
				font: {
					weight: 'bold',
					size: props.fontSize || 12
				},
				formatter: function(value, context) {
					var dataset = context.dataset;
                    var sum = dataset.data.reduce(function(a, b){
                        return a + b;
                    }, 0);
                    let value2 = (dataset.data[context.dataIndex] / sum) * 100;
					value = new Intl.NumberFormat().format(dataset.data[context.dataIndex])+ '\n '+new Intl.NumberFormat().format(value2)+' %';
					return value;
				}
			}
		  }
	}
    return (
		<div style={{textAlign:'center'}}>
        	<Chart type="horizontalBar" data={dataGraph} options={options} />		
		</div>	
    )
}

export default ChartHYM;