/* eslint-disable use-isnan */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useState} from "react";
import {IonRow, IonCol, IonGrid, IonSpinner} from "@ionic/react";
import {InputText} from "primereact/inputtext";
import {useForm} from "react-hook-form";
import {InputSwitch} from 'primereact/inputswitch';
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
	MEDIOS_QUERY,
	CREATE_MEDIO, 
	UPDATE_MEDIO,
	listaUbicaciones_Query,
	BUSCAR_SITIO_POR_ID,
	LISTA_TIPO
} from "./query";
import {InputTextComponent, InputTextareaComponent} from '../../components';
import { InputNumber } from 'primereact/inputnumber';
import './FormPlazasIndoor.css';
import {
	GOOGLE_PLACES_ZERO_RESULTS,
	GOOGLE_PLACES_ZERO_RESULTS_RESPONSE,
	INVALID_CATEGORY_LOCALITY,
	INVALID_VALUE_POI_7_ELEVEN,
	INVALID_VALUE_TELEFONO_PUBLICO,
	INVALID_VALUE_TELEFONO_PUBLICO_ACENTOS,
} from "./../../utils/Constanst";
import { useNotification } from "./../../hooks";
import {INVALID_VALUE_POI_OXXO} from './../../utils/Constanst';
import CargarListaUbicaciones from '../abcmedios/components/CargarListaUbicaciones/CargarListaUbicaciones';
import MostrarOcultarMapa from "../abcmedios/components/MostrarOcultarMapa/MostrarOcultarMapa";
import { ProveedoresLkUp } from "../abcmedios/components/LookUpsABC";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

export default function FormAbcmedios(props) {
	const { handleSubmit, setValue } = useForm({
		defaultValues: {
			clave: props.datos.clave,
			direccion: props.datos.direccion,
			medida: 0,
			claveproveedor: "",
			disponible: props.datos.disponible,
			activo: props.datos.activo,
			vialidad: props.datos.vialidad,
			colonia: props.datos.colonia,
			largo: 0,
			ancho: 0,
			niveldeimpacto: props.datos.niveldeimpacto,
			iluminacion: false,
			referencias: props.datos.referencias,
			comentarios: props.datos.comentarios,
			costo: 0,
			precioventa: 0,
			Vista_corta: props.datos.Vista_corta,
			Vista_media: props.datos.Vista_media,
			Vista_larga: props.datos.Vista_larga,
			imagenmapaubicacion: props.datos.imagenmapaubicacion,
			material: 'N/A',
			latitud: props.datos.latitud,
			longitud: props.datos.longitud,
			tipoacabado: 'N/A',
			ubicacion: props.datos.id,
			vista: 3,
			tipos: 34,
			proveedor: 0,
			//proveedor: props.datos.id,
			especiales: props.datos.especiales
		}
	});

	

	const {notification: notificationParam, showWarn, showSuccess} = useNotification();
	
	const [location, setLocation] = useState({
		id: 0,
		name: 'Guadalajara, Jalisco',
		lat: props.datos.latitud,
		lng: props.datos.longitud
	});
	const [fields, setValueFields] = useState(props.datos);
	const [selectProveedor, setSelectProveedor] = useState<string>(fields.proveedor === null ? "" : fields.proveedor);
	const [selectubicaciones, setSelectUbicaciones] = useState<string>(fields.ubicacion === null ? "" : fields.ubicacion);
	const [selectTipo, setSelectTipo]=useState<string>(fields.tipo===null ? "" : fields.tipo);
	const re = / /gi; 
	// const [selectNicho, setSelectNicho] = useState(fields.nicho === null ?  "" : fields.nicho);
	//const [selectNicho, setSelectNicho] = useState(fields.nicho === null ?  "" : fields.nicho.replace(re, ''));
	const [selectNicho, setSelectNicho] = useState(fields.nicho === null || fields.nicho === undefined?  "" : fields.nicho.replace(re, ''));


	const [latitud, setLatitud] = useState(fields.latitud);
	const [longitud, setLongitud] = useState(fields.longitud);

	const [idUbicacion, setidUbicacion] = useState(props.datos.ubicacion === null || props.datos.ubicacion === undefined ? 1 : parseInt(props.datos.ubicacion.id));
	const [idVista, setidVista] = useState(props.datos.vista === null || props.datos.vista === undefined ? 1 : parseInt(props.datos.vista.id));
	const [idTipos, setidTipos] = useState(props.datos.tipos === null || props.datos.tipos === undefined ? 37 : parseInt(props.datos.tipos.id));
	const [idProveedor, setidProveedor] = useState(props.datos.proveedor === null || props.datos.proveedor === undefined ? 2 : parseInt(props.datos.proveedor.id));
	const [disponibleBoolean, setdisponibleBoolean] = useState(fields.disponible);

	const [activoBoolean, setactivoBoolean] = useState(fields.activo);
	const [textareaReferencias, settextareaReferencias] = useState(fields.referencias ? fields.referencias : '');
	const [textareaComentarios, settextareaComentarios] = useState(fields.comentarios)
	const [direccion, setDireccion] = useState(fields.direccion);
	const [especiales, setEspeciales]=useState(fields.especiales)
	// const [direccionBuscar, setdireccionBuscar] = useState(fields.direccion);

	const [tipoIndoor, setTipoIndoor]=useState(null)

	const [mapReference, setMapReference] = useState();

	const { loading: loading_listaUbicaciones, data: data_listaUbicaciones } = useQuery(listaUbicaciones_Query);

	const getFieldsUpdate = () => {
		
		let referenceTemp = '';
		if (textareaReferencias.length > 255) {
			referenceTemp = textareaReferencias.substring(0,255);
		} else {
			referenceTemp = textareaReferencias;
		}
		let fieldtemp = { ...fields };
		delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		delete fieldtemp.Vista_larga;
		delete fieldtemp.Vista_corta;
		delete fieldtemp.Vista_media;
		fieldtemp.vista = 3;
		fieldtemp.ubicacion = (isNaN(idUbicacion) ? 1 : idUbicacion);
		fieldtemp.tipos = (isNaN(idTipos) ? 37 : idTipos);
		fieldtemp.proveedor = null
		fieldtemp.precioventa = 0;
		fieldtemp.costo = 0;
		fieldtemp.direccion = direccion;
		fieldtemp.activo = (activoBoolean === undefined ? false : activoBoolean);
		fieldtemp.especiales = (especiales === undefined ? false : especiales);
		// fieldtemp.referencias = textareaReferencias;
		fieldtemp.referencias = referenceTemp;
		
		fieldtemp.latitud = parseFloat(latitud);
		fieldtemp.longitud = parseFloat(longitud);

		return fieldtemp;
	};

	const {loading: loadingTipos, data: dataTipos, error:ErrorTipos}=useQuery(LISTA_TIPO,
        {
            variables:{
                where:{
                    _or:[
                        {"nombre":"Centro Comercial"},
                        {"nombre":"Aeropuertos"},
                        {"nombre":"Trenes Suburbanos"}
                    ]
                }
            }, onCompleted(dataTipos){
				console.log(dataTipos)
            }
    });

	const listaTip=()=>{
		if (loadingTipos) return <IonSpinner name="dots"/>
		if (ErrorTipos) return <h5 color="red">Algo salió mal</h5>
		if (dataTipos){
			return(
			<>
                <span>
                    <label htmlFor='in'>Seleccionar Tipo</label>
                    <br/>
                    <Dropdown
                        name="tipos"
                        tabIndex={11}
                        showClear={true}
                        style={{borderRadius:'25px', width: '100%'}}
                        placeholder='Selecciona el tipo indoor'
                        options={dataTipos.tipos}
                        dataKey='id'
                        optionLabel="nombre"
                        filter
                        filterBy="nombre"
                        required={true}
                        value={selectTipo}
						onChange={onSelectTipos}
                    />
                </span>
            </>
			)
		}
	}

	const [createMedio] = useMutation(CREATE_MEDIO, {
		variables: {
			input: {
				data: getFieldsUpdate()
			}
		},
		refetchQueries: [{ query: MEDIOS_QUERY }],
		onCompleted(data) {
			
			showSuccess("Registro exitoso", "Registro guardado con éxito")
			//uploadPlugin(data.createMedio.medio.id, imagen, 'medio', '')
			props.onSuccess();
		}
	});

	const[UpdateMedioIndoor]=useMutation(UPDATE_MEDIO, {
		variables: {
			input: {
				where: { id: parseInt(fields.id) },
				data: getFieldsUpdate()
			}
		},
		onCompleted(data){
			console.log(data)
			props.onSuccess()
		}
	})

	const onselectUbicacion = (selectid) => {
		if (selectid.value) {
			setidUbicacion(parseInt(selectid.value.id));
			setSelectUbicaciones(selectid.value);
		} else {
			setidUbicacion(null);
			setSelectUbicaciones(null);
		}
	}

	const onSelectTipos=(selectidT)=>{
		if (selectidT.value) {
			setidTipos(parseInt(selectidT.value.id));
			setSelectTipo(selectidT.value);
		} else {
			setidTipos(null);
			setSelectTipo(null);
		}
	}

	const onSubmit = data => {
		if (props.operacion === 'editar') {
			UpdateMedioIndoor();
		} else if (props.operacion === 'crear') {
			console.log("clave: ", fields.clave)
			buscaID({
				variables:{
					where:{
						clave:fields.clave
					}
				}
			})
		}
	}

	
	const [buscaID]=useLazyQuery(BUSCAR_SITIO_POR_ID, {
		onCompleted(data){
			//console.log(data.medios);
			if(data.medios.length!==0){
				showWarn("Advertencia", "Registro duplicado o clave ya en uso")
			}
			else{
				console.log(getFieldsUpdate())
				createMedio();
			}
		}, onError(error){
			console.log("ERROR: ", error)
		}
	})


	const handleChange = (e, name = '') => {
		setValueFields({ ...fields, [name ? name : '']: e })
	}


	const getvalueTextareaComentarios = (value) => {
		settextareaComentarios(value);
		fields.comentarios = value;
	}

	const getvalueTextareaReferncias = (value) => {
		settextareaReferencias(value);
		fields.referencias = value;
	}


	const validarActivo = (value) => {
		fields.activo = value;
		setactivoBoolean(value);
	}


	const validarEspeciales =(value)=>{
		fields.especiales=value;
		setEspeciales(value);
	}

	const asignaImagenMapa = (imagen: any) => {
		fields.imagenmapaubicacion = imagen;
	}

	const buscarEnMapa = (address) => {
		let geocoder = new google.maps.Geocoder();
		geocoder.geocode({ 'address': address }, function handleResults(results, status) {

			if (status === google.maps.GeocoderStatus.OK) {
				setLatitud(results[0].geometry.location.lat);
				setLongitud(results[0].geometry.location.lng);
			}
		})
	}

	const getDireccion = (value) => {
		//buscarEnMapa(value);
		document.getElementById("direccionMp").onblur = function () { buscarEnMapa(value) };

	}

	/**
	 * Funcion que ordena la informacion obtenida por 
	 * la api de Google.Places
	 * @param dataResponse 
	 */
	const orderReferences = (dataResponse: any[]) => {
		console.log('dataREsponse', dataResponse);
		const nameArrayResponse = [];
		// Se itera la respuesta obtenida y se separan los nombre de los sitios en un arreglo
		dataResponse.forEach(d => {
			if (d.name.toLowerCase().includes(INVALID_VALUE_POI_OXXO) ||
				d.name.toLowerCase().includes(INVALID_VALUE_POI_7_ELEVEN) ||
				d.name.toLowerCase().includes(INVALID_VALUE_TELEFONO_PUBLICO) ||
				d.name.toLowerCase().includes(INVALID_VALUE_TELEFONO_PUBLICO_ACENTOS)){
				console.log('No puedo agregar este', d.name);
				return;
			}

			if (d.types.includes(INVALID_CATEGORY_LOCALITY)) {
				console.log('Categoria invalida', d.name);
				return;
			}
			nameArrayResponse.push(d.name)
		});
		// Se setean en la funcion ya establecida anteriormente
		getvalueTextareaReferncias(nameArrayResponse.toString().substring(0,255));
	}

	/**
	 * Callback encargado de leer la respuesta que nos retorna
	 * y llamar a la funcion que creara los markers desde 
	 * Google.Maps.Places ademas de agregar otros atributos y categorizar la respuesta
	 * @param {any[]} results 
	 * @param {string} status 
	 */
	const callback = (results, status, pagination) => {
		if (status === 'OK') {
			orderReferences(results);
		} else if (status === GOOGLE_PLACES_ZERO_RESULTS) {
			zeroResultGoogleSearch();
		}
	};

	/**
	 * Evento que se lanza cuando el resultado de la busqueda
	 * de puntos de interes de google arroja zero resultados
	 */
	const zeroResultGoogleSearch = () => {
		getvalueTextareaReferncias(GOOGLE_PLACES_ZERO_RESULTS_RESPONSE);
		showWarn(GOOGLE_PLACES_ZERO_RESULTS_RESPONSE);
		// Intenta lanzar una alerta
	}

	/**
	 * Evento que ejecuta la busqueda y el callback del evento de google
	 */
	const eventSearchPOI = () => {
		try {
			const distancia = 100; // Distancia en metros
			const request = {
				location: location,
				radius: distancia,
				query: '*', // en teoria si lo mando con un * deberia traer todo tipo de refrencia
			};

			const service = new window.google.maps.places.PlacesService(mapReference);
			service.textSearch(request, (results, status, pagination) => {
				callback(results, status, pagination)
			});
		} catch (error) {
			
		}
	}

	/**
	 * Funcion encargada de validar y mandar llamar a los
	 * eventos que buscaran los puntos de referencias
	 */
	const searchReference = () => {
		if (latitud && longitud) {
			eventSearchPOI();
		} else {
			zeroResultGoogleSearch();
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<IonGrid style={{marginTop:'10px'}}>
				{notificationParam}
				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								// id={0}
								name="clave"
								value={(fields.clave === null ? "" : fields.clave)}
								style={{ width: "100%", borderRadius: '25px'}}
								disabled={props.operacion === "detalles" ? true : false}
								required
								onChange={e => {
									handleChange(e, 'clave');
									setValue("clave", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Clave</label>
						</span>
					</div>
					
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputText
								id="direccionMp"
								name="direccion"
								value={direccion}
								style={{ width: "100%", borderRadius: '25px' }}
								required
								tabIndex={2}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setDireccion(e.target["value"]);
									getDireccion(e.target["value"]);
								}}
							/>
							<label htmlFor="in">Direccion</label>
						</span>
					</div>
				</div>
				
				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="vialidad"
								value={(fields.vialidad === null ? "" : fields.vialidad)}
								style={{ width: "100%", borderRadius: '25px' }}
								required={true}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'vialidad');
									setValue("vialidad", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Vialidad</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="colonia"
								value={(fields.colonia === null ? "" : fields.colonia)}
								style={{ width: "100%", borderRadius: '25px' }}
								required
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'colonia');
									setValue("colonia", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Colonia</label>
						</span>
					</div>
				</div>

				<div className={'input-ubicacion-abc-medios'}>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in" >Ubicacion</label>
						<CargarListaUbicaciones 
							data_listaUbicaciones={data_listaUbicaciones}
							loading_listaUbicaciones={loading_listaUbicaciones}
							onselectUbicacion={onselectUbicacion}
							selectubicaciones={selectubicaciones}
						/>
					</div>
					<div className={"form-abc-medios-col-item"}>
						{listaTip()}
					</div>
				</div>

				{/* LATITUD-LONGITUD */}
				<div className={'container-lat-lng-abcmedios'}>
					<div>
						<span className="p-float-label">
							<InputNumber
								name="latitud"
								value={latitud}
								locale="en-US"
								mode="decimal"
								minFractionDigits={2}
								maxFractionDigits={8}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								tabIndex={11}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setValueFields({ ...fields, latitud: e.value });
									setValue("latitud", e.value);
									let tempLocation = { ...location };
									tempLocation.lat = e.value;
									setLocation(tempLocation);
									setLatitud(e.value);
								}}
							/>
							<label htmlFor="in">Latitud</label>
						</span>
					</div>
					<div>
						<span className="p-float-label">
							<InputNumber
								name="longitud"
								value={longitud}
								locale="en-US"
								mode="decimal"
								minFractionDigits={2}
								maxFractionDigits={8}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								tabIndex={11}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setValueFields({ ...fields, longitud: e.value });
									setValue("longitud", e.value);
									let tempLocation = { ...location };
									tempLocation.lng = e.value;
									setLocation(tempLocation);
									setLongitud(e.value);
								}}
							/>
							<label htmlFor="in">Longitud</label>
						</span>
					</div>
					<div>
						<div
							onClick={() => {
								if ((latitud !== undefined && latitud !== null) && (longitud !== undefined && longitud !== null)) {
									searchReference();
								}
							}}
							className={(latitud !== undefined && latitud !== null) && (longitud !== undefined && longitud !== null) ? 
								'button-form-abc-medios' : 'button-form-abc-medios-disable'}>
							Buscar Rereferencias
						</div>
					</div>
				</div>

				
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						<label htmlFor="in">Referencias</label>
						<InputTextareaComponent
							required={true}
							tabIndex={16}
							rows={4}
							disabled={props.operacion === "detalles" ? true : false}
							style={{ width: "100%", border: " 1px solid gray", borderRadius: '25px'  }}
							value={textareaReferencias} onChange={e => getvalueTextareaReferncias(e)}
							name={null}
						></InputTextareaComponent>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						<label htmlFor="in">Comentarios</label>
						<InputTextareaComponent
							tabIndex={17}
							style={{ width: "100%", border: " 1px solid gray", borderRadius: '25px'  }}
							rows={4}
							disabled={props.operacion === "detalles" ? true : false}
							value={textareaComentarios} onChange={e => getvalueTextareaComentarios(e)}
							name={null}
							required={null}
						></InputTextareaComponent>
					</IonCol>
				</IonRow>
				<br></br>
				<br/>

				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="12" sizeXl="12" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Activo</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={activoBoolean} onChange={(e) => validarActivo(e.value)} />
					</IonCol>
					{/* <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Implementaciones especiales</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={especiales} onChange={(e) => validarEspeciales(e.value)} />
					</IonCol> */}
				</IonRow>
			
				<div>
					<div>
						<MostrarOcultarMapa
							operacion={props.operacion}
							setMapReference={setMapReference}
							asignaImagenMapa={asignaImagenMapa}
							location={location}
							latitud={latitud}
							longitud={longitud}
							setLatitud={setLatitud}
							setLongitud={setLongitud}
							setValue={setValue}
							imagenmapaubicacion={fields.imagenmapaubicacion}
						/>
					</div>
				</div>

				<IonRow>
					<img alt='' src=""></img>
				</IonRow>
				<br /><br />
				<IonRow>
					<IonCol></IonCol>
					<IonCol className="ion-align-items-center">
						<Button
							label="Guardar"
							icon="pi pi-check"
							className="p-button-success"
							style={{marginLeft: "90px" }}
							type='submit'
						/>
					</IonCol>
					<IonCol>
						<Button
                            label="Salir   "
                            float-right="true"
                            icon="pi pi-times"
                            className="p-button-danger ion-float-left"
                            style={{ marginLeft: "20px" }}
                            onClick={props.onDiscard}
                        /></IonCol>
				</IonRow>
			</IonGrid>

		</form>
	);
}
