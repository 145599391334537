/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonFooter,
  IonText,
} from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './Menu.module.css';
import {isMobile} from 'react-device-detect';
import { useHistory } from "react-router-dom";
import useAuthentication from '../../hooks/useAuthentication';
import {NotasVersion} from '../';
import {VERSION} from '../../utils/Constanst';

interface MenuProps extends RouteComponentProps {
	menuEnabled:boolean;
}

interface AppPage {
  url: string;
  icon: string;
  title: string;
  rol: Array<string>
}

const appPages: AppPage[] = [
  {
    title: "KPI's",
    url: '/page/KPIS',
    icon: '/assets/menu/Dashboard.svg',
    rol: ["Administrador","Operaciones Fijos"]
  },
  {
    title: 'Medios ooh',
    url: '/page/sitios',
    icon:'/assets/menu/MEDIOS_OOH.svg',
    rol: ["Administrador","Comercial","Clientes", "Operaciones Fijos", "Operaciones Urbanos", "Operaciones", "Creativos"]
  },
  {
    title: 'Listado de pautas',
    url: '/page/Pautas',
    icon: '/assets/menu/PAUTAS.svg',
    rol: ["Administrador","Comercial", "CLientes", "Operaciones Fijos", "Operaciones Urbanos", "Operaciones"]

  },
  {
    title: 'Calendario de Campañas',
    url: '/page/calendario',
    icon: '/assets/menu/calendario.svg',
    rol: ["Administrador","Comercial", "Operaciones Fijos", "Operaciones Urbanos", "Operaciones", "Creativos"]
  },
  {
    title: 'Urbanos',
    url: '/page/urbanos',
    icon: '/assets/menu/RUTAS.svg',
    rol: ["Administrador","Comercial","Clientes", "Operaciones Urbanos", "Operaciones"]
  },
  {
    title: 'Inventario Comercial',
    url: '/page/inventario',
    icon: '/assets/menu/INVENTARIO.svg',
    rol: ["Administrador","Comercial","Clientes", "Operaciones Fijos", "Operaciones Urbanos", "Operaciones"]
  },
  {
    title: 'Inventario Proveedores',
    url: '/page/inventario-proveedores',
    icon: '/assets/menu/INVENTARIO.svg',
    rol: ["Administrador","Proveedor", "Operaciones Fijos", "Operaciones"]
  },
  {
    title: 'ABC Sitios',
    url: '/page/abcmedios',
    icon: '/assets/menu/ABC_SITIOS.svg',
    rol: ["Administrador", "Operaciones Fijos", "Operaciones"]
  },
  {
    title: 'ABC Sitios InDoor',
    url: '/page/abcindoor',
    icon: '/assets/menu/ABC_SITIOS.svg',
    rol: ["Administrador", "Operaciones Fijos", "Operaciones"]
  },
  {
    title: 'InDoor',
    url: '/page/plazasindoor',
    icon: '/assets/menu/indoor.svg',
    rol: ["Administrador"]
  },
  {
    title: 'Rutas',
    url: '/page/rutas',
    icon: '/assets/menu/RUTAS.svg',
    rol: ["Administrador", "Operaciones Urbanos", "Operaciones"]
  },
  {
    title: 'Testigos',
    url: '/page/testigos',
    icon: 'assets/menu/Revision-Cliente-Campaña.svg',
    rol:["Administrador", "Operaciones Urbanos", "Operaciones", "Operaciones Fijos", "Clientes", "Comercial", "Creativos"]
  },
  {
    title: 'Campañas Urbanos',
    url: '/page/campaniasUrbanos',
    icon: '/assets/menu/Iconos-EcoCampania.svg',
    rol: ["Administrador", "Operaciones Urbanos", "Operaciones"]
  },
  {
    title: 'Permisionarios',
    url: '/page/permisionarios',
    icon: '/assets/menu/PERMISIONARIO.svg',
    rol: ["Administrador", "Operaciones Urbanos", "Operaciones"]
  },
  {
    title: 'Unidades Urbanos',
    url: '/page/unidades-urbanos',
    icon: '/assets/menu/Icono-AutobusInventario.svg',
    rol: ["Administrador", "Operaciones Urbanos", "Operaciones"]
  },
  {
    title: 'Vistas',
    url: '/page/vistas',
    icon: '/assets/menu/VISTAS.svg',
    rol: ["Administrador", "Operaciones Fijos", "Operaciones"]
  },
  {
    title: 'Proveedores',
    url: '/page/proveedores',
    icon: '/assets/menu/PROVEEDORES.svg',
    rol: ["Administrador", "Operaciones Fijos", "Operaciones Urbanos", "Operaciones"]
  },
  {
    title: 'Clientes',
    url: '/page/clientes',
    icon: '/assets/menu/CLIENTES.svg',
    rol: ["Administrador"]
  },
  {
    title: 'Tipos',
    url: '/page/tipos',
    icon: '/assets/menu/TIPOS.svg',
    rol: ["Administrador", "Operaciones Fijos", "Operaciones"]
  },
  {
    title: 'Ubicaciones',
    url: '/page/ubicaciones',
    icon: '/assets/menu/UBICACION.svg',
    rol: ["Administrador"]
  },
  // {
  //   title: 'Abc marcas',
  //   url: '/page/Abcmarcas',
  //   icon: '/assets/menu/ABC_Marcas.svg',
  //   rol: ["Administrador"]
  // },
  {
    title: 'Usuarios',
    url: '/page/usuarios',
    icon: '/assets/menu/USUARIOS.svg',
    rol: ["Administrador"]
  },
  // {
  //   title: 'Ficha',
  //   url: '/page/Fichas',
  //   icon: '/assets/icon/svg/pautas.svg',
  //   rol: ["Administrador"]

  // },
  {
    title: 'Cerrar sesión',
    url: '/logout',
    icon: '/assets/menu/CERRAR_SESION.svg',
    rol: ["Authenticated","Administrador","Comercial", "Proveedor", "Operaciones Fijos", "Operaciones Urbanos", "Clientes", "Operaciones", "Creativos"]
  },
];

const Menu: React.FunctionComponent<MenuProps> = ({ 
	menuEnabled
}) => {
  const [arranque,setArranque] = useState(false);
	const inputEl:any = useRef(null);
  let history = useHistory();
  const { isAuthenticated, rol } = useAuthentication();
  
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

	useEffect(() => {
		if (!isMobile){
			if (inputEl && inputEl.current && !arranque){
				setArranque(true);
				if (inputEl.current.open){
					inputEl.current.open();
				}
			}
		}
  }, [inputEl]);
  

  if (isAuthenticated()) {
		return (
			<IonMenu ref={inputEl} id='mainMenu' disabled={menuEnabled} menuId="mainMenu" contentId="main" type="overlay" >
				<IonContent>
        <NotasVersion event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}/>
       
					<IonList id="inbox-list">
            <div className='ion-text-center'>
              <img src='/assets/BitPlanner.png' style={{maxWidth:'200px', maxHeight:'180px'}}/>
            </div>
            <div className='ion-text-center'>
              {/* <IonText>OOH Bit Planner</IonText><br /> */}
              <img src='/assets/notificaciones.png' style={{height: '15px'}} alt='Notificaciones' onClick={(e: any) => {
                  e.persist();
                  setShowPopover({ showPopover: true, event: e })
                  }}/>&nbsp;&nbsp;<IonNote>{VERSION}</IonNote>
            </div>
						{appPages.filter((elemento)=>elemento.rol.indexOf(rol())>-1).map((appPage, index) => {
              return (
								<IonMenuToggle key={index} autoHide={false}>
									<IonItem
										className={
											history.location.pathname === appPage.url
												? styles.selected
												: ""
										}
										routerLink={appPage.url}
										routerDirection="none"
										lines="none"
										detail={false}>
										<IonIcon
											slot="start"
											src={appPage.icon}
										/>
                    <IonLabel>{appPage.title}</IonLabel>
									</IonItem>
								</IonMenuToggle>
							);
						})}
            <IonFooter><IonNote>sistemas@imjmedia.com.mx</IonNote></IonFooter>
					</IonList>
				</IonContent>
			</IonMenu>
		);
	} else {
		return <></>;
	}
};

export default withRouter(Menu);
