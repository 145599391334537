/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "@apollo/client";
import { saveAs } from 'file-saver';
import { formatMoney } from '../../../utils/Utils';
import { AddSheet, AltoFila, AlineacionCelda, BordeCelda, CombinarCeldas, EXCEL_EXTENSION, FormatoFila, FormatoMultiplesCeldas, HojaActiva, RellenoCelda, ValorCelda, ValoresFila, workBook, FuenteCelda } from './funcionesExport';
import {LISTA_UNIVERSO} from '../queries'
const Excel = require('exceljs');


export const exportExcelDetail = async (dataDetails, derroteroDetails, campania, universos) =>{
    //
    let wb= workBook();
    AddSheet('Sitios')
    let ws = HojaActiva();
    CombinarCeldas('A1:AH1');
    RellenoCelda('A1','Rojo');
    ValorCelda('A1',"Detalle de Sitios, IMJ");
    FormatoFila(1,{height:40,font:{bold:true,size:18,name:'Dia Regular'},alignment:{vertical:'middle',horizontal:'center'}});
    //Headers
    CombinarCeldas('A2:N2');
    RellenoCelda('A2','AmarilloIMJ');
    ValorCelda('A2','Datos Técnicos');
    BordeCelda('A2',{top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}});
    
    CombinarCeldas('O2:AF2');
    RellenoCelda('O2','AzulIMJ');
    ValorCelda('O2','Data Geográfica y de Impactos');
    FuenteCelda('O2',{bold: true,size:15,name:'Dia Regular',color:{argb:'FFFFFF'}, wrapText: true})
    BordeCelda('O2',{top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}});

    FormatoFila(2,{height:40,font:{bold:true,size:18,name:'Dia Regular'},alignment:{vertical:'middle',horizontal:'center'}});

    CombinarCeldas('AG2:AH2');
    RellenoCelda('AG2','MagentaIMJ');
    ValorCelda('AG2','');
    BordeCelda('AG2',{top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}});

    FormatoFila(3,{height:40,font:{bold:true,size:18,name:'Dia Regular'},alignment:{vertical:'middle',horizontal:'center'}});

    AltoFila(3,35);
    ValoresFila(3,['Disponibilidad','Ubicación', 'Clave IMJ', 'Dirección', 'Inicio', 'Fin', 'Vista', 'Iluminación', 'Medida', 'Tipo Anuncio', 'Comentarios', 'Referencias Comerciales', 'Latitud', 'Longitud', '', 'Impactos', 'Alcance', 'Impactos Totales', 'Frecuencia', 'Kilometraje', 'Hombres', 'Mujeres', 'NSE A', 'NSE B', 'NSE C', 'NSE D', 'NSE E', 'Edad 13-17', 'Edad 18-25', 'Edad 26-40', 'Edad 41-55', 'Edad 55 o más','','Precio IMJ']);
    FormatoMultiplesCeldas(['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3', 'AB3', 'AC3', 'AD3', 'AE3', 'AF3', 'AG3', 'AH3'],
                            'Gris',
                            {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
                            {vertical: 'middle', horizontal: 'center'},
                            {bold: true,size:15,name:'Dia Regular',color:{argb:'000000'}, wrapText: true}
                        );
    
    ws.columns=[
        {key: 'disponible', width: 20}, {key: 'ubicacion', width: 20}, {key: 'clave', width: 20}, {key: 'direccion', width: 35}, {key: 'inicio', width: 35}, {key: 'fin', width: 40},
        {key: 'vista', width: 20}, {key: 'iluminacion', width: 20}, {key: 'medida', width: 20}, {key: 'tipos', width: 20}, {key: 'comentarios', width: 20}, {key: 'referencias', width: 40},
        {key: 'latitud', width: 20}, {key: 'longitud', width: 20}, {key: 'space_1', width: 3}, //15
        {key: 'impactos', width: 20}, {key: 'alcance', width: 20}, {key: 'total_hits', width: 35}, {key: 'frecuencia', width:20}, {key: 'kilometraje', width: 30},
        {key: 'maleU', width: 25}, {key: 'femaleU', width: 25},
        {key: 'income_a_users', width: 20}, {key: 'income_b_users', width: 20}, {key: 'income_c_users', width: 20}, {key: 'income_d_users', width: 20}, {key: 'income_e_users', width: 20},
        {key: 'a13_17_users', width: 35}, {key: 'a18_25_users', width: 35},{key: 'a26_40_users', width: 35}, {key: 'a41_55_users', width: 35}, {key: 'a55_users', width: 35},
        {key: 'space_2', width: 3}, //33
        {key: 'precioventa', width: 20},
    ];
     //Función que ordena por campo de ubicacion:
    dataDetails=dataDetails.sort(function(a, b){
        if(a.ubicacion < b.ubicacion)
        return -1;
        if(a.ubicacion > b.ubicacion)
            return 1
        return 0
    })
    var hash = {};
    var plazas = dataDetails.filter(function(current) {
    var exists = !hash[current.ubicacion];
    hash[current.ubicacion] = true;
    return exists;
    });

    console.log(plazas)
    plazas.forEach(element => {
        let incomeASuma=0, incomeBSuma=0, incomeCSuma=0, incomeDSuma=0, incomeESuma=0, e1317=0, e1825=0, e2640=0, e4155=0, e55=0 
        let totalImpactosTotal=0
        let sumaW=0, sumaM=0
        let largo=0 //variable que contabiliza los sitios de una plaza
        let acumulado=[]
        let alcance, impactos, freq
        let mTotal, wTotal;
        var universo=element.universo
        dataDetails.forEach(e => {
            if(e.ubicacion===element.ubicacion)
            {
                
                var sumaIngresos = e.income_a_users + e.income_b_users + e.income_c_users + e.income_d_users + e.income_e_users;
                var sumaEdades=e.a13_17_users + e.a18_25_users + e.a26_40_users + e.a41_55_users + e.a55_users;
                totalImpactosTotal+=e.total_hits
                sumaW+=e.femaleU
                sumaM+=e.maleU
                
                const rowValues=[];
                //Validaciones
                sumaIngresos=sumaIngresos===0 ? 1 : sumaIngresos; 
                sumaEdades=sumaEdades===0 ? 1 : sumaEdades
                e.frecuencia=(e.frecuencia===null || e.frecuencia===undefined) ? 0 :e.frecuencia
                e.impactos=(e.impactos===null || e.impactos===undefined) ? 0 : e.impactos 
                e.alcance=(e.alcance===null || e.alcance===undefined) ? 0 : e.alcance
                e.total_hits=(e.total_hits===null || e.total_hits===undefined ? 0 : e.total_hits)
                e.maleU=(e.maleU===null || e.maleU===undefined ? 0 : e.maleU)
                e.femaleU=(e.femaleU===null || e.femaleU===undefined ? 0 : e.femaleU)
                e.income_a_users=(e.income_a_users===null || e.income_a_users===undefined ? 0 : e.income_a_users)
                e.income_b_users=(e.income_b_users===null || e.income_b_users===undefined ? 0 : e.income_b_users)
                e.income_c_users=(e.income_c_users===null || e.income_c_users===undefined ? 0 : e.income_c_users)
                e.income_d_users=(e.income_d_users===null || e.income_d_users===undefined ? 0 : e.income_d_users)
                e.income_e_users=(e.income_e_users===null || e.income_e_users===undefined ? 0 : e.income_e_users)
                e.a13_17_users=(e.a13_17_users===null || e.a13_17_users===undefined ? 0 : e.a13_17_users)
                e.a18_25_users=(e.a18_25_users===null || e.a18_25_users===undefined ? 0 : e.a18_25_users)
                e.a26_40_users=(e.a26_40_users===null || e.a26_40_users===undefined ? 0 : e.a26_40_users)
                e.a41_55_users=(e.a41_55_users===null || e.a41_55_users===undefined ? 0 : e.a41_55_users)
                e.a55_users=(e.a55_users===null || e.a55_users===undefined ? 0 : e.a55_users)
                
                rowValues[1]=(e.disponible ? "Sí":"No");
                rowValues[2]=(e.ubicacion);
                rowValues[3]=(e.clave);
                rowValues[4]=(e.direccion);
                rowValues[5]=(e.inicio);
                rowValues[6]=(e.fin);
                rowValues[7]=(e.vista);
                rowValues[8]=(e.iluminacion ? "Sí" : "No")
                rowValues[9]=(e.medida);
                rowValues[10]=(e.tipos);
                rowValues[11]=(e.comentarios);
                rowValues[12]=(e.referencias);
                rowValues[13]=(e.latitud);
                rowValues[14]=(e.longitud);
                rowValues[15]=('')
                rowValues[16]=formatMoney(e.impactos, 0)
                rowValues[17]=parseFloat(e.alcance)
                rowValues[18]=formatMoney(parseInt(e.total_hits), 0);
                rowValues[19]=formatMoney(e.frecuencia, 2);
                rowValues[20]=(e.kilometraje)
                rowValues[21]=e.maleU
                rowValues[22]=formatMoney(e.femaleU, 0);
                rowValues[23]=e.income_a_users/sumaIngresos
                rowValues[24]=e.income_b_users/sumaIngresos
                rowValues[25]=e.income_c_users/sumaIngresos
                rowValues[26]=e.income_d_users/sumaIngresos
                rowValues[27]=e.income_e_users/sumaIngresos
                rowValues[28]=e.a13_17_users/sumaEdades
                rowValues[29]=e.a18_25_users/sumaEdades
                rowValues[30]=e.a26_40_users/sumaEdades
                rowValues[31]=e.a41_55_users/sumaEdades
                rowValues[32]=e.a55_users/sumaEdades
                rowValues[33]=('');
                rowValues[34]='$'+formatMoney(e.precioventa)

                ws.addRow(rowValues);

                incomeASuma+=rowValues[23]
                incomeBSuma+=rowValues[24]
                incomeCSuma+=rowValues[25]
                incomeDSuma+=rowValues[26]
                incomeESuma+=rowValues[27]
                e1317+=rowValues[28]
                e1825+=rowValues[29]
                e2640+=rowValues[30]
                e4155+=rowValues[31]
                e55+=rowValues[32]
                sumaM+=e.maleU;
                sumaW+=e.femaleU;
                largo++
                
                acumulado.push(e.alcance)
            }
        });
        let reachTemp=0
        if(largo===1)
        {
            alcance=element.alcance
            impactos=element.alcance*universo
            freq=formatMoney(element.frecuencia, 2)
            // mTotal=(sumaM/(sumaM+sumaW))*impactos
            // wTotal=(sumaW/(sumaM+sumaW))*impactos
        }
        else{
            for(var i=0; i<acumulado.length; i++)
            {
                if (i===0){
                    reachTemp=acumulado[i]
                }
                if(i===1){
                    reachTemp=(acumulado[1]*100+acumulado[0]*100)-((acumulado[1]*100/100)*(acumulado[0]*100/100))*100
                }
                else{
                    reachTemp=(reachTemp+acumulado[i]*100)-((reachTemp/100)*(acumulado[i]*100/100))*100
                }
                
            }
            alcance=reachTemp/100
            impactos=alcance*universo
            freq=formatMoney(totalImpactosTotal/impactos, 2)
        }
        mTotal=(sumaM/(sumaM+sumaW))*impactos
        wTotal=(sumaW/(sumaM+sumaW))*impactos
        //TOTALES
        ValoresFila(ws.rowCount+1, ['', '', '', '', '', '', '', '', '', '', '', '','', 'Totales: ', '', formatMoney(impactos, 0), alcance, formatMoney(totalImpactosTotal, 0), freq, '', formatMoney(mTotal, 0), formatMoney(wTotal, 0), incomeASuma/largo, incomeBSuma/largo, incomeCSuma/largo, incomeDSuma/largo, incomeESuma/largo, e1317/largo, e1825/largo, e2640/largo, e4155/largo, e55/largo])
        FormatoMultiplesCeldas([`N${ws.rowCount}`, `P${ws.rowCount}`, `Q${ws.rowCount}`, `R${ws.rowCount}`, `S${ws.rowCount}`, `U${ws.rowCount}`, `V${ws.rowCount}`, `W${ws.rowCount}`, `X${ws.rowCount}`, `Y${ws.rowCount}`, `Z${ws.rowCount}`, `AA${ws.rowCount}`, `AB${ws.rowCount}`, `AC${ws.rowCount}`, `AD${ws.rowCount}`, `AE${ws.rowCount}`, `AF${ws.rowCount}`], 
                            'Gris',
                            {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
                            {vertical: 'middle', horizontal: 'center'},
                            { size:12, name:'Dia Bold', bold: 'true'}
        );
        AltoFila(ws.rowCount, 20);

        ValoresFila(ws.rowCount+1, [''])
        ws.getColumn(17).numFmt='0.00%'
        ws.getColumn(23).numFmt='0.00%'
        ws.getColumn(24).numFmt='0.00%'
        ws.getColumn(25).numFmt='0.00%'
        ws.getColumn(26).numFmt='0.00%'
        ws.getColumn(27).numFmt='0.00%'
        ws.getColumn(28).numFmt='0.00%'
        ws.getColumn(29).numFmt='0.00%'
        ws.getColumn(30).numFmt='0.00%'
        ws.getColumn(31).numFmt='0.00%'
        ws.getColumn(32).numFmt='0.00%'
        ws.getColumn(21).numFmt='0,000'
        ws.getColumn(22).numFmt='0,000'
    });

    for (var i=ws.rowCount; i>3; i--)
    {
        [`A${i}`, `B${i}`, `C${i}`, `D${i}`, `E${i}`, `F${i}`, `G${i}`, `H${i}`, `I${i}`, `J${i}`, `K${i}`, `L${i}`, `M${i}`, `N${i}`, `O${i}`, `P${i}`, `Q${i}`, `R${i}`, `S${i}`, `T${i}`, `U${i}`, `V${i}`, `W${i}`, `X${i}`, `Y${i}`, `Z${i}`, `AA${i}`, `AB${i}`, `AC${i}`, `AD${i}`, `AE${i}`, `AF${i}`, `AG${i}`, `AH${i}`].forEach(key =>{
            BordeCelda(key, {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}})
            AlineacionCelda(key, {vertical: 'middle', horizontal: 'center',wrapText: true});
        });
    }
    
    ///////////////////////////////////RUTAS//////////////////////////////////////
    derroteroDetails.sort(function (a, b) {
        if (a.ubicacion > b.ubicacion) {
            return 1;
        }
        if (a.ubicacion < b.ubicacion) {
            return -1;
        }
        return 0;
    });
    var hashR = {};
    var plazasR = derroteroDetails.filter(function(currentR) {
    var existsR = !hashR[currentR.ubicacion];
    hashR[currentR.ubicacion] = true;
    return existsR;
    });

    console.log("Plazas R",derroteroDetails)

    plazasR.forEach(element => {
        let totalImpactosTotalR=0
        let sumaWR=0, sumaMR=0
        let largoR=0 //variable que contabiliza las rutas de una plaza
        let acumuladoR=[]
        let kmT=0
        let alcanceR, impactosR, freqR
        let mTotalR, wTotalR;
        let universo
        derroteroDetails.forEach(ele=>{
            universos.forEach(e=>{
                if(ele.ubicacion===element.ubicacion && ele.ubicacion===element.ubicacion)
                {
                    universo=e.universo;
                    totalImpactosTotalR+=ele.total_hits
                    sumaWR+=ele.femaleU
                    sumaMR+=ele.maleU
                    const rowValues=[]
                    //validaciones
                    ele.frecuencia=(ele.frecuencia===null || ele.frecuencia===undefined) ? 0 : ele.frecuencia
                    ele.impactos=(ele.impactos===null || ele.impactos===undefined) ? 0 : ele.impactos
                    ele.alcance=(ele.alcance===null || ele.alcance===undefined) ? 0 :ele.alcance
                    ele.total_hits=(ele.total_hits===null || ele.total_hits===undefined) ? 0 :ele.total_hits
                    ele.maleU=(ele.maleU===null || ele.maleU===undefined) ? 0 :ele.maleU
                    ele.femaleU=(ele.femaleU===null || ele.femaleU===undefined) ? 0 :ele.femaleU
                    ele.kilometraje=(ele.kilometraje===null || ele.kilometraje===undefined) ? 0 : ele.kilometraje
                    
                    rowValues[1]=(ele.disponible);
                    rowValues[2]=(ele.ubicacion);
                    rowValues[3]=(ele.clave);
                    rowValues[4]=(ele.direccion);
                    rowValues[5]=(ele.inicio);
                    rowValues[6]=(ele.fin);
                    rowValues[7]=(ele.vista);
                    rowValues[8]=(ele.iluminacion);
                    rowValues[9]=(ele.medida);
                    rowValues[10]=(ele.tipos);
                    rowValues[11]=(ele.comentarios);
                    rowValues[12]=(ele.referencias);
                    rowValues[13]=(ele.latitud);
                    rowValues[14]=(ele.longitud);
                    rowValues[15]=('');
                    rowValues[16]=formatMoney(ele.impactos, 0);
                    rowValues[17]=parseFloat(ele.alcance);
                    rowValues[18]=formatMoney(parseInt(ele.total_hits), 0);
                    rowValues[19]=formatMoney(ele.frecuencia, 2);;
                    rowValues[20]=(ele.kilometraje);
                    rowValues[21]=formatMoney((ele.maleU), 0);
                    rowValues[22]=formatMoney((ele.femaleU), 0);
                    rowValues[23]='N/A';
                    rowValues[24]='N/A';
                    rowValues[25]='N/A';
                    rowValues[26]='N/A';
                    rowValues[27]='N/A';
                    rowValues[28]='N/A';
                    rowValues[29]='N/A';
                    rowValues[30]='N/A';
                    rowValues[31]='N/A';
                    rowValues[32]='N/A';
                    rowValues[33]=('');
                    rowValues[34]='$'+formatMoney(ele.precioventa);
                    
                    ws.addRow(rowValues);
                    ws.getColumn(34).numFmt = '$0,000.00';
                    ws.getColumn(17).numFmt = '0.00%';
                    ws.getColumn(16).numFmt = '0,000';
                    ws.getColumn(18).numFmt = '0,000';
                    ws.getColumn(19).numFmt = '0.00';
                    ws.getColumn(21).numFmt = '0,000.00';
                    ws.getColumn(22).numFmt = '0,000.00';
                    ws.getColumn(23).numFmt = '0.00%';
                    ws.getColumn(24).numFmt = '0.00%';
                    ws.getColumn(25).numFmt = '0.00%';
                    ws.getColumn(26).numFmt = '0.00%';
                    ws.getColumn(27).numFmt = '0.00%';
                    ws.getColumn(28).numFmt = '0.00%';
                    ws.getColumn(29).numFmt = '0.00%';
                    ws.getColumn(30).numFmt = '0.00%';
                    ws.getColumn(31).numFmt = '0.00%';
                    ws.getColumn(32).numFmt = '0.00%';

                    sumaMR+=ele.maleU;
                    sumaWR+=ele.femaleU;
                    largoR++
                    kmT+=ele.kilometraje;
                    acumuladoR.push(ele.alcance*100)
                }
            });
            let reachTempR=0
            if (largoR===1){
                alcanceR=ele.alcance/100
                impactosR=alcanceR*universo
                freqR=totalImpactosTotalR/impactosR
            }
            else{
                for(var i=0; i<acumuladoR.length; i++)
                {
                    if (i===0){
                        reachTempR=acumuladoR[i]
                    }
                    if(i===1){
                        reachTempR=(acumuladoR[1]+acumuladoR[0])-((acumuladoR[1]/100)*(acumuladoR[0]/100))*100
                    }
                    else{
                        //=(G42+F43)-((G42/100)*(F43/100))*100
                        reachTempR=(reachTempR+acumuladoR[i])-((reachTempR/100)*(acumuladoR[i]/100))*100
                    }
                }
                alcanceR=reachTempR/100
                impactosR=alcanceR*universo
                freqR=totalImpactosTotalR/impactosR
            }
        })
        mTotalR=(sumaMR/(sumaMR+sumaWR))*impactosR
        wTotalR=(sumaWR/(sumaMR+sumaWR))*impactosR
        //TOTALES
        ValoresFila(ws.rowCount+1, ['', '', '', '', '', '', '', '', '', '', '', '','', 'Totales: ', '', formatMoney(impactosR, 0), alcanceR, formatMoney(totalImpactosTotalR, 0), freqR, kmT, formatMoney(mTotalR, 0), formatMoney(wTotalR, 0),'', '', '', '', '', '', '', '', '', ''])
        FormatoMultiplesCeldas([`N${ws.rowCount}`, `P${ws.rowCount}`, `Q${ws.rowCount}`, `R${ws.rowCount}`, `S${ws.rowCount}`, `T${ws.rowCount}`,`U${ws.rowCount}`, `V${ws.rowCount}`], 
                            'Gris',
                            {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
                            {vertical: 'middle', horizontal: 'center'},
                            { size:12, name:'Dia Bold', bold: 'true'}
        );
        AltoFila(ws.rowCount, 20);
        ValoresFila(ws.rowCount+1, [''])
    })

    for (var i=ws.rowCount; i>3; i--)
    {
        [`A${i}`, `B${i}`, `C${i}`, `D${i}`, `E${i}`, `F${i}`, `G${i}`, `H${i}`, `I${i}`, `J${i}`, `K${i}`, `L${i}`, `M${i}`, `N${i}`, `O${i}`, `P${i}`, `Q${i}`, `R${i}`, `S${i}`, `T${i}`, `U${i}`, `V${i}`, `W${i}`, `X${i}`, `Y${i}`, `Z${i}`, `AA${i}`, `AB${i}`, `AC${i}`, `AD${i}`, `AE${i}`, `AF${i}`, `AG${i}`, `AH${i}`].forEach(key =>{
            BordeCelda(key, {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}})
            AlineacionCelda(key, {vertical: 'middle', horizontal: 'center',wrapText: true});
        });
    }


    const totalNumberOfRows=ws.rowCount;
    const lRow=totalNumberOfRows+1;
    CombinarCeldas(`A${lRow}:AH${lRow}`);
    ValorCelda(`A${lRow}`, "Teléfono de contacto: 0133 3640 0944 / 3640 6194 o consulta por email a tu asesor.");
    FormatoMultiplesCeldas([`A${lRow}`], 
                            'Gris',
                            {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
                            {vertical: 'middle', horizontal: 'center'},
                            { size:16, name:'Dia Bold'}
                        );
    AltoFila(lRow, 20);

    //generación del Excel
    const buffer = await wb.xlsx.writeBuffer(); 
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob= new Blob([buffer], {type: fileType});
    if (campania===null || campania===undefined || campania==="")
    {
        saveAs(blob, 'dataDetails_IMJ_Export_'+ new Date().getTime()+EXCEL_EXTENSION);
    }
    else
    {
        saveAs(blob, 'Detalles_'+campania+'_Export_'+ new Date().getTime()+EXCEL_EXTENSION);
    }
}