import {gql} from '@apollo/client';

export const UNIDADES_URBANOS_QUERY = gql `
    query listaUnidadesUrbanos($sort: String, $limit: Int, $start: Int, $where: JSON){
        unidadesUrbanos(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            numeco
            tipoContrato
            cartuchos
            activo
            vigencia
            ruta{
                id
                clave
                nombre
                ubicacion{
                    id
                    nombre
                }
            }
            permisionario{
                id
                permisionario
            }
            created_at
        }
    } 
`;

export const CREATE_UNIDADES_URBANOS = gql `
    mutation CrearUnidadesUrbano($input:createUnidadesUrbanoInput){
        createUnidadesUrbano(input:$input){
            unidadesUrbano{
                id
                activo
                numeco
                tipoContrato
                cartuchos
                ruta{
                    id
                    clave
                }
                permisionario{
                    id
                    permisionario
                }
            }
        }
    }
`;

export const UPDATE_UNIDADES_URBANOS = gql `
    mutation UpdateUnidadesUrbano($input:updateUnidadesUrbanoInput){
        updateUnidadesUrbano(input:$input){
            unidadesUrbano{
                id
                activo
                numeco
                tipoContrato
                cartuchos
                vigencia
                ruta{
                    id
                    clave
                }
                permisionario{
                    id
                    permisionario
                }
            }
        }
    }
`;



export const LISTAR_RUTAS = gql `
query listaRutas($sort: String,$limit: Int,$start: Int,$where: JSON){
    rutas(sort:$sort,limit:$limit,start:$start,where:$where){
        id
        clave
        nombre
        ubicacion{
            id
            nombre
        }
    }
}
`;

export const PERMISIONARIOS_QUERY = gql `
    query listaPermisionarios($sort: String, $limit: Int, $start: Int, $where: JSON){
      permisionarios(sort: $sort, limit: $limit, start: $start, where: $where){
          id
          permisionario
      }
    } 
`;

export const UBICACIONES_QUERY = gql`
    query listaUbicaciones($sort: String, $limit: Int, $start: Int, $where:JSON){
        ubicacions(sort: $sort, limit: $limit, start: $start, where:$where){
            id
            nombre
        }
    }
`;

