import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { ApolloProvider } from '@apollo/client';
import cliente from './apollo/client';
import { HashRouter } from 'react-router-dom';
import ShoppingCartProvider from './context/ShoppingCartContext';


ReactDOM.render(
    <ApolloProvider client={cliente}>
        <ShoppingCartProvider>
            <HashRouter>
                <App />
            </HashRouter>
        </ShoppingCartProvider>
    </ApolloProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
