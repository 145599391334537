import {gql} from "@apollo/client";

export const LISTA_PRODUCTOS = gql `
    query detallePauta ($sort: String,$limit: Int,$start: Int,$where: JSON){
        detailpautas(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            producto
            cantidad
            fecha_inicio
            fecha_fin
            pauta{
                id
                cliente
                responsable
                
            }
            medio{
                id
                clave
            }
            ruta{
                id
                nombre
            }   
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
    }
`;

export const REGISTRAR_DETALLE_PAUTA = gql `
    mutation REGISTRAR_DETALLE_PAUTA($input:createDetailpautaInput){
        createDetailpauta(input:$input){
            detailpauta{
                id
                createdby{
                    email
                }
                modifiedby{
                    email
                }
            }
        }
    }
`;

export const UPDATE_DETAIL_PAUTA = gql `
    mutation UPDATE_DETAIL_PAUTA($input:updateDetailpautaInput){
        updateDetailpauta(input:$input){
            detailpauta{
            id
            createdby{
                email
            }
            modifiedby{
                email
            }
            }
        }
    }
`;

export const REGISTRAR_PAUTA = gql `
    mutation REGISTRAR_PAUTA($input:createPautaInput){
        createPauta(input:$input){
            pauta{
                id
                createdby{
                    email
                }
                modifedby{
                    email
                }
            }
        } 
    }
`;

export const ACTUALIZAR_PAUTA = gql `
    mutation ACTUALIZAR_PAUTA ($input: updatePautaInput){
        updatePauta(input:$input){
            pauta{
                id
                createdby{
                    email
                }
                modifedby{
                    email
                }
            }
        }
    }
`; 


export const LISTAR_MEDIOS = gql `
    query listar_medios ($sort: String , $limit: Int,$start: Int, $where: JSON){
        medios(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            clave
        }
    }
`;

export const LISTAR_RUTAS = gql `
    query listar_rutas($sort: String,$limit: Int,$start: Int,$where: JSON){
        rutas(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
        }
    }
`;

export const LISTAR_MARCAS = gql `
    query listarMarcas($sort: String,$limit: Int,$start: Int,$where: JSON){
        marcas(sort:$sort,limit:$limit,start:$start, where:$where){
            id
            nombre
        }
    }
`;

export const LISTAR_CLIENTES = gql `
    query ListarClientes($sort: String,$limit: Int,$start: Int,$where: JSON){
        clientes(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            idbitrix
            empresa  
            color
            logo
        }
    }
`;

export const LISTAR_RESPONSABLES = gql `
query ListarResponsables($sort: String,$limit: Int,$start: Int,$where: JSON){
    users(sort:$sort,limit:$limit,start:$start,where:$where){
        id
        nombre
    }
}
`;