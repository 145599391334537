import React, { useState } from "react";
import { IonRow, IonCol, IonGrid , IonText, IonSpinner} from "@ionic/react";
import { Button } from "primereact/button";
import { useMutation, useQuery } from "@apollo/client";
import {UNIDADES_URBANOS_QUERY} from "./queries";
import {Dropdown} from "primereact/dropdown";
import { InputTextComponent } from "../../components";
import useNotification from "../../hooks/useNotification";
import moment from "moment";
import { CREATE_TESTIGO, VISTA_TESTIGOS_QUERY, TIPO_EXHIBICION_QUERY } from "./queries";
import axios from "axios"
import { FileUpload } from 'primereact/fileupload';
import {uploadPlugin} from '../../utils/Utils'

export default function FormTestigos(props){
	const [fechaSubida, setFechaSubida]=useState(moment().format('YYYY-MM-DD'))
	const [elementoSelNew, setElementoSelNew]=useState();
	const [detalle, setDetalle]=useState()
	const [detalleId, setDetalleId]=useState(0)
	const [imagen, setImagen]=useState(null)
	const [producto, setProducto]=useState(0)
	const [ecoOpc, setEcoOpc]=useState([])
	const [ecoSel, setEcoSel]=useState(null)
	const [elementoSel, setElementoSel]=useState()
	const [vistaSel, setVistaSel]=useState()
	const [vistaId, setVistaId]=useState(null)
	const [tipoExhibSel, setTipoExhibSel]=useState()
	const [tipoExhibId, setTipoExhibId]=useState(null)
	const [numReporte, setNumReporte]=useState("1")
	const [numReporteSel, setNumReporteSel]=useState(null)

    const {
		notification,
		showSuccess,
        showWarn,
		showInfo,
        showError,
	} = useNotification();

	const createTestigos=()=>{
		//console.log("IMAGEN", imagen)
		console.log("DETALLE", detalleId)
		console.log("FECHA", fechaSubida)

		CrearTestigo()
	}
	console.log(props)

	// const subeImagen=async(id)=>{
	// 	console.log(id)
	// 	console.log('IMAGEN IMAGEN',imagen)
	// 	const data = new FormData()
	// 	data.append('files', imagen)
	// 	data.append('refId', id)
	// 	data.append('ref', 'testigos')
	// 	data.append('field', 'imagen')
	// 	const upload_res= await axios({
	// 	method:'POST',
	// 	//url: 'http://localhost:3002/upload',
	// 	url: 'https://services.imjmedia.com.mx/upload',
	// 	data
	// 	})
	// 	console.log("Sísepudo", upload_res)
	// }

	const [CrearTestigo] = useMutation(CREATE_TESTIGO, {
		variables: {
			input: {
				data:{
                    detailpauta:detalleId,
					fecha_tomada:fechaSubida,
					fecha_subida: fechaSubida,
					unidades_urbano:ecoSel,
					tipo_exhibicion:tipoExhibId,
					vista_testigo:vistaId,
					reporte:numReporte
                }
                
			}
		},
		onCompleted(data) {
            console.log("aaaaa",data)
			props.onSuccess();
			//subeImagen(data.createTestigo.testigo.id)
			uploadPlugin(data.createTestigo.testigo.id, imagen, 'testigos', 'imagen')

		}, 
		onError(error){
			console.log("ERROR: ", error)
			props.onError();
		}
	});
	
	const updateTestigos=async()=>{
		console.log("edita")
	}

	const onSubmit= (data) =>{
        if (props.operacion==='editar'){
			updateTestigos();
			
		}else if (props.operacion === 'crear'){
            //prueba()
			createTestigos();
        }else{
		
		}
	}

	const onSelectNew=(detalleSel)=>{
		if(detalleSel.value){
			setElementoSelNew(detalleSel.value)
			setDetalleId(parseInt(detalleSel.value.id))
			setDetalle(detalleSel.value)
			console.log('detallesel',detalleSel.value)
			}
			else{
			setElementoSelNew(null)
			setDetalleId(0)
			setDetalle(null)
			}
	}

	const onSelectRuta=(detalleSel)=>{
		if(detalleSel.value){
			setElementoSelNew(detalleSel.value)
			setDetalleId(parseInt(detalleSel.value.id))
			setDetalle(detalleSel.value)
			console.log('detallesel',detalleSel.value)
			setProducto(detalleSel.value.producto)
			}
			else{
			setElementoSelNew(null)
			setDetalleId(0)
			setDetalle(null)
			}
	}

	const onSelectEco=(ecoSelec)=>{
		if(ecoSelec.value){
			setEcoSel(parseInt(ecoSelec.value.id))
			setElementoSel(ecoSelec.value)
		}
		else{
			setElementoSel(null)
			setEcoSel(null)
		}
	}

	const onSelectReporte=(reporteSel)=>{
		if(reporteSel.value){
			setNumReporteSel(reporteSel.value)
			setNumReporte(String(reporteSel.value))
		}
		else{
			setNumReporteSel(null)
			setNumReporte("1")
		}
	}

	 /////////////////////////////////////////////////Query que carga las rutas
	const {loading:loadingEco, data:dataEco , error:errorEco}= useQuery(UNIDADES_URBANOS_QUERY, {
        variables:{
            where:{
                    "activo":true,
                    "ruta":producto
            }
        }, onCompleted(dataEco){
            console.log("ecos",dataEco.unidadesUrbanos)
			setEcoOpc(dataEco.unidadesUrbanos)
        }, onError(error){
            console.log("Error ECO: ",errorEco)
        }
    });

	////////////////////////Query que carga los tipos de vista
	const {loading:loadingVista, data:dataVista, error:errorVista}=useQuery(VISTA_TESTIGOS_QUERY,{
		variables:{

		}, onCompleted(dataVista){
			console.log("dataVista",dataVista.vistaTestigos)
		}, onError(error){
			console.log("Error Vista: ",errorVista)
		}
	})

	///////////////////////Query de los tipos de exhibicion
	const {loading:loadingTestigos, data:dataTestigo, error:errorTestigo}=useQuery(TIPO_EXHIBICION_QUERY,{
		variables:{

		}, onCompleted(dataTestigo){
			console.log("dataTestigos", dataTestigo.tipoExhibicions)
		}, onError(error){
			console.log("Error Testigos: ", errorTestigo)
		}
	})
	const exhib=()=>{
		if(errorTestigo) return <h5 style={{color:"red"}}>Algo salió mal</h5>
		if(loadingTestigos) return <IonSpinner name="dots"/>;
		if(dataTestigo) return(
			<Dropdown
				name="exhibicion"
				tabIndex={3}
				showClear={true}
				style={{borderRadius: '25px'}}
				options={dataTestigo.tipoExhibicions}
				value={tipoExhibSel}
				onChange={onselectExhib}
				dataKey='id'
				optionLabel='tipoexhibicion'
				filter
				filterBy="tipoexhibicion"
				placeholder="Selecciona un tipo de exhibición"
				required={true}
			/>
		)
	}
	const onselectExhib=(selE)=>{
		if(selE.value){
			setTipoExhibSel(selE.value);
			setTipoExhibId(parseInt(selE.value.id))
		}
		else{
			setTipoExhibSel(null)
			setTipoExhibId(null)
		}
	}

	const vistas=()=>{
		if(errorVista) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
		if(loadingVista) return <IonSpinner name="dots" />;
		if(dataVista) return(
			<Dropdown
			name="vista"
			tabIndex={2}
			showClear={true}
			style={{borderRadius: '25px'}}
			options={dataVista.vistaTestigos}
			value={vistaSel}
			onChange={onselectVista}
			dataKey='id'
			optionLabel='vista'
			filter
			filterBy="vista"
			placeholder="Selecciona una vista"
			required={true}
			/>
		)
	}

	const onselectVista=(selectV)=>{
		if(selectV.value){
			setVistaSel(selectV.value);
			setVistaId(parseInt(selectV.value.id))
		}
		else{
			setVistaSel(null)
			setVistaId(0)
		}
	}

	//*******************************Componente de botones/*************************//////////////////////
	const botones = ()=>{
		if(props.operacion === 'editar' || props.operacion === 'crear'){
			return (
				<div>
					<br/>
					<IonRow >
						<IonCol>
							<Button
								label="Grabar"
								icon="pi pi-check"
								onClick={onSubmit}
								className="p-button-success ion-float-left"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol>
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-right"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>	
					</IonRow>	
				</div>
			)
		}else{
			return (<div></div>)
		}
	}

	const medios=()=>{
		let sitiosOpc=[]
		let rutasOpc=[]

		props.datos.data.forEach(element=>{
			if(element.medio!=null)
			{
				sitiosOpc.push(element)
			}
			if(element.ruta!=null)
			{
				rutasOpc.push(element)
			}
		})
		if(sitiosOpc.length>0)
		{
			return(
				<IonGrid>
					<IonRow>
						<IonCol><IonText>Selecciona el medio</IonText></IonCol>
						<IonCol><IonText>Seleccione el tipo de testigo</IonText></IonCol>
						<IonCol><IonText>Selecciona la imagen</IonText></IonCol>
						<IonCol><IonText>Selecciona la fecha del testigo</IonText></IonCol>
						<IonCol><IonText>Selecciona el número de reporte</IonText></IonCol>		
					</IonRow>
					<IonRow>
						<IonCol>
							<Dropdown
								name="medio"
								tabIndex={1}
								showClear={true}
								placeholder='Seleccione el medio'
								style={{borderRadius: "25px"}}
								filter
								filterBy='medio.clave'
								optionLabel='medio.clave'
								options={sitiosOpc}
								dataKey="id"
								value={elementoSelNew}
								onChange={onSelectNew}
							/>
						</IonCol>
						<IonCol>
							{vistas()}
						</IonCol>
						<IonCol>
							<FileUpload 
								mode="basic" 
								name="imagen" 
								url=""
								chooseLabel="Subir Testigo"
								accept="image/*"
								onSelect={e=>{
									setImagen(e.files[0]);
									console.log("EEEEEE",e.files[0])
							}}/>
						</IonCol>
						<IonCol>
							<InputTextComponent
								name={"fechaSubida"}
								type={"date"}
								value={fechaSubida}
								style={{width:'80%',borderRadius: "25px"}}
								required={true}
								onChange={e =>{
									setFechaSubida(e);
								}}
								className={null}
								disabled={null}
								onKeyPress={null}
								placeholder={null}
								label={null}
								accept={null}
								onBlur={null}
							/>
							<br/>
						</IonCol>
						<IonCol>
							<Dropdown
								name="nombres"
								tabIndex={4}
								showClear={false}
								placeholder={"Seleccionar el reporte"}
								style={{borderRadius: "25px"}}
								options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]}
								value={numReporteSel}
								onChange={onSelectReporte}
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						{botones()}
					</IonRow>
				</IonGrid>
			)
		}
		if(rutasOpc.length>0)
		{
			return(
				<IonGrid>
					<IonRow>
						<IonCol><IonText>Selecciona la ruta</IonText></IonCol>
						<IonCol><IonText>Selecciona el eco</IonText></IonCol>
						<IonCol><IonText>Seleccione el tipo de testigo</IonText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<Dropdown
								name="ruta"
								tabIndex={1}
								showClear={true}
								placeholder="Seleccione la ruta"
								style={{borderRadius: "25px", width: "60%"}}
								filter
								filterBy="ruta.nombre"
								optionLabel="ruta.nombre"
								options={rutasOpc}
								dataKey="id"
								value={elementoSelNew}
								onChange={onSelectRuta}
							/>
						</IonCol>
						<IonCol>
							<Dropdown
								name="eco"
								tabIndex={2}
								showClear={true}
								placeholder="Seleccione el ECO"
								style={{borderRadius: "25px", width: '70%'}}
								filter
								filterBy="numeco"
								optionLabel="numeco"
								options={ecoOpc}
								dataKey="id"
								value={elementoSel}
								onChange={onSelectEco}
							/>
						</IonCol>
						<IonCol>
							{exhib()}
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol><IonText>Selecciona la imagen</IonText></IonCol>
						<IonCol><IonText>Selecciona la fecha del testigo</IonText></IonCol>
						<IonCol><IonText>Selecciona el número de reporte</IonText></IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<FileUpload 
								mode="basic" 
								name="imagen" 
								url=""
								chooseLabel="Subir Testigo"
								accept="image/*"
								onSelect={e=>{
									setImagen(e.files[0]);
									console.log("EEEEEE",e.files[0])
							}}/>
						</IonCol>
						<IonCol>
							<InputTextComponent
								name={"fechaSubida"}
								type={"date"}
								value={fechaSubida}
								style={{width:'50%',borderRadius: "25px"}}
								required={true}
								onChange={e =>{
									setFechaSubida(e);
								}}
								className={null}
								disabled={null}
								onKeyPress={null}
								placeholder={null}
								label={null}
								accept={null}
								onBlur={null}
							/>
							<br/>
						</IonCol>
						<IonCol>
							<Dropdown
								name="nombres"
								tabIndex={4}
								showClear={false}
								placeholder={"Seleccionar el reporte"}
								style={{borderRadius: "25px"}}
								options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]}
								value={numReporteSel}
								onChange={onSelectReporte}
							/>
						</IonCol>
					</IonRow>
					<IonRow>
						{botones()}
					</IonRow>
				</IonGrid>
				
			)
		}
		if(rutasOpc.length===0 && sitiosOpc.length===0)
		{
			return(
				<IonGrid>
					<h1>No hay detalles asociados a la campaña seleccionada</h1>
				</IonGrid>
			)
		}
	}

	return(
		medios()
	)
}