/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import {Button} from 'primereact/button';
import {error, success} from './resources';

var convert = require('xml-js');

/**
 * 
 * @param {Object} props
 * @param {number} props.width
 * @param {number} props.height
 * @param {(response) => Promise<void>} props.onComplete
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setEdit
 */
const KMLUpload = ({
    width,
    height,
    onComplete,
    setEdit,
}) => {
    const [editor, setEditor] = useState(null);
    const [imageToShow, setImageToShow] = useState();
    /**
     * Funcion que abre el modal para que puedan importar los archivos KML
     */

    const closeDialog = () => setEdit(false);

    const setEditorRef = (editor) => setEditor(editor);

    // Estado que contiene la informacion del KML
    const [xmlResponseTemp, setXmlResponseTemp] = useState(null);

    /**
     * Funcion que permite arrastrar el archivo KML para su analisis y manejo
     */
    const onDrop = useCallback((acceptedFiles) => {
        const _file =  acceptedFiles[0]; // Archivo KML
        const reader = new FileReader(); // Objejot que lee el XML
        const parser = new DOMParser(); // Objeto que parsea el archivo XML

        try {
            reader.readAsText(_file); // Lee el archivo XML
            // Evento que carga la informacion del archivo
            reader.onload = event => {
                const xmlDoc = parser.parseFromString(event.target.result,"text/xml"); // Se obtiene el contenido
                const result = convert.xml2json(xmlDoc.documentElement.innerHTML, {compact: true, spaces: 4}); // Funcion que convierte el contenido del archivo a JSON 
                const jsonResponse = JSON.parse(result); // Se parsea la cadena de texto y se convierte a un JSON para su mejor manipulacion

                // Cachando el error en caso de que haya
                if (jsonResponse.body) {
                    console.log('ERROR', jsonResponse.body.parsererror);
                    setImageToShow(error);
                    // Falta mostrar una alerta o algo para notificar el error
                    return;
                }

                let arrayResponse = []
                const placemark = jsonResponse.Document.Placemark ? jsonResponse.Document.Placemark : null; // Objeto que contiene el contenido importante del KML
                const folderPlacemaker = jsonResponse.Document.Folder ? jsonResponse.Document.Folder.Placemark : null;
                const folder = jsonResponse.Document.Folder ? jsonResponse.Document.Folder : null;

                if (placemark) {
                    if (placemark.length) {
                        arrayResponse = placemark;
                    } else {
                        arrayResponse.push(placemark);
                    }
                } else if (folderPlacemaker){ // Asi parece que viene desde MyMaps
                    if (folderPlacemaker.length) {     
                        arrayResponse = folderPlacemaker;
                    } else {
                        arrayResponse.push(folderPlacemaker);
                    }
                } else if (folder) {
                    const placemarkTempArray = []
                    folder.forEach((f => {
                        if (f.Placemark.length) {
                            f.Placemark.forEach(fp => {
                                placemarkTempArray.push(fp);
                            });
                        } else {
                            placemarkTempArray.push(f.Placemark);
                        }
                        
                    }))
                    arrayResponse = placemarkTempArray;
                }
                
                setXmlResponseTemp(arrayResponse);
                setImageToShow(success);
            };
        } catch (errorResponse) {
            console.log('Error Rrsponse:', errorResponse);
            setImageToShow(error);
        }
    });

    /**
     * Evento onClick
     */
    const onClickSave = () => {
        if (editor) {
            setEdit(false);
            onComplete(xmlResponseTemp)
        }
    }

    return (
        <div style={{width: width || 300}}>
             <div style={{
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
                <h3>Arrastrar Archivo</h3>
                <Dropzone  onFileDialogCancel={closeDialog} maxFiles={1} onDrop={onDrop} noClick={true}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                            <AvatarEditor ref={setEditorRef}
                                width={width || 300}
                                height={height || 225}
                                image={imageToShow}
                                scale={0.6}
                                    />
                            <input {...getInputProps()} />

                            <div style={{
                                display: 'flex', 
                                justifyContent: 'center',
                            }}>
                                <Button disabled={xmlResponseTemp ? false : true} style={{paddingLeft: 20, paddingRight: 20}} label='Aceptar' onClick={onClickSave} />
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </div>
    )
}

export default KMLUpload;
