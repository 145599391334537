import {gql} from '@apollo/client';

export const LISTAR_TESTIGOS= gql `
    query listaTestigos($sort: String,$limit: Int,$start: Int,$where: JSON){
        testigos(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            fecha_tomada
            fecha_subida
            reporte
            imagen{
                id
                formats
                created_at
                name
                url
                hash
                mime
                ext
            }
            tipo_exhibicion{
                id
                tipoexhibicion
            }
            vista_testigo{
                id
                vista
            }
            unidades_urbano{
                id
                numeco
            }
            detailpauta{
                producto
                cantidad
                fecha_inicio
                fecha_fin
                medio{
                    id
                    clave
                    referencias
                    tipos{
                        nombre
                    }
                    precioventa
                    direccion
                    ubicacion{
                        nombre
                    }
                }
                ruta{
                    id
                    nombre
                    clave
                    descripcion
                    inicio
                    fin
                    ubicacion{
                        nombre
                        tipoexhib
                        preciourbano
                    }
                }
                pauta{
                    id
                    campania{
                        id
                    }
                }
            }
            created_at
        }
    }
`;

export const CREATE_TESTIGO = gql `
mutation CrearTestigo($input:createTestigoInput){
    createTestigo(input:$input){
        testigo{
            id  
            created_at
        }
    }
}
`;


export const LISTAR_CAMPANIAS = gql `
    query listaCampanias($sort: String,$limit: Int,$start: Int,$where: JSON){
        campanias(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            estatus
            clientes{
                id
                nombre
                empresa
            }
            responsable{
                id
                nombre
            }
        }
    }
`;

export const LISTAR_ODC= gql `
    query odc($sort: String,$limit: Int,$start: Int,$where:JSON){
        ordencompras(sort:$sort, limit:$limit,start:$start,where:$where){
            id
            idodoo
            campania{
                id
                nombre
            }
            nombre
            origen
            proveedor_ref
            fecha_orden
            fecha_aprov
            proveedor
            notas
            importe
            iva
            total
            cuenta_analitica
            estado
            order_line_json
            created_at
        }
    }
`;

export const LISTA_PAUTAS = gql `
    query listaPautas($sort: String,$limit: Int,$start: Int,$where: JSON){
        pautas(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre_campania
            campania{
                id
                nombre
                estatus
            }
            estatus
            responsable{
                id
                nombre
            }
        }
    }
`;

export const LISTAR_CLIENTES = gql `
    query ListarClientes($sort: String,$limit: Int,$start: Int,$where: JSON){
        clientes(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            nombre
            idbitrix
            empresa  
            color
            logo
            campanias{
                id
                nombre
            }
        }
    }
`;

export const LISTAR_RESPONSABLES = gql `
query ListarResponsables($sort: String,$limit: Int,$start: Int,$where: JSON){
    users(sort:$sort,limit:$limit,start:$start,where:$where){
        id
        nombre
    }
}
`;

export const LISTA_PRODUCTOS = gql `
    query detallePauta ($sort: String,$limit: Int,$start: Int,$where: JSON){
        detailpautas(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            producto
            cantidad
            fecha_inicio
            fecha_fin
            descuento
            pauta{
                id
                cliente
                responsable{
                    id
                    nombre
                } 
            }
            medio{
                id
                clave
                direccion
                precioventa
                tipos{
                    nombre
                }
                ubicacion{
                    id
                    nombre
                }
                medida
            }
            ruta{
                id
                clave
                nombre
                ubicacion{
                    nombre
                    tipoexhib
                    preciourbano
                }
                descripcion
            }   
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
    }
`;

export const UNIDADES_URBANOS_QUERY = gql `
    query listaUnidadesUrbanos($sort: String, $limit: Int, $start: Int, $where: JSON){
      unidadesUrbanos(sort: $sort, limit: $limit, start: $start, where: $where){
              id
              numeco
              tipoContrato
              cartuchos
              activo
              ruta{
                  id
                  clave
                  nombre
                  ubicacion{
                      id
                      nombre
                  }
              }
              permisionario{
                  id
                  permisionario
              }
      }
    } 
`;

export const VISTA_TESTIGOS_QUERY = gql `
    query listaVistaTestigos($sort: String, $limit: Int, $start: Int, $where: JSON){
        vistaTestigos(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            vista
        }
    }
`;

export const TIPO_EXHIBICION_QUERY = gql `
query listaTipoExhibicions($sort: String, $limit: Int, $start: Int, $where: JSON){
    tipoExhibicions(sort: $sort, limit: $limit, start: $start, where: $where){
        id
        tipoexhibicion
    }
}
`;

export const LISTAR_PRESENTACIONES= gql `
    query listaPresentacion($sort: String,$limit: Int,$start: Int,$where: JSON){
        presentacions(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            campania{
                id
                nombre
            }
            presentacion{
                id
                created_at
                name
                url
                hash
                mime
                ext
            }
            created_at
        }
    }
`;

export const CREATE_PRESENTACIONES= gql `
mutation CrearPresentacion($input:createPresentacionInput){
    createPresentacion(input:$input){
        presentacion{
            id  
            created_at
        }
    }
}
`;


export const LISTA_UNIVERSO = gql `
    query listaUniverso($sort: String,$limit: Int,$start: Int,$where: JSON){
        medios(sort:$sort,limit:$limit,start:$start,where:$where){
            id
            universo
            ubicacion{
                id
                nombre
            }
            activo
        }
    }
`;