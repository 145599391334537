/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";
import { DateField } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import useDialog from "../../hooks/useDialog";
import { useMutation } from "@apollo/client";
import {Toolbar} from "../../components";
import { UPDATE_DETAIL_PAUTA } from './queries';
import  FormProductos from './formproductos';
import useAuthentication from "../../hooks/useAuthentication";
import { ShoppingCartContext } from '../../context/ShoppingCartContext';

const columnas = [
    {
        header :"Cliente",
        field : "pauta.cliente",
        sortable: true,
        filter: true
    },
	{
		header: "producto",
		field: "producto",
		sortable: true,
		filter: true
	},
	{
		header: "Fecha inicio",
		field: "fecha_incio",
		body: DateField
	},
	{
		header: "Fecha fin",
		field: "fecha_fin",
		body:  DateField
	},
	{
		header: "Última actualización",
		field: "updated_at",
		body: DateField
	}
];

export default function Abcproductos (props){
    
    const [refresh, setRefresh] = useState(false);
	const {usuarioid} = useAuthentication();
	const [titleHeader, settitleHeader] = useState("Titulo");
	const [Bandera, setBandera] = useState(false);
	const [datos, setdatos] = useState({});
	const [op, setop] = useState("");

	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
	
	const [updateDetailPauta] = useMutation(UPDATE_DETAIL_PAUTA, {
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el estatus del registro exitosamente');
		}
	});
    
    const {showDialog, FormDialog} = useDialog();

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */

	const CambiarEstatus = (data) => {
        console.log(data);
        if (data){
			//updateDetailPauta({ variables: { input: { where: { id: data.id }, data:{estatus:!data.estatus} } } });
		}
    };
    

	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
    
	const addDetailPauta = () => {

		let data = {place:"",createdby:usuarioid(),modifiedby:usuarioid()}
		showDialog(data, "crear");
		settitleHeader('Crear pauta');
		let ope="crear";
		setdatos(data);
		setop(ope);
		setBandera(true);
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) =>{
		
		if (data.createdby===null){
			data.createdby = usuarioid();
		}else{
			var idcreatedby =data.createdby.id;
			data.createdby=parseInt(idcreatedby);
		}
        

		data.modifiedby=usuarioid();

		showDialog(data,'editar');
		settitleHeader('Editar informaciòn de la detalle de pauta');
		let ope='editar';
		setdatos(data);
		setop(ope);
        setBandera(true);
		
	}

	const onView = (data) => {
		showDialog(data,"detalles");
		settitleHeader("Detalles de la pauta");
		let ope='detalles';
		setdatos(data);
		setop(ope);
        setBandera(true);
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}

	
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>
			<IonContent>
				{notification}
				<div >
					<br></br>
					<FormProductos
					datos={datos}
					operacion ={op}
					onSuccess={onSuccess}
					onDiscard={onDiscard}
					></FormProductos>
				</div>
			</IonContent>
		</IonPage>
	);
}