import React from 'react'

const TituloSitio = (props) => {
    const fontSize = '35px';
    return (
        <div style={{width:props.width, 
            height:props.height, 
            left:props.left, 
            right:props.right, 
            top:props.top, 
            position:'absolute'}}>
            <div style={{fontSize: fontSize}}>{props.sitio||'IMJ-CIUDAD-000'}</div>
            <div style={{fontSize: fontSize, color:'#fc3d38'}}>{props.tipo||'TIPO DE SITIO'}</div>
        </div>
    )
}

export default TituloSitio;
