import React, {useEffect, useState} from "react";
import {
    IonHeader
} from "@ionic/react";
import {MToolBar, DTableV2} from "../../components";
import useAuthentication from "../../hooks/useAuthentication";
import useNotification from "../../hooks/useNotification";
import {LISTAR_TESTIGOS} from './queries';
import useDialog from "../../hooks/useDialog";
import FormTestigos from "./FormTestigos";
import VistaTestigoUnico from "./VistaTestigoUnico"
import { DateField, renderImageUpload } from "../../utils/dataTableAux";

const TablaTestigos=(props)=>{
    const columnas=[
        {
            header: "Medio",
            field: "detailpauta.producto",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Clave de Sitio",
            field: "detailpauta.medio.clave",
            filter: true,
            filterMatchMode:"contains"
        },
        {
            header:"Tipo de Vista",
            field:"vista_testigo.vista",
            filter: true,
            filterMatchMode:"contains"
        },
        {
            header: "Nombre de Ruta",
            field: "detailpauta.ruta.nombre",
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header:"ECO",
            field: "unidades_urbano.numeco",
            filter: true,
            filterMatchMode:"eco"
        },
        {
            header: "Tipo de Exhibición",
            field: "tipo_exhibicion.tipoexhibicion",
            filter: true,
            filterMatchMode:"tipoexhibicion"
        },
        {
            header: "Fecha",
            field: "fecha_subida",
            sortable: true,
            body: DateField
        },
        {
            header: "Imagen",
            field: "imagen.formats.thumbnail.url",
            body: renderImageUpload
        },
        {
            header: "Número de reporte",
            field: "reporte", 
            sortable: true
        }
    ];
    const [refresh, setRefresh] = useState(false);
    //popover de vista
    const [showInfoVista, setShowInfoVista] = useState(false);
    const [row, setRow]=useState()
    const {usuarioid, rol} = useAuthentication();
    const [titleHeader, settitleHeader] = useState("Agregar Testigo");
    const {showDialog, FormDialog} = useDialog();
    const pauta=props.pauta
    const data=props.data

    const {
        notification,
        showSuccess,
        showInfo,
    } = useNotification();

    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}


    const agregarTestigo=(props)=>{
        let datos = {pauta, data, created_by:usuarioid(),updated_by:usuarioid()}
		showDialog(datos, "crear");
		settitleHeader('Agregar Testigo');
    }

    const descargarTestigo=(row)=>{
        console.log("https://services.imjmedia.com.mx"+row.imagen.formats.large.url)
        var uri="https://services.imjmedia.com.mx"+row.imagen.formats.large.url
        fetch(uri, {
            method: "GET",
            headers: {}
            })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", row.imagen.name); //or any other extension
                document.body.appendChild(link);
                link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    }
    
    const closeInfoWindow = () => {
        setShowInfoVista(false);
    }
    const editarTestigo=()=>{
        let datos = {pauta, data, created_by:usuarioid(),updated_by:usuarioid()}
		showDialog(datos, "editar");
		settitleHeader('Editar Testigo');
    }
    const bajarTestigo=(row)=>{
        console.log(row)
        setRow(row)
        let data={row, created_by:usuarioid(),updated_by:usuarioid()}
        setShowInfoVista(true);
    }
            
    const checaRol=()=>{
        //console.log(rol())
        if(rol()!=="Clientes"){
            return(
                <MToolBar 
                addClick={agregarTestigo} //agregar foto a detalle dado 
                //addFourthClick={bajarTestigos} //descargar todos los testigos de la campaña
            />
            )
        }
    }
    return(
        <>
        <IonHeader>
            {notification}
            {checaRol()}
        </IonHeader>
        {/* Usar prop de filtros como en ODC*/}
        <DTableV2
            filtro={{"detailpauta.pauta.campania":props.pauta.campania.id}}
            element="testigos"
            query={LISTAR_TESTIGOS}
            style={{ margin: "15px" }}
            paginator={true}
            canEdit={false}
            columnas={columnas}
            onEdit={editarTestigo}
            canView={true}
            onView={bajarTestigo}
            refresh={refresh}
			onEndRefresh={() => setRefresh(false)}
            canDownload={true}
            onDownload={descargarTestigo}
        />
        <FormDialog
			header={titleHeader}
			onSuccess={onSuccess}
			onDiscard={onDiscard}
            >
		<FormTestigos />
		</FormDialog>
        <VistaTestigoUnico row={row} props={data} visible={showInfoVista} onClose={closeInfoWindow}/>
        </>
    )
}
export default TablaTestigos;