import { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
    IonGrid,
    IonRow,
    IonLabel,
    IonCol,
    IonSpinner,
    IonCard,
    IonAlert
} from "@ionic/react";
import {LISTAR_ECOS, 
        CAMPANIAS_URBANOS_QUERY,
        URBANOS_INCIDENCIAS_QUERY,
        LISTAR_CAMPANIAS,
        UPDATE_CAMPANIASURBANOS,
        DELETE_CAMPANIASURBANOS,
        DELETE_URBANOSINCIDENCIAS,
        LISTAR_RUTAS} from './query';
import { useLazyQuery, useMutation, useQuery} from "@apollo/client";
import moment from 'moment'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox';
import Calendario from "./Calendario";
import { InputTextComponent } from "../../components";
import { Button } from "primereact/button";
import useNotification from "../../hooks/useNotification";
import { of } from "zen-observable";
import { UNIDADES_URBANOS_QUERY } from "../unidades-urbanos/queries";

const Filtros=(props)=>{
    const hoy=moment().format('YYYY-MM-DD')
    //////Creación de grupos (nombres, rutas, ecos, etc.)
    const [ecos, setEcos]=useState([])
    const [incide, setIncide]=useState([])
    const [eventos, setEventos]=useState([])
    const [conteo, setConteo]=useState(0)
    const startDate=moment().locale('es-mx').startOf("year").toDate()
    const endDate=moment().startOf("year").add(1, "year").toDate()
    const [porVencer, setPorVencer]=useState(hoy)
    //   //Checkbox de unidades desactivadas 
    const [checked, setChecked]=useState(true)
    const [showAlert, setShowAlert] = useState(false);
    const [idSel, setIdSel]=useState(0)
    const [refresh, setRefresh] = useState(false);
    
    // //   //Filtro de campanias
    const[elementoSelCampania, setElementoSelCampania]=useState(null)
    const[idCampania, setIdCampania]=useState(0)

    //Filtro de permisionarios
    const[elementoSelPerm, setElementoSelPerm]=useState(null)
    const [idPermisionarios, setIdPermisionarios]=useState(0)

    //Filtro de rutas
    const [elementoSelRutas, setElementoSelRuta]=useState(null)
    const [idRutas, setIdRutas]=useState(0)

    const [vendidas, setVendidas]=useState(null)
    const [desocupadas, setDesocupadas]=useState(null)
    const [mesSel, setMesSel]=useState(null)
    
    const mesesOpc=[];
    for(var j=0;j<12; j++)
    {
        var y={
            id:moment().month(j).format('MM'),
            mes:moment.months(j),
            num:j
        }
        mesesOpc.push(y)
    }

    //Filtro vendidas
    const onSelVendidas=(sel)=>{
        if(sel.value){
            setVendidas(sel.value)
            setDesocupadas(null)
            const fechaInicioV=moment().year()+'-'+sel.value.id+'-01'
            const fechaFinalV=moment(fechaInicioV).endOf('month').format('YYYY-MM-DD')
            console.log(fechaInicioV + '  ' + fechaFinalV)
            const datac=dataCampaniasUrbanos.urbanoscampanias;
            const datau=dataUnidades.unidadesUrbanos
            const arrayaux=[]
            datac.forEach(element => {
                if(element.inicia>=fechaInicioV && element.termina<=fechaFinalV)
                {
                    arrayaux.push(element.unidades_urbano)
                }
                if(element.inicia>=fechaInicioV && element.inicia<=fechaFinalV && element.termina>=fechaFinalV)
                {
                    arrayaux.push(element.unidades_urbano)
                }
                if(element.inicia<fechaInicioV && element.termina>fechaFinalV)
                {
                    arrayaux.push(element.unidades_urbano)
                }
            })
            var array=[];
            for (var i = 0; i < datau.length; i++)
            {
                var igual=false;
                for (var j = 0; j < arrayaux.length && !igual; j++)
                {
                    if(datau[i]['id'] === arrayaux[j]['id'] && 
                    datau[i]['numeco'] === arrayaux[j]['numeco']) 
                    igual=true;
                }
                if(igual)array.push(datau[i])
            }
            const ventas=[]
            array.forEach(element=>{
                //Filtrado de grupos
                var y={
                    id:element.id,
                    title: element.ruta.nombre+' - '+element.numeco+' - '+element.permisionario.permisionario+'  -  '+element.tipoContrato,
                    isRoot: false,
                    parent: parseInt(element.ruta.id), 
                    rightTitle:element.cartuchos,
                    tipoContrato:element.tipoContrato,
                    activo:element.activo
                }
                ventas.push(y)
            });
            setEcos(ventas)
            setConteo(ventas.length)
        }
        else{
            setVendidas(null)
        }

    }
    //Filtro desocupadas
    const onSelVacantes=(sel)=>{
        if(sel.value)
        {
            setDesocupadas(sel.value)    
            setVendidas(null)
            const fechaInicioD=moment().year()+'-'+sel.value.id+'-01'
            const fechaFinalD=moment(fechaInicioD).endOf('month').format('YYYY-MM-DD')
            const dataCamp=dataCampaniasUrbanos.urbanoscampanias
            const dataUrbanos=dataUnidades.unidadesUrbanos
            const arrayaux=[]
            dataCamp.forEach(element => {
                if(element.inicia>=fechaInicioD && element.termina<=fechaFinalD)
                {   
                    arrayaux.push(element.unidades_urbano)
                }
                if(element.inicia>=fechaInicioD && element.inicia<=fechaFinalD && element.termina>fechaFinalD)
                {
                    arrayaux.push(element.unidades_urbano)  
                }
                if(element.inicia<fechaInicioD && element.termina>fechaFinalD)
                {
                    arrayaux.push(element.unidades_urbano)
                }
            });
            var array = [];
            for (var i = 0; i < dataUrbanos.length; i++) 
            {
                var igual=false;
                for (var j = 0; j < arrayaux.length && !igual; j++) 
                {
                    if(dataUrbanos[i]['id'] === arrayaux[j]['id'] && 
                    dataUrbanos[i]['numeco'] === arrayaux[j]['numeco']) 
                    igual=true;
                }
                if(!igual)array.push(dataUrbanos[i]);
            }
            /////////////////Array es el nuevo arreglo que settearemos para mostrar solo lo vacío
            const mermas=[]
            array.forEach(element=>{
                //Filtrado de grupos
                if(checked===true)
                {
                    var y={
                        id:element.id,
                        title:element.ruta.nombre+' - '+element.numeco+'  -  '+element.permisionario.permisionario+'  -  '+element.tipoContrato,
                        isRoot: false,
                        parent:parseInt(element.ruta.id),
                        rightTitle:element.cartuchos,
                        tipoContrato:element.tipoContrato,
                        activo:element.activo
                    }
                mermas.push(y)
                }
                else{
                    if(element.activo===true)
                    {
                        var z={
                            id:element.id,
                            title:element.ruta.nombre+' - '+element.numeco+'  -  '+element.permisionario.permisionario+'  -  '+element.tipoContrato,
                            isRoot: false,
                            parent:parseInt(element.ruta.id),
                            rightTitle:element.cartuchos,
                            tipoContrato:element.tipoContrato,
                            activo:element.activo
                        }
                        mermas.push(z)
                    }
                }
            });
            setEcos(mermas)
            setConteo(mermas.length)
        }
        else{
            setDesocupadas(null)
        }
    }

    const {
        notification,
        showSuccess,
        showInfo,
        showError,
        } = useNotification();

    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}
	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
    /////////////////////////////////////////////QUERY PARA LLENADOS
    //Campanias
    const {loading:loadingCampanias, error:errorCampanias, data:dataCampanias}=useQuery(LISTAR_CAMPANIAS, {
        variables:{
            sort:"idbitrix:DESC",
            where:{
                _or:[
                    {estatus:"Cerrado Ganado"},
                    {estatus:"Cotización personalizada"},
                    {estatus:"Cotización informal"}
                ],
                preciourbanos_gt:0 
            }
        },
        onCompleted:(dataCampanias)=>{
        }, onError(errorCampanias){
            console.log("Error: ",errorCampanias)
        }
    });
    ///ECOS
    const {loading: loadingUnidades, error:errorUnidades, data:dataUnidades}=useQuery(LISTAR_ECOS, {
        variables:{
            sort:"ruta.id:ASC",
            where:{
                "ruta.ubicacion":1
            }
        },
        onCompleted:(data)=>{
            console.log(dataUnidades.unidadesUrbanos)
            const datos=dataUnidades.unidadesUrbanos
            getEcos(datos);
        }
    });
    //Incidencias
    const {loading:loadingIncidencias, error:errorIncidencias,data:dataIncidencias}=useQuery(URBANOS_INCIDENCIAS_QUERY, {
        variables:{
            sort:"id:ASC",
            // where:{
            //   "unidades_urbano.activo":true
            // }
        },
        onCompleted:(dataIncidencias)=>{
            setIncide(dataIncidencias.urbanosIncidencias)
        }
    });
    //Campanias Urbanos
    const {loading:loadingCampaniasUrbanos, error:errorCampaniasUrbanos, data:dataCampaniasUrbanos}=useQuery(CAMPANIAS_URBANOS_QUERY, {
        variables:{
            sort:"id:ASC"
        },
        onCompleted:(dataCampaniasUrbanos)=>{
            //console.log(dataCampaniasUrbanos.urbanoscampanias)
            getItems(dataCampaniasUrbanos.urbanoscampanias, incide);
        },
        onError(errorCampaniasUrbanos){
            console.log("ERROR: ", errorCampaniasUrbanos)
        }
    });
    ///Rutas
    const {loading: loadingRutas, error:errorRutas, data:dataRutas}=useQuery(LISTAR_RUTAS, {
        variables:{
            sort:"id:ASC",
            where:{
                ubicacion:1,
                activo:true
            }
        },
        onCompleted:(dataRutas)=>{
            console.log(dataRutas.rutas)
        }
    })
    // ///////////////////////////////////////////////FIN DE QUERY

    /////////////////////////////////////////////////DROPDOWNS
    //Campaña
    const filtroCampaña=()=>{
        if(errorCampanias) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingCampanias) return  <IonSpinner name="dots" />;
        if(dataCampanias)
            return(
                <Dropdown
                    id='campanas'
                    placeholder="Selecciona campaña"
                    tabIndex={1}
                    showClear={true}
                    options={dataCampanias.campanias}
                    dataKey='id'
                    style={{borderRadius: "25px", maxWidth:"90%"}}
                    filter
                    filterBy="nombre"
                    optionLabel='nombre'
                    onChange={onSelectCamp}
                    value={elementoSelCampania}
                />
            )
    }
    const onSelectCamp=(camp)=>{
        if(camp.value){
            setElementoSelCampania(camp.value)
            setIdCampania(camp.value.id)
            buscaCamp({
                variables:{
                    where:{
                        "campania":camp.value.id
                    }
                }
            })
        }
        else{
            setElementoSelCampania(null)
            setIdCampania(0)
            getEcos(dataUnidades.unidadesUrbanos)
        }
    }
    const[buscaCamp]=useLazyQuery(CAMPANIAS_URBANOS_QUERY, {
        onCompleted(data){
            var parents=[]
            data.urbanoscampanias.forEach(element=>{
                var y={
                    id:element.unidades_urbano.id,
                    title:element.unidades_urbano.ruta.nombre+' - '+element.unidades_urbano.numeco+'  -  '+element.unidades_urbano.permisionario.permisionario+'  -  '+element.unidades_urbano.tipoContrato,
                    isRoot: false,
                    parent:parseInt(element.unidades_urbano.ruta.id),
                    rightTitle:element.unidades_urbano.cartuchos,
                    tipoContrato:element.unidades_urbano.tipoContrato,
                    activo:element.unidades_urbano.activo
                }   
                parents.push(y)
            })
            parents=parents.sort(function(a, b){
                if(a.title < b.title)
                return -1;
                if(a.title > b.title)
                    return 1
                return 0
            })
            setEcos(parents)
            setConteo(parents.length)
        }
    })
    //Rutas
    const filtroRutas=()=>{
        if(errorRutas) return <h5 color="red">Algo salió mal</h5>
        if(loadingRutas) return <IonSpinner name="dots"></IonSpinner>
        if (dataRutas)
            return(
                <Dropdown
                    id='rutas'
                    placeholder="Selecciona una Ruta"
                    tabIndex={3}
                    showClear={true}
                    style={{borderRadius: "25px", maxWidth:"70%"}}
                    options={dataRutas.rutas}
                    filter
                    dataKey="id"
                    filterBy="nombre"
                    optionLabel="nombre"
                    value={elementoSelRutas}
                    onChange={onSelectRutas}
                />
            )
    }
    const onSelectRutas=(rut)=>{
        if(rut.value){
            setElementoSelRuta(rut.value)
            setIdRutas(rut.value.id)
            buscaRut({
                variables:{
                    where:{
                            "ruta":rut.value.id
                    }
                }
            })
        }
        else{
            setElementoSelRuta(null)
            setIdRutas(0)
            getEcos(dataUnidades.unidadesUrbanos)
        }
    }
    const[buscaRut]=useLazyQuery(UNIDADES_URBANOS_QUERY, {
        onCompleted(data){
            const parents=[]
            data.unidadesUrbanos.forEach(element=>{
                var y={
                    id:element.id,
                    title:element.ruta.nombre+' - '+element.numeco+'  -  '+element.permisionario.permisionario+'  -  '+element.tipoContrato,
                    isRoot: false,
                    parent:parseInt(element.ruta.id),
                    rightTitle:element.cartuchos,
                    tipoContrato:element.tipoContrato,
                    activo:element.activo
                }
                parents.push(y)
            });
            setEcos(parents)
            setConteo(parents.length) 
        }
    })

    //Permisionarios
    const filtroPermisionarios=()=>{
        if(errorUnidades) return <h5 style={{color:"red"}}>Algo salió mal</h5>
        if(loadingUnidades) return <IonSpinner name="dots"/>
        if(dataUnidades)
        var opc=[]
        dataUnidades.unidadesUrbanos.forEach(element=>{
            var x={
                id:parseInt(element.permisionario.id),
                numPermisionario:element.permisionario.numPermisionario,
                nombre: element.permisionario.permisionario,
                label: element.permisionario.numPermisionario + ' - ' + element.permisionario.permisionario
            }
            opc.push(x)
        })
        var hash = {};
        opc = opc.filter(function(current) {
            var exists = !hash[current.id];
            hash[current.id] = true;
            return exists;
        });
        return(
            <Dropdown
                id='permisionarios'
                placeholder="Selecciona un Permisionario"
                tabIndex={2}
                showClear={true}
                style={{borderRadius: "25px", maxWidth:"70%"}}
                options={opc}
                filter
                dataKey="id"
                filterBy="label"
                optionLabel={'label'}
                onChange={onSelectPermisionario}
                value={elementoSelPerm}
            />
        )
    }
    const onSelectPermisionario=(perm)=>{
        if(perm.value){
            setElementoSelPerm(perm.value);
            setIdPermisionarios(perm.value.id)
            buscarPerm({
                variables:{
                    where:{
                        "permisionario":{
                            "id":perm.value.id
                        }
                    }
                }
            })
        }
        else{
            setElementoSelPerm(null)
            setIdPermisionarios(0)
            getEcos(dataUnidades.unidadesUrbanos)
        }
    }
    const [buscarPerm]=useLazyQuery(UNIDADES_URBANOS_QUERY, {
        onCompleted(data){
            var parents=[]
            data.unidadesUrbanos.forEach(element=>{
                var y={
                    id:element.id,
                    title:element.ruta.nombre+' - '+element.numeco+'  -  '+element.permisionario.permisionario+'  -  '+element.tipoContrato,
                    isRoot: false,
                    parent:parseInt(element.ruta.id),
                    rightTitle:element.cartuchos,
                    tipoContrato:element.tipoContrato,
                    activo:element.activo
                }
                parents.push(y)
            });
            parents=parents.sort(function(a, b){
                if(a.title < b.title)
                return -1;
                if(a.title > b.title)
                    return 1
                return 0
            })
            setEcos(parents)
            setConteo(parents.length)
        }
    })

    //////////////////////////////////FUNCIONES DE DELETE
    const doubleClic=(groupIdKey, time, e)=>{
        setIdSel(groupIdKey)
        setShowAlert(true)
        console.log("id: ", groupIdKey)
    }
    const [deleteUrbanoscampania]=useMutation(DELETE_CAMPANIASURBANOS, {
        onCompleted(data){
            console.log("Borrado exitoso")
        }, onError(error){
            console.log("ERROR: ", error)
        }
    })

    const [deleteUrbanosIncidencias]=useMutation(DELETE_URBANOSINCIDENCIAS,{
        onCompleted(data){
            console.log("Borrado exitoso")
        }, onError(error){
            console.log("ERROR: ", error)
        }
    })
    // //////////////////////////Funciones de llenado del timeline
    //función que obtiene los ecos
    const getEcos=(datos)=>{
        const groups=[];
        datos.forEach(element=>{
            var x={
                id: parseInt(element.id),
                title:element.ruta.nombre+' - '+element.numeco+'  -  '+element.permisionario.permisionario+'  -  '+element.tipoContrato,
                isRoot: false,
                parent:parseInt(element.ruta.id),
                rightTitle:element.cartuchos,
                tipoContrato:element.tipoContrato,
                value: parseInt(element.id),
                activo:element.activo,
                label: element.ruta.nombre+' - '+element.numeco+'  -  '+element.permisionario.permisionario+'  -  '+element.tipoContrato
            }
            groups.push(x);
        })
        setEcos(groups)
        setConteo(groups.length)
    }

    ////Llenado de eventos (incidencias y campañas)
    const getItems=(dataCampaniasUrbanos, inciden)=>{
        const items=[];
        var color;
        if(dataCampaniasUrbanos){
            dataCampaniasUrbanos.forEach(element=>{
            //ecos inactivos pero que tienen campañas van en gris
            if(element.unidades_urbano.activo===true){
                color="#FFFFFF"
            }
            else{
                color='gray'
            }
            var x={
                    id:element.id,
                    group:element.unidades_urbano.id,
                    title:element.campania.nombre+' Inicia: '+element.inicia+' Termina: '+element.termina,
                    start: moment(element.inicia),
                    end: moment(element.termina),
                    canMove:true,
                    canResize:"both",
                    canChangeGroup: false,
                    itemProps:{
                        style:{
                            background:color,
                            color:'black'
                        }
                    }
                }
                items.push(x)
            });
        }
        inciden.forEach(element => {
            if(element.tipoIncidencia==="Cartucho")
            {
                color="#5a0101"
            }
            else{
                color="#85c3ea"
            }
            var y={
                id: parseInt(element.id),
                group:element.unidades_urbano.id,
                title: 'INCIDENCIA: '+element.tipoIncidencia,
            start:moment(element.inicia),
            end:moment(element.termina), 
            canMove:true,
            canResize:false,
            itemProps:{
                style:{
                background:color,
                color:'black'
                }
            }
        }
        items.push(y)
        });
        setEventos(items);
    }
    const renderCalendar=()=>{
        if(errorCampaniasUrbanos || errorIncidencias) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingCampaniasUrbanos || loadingIncidencias) return  <IonSpinner name="dots" />;
        if(ecos.length===0) return <h1>No hay eventos para mostrar</h1>
        if(dataCampaniasUrbanos && dataIncidencias)
        {
            return(
                <Calendario
                    inicia={startDate}
                    termina={endDate}
                    groups={ecos}
                    items={eventos}
                    doubleClic={doubleClic}
                />
            )
        }
    }

    /////////////////////////////////////////////FUNCIONES DE FILTRADOS
    ////Mostrar desactivadas o no
    const onCheck=(e)=>{
        if(e===false){
            const inactivas=[]
            ecos.forEach(element=>{
                if(element.activo===true)
                {
                    inactivas.push(element)
                }
            })
        setEcos(inactivas)
        setConteo(inactivas.length) 
        }
        if(e===true)
        {
            getEcos(dataUnidades.unidadesUrbanos)
        }
    }

    //Mostrar los ecos que se van a desocupar en 15 días
    const mostrarPorVencer=(e)=>{
        console.log(e)
        console.log(moment(e).add(15, 'day').format('YYYY-MM-DD'))
        const fechaVigencia=moment(e).add(15, 'day').format('YYYY-MM-DD')
        const datac=dataCampaniasUrbanos.urbanoscampanias;
        const arrayaux=[]
        datac.forEach(element => {
            if((element.termina<=fechaVigencia && element.termina>=e) && element.unidades_urbano.activo===true)
            {
                arrayaux.push(element.unidades_urbano)
            }
        });
        arrayaux.filter((ele, id)=>{
            console.log(arrayaux.indexOf(ele)===id)
        })
        const xVencer=[]
        arrayaux.forEach(element=>{
            var y={
                id:element.id,
                title: element.ruta.nombre+' - '+element.numeco+' - '+element.permisionario.permisionario+'  -  '+element.tipoContrato,
                isRoot: false,
                parent: parseInt(element.ruta.id), 
                rightTitle:element.cartuchos,
                tipoContrato:element.tipoContrato,
                activo:element.activo
            }
            xVencer.push(y)
        });
        setChecked(false)
        setEcos(xVencer)
        setConteo(xVencer.length)
    }

    return(
        <>
            <IonCard>
                <IonAlert
                    isOpen={showAlert}
                    onDidDismiss={() => setShowAlert(false)}
                    cssClass='my-custom-class'
                    header={'Confirma la siguiente acción:'}
                    subHeader={'Esta acción borrará el registro'}
                    message={'¿Deseas continuar?'}
                    buttons={[
                    {
                        text: 'Aceptar',
                        handler: () => {
                        console.log('Confirm Okay', idSel);
                        deleteUrbanoscampania({variables:{input:{where:{id:idSel}}}})
                        deleteUrbanosIncidencias({variables:{input:{where:{id:idSel}}}})
                        onSuccess()
                        }
                    },
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {
                        console.log('Confirm Cancel');
                        onDiscard()
                        }
                    }
                    ]}
                />
                <IonGrid>
                    <IonRow>
                        <IonCol style={{textAlign:'center'}}>
                            Mostrar Desactivadas
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            Filtrar Campañas
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            Filtrar Rutas
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            Filtrar Permisionarios
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            Unidades Vendidas
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            Unidades desocupadas
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            Por vencer a la fecha
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            Conteo de unidades
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol style={{textAlign:'center'}}>
                            <Checkbox checked={checked} onChange={e=>{
                                setChecked(e.checked) 
                                onCheck(e.checked)
                                }}
                            />
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            {filtroCampaña()}
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            {filtroRutas()}
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            {filtroPermisionarios()}
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            <Dropdown
                                style={{borderRadius: "25px", maxWidth:"90%"}}
                                placeholder="Selecciona Mes"
                                options={mesesOpc}
                                dataKey='id'
                                optionLabel='mes'
                                showClear={true}
                                value={vendidas}
                                onChange={onSelVendidas}
                            />
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            <Dropdown
                                style={{borderRadius: "25px", maxWidth:"90%"}}
                                placeholder="Selecciona Mes"
                                options={mesesOpc}
                                dataKey='id'
                                optionLabel='mes'
                                showClear={true}
                                value={desocupadas}
                                onChange={onSelVacantes}
                            />
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            <InputTextComponent
                                name={"fecha_venta"}
                                type={"date"}
                                value={porVencer}
                                style={{borderRadius: "25px", maxWidth:"90%"}}
                                required={true}
                                onChange={e=>{
                                    setPorVencer(e)
                                    mostrarPorVencer(e);
                                }}
                                className={null}
                                disabled={null}
                                onKeyPress={null}
                                placeholder={null}
                                label={null}
                                accept={null}
                                onBlur={null}
                            />
                        </IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            <InputTextComponent
                                name={"conteo"}
                                type={"text"}
                                value={conteo}
                                style={{borderRadius: "25px", maxWidth:"25%"}}
                                required={true}
                                onChange={e=>{
                                }}
                                className={null}
                                disabled={true}
                                onKeyPress={null}
                                placeholder={null}
                                label={null}
                                accept={null}
                                onBlur={null}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                        <IonCol style={{textAlign:'center'}}>
                            <Button
                            label="Limpiar filtros" 
                            className="p-button-raised p-button-rounded p-button-info ion-float-left"
                            onClick={()=>
                                {
                                getEcos(dataUnidades.unidadesUrbanos)
                                getItems(dataCampaniasUrbanos.urbanoscampanias, incide)
                                setPorVencer(hoy)
                                setElementoSelPerm(null)
                                setElementoSelCampania(null)
                                setElementoSelRuta(null)
                                setIdCampania(0)
                                setIdPermisionarios(0)
                                setIdRutas(0)
                                setChecked(true)
                                setDesocupadas(null)
                                setVendidas(null)
                                } 
                            }
                            />
                        </IonCol>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
            {notification}
            <br/>
            {renderCalendar()}
        </>
    )
}
export default Filtros;