const { create } = require('xmlbuilder2')

class KMLExport {
    constructor(options = {}) {
        const name = options.name === undefined ? 'kml' : options.name
        const open = options.open === undefined ? '1' : options.open

        this.kml = create({ version: '1.0', encoding: 'UTF-8' })
            .ele('kml', {
                'xmlns': 'http://www.opengis.net/kml/2.2',
                'xmlns:gx': 'http://www.google.com/kml/ext/2.2',
                'xmlns:kml': 'http://www.opengis.net/kml/2.2',
                'xmlns:atom': 'http://www.w3.org/2005/Atom'
            })
            .ele('Document')
            .ele('name').txt(name).up()
            .ele('open').txt(open).up()
    }

    placemark(pm) {
        if (!Array.isArray(pm.coordinates)) {
            throw new TypeError('Coordinates not provided');
        }

        const name = pm.name === undefined ? 'new-placemark' : pm.name;
        const coordinates = pm.coordinates.map(c => c.join(',')).join(' ') // coordinates are delivered in format [[-102.0000492, 41.07128125, 20062], [-101.9940706, 41.07336908, 19669]]
        const description = pm.descripcion === undefined ? 'Punto IMJ':pm.descripcion;

        const placemark = {
            Placemark: {
                name: name,
                description: description,
                LineString: {
                    extrude: 1,
                    tessellate: 1,
                    altitudeMode: 'absolute',
                    coordinates: coordinates
                }
            }
        }

        this.kml.ele(placemark)
    }
    
     /* 
        metodo para agrgar una puntos/sitios al kml
        se debe pasar un objeto con:
        {
            name: Nombre para la línea, es la ruta
            description: pude ser el derrotero
            coordinates: coordenadas del sitio e  lat,lng
        }
    
    */
    sitio(pp) {
        const name = pp.name === undefined ? 'Punto IMJ' : pp.name
        const coordinates = pp.coordinates;
        const description = pp.descripcion === undefined ? 'Punto IMJ':pp.descripcion;

        const pushpin = {
            Placemark: {
                name: name,
                description: description,
                Point: {
                    altitudeMode: 'absolute',
                    coordinates: coordinates
                }
            }
        }

        this.kml.ele(pushpin)
    }

    /* 
        metodo para agrgar una ruta al kml
        se debe pasar un objeto con:
        {
            name: Nombre para la línea, es la ruta
            description: pude ser el derrotero
            coordinates: todas las coordenadas de los puntos del derrotero separadas por espacio entre ellas y en pares lat lng ejemplo: -122.364383,37.824664 -122.364152,37.824322
        }
    
    */
    ruta(pp){
        //TODO: definir el color
        const name = pp.name === undefined ? 'Punto IMJ' : pp.name
        const coordinates = pp.coordinates;
        const description = pp.descripcion === undefined ? 'Punto IMJ':pp.descripcion;

        const pushpin = {
            Placemark: {
                name: name,
                description: description,
                Style:{
                    LineStyle:{
                        color: Math.random().toString(16).substr(-6),
                        width: 3
                    }
                },
                LineString: {
                    extrude:1,
                    tessellate:1,
                    altitudeMode: 'relativeToGround',
                    coordinates: coordinates
                }
            }
        }

        this.kml.ele(pushpin)
    }

    /* 
    Método para agregar un array de sitios al kml
    */
    sitios(arraySitios){
        arraySitios.forEach(sitioIMJ => {
            const nuevoSitio={
                name:sitioIMJ.clave,
                descrption: sitioIMJ.tipos.nombre,
                coordinates: sitioIMJ.lng+ ',' + sitioIMJ.lat
            }
            this.sitio(nuevoSitio)
            
        });
    }

    /*
    Método para agregar múltiples rutas
    */
    rutas(arrayRutas){
        arrayRutas.rutas.forEach(ruta => {
            const nuevaRuta={
                name:ruta.nombre,
                descripcion:ruta.descripcion,
                coordinates:this.rutasToPath(ruta.puntos)
            }
            this.ruta(nuevaRuta)
            
        });  
    }
    rutasToPath(rutas){
        let puntos='';
        
        rutas.forEach(punto => {
            puntos+= ' '+punto.lng+','+punto.lat
        });
        return puntos;
    }


    rutasPauta(arrayRutas){
        
        var hash = {};
        arrayRutas = arrayRutas.filter(function(current) {
            var exists = !hash[current.id];
            hash[current.id] = true;
            return exists;
        });
        
        arrayRutas.forEach(ruta => {
            
            let puntosRuta=ruta.puntos || ruta.ruta || ruta.rutas;
            const nuevaRuta={
                name:ruta.nombre,
                descripcion:ruta.descripcion,
                coordinates:this.rutasToPath(puntosRuta) 
            }
            this.ruta(nuevaRuta)
            
        });  
    }
      

   

    stringify(pretty = true) {
        return this.kml.end({ prettyPrint: pretty });
    }
}
export default KMLExport;