import {gql} from '@apollo/client';

export const CREAR_USUARIO = gql `
    mutation CrearUsuario($input: createUserInput){
            createUser(input:$input){
                user{
                    id
                    nombre
                    username
                    email
                    role{
                        name
                    }
                }
            }
    }
`;


export const LISTA_ROLES = gql `
    query ListaRoles($sort: String ,$limit: Int ,$start: Int , $where: JSON){
        roles(sort:$sort , limit:$limit ,  start:$start,where:$where){
            id
            name
        }
    }
`;

export const LISTA_USUARIOS = gql `
    query ListaUsuarios{
        users{
            id
            nombre
            username
            email
            blocked
            role{
                id
                name
            }
            createdby{
                id
                username
            }
            modifiedby{
                id
                username
            }
        }
    }
`;


export const ACTUALIZAR_USUARIO =gql `
    mutation ActualizarUsuario($input:updateUserInput){
        updateUser(input:$input){
            user{
            id
            nombre
            }
        }
    }
`;

export const ELIMINAR_USUARIO = gql `
    mutation eliminar_usuario($id:ID!){
        deleteUser(input:{where:{id:$id}}){
            user{
            id
            }
        }  
    }
`;