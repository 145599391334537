import React, { useState } from 'react';
import { IonPopover } from "@ionic/react";
import { Panel } from "primereact/panel";
import { Button } from 'primereact/button';
import { InputTextComponent } from '../../../../components';

//PopUp de configuración de filtros del círculo
const PopupInputsCirculo = ({showPopover, setshowPopover, map, CrearCirculoConlatLng, circleListener, circulos}) => {
    // console.log('PopupInputsCirculo = circulos', circulos);
    //states para creación de círculos por domicilio o latitud
    const [latitudC, setLatitudC] = useState(null);//20.66682
    const [longitudc, setLongitudc] = useState(null)//-103.39182
    const [radioC, setRadioC] = useState(null)//6846
    const [domicilio, setDomicilio] = useState("");
    //fin de states para creación de círculos
    return (
        <IonPopover
            isOpen={showPopover}
            cssClass='my-custom-class'
            onDidDismiss={e => setshowPopover(false)}
        >
            <Panel header='Crear circulo'>
                <div><span>
                    <label>Dirección (calle+#,colonia,ciudad)</label><br></br>
                    <InputTextComponent
                        value={domicilio}
                        onChange={(e) => { setDomicilio(e)}}
                        style={{ width: "100%" }}
                        disabled={null}
                        name={null}
                        required={null}
                        type={'text'}
                        accept={null}
                        className={null}
                        label={null}
                        onBlur={null}
                        onKeyPress={null}
                        placeholder={null}
                    />
                </span>
                </div>
                <div><span>
                    <label>latitud</label><br></br>
                    <InputTextComponent
                        value={latitudC}
                        type="number"
                        onChange={(e) => setLatitudC(e)}
                        style={{ width: "100%" }}
                        disabled={null}
                        name={null}
                        required={null}
                        accept={null}
                        className={null}
                        label={null}
                        onBlur={null}
                        onKeyPress={null}
                        placeholder={null}
                    />
                </span>
                </div>
                <br></br>
                <div>
                    <label>longitud</label><br></br>
                    <InputTextComponent
                        value={longitudc}
                        type="number"
                        onChange={e => setLongitudc(e)}
                        style={{ width: "100%" }}
                        disabled={null}
                        name={null}
                        required={null}
                        accept={null}
                        className={null}
                        label={null}
                        onBlur={null}
                        onKeyPress={null}
                        placeholder={null}
                    />
                </div>
                <br></br>
                <div>
                    <label>Radio (en metros)</label><br></br>
                    <InputTextComponent
                        value={radioC}
                        type="number"
                        onChange={e => setRadioC(e)}
                        style={{ width: "100%" }}
                        disabled={null}
                        name={null}
                        required={null}
                        accept={null}
                        className={null}
                        label={null}
                        onBlur={null}
                        onKeyPress={null}
                        placeholder={null}
                    />
                </div>
                <br></br>
                <br></br>
                <div style={{ alignItems: "center" }}>
                    <Button
                        label="crear circulo"
                        icon="pi pi-circle-off"
                        className="primary"
                        onClick={() => {
                            let circuloTemp = undefined;
                            if (domicilio) {
                                let geocoder = new google.maps.Geocoder();
                                geocoder.geocode({ 'address': domicilio }, function (results, status) {
                                    if (status === 'OK') {
                                        map.setCenter(results[0].geometry.location);
                                        circuloTemp = CrearCirculoConlatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng(), radioC || 1000, map);
                                        circleListener(circuloTemp.circulo);
                                        circulos.agregarCirculo(circuloTemp);
                                    } else {
                                        alert('Geocode was not successful for the following reason: ' + status);
                                    }
                                });

                            } else {
                                circuloTemp = CrearCirculoConlatLng(latitudC, longitudc, radioC, map);
                                circleListener(circuloTemp.circulo);
                                circulos.agregarCirculo(circuloTemp);
                                map.setCenter({lat:parseFloat(latitudC),lng:parseFloat(longitudc)});
                            }
                            setshowPopover(false);
                        }}
                    />
                    <Button
                        label="cancelar"
                        icon="pi pi-times-circle"
                        className="p-button-danger"
                        style={{ marginLeft: "30px" }}
                        onClick={() => {
                            setshowPopover(false);
                        }}
                    />
                </div>
            </Panel>
        </IonPopover>
    )
}

export default PopupInputsCirculo;
