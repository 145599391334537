import {gql} from '@apollo/client';
const RUTASENAREA = gql`
query rutas ($distancia: Float,$latitud:Float,$longitud:Float){
    rutasEnArea(distancia: $distancia,latitud: $latitud,longitud: $longitud){
        id
        ubicacion{
            id
            nombre
            tipoexhib
            preciourbano
        }
        nombre
        inicio
        fin
        descripcion
        puntos(sort:"id:asc"){
            id
            lat
            lng
        }
        impactos_ruta
        reach
        frequency
        male_users
        female_users
        total_hits
        kilometraje
    }  
  }`;

export default RUTASENAREA;