/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
	IonRow,
	IonCol,
	IonPopover,
	IonButton,
	IonCard,
	IonTitle,
	IonGrid
} from "@ionic/react";
import { renderBoolean, renderCurrency } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import {
	MToolBar,
	Toolbar,
	ChartHYM,
	ChartNSE,
	ChartUsers,
	ChartGrupoEdad,
	DTableV2
} from "../../components";
import useDialog from "../../hooks/useDialog";
import { useMutation, useLazyQuery, useQuery} from "@apollo/client";
import { MEDIOS_QUERY, UPDATE_ESTADO, MEDIOS_QUERY_UPDATE, GET_IMAGE_MEDIOS_QUERY, BUSCAR_SITIO_POR_ID } from './queries/queries';
import {FormAbcMedios} from "./components";
import useAuthentication from "../../hooks/useAuthentication";
import NumberFormat from 'react-number-format';
import 'chartjs-plugin-datalabels';
import {ChartHM} from "../../components/infinia/ChartHYM";
import {Button} from "primereact/button";
import {useJSReportPDF} from '../../hooks';
import {
	MESSAGE_COMPLETE_DOWNLOAD_PDF,
	MESSAGE_INFO_DONWLOAD_PDF,
} from "../../utils/Messages";
import { InputNumber } from "primereact/inputnumber";

const Abcmedios = (props) => {

	const geoData = (data) => {
		return (
			<div>
				<IonRow>
					<IonCol size='4'>
						<ChartNSE data={data} showData={true} fontSize={10} />
					</IonCol>
					<IonCol size='4'>
						<ChartGrupoEdad data={data} showData={true} fontSize={10} />
					</IonCol>
					<IonCol size='4'>
						<ChartUsers data={data} showData={true} fontSize={10} />
					</IonCol>
					<IonCol size='4'>
						<ChartHYM data={data} showData={true} fontSize={10} />
					</IonCol>
					<IonCol size='4'>
						<div style={{ fontSize: '10px' }}>Alcance: <b><NumberFormat value={data.reach} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div><br></br>
						<div style={{ fontSize: '10px' }}>Alcance: <b>Porcentaje de personas alcanzadas dentro del universo de <NumberFormat value={data.universo} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div><br></br>
						<div style={{ fontSize: '10px' }}>Frecuencia: <b><NumberFormat value={data.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div><br></br>
						<br></br>
	
						<div style={{ fontSize: '10px' }}>Frecuencia: <b>Número promedio de veces que estuvo un grupo de personas en un mismo periodo de tiempo y ubicados dentro del radio del espacio urbano</b></div>
						<br></br>
					</IonCol>
				</IonRow>
			</div>
		)
	}

	/** 
	 * Componente JSX que renderiza el boton para imprimri el pdf de una pauta
	 * 
	 */
	const BotonFichas = (dataParam, field) => {
		return (
			<Button
				type="button"
				icon="pi pi-file-pdf"
				className="p-button-danger p-button-raised p-button-rounded"
				onClick={async () => { 
					showInfo(MESSAGE_INFO_DONWLOAD_PDF);
					fichasPDF(dataParam);
				}}></Button>
		)
	}
	// Estados usados para el PDF
	const [dataToPDF, setDataToPDF] = useState();
	const [loadingFlag, setLoadingFlag] = useState(false);

	const {_renderAsyncPautaSingleDirectDownload : renderAsyncPautaSingleDirectDownload} = useJSReportPDF();

	/**
	 * Funncion que obtiene la informacion de las imagenes en Base64
	 * y manda llamar la funcion que crea el PDF para el usuario.
	 * @param props Data que se enviara para el PDF
	 */
	const fichasPDF = async (props) => {
		setDataToPDF(props);
		setLoadingFlag(true);
		return new Promise(() => {
			const id = props.id;
			// LazyQuery que obtine las imagenes y cuando termina llama a la funcion jsreportPDF
			getImageMedioById({
				variables: {
					id: parseInt(id)
				}
			});
			console.log('FIN promesa');
		});
	}
	
	
	/**
	 * Funcion que se encarga de mandar un request al servidor jsReport de IMJMedia
	 * el cual creara el PDF para el usuario y descargara de forma autoamtica.
	 * @param dataImage {JSON} objeto que contiene las imagenes vista e imagenUbicacion en base64 
	 */
	const jsreportDownload = async (dataImage) => {
		// console.log('jsreportDownload', dataImage);
		let dataJSON: any = dataToPDF;
		Object.assign(dataJSON, {Vista_media: dataImage.medio.Vista_media})
		Object.assign(dataJSON, {imagenmapaubicacion: dataImage.medio.imagenmapaubicacion})
		const clave = dataJSON.clave;
		// let fileName = 'Pautas-' + clave + '.pdf';
		let fileName = 'Pautas-' + clave;

		try {
			const  responsePDF = await renderAsyncPautaSingleDirectDownload(dataJSON, fileName, showError);
			if (responsePDF) {
				showSuccess(MESSAGE_COMPLETE_DOWNLOAD_PDF);
			}
			setLoadingFlag(false);
		} catch (error) {
			console.log('abcMedios.tsx => jsreportDownload() =>', error)
			setLoadingFlag(false);
		}
	};

	const cambiarEstatus = (e) =>{
		if(e.target.name==='iluminacion'){
			cambiarIluminacion(parseInt(e.target.id),e.target.value)
		}
		if (e.target.name==='disponible'){
			cambiarDisponibleMedio(parseInt(e.target.id),e.target.value)
		}
		if (e.target.name==='activo'){
			CambiarEstatusActivo(parseInt(e.target.id),e.target.value)
		}
		if (e.target.name==='bloqueado'){
			cambiarBloqueadoMedio(parseInt(e.target.id),e.target.value)
		}
		if (e.target.name==='especiales'){
			cambiarEspecialesMedio(parseInt(e.target.id),e.target.value)
		}
	}

	///////////////////
	const columnas = [
		{
			header: "Clave",
			field: "clave",
			sortable: true,
			filter: true,
			filterMatchMode: "contains",
			style:{width:'150px !important'}
		},
		{
			header: "Tipo",
			field: "tipos.nombre",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Proveedor",
			field: "proveedor.nombre_comercial",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Clave Proveedor",
			field: "claveproveedor",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Latitud",
			field: "latitud"
		},
		{
			header: "Longitud",
			field: "longitud"
		},
		{
			header: "Direccion",
			field: "direccion",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Ubicación",
			field: "ubicacion.nombre",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Referencias",
			field: "referencias",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Nicho",
			field: "nicho",
			sortable:true,
			filter:true,
			filterMatchMode: "contains"
		},
		{
			header: "Fecha Creado",
			field: "created_at",
			sortable:true,
			filter:false,
			filterMatchMode: "contains"
		},
		{
			header: "Iluminación",
			field: "iluminacion",
			body: renderBoolean,
			customChange: cambiarEstatus,
			sortable: true
		},
		{
			header: "Medida",
			field: "medida",
			sortable: true
		},
		{
			header: "Material",
			field: "material",
			sortable: true
		},
		{
			header: "Disponible",
			field: "disponible",
			body: renderBoolean,
			customChange: cambiarEstatus,
			sortable: true
		},
		{
			header: "Activo",
			field: "activo",
			body: renderBoolean,
			customChange: cambiarEstatus,
			sortable: true
		},
		{
			header: "Bloqueado",
			field: "bloqueado",
			body: renderBoolean,
			customChange: cambiarEstatus,
			sortable: true
		},
		{
			header: "Comentarios",
			field: "comentarios"
		},
		{
			header: "Costo",
			field: "costo",
			body: renderCurrency,
			filter: true
		},
		{
			header: "Precio",
			field: "precioventa",
			body: renderCurrency,
			filter: true
		},
		{
			header: 'Implementaciones Especiales',
			field: "especiales",
			body: renderBoolean,
			customChange: cambiarEstatus
		},
		{
			header: "Alcance",
			body: ChartHM,
			exportable: false,
			style: { width: '180px' }
		},
		{
			header: 'Ficha',
			body: BotonFichas
		}
	];
	//////////////////


	const [titleHeader, settitleHeader] = useState("Titulo");
	const [refresh, setRefresh] = useState(false);
	const { usuarioid, rol } = useAuthentication();
	// const [datos, setdatos] = useState({});
	const [expandedRows, setExpandedRows] = useState();

	const {
		notification,
		showSuccess,
		showInfo,
		showError
	} = useNotification();

	const [updateMedio] = useMutation(UPDATE_ESTADO, {
		onCompleted(data) {
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});

	const { showDialog, FormDialog } = useDialog();

	const [traerMedio] = useLazyQuery(MEDIOS_QUERY_UPDATE, {
		onCompleted: (data) => {
			showDialog(data.medios[0], "detalles");
		}
	});

	const [editarMedio] = useLazyQuery(MEDIOS_QUERY_UPDATE, {
		onCompleted: (data) => {

			if (data.medios[0].Createdby === undefined || data.medios[0].Createdby === null) {
				data.medios[0].Createdby = usuarioid();
			} else {
				data.medios[0].Createdby = parseInt(data.medios[0].Createdby.id);
			}

			data.medios[0].Modifiedby = usuarioid();
			showDialog(data.medios[0], "editar");
		}
	})

	/** LazyQuery que obtiene las imagenes en Base64 de una pauta en especifico y ejecuta una funcion cuando termina*/
	const [getImageMedioById] = useLazyQuery(GET_IMAGE_MEDIOS_QUERY, {
		onCompleted: (data) => 
			jsreportDownload(data),
		onError: (res) => {
			console.log('ERROR **********', res);
		}
	});

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */
	const CambiarEstatusMedios = (data) => {
		if (data) {
			updateMedio({
				variables: {
					input: {
						where: {
							id: data.id
						}, data: {
							activo: !data.activo
						}
					}
				}
			})
		}
	};
	/*
	* Se cambia al presionar el switch
	*/
	const cambiarDisponibleMedio = (id,disponible) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  disponible:disponible
						}
					}
				}
			})
		}
	}

	const cambiarBloqueadoMedio = (id,bloqueado) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  bloqueado:bloqueado
						}
					}
				}
			})
		}
	}

	const cambiarEspecialesMedio = (id, especiales)=>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  especiales:especiales
						}
					}
				}
			})
		}
	}

	const CambiarEstatusActivo =(id,activo) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  activo:activo
						}
					}
				}
			})
		}
	}

	const cambiarIluminacion=(id, iluminacion)=>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
							iluminacion:iluminacion
						}
					}
				}
			})
		}
	}
	/**
	 * Mostrar la forma de captura/edición en un dialog
	 * showDialog(datos para el formulario, operación)
	 * operación puede ser crear/editar
	 */
	const [popoverState, setShowPopover]=useState({showPopover: false, event: undefined})
	const [latB, setLatB]=useState(0)
	const [lngB, setLngB]=useState(0)
	const [mediosFound, setMediosFound]=useState([])
	const addMedio = () => {
		//showWarn("Título", "Mensaje de la notificación");
		setShowPopover({showPopover:true, event:"Búsqueda por coordenadas"})
		// let data = { Createdby: usuarioid(), Modifiedby: usuarioid(), disponible: true, activo: true, nicho:"" }
		// showDialog(data, "crear");
		// settitleHeader('Crear nuevo sitio');
	};

	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) => {

		//showDialog(data,'editar');
		//setdatos(data);

		editarMedio({
			variables: {
				where: {
					id: parseInt(data.id)
				}
			}
		})
		settitleHeader('Editar informaciòn del sitio');
	}

	const onView = (data) => {
		//Aquí el lazy query
		traerMedio({
			variables: {
				where: {
					id: parseInt(data.id)
				}
			}
		})
		settitleHeader('Detalles del sitio');
	}

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada', 'Se abortó correctamente la operación')
	}

	const [buscaCoord]=useLazyQuery(BUSCAR_SITIO_POR_ID,{
		onCompleted(data){
			console.log(data.medios);
			setMediosFound(data.medios)
		}, onError(error){
			console.log("ERROR: ", error)
		}
	})

	const validacion=()=>{
		if(mediosFound.length===0){
			return(
				<IonCol>
					<h4>No hay coincidencias</h4>
				</IonCol>
			)
		}
		else{
			return(
				<>
					<IonGrid>
						<IonRow>
							<IonTitle>Claves</IonTitle>
						</IonRow>
					{mediosFound.map(medio =>
						<IonRow>
							<IonCol key={medio.clave}>
								{medio.clave}
							</IonCol>
						</IonRow>
					)}
					</IonGrid>
				</>
			)
		}
	}

	return (
		<IonPage>
			<IonHeader>
				<Toolbar
					title={props.title}
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent style={{overflow:'scroll'}}>
				{notification}
				<MToolBar showCart={true} addClick={addMedio}/>
				<div  >
					<div style={{width: '500px', height: '500px', display: 'none'}} id='placeholder'></div>
					<DTableV2
						filtro={{'tipos.tipomedio.nombre':'OOH'}}
						autoLayout={true}
						paginable={true}
						// ordenarPor={'id:desc'}
						ordenarPor={'created_at:desc'}
						query={MEDIOS_QUERY}
						style={{ margin: "15px" }}
						columnas={columnas}
						element="medios"
						loadingFlag={loadingFlag}
						onDelete={CambiarEstatusMedios}
						canAdd={true}
						canView={true}
						canDelete={true}
						canEdit={true}
						onEdit={onEdit}
						onView={onView}
						refresh={refresh}
						onEndRefresh={() => setRefresh(false)}
						rowExpansionTemplate={geoData}
						expandedRows={expandedRows}
						onRowToggle={(e) => setExpandedRows(e.data)}
						responsive={false}
					/>
				</div>
				<IonPopover
					event={popoverState.event}
					isOpen={popoverState.showPopover}
					onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
				>
					<IonCard>
						<IonTitle>
							Búsqueda de sitios por coordenadas
						</IonTitle>
						<br/>
						<IonGrid>
							<IonRow>
								<IonCol>
									<span className="p-float-label">
										<InputNumber
											name="latitud"
											value={latB}
											locale="en-US"
											mode="decimal"
											minFractionDigits={2}
											maxFractionDigits={8}
											style={{ width: "100%", borderRadius: '25px'}}
											required
											onChange={e => {
												console.log(e.value)
												setLatB(e.value)
											}}
										/>
										<label htmlFor="in">Latitud</label>
									</span>
								</IonCol>
								<IonCol>
									<span className="p-float-label">
										<InputNumber
											name="longitud"
											value={lngB}
											locale="en-US"
											mode="decimal"
											minFractionDigits={2}
											maxFractionDigits={8}
											style={{ width: "100%", borderRadius: '25px'}}
											required
											onChange={e => {
												console.log(e.value)
												setLngB(e.value)
											}}
										/>
										<label htmlFor="in">Longitud</label>
									</span>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton
										color="secondary"
										onClick={()=>
											buscaCoord({
												variables:{
													where:{
														_and:[
															{latitud_contains:latB},
															{longitud_contains:lngB}
														]
													}
												}
											})
											
										}
									>
										Buscar coincidencias
									</IonButton>
								</IonCol>
							</IonRow>
							<IonRow>
								{validacion()}
							</IonRow>
							<IonRow>
								<IonCol>
									<IonButton 
										color="success"
										onClick={()=>{
											let data = { Createdby: usuarioid(), Modifiedby: usuarioid(), disponible: true, activo: true, nicho:"" }
											showDialog(data, "crear");
											settitleHeader('Crear nuevo sitio');
											setShowPopover({showPopover: false, event: 'e'})
										}}
										disabled={(latB && lngB) ? false : true}
									>
										Continuar Captura
									</IonButton>
								</IonCol>
								<IonCol>
									<IonButton 
										color="warning"
										onClick={()=>{
											setShowPopover({showPopover: false, event: 'e'})
											setLatB(0)
											setLngB(0)
										}}
									>
										Cancelar
									</IonButton>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonCard>
				</IonPopover>
				<FormDialog
					header="Alta/Edición de Sitios"
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormAbcMedios />

				</FormDialog>
			</IonContent>
		</IonPage>
	);
}

export default Abcmedios;
