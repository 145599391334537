import React, {useEffect, useState} from "react";
import {
    IonCol,
	IonGrid, IonRow
} from "@ionic/react";
import useAuthentication from "../../hooks/useAuthentication";
import useDialog from "../../hooks/useDialog";
import {DataTable} from  "primereact/datatable";
import { IonSpinner } from '@ionic/react';
import { Dropdown } from 'primereact/dropdown'
import {
    LISTA_PAUTAS,
    LISTA_PRODUCTOS
} from "./queries"
import { useLazyQuery, useQuery } from "@apollo/client";
import { TabPanel, TabView } from 'primereact/tabview';
import TablaDetalles from "./TablaDetalles";
import TablaTestigos from "./TablaTestigos";
import TablaODC from "./TablaODC";
import TablaSitios from "./TablaSitios";
import TablaRutas from "./TablaRutas";
import Presentacion from "./Presentacion";

const Tabulador=(props)=>
{
    const [activeIndex, setActiveIndex] = useState(0);
    const {usuarioid, rol} = useAuthentication();


    const detallesPauta=()=>{
        if(props.pauta)
        {
            
            if(props.detalles.length>0){
                return(
                    <TablaDetalles data={props.detalles}/>
                )
            }
            else{
                return (<h2>La pauta no tiene detalles</h2>)
            }
        }
        else{
            return(<h2>No hay campaña seleccionada</h2>)
        }
    }

    const detallesTestigos=()=>{
        if(props.pauta)
        {
            return(<TablaTestigos data={props.detalles} pauta={props.pauta}/>)
        }
        else{
            return(<h2>No hay campaña seleccionada</h2>)
        }
    }

    const odc=()=>{
        if(props.pauta)
        {
            return (<TablaODC data={props.detalles} pauta={props.pauta}/>)
        }
        else{
            return(<h2>No hay campaña seleccionada</h2>)
        }
    }

    const tablaSitios=()=>{
        if(props.pauta){
            if(props.detalles.length>0){
                if(props.detalles[0].medio && props.detalles[0].medio!==null){
                    return(<TablaSitios data={props.detalles} pauta={props.pauta}/>)
                }
                else{
                    return(<h2>No hay sitios en la campaña seleccionada</h2>)
                }
            }
            if(props.detalles.length===0){
                return(<h2>No hay detalles en la campaña seleccionada</h2>)
            }
            
        }
        else{
            return (<h2>No hay campaña seleccionada</h2>)
        }
    }

    const tablaRutas=()=>{
        if(props.pauta){
            if(props.detalles.length>0){
                if(props.detalles[0].ruta && props.detalles[0].ruta!==null){
                    return(<TablaRutas data={props.detalles} pauta={props.pauta}/>)
                }
                else{
                    return(<h2>No hay urbanos en la campaña seleccionada</h2>)
                }
            }
            if(props.detalles.length===0){
                return(<h2>No hay detalles en la campaña seleccionada</h2>)
            }
            
        }
        else{
            return (<h2>No hay campaña seleccionada</h2>)
        }
    }

    const present=()=>{
        if(props.pauta){
            return(<Presentacion pauta={props.pauta}/>)
        }
        else{
            return(<h2>No hay campaña seleccionada</h2>)
        }
    }

    const TabAdmin=(props)=>{
        return(
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel header="Sitios" disabled={false}>
                        {tablaSitios()}
                    </TabPanel>
                    <TabPanel header="Rutas" disabled={false}>
                        {tablaRutas()}
                    </TabPanel>
                    {/* <TabPanel header="Detalle de Pauta">
                        <IonGrid>
                            {detallesPauta()}
                        </IonGrid>
                    </TabPanel> */}
                    <TabPanel header="Testigos">
                        {detallesTestigos()}
                    </TabPanel>
                    <TabPanel header="Orden de compra">
                        {odc()}
                    </TabPanel>
                    <TabPanel header="Presentación">
                        {present()}
                    </TabPanel>
            </TabView>
        )
    }
    const TabCliente=(props)=>{
        return(
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Sitios" disabled={false}>
                    {tablaSitios()}
                </TabPanel>
                <TabPanel header="Rutas" disabled={false}>
                    {tablaRutas()}
                </TabPanel> 
                <TabPanel header="Detalle de Pauta">
                    {detallesPauta()}
                </TabPanel>
                <TabPanel header="Testigos">
                    {detallesTestigos()}
                </TabPanel>
                <TabPanel header="Presentación">
                    {present()}
                </TabPanel>
            </TabView>
        )
    }
	
    const Tabuladores=(props)=>{
        if(rol()===('Administrador') || rol()===('Operaciones') || rol()===('Operaciones Urbanos') || rol()===('Operaciones Fijos') || rol()===('Creativos'))
        {
            return <TabAdmin/>
        }
        else
            return (
                <TabCliente/>
            )
            
    }
    return(
        Tabuladores(props)
    )
} 
export default Tabulador;