import React from 'react'

const ImagenSitio = (props) => {
    return (
        <div id='imagenSitio' style={{width:props.width, 
            backgroundImage: 'url("' + props.imagen +'")',
            backgroundSize: 'cover',
            height:props.height, 
            left:props.left, 
            right:props.right, 
            top:props.top, 
            position:'absolute',
            borderColor: 'black'}}>
            {/* <img  alt='' src={props.imagen} style={{height: '100%'}}/> */}
        </div>
    )
}

export default ImagenSitio;
