import React, { useState, useEffect } from "react";
import { IonCardContent, IonCard, IonPopover, IonGrid, IonRow, IonCol} from "@ionic/react";

const VistaTestigoUnico=(props)=>{
    console.log("PROPS", props.row)
    var imagen
    if(props.row){
        imagen=props.row.imagen.formats.large.url
        //imagen=props.row.imagen.url
    }
    console.log(imagen)
    const cierraPopover = () => {
        props.onClose(props)
    }
    return(
        <IonPopover isOpen={props.visible} animated={true} onDidDismiss={cierraPopover} cssClass='infowindow'>
            <IonCard>
                <IonCardContent>
                    <IonGrid>
                        <IonRow className="ion-align-items-center">
                            <IonCol className="ion-align-center" style={{ marginTop: '8px', marginLeft:'100px'}}>
                                <p>
                                <img src={"https://services.imjmedia.com.mx"+imagen} alt={props.row ? props.row.imagen.name : "IMAGEN NO ENCONTRADA"}/>
                                </p>
                            </IonCol>
                        </IonRow> 
                    </IonGrid>
                    
                </IonCardContent>
            </IonCard>
        </IonPopover>
    )
}
export default VistaTestigoUnico;