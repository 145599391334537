/* eslint-disable no-unused-vars */
import { useQuery } from '@apollo/client';
import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import ChartGrupoEdad from '../../components/infinia/ChartGrupoEdad';
import ChartNSE from '../../components/infinia/ChartNSE';
import {
    CuadrosFicha,
    CuadroSinBorde,
    DatosInfinia,
    Footer,
    ImagenSitio,
    Logotipo,
    TituloSitio,
    ImagenMapa,
} from './components/';
import {MEDIOS_QUERY_WHERE, RUTAS_QUERY_WHERE, NEW_RUTAS_QUERY_WHERE} from './querys';
import styles from  './ficha.module.css';
import { Button } from 'primereact/button';
import ReactLoading from 'react-loading';
import {JS_REPORT_PASSWORD, JS_REPORT_TEMPLEATE_PAUTA_PATH, JS_REPORT_USER, URL_JS_REPORT_DEVELOP, URL_JS_REPORT_IMJ} from '../../utils/Constanst'
import { useJSReportPDF, useNotification } from '../../hooks';
import { MESSAGE_COMPLETE_DOWNLOAD_PDF } from '../../utils/Messages';
const jsreport = require("jsreport-browser-client-dist");

const FichaSitiosMultiplesPdf = (props)=>{
    const {_renderAsyncMultipleDirectDownload : renderAsyncMultipleDirectDownload, _renderAsyncPDFUrbanosMultipleDirectDownload: renderAsyncPDFUrbanosMultipleDirectDownload} = useJSReportPDF();
    const {
        notification,
        showError,
        showSuccess,
    } = useNotification();


	/**@type {string} */
    let campania = props.match.params.campania;

    /**@type {string} */
    const parametros = props.match.params.id;
    let listIds = [];
    if (parametros.includes(',')) {
        listIds = parametros.split(',');
    } else {
        listIds.push(parametros);
    }

    const [linkURL, setLinkURL] = useState('#');
    const [flag, setFlag] = useState(true);
    const [loadingFlag, setLoadingFlag] = useState(true);

    // const {data, loading} = useQuery(MEDIOS_QUERY_WHERE,{
    //     variables:{
    //         where: {
    //             id_in: listIds
    //         }
    //     }
    // }); 
    const data = [];
    const {data: dataRutas, loading: loadingRutas} = useQuery(NEW_RUTAS_QUERY_WHERE,{
        variables:{
            where: {
                id_in: listIds
            }
        }
    });
    const urlMapa = "http://google.com/maps/place/";

    

    /**
     * Funcion que hace un request de forma asincrona al template 
     * especifico para este formato de pauta en el servidor de JS Report 
     */
    const renderAsync = async () => {
        // Validacion de nombre
        let fileName = '';
        if (campania === '*') {
            // fileName = 'Pautas-IMJ.pdf';
            fileName = 'Pautas-IMJ';
        } else {
            // fileName = 'Pautas-' + campania + '.pdf';
            fileName = 'Pautas-' + campania;
        }
        console.log('renderAsync data', dataRutas);
        // const  responsePDF = await renderAsyncMultipleDirectDownload(dataRutas, fileName,showError);
        const responsePDF = await renderAsyncPDFUrbanosMultipleDirectDownload(dataRutas, fileName, showError);
		if (responsePDF) {
			showSuccess(MESSAGE_COMPLETE_DOWNLOAD_PDF);
		}
		setLoadingFlag(false);
    }

    const consoleData = () => {
        console.log('dataRutas', dataRutas)
    }; 

    /**
     * Funcion que abre una ventana al recipiente de pdf
     * de JS report en el server de IMJ
     * con el PDF listo para ver y descargar
     */
    const goToLinkPDF = () => {
        window.open(linkURL)
    };
    
    /**
     * Componente contenedor con estilos para un elemento PDF individual
     */
    const PDFViewNewFormat = () => {
        const cuadroFichaWeigth = '160px';
        const leftPos = '130px';
        const leftPosLogo = '80px';
        const specialFontSize = '9.5px';
        const grapsTop = '3.6in';
        const dataBelowGraps = '5.9in';
        const topImageMap = '660px';
        const leftPosMap = '832px';
        const divInfoWidth = 550;
        return (
            <div id='container' style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}}>
                <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}} id='toPDF' >
                    {!loadingRutas && dataRutas && dataRutas.rutas && 
                        dataRutas.rutas.map((ruta, index) => (
                            <div id={'toPDF-'+index} key={index} className={styles.principalMultiple}>
                                <Logotipo top='0px' left={leftPosLogo} width='355.6px' height='200px' />
                                <TituloSitio height='100px' width='270px' top='200px' left={leftPos} sitio={ruta.clave} tipo={ruta.nombre}/>

                                {/* <ImagenMapa width='200px' height='100px' left={leftPosMap} top={topImageMap} imagen={ruta.imagenmapaubicacion}/> */}

                                {/* UBICACION */}
                                {/* <CuadrosFicha title="Ubicación" top='600px' left='13px' width='260px' height={cuadroFichaWeigth}>
                                    <b>Dirección:</b> {ruta.direccion}<br></br>
                                    Colonia: {ruta.colonia}<br></br>
                                    Ciudad y Estado: {ruta.ubicacion && ruta.ubicacion.nombre},{ruta.ubicacion && ruta.ubicacion.estado}<br></br>
                                    Vialidad: {ruta.vialidad}<br></br>
                                    <br></br>
                                </CuadrosFicha> */}

                                <CuadrosFicha title="Ubicación" top='600px' left='13px' width='260px' height={cuadroFichaWeigth}>
                                    inicio: {ruta.inicio}<br></br>
                                    fin: {ruta.fin}<br></br>
                                    kilometraje: {ruta.kilometraje}<br></br>
                                    Ciudad y Estado: {ruta.ubicacion && ruta.ubicacion.nombre},{ruta.ubicacion && ruta.ubicacion.estado}<br></br>
                                    <br></br>
                                </CuadrosFicha>
                                
                                
                                {/* ANTERIOR */}
                                {/* <CuadrosFicha title="Bloque Datos" top='600px' left='286px' width='260px' height={cuadroFichaWeigth}>
                                    Medidas: {ruta.medida}<br></br>
                                    Material: {ruta.material}<br></br>
                                    Acabado: {ruta.tipoacabado}<br></br>
                                    Iluminación: {ruta.iluminacion?'Si':'No'}<br></br>
                                    Tipo de vista: {ruta.vista && ruta.vista.nombre}<br></br>
                                    Nicho: {ruta.nicho}<br></br>
                                    <br></br>
                                </CuadrosFicha> */}
                                
                                <CuadrosFicha title="Bloque Datos" top='600px' left='286px' width='260px' height={cuadroFichaWeigth}>
                                    
                                    activo: {ruta.activo?'Si':'No'}<br></br>
                                    clave: {ruta.clave}<br></br>
                                    impactos_ruta: {ruta.impactos_ruta}<br></br>
                                    <br></br>
                                </CuadrosFicha>
                                
                                <CuadrosFicha title="Derrotero" top='600px' left='559px' width='260px' height={cuadroFichaWeigth}>
                                    Descripcion Comercial: {ruta.descripcion}<br></br>
                                </CuadrosFicha>

                                {/* <CuadrosFicha color='#000' title="Coordenadas" top='600px' left='832px' width='200px' height={cuadroFichaWeigth}>
                                    <a href={urlMapa + ruta.ubicacion.lat.toString() +"," + ruta.ubicacion.lng.toString()}>Lat: {ruta.ubicacion.lat} <br></br>Long:{ruta.ubicacion.lng}</a>
                                </CuadrosFicha> */}




                                



            
                                <CuadroSinBorde title="Datos de mercado" top='350px' right='25px' width='200px' height='250px'>
                                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 5, marginBottom:10}}>
                                        Impactos: <b><NumberFormat value={ruta.total_hits} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b><br/><br/>
                                    </div>
                                    <img src='/assets/infinia/man.png' alt='' height={90} /><b><NumberFormat value={ruta.male_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b>
                                    <img src='/assets/infinia/woman.png' alt='' height={90} /><b><NumberFormat value={ruta.female_users} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b><br/><br/>
                                    <div style={{marginBottom: 5}}>Alcance: <b><NumberFormat value={ruta.reach*100} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>
                                    <div>Frecuencia: <b> <NumberFormat value={ruta.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div>
                                    <br></br>
                                    <div className='.fontSizeCuadroSinBorde' style={{fontSize: '9.5px', marginTop: 3}}>*Datos actualiados mensualmente</div>
                                </CuadroSinBorde>

                                {/* <DatosInfinia title="Datos Geográficos" top={grapsTop} left='-0.8cm' width='11cm' height='9.5cm'>
                                    <ChartGrupoEdad data={ruta} fontSize={10}/>
                                </DatosInfinia> */}
                                {/* <DatosInfinia title="Datos Geográficos" top={grapsTop} left='9.5cm' width='11cm' height='6.5cm'>
                                    <ChartNSE data={ruta} fontSize={10}/>
                                </DatosInfinia> */}

                                <CuadroSinBorde title="Datos de mercado footer" top={dataBelowGraps} left='10px' width='750px' height='100px'>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}> 
                                        <div style={{width: divInfoWidth}}>
                                            <div style={{fontSize: specialFontSize}}>Alcance: <b>Porcentaje de personas alcanzadas dentro del universo poblacional total de la plaza</b></div>
                                        </div>
                                        
                                        <div style={{width: divInfoWidth}}>
                                            <div style={{fontSize: specialFontSize}}>Frecuencia: <b>Número promedio de veces que estuvo un grupo de personas en un mismo periodo de tiempo y ubicados dentro del radio del espacio urbano</b></div>                                
                                        </div>
                                    </div>
                                    
                                </CuadroSinBorde>

                                <Footer className={styles.footer}/> 
                                <ImagenSitio
                                    width='561.78px' 
                                    height='316px'
                                    right='30px'
                                    top='24px' imagen={ruta.ubicacion.imagenexhib}/>
                                    <div style={{breakAfter: 'always'}}></div>
                                    <div style={{pageBreakAfter: 'always'}}></div>
                            </div>
                        ))
                    }
                </div>
            </div>
          );
    };    
    // if (!loading) {
    //     if (flag) {
    //         setFlag(false);
    //         renderAsync();
    //     }
    // }

    return (
        <IonPage>
            <IonContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}} slot='fixed'>
                <div style={{display: 'flex', flexDirection:'column', paddingTop: 20, alignItems: 'center', justifyContent: 'center', justifyItems: 'center'}} >
                    {notification}
                    <PDFViewNewFormat />
                    <div style={{width: '500px', height: '500px', display: 'none'}} id='placeholder'></div>
                        <div>
                            {JSON.stringify(dataRutas)}
                        </div>
                        {loadingRutas ? (
                            <div style={{marginLeft: 20}}>
                                <ReactLoading 
                                    // height={50}
                                    // width={50}
                                    color={'#fcb32b'}
                                    type={'spin'}
                                />
                        </div>) : null}
                    <Button className={styles.closeButton}  onClick={renderAsync}>
                        {'Descargar PDF'}
                    </Button>

                    
                </div>                
                
            </IonContent>
        </IonPage>
    )
}

export default FichaSitiosMultiplesPdf;
