import React from 'react'

const CuadroSinBorde = (props) => {
    return (
        <div style={{width:props.width, 
            height:props.height, 
            left:props.left, 
            right:props.right, 
            top:props.top, 
            position:'absolute',
            fontSize: props.fontSize || '14px'}}>
            <div id='mercado' style={{marginLeft:'5px'}}>{props.children}</div>
        </div>
    );
}

export default CuadroSinBorde;
