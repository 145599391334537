import React from 'react';
import { IonPopover } from '@ionic/react';
import { VERSION } from '../../utils/Constanst';
import './NotaVersion.css';

/**
 * 
 * @param {Object} props
 * @param {boolean} props.isOpen
 * @param {() => void} props.event
 * @param {({showPopover: boolean, event: any}) => void} props.onDidDismiss 
 * @returns 
 */
const NotasVersion = ({
  event,
  isOpen,
  onDidDismiss,
}) => {

  const NOTAS = [
    "1.14.0",
    "Corrección a decarga de pdf",
    "1.13.0",
    "Se agrega dispo a campañas urbanos",
    "1.12.3",
    "Se modifica el formato de captura de los sitios indoor",
    "Formato de validación antes de capturar un sitio para saber si hay algo en x y y coordenadas",
    "Cambio en campañas urbanos, se toma en cuenta el último día del mes y no el primero para validar que no se cobren retroactivos",
    "1.12.2",
    "Cambios en Excel Medios: Arroja totales de data Infinia en rutas",
    "1.12.1",
    "Cambio de logotipo OOH Bit Planner",
    "Si hay algo en memoria y se desea trabajar en pauta, se pondrá un aviso",
    "1.12.0",
    "Reconstrucción de Campañas de Urbanos",
    "Liberación de módulo InDoor",
    "1.11.0",
    "Cambio en logotipo OOH Bit Planner",
    "Módulo InDoor, donde se darán de alta Centros Comerciales, Trenes Suburbanos, Aeropuertos",
    "Módulo ABC Sitios InDoor, donde se darán de alta los sitios de cada InDoor del módulo anterior.",
    "De momento, sólo se capturan y revisan, no se pueden agregar a pautas ni mostrar en mapa. Pendiente para versiones futuras.",
    "1.10.3",
    "Se agrega checkbox a campañas urbanos para mostrar u ocultar ecos desactivados",
    "Círculos muestran nombre al colocar cursor sobre él",
    "Panel de precios muestra nombre del círculo que está mostrando",
    "PDF de fichas técnicas organiza por plaza los sitios seleccionados",
    "1.10.2",
    "Cambios en Excel Medios: Arroja totales de data Infinia",
    "1.10.1",
    "Refactorización de Campañas Urbanos",
    "Se agregan filtros por permisionarios y rutas",
    "Filtro de campañas muestra las campañas antes y después de la seleccionada",
    "Cambio en el resumen anual, puede verse el año",
    "Mejora del algoritmo que obtiene el corte",
    "1.9.2",
    "Bug reparado que duplicaba rutas",
    "Primera integración con Indoor, en tipos ya es posible dar de alta tipos Indoor",
    "Se crea tabla indoor amarrada a medio",
    "Botón de estatus dinámico en Tipos",
    "Al dar de alta un medio, el tipo incluye concatenado el tipomedio (OOH/InDoor)",
    "1.9.1",
    "Se repara error en editar pauta con rutas",
    "Nuevo botón para descargar fotos individuales de testigos",
    "Cambio de estilo en subir presentación",
    "Slider de múltiples testigos",
    "Columna de número de reporte para mayor control de campañas con duración de más de un mes",
    "Cambio de estilo de botón para subir testigos",
    "1.9.0",
    "Se integra módulo de testigos a la plataforma.",
    "1.8.14",
    "Se desactiva el menú contextual de sistema que aparecía en los círculos",
    "1.8.13",
    "Se agregan los estadísticos para precio y costo",
    "1.8.12",
    "Se agrega validación para que no se retrabajen pautas listadas como perdidas",
    "ABC Sitios muestra advertencia si se intenta duplicar una clave de sitio",
    "Se soluciona error que impedía ver tipos de exhibición en Ubicaciones",
    "Se agrega validación de círculos en botón de Excel Rutas",
    "Notificación al borrar un registro en campañas urbanos",
    "Se agrega borrado para registros e incidencias en campañas urbanos",
    "Solucionado bug en filtro de permisionarios",
    "1.8.11",
    "Se agrega botón que muestra unidades vendidas en campañas urbanos dada una fecha",
    "Corregido bug de Calendarización de campañas en el conteo de campañas activas",
    "Excel Details y Excel Rutas muestran elementos ordenados por plaza",
    "Se añade número de unidades a columna de permisionario",
    "Cambio de color de eventos en Calendarización de Campañas",
    "Columna 'Fecha de creación' agregada a Listado de Pautas'",
    "1.8.10",
    "Añadida columna de sobrevendidos",
    "Se agrega botón de resumen anual de campañas urbanos con opción de descarga y PDF",
    "Cambio de formato Excel Medios",
    "Cambio en las fórmulas de campañas urbanos",
    "Resumen anual muestra merma total anual",
    "1.8.9",
    "Liberación de campañas urbanos con las siguientes mejoras: ",
    "   ->Filtro para visualizar la merma en el calendario",
    "   ->Filtro de campañas",
    "   ->Edición de campañas por arrastre",
    "   ->Edición de duración de campañas por formulario",
    "   ->Correción de cálculos de merma y unidades con incidentes",
    "   ->Filtro por permisionario, eco, ruta",
    // "1.8.8",
    // "Se agrega la posibilidad de editar rutas a través de la importación de KML",
    // "1.8.7",
    // "Filtro de campañas en Campañas Urbanos",
    // "Edición mediante arrastre de las campañas Urbanos",
    // "Filtro de campañas también trae sólo los ecos involucrados con esa campaña",
    // "1.8.6",
    // "Persistencia de sitios",
    // "1.8.5",
    // "Se agrega tablero de negociaciones activas",
    // "1.8.4",
    // "Corrección de error en edición de pautas que no permitía guardar",
    // "Se implementa descarga de corte en PDF",
    // "Edición de Permisionarios solo autorizado a Administrador",
    // "Se agrega el número de unidades que cada permisionario tiene al Excel y PDF",
    // "1.8.3",
    // "Se implementa la descarga de corte en Excel",
    // "1.8.2",
    // "Se corrige esquema de nombrado de archivos de fichas",
    // "Se cambia en ABC Sitios el campo Proveedor que mostraba el nombre del contacto para que mueste el nombre del proveedor",
    // "1.8.1",
    // "Se agrega pantalla de lectura de datos a los DTable (Grids)",
    // "1.8.0",
    // "Se recontruye el componente DTable para poder exportar datos paginados",
    // "Se corrigen errores menores sintacticos",
    // "1.7.7",
    // "Se agrega filtro de ecos en calendarización de campañas en urbanos y se cambia el idioma de los encabezados",
    // "1.7.6",
    // "Calendarización de campañas con relación a los ECO",
    // "Se implementa un multi-select para asociar múltiples ECO por cada operación, primera versión",
    // '1.7.5',
    // "Se agrega la opción KPI's y los tabs correspondientes para Administrador y Operaciones Fijos",
    // '1.7.4',
    // 'Se habilita el botón Activo/Inactivo de Rutas con función',
    // 'Se agrega dashboard de Google Data Studio',
    // 'Se quita la opción de editar plazas',
    // 'Calendario muestra campañas activas en mes actual (pendiente meses siguientes y anteriores)',
    // 'Se añade funcionalidad al botón de activo/inactivo en Rutas',
    // 'Fix de pautas',
    // 'Se añade filtro de campañas, lo que agiliza la búsqueda en campañas urbanos',
    // 'Se agrega módulo campañas camiones urbanos para llevar control de campañas contra números ECO',
    // 'Descuento global para pauta y tope de 40% en descuentos',
    // 'Se agrega el módulo "Campaña Urbanos" para asignar campaña a cada camión urbano con su respectivo ECO',
    // 'Se agrega campo de costo en el grid de los permisionarios',
    // 'Se agrega campo "activo" en grid de Unidades Urbanos y se retira campo de Número de Unidad',
    // 'Grid de Ubicaciones ya no trae columna de imágenes',
    // 'Cambio de estilos en Dropdown calendarios',
    // 'Se agrega el campo "Implementaciones especiales" a los sitios',
    // 'Calendarización de campañas tiene opción de visualizar sólo el inicio o solo el fin de las campañas',
    // 'Nuevo rol de Operaciones para quienes manejen urbanos y fijos',
    // 'Cambio en el orden del menú',
    // 'Pautas muestran quién creó y modificó una pauta',
    // 'Corrección de calendario, muestra sólo las ganadas',
    // 'Creación de módulo de calendarización, el cual muestra un calendario con las pautas creadas y permite seleccionar una o todas para consulta exclusivamente',
    // 'Cambios en formato Excel Medios',
    // 'Se corrige error que impedía ver pautas a Operaciones',
    // 'Las campañas vienen de Bitrix, por lo que a partir de ahora se trabajarán desde edición',
    // 'El formulario de clientes se limita, de manera que sólo se puede agregar contraseña para crear usuario cliente',
    // 'Las campañas se ligan a pautas',
    // 'Se agrego un wizard para que facilite la creacio  de graficas en Dashboard',
    // 'Se agrego un encabezado que ayudara a filtrar las graficas del usuario por su categorai',
    // // 'Se agrego la tabla bitacoras a dashboard',
    // 'Se arreglaron algunos problemas con la experiencia de usuario en Dashboard',
    // 'Se arreglo el acomodo de datos en la tabla pivote, permitiendo al usuario acomodarlo como a el le plazca',
    // 'Se tradujo el componente de tabla pivote',
    // 'Se Creo la primera version de un wizard para que el usuario cree graficas de forma mas facil e intuitiva',
    // 'Se cambiaron algunos estilos con respecto al dashboard',
    // 'Se corrige funcionamiento de inventario proveedores y se habilita el acceso a operaciones',
    // 'Se arreglaron errores con respecto a la creacion de puntos con el modulo de Rutas',
    // 'Se arreglo el formato de datos numericos para dashboard y permita un mejor manejo de datos',
    //'Se elimina el campo nicho de la captura de sitios',
    //'Se agregan los campos largo y ancho para cálculo de costo por m2',
    // 'Los campos de switch pueden ser manipulados desde la tabla de inventario sin entrar al formulario de edición',
    // 'Se corrigen los campos "largo" y "ancho" en el formulario de ABC Medios para capturar decimales',
    // 'Se cambio el estilo para la herramienta QueryBuilder y facilite la visualizacion de las graficas',
    // 'Se agrego la funcion de editar graficas en el dashboard',
    //'Se agrego un filtro por tiempo para la herramienta QueryBuilder',
    //'Se agregaron tips de filtrados para la herramienta QueryBuilder',
    //'Validaciones para la experiencia del usuario en el dashboard',
    //'Cambios en los iconos de Permisionario e inventario de urbanos',
    //'Se corrige un error que no permitía hacer copy paste en descripciones de rutas',
    //'Se libera el campo de costos en Ubicaciones',
    // 'Se crean los menús de Permisionario e inventario de urbanos',
    // 'Se elimina el campo nicho de la captura de sitios',
    // 'Se agregan los campos largo y ancho para cálculo de costo por m2',
    //'Se corrige un bug de seguridad'
    //'Se implementa dos tipos de edición de pauta: Completa y parcial',
    //'Se corrige un bug de seguridad',
    //'Se agrego un nuevo componente de graficacion llamado Tabla Pivote para el dashboard de la plataforma. Dicho componente permite ordenar mas a placer los datos al usuario, ademas de permitirle descargar la informacion en .CSV para excel',
    //'Se agregaron 2 filtros para el componente de creacion de graficas "Datos Vacions" y "Datos No Vacios"',
    //'Se arreglaron varios problemas para guardar graficas, como lo son su "Orden", Limite, Tipo de grafica',
    //'Se modificaron y agregaron algunas medidas para la generacion de graficas',

    //'Se agregaron funcionalidades al filtro del Query Builder del dashboard en la plataforma',
    //'Se permitio la funcoinalidad de filtros multiples en Query Builder del dashboard en la plataforma',
    //'Se agregaron funcionalidades de medida en Query Builder como la sumatiora, maximos y minimos',

    //'Se añade una función que elimina círculos huérfanos',
    //'La funcionalidad de editar pauta se añade al listado de pautas',
    //'El campo de Campañas en Crear Pauta ya es obligatorio',
    //'Se agrego una herramienta para la creacion de graficas por parte del usuario en el Dashboard de la plataforma',
    //'Se agrgo la funcionalidad de creacion de graficas de forma local',
    //'Se agrgo la funcionalidad para eliminar graficas de forma local',
    //'Se agrega funcionalidad de generar contraseñas aleatorias',
    //'Se corrige error de listado de pautas y se reestructura código',
    //'Al crear cliente en la plataforma, se crea un usuario',
    //'Se agrego un modulo dashboard para la visualizacion de graficas dinamicas y editables para el usuario',
    //'Se permite ordenar el ordenamiento de todas las graficas segun le plazca al usuario',
    //'Se permite editar el tipo de grafica segun la necesidad  del usuario',
    //'Se permite editar el limite de registros a visualizar en la grafica segun la necesidad  del usuario',
    // 'Se permite editar el orden de la grafica en ASCENDENTE y DESCENDETNE segun la necesidad  del usuario',

    // 'Se cambio la informacion para mostrar en el campo select "Proveedores" a su nombre comercial en caso de que este guardado en el formulario de ABC Sitios',
    // 'Se ordenaron por nombre alfabetico los nombre de proveedores en el formulario de ABC Sitios',
    // 'Se agrego una nueva funcionalidad que agrega puntos de referencia en base a la lat/lng en el formulario para agregar/editar sitios',
    // 'Se corrigio un bug que no captura la referencia en google maps en el formulario para agregar/editar sitios',
    // 'Se corrigio un error con la descarga del pdf de medios',
    // 'Se corrigio un error que no permitia editar las imagenes en sitios',
    // 'Se corrige la carga de imágenes en el registro de fichas',
    // 'Se agrego una validacion que permite agregar rutas con el mismo nombre, pero diferente estado',
    // 'Se agregaron campos para su visualización en el componente InfoWindow en el mapa de la pagina /Sitios',
    // 'Listado de pautas muestra estatus y almacena descuentos',
    // 'Se removio la opcion de arrastrar imagen para subir capturas en ABC sitios, solucionando asi un error al subir la imagen vacia',
    // 'Histórico de Pautas creado. Registra círculos con su respectiva etiqueta'
  ];

  return (
    <IonPopover
      event={event}
      isOpen={isOpen}
      onDidDismiss={() => onDidDismiss({ showPopover: false, event: undefined })}
    >
      <div className={'container-notes'}>
        <h4 className={'title-note'}>{VERSION}</h4>
        <div className="column">
          {NOTAS.map(n => (
            <div key={Math.random()} className={'item-note'}><p>{n}</p></div>
          ))}
        </div>
      </div>
    </IonPopover>
  );
}

export default NotasVersion;