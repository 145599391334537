import React, { useState } from "react";
import FormCampaniaEdit from "./FormCampaniaEdit";
import FormIncidencias from "./FormIncidencias";
import AsignarCampania from "./AsignarCampania"
import EliminarAsignacion from "./EliminarAsignacion"
import moment from "moment";

const Asignaciones=(props)=>{
    
    
    if(props.operacion!==null && props.operacion!==undefined){
        if(props.operacion==="Asignar campaña"){
            return(
                <>
                <br/><br/><br/>
                <AsignarCampania/>
                </>
            )
        }
        if(props.operacion==="Editar campaña"){
            return(
                <>
                    <br/>
                    <FormCampaniaEdit/>
                </>
            )
        }
        if(props.operacion==="Asignar incidencia"){
            return(
                <>
                    <br/>
                    <FormIncidencias props={props}/>
                </>
            )
        }
        if(props.operacion==="Eliminar asignación"){
            return(
                <>
                    <br/>
                    <EliminarAsignacion></EliminarAsignacion>
                </>
            )
        }
    }
    else{
        return(
            <h3>Seleccionar una operación</h3>
        )
    }
    

}
export default Asignaciones;