import { gql } from "@apollo/client";

const MEDIOS_QUERY = gql`
  query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            tipos{
                id
                nombre
            }
            proveedor{
                id
                nombre
            }
            claveproveedor
            direccion
            ubicacion{
                nombre
            }
            iluminacion
            nicho
            niveldeimpacto
            referencias
            medida
            disponible
            activo
            bloqueado
            Vista_larga
            Vista_media
            Vista_corta
            comentarios
            precioventa
            male_users
            female_users
            a13_17_users
            a18_25_users
            a26_40_users
            a41_55_users
            a55_users
            income_a_users
            income_b_users
            income_c_users
            income_d_users
            income_e_users
            total_hits
            total_users
            reach
            frequency
            universo
            created_at
            updated_at
            latitud
            longitud
            especiales
            largo
            ancho
        }
    } 
`;

export default MEDIOS_QUERY;