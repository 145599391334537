import React, {useState, useEffect} from 'react';
import {WizardIconComponent} from '../../utils/SVGS';
// import './ScrollButton.style.css';


const ScrollTopArrow = () =>{

  const [showScroll, setShowScroll] = useState(false)

  useEffect(()=>{
      console.log('USE EFFECT', showScroll);
    window.addEventListener('scroll', () => checkScrollTop())
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop)
    }
  })

  const checkScrollTop = () => {
      console.log('checkScrollTop showScroll', showScroll);
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
      <div style={{height: 40, display: showScroll ? 'flex' : 'none'}} className="scrollTop" onClick={scrollTop}>
          <WizardIconComponent height={20} width={20} />
      </div>
        
  );
}

export default ScrollTopArrow;