import React, { useState } from "react";
import { IonRow, IonCol, IonGrid, IonHeader, IonFooter, IonSpinner } from "@ionic/react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { URBANOS_INCIDENCIAS_QUERY } from "./query";
import { Button } from "primereact/button";
import { saveAs } from 'file-saver';
import { formatMoney } from '../../utils/Utils';
import { AddSheet,CombinarCeldas, EXCEL_EXTENSION, FormatoFila, FormatoMultiplesCeldas, HojaActiva, ValorCelda, ValoresFila, workBook } from '../../components/Pauta/export/funcionesExport';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';

const Resumen=(props)=>{
    const Excel = require('exceljs');
    const [incide, setIncide]=useState([])
    const {loading:loadingIncidencias, error:errorIncidencias,data:dataIncidencias}=useQuery(URBANOS_INCIDENCIAS_QUERY, {
        variables:{
            sort:"id:ASC",
            where:{
                'unidades_urbano.activo':true
            }
            
        },
        onCompleted:(dataIncidencias)=>{
            setIncide(dataIncidencias.urbanosIncidencias)
        }
    });
    
    //genera un objeto más fácil de leer
    const unidadesTemp=[]
    props.unidadesurbanos.forEach(element=>{
        if(element.activo===true){
            let x={
                id:element.id,
                numeco:element.numeco,
                tipoContrato:element.tipoContrato,
                activo:element.activo,
                cartuchos:element.cartuchos,
                created:element.created_at.split('T')[0],
                costo:element.permisionario.costo,
                vigencia: element.vigencia!==null ? element.vigencia.split('T')[0] : element.vigencia
            }
            unidadesTemp.push(x)
        }
        
    })
    //Arrays que se usan para el mapeo
    let vendidasA=[]
    let novendidas=[]
    let porpagarA=[]
    let mermaA=[]
    let incidencias=[]

    let sumaArray=[] //array que almacena la suma del costo de la incidencia

    const arrayFinal=[]
    for(var i=0; i<12; i++)
    {
        const mes=moment().month(i).format('MM');
        const anio=props.anio;
        const fechaInicio=anio+'-'+mes+'-01';
        const fechaFin=moment(fechaInicio).endOf('month').format('YYYY-MM-DD')
        //unidades vendidas
        let vendidasM=[];
        props.urbanoscampanias.forEach(element => {
            if(element.inicia>=fechaInicio && element.termina<=fechaFin)
            {
                vendidasM.push(element.unidades_urbano)
            }
            if(element.inicia>=fechaInicio && element.inicia<=fechaFin && element.termina>fechaFin)
            {
                vendidasM.push(element.unidades_urbano)
            }
            if(element.inicia<fechaInicio && element.termina>fechaFin)
            {
                vendidasM.push(element.unidades_urbano)
            }
        })
        let arregloAux=[];
        for (var l = 0; l < props.unidadesurbanos.length; l++)
        {
            var igual=false;
            for (var j = 0; j < vendidasM.length && !igual; j++)
            {
                if(props.unidadesurbanos[l]['id'] === vendidasM[j]['id'] && 
                    props.unidadesurbanos[l]['numeco'] === vendidasM[j]['numeco']) 
                    igual=true;
            }
            if(igual)arregloAux.push(props.unidadesurbanos[l])
        }
        vendidasA.push(arregloAux.length)
        //fin vendidas
        //Ciclo que obtiene unidades por pagar
        let porpagarM=[]
        unidadesTemp.forEach(element=>{
            if(element.created<fechaFin && element.tipoContrato!=='Variable' && (element.activo===true || element.vigencia<fechaInicio))
            {
                porpagarM.push(element)
            }
        })
        //Fin de unidades por pagar
        //Merma
        //Obtiene unidades que no tienen campaña
        let concampania=[]; //array temporal que almacena los ecos con campañas
        props.urbanoscampanias.forEach(element=>{
            if((element.inicia>=fechaInicio && element.termina<=fechaFin && (element.unidades_urbano.tipoContrato==="Fijo" || element.unidades_urbano.tipoContrato==="Fijo con cartucho")) ||
            (element.inicia>=fechaInicio && element.inicia<=fechaFin && element.termina>=fechaFin && (element.unidades_urbano.tipoContrato==="Fijo" || element.unidades_urbano.tipoContrato==="Fijo con cartucho")) ||
            (element.inicia<fechaInicio && element.termina>fechaFin && (element.unidades_urbano.tipoContrato==="Fijo" || element.unidades_urbano.tipoContrato==="Fijo con cartucho"))
            )
            {
                concampania.push(element.unidades_urbano)
            }
        })
        let array = [];
        for (var m = 0; m < props.unidadesurbanos.length; m++) 
        {
            var same=false;
            for (var n = 0; n < concampania.length && !same; n++) 
            {
                if((props.unidadesurbanos[m]['id'] === concampania[n]['id'] && 
                props.unidadesurbanos[m]['numeco'] === concampania[n]['numeco'])
                // && props.unidadesurbanos[m]['activo']===true
                ) 
                same=true;
            }
        if(!same)array.push(props.unidadesurbanos[m]);
        }
        //No vendidas
        //se retiran las que no fueron creadas:
        let aux=[]
        array.forEach(element=>{
            if(element.created_at.split('T')[0]<fechaFin && (element.activo===true || element.vigencia<fechaInicio))
            {
                aux.push(element)
            }
        })
        novendidas.push(aux.length)
        //fin No vendidas
        //cálculo de merma
        let mermaM=[]
        let suma=0
        aux.forEach(element=>{
            suma+=element.permisionario.costo
        })
        mermaM.push(suma)
        //fin merma
        //incidencias
        if(errorIncidencias){
            return(
                <h5 color="red">Algo salió mal</h5>
            )
        }
        if(loadingIncidencias)
        {
            return(
                <IonSpinner name="dots"></IonSpinner>
            )
        }
        if(dataIncidencias){
            let incidenciasM=[]
            let resta=0
            dataIncidencias.urbanosIncidencias.forEach(element=>{
                if((element.inicia>=fechaInicio && element.termina<=fechaFin) || (element.inicia>=fechaInicio && element.inicia<=fechaFin && element.termina>=fechaFin) || (element.inicia<fechaInicio && element.termina>=fechaInicio && element.termina<=fechaFin) || (element.inicia<fechaInicio && element.termina>fechaFin))
                {
                    incidenciasM.push(element)
                    resta+=element.unidades_urbano.permisionario.costo
                    
                }
            })
            sumaArray.push(resta)
            incidencias.push(incidenciasM.length);
            //Unidades por pagar
            const restaIncidencias=porpagarM.length-incidenciasM.length;
            porpagarA.push(restaIncidencias);
            mermaA.push(suma-resta)
        }
        
        //fin incidencias
        var z={
            id:i,
            mes:moment.months(i),
            vendidas:vendidasA[i],
            novendidas:novendidas[i],
            porpagarA:porpagarA[i],
            mermaA:'$'+formatMoney(mermaA[i]),
            incidencias:incidencias[i]
        }
        arrayFinal.push(z)
    }

    ////////////////////////////////EXPORT EXCEL   
    const exportXLS=async()=>{
        let wb=workBook();
        AddSheet('Resumen de corte anual')
        let ws=HojaActiva();
        CombinarCeldas('A1:F1');
        FormatoMultiplesCeldas(['A1'],
            'Rojo',
            {top: {style:'none'}, left: {style:'none'}, bottom: {style:'thin'}, right: {style:'thin'}},
            {vertical: 'middle', horizontal: 'center', wrapText: true},
            {bold: true, name:'Dia Regular'}
        )
        ValorCelda('A1', "Resumen Corte Urbanos "+props.anio)
        FormatoFila(1, {height: 40, font:{bold: true, size:18, name:'Dia Regular'}, alignment:{vertical: 'middle', horizontal: 'center'}})
        ValoresFila(3, ['', 'Unidades Vendidas', 'Sin Usar', 'Por Pagar', 'Merma', 'Incidencias'])
        FormatoMultiplesCeldas(['A3', 'B3', 'C3', 'D3', 'E3', 'F3'],
            'Rojo',
            {top: {style:'thin'}, left: {style:'thin'}, bottom: {style:'thin'}, right: {style:'thin'}},
            {vertical: 'middle', horizontal: 'center', wrapText: true},
            {size: 16, bold: true, name:'Dia Regular'})
        ws.columns=[
            {key: '', width:20},
            {key: 'unidadesVendidas', width:30},
            {key: 'SinUsar', width: 20},
            {key: 'PorPagar', width: 20},
            {key: 'Merma', width: 20},
            {key: 'Incidencias', width: 30}
        ];
        let j=0
        for(let i=4; i<=15; i++){
            ValorCelda(`A${i}`, arrayFinal[j].mes);
            ValorCelda(`B${i}`, arrayFinal[j].vendidas);
            ValorCelda(`C${i}`, arrayFinal[j].novendidas);
            ValorCelda(`D${i}`, arrayFinal[j].porpagarA);
            ValorCelda(`E${i}`, arrayFinal[j].mermaA);
            ValorCelda(`F${i}`, arrayFinal[j].incidencias);
            FormatoMultiplesCeldas([`A${i}`, `B${i}`, `C${i}`, `D${i}`, `E${i}`, `F${i}`],
                'Gris',
                {top: {style:'thin'}, left: {style:'none'}, bottom: {style:'thin'}, right: {style:'thin'}},
                {vertical: 'middle', horizontal: 'center', wrapText: true},
                {bold: false, name:'Dia Regular'})
            j++
        }
        const buffer = await wb.xlsx.writeBuffer(); 
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const blob= new Blob([buffer], {type: fileType});
        saveAs(blob, 'Resumen_Urbanos_Mensual_Export_'+ new Date().getTime()+EXCEL_EXTENSION);
    }
    ///////////////////////////////////EXPORT PDF
        const exportpdf=()=>{
        const doc = new jsPDF('landscape');
        doc.setFontSize(22);
        doc.text("Resumen de corte anual "+props.anio, 50, 15);
        autoTable(doc, {startY: 20});
        autoTable(doc, {   
            columnStyles: {0: {halign: 'center'}, 1: {halign: 'center'}, 2: { halign: 'center' }, 3:{halign: 'center'}, 4: {halign: 'center'}, 5: {halign:'center'}}, // Cells in first column centered
            margin: { top: 10 },
            //color de los headers
            didParseCell: function (table) {
                if (table.section === 'head') {
                    table.cell.styles.fillColor = '#126efa';
                }
            }, 
            columns: [
                {dataKey:'mes'},
                {dataKey:'vendidas'},
                {dataKey: 'novendidas'},
                {dataKey: 'porpagarA'},
                {dataKey: 'mermaA'},
                {key: 'incidencias'}
            ],   
            head: [['', 'Unidades Vendidas', 'Sin Usar No Vendidas', 'Unidades a Pagar', 'Merma', 'Incidentes']],
            body: 
                arrayFinal
            ,
        })
        var pageNumber = doc.internal.getNumberOfPages()
        doc.setPage(pageNumber);
        doc.save('Resumen_Urbanos_Anual_GDL_Export_'+ new Date().getTime()+'.pdf') 
    }
    const mapeoFinal=arrayFinal.map((element)=>{
        return(
            <div key={element.id}>
                <IonRow>
                    <IonCol>
                        {element.mes}
                    </IonCol>
                    <IonCol>
                        {element.vendidas}
                    </IonCol>
                    <IonCol>
                        {element.novendidas}
                    </IonCol>
                    <IonCol>
                        {element.porpagarA}
                    </IonCol>
                    <IonCol>
                        {element.mermaA}
                    </IonCol>
                    <IonCol>
                        {element.incidencias}
                    </IonCol>
                </IonRow>
            </div>
        )
    })
    
    const header=[
        {id:1, title:'Mes'}, 
        {id:2, title:'Unidades Vendidas'}, 
        {id:3, title: 'No vendidas'}, 
        {id:4, title:'Unidades Por Pagar'},
        {id:5, title:'Merma'},
        {id:6, title:'Incidencias'}
    ]
    const cabecera=header.map((element)=>{
        return(
            <IonCol key={element.id}>
                <IonHeader>{element.title}</IonHeader>
            </IonCol>
        )
    })
    return(
        <IonGrid>
            <IonRow>
                {cabecera}
            </IonRow>
            {mapeoFinal}
            <br/>
            <IonFooter>
                <IonRow>
                    <IonCol>
                    <Button
                        label="Exportar XLS"
                        icon="pi pi-file-excel"
                        className='p-button-raised p-button-rounded p-button-success ion-float-left'
                        onClick={exportXLS}
                    />
                    </IonCol>
                    <IonCol>
                        <Button
                            label="Exportar PDF"
                            icon="pi pi-file-pdf"
                            className="p-button-raised p-button-rounded p-button-danger ion-float-right"
                            onClick={exportpdf}
                        />
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonGrid>
    )
}
export default Resumen