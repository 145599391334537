/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
    IonCard,
    IonCol,
    IonGrid,
    IonInput,
    IonPage,
    IonRow,
    IonSpinner,
} from "@ionic/react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { LISTAR_CAMPANIA } from './query';
import { Toolbar } from "../../components";
import { Dropdown } from 'primereact/dropdown'
import { IMJ_COLORS } from "../../utils/Constanst";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGrid from '@fullcalendar/timegrid'
import { Dialog } from "primereact/dialog";
import "./calendario.module.css";

const Calendario = (props) =>{
    const [eventos, setEventos] = useState([]);
    const [elementoSel, setElementoSel] = useState(null)
    const [vistaSel, setVistaSel] = useState(null)
    const [opciones, setOpciones] = useState([])
    const vistaOpcion = ['Inicio de Campaña', 'Toda la duración de campaña', 'Fin de Campaña']
    const [vistas, setVistas] = useState(vistaOpcion[1])
    //Info para el popover
    const [titulo, setTitulo] = useState("")
    const [cliente, setCliente] = useState("Cliente no definido")
    const [responsable, setResponsable] = useState("Comercial no definido")
    //Vista del popoverTodo
    const [displayBasic, setDisplayBasic] = useState(false);
    const [position, setPosition] = useState('center');
    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }
    //states de fechas
    const fecha=moment()
    const [inicio, setInicio]=useState(moment(fecha).startOf("month").format('YYYY-MM-DD'))
    const [fin, setFin]=useState(moment(fecha).endOf("month").format('YYYY-MM-DD'))
    console.log(inicio, fin)
    const [campaniasActivas, setCampaniasActivas] = useState(0);
    const { loading: loadingPautas, data: dataPautas, error: errorPautas } = useQuery(LISTAR_CAMPANIA, {
        variables: {
            sort: "nombre:ASC",
            where: {
                estatus: "Cerrado Ganado"
            }
        },
        onCompleted: (dataPautas) => {
            getEvents(dataPautas.campanias, vistas)
            setOpciones(dataPautas.campanias)
        }
    });
    const getEvents=(data, vis)=>{
        var eventoTemp=[];
        var conteo=[];
        data.forEach(element=>{
            var color=element.clientes===undefined || element.clientes===null || element.clientes.color===null || element.clientes.color===undefined || element.clientes.color==="" ? IMJ_COLORS[0] : element.clientes.color
            var title=(element.clientes===undefined || element.clientes===null) ? element.nombre+' - '+element.responsable.nombre : element.nombre+' - '+element.clientes.nombre+' - '+element.responsable.nombre
            switch (vis)
            {
                case 'Toda la duración de campaña':
                    var x = {
                        id: element.id,
                        title: title,
                        start: element.inicia,
                        end: element.termina,
                        backgroundColor: color,
                        borderColor: color
                    }
                    
                    if(((element.termina >= inicio || element.inicia >= fin)) &&
                    element.inicia !== null &&
                    element.termina !== null &&
                    element.termina !== undefined){
                        conteo.push(element)
                    }
                break;
                case 'Inicio de Campaña':
                    x = {
                        id: element.id,
                        title: title,
                        start: element.inicia,
                        end: element.inicia,
                        backgroundColor: color,
                        borderColor: color
                    };
                    if(element.inicia>=inicio && element.inicia<fin)
                    {
                        conteo.push(element)
                    }
                break;
                case 'Fin de Campaña':
                    x = {
                        id: element.id,
                        title: title,
                        start: element.termina,
                        end: element.termina,
                        backgroundColor: color,
                        borderColor: color
                    };
                    if(element.termina>=inicio && element.termina<fin)
                    {
                        conteo.push(element)
                    }
                break;
            }
            setCampaniasActivas(conteo.length)
            eventoTemp.push(x)
        })
        
        setEventos(eventoTemp)
        //Ejecuta conteo de campañas activas
    }
    const listarEventos = () => {
        if (errorPautas) return <h5 style={{ color: "red" }}>Algo salió mal</h5>;
        if (loadingPautas) return <IonSpinner name="dots" />;
        if (dataPautas) return (
            <Dropdown
                id='lista'
                tabIndex={1}
                showClear={true}
                style={{ width: '100%', minWidth: '100px', borderRadius: "25px" }}
                value={elementoSel}
                options={opciones}
                onChange={onSelectCampania}
                dataKey='id'
                filter
                filterBy="nombre"
                optionLabel='nombre'
                placeholder="Selecciona una campaña"
            />
        );
    }
    const onSelectCampania=(campaniaSel)=>{
        if(campaniaSel.value){
            const campaniaS = campaniaSel.value
            setElementoSel(campaniaS);
            var evenTemp=[]
            var color=campaniaS.clientes===undefined || campaniaS.clientes===null || campaniaS.clientes.color===null || campaniaS.clientes.color===undefined || campaniaS.clientes.color==="" ? IMJ_COLORS[0] : campaniaS.clientes.color
            var title=(campaniaS.clientes===undefined || campaniaS.clientes===null) ? campaniaS.nombre+' - '+campaniaS.responsable.nombre : campaniaS.nombre+' - '+campaniaS.clientes.nombre+' - '+campaniaS.responsable.nombre
            switch (vistas)
            {
                case 'Toda la duración de campaña':
                    var x = {
                        title: title,
                        start: campaniaS.inicia,
                        end: campaniaS.termina,
                        backgroundColor: color,
                        borderColor: color
                    };
                break;
                case 'Inicio de Campaña':
                    x = {
                        title: title,
                        start: campaniaS.inicia,
                        end: campaniaS.inicia,
                        backgroundColor: color,
                        borderColor: color
                    };
                break;
                case 'Fin de Campaña':
                    x = {
                        title: title,
                        start: campaniaS.termina,
                        end: campaniaS.termina,
                        backgroundColor: color,
                        borderColor: color
                    };
                break;
                
            }
            evenTemp.push(x)
            setEventos(evenTemp);
            
        }
        else
        {
            setElementoSel(null);
            getEvents(dataPautas.campanias, vistas)
            setVistaSel(null)
        }
    }
    const tipoVista=(opcion)=>{
        setVistaSel(opcion)
        setVistas(opcion.value.toString());
        if (eventos.length>1){
            getEvents(dataPautas.campanias, opcion.value.toString())
        }
        if(eventos.length===1)
        {
            var evenTemp=[]
            switch (opcion.value.toString()){
                case 'Toda la duración de campaña':
                    var x = {
                        title: eventos[0].title,
                        start: elementoSel.inicia,
                        end: elementoSel.termina,
                        backgroundColor: eventos[0].backgroundColor,
                        borderColor: eventos[0].borderColor
                    };
                break;
                case 'Inicio de Campaña':
                    x = {
                        title: eventos[0].title,
                        start: elementoSel.inicia,
                        end: elementoSel.inicia,
                        backgroundColor: eventos[0].backgroundColor,
                        borderColor: eventos[0].borderColor
                    };
                    if(elementoSel.inicia>=inicio && elementoSel.inicia<fin)
                    {
                        setCampaniasActivas(1)
                    }
                break;
                case 'Fin de Campaña':
                    x = {
                        title: eventos[0].title,
                        start: elementoSel.termina,
                        end: elementoSel.termina,
                        backgroundColor: eventos[0].backgroundColor,
                        borderColor: eventos[0].borderColor
                    };
                    if(elementoSel.termina>=inicio && elementoSel.termina<fin)
                    {
                        setCampaniasActivas(1)
                    }
                break;
            }
            evenTemp.push(x)
            setEventos(evenTemp)
        }
    }
    const eventodeclic = (info) => {
        const head = info.event.title.split(" - ")
        const encabezado = head[0].split("-")
        setTitulo(encabezado[1]);
        setCliente(head[1])
        setResponsable(head[2])
        setDisplayBasic(true)
        if (position) {
            setPosition(position);
        }
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    return (
        <IonPage>
            <Toolbar
                title={props.title}
                setMenuEnabled={props.setMenuEnabled}
                menuEnabled={props.menuEnabled}>
            </Toolbar>
            <IonCard style={{ overflow: "visible" }} >
                <IonGrid>
                    <IonRow>
                        <IonCol size-md='12' size-sm='12' size='4' size-lg='4' size-xl='4'>
                            <h4>Consulta individual de campañas</h4>
                        </IonCol>
                        <IonCol className='ion-align-self-center' size="4"> 
                            <h4>Tipo de vista</h4>
                        </IonCol>
                        <IonCol className="ion-align-self-end" size="4">
                            <h4>Campañas activas</h4>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size-md='12' size-sm='12' size='4' size-lg='4' size-xl='4'>
                            {listarEventos()}
                        </IonCol>
                        <IonCol>
                            <Dropdown
                                id='vistas'
                                tabIndex={1}
                                showClear={false}
                                style={{ width: '100%', borderRadius: "25px" }}
                                value={vistas}
                                options={vistaOpcion}
                                onChange={tipoVista}
                            />
                        </IonCol>
                        <IonCol size-md='12' size-sm='12' size='4' size-lg='4' size-xl='4'>
                            <IonInput
                                id={'campaniasActivas'}
                                value={campaniasActivas}
                                style={{width: '100%', borderRadius: "25"}}
                                readonly
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCard>
                <FullCalendar
                    height='100%'
                    plugins={[dayGridPlugin, interactionPlugin, timeGrid]}
                    datesSet={(arg) => {
                        //arg contiene las fechas en las que navega
                        //arg es el objeto donde view es la fecha
                        //Se trunca el startStr para poder comparar
                        if (dataPautas !== undefined) {
                            switch (vistas)
                            {
                                case 'Toda la duración de campaña': 
                                    var conteo = eventos.filter(d =>
                                        (d.end >= arg.startStr.slice(0, 10) || d.start >= arg.startStr.slice(0, 10)) &&
                                        d.start !== null &&
                                        d.end !== null &&
                                        d.end !== undefined
                                    )
                                    setCampaniasActivas(conteo.length)
                                break;
                                case 'Inicio de Campaña':
                                    var conteoI=[]
                                    eventos.forEach(element=>{
                                        if(element.start>=arg.startStr.slice(0, 10) && element.start<=arg.endStr.slice(0,10))
                                        {
                                            conteoI.push(element)
                                        }
                                    })
                                    setCampaniasActivas(conteoI.length)
                                break;
                                case 'Fin de Campaña':
                                    var conteoF=[]
                                    eventos.forEach(element=>{
                                        if(element.end>=arg.startStr.slice(0, 10) && element.end<=arg.endStr.slice(0,10))
                                        {
                                            conteoF.push(element)
                                        }
                                    })
                                    setCampaniasActivas(conteoF.length)
                                break;
                            }
                        }
                    }}
                    locale={'es'}
                    initialView="dayGridMonth"
                    weekends={true}
                    buttonText={{
                        prev: "Anterior",
                        next: "Siguiente",
                        today: "Hoy",
                        month: "Vista Calendario",
                        week: "Semana",
                        day: "Día",
                    }}
                    showNonCurrentDates={false}
                    eventClick={eventodeclic}
                    dayMaxEventRows={true}
                    dayMaxEvents={true}
                    moreLinkClick={"popover"}
                    eventDisplay={"block"}
                    moreLinkText={"Más..."}
                    buttonIcons={false}
                    navLinks={false}
                    events={
                        eventos
                    }
                    headerToolbar={{
                        left: 'prev',
                        center: 'title',
                        right: 'next'
                    }}
                    footerToolbar={
                        {
                            center: 'today',
                        }
                    }
                />
                <div className="dialog-demo">
                <div className="card">
                    <Dialog visible={displayBasic} style={{ width: '50vw' }} onHide={() => onHide('displayBasic')}>
                        <h5>Campaña {titulo} para el cliente {cliente} a cargo de {responsable}</h5>
                    </Dialog>
                </div>
            </div>
        </IonPage>
    )
}
export default Calendario;