import React ,{useEffect, useState}from 'react'
import { IMJ_API_KEY } from '../../../../utils/Constanst';
import './FormImportRoute.css'

/**
 * Formulario que muestra la informacion obtenida desde el archivo KML
 * @param {Object} props
 * @param {string} props.nombre
 * @param {string} props.descripcion
 * @param {[]} props.puntos
 * @param {string} props.errorMsj
 * 
 * @returns  
 */
const FormRutasKML = ({
    nombre = '',
    descripcion = '',
    puntos = [],
    // errorMsj = '',
}) => {
    const [_descripcion, setdescripcion] = useState('');
    const [_nombre, setNombre] = useState('');
    // const [image, setImage] = useState();
    /**
     * Effect que setea los datos que se usaran en el componente.
     */
    useEffect(() => {
        if (nombre) {
            setNombre(nombre);
        }

        // if (puntos && puntos.length > 0) {
        //     generaImagen(puntos)
        // }

        if (descripcion) {
            
            setdescripcion(descripcion);
            
        }        
    }, [
        nombre,
        descripcion,
        puntos,
    ]);

    // /**
    //  * Funcion que devuelve el URL correcto para crear una imagen estatica de google maps
    //  * de tipo Polyline, la cual recibe un arreglo de puntos [latitud, longi]
    //  * @param {[{lat: '', lng: ''}]} puntosParam 
    //  * @returns {string}
    //  */
     const formatURLImageMap = puntosParam => {
        let pathPuntosString = '';
        // Se itera en el arrelo para obtener los puntos de lat y lng para pintarlos en la URL
        puntosParam.forEach(p => {
            /**
            * Existen varios puntos vacios que arruinan toda la imagen,
            * Por lo tanto se validan que existan para gregarlos
            */
            if (p.lat && p.lng) {
                pathPuntosString += p.lat + ',' + p.lng + '|';
            }
        });    
        const halfPuntos = Math.trunc(puntosParam.length / 2); // Se obtiene el punto medio
        // Se crea una variable que almacene la lat y lng del punto medio.
        const myLatLngString = 'center=' + parseFloat(puntosParam[halfPuntos].lat + '') + ',' + parseFloat(puntosParam[halfPuntos].lng + '');
        
        // Variable que almacenara los puntos de coordenas y opciones para la PolyLine como el colo o el tamaño
        let pathFinal = 'path=color:0x0000ff|weight:5|' + pathPuntosString;
        // console.log('pathFinal', pathFinal);
        pathFinal = pathFinal.substring(0, pathFinal.length -1); // Se quita el ultimo caracter ya que siempre termina vacio.
        
        const options = '&zoom=12&size=319x145&key=' + IMJ_API_KEY; // Opciones para el mapa como el tamaño, zoom y la ApiKey
        const url = 'https://maps.googleapis.com/maps/api/staticmap?' + myLatLngString + '&' + pathFinal + options; // Variable que contendra la URL que mostrara la imagen
        
        return encodeURI(url);
    };

    /**
     * Funcion que crea la imagen en base a los puntos de coordenadas
     * y comvierte a Base64 para su visualizacion (y posible almacenamiento si se desea)
     * @param {[{lat: '', lng: ''}]} puntosParam 
     */
    // const generaImagen = (puntosParam) =>{
    //     // const imageMapUrl = formatURLImageMap(puntosParam);
    //     const toDataURL = url => fetch(url)
    //       .then(response => response.blob())
    //       .then(blob => new Promise((resolve, reject) => {
    //         const reader = new FileReader()
    //         reader.onloadend = () => resolve(reader.result)
    //         reader.onerror = reject
    //         reader.readAsDataURL(blob)
    //     }))

    //     /*varibale que continene el resultado de lacomversion a base 64 ,
    //     pendiente pasara a formulario para guardar*/
    //     toDataURL(imageMapUrl).then(dataUrl => setImage(dataUrl));
    // }

    return (
        <div className={'container'}>
            <div className={'flex'}>

                <div className={'item-main-nombre'}>
                    <h3>
                        Nombre Ruta: {_nombre === null ? "" : _nombre}
                    </h3>
                </div>
            </div>

            <div className={'flex'}>
                <div className={'item-main-derroteo'}> 
                    <h3>Derrotero</h3>
                    <p>
                        {_descripcion === null ? "Sin Derrotero" : _descripcion}
                    </p>
                </div>
                <div>
                    <img src={formatURLImageMap(puntos)} alt='Mapa de puntos' />
                </div>
            </div>
        </div>
    );
}

export default FormRutasKML;
