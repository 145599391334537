/* eslint-disable array-callback-return */
import React, { useState } from 'react'
import {
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonButton,
	IonAlert
} from "@ionic/react";
import { gql } from '@apollo/client';
import {isMobile} from 'react-device-detect';
import { useMutation } from '@apollo/client';
import useAuthentication from '../../hooks/useAuthentication';
import { InputTextComponent } from '../../components';

const CREATE_USER=gql`
    mutation creaUsuario($input:createUserInput){
        createUser(input:$input){
            user{
                id
                username
                email
            }
        }
    }
`;

//Hacer la forma para registrar el nombre del negocio y el nombre del propietario
//el propietario se crea en user
//Se hace el registro en user y con el jwt que regresa se graba negocio y luego se redirige a principal
const Register = (props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [mensaje, setMensaje] = useState('Ocurrió un error');
    const storage = !isMobile?sessionStorage:localStorage;
    
    const onRegister = (data)=>{
        storage.setItem('u',JSON.stringify(data.createUser.user));
        login({ identifier: username, password: password });
    }    

    const errorRegister = (error)=>{
        //error.graphQLErrors[""0""].extensions.exception.data.message
        let mensaje='';
        const {graphQLErrors} = error;
        if (graphQLErrors){
            graphQLErrors.map(({extensions}) =>{
                if (extensions){
                    extensions.exception.data.message.map((message) => {
                        if (message){
                            message.messages.map((msg)=>{
                                if (msg.message==="Email is already taken."){
                                    mensaje = mensaje+'Ya existe un usuario con ese email';
                                }
                                if (msg.message==="Username already taken"){
                                    mensaje = mensaje+'Ya existe un usuario con ese nombre';
                                }
                            })
                        }
                    });
                }
            });
        }
        setMensaje(mensaje);
        setIsOpen(true);
    }

    const [registerMutation] = useMutation(CREATE_USER,{
        onCompleted:onRegister,
        onError:errorRegister
    });

    /* Login al sistema despues de crear el usuario, después del login crear el negocio */
    const succesfulLogin = (data: any) => {
        props.history.push('/')
	}

	const onError = (error: any) => {
		setMensaje(error.message);
		setIsOpen(true);
    }
    
    const { login } = useAuthentication(succesfulLogin, onError);
    /** Fin del login */

    return (
        <IonContent className="login">
            <form
                onSubmit={e => {
                    e.preventDefault();
                    registerMutation({
                        variables:{
                            input:{
                                data:{
                                    username: username,
                                    email: username,
                                    password: password,
                                    confirmed: true,
                                    blocked: false
                                }
                            }
                        }
                    })
                }}>
                <div className="login-centrado">
                    <img alt="logo" src="/assets/IMJ.png" style={{ width: '200px', height: '100px' }}/>
                    <IonList className="transparente">
                        <IonItem className="login-items">
                            <IonLabel>Email</IonLabel>
                            <InputTextComponent
                                name="email"
                                type="email"
                                value={username}
                                onChange={e =>
                                    setUsername(e)
                                }
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                required={null}
                                style={null}
                            />
                        </IonItem>
                        <IonItem className="login-items">
                            <IonLabel>Password</IonLabel>

                            <InputTextComponent
                                name="password"
                                type="password"
                                value={password}
                                onChange={e => {
                                    setPassword(e);
                                }}
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                required={null}
                                style={null}
                            />
                        </IonItem>
                    </IonList>
                    <IonList className="transparente">
                                <IonButton type="submit" expand="block" color="secondary">
                                    Registrarse
								</IonButton>
                    </IonList>

                </div>
            </form>
            <IonAlert
                isOpen={isOpen}
                header={'Error de registro'}
                message={mensaje || 'Usuario y/o contraseña inválidos.'}
                buttons={['OK']}
            />
        </IonContent>
    )
}

export default Register;
