import React, { useState } from "react";
import {Toolbar, MToolBar, DTableV2} from "../../components";
import useAuthentication from "../../hooks/useAuthentication";
import useNotification from "../../hooks/useNotification";
import {LISTAR_TESTIGOS,  LISTA_PRODUCTOS} from "./queries";
import FormDetalles from "./FormDetalles"; //Form que mostrará los testigos asociados
import { DateField } from "../../utils/dataTableAux";
import useDialog from "../../hooks/useDialog";

const TablaRutas=(props)=>{
    const [refresh, setRefresh] = useState(false);
    const [titleHeader, settitleHeader] = useState("Ver Testigos");
    const {showDialog, FormDialog} = useDialog();
    const {usuarioid, rol} = useAuthentication();

    const {
        notification,
        showSuccess,
        showInfo,
        showError
    } = useNotification();

    const vistaTestigos=(row)=>{
        //Aquí se abre un form que muestra los Testigos asociados al detalle
        console.log(row)
        const medio="urbano"
        let datos = {row, medio, created_by:usuarioid(),updated_by:usuarioid()}
		showDialog(datos, "crear");
		settitleHeader('Ver Testigos');
    }
    const columnas=[
        {
            header:"Nombre de ruta",
            field:"ruta.nombre",
            sortable:true,
            filter: true,
            filterMatchMode:"contains"
        },
        {
            header: "Clave",
            field: "ruta.clave",
            sortable: true,
            filter:true,
            filterMatchMode:"contains"
        },
        {
            header: "Fecha de Inicio",
            field: "fecha_inicio",
            bodyRender:DateField,
            sortable: true
        },
        {
            header: "Fecha de Fin",
            field: "fecha_fin",
            bodyRender: DateField,
            sortable: true
        },
        {
            header: "Cantidad",
            field: "cantidad"
        },
        {
            header:"Ubicación",
            field: "ruta.ubicacion.nombre",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Tipo de Exhibición",
            field: "ruta.ubicacion.tipoexhib",
            sortable: true,
            filter: true.valueOf,
            filterMatchMode: "contains"
        }
    ]

    return(
        <>
            <DTableV2
                query={LISTA_PRODUCTOS}
                filtro={{"pauta":props.pauta.id}}
                element="detailpautas"
                columnas={columnas}
                style={{margin: "15px"}}
                paginator={true}
                refresh={refresh}
                onEndRefresh={() => setRefresh(false)}
                canView={true}
                onView={vistaTestigos}
            />
            <FormDialog
                header={titleHeader}
                >
                <FormDetalles /> 
            </FormDialog>
        </>
    )
    
}
export default TablaRutas;