import {
  // ApolloLink,
  // DefaultOptions, 
  ApolloClient,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import {isMobile} from 'react-device-detect';

const urlDevL = 'http://localhost:3002/graphql';
// const urlDev = 'http://192.168.1.88:3002/graphql';
//const urlProd = 'https://apigraphql.imjmedia.com.mx/graphql';
const urlProd = 'https://services.imjmedia.com.mx/graphql'; 
// const urlProdh = 'https://plataformaimj.herokuapp.com/graphql';

const httpLink = createHttpLink({
  uri:  urlProd,
  //uri: urlDevL,
});

// const addCount = new ApolloLink((operation, forward) => {

//   return forward(operation).map(response => {
//     const context = operation.getContext();
//     const {
//       response: { headers }
//     } = context;

//     if (response.data) {
//       response.data.count = headers.get("Content-Range");
//     }
//     return response;
//   });
// });

const errorLink = onError(({ 
  operation,
  // response,
  graphQLErrors,
  networkError,
  forward
}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Invalid token.') {
        const storage = isMobile ? localStorage : sessionStorage;
        // get the authentication token from local storage if it exists
        storage.removeItem('token');
        storage.removeItem('u');
      }
      console.log(
        `[GraphQL error]: Operation ${operation} Message: ${message}, 
          Location: ${locations}, Path: ${path}`,
      )
    }

    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
  return forward(operation);
});

const authLink = setContext((_, { headers }) => {
  const storage = isMobile ? localStorage : sessionStorage;
  // get the authentication token from local storage if it exists
  const token = JSON.parse(storage.getItem('token'));
  //Si es login la operación no tenemos que mandar authorizaion
  if ((_.operationName !== 'login') && (_.operationName !== 'creaUsuario')) {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    }
  }
});

export const cliente = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query:{
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    watchQuery:{
      fetchPolicy: 'no-cache'
    }
  }
});

export default cliente;