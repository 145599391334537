import React from 'react';
import { IonFabButton, IonIcon } from '@ionic/react';

/**
 * 
 * @param {Object} props
 * @param {any} props.traffic
 * @param {React.Dispatch<React.SetStateAction<any>>} props.setTraffic
 * @param {any} props.map
 */
const ButtonTraffic = ({traffic, setTraffic, map}) => {

    const clickTrafico = () => {
        if (!traffic || !traffic.map) {
            let trafficLayer = new google.maps.TrafficLayer()
            trafficLayer.setMap(map);
            setTraffic(trafficLayer);
        } else {
            traffic.setMap(null);
        }
    }

    return (<div className="tooltip"><IonFabButton
        onClick={clickTrafico} color='success'><IonIcon className='pi pi-directions' /></IonFabButton><span className="tooltiptext">Capa de tráfico</span></div>);

}

export default ButtonTraffic;