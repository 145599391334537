import React, {useState} from "react";
import {IonRow, IonCol, IonText, IonSpinner} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useMutation, useQuery } from "@apollo/client";
import {CREATE_UNIDADES_URBANOS, UPDATE_UNIDADES_URBANOS, LISTAR_RUTAS, UBICACIONES_QUERY} from "./queries";
import {Dropdown} from "primereact/dropdown";
import { PERMISIONARIOS_QUERY } from "../permisionarios/queries";
import { InputTextComponent } from "../../components";

export default function FormUnidadesUrbano (props){
        console.log(props.datos)
	  //States de dropdowns
      const [permisionario, setPermisionario]=useState();
      const [elementoSelPerm, setElementoSelPerm]=useState(null);
      const [idPermisionario, setIdPermisionario]=useState(0)
      const [ruta, setRuta]=useState()
      const [elementoSelRuta, setElementoSelRuta]=useState(null);
      const [idRuta, setIdRuta]=useState(0)

      //State de ubicación
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [plaza, setPlaza]=useState("Guadalajara");
      const [idPlaza, setIdPlaza]=useState(1)
      const [elementoSelPlaza, setElementoSelPlaza]=useState({ id: "1", nombre: "Guadalajara", __typename: "Ubicacion" });

    const { handleSubmit, setValue } = useForm({
		defaultValues: {
             id:props.datos.id,
             numeco:props.datos.numeco,
             tipoContrato:props.datos.tipoContrato,
             cartuchos:props.datos.cartuchos,
             permisionario:permisionario,
             ruta:ruta

		}
    });

    const [fields,setValueFields] = useState(props.datos);
    const [tipoContrato, setTipoContrato] = useState<string>(fields.tipoContrato === null ? "" : fields.tipoContrato);
   
    const onSubmit= (data) =>{
        //Validación de que sea tipo de contrato fijo con cartucho para añadir cartuchos
        
        if (props.operacion==='editar'){
			updateUnidadesUrbanos();
			
		}else if (props.operacion === 'crear'){
            createUnidadesUrbanos();
        }else{
		
		}
	}

    // const handleChange = (e) =>{
    //     setValueFields({...fields,[e.target.name]:e.target.value})
	// }

    const handleChange = (e, name = '') =>{
        setValueFields({...fields,[name]:e})
	}

    //**************************MUTACIONES UPDATE Y CREATE ***************************************//

    const [updateUnidadesUrbanos] = useMutation(UPDATE_UNIDADES_URBANOS, {
		variables: {
			input: {
				where: { id: fields.id },
				data: {
                    numeco:fields.numeco,
                    tipoContrato:tipoContrato,
                    cartuchos:parseInt(fields.cartuchos),
                    permisionario:idPermisionario,
                    ruta:idRuta,
                    modifedby: props.datos.modifiedby
                }
				
			}
		},
		onCompleted(data){
            console.log(data)
            props.onSuccess();
		}
	});

    const [createUnidadesUrbanos] = useMutation(CREATE_UNIDADES_URBANOS, {
		variables: {
			input: {
				data:{
                    numeco:fields.numeco,
                    tipoContrato:tipoContrato,
                    activo:true,
                    cartuchos:parseInt(fields.cartuchos),
                    permisionario:idPermisionario,
                    ruta:idRuta,
                    createdby: props.datos.createdby,
                    modifedby: props.datos.modifiedby
                }
                
			}
		},
		onCompleted(data) {
            console.log(data)
			props.onSuccess();
		}
	});

	//*******************************Componente de botones/*************************//////////////////////
	const botones = ()=>{
		if(props.operacion === 'editar' || props.operacion === 'crear'){
			return (
				<div >
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>
			)
		}else{
			return (<div></div>)
		}
	}
	
    //////////////////////////////////////////////Query que trae las ubicaciones
    const {loading:loadingUbicacion, data:dataUbicacion, error:errorUbicacion}=useQuery(UBICACIONES_QUERY, {
        variables:{
            sort:"nombre:ASC",
            where:{
                "activo":true
            }
        },onCompleted(dataUbicacion){
            console.log("Ubicación", dataUbicacion.ubicacions)
        }, onError(error){
            console.log("Error Plaza: ",error)
        }
    });

    /////////////////////////////////////////////////Query que carga las rutas
    const {loading:loadingRuta, data:dataRuta , error:errorRuta}= useQuery(LISTAR_RUTAS, {
        variables:{
            sort:"clave:ASC",
            where:{
                    "activo":true,
                    "ubicacion":idPlaza
            }
        }, onCompleted(dataRuta){
            console.log("Rutas",dataRuta)
        }, onError(error){
            console.log("Error RUTAS: ",error)
        }
    });

    ////////////////////////////////Query que carga los permisionarios
    const {loading:loadingPermisionarios, data:dataPerm, error:errorPermisionarios}= useQuery(PERMISIONARIOS_QUERY, {
        variables:{
            sort:"permisionario:ASC",
        }, onCompleted(dataPerm){
            console.log(dataPerm.permisionarios)
        }, onError(error){
            console.log("Error Permisionarios: ",error)
        }
    });

    /////////////////////////////Funciones encargadas de asignar valores de los dropdown
    const onselectPlaza=(selectU)=>{
        if(selectU.value){
            setElementoSelPlaza(selectU.value);
            setPlaza(selectU.value.nombre);
            setIdPlaza(parseInt(selectU.value.id))
            console.log(elementoSelPlaza)
        }
        else{
            setElementoSelPlaza(null);
            setPlaza(null);
        }
    }

    const onselectRuta = (selectR) =>{
        if(selectR.value){
            setElementoSelRuta(selectR.value);
            setRuta(selectR.value.nombre);
            setIdRuta(parseInt(selectR.value.id));
            
        }else{
            setElementoSelRuta(null);
            setRuta(null);
        }
    }

    const onselectPerm = (selectP) =>{
        if(selectP.value){
            setElementoSelPerm(selectP.value);
            setPermisionario(selectP.value.permisionario)
            setIdPermisionario(parseInt(selectP.value.id));
            
        }
        else{
            setElementoSelPerm(null)
            setPermisionario(null)
        }
    }

    //Dropdowns 
	const opciones=['Fijo', 'Variable', 'Fijo con cartucho'];
	const tipodeContrato=()=>{
		return(
			<Dropdown
			name="tipoContrato" 
			tabIndex={1}
			showClear={true}
			style={{width:'300%'}}
			value={tipoContrato} 
			options={opciones} 
			onChange={e=>{
				setTipoContrato(e.target["value"]);
				console.log(tipoContrato)
                handleChange(e);
			}}
			placeholder='Selecciona un tipo de contrato'
			required={true}
		/>
		)
	}

    const rutaDrop=()=>{
        if(errorRuta) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingRuta) return  <IonSpinner name="dots" />;
        if(dataRuta)   return(
            <Dropdown
            name="ruta"
            tabIndex={2}
            showClear={true}
            style={{width:'100%'}}
            value={elementoSelRuta}
            options={dataRuta.rutas}
            onChange={onselectRuta}
            dataKey='id'
            optionLabel='nombre'
            filter
            filterBy='nombre'
            placeholder='Selecciona una ruta'
            required={true}
            />
        )
    }

    const perm=()=>{
        if(errorPermisionarios) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingPermisionarios) return  <IonSpinner name="dots" />;
       if(dataPerm) return(
            <Dropdown
            name="permisionario"
            tabIndex={2}
            showClear={true}
            style={{width:'100%'}}
            filter
            dataKey='id'
            filterBy='permisionario'
            optionLabel='permisionario'
            placeholder='Selecciona un permisionario'
            onChange={onselectPerm}
            options={dataPerm.permisionarios}
            value={elementoSelPerm}
            />
        )
    }

    const ciudad=()=>{
        if(errorUbicacion) return <h5 style={{color:"red"}}>Algo salió mal</h5>
        if(loadingUbicacion) return <IonSpinner name="dots"/>
        if(dataUbicacion) 
        return(
           <Dropdown
           name="ubicacion"
           tabIndex={3}
           showClear={true}
           style={{width:'100%'}}
           filter
           dataKey='id'
           filterBy='nombre'
           optionLabel='nombre'
           placeholder='Selecciona una plaza'
           onChange={onselectPlaza}
           value={elementoSelPlaza}
           options={dataUbicacion.ubicacions}
           /> 
        )
    }

	return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <div>
                <IonRow>
                    {/* <IonCol>
                        <IonText>Número de Unidad</IonText>	
                    </IonCol> */}
                    <IonCol>
                        <IonText>Número ECO</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <InputTextComponent
                            name="numeco"
                            value={fields.numeco === null ? "" : fields.numeco}
                            onChange={(e)=> {
                                setValue("numeco", e);
                                handleChange(e, 'numeco');
                            }}
                            disabled={null}
                            type={null}
                            required={true} 
                            style={{ width: "100%",color:'black'}}
                            accept={null}
                            className={null}
                            label={null}
                            onBlur={null}
                            onKeyPress={null}
                            placeholder={null}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="4" sizeXl="4">
                        <IonText>Tipo de Contrato</IonText>
                        {tipodeContrato()}		 
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonText>Cartuchos</IonText>
                    </IonCol>
                    <IonCol>
                        <IonText>Permisionario</IonText>
                    </IonCol>
                    <IonCol>
                        <IonText>Ubicación</IonText>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                    <InputTextComponent
                        name="cartuchos"
                        value={fields.cartuchos === null ? "" : fields.cartuchos}
                        onChange={(e)=> {
                                setValue("cartuchos",e)
                                handleChange(e, 'cartuchos');
                            }}
                        style={{ width: "100%",color:'black'}}
                        accept={null}
                        className={null}
                        disabled={null}
                        label={null}
                        onBlur={null}
                        onKeyPress={null}
                        placeholder={null}
                        required={null}
                        type={'number'}
                    />
                    </IonCol>
                    <IonCol>
                        {perm()}
                    </IonCol>
                    <IonCol>
                        {ciudad()}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        Ruta
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        {rutaDrop()}
                    </IonCol>
                </IonRow>
                <IonRow>
                    <br></br>
                    <br></br>
                    <IonCol>
                        <div>
                            {botones()} 
                        </div>
                    </IonCol>
                </IonRow>
            </div>
        </form>
	);
}