import { IonSpinner } from '@ionic/react';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const CargarListaUbicaciones = ({loading_listaUbicaciones, data_listaUbicaciones, selectubicaciones, onselectUbicacion}) => {
    if (loading_listaUbicaciones) return <IonSpinner name="dots" />;
    if (data_listaUbicaciones) {
        if (data_listaUbicaciones !== undefined) {
            return (
                <Dropdown
                    tabIndex={24}
                    showClear={true}
                    style={{ minWidth: '200px', borderRadius: '25px'  }}
                    value={selectubicaciones}
                    options={data_listaUbicaciones.ubicacions}
                    onChange={onselectUbicacion}
                    dataKey='id'
                    name={'ubicacion'}
                    filter
                    filterBy='nombre'
                    optionLabel='nombre'
                    placeholder="Selecciona la ubicación"
                    required={true} />
            )
        }
    }
}

export default CargarListaUbicaciones;

