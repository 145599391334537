/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";
import useNotification from "../../hooks/useNotification";
import {Toolbar} from "../../components";
import useAuthentication from "../../hooks/useAuthentication";
import  {FormDialogPauta}  from "../../components";
import { Redirect } from "react-router";
import {Button} from "primereact/button";

export default function InformacionCliente (Props){
    
    const [refresh, setRefresh] = useState(false);
	const {usuarioid} = useAuthentication();
	const [titleHeader, settitleHeader] = useState("Titulo");
	const [Bandera, setBandera] = useState(false);
	const [op, setop] = useState("");
	const [redirect, setRedirect] = useState(false);

	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
	
	

	const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}

	const IrASitios =()=>{
		setRedirect(true);

        setTimeout(() => {
            setRedirect(false);
        },2000);
	}
     

	let datos = {"createdby":usuarioid(),"modifiedby":usuarioid()}
	
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={Props.title} 
					setMenuEnabled={Props.setMenuEnabled}
					menuEnabled={Props.menuEnabled}>
				</Toolbar>
			</IonHeader>
			<IonContent>
		     	{redirect && <Redirect to="/page/sitios" />}
				{notification}
				<Button
				     style={{marginLeft:"10px"}}
                     label="Regresar a sitios" 
                     icon="pi pi-arrow-left"
                     onClick={()=>IrASitios()}
                    />
				<FormDialogPauta
				datos={datos}
				operacion ={op}
				onSuccess={onSuccess}
				onDiscard={onDiscard}
				></FormDialogPauta>
			</IonContent>
		</IonPage>
	);
}