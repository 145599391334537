import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent
} from "@ionic/react";
import {Toolbar, MToolBar, DTableV2} from "../../components";
import {PERMISIONARIOS_QUERY} from './queries';
import useAuthentication from "../../hooks/useAuthentication";
import useNotification from "../../hooks/useNotification";
import useDialog from "../../hooks/useDialog";
import FormPermisionarios from "./FormPermisionarios";
import {DataTable} from  "primereact/datatable";
import {Column} from "primereact/column";
import { renderCurrency } from "../../utils/dataTableAux";

const columnas = [
	{
		header: "Permisionario",
		field: "permisionario",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Esquema de Pago",
		field: "esquemaPago",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Costo",
		field: "costo",
		sortable: true,
		filter: true,
		filterMatchMode: "contains",
		body: renderCurrency
	},
    {
		header: "Número de Permisionario",
		field: "numPermisionario",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Número de unidades",
		field: "unidades_urbanos.length",
		filter: true,
		filterMatchMode:"equals"
	}
];

const Permisionarios = (props) => {
	const [refresh, setRefresh] = useState(false);
	const {usuarioid, rol} = useAuthentication();
   const [titleHeader, settitleHeader] = useState("Titulo");
   const {showDialog, FormDialog} = useDialog();
   const [expandedRows, setExpandedRows] = useState(null);
   
   let datosUnidades= [];

   const rowExpansionTemplate = (data)=>{
	
	for (let index = 0; index < data.unidades_urbanos.length; index++) {
		const element = data.unidades_urbanos[index];
		if(element.unidades_urbanos !== null){
			datosUnidades.push(element);
		}
	}
	console.log(datosUnidades);

	return (
		<div className="p-grid p-fluid" style={{padding: '.5em .5em .5em .5em'}}>
		{data.unidades_urbanos.length <= 0 && <p>Sin datos para mostrar</p>}
		{datosUnidades.length > 0 &&
		 <DataTable value={datosUnidades}>
			<Column field="numeco" header="NumEco" />
			<Column field="tipoContrato" header="Tipo de Contrato" />
			<Column field="cartuchos" header="Cartuchos" />
			<Column field="ruta.nombre" header="Ruta" />
			
		</DataTable>
		}
	</div>
	); 
}


   console.log(props)
   const {
    notification,
    showSuccess,
    showInfo
    } = useNotification();

    const addPermisionario=()=>{
        let data = {created_by:usuarioid(),updated_by:usuarioid()}
		showDialog(data, "crear");
		settitleHeader('Crear permisionario');
    }

    const onEdit = (data)=>{
        console.log(data)
        // if (data.updated_by===null){
		// 	data.updated_by = usuarioid();
		// }else{
		// 	var idcreated_by =data.updated_by.id;
		// 	data.updated_by=parseInt(idcreated_by);
		// }
        
		// data.updated_by=usuarioid();
		showDialog(data,'editar');
		settitleHeader('Editar información de permisionario');
    }

    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
    return(
        <IonPage>
            <IonHeader>
                <Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
            </IonHeader>
            <IonContent>
                <MToolBar addClick={addPermisionario}/>
                 {notification}
                <DTableV2
					autoLayout={true}
					paginator={true}
					ordenarPor='id:asc'
					query={PERMISIONARIOS_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element='permisionarios'
					canView={false}
					canDelete={false}
					canEdit={rol()===('Administrador') ? true : false}
					onEdit={onEdit}
					refresh={refresh}
					onEndRefresh={() => setRefresh(false)}
					rowExpansionTemplate={rowExpansionTemplate}
					expandedRows={expandedRows} 
					onRowToggle={(e) => {
						console.log('Valor de e ',e.data)
						setExpandedRows(e.data)
					}}
				/>
                <FormDialog
					header={titleHeader}
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormPermisionarios />
				</FormDialog>
            </IonContent>
        </IonPage>
    );
}
export default Permisionarios