/* eslint-disable no-unused-vars */
import React from 'react';
import {
    JS_REPORT_PASSWORD,
    JS_REPORT_TEMPLEATE_PAUTA_PATH,
    JS_REPORT_TEMPLEATE_SINGLE_PAUTA_PATH,
    JS_REPORT_USER, 
    URL_JS_REPORT_DEVELOP,
    URL_JS_REPORT_IMJ,
    URL_JS_REPORT_IMJ_SERVICES,
    JS_REPORT_TEMPLEATE_SINGLE_URBANO_PATH,
    JS_REPORT_TEMPLEATE_URBANO_PATH,
} from '../utils/Constanst';
import { SERVER_ERROR_JS_REPORT } from '../utils/Messages';

const jsreport = require("jsreport-browser-client-dist");

const useJSReportPDF = () => {debugger;
    jsreport.headers['Authorization'] = "Basic " + btoa(JS_REPORT_USER + ':' + JS_REPORT_PASSWORD) // correcta
    // jsreport.serverUrl = URL_JS_REPORT_DEVELOP;
    // jsreport.serverUrl = URL_JS_REPORT_IMJ;
    jsreport.serverUrl = URL_JS_REPORT_IMJ_SERVICES;
    jsreport.options = { "async": true, "save": true,  "public": true }

    /**
     * Funcion que hace un request al template especifico
     * con un renderizado normal (no asincrono);
     */
    const renderNormal = (templateName, data) => {
        var request = {
            template: {
                name: '/PDF/Pauta/pauta-main-multiple',
                engine: 'handlebars', 
                recipe: 'chrome-pdf',
                chrome: {
                    "landscape": true
                },
            },
            options: {
                reports: { "async": true, "save": true,  "public": true }
            },
            data: data,
        };
        jsreport.render(document.getElementById('placeholder'), request); // Funciona, que onda con render async?
    }

    /**
     * Funcion que hace un request de forma asincrona al template 
     * especifico para este formato de pauta en el servidor de JS Report 
     */
    const renderAsync = async (data) => {
        // Validacion de nombre
        let fileName = '';
        let response = '';
        // Objeto que contiene la informacion de request que se envia al servidor
        const request = {
            template: {
                name: JS_REPORT_TEMPLEATE_PAUTA_PATH, // Nombre y ruta del template al que se llama
                engine: 'handlebars', // Motor con el que se generara
                recipe: 'chrome-pdf', // Recipiente al que se mostrara
                chrome: {
                    "landscape": true // Opcion lanscape
                },
            },
            data: data, // data que se envia para el renderizado
            options: {
                reports: { "async": true,  "save": true,  "public": true }
                // reportName: fileName
            },
        };
        // console.log('request', request);
        /**
         * funcion render async que recibe el template que genera el PDF en el servidor
         * Y el callback maneja la respuesta. Cabe mencionar que la respuesta
         * es un link para un iframe que te da el link, para ver el PDF en el server de JSReport/IMJ
         */
        jsreport.renderAsync(request).then((res) => {debugger;
            const contentType = 'application/pdf'
            const dataView = new DataView(res);
            let blob;
            let objectURL;
            try {
                blob = new Blob([dataView])
              } catch (e) {
                console.log('Error', e);
                if (e.name === 'InvalidStateError') {
                    var byteArray = new Uint8Array(res)
                    blob = new Blob([byteArray.buffer], { type: contentType })
                } else {
                    throw e
                }
            }

            objectURL = URL.createObjectURL(blob)

            const placeholder = document.getElementById('placeholder') // Obteniendo elemento html placeholder
            const link = document.createElement("a"); // crea elemento anchor
            const iframe = document.createElement('iframe') // crea un elemento iframe
            iframe.id = 'myFrame'; // se le agrega un id para su posterior localizacion

            link.href = objectURL; // se agrega el contenido del response a un componente para el iframe
            link.download = fileName; // nombre de documento a descargar... que no funciona

            iframe.src = objectURL; // se agrega el contenido del response a un componente para el iframe
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            // clear placeholder
            while (placeholder.firstChild) {
                placeholder.removeChild(placeholder.firstChild);
            }
            placeholder.appendChild(iframe);

        }).then(() => {
            // Se crea un temporizador que espera unos segundos para obtener el link del iframe
            setTimeout(() => {
                const iframe_temp = document.getElementById("myFrame"); // Se obtiene instancia el iframe
                var elmnt = iframe_temp.contentWindow.document; // se obtiene elemento
                const bodyString = elmnt.getElementsByTagName("body")[0].innerHTML; // se obtiene el cuerpo del html
                // Se parte el string para obtener el url y concatenarselo al boton.
                const arr = bodyString.split('href=\'');
                const arr2 = arr[1].split('/status');
                const urlToPDF = arr2[0] + '/content';

                // setLinkURL(urlToPDF);
                response = urlToPDF;
                // setLoadingFlag(false);
                // window.open(urlToPDF, "_blank") // No lo hace automatico, no se por que
             }, 5000);             
        });

        return response;
    }

    /**
     * Hook que hace un request al template multiple
     * en el servidor de JSReport de IMJ
     * @param {any[]} data
     * @param {string} fileName
     * @param {(title: string, details: string) => void} showError 
     */
    const renderAsyncPautaMultipleDirectDownload = async (data, fileName, showError) => {
        let response = false;
        // Objeto que contiene la informacion de request que se envia al servidor
        const request = {
            template: {
                name: JS_REPORT_TEMPLEATE_PAUTA_PATH, // Nombre y ruta del template al que se llama
                engine: 'handlebars', // Motor con el que se generara
                recipe: 'chrome-pdf', // Recipiente al que se mostrara
                chrome: {
                    "landscape": true // Opcion lanscape
                },
            },
            data: data, // data que se envia para el renderizado
            options: {
				reports: { "save": true,  "public": true, "reportName": fileName, "blobName": fileName }
			},
        };
        // console.log('renderAsync = request', request);
        console.log('jsreport', jsreport);
        await jsreport.renderAsync(request).then((res) => {
            res.download(fileName);
            response = true;
        }, (error) => {
            showError('ERROR', SERVER_ERROR_JS_REPORT + JSON.stringify(error), 10000);
            response = false;
        });
        return response;
    };

    /**
     * Hook que hace un request al template individual
     * en el servidor de JSReport de IMJ
     * @param {any} data 
     * @param {string} fileName
     * @param {(title: string, details: string) => void} showError 
     */
    const renderAsyncPautaSingleDirectDownload = async (data, fileName, showError) => {
        let response = false;
        // Objeto que contiene la informacion de request que se envia al servidor
        const request = {
            template: {
                name: JS_REPORT_TEMPLEATE_SINGLE_PAUTA_PATH, // Nombre y ruta del template al que se llama
                engine: 'handlebars', // Motor con el que se generara
                recipe: 'chrome-pdf', // Recipiente al que se mostrara
                chrome: {
                    "landscape": true // Opcion lanscape
                },
            },
            data: data, // data que se envia para el renderizado
            options: {
				reports: { "save": true,  "public": true, "reportName": fileName, "blobName": fileName }
			},
        };
        console.log('renderAsync = request', request);
        console.log('jsreport', jsreport);
        await jsreport.renderAsync(request).then((res) => {
            res.download(fileName);
            response = true;
        }, (error) => {
            showError('ERROR', SERVER_ERROR_JS_REPORT + JSON.stringify(error), 10000);
            response = false;
        });
        return response;
    };

    /**
     * 
     * @param {any} data 
     * @param {string} fileName 
     * @param {(title: string, details: string) => void} showError 
     */
    const renderAsyncPDFUrbanosMultipleDirectDownload = async (data, fileName, showError) => {
        let response = false;
        // Objeto que contiene la informacion de request que se envia al servidor
        const request = {
            template: {
                name: JS_REPORT_TEMPLEATE_URBANO_PATH, // Nombre y ruta del template al que se llama
                engine: 'handlebars', // Motor con el que se generara
                recipe: 'chrome-pdf', // Recipiente al que se mostrara
                chrome: {
                    "landscape": true // Opcion lanscape
                },
            },
            data: data, // data que se envia para el renderizado
            options: {
				reports: { "save": true,  "public": true, "reportName": fileName, "blobName": fileName }
			},
        };
        // console.log('renderAsync = request', request);
        console.log('jsreport', jsreport);
        await jsreport.renderAsync(request).then((res) => {
            res.download(fileName);
            response = true;
        }, (error) => {
            showError('ERROR', SERVER_ERROR_JS_REPORT + JSON.stringify(error), 10000);
            response = false;
        });
        return response;
    };

    /**
     * Hook que hace un request al template individual
     * en el servidor de JSReport de IMJ
     * @param {any} data 
     * @param {string} fileName
     * @param {(title: string, details: string) => void} showError 
     */
    const renderAsyncUrbanosSingleDirectDownload = async (data, fileName, showError) => {
        let response = false;
        // Objeto que contiene la informacion de request que se envia al servidor
        const request = {
            template: {
                name: JS_REPORT_TEMPLEATE_SINGLE_PAUTA_PATH, // Nombre y ruta del template al que se llama
                engine: 'handlebars', // Motor con el que se generara
                recipe: 'chrome-pdf', // Recipiente al que se mostrara
                chrome: {
                    "landscape": true // Opcion lanscape
                },
            },
            data: data, // data que se envia para el renderizado
            options: {
				reports: { "save": true,  "public": true, "reportName": fileName, "blobName": fileName }
			},
        };
        // console.log('renderAsync = request', request);
        console.log('jsreport', jsreport);
        await jsreport.renderAsync(request).then((res) => {
            res.download(fileName);
            response = true;
        }, (error) => {
            showError('ERROR', SERVER_ERROR_JS_REPORT + JSON.stringify(error), 10000);
            response = false;
        });
        return response;
    };

    return {
        _renderAsyncPautaMultipleDirectDownload : renderAsyncPautaMultipleDirectDownload,
        _renderAsyncPautaSingleDirectDownload : renderAsyncPautaSingleDirectDownload,
        _renderAsync : renderAsync,
        _renderAsyncPDFUrbanosMultipleDirectDownload: renderAsyncPDFUrbanosMultipleDirectDownload,
        _renderAsyncUrbanosSingleDirectDownload: renderAsyncUrbanosSingleDirectDownload,
    }
};

export default useJSReportPDF;
