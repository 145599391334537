import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
import {formatMoney} from '../../../utils/Utils';
const imjColors = ['#126efa', '#fc3d38', '#1cb5b2','#d11787','#fcb32b','#874afa','#bfeb08','#1cbf59'];

 ////////////////////////////////PDF COTIZADOR///////////////////////////////////////////
export const exportPDF = async (dataEXcel, imgUrl, campania, colorVista, fecha_fin, fecha_inicio, cliente, responsable) =>{   
    let valuesToExport=dataEXcel;
    const doc = new jsPDF('landscape');
    //Logo de campañas
    var img = new Image();
    img.src=imgUrl
    if (imgUrl === null || imgUrl ===undefined || imgUrl ===""){
        img.src = 'assets/IMJ.png';
    }
    doc.addImage(img, 'PNG', 15, 5, 45, 30);
    doc.setFontSize(24); 
    const campaniadefault="IMJ";
    if(campania === null|| campania === undefined || campania === ""){
        
        doc.text('Pauta '+campaniadefault, 100, 22);
    }
    else{
        doc.text('Pauta '+campania, 100, 22);
    }
    autoTable(doc, { startY: 30 });
    autoTable(doc, {   
        columnStyles: { 2: { halign: 'right' }, 3:{halign: 'right'} }, // Cells in first column centered
   margin: { top: 10 },
    //color de los headers
    didParseCell: function (table) {
        if (table.section === 'head') {
          table.cell.styles.fillColor = '#'+colorVista;
          if (colorVista === null || colorVista === undefined || colorVista === ""){
            table.cell.styles.fillColor = imjColors[4];
          }
        }
     },     

    head: [['Cliente', 'Campaña', 'Inicio', 'Fin', 'Responsable']],
    body: [
         [cliente, campania, new Date(fecha_inicio).toLocaleDateString('es-MX'), new Date(fecha_fin).toLocaleDateString('es-MX'), responsable] 
      ],

  })
    
    var pageNumber = doc.internal.getNumberOfPages()
    //Contenido de pauta
    var rows=[];
    var rowrow=[];
    var subtotalmensual=0;
    var subtotalF=0;
    var col=['Zona', 'Tipo', 'Producto', 'Cantidad', 'Inicio', 'Fin', 'Descripción', 'Precio unitario por mes', 'Descuento', 'Total'];  
    valuesToExport.forEach(element =>{
        //validación de 0 en descuento
        if ( element.descuento === null || element.descuento === undefined) {
            element.descuento=0;
         } 
        var temp=[element.zona, element.tipo, element.producto, element.cantidad, new Date(element.fecha_inicio).toLocaleDateString('es-MX'), new Date(element.fecha_fin).toLocaleDateString('es-MX'), element.descripcion, '$'+formatMoney(element.precio), (element.descuento+'%')];
        //diferencia de fechas
        var fechaInicio = new Date(element.fecha_inicio).getTime();
        var fechaFin    = new Date(element.fecha_fin).getTime();
        var diff = fechaFin - fechaInicio;
        let diferencia=Math.ceil(diff/(1000*60*60*24)/31);
        subtotalmensual+=element.precio;
        var total=(element.precio-(element.precio*element.descuento/100))*diferencia*element.cantidad;
        subtotalF+=total
        temp.push('$'+formatMoney(total));
        rows.push(temp);
    });
    var subtotal=['', '', '', '', '', '', 'Subtotal Mensual: ', '$'+formatMoney(subtotalmensual), 'Subtotal: ', '$'+formatMoney(subtotalF)]
    var ivacalc=subtotalF*.16
    var iva=['', '', '', '','','','','','IVA :', '$'+formatMoney(ivacalc)]
    var totalF=ivacalc+subtotalF
    var totalFinal=['', '', '', '','','','','','Total: ', '$'+formatMoney(totalF)]
    rowrow=[...rows];
    rowrow.push(subtotal)
    rowrow.push(iva)
    rowrow.push(totalFinal)
    
    autoTable(doc, {
        //FUnción que pinta los headers
        didParseCell: function (table) {
            if (table.section === 'head') {
                table.cell.styles.fillColor = '#'+colorVista;
                if(colorVista === null || colorVista === undefined || colorVista === "")
                {
                    table.cell.styles.fillColor = imjColors[4]
                }
            }
         }, 
        columns: [
            { dataKey: 'zona' },
            { dataKey: 'tipo' },
            { dataKey: 'producto' },
            { dataKey: 'cantidad' },
            { dataKey: 'fecha_inicio' },
            { dataKey: 'fecha_fin' },
            { dataKey: 'descripcion' },
            { dataKey: 'precio' },
            { dataKey: 'descuento'},
            { dataKey: 'total'}
          ],
          head:[col],
          body:
            rowrow
          ,
       columnStyles: {
           4: {halign: 'right'},
           5: {halign: 'right'},
           7: {halign: 'right'},
           9: {halign: 'right'}
    },
        margin: { top: 10 },
      })

      doc.setPage(pageNumber);
      if(campania === null || campania === undefined || campania === "")
      {
        doc.save('Pauta_'+campaniadefault+'_Export_'+ new Date().getTime()+'.pdf')  
      }
      else{
        doc.save(campania+'_Export_'+ new Date().getTime()+'.pdf')  
      }       
}
///////////////////////////FIN PDF COTIZADOR////////////////////////////////////////////
