import React from 'react';
import {InputText} from "primereact/inputtext";


/**
 * 
 * @param {Object} props
 * @param {string} props.name
 * @param {any} props.value
 * @param {any} props.style
 * @param {boolean} props.required
 * @param {string} props.type
 * @param {string} props.label
 * @param {string} props.className
 * @param {string} props.placeholder
 * @param {boolean} props.disabled
 * @param {any} props.accept
 * @param {(newValue: string) => void} props.onChange
 * @param {(event: any) => void} props.onKeyPress
 * @param {(event: any) => void} props.onBlur
 * @returns 
 */
const InputTextComponent = ({
    name,
    value,
    style,
    required = false,
    disabled = false,
    onChange = null,
    label,
    type = 'text',
    className = '',
    placeholder = '',
    onKeyPress = null,
    accept = null,
    onBlur = null,
}) => {
    return (
        <>
            {label ? (
                <div>
                    <span className="p-float-label">
                        <InputText
                            required={required}
                            value={value}
                            name={name}
                            placeholder={placeholder}
                            type={type}
                            className={className}
                            style={style ? style : null}
                            onKeyPress={e => onKeyPress ? onKeyPress(e) : null}
                            onChange={e => onChange(e.target['value'])}
                            disabled={disabled}
                            accept={accept}
                            onBlur={onBlur}
                        />
                        {label ? (<label htmlFor="in">{label}</label>) : null}
                    </span>
                </div>
            ) : (
                <InputText
                    required={required}
                    value={value}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    className={className ? className : null}
                    style={style ? style : null}
                    onChange={e => onChange(e.target['value'])}
                    disabled={disabled}
                    accept={accept}
                    onBlur={onBlur}
                />
            )}

        </>
    );
};

export default InputTextComponent;