/* eslint-disable use-isnan */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React ,{useState} from 'react';
import {CREAR_USUARIO,LISTA_ROLES,ACTUALIZAR_USUARIO} from './queries';
import { useMutation,useQuery } from '@apollo/client';
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";
import { IonRow,
         IonCol,
         IonSpinner} from '@ionic/react';
import {Dropdown} from 'primereact/dropdown';
import { InputTextComponent } from '../../components';


export default function FormUsuario(props){

    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
           nombre : props.datos.nombre,
           username :  props.datos.username,
           email : props.datos.email,
           role: props.datos.role
		}
    });

    const [fields,setValueFields] = useState(props.datos);

    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
		delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
        delete fieldtemp.updated_at;
        if(fieldtemp.password === null || fieldtemp.password === ""){
            delete fieldtemp.password;
        }
        fieldtemp.role = (Idrol === NaN ? 1 : Idrol);
        fieldtemp.blocked=(blockedBoolean === undefined ? false : blockedBoolean);
		return fieldtemp;
    };
    
    const handleChange = (e, name = '') =>{
        setValueFields({...fields,[name !== '' ? name : e]:e})
    }

    const onSubmit = data => { 

        if (props.operacion==='editar' ){
          
           ActualizarUsuario();

        }else if(props.operacion === "crear"){
            
            CrearUser();
            
        }
       
    }

    /* Variables */
    const [blockedBoolean, setblockedBoolean] = useState(fields.blocked);
    const [Idrol, setIdrol] = useState((props.datos.role === undefined ? null : parseInt(props.datos.role.id)));
    const [elementoSel,setElementoSel] = useState<string>(fields.role === null ? "" : fields.role);


    /* Query */
    const [CrearUser] = useMutation(CREAR_USUARIO,{
        variables:{
            input:{
                data:getFieldsUpdate()
            }
        },
        onCompleted:(data) =>{
            //console.log(data);
            props.onSuccess();
        }
    });

    const [ActualizarUsuario] =useMutation(ACTUALIZAR_USUARIO,{
        variables:{
            input:{
                where: { id: fields.id },
                data:getFieldsUpdate()
            }
        },
        onCompleted(data){
            props.onSuccess();
        }
    });
    
    const {loading, error : error_listaRoles, data} = useQuery(LISTA_ROLES,{
        
        onCompleted: (data) =>{
           // console.log(data.roles);
        }
    });

    const ListarRoles = () =>{
        if (loading) return <IonSpinner name="dots" />
        if (data) return (


            <Dropdown 
                tabIndex={1}
                showClear={true}
                style={{width:'100%'}}
                value={elementoSel} 
                options={data.roles} 
                onChange={onselect} 
                dataKey='id'
                optionLabel='name'
                placeholder="Selecciona un roll"
                required={true}
            />
        )
    }
   
    const onselect=(selectId)=>{
        if(selectId.value){
            setIdrol(parseInt(selectId.value.id));
            setElementoSel(selectId.value);
        }else{
            setIdrol(null);
            setElementoSel(null);
        }
    }

    return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="6" sizeXl="6">
                    <br></br>
                    <span className="p-float-label">
                        <InputTextComponent
                            name="nombre"
                            type="text"
                            value={fields.nombre === null ? "" : fields.nombre}
                            style={{ width: "100%" }}
                            required
                            onChange={e => {
                                handleChange(e, 'nombre');
                                setValue("nombre", e);
                            }}
                            accept={null}
                            className={null}
                            disabled={null}
                            label={null}
                            onBlur={null}
                            onKeyPress={null}
                            placeholder={null}
                        />
                        <label htmlFor="in">Nombre</label>
                    </span>    
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="6" sizeXl="6">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                name="username"
                                type="text"
                                value={fields.username === null ? "" : fields.username }
                                style={{width : "100%"}}
                                required
                                onChange={e =>{
                                    handleChange(e, "username");
                                    setValue("username", e);
                                }} 
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}

                            />
                            <label htmlFor="in">Nombre de usuario</label>
                        </span>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                name="email"
                                type="email"
                                value={fields.email === null ? "" : fields.email}
                                style={{width:"100%"}}
                                required
                                onChange={e  =>{
                                    handleChange(e, "email");
                                    setValue("email", e);
                                }}
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                                <label htmlFor="in">Email</label>
                        </span>    
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="4" sizeXl="4">
                        <br></br>
                        <span className="p-float-label">
                            <InputTextComponent
                                name="password"
                                type="password"
                                value={fields.password === null ? "" : fields.password }
                                style={{width:"100%"}}
                                onChange={e =>{
                                    handleChange(e, 'password');
                                    setValue("password", e);
                                }}
                                accept={null}
                                className={null}
                                disabled={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                required={null}
                            />
                            <label htmlFor="in">Password</label>
                        </span>    
                    </IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="4" sizeXl="4">
                        <br></br>
                        {ListarRoles()}
                    </IonCol>
                </IonRow>
                <br></br>
                <IonRow>
                    <IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
                        <Button 
                            label="Registrar"
                            icon="pi  pi-save"
                            className="p-button-success ion-float-right"
                            type="submit"
                            style={{ marginLeft: "20px"}}
                        />
                    </IonCol>
                    <IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
                        <Button 
                            label="Cancelar"
                            icon="pi pi-times"
                            float-right="true"
                            className="p-button-danger ion-float-left"
                            onClick={props.onDiscard}
                            style={{marginLeft:"20px"}}
                        />
                    </IonCol>
                </IonRow>
            </form>
    )

}

