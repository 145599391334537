/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {IonRow, IonCol,IonText, IonGrid, IonSpinner,} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import {LISTAR_RUTAS,
        LISTAR_CAMPANIAS,
        CREATE_CAMPANIASURBANOS,
        UPDATE_CAMPANIASURBANOS,
        CAMPANIAS_URBANOS_QUERY} from "./query";
import { InputTextComponent } from "../../components";
import {Dropdown} from 'primereact/dropdown'
import { useQuery,
        useMutation,
        useLazyQuery} from "@apollo/client";
import moment from 'moment';
import { UNIDADES_URBANOS_QUERY } from "../unidades-urbanos/queries";
import { useNotification } from "../../hooks";
import MultiSelect from "react-multi-select-component"; 

export default function FormCampaniasUrbanos (props){
    

    const [refresh, setRefresh] = useState(false);
    const [ruta, setRuta]=useState();
    const [idRuta, setIdRuta]=useState(0);
    const [elementoSelRuta, setElementoSelRuta]=useState(null)

    const [unidadesurbano, setUnidadesUrbano]=useState();
    const [idUnidadesUrbano, setIdUnidadesUrbano]=useState(0);
    const [elementoSelUnidadesUrbano, setElementoSelUnidadesUrbano]=useState(null);

    const [campania, setCampania]=useState();
    const [idCampania, setIdCampania]=useState(0);
    const [elementoSelCampania, setElementoSelCampania]=useState(null)

    const [fecha, setFecha]=useState(moment().format('YYYY-MM-DD'));
    const [tomorrow, setTomorrow]=useState(moment().add(1, 'month').format('YYYY-MM-DD'));
    const [fecha_inicio, setFecha_inicio] = useState(fecha);
    const [fecha_fin, setFecha_fin] = useState(tomorrow);
    
    //Multiselect
    const[opciones, setOpciones]=useState([])
    const[selected, setSelected]=useState([])


    const {
        notification,
        showSuccess,
        showWarn
        } = useNotification();
    

    const onSubmit= (data) =>{
        selected.forEach(element=>{
            var id=parseInt(element.value)
            recorrerArray(id)
        })  
	}

    const recorrerArray=(id)=>{
        console.log("Recorrer Array",id)
        crearCampaniasurbano({
            variables: {
                input: {
                    data:{
                        unidades_urbano:id,
                        campania:idCampania,
                        inicia:fecha_inicio,
                        termina:fecha_fin
                    }  
                }
            },
        })
    }

    ///////////////////////////////////////Llenado de Dropdowns/////////////////////////////////////////////////
    /////////////////////LLenado de ruta
    const {loading:loadingRuta, data:dataRuta , error:errorRuta}= useQuery(LISTAR_RUTAS, {
        variables:{
            sort:"clave:ASC",
            where:{
                "activo":true,
                "ubicacion":1
            }
        }, onCompleted(dataRuta){
            console.log("Rutas",dataRuta)
        }, onError(errorRuta){
            console.log("Error RUTAS: ",errorRuta)
        }
    });
    /////////////////////////Llenado de campaña
    const {loading:loadingCampania, data:dataCampania, error:errorCampania}=useQuery(LISTAR_CAMPANIAS, {
        variables:{
            sort:"created_at:ASC",
            where:{
                _or:[
                    {estatus:"Cerrado Ganado"},
                    {estatus:"Cotización personalizada"},
                    {estatus:"Cotización informal"}
                ],
                preciourbanos_gt:0
            }
        }, onCompleted(dataCampania){
            console.log("Campañas, ", dataCampania)
        }, onError(errorCampania){
            console.log("Error Campañas ",errorCampania)
        }
    });
    ///////////////////LLenado de ECO de camiones
    const {loading:loadingEco, data:dataEco, error:errorEco}=useQuery(UNIDADES_URBANOS_QUERY, {
        variables:{
            sort:"id:ASC",
            where:{
                "activo":true,
                "ruta":idRuta
            }
        }, onCompleted(dataEco){
            console.log("Ecos disponibles ", dataEco.unidadesUrbanos)
            const options=[]
            dataEco.unidadesUrbanos.forEach(element => {
                var x={
                    label:element.numeco+' - '+element.permisionario.permisionario,
                    value:element.id
                }
                options.push(x)
                console.log(options)
            });
            setOpciones(options)
            
        }, onError(errorEco){
            console.log("Error Ecos ",errorEco)
        }
    });

     //**************************MUTACIONES UPDATE Y CREATE ***************************************//
    const [crearCampaniasurbano] = useMutation(CREATE_CAMPANIASURBANOS, {
		
		onCompleted(data) {
            console.log(data)
            showSuccess('Operación exitosa', 'Se realizó la operación con éxito')
            setElementoSelRuta(null);
            setRuta(null);
            setElementoSelUnidadesUrbano(null);
            setUnidadesUrbano(null)
            setSelected([])
            

		}, onError(error){
            console.log("Error ",error)
            showWarn('Error', 'No se pudo completar la operación')
        }
	});

    const [updateCampaniasurbano] = useMutation(UPDATE_CAMPANIASURBANOS, {
		variables: {
			input: {
				where: { id: props.id },
				data: {
                    unidades_urbano:idUnidadesUrbano,
                    campania:idCampania,
                    inicia:fecha_inicio,
                    termina:fecha_fin,
                }	
			}
		},
		onCompleted(data){
            console.log(data)
            props.onSuccess();
		}
	});

    /////////////////////DROPDOWN RUTAS/////////////////////////
    const listarRutas=()=>{
        if(errorRuta) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingRuta) return  <IonSpinner name="dots" />;
        if(dataRuta)   return(
			<Dropdown
			name="rutas" 
			tabIndex={1}
			showClear={true}
			style={{width:'100%',borderRadius: "25px"}}
            dataKey='id'
            optionLabel='nombre'
            filter
            filterBy='nombre'
			value={elementoSelRuta} 
			options={dataRuta.rutas} 
			onChange={onselectRuta}
			placeholder='Selecciona la ruta'
			required={true}
		/>
		)
    }

    const onselectRuta=(selectR)=>{
        if(selectR.value){
            setSelected([])
            setElementoSelRuta(selectR.value);
            setRuta(selectR.value.nombre);
            setIdRuta(parseInt(selectR.value.id));
            console.log(selectR.value.nombre)
        }else{
            setElementoSelRuta(null);
            setRuta(null);
            setElementoSelUnidadesUrbano(null);
            setUnidadesUrbano(null)
            setSelected([])
        }
    }

    /////////////////////////////////////////NUMECO/////////////////////////////////////////////////////
    const   OverrideString = {
        "selectSomeItems": "Seleccionar...",
        "allItemsAreSelected": "Todos los ECO están seleccionados.",
        "selectAll": "Todos",
        "search": "Buscar",
        "clearSearch": "Limpiar búsqueda"
    }

    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => "✔️ " + label)
            : "Seleccionar ECO";
    };

    const listarNumEco=()=>{
        if(errorEco) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingEco) return  <IonSpinner name="dots" />;
        if(dataEco)   return(
            <>
            <MultiSelect
                options={opciones}
                value={selected}
                onChange={setSelected}
                labelledBy="numeco"
                overrideStrings={OverrideString}
                valueRenderer={customValueRenderer}
            />
            </>
		)
    }

    /////////////////////////////////////////CAMPAÑA
    const listarCampania=()=>{
        if(errorCampania) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingCampania) return  <IonSpinner name="dots" />;
        if(dataCampania)   return(
			<Dropdown
			name="campania" 
			tabIndex={1}
			showClear={true}
			style={{width:'100%',borderRadius: "25px"}}
            dataKey='id'
            optionLabel='nombre'
            filter
            filterBy='nombre'
			value={elementoSelCampania} 
			options={dataCampania.campanias} 
			onChange={onselectCampania}
			placeholder='Selecciona la campaña a ser asignada'
			required={true}
		/>
		)
    }

    const onselectCampania=(selectC)=>{
        if(selectC.value){
            setElementoSelCampania(selectC.value);
            setCampania(selectC.value.nombre);
            setIdCampania(parseInt(selectC.value.id));
            setFecha_inicio(selectC.value.inicia);
            setFecha_fin(selectC.value.termina);
            console.log(selectC.value);
        }else{
            setElementoSelCampania(null);
            setCampania(null);
            setFecha_inicio(fecha)
            setFecha_fin(tomorrow)
        }
    }

    return (
            <div>
            <IonGrid>
                {notification}
                    <IonRow>
                        <IonCol>
                            Ruta:
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {listarRutas()}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            Campaña:
                        </IonCol>
                        <IonCol>
                            Fecha de Inicio de Campaña:
                        </IonCol>
                        <IonCol>
                            Fecha de Fin de Campaña:
                        </IonCol>  
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {listarCampania()}
                        </IonCol>
                        <IonCol>
                            <InputTextComponent
                                name={"fecha_inicio"}
                                type={"date"}
                                value={fecha_inicio}
                                style={{width:'100%',borderRadius: "25px"}}
                                required={true}
                                onChange={e =>{
                                    setFecha_inicio(e);
                                }}
                                className={null}
                                disabled={null}
                                onKeyPress={null}
                                placeholder={null}
                                label={null}
                                accept={null}
                                onBlur={null}
                            />
                        </IonCol>
                        <IonCol>
                            <InputTextComponent
                                name={"fecha_fin"}
                                type={"date"}
                                value={fecha_fin}
                                style={{width:'100%',borderRadius: "25px"}}
                                required={true}
                                onChange={e =>{
                                    setFecha_fin(e);
                                }}
                                className={null}
                                disabled={null}
                                onKeyPress={null}
                                placeholder={null}
                                label={null}
                                accept={null}
                                onBlur={null}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            NumEco
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {listarNumEco()}
                        </IonCol> 
                    </IonRow>
                    <IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Guardar"
								icon="pi pi-check"
								onClick={onSubmit}
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
            </IonGrid>     
            </div>
	);
}
