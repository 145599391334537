import React, {useState} from "react";
import {DTableV2} from "../../components";
import {DataTable} from  "primereact/datatable";
import {Column} from "primereact/column";
import { renderCurrency } from "../../utils/dataTableAux";
import {
    LISTAR_ODC
} from "./queries"

const columnas = [
    {
        header: "Campaña",
        field: "campania.nombre",
        sortable: true,
        filter: true,
        filterMatchMode: "contains"
    },
    {
        header: "Nombre",
        field: "nombre",
        sortable: true,
        filter: true,
        filterMatchMode: "contains"
    },
    {
        header: "Referencia de Proveedor",
        field: "proveedor_ref",
        sorteable: true,
        filter: true,
        filterMatchMode: "contains"
    },
    {
        header: "Fecha de Orden",
        field: "fecha_orden",
        sortable: true
    },
    {
        header: "Fecha de Aprobación",
        field:"fecha_aprov",
        sortable: true,
    },
    {
        header: "Proveedor",
        field: "proveedor",
        sortable: true,
        filter: true,
        filterMatchMode: "contains"
    },
    {
        header: "Notas",
        field: "notas"
    },
    {
        header:"Importe",
        field: "importe",
        body: renderCurrency
    },
    {
        header:"IVA",
        field:"iva",
        body: renderCurrency
    },
    {
        header:"Total",
        field:"total",
        body: renderCurrency
    },
    {
        header:"Cuenta Analítica",
        field: "cuenta_analitica",
        sortable: true,
        filter: true,
        filterMatchMode:"contains"
    },
    {
        header: "Estado",
        field: "estado",
        sortable: true,
        filter: true,
        filterMatchMode: "contains"
    }
];

const TablaODC=(props)=>{
    const [refresh, setRefresh] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    let datosODC=[]

    const rowExpansionTemplate=(data)=>{
        for (let index=0; index<data.order_line_json.length; index++)
        {
            const element=JSON.parse(data.order_line_json[index].replaceAll("'", String.fromCharCode(34)))
            datosODC.push(element)
        }
        return(
            <div className="p-grid p-fluid" style={{padding: '.5em .5em .5em .5em'}}>
                {data.order_line_json.length <= 0 && <p>Sin datos para mostrar</p>}
                {datosODC.length>0 &&
                <DataTable value={datosODC}>
                    <Column field="nombre" header="Nombre"/>
                    <Column field="cantidad" header="Cantidad"/>
                    <Column field="impuesto" header="Impuesto" body={renderCurrency}/>
                    <Column field="precio" header="Precio" body={renderCurrency}/>
                    <Column field="punitario" header="Precio Unitario" body={renderCurrency}/>
                    <Column field="subtotal" header="Subtotal" body={renderCurrency}/>
                </DataTable>}
            </div>
        )
    }

    return(
        <DTableV2
            filtro={{"campania":props.pauta.campania.id}}
            query={LISTAR_ODC}
            columnas={columnas}
            style={{ margin: "15px" }}
            element="ordencompras"
            ordenarPor={'created_at:desc'}
            refresh={refresh}
            paginator={true}
            onEndRefresh={() => setRefresh(false)}
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows} 
			onRowToggle={(e) => {
				console.log('Valor de e ',e)
				setExpandedRows(e.data)
			}}
        />
    )
}
export default TablaODC;