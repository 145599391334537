/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { IonText } from '@ionic/react';
import { gql } from '@apollo/client';
import './mapa.styles.css';
import {useLazyQuery} from '@apollo/client';
import {InputTextComponent} from '../../components';

const MEDIO=gql`
    query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            direccion
            medida
            claveproveedor
            disponible
            activo
            vialidad
            colonia
            niveldeimpacto
            iluminacion
            nicho
            referencias
            comentarios
            costo
            precioventa
            latitud
            longitud
            Vista_corta
            Vista_media
            Vista_larga
            male_users
            female_users
            a13_17_users
            a18_25_users
            a26_40_users
            a41_55_users
            a55_users
            income_a_users
            income_b_users
            income_c_users
            income_d_users
            income_e_users
            total_hits
            total_users
            reach
            frequency
            imagenmapaubicacion
            material
            tipoacabado
            especiales
            universo
            ubicacion{
                id
                nombre
            }
            vista{
                id
                nombre
            }
            tipos{
                id
                nombre
            }
            proveedor{
                id
                nombre
            }	
            Createdby{
                id
                email
            
            }
            Modifiedby{
                id
                email

            }
        
        }
    } 
`;

export default function BuscaClave(props) {
    const [value,setValue] = useState(undefined);
    const [ refetch ] = useLazyQuery(MEDIO,{
        onCompleted:(data)=>{
            props.onSearchResult(data);
            if (props.loading){
                props.loading(false);
            }
        }
    })

    useEffect(() => {
        if (value!==null && value!==''){
            refetch({
                variables:{
                    where:{
                        clave_contains: value
                    }
                }
            });
        }else{
            props.onSearchResult(null);
        }
    }, [value])
    return (
        <div className={'margin-search-by-clave'}>
            <IonText className={'margin-bottom-ion-text'}>Buscar por clave</IonText>
            <InputTextComponent
                value={value} 
                placeholder={'Introduzca la clave'}
                // debounce={800}
                onKeyPress={e => {
                    console.log('e', e);
                    if (e.key==='Enter'){
                        if (props.loading){
                            props.loading(true);
                        }
                        refetch({
                            variables:{
                                where:{
                                    clave_contains: e.currentTarget['value']
                                }
                            }
                        });
                        // setValue(e.currentTarget['value']);
                    }
                }}
                onBlur={null}
                label={null}
                disabled={null}
                name={null}
                onChange={(e) => setValue(e)}
                required={null}
                style={null}
                type={null}
                accept={null}
                className={'search-by-clave'}
                css={null}
                key={null}
            />
        </div>
            
    )
}
