import React, { useState, useEffect } from "react";
import { IonCardContent, IonText, IonSpinner, IonCard, IonGrid, IonCardSubtitle, IonRow, IonCol, IonCardHeader, IonCardTitle} from "@ionic/react";
import { useQuery } from "@apollo/client";
import { LISTAR_TESTIGOS } from "./queries";
import { Carousel } from 'primereact/carousel';
import './carousel.css';

export default function FormDetalles(props){
    console.log("PROPS",props)
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

     /////////////////////////////////////////////////Query que carga los testigos
	const {loading:loadingTestigos, data:dataTestigos , error:errorTestigos}= useQuery(LISTAR_TESTIGOS, {
        variables:{
            where:{
                detailpauta:props.datos.row.id
            }
        }, onCompleted(dataEco){
            console.log("TESTIGOS",dataTestigos.testigos)
            
        }, onError(error){
            console.log("Error TESTIGOS: ",errorTestigos)
        }
    });


    if(errorTestigos){
        return(
            <IonCard>
                <h5 color="red">Algo salió mal</h5>
            </IonCard>
        )
    }
    if (loadingTestigos){
        return(
            <>
                <IonCard>
                    <IonText>Cargando testigos, por favor espere...</IonText>
                    <IonSpinner name="dots"></IonSpinner>
                </IonCard>
            </>
        )
    }
    if(dataTestigos)
        
        if(dataTestigos.testigos.length===0){
            return(
                <IonCard>
                    <IonCardContent>
                        <IonText>No hay testigos para mostrar</IonText>
                    </IonCardContent>
                </IonCard>
            )
        }
        if(dataTestigos.testigos.length>0){
            const imagenes=dataTestigos.testigos;
            var imagMap=[]
            if(props.datos.medio==="sitios"){
                for(var i=0; i<imagenes.length; i++){
                    var x={
                        id: i,
                        nombre: imagenes[i].imagen.hash,
                        imagen: imagenes[i].imagen.formats.medium.url,
                        tipofoto:imagenes[i].vista_testigo.vista,
                        fecha: imagenes[i].fecha_tomada,
                        reporte: imagenes[i].reporte
                    }
                    imagMap.push(x)
                }
                const template=(index)=>{
                    return(
                        <div className="product-item">
                            <div className="product-item-content">
                                <div className="mb-3" >
                                    <img src={"https://services.imjmedia.com.mx"+index.imagen} alt={index.nombre} style={{marginLeft:'70px'}}/>
                                </div>
                                <div>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <IonCardSubtitle style={{textAlign:"center"}}>Tipo de Vista:<br/>{index.tipofoto}</IonCardSubtitle>
                                            </IonCol>
                                            <IonCol>
                                                <IonCardSubtitle style={{textAlign:"center"}}>Fecha del testigo: <br/>{index.fecha}</IonCardSubtitle>
                                            </IonCol>
                                            <IonCol>
                                                <IonCardSubtitle style={{textAlign:"center"}}>Número de reporte: <br/>{index.reporte}</IonCardSubtitle>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </div>
                            </div>
                        </div>
                    )
                }
                return(
                    <div className="carousel-demo">
                        <div className="card"> 
                            <Carousel value={imagMap} itemTemplate={template} responsiveOptions={responsiveOptions}/> 
                        </div> 
                    </div>
                )
            }
            if(props.datos.medio==="urbano"){
                for(var j=0; j<imagenes.length; j++){
                    var y={
                        id: j,
                        nombre: imagenes[j].imagen.hash,
                        imagen: imagenes[j].imagen.formats.medium.url,
                        eco: imagenes[j].unidades_urbano.numeco,
                        tipofoto:imagenes[j].tipo_exhibicion.tipoexhibicion,
                        fecha: imagenes[j].fecha_tomada,
                        reporte: imagenes[j].reporte
                    }
                    imagMap.push(y)
                }
                const template=(index)=>{
                    console.log(index)
                    return(
                        <div className="product-item">
                            <div className="product-item-content">
                                <div className="mb-3" >
                                    <img src={"https://services.imjmedia.com.mx"+index.imagen} alt={index.nombre} style={{marginLeft:'70px', maxHeight:'600px'}}/>
                                </div>
                                <div>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                <IonCardSubtitle style={{textAlign:"center"}}>Tipo de Exhibición:<br/>{index.tipofoto}</IonCardSubtitle>
                                            </IonCol>
                                            <IonCol>
                                                <IonCardSubtitle style={{textAlign:"center"}}>Número económico: <br/>{index.eco}</IonCardSubtitle>
                                            </IonCol>
                                            <IonCol>
                                                <IonCardSubtitle style={{textAlign:"center"}}>Fecha del testigo: <br/>{index.fecha}</IonCardSubtitle>
                                            </IonCol>
                                            <IonCol>
                                                <IonCardSubtitle style={{textAlign:"center"}}>Número de reporte: <br/>{index.reporte}</IonCardSubtitle>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </div>
                            </div>
                        </div>
                    )
                }
                return(
                    <div className="carousel-demo">
                        <div className="card"> 
                            <Carousel value={imagMap} itemTemplate={template} responsiveOptions={responsiveOptions}/> 
                        </div> 
                    </div>
                )
            }
        }
    }
    