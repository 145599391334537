/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { IonRow, IonCol, IonGrid , IonCardContent, IonText, IonSpinner} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {BUSCAR_DETALLES, DELETE_CIRCULO, DELETE_DETAILS, REGISTRO_BITACORAS} from "./queries";
import {Dropdown} from "primereact/dropdown";
import { Redirect } from "react-router";
import { setLocalStorage } from "../../utils/Utils";
import { InputTextareaComponent } from "../../components";
import useNotification from "../../hooks/useNotification";

export default function FormPorductos(props){
	
	const pautaJSON=JSON.stringify(props);
	const estatusCampania=props.datos.estatus
	//console.log("*************",estatusCampania)
	//Para redireccionar
	const [redirect, setRedirect] = useState(false);
	//Estados para los valores del modal
	const [motivo, setMotivo] = useState();
	const [comentarios, setComentarios] = useState("");


	//Local Storage
	//setLocalStorage("pautaEdit", pautaJSON);
	const dataLocal=JSON.parse(pautaJSON)
	

    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
			pauta: dataLocal.datos.id,
			motivo: motivo,
			comentarios: comentarios,
			// tipoRetrabajo: tipoRetrabajo
		}
    });
	
	const {
		notification,
		showSuccess,
        showWarn,
		showInfo,
        showError,
	} = useNotification();

	let paut:any;
	const pauta=dataLocal.datos.id
    
    const onSubmit= data =>{
		console.log("Lo que se manda",data)
		console.log("El id de pauta", dataLocal.datos.id)
	}
	
	const botones = () =>{
		return(
			<div>
				<br></br>
				<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
					<Button 
						disabled={motivo===null || motivo===undefined || motivo==="" || dataLocal.datos.detailpautas.length===0}
						label="Retrabajo completo"
						className="p-button-raised p-button-rounded"
						type="submit"
						style={{ marginLeft: "20px"}}
						onClick={retrabajoCompleto}
					/>
				</IonCol>
				<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
					<Button
						disabled={motivo===null || motivo===undefined || motivo===""} 
						label="Retrabajo parcial"
						float-right="true"
						className="p-button-raised p-button-rounded"
						onClick={retrabajoParcial}
						style={{marginLeft:"20px"}}
					/>
				</IonCol>
			</div>
		)
	}

	const retrabajoCompleto=()=>{
		if(estatusCampania==="Perdida")
		{
			showWarn("Advertencia", "La pauta está como perdida, no puede ser modificada")
		}
		else{
			createBitacora(
				{variables: {
					input: {
						data:{
							pauta:pauta,
							Motivo:motivo,
							Comentarios:comentarios,
							TipoRetrabajo:"Retrabajo Completo"
						}
					}
				}}
			);
			//Se buscan los detalles de la pauta antes de borrar
			buscarDetalles({
				variables:{
					where:{
						pauta:pauta
					}
				}
			})
		}
	}

	const [buscarDetalles]=useLazyQuery(BUSCAR_DETALLES,{
        onCompleted(data){
            data.detailpautas.forEach(detalle => {
                if (detalle.circulospauta){
                    if (detalle.circulospauta.id){
                    	deleteCirculospauta({variables:{input:{where:{id:detalle.circulospauta.id}}}})
                    }
                }    
            });
            console.log('Búsqueda :)',data)
            //Se recorre en un arreglo los id de pauta
            data.detailpautas.forEach(detalle => {
                let idDP=detalle.id;
                console.log("Detalle ID",idDP)
                deleteDetailpauta({variables:{input:{where:{id:idDP}}}})
            });
			//Se redirecciona a Mapa
			localStorage.removeItem("pautaEdit"); //Se limpia el localStorage, si hay algo ahí
			delete dataLocal.datos.detailpautas; //Se elimina el arreglo de pautas
			 paut=JSON.stringify(dataLocal) //Recibe el objeto de props
			 setLocalStorage("pautaEdit",paut) //Setea el localstorage con los props sin detalles
			 setRedirect(true);
        	 setTimeout(() => {
            	setRedirect(false);
        	},50000);
			
        }, onError(error){
            console.log('error',error)
        }
		
    })

	const [deleteCirculospauta]=useMutation(DELETE_CIRCULO,{
        onCompleted(data){
            console.log("círculo borrado",data)
        }
    })

	const [deleteDetailpauta] =useMutation(DELETE_DETAILS,{    
		onCompleted(data){
		   console.log("Borrado exitoso", data);
		   console.log(props.datos)
	   }
   });

	const [createBitacora] = useMutation(REGISTRO_BITACORAS, {
		onCompleted:(data)=>{
			console.log("Bitácora creada",data);
		}
	});

	const retrabajoParcial=()=>{
		if(estatusCampania==="Perdida")
		{
			showWarn("Advertencia", "La pauta está como perdida, no puede ser modificada")
		}
		else{
			setLocalStorage("pautaEdit", pautaJSON);
			createBitacora(
				{variables: {
					input: {
						data:{
							pauta:pauta,
							Motivo:motivo,
							Comentarios:comentarios,
							TipoRetrabajo:"Retrabajo Parcial"
						}
					}
				}}
			)
			setRedirect(true);
			setTimeout(() => {
				setRedirect(false);
			},10000);
		}
	}

	
	const opciones=['Propuesta Inicial','El presupuesto no se ajusta al del cliente', 'Busca otra zona, busca otro NSE', 'No había disponibilidad', 'No le gustó al cliente', 'Cambios en fechas de campaña y/o número de unidades'];

	const motivoRetrabajo=()=>{
		return(
			<Dropdown
			name="Motivos" 
			tabIndex={1}
			showClear={true}
			style={{width:'300%', borderRadius: "25px"}}
			value={motivo} 
			options={opciones} 
			onChange={e=>{
				setMotivo(e.target["value"]);
			}}
			placeholder='Selecciona un motivo para editar la pauta'
			required={true}
		/>
		)
	}

	return (
		<div>
			{redirect && <Redirect to={{
		 		pathname: "/page/sitios",
		 		state: {data:paut}
		 	}}  />}
			{notification}
			<IonRow>
				<IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="4" sizeXl="4">
					{motivoRetrabajo()}		 
				</IonCol>
			</IonRow>
			<br></br>
			<IonRow>
				<IonCol>
					<IonText>Comentarios adicionales (opcional)</IonText>	
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<InputTextareaComponent
						name="comentarios"
                        value={comentarios}
						onChange={(e)=> {
							setComentarios(e)
							// console.log(comentarios)
						}}
                        rows={4}
						required={false} 
                        style={{width:"100%",borderRadius: "25px",color:"black", resize: "none"}}
						disabled={null}
						tabIndex={null}  
                    />
				</IonCol>
			</IonRow>
			<IonRow>
				<br></br>
				<br></br>
				<div>
					{botones()}
				</div>
			</IonRow>
		</div>
	);
}



// 	const retrabajoCompleto=()=>{
// 		setTipoRetrabajo("Retrabajo Completo")
// 		delete dataLocal.datos.detailpautas;
// 		setLocalStorage('pautaEdit', JSON.stringify(dataLocal))
// 		createBitacora(
// 			{variables: {
// 				input: {
// 					data:{
// 						pauta:pauta,
// 						Motivo:motivo,
// 						Comentarios:comentarios,
// 						TipoRetrabajo:"Retrabajo Completo"
// 					}
// 				}
// 			}}
// 		);
// 		//Se buscan los detalles de la pauta antes de borrar
// 		buscarDetalles({
//             variables:{
//                 where:{
//                     pauta:pauta
//                 }
//             }
//         })
		
// 	}

// 	const [buscarDetalles]=useLazyQuery(BUSCAR_DETALLES,{
//         onCompleted(data){
//             data.detailpautas.forEach(detalle => {
//                 if (detalle.circulospauta){
//                     if (detalle.circulospauta.id){
//                         deleteCirculospauta({variables:{input:{where:{id:detalle.circulospauta.id}}}})
//                     }
//                 }    
//             });
//             console.log('Búsqueda :)',data)
//             //Se recorre en un arreglo los id de pauta
//             data.detailpautas.forEach(detalle => {
//                 let idDP=detalle.id;
//                  console.log("Detalle ID",idDP)
//                  deleteDetailpauta({variables:{input:{where:{id:idDP}}}})
//             });
// 			//Se redirecciona a Mapa
// 			paut=JSON.stringify(props.datos)
// 			setRedirect(true);
//         	setTimeout(() => {
//             	setRedirect(false);
//         	},10000);
			
//         }, onError(error){
//             console.log('error',error)
//         }
		
//     })

// 	const [deleteCirculospauta]=useMutation(DELETE_CIRCULO,{
//         onCompleted(data){
//             console.log("círculo borrado",data)
//         }
//     })

// 	const [deleteDetailpauta] =useMutation(DELETE_DETAILS,{    
// 		onCompleted(data){
// 		   console.log("Borrado exitoso", data);
// 		   console.log(props.datos)
// 	   }
//    });
