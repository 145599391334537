/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-array-constructor */
/* eslint-disable new-parens */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component} from "react";
import GoogleMapReact from "google-map-react";
import { Location } from "../../store/locations/types";
import "./marker.css";
import {IonSpinner}  from "@ionic/react";
import { IMJ_API_KEY } from "../../utils/Constanst";



type Props = {
  locations: Location[];
  mapCenter: Location;
  zoom: number;
  ubicacionDireccion:string;
  onLoadImage?: Function;
  OnLoadCoordenadas?:Function;
  OnloadDirecion?:Function;
};


// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds();

  places.forEach(place => {
    bounds.extend(
      new maps.LatLng(place.geometry.location.lat, place.geometry.location.lng)
    );
  });
  return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, "idle", () => {
    maps.event.addDomListener(window, "resize", () => {
      map.fitBounds(bounds);
    });
  });
};

export default class MapaDireccion extends Component<Props>{
  mapEle: React.RefObject<HTMLDivElement>;
  map?: google.maps.Map;
  mapLibrary: any;
  geocoder :any;
  controlDiv:HTMLDivElement;
  searchInputElement :any;
  lat:any;
  lng:any;
  title:any;
  buscarEnMapa:any;
  direccion:any;
  mapimagen:any;
  aux:any;
  markers = new Array();
  bandera : true;
  direccionGoogleMaps:any;
  latlng =  new Array();
  constructor(props: Props) {
    super(props);
    this.mapEle = React.createRef();
    this.controlDiv = document.createElement('div');
    this.controlDiv.setAttribute("id", "controles");
    
  }
  
 
  apiIsLoaded = async (map, maps, places) => {
        const bounds = getMapBounds(map, maps, places);
        map.fitBounds(bounds);

        map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.controlDiv);
        map.panTo(this.props.mapCenter);
        map.setCenter(this.props.mapCenter);
        map.setZoom(this.props.zoom);
        bindResizeListener(map, maps, bounds);
        this.map = map;
        this.mapLibrary = maps;
        this.setState({
          centro: {
            lat: this.props.mapCenter.lat,
            lng: this.props.mapCenter.lng
          }
        });
        this.latlng[0] ={lat:this.props.mapCenter.lat,lng:this.props.mapCenter.lat}; 

    //}
    //this.geocodeAddress(this.props.ubicacionDireccion);

  };

  _onDragEnd = (map) => {
    
  }

  _onChange = (e) => {

    this.map.setCenter(this.latlng[0]); 
    this.map.setZoom(18);

  }


  
  /*funcion que busca la direccion enviada por la variable address*/
  geocodeAddress =(address) =>{
    for (let index = 0; index < this.markers.length; index++) {
     
      if(this.markers[index] !== undefined){
        this.markers[index].setMap(null);
        delete this.markers[index];
      }
    }
    let marker : any;
   

    let ImageMapUrl = "";
    this.geocoder = new google.maps.Geocoder();
	  this.geocoder.geocode({ 'address': address }, function handleResults(results, status) {

		if (status === google.maps.GeocoderStatus.OK) {

      this.map.setCenter(results[0].geometry.location);
      this.map.setZoom(18);
      marker = new google.maps.Marker({
        draggable: true,
        position: results[0].geometry.location
        
      });

      this.markers.push(marker);

      for (let index = 0; index < this.markers.length; index++) {
         
          if(this.markers[index] !== undefined){
            this.markers[index].setMap(this.map);
          }
        
      }

      var title_x=results[0].formatted_address;
      this.direccion=results[0].formatted_address;

      let latMarker = marker.getPosition().lat();
      let lngMarker = marker.getPosition().lng();
      this.props.OnLoadCoordenadas(latMarker,lngMarker);
      this.latlng[0] ={lat:latMarker,lng:lngMarker}; 
      this.lat=latMarker;
      this.lng=lngMarker;
      /*
      let infoWindow = new google.maps.InfoWindow({
        content: `<h5>${title_x}</h5>`
      });
      
      marker.addListener("click", () => {
        infoWindow.open(this.map, marker);
      });
      */
     
      
      google.maps.event.addListener(marker, 'dragend', function (event) {
      
        setgetLatLng(marker);
        
      });
    

       if(this.direccion !== ""){
          ImageMapUrl='https://maps.googleapis.com/maps/api/staticmap?center='+this.direccion+'&zoom=18&size=600x400&maptype=roadmap\&markers=size:mid%7Ccolor:red%7C'+this.direccion+'&key=AIzaSyBop_VhCaJf7YviDGL7uIHLjPSJft1QmfQ';
     
          const  toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.onerror = reject
              reader.readAsDataURL(blob)
          }))


          toDataURL(ImageMapUrl).then(dataUrl => {
             /*varibale que continene el resultado de lacomversion a base 64 ,
            pendiente pasara a formulario para guardar*/
              this.mapimagen=dataUrl;
              if(this.props.onLoadImage){
                this.props.onLoadImage(this.mapimagen);
              }
             
          })

      }


  
         
        const setgetLatLng=(marker)=>{

          var geocoder = new google.maps.Geocoder;
          let latdraged1 = marker.getPosition().lat();
          let lngdreged1 = marker.getPosition().lng();
          let latString = latdraged1.toString();
          let lngString = lngdreged1.toString();
         
          let  ImageMapUrlMarker = 'https://maps.googleapis.com/maps/api/staticmap?center='+latString+','+lngString+'&markers=size:mid%7Ccolor:red%7C'+latString+','+lngString+'&zoom=18&size=600x400&key=AIzaSyBop_VhCaJf7YviDGL7uIHLjPSJft1QmfQ';

          const  toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.onerror = reject
              reader.readAsDataURL(blob)
          }))
          
          this.props.OnLoadCoordenadas(latdraged1,lngdreged1);
          toDataURL(ImageMapUrlMarker).then(dataUrl => {
            /*varibale que continene el resultado de lacomversion a base 64*/
             this.mapimagen=dataUrl;
             if(this.props.onLoadImage){
               this.props.onLoadImage(dataUrl);
             }
            
          })

          geocoder.geocode({'location': marker.getPosition()}, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                getDireccion(results[1].formatted_address);
                
              }
            }
          });
          this.props.OnloadDirecion(this.direccionGoogleMaps);

          const getDireccion =(direccionGoogle)=>{
            this.props.OnloadDirecion(direccionGoogle);
          } 
          
        }
       

		  return;
		}
  
    }.bind(this));
  
  };

  
  
  buscar=() =>{
   
    var address = this.buscarEnMapa.element.value;
    this.geocodeAddress(address);
  }
   
  /*componentDidUpdate(prevProps) {
    
  }*/

  componentWillUpdate(prevProps){
    if(prevProps.ubicacionDireccion !== this.aux){
      

      this.aux=prevProps.ubicacionDireccion;
      
      setTimeout(function () {
       
      llamarFuncion();

      },2000);

      const llamarFuncion =()=>{
        this.geocodeAddress(prevProps.ubicacionDireccion)
      }
    }
  }

 

   render() {
    return (
      <div id="map_canvas" style={{ height: "40vh", width: "100%"}}>
         
          <GoogleMapReact
            key={'mapa'}
            bootstrapURLKeys={{ key: IMJ_API_KEY, libraries: ["places", "drawing"] }}
            defaultCenter={{lat:0,lng:0}}
            defaultZoom={18}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps, this.props.locations)}
            onDragEnd={this._onDragEnd}
            onZoomAnimationEnd={this._onChange}
            options={{
              mapTypeControl: false,
              streetViewControl: true,
              styles: [ { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }], }, ]

            }}
          >
          </GoogleMapReact>
          <br></br>
          <br></br>
      </div>
    );
  }
}
