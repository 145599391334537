import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import cliente from '../apollo/client';
import {isMobile} from 'react-device-detect';

/**
 * El resultado de un login válido grabarlo al session storage si es web y a localstorage si es movil
 * En la forma de login usar este hook y llamar al método login
 * En la app se debe llamar un método isAuth , este método revisa si hay un tokent en session o local storage y lo devuelve.
 * idealmente hay que validar si el token sigue activo.
 * Agregar un método para cambiar password
 * Hacer que apolo incluya el token en todas las consultas
 */

const LOGIN = gql`
  mutation login($login: UsersPermissionsLoginInput!){
    login(input:$login){
      jwt
      user{
        id
        username
        email
        role{
          name
        }
      }
    }
  }
`;

const BUSCA_PROVEEDOR = gql`
  query buscaProveedor($where: JSON){
    proveedors(where:$where){
      id
      nombre
      users{
        nombre
        id
      }
    }
  }
`;

interface loginparam {
  identifier: string,
  password: string
}

const useAuthentication = (callback?:any,onError?:any) =>{
    const storage = !isMobile?sessionStorage:localStorage;
    
    const [loginMutation] = useMutation(LOGIN,{
      onCompleted(data){
        storage.setItem('token',JSON.stringify(data.login.jwt));
        storage.setItem('u',JSON.stringify(data.login.user));
        callback(data);
      },
      onError:onError
    });
    
    const logout = () =>{
      storage.removeItem('token');
      storage.removeItem('u');
    }

    const login = (loginData:loginparam)=>{
      loginMutation({
          variables:{
            login:loginData
          }
        }
      )
    };

    const loggedUser = () =>{
      let usuario = JSON.parse(storage.getItem('u'));
      if (usuario){
        return usuario
      }else{
        return null
      }
    };

    //Falta validar si el token sigue vigente en caso de móvil o dejar computadora prendida mucho tiempo
    const isAuthenticated = () => {
      let usuario = JSON.parse(storage.getItem('token'));
      if (usuario){
        return true
      }else{
        return false
      }
    }

    const rol = () =>{
      let usuario = JSON.parse(storage.getItem('u'));
      if (usuario){
        return usuario.role.name
      }else{
        return "Public"
      }
    }

    const usuarioid=() =>{
      let usuario = JSON.parse(storage.getItem('u'));
      if (usuario){
        return parseInt(usuario.id)
      }else{
        return null
      }
    }

    const proveedor = async () =>{
      let p = await cliente.query({
        query:BUSCA_PROVEEDOR,
        variables:{where:{users:{id:parseInt(JSON.parse(storage.getItem('u')).id)}}}
      });
      if (p.data.proveedors.length>0){
        return parseInt(p.data.proveedors[0].id)
      }else{
        return -1
      }
    }

    return {login,logout,loggedUser,isAuthenticated, rol, usuarioid, proveedor}
}

export default useAuthentication;