import React, { useState, useEffect } from "react";
import { IonRow, IonCol, IonGrid, IonSpinner } from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import {InputSwitch} from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import './FormUbicaciones.css';
import { useLazyQuery, useMutation } from "@apollo/client";
import {
	UBICACIONES_QUERY,
	CREATE_UBICACION,
	UPDATE_UBICACION, 
	GET_IMAGE_UBICACION_QUERY,
} from "./queries";
import useAuthentication from "../../hooks/useAuthentication";
import { ImageUpload, InputTextComponent } from "../../components";
import { IMJ_API_KEY } from "../../utils/Constanst";

const GOOGLE_API = "https://maps.google.com/maps/api/geocode/json?key="+ IMJ_API_KEY;

const TipoExhibicion = (props) =>{
	const [exhib,setExhib] = useState(props.exhibicion);
	const opciones=[
		{label:'Medallón',value:'Medallón'},
		{label:'Medio Medallón',value:'Medio Medallón'},
		{label:'Integral',value:'Integral'},
		{label:'Semi Integral',value:'Semi Integral'},
	];

	const handleChange = (value) =>{
		if (props.onChange){
			props.onChange(value);
		}
		setExhib(value.value);
	}
	return(
		<Dropdown name='tipoexhib' value={exhib} style={{width:'100%'}}
			options={opciones} 
			onChange={handleChange} 
			placeholder="Seleccione Tipo de Exhibición"/>
	)
}

export default function FormUbicaciones(props){
	const {usuarioid} = useAuthentication();
    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
			nombre: props.datos.nombre,
			activo: props.datos.activo,
			estado: props.datos.estado,
			tipoexhib: props.datos.tipoexhib,
			lat   : props.datos.lat,
			lng   : props.datos.lng

		}
	});
	
	const [fields,setValueFields] = useState(props.datos);
    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
        delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		fieldtemp.preciourbano = parseFloat(fieldtemp.preciourbano);
		if (fieldtemp.Createdby===null){
			fieldtemp.Createdby = usuarioid();
		}else{
			var idcreatedby =fieldtemp.Createdby.id;
			fieldtemp.Createdby=parseInt(idcreatedby);
		}
		fieldtemp.costourbano = parseFloat(fieldtemp.costourbano);
		if (fieldtemp.Createdby===null){
			fieldtemp.Createdby = usuarioid();
		}else{
			idcreatedby =fieldtemp.Createdby.id;
			fieldtemp.Createdby=parseInt(idcreatedby);
		}
        

		fieldtemp.Modifiedby=usuarioid();
		fieldtemp.lat = parseFloat(fieldtemp.lat);
		fieldtemp.lng = parseFloat(fieldtemp.lng);
		return fieldtemp;
	};
	const [Checked, setChecked] = useState(fields.activo);
    const [UpdateUbicacion] = useMutation(UPDATE_UBICACION, {
		variables: {
			input: {
				where: { id: fields.id },
				data: getFieldsUpdate()
				
			}
		},
		onCompleted(data){
            props.onSuccess();
		}
	});


    const [createUbicacion] = useMutation(CREATE_UBICACION, {
		
		variables: {
			input: {
				data: getFieldsUpdate()
			}
		},
		refetchQueries: [{ query: UBICACIONES_QUERY }],
		onCompleted(data) {
			props.onSuccess();
		}
	});
    
	const onSubmit = async (data) => {
		let url = `${GOOGLE_API}&address=${encodeURIComponent(fields.nombre+','+fields.estado)}`;
		const response = await fetch(url).then(res => res.json());
		if (response.status === 'OK'){
			fields.lat = response.results[0].geometry.location.lat;
			fields.lng = response.results[0].geometry.location.lng;
		}
		if (props.operacion==='editar'){
			UpdateUbicacion();
		} else if (props.operacion === 'crear'){
            createUbicacion();
        }
    }

    useEffect(() => {
		register({ name: "nombre" });
		register({ name:'tipoexhib' });
		register({ name:'preciourbano' });
	}, [register]);

	////////// Imagenexhib //////////
	const [, setImagenForm] = useState('');

	/**
	 * LazyQuerie encargado de obtener la imagen de exhibicion
	 * de una ubicacion en especifico por su ID
	 */
	// const [getImageUbiacionById, { loading: loadingImage}] = useLazyQuery(GET_IMAGE_UBICACION_QUERY, {
	// 	onCompleted: data => {
	// 		// Se guarda la imagen en Base64
	// 		const image = data.ubicacion.imagenexhib;
	// 		if (image) {
	// 			setImagenForm(image);
	// 		} else {
	// 			// en caso de que la imagen venga en null se setea un * para validar que no hay imagen
	// 			setImagenForm('*');
	// 		}
			
	// 	}, onError: (res) => {
	// 		console.log('ERROR **********', res);
	// 	}
	// });
	const [getImageUbiacionById, {data:dataImg, loading:loadingImg, error:errorImg}]=useLazyQuery(GET_IMAGE_UBICACION_QUERY, {
		onCompleted: data=>{
			const image=data.ubicacion.imagenexhib;
			if(image){
				setImagenForm(image);
			} else {
				setImagenForm('*');
			}
		}, onError: (error)=>{
			console.log('ERROR: ', error)
		}
	})
	
	const showImage =()=> {
		if(errorImg) return <h5 style={{ color: "red" }}>Algo salió mal</h5>;
		if (loadingImg) return <IonSpinner name="dots" />;
		if(dataImg) return(
			<ImageUpload
				imagen={dataImg.ubicacion.imagenexhib}
				tipo='Vista corta'
				width={729}
				height={388}
				onSave={(data)=>{
					setValueFields({...fields,'imagenexhib':data})
					setValue('imagenexhib',data);
				}}/>
		);
	}


	/**
	 * Effect que se encarga de obtener la imagen de exhibicion en particular
	 * de la ubicacion para su visualizacion en el formulario
	 */
	useEffect(() => {
		// valida que contenga un id
		if (props.datos.id) {
			const idUbicacion = props.datos.id;
			// Ejecuta el query que obtiene la imagen
			getImageUbiacionById({
				variables: {
					id: parseInt(idUbicacion)
				}
			});
		}

	},[props, getImageUbiacionById]);

	////////// Imagenexhib //////////
    
    const handleChange = (e, name: string) =>{
		setValueFields({...fields,[name]:e})
	}

	const handleChangeWithouName = (e) =>{
		setValueFields({...fields,[e.target.name]:e.target.value})
	}
	
	const validaswitchButton = (value) =>{
		setChecked(value);
		fields.activo=value;
		
	}
	
	const botones = ()=>{
		if(props.operacion === 'editar' || props.operacion === 'crear'){
			return (
				<div >
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>
			)
		}else{
			return (<div></div>)
		}
	}
	
	
	return (
		<form  onSubmit={handleSubmit(onSubmit)} >
			<IonGrid >
				<IonRow >
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<span>
							<label htmlFor="in">Nombre</label>
							<InputTextComponent
								// tabIndex={1}
								name="nombre"
								value={(fields.nombre === null ? "" : fields.nombre)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'nombre');
									setValue("nombre", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
						</span>
					</IonCol>
					
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<span>
							<label htmlFor="in">Estado</label>
							<InputTextComponent
								// tabIndex={2}
								name="estado"
								value={(fields.estado === null ? "" : fields.estado)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'estado');
									setValue("estado", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
						</span>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<span>
							<label htmlFor="in">Tipo de Exhibición</label><br></br>
							<TipoExhibicion id='tipoexhib' exhibicion={fields.tipoexhib} 
								onChange={(value)=>{
									handleChangeWithouName(value);
									setValue('tipoexhib',value.value);
								}}/>
						</span>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<span>
							<label htmlFor="in">Precio urbanos</label>
							<InputTextComponent
								// tabIndex={1}
								name="preciourbano"
								type='number'
								value={(fields.preciourbano === null ? "" : fields.preciourbano)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'preciourbano');
									setValue("preciourbano", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
							/>
						</span>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<span>
							<label htmlFor="in">Costo Urbanos</label>
							<InputTextComponent
								name="costourbano"
								type='number'
								value={(fields.costourbano === null ? "" : fields.costourbano)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'costourbano');
									setValue("costourbano", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
							/>
						</span>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="12" sizeLg="2" sizeXl="2" style={{textAlign:'center'}}>

						<label htmlFor="in">Activo</label>
						<br></br>
						<InputSwitch 
							checked={Checked} 
							onChange={(e) => validaswitchButton(e.value)}
						/>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="12" size-xs="12" sizeMd="12" sizeLg="5" sizeXl="5" >
						<br></br>
						<span className="p-float-label ">
							<InputTextComponent
								name="lat"
								value={(fields.lat === null ? "" : fields.lat)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								type="number"
								onChange={e => {
									handleChange(e, 'lat');
									setValue("lat", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								// type={null}
								required={null}
							/>
							<label htmlFor="in">latitud</label>
						</span>		
					</IonCol>
					<IonCol size="12" size-xs="12" sizeMd="12" sizeLg="5" sizeXl="5" >
						<br></br>
						<span className="p-float-label ">
							<InputTextComponent
								// tabIndex={5}
								name="lng"
								value={(fields.lng === null ? "" : fields.lng)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								type="number"
								onChange={e => {
									handleChange(e, 'lng');
									setValue("lng", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
								// type={null}
							/>
							<label htmlFor="in">longitud</label>
						</span>		
					</IonCol>
				</IonRow>
				<br></br>
				<div className={'center'}>
					<br></br>
					<label htmlFor="in">Vista de exhibición</label>
					{showImage()}
					{/* <ImageUpload
						//imagen={dataImg}
						imagen={imagenForm !== '' ? imagenForm : null}
						tipo='Vista corta'
						width={729}
						height={388}
						//loading={loadingImage}
						onSave={(data)=>{
							setValueFields({...fields,'imagenexhib':data})
							setValue('imagenexhib',data);
					}}/> */}
				</div>
				<br/><br/>
				<div>{botones()}</div>
				<IonRow>
					
				</IonRow>

				
			</IonGrid>
		</form>
	);

}