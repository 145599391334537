/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useState } from 'react'
import { Toolbar } from '../../components'
import useAuthentication from '../../hooks/useAuthentication';
import './KpisNegociaciones.css';

export const KpisNegociaciones = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndexOp, setActiveIndexOp] = useState(0);
    const { rol } = useAuthentication()

    const cambiaTabOp = (index) =>{
        if (rol()==='Administrador'){
            if (index===3){
                window.open("https://datastudio.google.com/reporting/d662e5f1-9497-4c3d-beb7-40e69d30bd71/page/TccaC","_blank");
            }
        }else{
            if (index===0){
                window.open("https://datastudio.google.com/reporting/d662e5f1-9497-4c3d-beb7-40e69d30bd71/page/TccaC","_blank");
            }
        }
        setActiveIndex(index);
    }

    useEffect(() => {
        if (rol()==='Operaciones Fijos'){
            window.open("https://datastudio.google.com/reporting/d662e5f1-9497-4c3d-beb7-40e69d30bd71/page/TccaC","_blank");
        }
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <Toolbar
                    title={props.title}
                    setMenuEnabled={props.setMenuEnabled}
                    menuEnabled={props.menuEnabled}>
                </Toolbar>
            </IonHeader>

            <IonContent>
                {rol() === 'Administrador' && (
                    <TabView activeIndex={activeIndex} onTabChange={(e) => cambiaTabOp(e.index)} style={{ width: '100%', height: '100%' }}>
                        <TabPanel header="Negociaciones" contentStyle={{ width: '100%', height: '100%' }}>
                            <div style={{ height: '100%', width: '100%', border: 'none' }}>
                                <iframe width="600"
                                    height="100%"
                                    style={{ width: '100%', height: '100%' }}
                                    frameBorder='0'
                                    src="https://datastudio.google.com/embed/reporting/044f05be-59fd-4a62-b891-308fa493e9ea/page/MJ4XC"></iframe>
                            </div>
                        </TabPanel>
                        <TabPanel header="Negociaciones por empresa" contentStyle={{ width: '100%', height: '100%' }}>
                            <div style={{ height: '100%', width: '100%', border: 'none' }}>
                                <iframe width="600"
                                    height="1000"
                                    style={{ width: '100%', height: '100%' }}
                                    frameBorder='0'
                                    src="https://datastudio.google.com/embed/reporting/044f05be-59fd-4a62-b891-308fa493e9ea/page/p_q4bih9gjnc" ></iframe>
                            </div>
                        </TabPanel>
                        <TabPanel header="Negociaciones Activas" contentStyle={{ width: '100%', height: '100%' }}>
                            <div style={{ height: '100%', width: '100%', border: 'none' }}>
                                <iframe width="600"
                                    height="1000"
                                    style={{ width: '100%', height: '100%' }}
                                    frameBorder='0'
                                    src="https://datastudio.google.com/embed/reporting/044f05be-59fd-4a62-b891-308fa493e9ea/page/p_r7ej2jk5nc" ></iframe>
                            </div>
                        </TabPanel>
                        <TabPanel header="KPI's Sitios" contentStyle={{ width: '100%', height: '100%' }} >
                            <div style={{ height: '100%', width: '100%', border: 'none' }}>
                                <h1>El contenido de esta pestaña se abre en una nueva ventana</h1>
                                
                            </div>
                        </TabPanel>
                    </TabView>
                )}
                {rol() === "Operaciones Fijos" && (
                    <TabView activeIndex={activeIndex} onTabChange={(e) => cambiaTabOp(e.index)} style={{ width: '100%', height: '100%' }}>
                        <TabPanel header="KPI's Sitios" contentStyle={{ width: '100%', height: '100%' }}>
                            <div style={{ height: '100%', width: '100%', border: 'none' }}>
                                <h1>El contenido de esta pestaña se abre en una nueva ventana</h1>
                            </div>
                        </TabPanel>
                    </TabView>
                )}
            </IonContent>
        </IonPage>
    )
}
