/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
	IonRow,
	IonCol
} from "@ionic/react";

import { renderBoolean } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import {
	MToolBar,
	Toolbar,
	ChartHYM,
	ChartNSE,
	ChartUsers,
	ChartGrupoEdad,
	DTableV2
} from "../../components";
import useDialog from "../../hooks/useDialog";
import { useMutation, useLazyQuery } from "@apollo/client";
import {MEDIOS_QUERY, UPDATE_ESTADO, MEDIOS_QUERY_UPDATE} from './queries';
import useAuthentication from "../../hooks/useAuthentication";
import 'chartjs-plugin-colorschemes';
import FormInventario from "./FormInventario";
import NumberFormat from 'react-number-format';
import 'chartjs-plugin-datalabels';

//Esquemas de color en: https://nagix.github.io/chartjs-plugin-colorschemes/colorchart.html

const geoData  = (data) => {
	
	return  (
		<div>
			<IonRow>
				<IonCol>
					<ChartNSE data={data}/>
				</IonCol>
				<IonCol>
					<ChartGrupoEdad data={data} />
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<ChartUsers data={data}/>
				</IonCol>
				<IonCol>
					<ChartHYM data={data}/>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<div>Alcance: <b><NumberFormat value={data.reach} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div><br></br>
					<div>Alcance: <b>Porcentaje de personas alcanzadas dentro del universo total nacional</b></div><br></br>
				</IonCol>
				<IonCol>
					<div>Frecuencia: <b><NumberFormat value={data.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div><br></br>
					<br></br>
					
					<div>Frecuencia: <b>Número promedio de veces que estuvo un grupo de personas en un mismo periodo de tiempo y ubicados dentro del radio del espacio urbano</b></div>
					<br></br>
				</IonCol>
			</IonRow>
		</div>
	)
}

const InventarioProveedores = (props) => {
	const [titleHeader, settitleHeader] = useState("Titulo");
	const [refresh, setRefresh] = useState(false);
	const {usuarioid,rol, proveedor} = useAuthentication();
	const [idproveedor,setIdProveedor] = useState(0);
	const [expandedRows,setExpandedRows] = useState();
	const [showInfinia,setShowInfinia] = useState(false);
	const [filtro,setFiltro] = useState(null);

	const cambiarEstatus = (e) =>{
		if (e.target.name==='disponible'){
			cambiarDisponibleMedio(parseInt(e.target.id),e.target.value)
		}
		if (e.target.name==='activo'){
			CambiarEstatusMedios(parseInt(e.target.id),e.target.value)
		}
		if (e.target.name==='bloqueado'){
			cambiarBloqueadoMedio(parseInt(e.target.id),e.target.value)
		}
		if (e.target.name==='iluminacion'){
			cambiarIluminacionMedio(parseInt(e.target.id),e.target.value)
		}
	}

	const columnas = [
		{
			header: "Proveedor",
			field: "proveedor.nombre"
		},
		{
			header: "Clave de proveedor",
			field: "claveproveedor",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header:'Vista',
			field: 'vista.nombre',
			sortable: true,
			filter: false
		},
		{
			header: "Tipo",
			field: "tipos.nombre",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Direccion",
			field: "direccion",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Ciudad",
			field: "ubicacion.nombre",
			sortable: true,
			filter: true,
			filterMatchMode: "contains"
		},
		{
			header: "Medida",
			field: "medida",
			sortable: true
		},
		{
			header: "Fecha Creado",
			field: "created_at",
			sortable: true
		},
		
		{
			header:'Iluminación',
			field:'iluminacion',
			body: renderBoolean,
			sortable: false,
			customChange: cambiarEstatus
		},
		{
			header: "Disponible",
			field: "disponible",
			body:renderBoolean,
			sortable: true,
			customChange: cambiarEstatus
		},
		{
			header: "Activo",
			field: "activo",
			body:renderBoolean,
			sortable: true,
			customChange: cambiarEstatus
		},
		{
			header: "Bloqueado",
			field: "bloqueado",
			body:renderBoolean,
			sortable: true,
			customChange: cambiarEstatus
		}
	];
	
	
	const {
		notification,
		showSuccess,
		showInfo
	} = useNotification();
	
	const [updateMedio] = useMutation(UPDATE_ESTADO, {
		onCompleted(data){
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});
   
	const {showDialog, FormDialog} = useDialog();
	
	const [traerMedio] = useLazyQuery(MEDIOS_QUERY_UPDATE,{
		onCompleted:(data)=>{
			showDialog(data.medios[0],"detalles");
		}
	});

	const [editarMedio] = useLazyQuery(MEDIOS_QUERY_UPDATE,{
		onCompleted:(data)=>{
			
			if(data.medios[0].Createdby === undefined){
				data.medios[0].Createdby = usuarioid();
			}else{
				data.medios[0].Createdby = parseInt(data.medios[0].Createdby.id); 
			}
			
			data.medios[0].Modifiedby = usuarioid();
			showDialog(data.medios[0],"editar");
		}
	})

	useEffect(() => {
		const getIdProveedor= async ()=>{
			let idp = await proveedor();
			let filtro = {};
			setIdProveedor(idp);
			if (rol()===('Administrador') || rol()===('Operaciones Fijos') || rol()===('Operaciones Urbanos') || (rol()==='Operaciones')){
				filtro = {
					proveedor_gt:0
				};
			}else{
				filtro = {
					proveedor: idp
				}
			}
			setFiltro(filtro);
		}
		getIdProveedor();	
	},[])

	/**
	 * Se ejecuta la acción de borrado de un mutation, lo dispara el grid con el método onDelete
	 * @param data : datos del registro
	 */
	const CambiarEstatusMedios = (id,activo) => {
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  activo:activo
						}
					}
				}
			})
		}
	};

	const cambiarIluminacionMedio = (id,iluminacion) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  iluminacion:iluminacion
						}
					}
				}
			})
		}
	}

	const cambiarDisponibleMedio = (id,disponible) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  disponible:disponible
						}
					}
				}
			})
		}
	}

	const cambiarBloqueadoMedio = (id,bloqueado) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						  bloqueado:bloqueado
						}
					}
				}
			})
		}
	}
	/**
	 * Se llama la forma de captura/edición en un dialog
	 * Se pasa la operación editar al dialog para que sepa si ejecuta una mutación de crear o editar
	 * @param data Datos a editar
	 */
	const onEdit = (data) =>{
		
		
		//showDialog(data,'editar');
		//setdatos(data);

		editarMedio({
			variables:{
				where:{
					id:parseInt(data.id)
				}
			}
		})
		settitleHeader('Editar informaciòn del sitio');
	}
	
	const onView = (data) => {
		//Aquí el lazy query
		traerMedio({
			variables:{
				where:{
					id:parseInt(data.id)
				}
			}
		})
		settitleHeader('Detalles del sitio');
	}

	const onSuccess = () => {
		setRefresh(false);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada','Se abortó correctamente la operación')
	}
	
	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title + '*******'} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				<MToolBar showCart={false}/>
				<DTableV2
					rows={rol()===('Administrador') || rol()===('Operaciones Fijos') || rol()===('Operaciones Urbanos') || rol()===('Operaciones')?50:500}
					autoLayout={true}
					paginable={rol()===('Administrador') || rol()===('Operaciones Fijos') || rol()===('Operaciones Urbanos') || rol()===('Operaciones')?true:false}
					// ordenarPor='proveedor:asc,claveproveedor:asc'
					ordenarPor={'id:desc,created_at:desc'}
					query={MEDIOS_QUERY}
					style={{ margin: "15px" }}
					columnas={columnas}
					element="medios"
					canView={false}
					canDelete={false}
					canEdit={false}
					canAdd={false}
					onEdit={onEdit}
					onView={onView}
					refresh={refresh}
					filtro={filtro}
					onEndRefresh={() => setRefresh(false)}
					responsive={false}
				/>
				<FormDialog
					header="Alta/Edición de Sitios"
					onSuccess={onSuccess}
					onDiscard={onDiscard}>
					<FormInventario  />
					
				</FormDialog>
			</IonContent>
		</IonPage>
	);
}

export default InventarioProveedores;