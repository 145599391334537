/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import {IonRow, IonCol, IonGrid} from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";

import { useMutation } from "@apollo/client";
import {PROVEEDORES_QUERY,CREATE_PROVEEDOR,UPDATE_PROVEEDOR} from "./queries";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextComponent } from "../../components";

export default function FormProveedores(props){

    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
			nombre: props.datos.nombre,
			telefono: props.datos.telefono,
			rfc: props.datos.rfc,
			nombre_comercial: props.datos.nombre_comercial,
			liquidara: props.datos.liquidara,
			domicilio : props.datos.domicilio,
			colonia: props.datos.colonia,
			cp: props.datos.cp,
			email: props.datos.email,
			password: props.datos.password,
			estatus: props.datos.estatus
		}
	});
	
    const [fields,setValueFields] = useState(props.datos);
    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
        delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		return fieldtemp;
	};

    const [updateProveedor] = useMutation(UPDATE_PROVEEDOR, {
		variables: {
			input: {
				where: { id: fields.id },
				data: getFieldsUpdate()
				
			}
		},
		onCompleted(data){
            props.onSuccess();
		}
	});

    const [createProveedor] = useMutation(CREATE_PROVEEDOR, {
		variables: {
			input: {
				data: getFieldsUpdate()
			}
		},
		refetchQueries: [{ query: PROVEEDORES_QUERY }],
		onCompleted(data) {
			props.onSuccess();
		}
	});
    
	const onSubmit = data => { 
		
        if (props.operacion==='editar'){
			updateProveedor();
			
		}else if(props.operacion === 'crear'){
			createProveedor();
        }
    }

    useEffect(() => {
		register({ name: "nombre" });
		register({ name: "telefono" });
		register({ name: "rfc" });
		register({ name: "nombre_comercial" });
		register({ name: "liquidara" });
		register({ name: "domicilio" });
		register({ name: "colonia" });
		register({ name: "cp" });
		register({ name: "email" });
		register({ name: "password" });
		register({ name: "estatus" });
	}, [register]);
    
    const handleChange = (e, name = '') =>{
		// console.log('handleChange e', e);
		// console.log('handleChange {...fields,[e]:e}', {...fields,[e]:e});
		setValueFields({...fields,[name ? name : e]:e})
	}

	// const handleChange2 = (e) =>{
	// 	setValueFields({...fields,[e.target.name]:e.target.value})
	// }
	
	const botones = () => {
		if (props.operacion === 'editar' || props.operacion === 'crear') {
			return (
				<div>
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>			
			)
			
		}else{
			return(
				<div>

				</div>
			)
		}
		
	}
	
	return (
		<form onSubmit={handleSubmit(onSubmit)} >
			<IonGrid >	
				<IonRow >
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						{/* <InputTextComponent
							label={'Nombre de ejecutivo'}
							name="nombre"
							value={(fields.nombre === null || fields.nombre === undefined ? "" : fields.nombre)}
							style={{ width: "100%" ,color:'black'}}
							disabled={props.operacion === "detalles" ? true:false}
							onChange={e => {
								handleChange(e);
								setValue("nombre", e);
							}}
							accept={null}
							className={null}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={true}
							type={'text'}
						/> */}
							<span className="p-float-label ">
							<InputTextComponent
								name="nombre"
								value={(fields.nombre === null ? "" : fields.nombre)}
								style={{ width: "100%" ,color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'nombre');
									setValue("nombre", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={true}
								type={'text'}
							/>
							<label htmlFor="in">Nombre de ejecutivo</label>
						</span>
						</IonCol>
						<IonCol  size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
							<br></br>
							<InputTextComponent
								name="telefono"
								value={(fields.telefono === null ?"" :fields.telefono)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'telefono');
									setValue("telefono", e);
								}}
								accept={null}
								className={null}
								label={'Telefono'}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={true}
								type={'number'}
							/>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<InputTextComponent
							name="email"
							type={'email'}
							value={(fields.email === null ? "" : fields.email)}
							style={{ width: "100%",color:'black'}}
							disabled={props.operacion === "detalles" ? true:false}
							onChange={e => {
								handleChange(e, 'email');
								setValue("email", e);
							}}
							accept={null}
							className={null}
							label={'Email'}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={true}
						/>
					</IonCol>
					
				</IonRow>
			
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<InputTextComponent
							name="rfc"
							value={(fields.rfc === null ? "" : fields.rfc)}
							style={{ width: "100%",color:'black'}}
							disabled={props.operacion === "detalles" ? true:false}
							onChange={e => {
								handleChange(e, 'rfc');
								setValue("rfc", e);
							}}
							accept={null}
							className={null}
							label={'Rfc'}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
							type={'text'}
						/>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<InputTextComponent
							// tabIndex={5}
							name="nombre_comercial"
							value={(fields.nombre_comercial === null ? "" : fields.nombre_comercial)}
							style={{ width: "100%",color:'black'}}
							disabled={props.operacion === "detalles" ? true:false}
							onChange={e => {
								handleChange(e, 'nombre_comercial');
								setValue("nombre_comercial", e);
							}}
							accept={null}
							className={null}
							label={'Nombre comercial'}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
							type={'text'}
						/>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<InputTextComponent
							name="liquidara"
							value={(fields.liquidara === null ? "" : fields.liquidara)}
							style={{ width: "100%",color:'black'}}
							disabled={props.operacion === "detalles" ? true:false}
							onChange={e => {
								handleChange(e, 'liquidara');
								setValue("liquidara", e);
							}}
							accept={null}
							className={null}
							label={'Razón social'}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
							type={'text'}
						/>
					</IonCol>
				</IonRow>
			
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<InputTextComponent
							accept={null}
							className={null}
							label={'Domicilio'}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
							type={'text'}
							name="domicilio"
							value={(fields.domicilio === null ? "" : fields.domicilio)}
							style={{ width: "100%",color:'black'}}
							disabled={props.operacion === "detalles" ? true:false}
							onChange={e => {
								handleChange(e, 'domicilio');
								setValue("domicilio", e);
							}}
						/>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<InputTextComponent
							name="colonia"
							value={(fields.colonia === null ? "" : fields.colonia)}
							style={{ width: "100%",color:'black'}}
							disabled={props.operacion === "detalles" ? true:false}
							onChange={e => {
								handleChange(e, 'colonia');
								setValue("colonia", e);
							}}
							accept={null}
							className={null}
							label={'Colonia'}
							onBlur={null}
							onKeyPress={null}
							placeholder={null}
							required={null}
							type={'text'}
						/>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
					<br></br>
							<InputTextComponent
								name="cp"
								type={"number"}
								value={(fields.cp === null ? "" : fields.cp)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'cp');
									setValue("cp", e);
								}}
								accept={null}
								className={null}
								label={'Codigo postal'}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
							/>
					</IonCol>
				</IonRow>
			
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="3" sizeLg="3" sizeXl="3" style={{textAlign:'center'}}>
						
						<label htmlFor="in">Estatus</label>
						<br></br>
						<InputSwitch 
							checked={fields.estatus} onChange={(e) => {
								setValueFields({...fields,['estatus']:e.value})
								setValue("estatus", e.value);
							}}
						/>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="9" sizeLg="9" sizeXl="9">
						<br></br>
							<InputTextComponent
								name="password"
								type={'password'}
								value={(fields.password === null ? "" : fields.password)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								onChange={e => {
									handleChange(e, 'password');
									setValue("password", e);
								}}
								accept={null}
								className={null}
								label={'Contraseña'}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
							/>
					</IonCol>
				</IonRow>
				<br/><br/>
				<div>{botones()}</div>
				
			</IonGrid>
		</form>
	);

}