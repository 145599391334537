import React, { useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { menuController } from '@ionic/core';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Prime React */
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
/* fin primereact */

/* Theme variables */
import './theme/variables.css';

import {
	Login,
	Register, 
	Vistas,
	Abcmarcas,
	Abcmedios,
	Abcproductos,
	Clientes,
	InventarioProveedores,
	Pautas,
	Proveedores,
	Rutas,
	Sitios,
	Tipos,
	Ubicaciones,
	Usuarios
} from './pages';
import useAuthentication from './hooks/useAuthentication';
import PrivateRoute from './PrivateRoute';
import InformacionCliente from './pages/abcproductos/datosCliente';
import {Menu} from './components';
// import Sitios from './pages/sitios/sitios';
import InventarioComercial from './pages/inventarioComercial/inventarioComercial';
// import InventarioProveedores from './pages/invproveedores/inventarioProveedores';
import FichaSitiosPdf from  './pages/ficha/ficha';
import FichaSitiosMultiplesPdf from './pages/ficha/fichasMultiples';
import EnlacesRutas from './pages/EnlacesRutas/EnlacesRutas';
import EnlacesRutasPublico from './pages/EnlacesRutas/EnlacesRutasPublico';
import FormRutas from './pages/rutas/components/FormRutas';
import Permisionarios from './pages/permisionarios/permisionarios';
import UnidadesUrbanos from './pages/unidades-urbanos/unidades-urbanos';
import Calendario from './pages/calendario/calendario';
import { KpisNegociaciones } from './pages/KPIS/KpisNegociaciones';
import campaniasUrbanos from './pages/campaniasUrbanos/campaniasUrbanos';
import Testigos from './pages/testigos/Testigos'
import Abcindoor from './pages/abcindoor/abcindoor';
import Plazasindoor from './pages/plazasindoor/plazasindoor';

const App: React.FC = () => {
	const isMenuEnabled = async () => {
		return await menuController.isEnabled();
	}
	let menuHabilitado = true;
	isMenuEnabled().then((dato) => {
		menuHabilitado = dato;
	});
	
	const [menuEnabled, setMenuEnabled] = useState(menuHabilitado);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { rol, logout } = useAuthentication();
	return (
		<IonApp>
			<IonReactHashRouter>
				<IonSplitPane contentId="main">
					<Menu
						menuEnabled={menuEnabled}
					/>
					<IonRouterOutlet id="main">
						<PrivateRoute
							path="/page/sitios"
							component={Sitios}
							exact={true}
							title="Localiza tu zona ideal - Sitios y Urbanos IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/KPIS"
							component={KpisNegociaciones}
							exact={true}
							title="KPI's de Negociaciones"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/calendario"
							component={Calendario}
							exact={true}
							title="Calendario de campañas"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/vistas"
							component={Vistas}
							exact={true}
							title="Vistas IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/proveedores"
							component={Proveedores}
							exact={true}
							title="Proveedores IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/permisionarios"
							component={Permisionarios}
							exact={true}
							title="Permisionarios"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/campaniasUrbanos"
							component={campaniasUrbanos}
							exact={true}
							title="Campañas Urbanos"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/unidades-urbanos"
							component={UnidadesUrbanos}
							exact={true}
							title="Unidades Urbanos"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/clientes"
							component={Clientes}
							exact={true}
							title="Clientes IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/tipos"
							component={Tipos}
							exact={true}
							title="Tipos IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/ubicaciones"
							component={Ubicaciones}
							exact={true}
							title="Ubicaciones IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/abcmedios"
							component={Abcmedios}
							exact={true}
							title="ABC Sitios IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/abcindoor"
							component={Abcindoor}
							exact={true}
							title="ABC Sitios InDoor IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/plazasindoor"
							component={Plazasindoor}
							exact={true}
							title="Centros Comerciales / Aeropuertos IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/inventario"
							component={InventarioComercial}
							exact={true}
							title="Inventario Comercial"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/inventario-proveedores"
							component={InventarioProveedores}
							exact={true}
							title="Inventario Proveedores"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/rutas"
							component={Rutas}
							exact={true}
							title="Rutas IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/testigos"
							component={Testigos}
							exact={true}
							title={"Testigos IMJ"}
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/FormRutas"
							component={FormRutas}
							exact={true}
							title="Rutas IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/Abcmarcas"
							component={Abcmarcas}
							exact={true}
							title="Abc marcas IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/usuarios"
							component={Usuarios}
							exact={true}
							title="Usuarios IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/productos"
							component={Abcproductos}
							exact={true}
							title="Abc productos IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/InformacionCliente"
							component={InformacionCliente}
							exact={true}
							title="Información de cliente  IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute
							path="/page/Pautas"
							component={Pautas}
							exact={true}
							title="Pautas IMJ"
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled}
						/>
						<PrivateRoute path="/page/urbanos"
							component={EnlacesRutas}
							exact={true}
							title='urbanos'
							setMenuEnabled={setMenuEnabled}
							menuEnabled={menuEnabled} />
						{/*<Route path="/page/:name" render={(props) => {
                setSelectedPage(props.match.params.name);
                return <Page {...props} />;
              }} exact={true} />*/}
					  <Route path="/" 
					  	render={() =>{ 
						  if (rol()==='Proveedor') {
							  return <Redirect to="/page/inventario-proveedores" />}
						  else {
							  return <Redirect to="/page/sitios" />
						  }}} exact={true} />
						
						<Route path="/logout" 
					  		render={() =>{ 
							  logout();
							  return <Redirect to="/login" />
							}} exact={true} />

						
			  		
					</IonRouterOutlet>
				</IonSplitPane>
				<Route path="/login" component={Login} exact={true} />
				<Route path="/registro" component={Register} exact={true} />
				<Route
					path="/page/Fichas/:id"
					component={FichaSitiosPdf}
					exact={true}
				/>
				<Route
					path="/page/FichasMultple/:id/:campania"
					component={FichaSitiosMultiplesPdf}
					exact={true}
				/>
				<Route path="/urbanos"
							component={EnlacesRutasPublico}
							exact={true} />
			</IonReactHashRouter>
		</IonApp>
	);
};

export default App;
