/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
	IonRow,
	IonCol
} from "@ionic/react";
import { renderBoolean, renderBooleanText} from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import useDialog from "../../hooks/useDialog";
import {useLazyQuery} from "@apollo/client";
import {
	Toolbar,
	MToolBar,
	ChartHYM,
	ChartNSE,
	ChartGrupoEdad,
	ChartUsers,
	DTableV2,
} from "../../components";
import {MEDIOS_QUERY, LISTA_RUTAS_QUERY, MEDIOS_QUERY_UPDATE, GET_IMAGE_MEDIOS_QUERY} from './querys/queries';
 import 'chartjs-plugin-colorschemes';
import NumberFormat from 'react-number-format';
import 'chartjs-plugin-datalabels';
import { ChartHM } from "../../components/infinia/ChartHYM";
import { TabView, TabPanel } from "primereact/tabview";
import {FormDialogRutas, FormInventario} from "./components";
import {Button} from "primereact/button";
import {useJSReportPDF} from '../../hooks';
import { MESSAGE_COMPLETE_DOWNLOAD_PDF, MESSAGE_INFO_DONWLOAD_PDF } from "../../utils/Messages";

const InventarioComercial = (props) => {

const {_renderAsyncPautaSingleDirectDownload : renderAsyncPautaSingleDirectDownload} = useJSReportPDF();

const [dataToPDF, setDataToPDF] = useState({});
const [loadingFlag, setLoadingFlag] = useState(false);
/**
 * Funncion que obtiene la informacion de las imagenes en Base64
 * y manda llamar la funcion que crea el PDF para el usuario.
 * @param props Data que se enviara para el PDF
 */
const fichasPDF = (props) => {
	// console.log('Props del botón ', props)
	showInfo(MESSAGE_INFO_DONWLOAD_PDF);
	setDataToPDF(props);
	setLoadingFlag( true);
		return new Promise(() => {
			const id = props.id;
			getImageMedioById({
				variables: {
					id: parseInt(id)
				}
			});
		});
}

/** 
 * Componente JSX que renderiza el boton para imprimri el pdf de una pauta
 * 
 */
const BotonFichas = (data, field) => {
	return (
		<Button
			type="button"
			icon="pi pi-file-pdf"
			className="p-button-danger p-button-raised p-button-rounded"
			onClick={() => { fichasPDF(data) }}></Button>
	)
}

/**
 * Funcion que se encarga de mandar un request al servidor jsReport de IMJMedia
 * el cual creara el PDF para el usuario y descargara de forma autoamtica.
 * @param dataImage {JSON} objeto que contiene las imagenes vista e imagenUbicacion en base64 
 */
const jsreportDownload = async (dataImage) => {
	let dataJSON: any = dataToPDF;
	Object.assign(dataJSON, {Vista_media: dataImage.medio.Vista_media})
	Object.assign(dataJSON, {imagenmapaubicacion: dataImage.medio.imagenmapaubicacion})
	const clave = dataJSON.clave;
	// let fileName = 'Pautas-' + clave + '.pdf';
	let fileName = 'Pautas-' + clave;

	const  responsePDF = await renderAsyncPautaSingleDirectDownload(dataJSON, fileName, showError);
	if (responsePDF) {
		showSuccess(MESSAGE_COMPLETE_DOWNLOAD_PDF);
	}
	setLoadingFlag(false);
};

const columnasRutas = [
	{
		header: "Id",
		field: "id",
		sortable: true
    },
	{
		header: "Clave",
		field: "clave",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
    },
	{
		header: "Nombre",
		field: "nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
    },
    {
		header: "Ubicacion",
		field: "ubicacion.nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Incio",
		field: "inicio",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
    },
	{
		header: "Fecha Creacion",
		field: "created_at",
		sortable: true,
    },
    {
		header: "Destino",
		field: "fin",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Estatus",
		field: "activo",
		body: renderBoolean,
		sortable: true
	}
	
];

const columnas = [
	{
		header: "Clave",
		field: "clave",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Tipo",
		field: "tipos.nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
    {
		header: "Direccion",
		field: "direccion",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Ubicación",
		field: "ubicacion.nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Medida",
		field: "medida",
		sortable: true
	},
	{
		header: "Vista",
		field: "vista.nombre",
		sortable: true,
		filter: true,
		filterMatchMode: "contains"
	},
	{
		header: "Fecha Creacion",
		field: "created_at",
		sortable: true,
    },
    {
		header: "Disponible",
        field: "disponible",
		body:renderBooleanText,
		sortable: true
    },
	{
		header: "Activo",
        field: "activo",
		body:renderBooleanText,
		sortable: true
    },
	{
		header: 'Implementaciones Especiales',
		field: "especiales",
		body:renderBooleanText
	},
    {
		header: "Comentarios",
		field: "comentarios"
	},
	{
		header: "Alcance",
		body: ChartHM,
		exportable: false,
		style: {width:'180px'}
	},
	{
		header: 'Ficha',
		body: BotonFichas
	}	
];

const geoData = (data) => {
	return (
		<div>
			<IonRow>
				<IonCol size='4'>
					<ChartNSE data={data} showData={true} fontSize={10} />
				</IonCol>
				<IonCol size='4'>
					<ChartGrupoEdad data={data} showData={true} fontSize={10} />
				</IonCol>
				<IonCol size='4'>
					<ChartUsers data={data} showData={true} fontSize={10} />
				</IonCol>
				<IonCol size='4'>
					<ChartHYM data={data} showData={true} fontSize={10} />
				</IonCol>
				<IonCol size='4'>
					<div style={{ fontSize: '10px' }}>Alcance: <b><NumberFormat value={data.reach} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div><br></br>
					<div style={{ fontSize: '10px' }}>Alcance: <b>Porcentaje de personas alcanzadas dentro del universo de <NumberFormat value={data.universo} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div><br></br>
					<div style={{ fontSize: '10px' }}>Frecuencia: <b><NumberFormat value={data.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div><br></br>
					<br></br>

					<div style={{ fontSize: '10px' }}>Frecuencia: <b>Número promedio de veces que estuvo un grupo de personas en un mismo periodo de tiempo y ubicados dentro del radio del espacio urbano</b></div>
					<br></br>
				</IonCol>
			</IonRow>
		</div>
	)
}

	const [titleHeader, settitleHeader] = useState("Titulo");
	const [refresh, setRefresh] = useState(false);
	// const {usuarioid,rol} = useAuthentication();
	const [expandedRows,setExpandedRows] = useState();
	const [activeIndex, setActiveIndex] = useState(0);
	
	
	const {
		notification,
		showSuccess,
		showInfo,
		showError,
	} = useNotification();
	
	const {showDialog, FormDialog} = useDialog();
	const {showDialog:showDialogRutas, FormDialog:DialogRutas} = useDialog();
	
	const [traerMedio] = useLazyQuery(MEDIOS_QUERY_UPDATE,{
		onCompleted:(data)=>{
			showDialog(data.medios[0],"detalles");
		}
	});

	/** LazyQuery que obtiene las imagenes en Base64 de una pauta en especifico y ejecuta una funcion cuando termina*/
	const [getImageMedioById] = useLazyQuery(GET_IMAGE_MEDIOS_QUERY, {
		// onCompleted: (data) => jsreportPDF(data)
		onCompleted: (data) => jsreportDownload(data)
	});

	const onView = (data) => {
		//Aquí el lazy query
		traerMedio({
			variables:{
				id:parseInt(data.id)
			}
		})
		settitleHeader('Detalles del sitio');
	}

	const onViewRutas = (data) => {
		showDialogRutas(data,"detalles");
	}

	return (
		<IonPage>
			<IonHeader>
				<Toolbar 
					title={props.title} 
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>

			<IonContent>
				{notification}
				<div style={{width: '500px', height: '500px', display: 'none'}} id='placeholder'></div>
				<MToolBar showCart={true}/>
				<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
					<TabPanel header="Sitios OOH">
						<DTableV2
							autoLayout={true}
							paginable={true}
							// ordenarPor='clave:asc'
							ordenarPor={'id:desc,created_at:desc'}
							query={MEDIOS_QUERY}
							loadingFlag={loadingFlag}
							style={{ margin: "15px" }}
							columnas={columnas}
							element="medios"
							canView={true}
							canDelete={false}
							canEdit={false}
							canAdd={true}
							onView={onView}
							refresh={refresh}
							onEndRefresh={() => setRefresh(false)}
							rowExpansionTemplate={geoData}
							expandedRows={expandedRows} 
							onRowToggle={(e) => setExpandedRows(e.data)}
							responsive={false}
						/>
						<FormDialog
							header="Detalle del Sitio"
							>
							<FormInventario  />
							
						</FormDialog>
					</TabPanel>
					<TabPanel header="Rutas">
						<DTableV2
							autoLayout={true}
							paginable={false}
							// ordenarPor='clave:asc'
							ordenarPor={'id:desc,created_at:desc'}
							query={LISTA_RUTAS_QUERY}
							style={{ margin: "15px" }}
							columnas={columnasRutas}
							element="rutas"
							canAdd={true}
							canView={true}
							onView={onViewRutas}
							refresh={refresh}
							onEndRefresh={() => setRefresh(false)}
						/>
						<DialogRutas
							header="Detalle de la Ruta"
							>
							<FormDialogRutas  />
							
						</DialogRutas>
					</TabPanel>
				</TabView>
			</IonContent>
		</IonPage>
	);
}

export default InventarioComercial;
