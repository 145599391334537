/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-mixed-operators */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { IonPopover, IonItem, IonText, IonRow, IonCol, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonGrid } from '@ionic/react';
import './InfoWindow.css';
import { ShoppingCartContext } from '../../context/ShoppingCartContext';
import useNotification from "../../hooks/useNotification";
import NumberFormat from 'react-number-format';
import QRCode from 'qrcode.react';
import ChartNSE from '../infinia/ChartNSE';
import { gql, useQuery } from "@apollo/client";

// InfoWindow component
/*
id: "2462"
clave: "IMJ-GDL-1448"
ubicacion: {nombre: "Guadalajara", __typename: "Ubicacion"}
vista: {nombre: "Natural", __typename: "Vistas"}
tipos: {nombre: "Muro", __typename: "Tipos"}
proveedor: {nombre: "DIVOT Medios", __typename: "Proveedor"}
direccion: "Av. Juárez #21"
medida: "A:10.82 x 6.36m; B:18.40 x 5.40m"
claveproveedor: "DME-010 Divot"
latitud: 20.67530429776
longitud: -103.342235684395
disponible: true
activo: true
vialidad: "Av. Juárez"
colonia: "Mexicaltzingo"
niveldeimpacto: "AAA"
iluminacion: false
nicho: "B, C+, C"
referencias: "Farmacias ABC, mercado San Juan de Dios, 7-Eleven, Oxxo, Parroquia de San Juan de Dios, Banorte."
comentarios: "Vista general libre"
costo: 130000
precioventa: 299900*/
const VISTAS = gql`
    query fotos($id: ID!){
        medio(id:$id){
            Vista_media
        }
    }
`

const InfoWindowSitios = (props) => {
    const {data, loading}=useQuery(VISTAS, {
        variables:{
            id: props.sitio? props.sitio.id: 0 
        }
    })
    const { ButtonAddSitios } = useContext(ShoppingCartContext);

    const place = props.sitio || {};
    const urlStreetView = "http://www.google.com/maps?layer=c&cbll=" + place.latitud + "," + place.longitud;

    const infoWindowStyle = {
        position: 'relative' as 'relative',
        bottom: 150,
        left: '-45px',
        width: 220,
        backgroundColor: 'white',
        boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
        padding: 10,
        fontSize: 14,
        zIndex: 100,
    };

    const cierraPopover = () => {
        props.onClose(place.id)
    }

    const {
        notification,
        showSuccess,
        showInfo,
        showWarn
    } = useNotification();

    const [claveAux, setClaveAux] = useState("");

    const onSuccess = () => {
        showSuccess("Operación exitosa", "Se agrego el producto con exito");
    }

    const OnWarning = () => {
        showWarn("advertencia", " El producto ya fue agregado previamente");
    }

    const getSticker = (tipo) =>{
        switch (tipo) {
            case 'Pantalla Digital':
                return '/assets/stickers/digital.png';
            case 'Espectaculares':
                return '/assets/stickers/espectacular.png';
            case 'Puente Peatonal':
                return '/assets/stickers/puente.png';
            case 'Puente':
                return '/assets/stickers/puente.png';
            case 'Valla':
                return '/assets/stickers/vallafija.png';
            case 'Valla Móvil':
                return '/assets/stickers/vallamovil.png';
            case 'Muro':
                return '/assets/stickers/muro.png';
            case 'Bajo Puente':
                return '/assets/stickers/bajo_puentes.png';
            case 'Cabecera':
                return '/assets/stickers/cabecera.png';
            case 'Arte en Muro':
                return '/assets/stickers/arte_en_muro.png';    
            case 'Mupi':
                return '/assets/stickers/mupi.png';
            case 'Pendones':
                return '/assets/stickers/pendones.png';
            case 'Puente digital':
                return '/assets/stickers/puente_digital.png';
            case 'Totem':
                return '/assets/stickers/totem.png';
            default:
                return '/assets/stickers/sitio.png';
        }
    }

    const sumaIngresos = place.income_a_users + place.income_b_users + place.income_c_users + place.income_d_users + place.income_e_users;
    const porcentajeIngreso = (ingreso) =>{
        return (ingreso*100)/sumaIngresos;
    }

    const sumaEdades=place.a13_17_users + place.a18_25_users + place.a26_40_users + place.a41_55_users + place.a55_users;
    const porcentajeEdades=(edades) =>{
        return (edades*100)/sumaEdades;
    }

    
    
    return (
        <IonPopover isOpen={props.visible} onDidDismiss={cierraPopover} animated={true} cssClass='infowindow'>
            {notification}
            <IonGrid>
                <IonRow>
                    <IonCol size='5' style={{ marginTop: '13px' }}>
                        {data && data.medio && data.medio.Vista_media && <img src={data.medio.Vista_media?data.medio.Vista_media:'/assets/IMJ.png'} alt='Imagen del Sitio' />}
                        {loading && <img src='/assets/IMJ.png' alt='Imagen del Sitio' />}
                    </IonCol>
                    <IonCol size='7'>
                        <IonCard color="resaltar">
                            <IonCardHeader>
                                <IonCardSubtitle>{place && place.tipos && place.tipos.nombre || ''}</IonCardSubtitle>
                                <IonGrid>
                                    <IonRow>
                                        <IonCol>
                                            <IonCardTitle>{place && place.clave || ''} <a href={urlStreetView} target="_blank"><img src="assets/street_view2.png" style={{ height: '30px' }} /></a></IonCardTitle>    
                                        </IonCol>
                                        <IonCol style={{textAlign:'end'}}>
                                            {place && place.tipos && <img src={getSticker(place.tipos.nombre)} height={100} alt='Sticker de sitio'/>}
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonCardHeader>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol className="ion-float-left" size='10'>
                                        <IonText color="secondary">
                                            <h1>{place && place.direccion || ''}-{place && place.ubicacion && place.ubicacion.nombre || ''}</h1>
                                        </IonText>
                                    </IonCol>
                                    <IonCol className="ion-float-left" size='10'>
                                        <IonText color="secondary">
                                            <h3>Latitud: {place.latitud} , Longitud: {place.longitud}</h3>
                                        </IonText>
                                    </IonCol>
                                    
                                    <IonCol size='2'>
                                        <ButtonAddSitios
                                            className="ion-float-right p-button-raised p-button-rounded"
                                            tooltip="Agregar sitio a Pauta"
                                            sitio={place}
                                            onSuccess={onSuccess}
                                            onWarning={OnWarning}
                                        />
                                    </IonCol>
                                </IonRow>

                                <IonRow className="ion-align-items-center height40">
                                    <IonCol >
                                        <IonText>
                                            <h3>Iluminación</h3>
                                        </IonText>
                                    </IonCol>
                                    <IonCol>
                                        {place && place.iluminacion && <b><p>Si</p></b>}
                                        {place && !place.iluminacion && <b><p>No</p></b>}
                                        {place && place.iluminacion===null && <b><p>No Disponible</p></b>}
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center height40">
                                    <IonCol >
                                        <IonText>
                                            <h3>Nivel:</h3>
                                        </IonText>
                                    </IonCol>
                                    <IonCol>
                                        <IonText>
                                            <b><p>{place && place.niveldeimpacto || ''}</p></b>
                                        </IonText>
                                    </IonCol>
                                </IonRow>
                                <IonRow className="ion-align-items-center height40">
                                    <IonCol>
                                        <IonText>
                                            <b><p>Disponible: </p></b>
                                        </IonText>
                                    </IonCol> 
                                    <IonCol>
                                        {place && place.disponible && <b><p>Sí</p></b>}
                                        {place && !place.disponible && <b><p>No</p></b>}
                                    </IonCol>  
                                </IonRow> 
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>

            <IonGrid>
                <IonRow>
                    <IonCol size='10' style={{ marginTop: '2px' }}>
                        <IonItem color="resaltar">
                            <IonCol>Big Data : </IonCol>
                            <IonCol><div style={{textAlign:"center"}}><img src='/assets/infinia/man.png' alt="hombres" height={50} /><br/><b>&nbsp;&nbsp;<NumberFormat value={place.male_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div></IonCol>
                            <IonCol><div style={{textAlign:"center"}}><img src='/assets/infinia/woman.png' alt="mujeres" height={50} /><br/><b>&nbsp;&nbsp;<NumberFormat value={place.female_users} displayType={'text'} thousandSeparator={true} decimalScale={2}/></b></div></IonCol>
                            <IonCol><div style={{textAlign:"center"}}>Alcance: <br/><b><NumberFormat value={place.reach * 100} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div></IonCol>
                            <IonCol><div style={{textAlign:"center"}}>Frecuencia: <br/><b><NumberFormat value={place.frequency} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div></IonCol>
                            <IonCol><div style={{textAlign:"center"}}>Impactos: <br/><b><NumberFormat value={place.total_hits} displayType={'text'} thousandSeparator={true} decimalScale={2} /></b></div></IonCol>
                        </IonItem>
                        <IonItem color="resaltar">
                            <IonCol>Nivel Socioeconómico : </IonCol>
                            <IonCol><br/><div style={{textAlign:"center"}}> A : <b><br/><NumberFormat value={porcentajeIngreso(place.income_a_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><br/><div style={{textAlign:"center"}}> B : <b><br/><NumberFormat value={porcentajeIngreso(place.income_b_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><br/><div style={{textAlign:"center"}}> C : <b><br/><NumberFormat value={porcentajeIngreso(place.income_c_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><br/><div style={{textAlign:"center"}}> D : <b><br/><NumberFormat value={porcentajeIngreso(place.income_d_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><br/><div style={{textAlign:"center"}}> E : <b><br/><NumberFormat value={porcentajeIngreso(place.income_e_users)} displayType={'text'} thousandSeparator={true} decimalScale={2} />%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                        </IonItem>
                        <IonItem color="resaltar">
                            <IonCol>Rango de edades: </IonCol>
                            <IonCol><div style={{textAlign:"center"}}><br/>13 a 17: <br/><b><NumberFormat value={porcentajeEdades(place.a13_17_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><div style={{textAlign:"center"}}><br/>18 a 25: <br/><b><NumberFormat value={porcentajeEdades(place.a18_25_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><div style={{textAlign:"center"}}><br/>26 a 40: <br/><b><NumberFormat value={porcentajeEdades(place.a26_40_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><div style={{textAlign:"center"}}><br/>41 a 55: <br/><b><NumberFormat value={porcentajeEdades(place.a41_55_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                            <IonCol><div style={{textAlign:"center"}}><br/>55 y más: <br/><b><NumberFormat value={porcentajeEdades(place.a55_users)} displayType={'text'} thousandSeparator={true} decimalScale={2}/>%</b></div>&nbsp;&nbsp;&nbsp;&nbsp;</IonCol>
                        </IonItem>
                        <IonItem color="resaltar">
                            <IonText style={{textAlign:"justify"}}>
                                <p><b>Referencias:</b> {place && place.referencias || ''}</p>
                            </IonText>
                        </IonItem>
                    </IonCol>
                    <IonCol size='2'>
                        <br/>
                        <br />
                        <br/>
                        <br/>
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<QRCode value={urlStreetView}/>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonPopover>
    );
    
};

/*
income_a_users: 81362
income_b_users: 53800
income_c_users: 40017
income_d_users: 27107
income_e_users: 55055
*/

export default InfoWindowSitios;