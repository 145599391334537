import {gql} from '@apollo/client';

export const  MEDIOS_QUERY = gql`
    query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            tipos{
                id
                nombre
                tipomedio{
                    nombre
                }
            }
            proveedor{
                id
                nombre_comercial
            }
            claveproveedor
            latitud
            longitud
            direccion
            ubicacion{
                id
                nombre
            }
            referencias
            activo
            vialidad
            colonia
            comentarios
            created_at
            updated_at
            indoors{
                clave
                descripcion
                colindancia
                base
                altura
                nivel
                precioventa
                costo
                proveedor{
                    nombre_comercial
                }
                activo
                created_at
            }
        }
    } 
`;

export const CREATE_MEDIO = gql`
    mutation CrearMedio($input:createMedioInput){
        createMedio(input:$input){
            medio{
                id
                clave
                tipos{
                    id
                    nombre
                }
                proveedor{
                    id
                    nombre_comercial
                }
                claveproveedor
                latitud
                longitud
                direccion
                ubicacion{
                    id
                    nombre
                }
                referencias
                medida
                ancho
                largo
                disponible
                activo
                bloqueado
                Vista_larga
                Vista_media
                Vista_corta
                comentarios
                costo
                precioventa
                created_at
                updated_at
                especiales
            }
        }
    }
`;

export const UPDATE_MEDIO = gql`
    mutation UpdateMedio($input:updateMedioInput){
        updateMedio(input:$input){
            medio{
                id
                clave
                tipos{
                    id
                    nombre
                }
                proveedor{
                    id
                    nombre_comercial
                }
                claveproveedor
                latitud
                longitud
                direccion
                ubicacion{
                    id
                    nombre
                }
                referencias
                medida
                ancho
                largo
                disponible
                activo
                bloqueado
                Vista_larga
                Vista_media
                Vista_corta
                comentarios
                costo
                precioventa
                created_at
                updated_at
                especiales
            }
        }
    }
`;

export const UPDATE_ESTADO = gql`
    mutation Cambiar_estaus($input:updateMedioInput){
        updateMedio(input:$input){
            medio{
                id
                activo
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                
            }
        }
    }
`;

export const DELETE_MEDIO = gql`
    mutation DeleteMedio($input: deleteMedioInput){
        deleteMedio(input:$input){
            medio{
                id
                clave
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                
            }
        }
    }
`;


export const listaUbicaciones_Query = gql`
    query listaUbicaciones{
        ubicacions{
            id
            nombre
        }
    }
`;


export const listaVistas_Query = gql`
    query listaVistas{
        vistas{
            id
            nombre
        }
    }
`;

export const LISTA_TIPO=gql `
    query listaTipos ($sort: String, $limit: Int, $start: Int, $where: JSON){
        tipos(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            nombre
            tipomedio{
                nombre
            }
        }
    }
`;

export const listaProveedores_Query = gql`
    query listaProveedor{
        proveedors{
            id
            nombre
            nombre_comercial
        }
    }
`;

export const BUSCAR_UBICACION_BY_ID_QUERY = gql`
    query BuscarUbicacionPorId($id:ID!){
        ubicacion(id:$id){
                nombre    
        }
    }
`;

export const BUSCAR_TIPO_BY_ID_QUERY = gql`
    query BuscarTipoPorId($id:ID!){
        tipo(id:$id){
            nombre
        }
    }
`;

export const BUSCAR_VISTA_BY_ID_QUERY = gql`
    query buscarVistaPorId($id:ID!){
        vista(id:$id){
            nombre
        }
    }
`;

export const BUSCAR_POR_ID_MEDIO = gql`
    query BuscarMedioPorId ($id:ID!){
        medio(id:$id){
            Vista_corta
            Vista_media
            Vista_larga
            imagenmapaubicacion
        }
    }
`;

export const  GET_IMAGE_MEDIOS_QUERY = gql`
    query getImageMedioById ($id:ID!){
        medio(id:$id){
            Vista_media
            imagenmapaubicacion
        }
    } 
`;

export const  MEDIOS_QUERY_UPDATE = gql`
  query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            vista{
                id
                nombre
            }
            direccion
            medida
            largo
            ancho
            claveproveedor
            latitud
            longitud
            disponible
            activo
            bloqueado
            vialidad
            colonia
            niveldeimpacto
            iluminacion
            referencias
            comentarios
            costo
            precioventa
            Vista_corta
            Vista_media
            Vista_larga
            imagenmapaubicacion
            Createdby{
                id
                email
            }
            Modifiedby{
                id
                email
            }
            imagenmapaubicacion
            material
            tipoacabado
            male_users
            female_users
            a13_17_users
            a18_25_users
            a26_40_users
            a41_55_users
            a55_users
            income_a_users
            income_b_users
            income_c_users
            income_d_users
            income_e_users
            total_hits
            total_users
            reach
            frequency
            universo
            created_at
            updated_at
            especiales
            tipos{
                id
                nombre
            }
            proveedor{
                id
                nombre
            }	
            ubicacion{
                id
                nombre
            }
        }
    } 
`;

export const BUSCAR_SITIO_POR_ID = gql `
    query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
        }
    }
`;