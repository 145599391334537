import React, { useState } from "react";
import { IonGrid, IonRow, IonCol, IonSpinner } from "@ionic/react";
import { useNotification } from "../../hooks";
import { InputTextareaComponent, InputTextComponent } from "../../components";
import {useForm} from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {LISTA_TIPO, LISTA_MEDIOS, BUSCAR_INDOOR_POR_ID, CREATE_INDOOR, UPDATE_INDOOR} from './queries'
import { uploadPlugin } from "../../utils/Utils";
import { ProveedoresLkUp } from "../../pages/abcmedios/components/LookUpsABC";
import { InputSwitch } from "primereact/inputswitch";


export default function FormABCIndoor(props){
    const {notification: notificationParam, showWarn, showSuccess, showError} = useNotification();
    
    const handleChange = (e, name = '') => {
		setValueFields({ ...fields, [name ? name : '']: e })
	}

    const [fichaTecnica, setFichaTecnica]=useState(null)
    const [imagen1, setImagen1]=useState(null)
    const [imagen2, setImagen2]=useState(null)
    const [fields, setValueFields] = useState(props.datos);

    const [selectTipos, setSelectTipos] = useState<string>(fields.tipo === null ? "" : fields.tipo);
    const [idTipos, setidTipos] = useState(props.datos.tipo === null || props.datos.tipo === undefined ? 34 : parseInt(props.datos.tipo.id));

    const [selectMedios, setSelectMedios]=useState<string>(fields.medio === null ? "" : fields.medio);
    const [idMedios, setIdMedios]=useState(props.datos.medio===null || props.datos.medio===undefined ? 11696 : parseInt(props.datos.id));

    const [selectProveedor, setSelectProveedor] = useState<string>(fields.proveedor === null ? "" : fields.proveedor);
    const [idProveedor, setidProveedor] = useState(props.datos.proveedor === null || props.datos.proveedor === undefined ? 2 : parseInt(props.datos.proveedor.id));

    const [piso, setPiso] = useState(fields.piso)
    const [activo, setActivo]=useState(fields.activo)
    const [comentarios, setComentarios]=useState(fields.comentarios)

    const {handleSubmit, setValue}=useForm({
        defaultValues: {
            clave: props.datos.clave,
            descripcion:props.datos.descripcion,
            claveproveedor:props.datos.claveproveedor,
            base: parseFloat(props.datos.base),
            altura: parseFloat(props.datos.altura),
            nivel:props.datos.nivel,
            tipo: parseInt(props.datos.id),
            medio: parseInt(props.datos.id),
            comentarios: props.datos.comentarios,
            costo: parseFloat(props.datos.costo),
            precioventa: parseFloat(props.datos.precioventa),
            proveedor: props.datos.id,
            piso: props.datos.piso,
            activo:props.datos.activo
        }
    });
    const getFieldsUpdate=()=>{
        let fieldtemp={...fields};
        delete fieldtemp.Createdby;
        delete fieldtemp.Modifiedby;
        delete fieldtemp.created_by;
        delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
        delete fieldtemp.id;
		delete fieldtemp.__typename;
        fieldtemp.tipo= idTipos;
        fieldtemp.base=parseFloat(fields.base)
        fieldtemp.altura=parseFloat(fields.altura)
        fieldtemp.claveproveedor=fields.claveproveedor
        fieldtemp.medio= idMedios
        fieldtemp.proveedor = (isNaN(idProveedor) ? 2 : idProveedor);
        fieldtemp.piso=(piso === undefined ? false : piso);
        fieldtemp.activo = (activo === undefined ? false : activo);
        fieldtemp.costo=parseFloat(fieldtemp.costo);
        fieldtemp.precioventa = parseFloat(fieldtemp.precioventa);

        return fieldtemp
    }
    console.log("PROPS",props)
    //////////////////////////////////////////////tipos
    const {loading: loadingTipos, data: dataTipos, error:ErrorTipos}=useQuery(LISTA_TIPO,
        {
            variables:{
                where:{
                    "tipomedio":{
                        "nombre":"InDoor"
                    }
                }
            }, onCompleted(dataTipos){
            }
    });
    //////////////////////////////////////////Medios
    const {loading: loadingMedios, data: dataMedios, error:errorMedios}=useQuery(LISTA_MEDIOS,
        {
            variables:{
                where:{
                    _or:[
                        {"tipos.nombre":"Centro Comercial"},
                        {"tipos.nombre":"Aeropuertos"},
                        {"tipos.nombre":"Trenes Suburbanos"}
                    ]
                }
            }, onCompleted(dataMedios){
                console.log(dataMedios.medios)
            }
        });

    const onSelectTipos = (tipoSelected) => {
		if (tipoSelected.value) {
			setidTipos(parseInt(tipoSelected.value.id));
			setSelectTipos(tipoSelected.value);
            console.log(tipoSelected.value)
		} else {
			setidTipos(null);
            setSelectTipos(null)
			//selectTipos(tipoSelected);
		}
	}

    const onSelectMedios=(sel)=>{
        if(sel.value){
            setIdMedios(parseInt(sel.value.id));
            setSelectMedios(sel.value);
        }
        else{
            setIdMedios(null);
            setSelectMedios(null);
        }
    }
        
        const listaTipos =()=>{
            if (loadingTipos) return <IonSpinner name="dots"></IonSpinner>
            if(ErrorTipos) return <h5 color="red">Algo salió mal</h5>
            if(dataTipos)
            {
                return (
                <>
                    <span>
                        <label htmlFor='in'>Seleccionar Tipo</label>
                        <br/>
                        <Dropdown
                            name="tipos"
                            tabIndex={11}
                            showClear={true}
                            style={{borderRadius:'25px', width: '100%'}}
                            placeholder='Selecciona el tipo indoor'
                            options={dataTipos.tipos}
                            dataKey='id'
                            optionLabel="nombre"
                            filter
                            filterBy="nombre"
                            required={true}
                            value={selectTipos}
                            onChange={onSelectTipos}
                        />
                    </span>
                </>
                )
            }
        }
    /////////////////////////////////MEDIO

    const listaMedios=()=>{
        if(loadingMedios) return <IonSpinner name="dots"></IonSpinner> 
        if(errorMedios) return <h5 color="red">Algo salió mal</h5>
        if(dataMedios)
        return(
        <span>
            <label htmlFor='in'>Seleccionar Medio</label>
                <Dropdown
                    style={{width:'100%', borderRadius:'25px'}}
                    placeholder="Selecciona Medio"
                    dataKey="id"
                    filter
                    required={true}
                    options={dataMedios.medios}
                    showClear={true}
                    optionLabel="clave"
                    filterBy="nombre"
                    value={selectMedios}
                    onChange={onSelectMedios}
                />
        </span>
        )
    }

    const validarActivo = (value) => {
		fields.activo = value;
		setActivo(value);
	}

    const validarPisoFijo = (value) => {
		fields.activo = value;
		setPiso(value);
	}

    const onSubmit=data=>{
        if(props.operacion==='editar'){
            UpdateIndoor()
        }
        if(props.operacion==='crear'){
            buscaCreada({
                variables:{
                    where:{
                        clave:fields.clave
                    }
                }
            })
        }
    }


    const [buscaCreada]=useLazyQuery(BUSCAR_INDOOR_POR_ID, {
        onCompleted(data){
            console.log(data)
            if(data.indoors.length!==0){
                showWarn("Advertencia", "Esta clave ya ha sido asignada")
            }
            else{
                console.log(getFieldsUpdate())
                createIndoor({
                    variables:{
                        input:{
                            data:
                                getFieldsUpdate()
                            
                        }
                    }
                })
            }
        }, onError(error){
            console.log("ERROR: ", error)
            showError("Error", "Notificar a TI el siguiente código: "+error)
        }
    });

    const [createIndoor]=useMutation(CREATE_INDOOR, {
        onCompleted(data){
            console.log(data)
            //props.onSuccess();
                let promises=[]
                if(fichaTecnica!==null && fichaTecnica!==undefined){promises.push(uploadPlugin(data.createIndoor.indoor.id, fichaTecnica, 'indoor', 'ficha_tecnica'))}
                if(imagen1!==null && imagen1!==undefined){promises.push(uploadPlugin(data.createIndoor.indoor.id, imagen1, 'indoor', 'imagen1'))}
                if(imagen1!==null && imagen1!==undefined){promises.push(uploadPlugin(data.createIndoor.indoor.id, imagen2, 'indoor', 'imagen2'))}
                if(promises.length!==0){
                    Promise.all(promises).then(function(){props.onSuccess();})
                }
                else{
                    props.onSuccess();
                }
            
        }, onError(error){
            console.log("ERROR", error)
        }
    })

    const [UpdateIndoor]=useMutation(UPDATE_INDOOR,{
        variables: {
			input: {
				where: { id: parseInt(fields.id) },
				data: getFieldsUpdate()
			}
		},
		onCompleted(data){
			console.log(data)
			props.onSuccess()
		}
    })

    const onselectProveedor = (selectid) => {
		if (selectid) {
			setidProveedor(parseInt(selectid.id));
			setSelectProveedor(selectid);
		} else {
			setidProveedor(null);
			setSelectProveedor(null);
		}
	}

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <IonGrid style={{marginTop:'2px', justifyContent: 'space-between'}}>
                {notificationParam}
                <br/>
                <IonRow>
                    <IonCol size="4">
                        {listaMedios()}
                    </IonCol>
                    <IonCol size="4">
                        {listaTipos()}
                    </IonCol>
                    <IonCol size='4'>
                        <span className="p-float-label">    
                            <label>Proveedor</label>
                            <br/>
                        </span>
                        <ProveedoresLkUp
                            proveedor={selectProveedor}
                            onSelectProveedor={onselectProveedor}
                            style={{ width: "100%", borderRadius: '25px'}}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size='6'>
                        <span className="p-float-label">
                            <InputTextComponent
                                name='clave'
                                value={(fields.clave===null ? "":fields.clave)}
                                style={{ width: "100%", borderRadius: '25px'}}
                                required
                                disabled={false}
                                onChange={e=>{
                                    handleChange(e, 'clave');
									setValue("clave", e);
                                }}
                                accept={null}
                                className={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                type={'text'}
                            />
                            <label htmlFor="in">Clave</label>
                        </span>
                    </IonCol>
                    <IonCol size='6'>
                        <span className="p-float-label">
                            <InputTextComponent
                                name='claveproveedor'
                                value={(fields.claveproveedor===null ? "": fields.claveproveedor)}
                                disabled={false}
                                style={{ width: "100%", borderRadius: '25px'}}
                                type={'text'}
                                required
                                onChange={e=>{
                                    handleChange(e, 'claveproveedor')
                                    setValue('claveproveedor', e)
                                }}
                                accept={null}
                                className={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                            <label htmlFor="in">Clave Proveedor</label>
                        </span>
                    </IonCol>
                </IonRow>
                <br/>
                <IonRow>
                    <IonCol size='12'>
                        <span className="p-float-label">
                            <InputTextareaComponent
                                name='descripcion'
                                value={(fields.descripcion===null ? "":fields.descripcion)}
                                tabIndex={3}
                                disabled={false}
                                style={{ width: "100%", borderRadius: '25px'}}
                                rows={6}
                                required
                                onChange={e=>{
                                    handleChange(e, 'descripcion')
                                    setValue("descripcion", e)
                                }}
                            />
                            <label htmlFor="in">Descripción</label>
                        </span>
                    </IonCol>
                </IonRow>
                <br/>
                <IonRow>
                    <IonCol>
                        <span className="p-float-label">
                            <InputTextComponent
                                name='costo'
                                value={(fields.costo===null ? "":fields.costo)}
                                style={{ width: "100%", borderRadius: '25px'}}
                                required
                                disabled={false}
                                onChange={e=>{
                                    handleChange(e, 'costo');
									setValue("costo", e);
                                }}
                                accept={null}
                                className={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                type={'number'}
                            />
                            <label htmlFor="in">Costo</label>
                        </span>
                    </IonCol>
                    <IonCol>
                        <span className="p-float-label">
                            <InputTextComponent
                                name='precioventa'
                                value={(fields.precioventa===null ? "":fields.precioventa)}
                                style={{ width: "100%", borderRadius: '25px'}}
                                required
                                disabled={false}
                                onChange={e=>{
                                    handleChange(e, 'precioventa');
									setValue("precioventa", e);
                                }}
                                accept={null}
                                className={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                type={'number'}
                            />
                            <label htmlFor="in">Precio de Exhibición</label>
                        </span>
                    </IonCol>
                </IonRow>
                <br/>
                <IonRow>
                    <IonCol size="4">
                        <span className="p-float-label">
                            <InputTextComponent
                                name='base'
                                value={(fields.base===null ? "" : fields.base)}
                                style={{ width: "100%", borderRadius: '25px'}}
                                required
                                disabled={false}
                                onChange={e=>{
                                    handleChange(e, 'base')
                                    setValue('base', e)
                                }}
                                accept={null}
                                className={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                type={'number'}
                            />
                            <label htmlFor="in">Base</label>
                        </span>
                    </IonCol>
                    <IonCol size="4">
                        <span className="p-float-label">
                            <InputTextComponent
                                name='altura'
                                value={(fields.altura===null ? "" : fields.altura)}
                                style={{ width: "100%", borderRadius: '25px'}}
                                required
                                disabled={false}
                                onChange={e=>{
                                    handleChange(e, 'altura')
                                    setValue('altura', e)
                                }}
                                accept={null}
                                className={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                type={'number'}
                            />
                            <label htmlFor="in">Altura</label>
                        </span>
                    </IonCol>
                    <IonCol size="4">
                        <span className="p-float-label">
                            <InputTextComponent
                                name='Nivel'
                                value={(fields.nivel===null ? "" : fields.nivel)}
                                style={{width: "100%", borderRadius: '25px'}}
                                required
                                disabled={false}
                                onChange={e=>{
                                    handleChange(e, 'nivel')
                                    setValue('nivel', e)
                                }}
                                accept={null}
                                className={null}
                                label={null}
                                onBlur={null}
                                onKeyPress={null}
                                placeholder={null}
                                type={'text'}
                            />
                            <label htmlFor="in">Nivel</label>
                        </span>
                    </IonCol>
                </IonRow>
                <br/>
                <IonRow>
                    <IonCol size="4">
                    <label>Vista</label>
                        <FileUpload
                            mode="basic"
                            name="Imagen1"
                            url=""
                            chooseLabel="Subir Imagen"
                            accept="image/*"
                            onSelect={e=>{
                                setImagen1(e.files[0]);
                            }}
                        />
                    </IonCol>
                    <IonCol size="4">
                    <label>Mapa</label>
                        <FileUpload
                            mode="basic"
                            name="Imagen2"
                            url=""
                            chooseLabel="Subir Imagen"
                            accept="image/*"
                            onSelect={e=>{
                                setImagen2(e.files[0])
                            }}
                        />
                    </IonCol>
                    <IonCol size='4'>
                        <div>
                            <label>Ficha técnica</label>
                            <FileUpload
                                mode="basic"
                                name="ficha_tecnica"
                                url=""
                                chooseLabel="Subir Ficha Técnica"
                                accept=".pdf"
                                onSelect={e=>{
                                    setFichaTecnica(e.files[0]);
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
                <br/>
                <IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Activo</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={activo} onChange={(e) => validarActivo(e.value)} />
					</IonCol>
                    <IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Medio de Piso</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={piso} onChange={(e) => validarPisoFijo(e.value)} />
					</IonCol>
                </IonRow>
                <br/>
                <IonRow>
                    <IonCol>
                        <Button
                            label="Guardar"
                            icon="pi pi-check"
                            className="p-button-success ion-float-right"
                            style={{ marginLeft: "20px" }}
                            type='submit'
                        />
                    </IonCol>
                    <IonCol>
                        <Button
                            label="Salir   "
                            float-right="true"
                            icon="pi pi-times"
                            className="p-button-danger ion-float-left"
                            style={{ marginLeft: "20px" }}
                            onClick={props.onDiscard}
                        />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </form>
    );
}