/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {IonRow, IonCol, IonGrid, IonPopover, IonLabel, IonHeader, IonSpinner} from "@ionic/react";
import { Button } from "primereact/button";
import {LISTAR_CAMPANIAS, CAMPANIAS_URBANOS_QUERY, UPDATE_CAMPANIASURBANOS} from "./query";
import { InputTextComponent } from "../../components";
import moment from 'moment';
import { useQuery,
    useMutation,
    useLazyQuery} from "@apollo/client";
import {Dropdown} from 'primereact/dropdown'
import { useNotification } from "../../hooks";

const FormCampaniaEdit=(props)=>{
    //Fechas
    const [fecha, setFecha]=useState(moment().format('YYYY-MM-DD'));
    const [tomorrow, setTomorrow]=useState(moment().add(1, 'month').format('YYYY-MM-DD'));
    const [fecha_inicio, setFecha_inicio] = useState(fecha);
    const [fecha_fin, setFecha_fin] = useState(tomorrow);
    //Campaña
    const [campania, setCampania]=useState()
    const [idCampania, setIdCampania]=useState(0)
    const [campaniaSel, setCampaniaSel]=useState(null)

    const [refresh, setRefresh] = useState(false);
    const {
        notification,
        showSuccess,
        showInfo,
        showError,
        } = useNotification();
    
    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

    const {loading:loadingCampanias, error:errorCampanias, data:dataCampanias}=useQuery(LISTAR_CAMPANIAS, {
        variables:{
            sort:"idbitrix:DESC",
            where:{
                _or:[
                    {estatus:"Cerrado Ganado"},
                    {estatus:"Cotización personalizada"},
                    {estatus:"Cotización informal"}
                    ],
                preciourbanos_gt:0 
            }
        },
        onCompleted:(dataCampanias)=>{
          //console.log("Data Campañas ",dataCampanias.campanias)
        }, onError(errorCampanias){
            console.log("Error: ",errorCampanias)
        }
    });

    const onselectCampania=(selC)=>{
        if(selC){
            setCampaniaSel(selC.value)
            setCampania(selC)
            if(selC.value){
                setFecha_inicio(selC.value.inicia)
                setFecha_fin(selC.value.termina)
            }
            else{
                setFecha_inicio(fecha)
                setFecha_fin(tomorrow)
            }
        }
        else{
            setFecha_inicio(fecha)
            setFecha_fin(tomorrow)
            setCampaniaSel(null)
            setCampania(null)
            
        }
    }

    const campaniaDrop=()=>{
        if(errorCampanias) return <h5 style={{color:"red"}}>Algo salió mal</h5>;
        if(loadingCampanias) return  <IonSpinner name="dots" />;
        if(dataCampanias)
        return(
            <Dropdown
                name="campanias"
                filter 
                tabIndex={1}
                showClear={true}
                style={{width:'95%',borderRadius: "25px"}}
                dataKey='id'
                optionLabel='nombre'
                value={campaniaSel} 
                options={dataCampanias.campanias} 
                onChange={onselectCampania}
                placeholder='Selecciona la campaña'
                required={true}
            />
        )
    }

    const guardar=(cam)=>{
        const idCam=cam.value.id
        buscarCam({
            variables:{
                sort:"id:ASC",
                where:{
                    campania:idCam
                }
            }
        })
    }
    const [buscarCam]=useLazyQuery(CAMPANIAS_URBANOS_QUERY,{
        onCompleted(data){
            console.log(data.urbanoscampanias)
            const dataurbanos=data.urbanoscampanias;
            dataurbanos.forEach(element => {
                editarDuracion({
                    variables:{
                        input:{
                            where:{id:parseInt(element.id)},
                            data:{
                                inicia:fecha_inicio,
                                termina:fecha_fin
                            }
                        }
                    }
                })
            });
            
        }, onError(error){
            console.log("Error, ",error)
        }
    })

    const [editarDuracion]=useMutation(UPDATE_CAMPANIASURBANOS, {
        onCompleted(data){
            console.log(data)
            onSuccess()
        }, onError(error){
            console.log("Error, ", error)
        }
    })
    return(
        <div>
            {notification}
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonLabel>Seleccionar campaña</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    {campaniaDrop()}
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonLabel>Seleccionar Fecha de Inicio</IonLabel>
                    </IonCol>
                    <IonCol>
                        <IonLabel>Seleccionar Fecha de Fin</IonLabel>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <InputTextComponent
                            name={"fecha_inicio"}
                            type={"date"}
                            value={fecha_inicio}
                            style={{width:'90%',borderRadius: "25px"}}
                            required={true}
                            onChange={e =>{
                                setFecha_inicio(e);
                            }}
                            className={null}
                            disabled={null}
                            onKeyPress={null}
                            placeholder={null}
                            label={null}
                            accept={null}
                            onBlur={null}
                        />
                    </IonCol>
                    <IonCol>
                        <InputTextComponent
                            name={"fecha_fin"}
                            type={"date"}
                            value={fecha_fin}
                            style={{width:'90%',borderRadius: "25px"}}
                            required={true}
                            onChange={e =>{
                                setFecha_fin(e);
                            }}
                            className={null}
                            disabled={null}
                            onKeyPress={null}
                            placeholder={null}
                            label={null}
                            accept={null}
                            onBlur={null}
                        />
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonRow>
                <br/>
                <div style={{marginLeft:'25px', marginRight:'25px', marginTop:'10px', marginBottom:'20px'}}>
                    <Button
                        label="Guardar"
                        icon="pi pi-check"
                        onClick={()=>{guardar(campania)}}
                        className="p-button-success ion-float-right"
                        style={{ marginLeft: "20px"}}
                    />
                </div>
            </IonRow>
        </div>
        );

}
export default FormCampaniaEdit;