import {gql} from '@apollo/client';

export const LISTA_MARCAS_QUERY = gql `
    query listarMarcas($sort: String, $limit: Int, $start: Int, $where: JSON){
        marcas(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            nombre
            logo
            estatus
            created_at
            updated_at
            createdby{
                email
            }
            modifiedby{
                email
            }
        }
    }
`;

export const CREATE_MARCA_MUTATION = gql `
    mutation CrearMarca($input: createMarcaInput){
        createMarca(input:$input){
            marca{
                id
                nombre
                estatus
                createdby {
                    email
                }
                modifiedby{
                    email
                }      
            }
        }
    }
`;

export const UPDATE_MARCA_MUTATION = gql `
    mutation AtualizarMarca($input: updateMarcaInput){
        updateMarca(input:$input){
            marca{
                id
                nombre
                estatus
                createdby {
                    email
                }
                modifiedby{
                    email
                }      
            }
        }
    }
`;