import {isMobile} from 'react-device-detect';

const useTranslateGQLError = () => {
    const storage = isMobile?localStorage:sessionStorage;
    
    const translate = (graphQLErrors) =>{
        let mensaje = {title:'Error no identificado',message:'Se encontró un error no identificado'};
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path, extensions }) => {
                switch (message) {
                    case "Invalid token.":
                        storage.removeItem('token');
                        storage.removeItem('u');
                        if ((window.location.hash!=='#/registro')&&(window.location.href !== '#/login')){
                            window.location.href = '#/login'
                        }
                        mensaje = {title:'Error en sesion',message:'Tenía una sesión abierta inválida, reintente nuevamente'}
                        break;
                    case 'User Not Found':
                        storage.removeItem('token');
                        storage.removeItem('u');
                        mensaje = {title:'Error en sesion',message:'Usuario y/o contraseña no válidos'}
                        if ((window.location.hash!=='#/registro')&&(window.location.href !== '#/login')){
                            window.location.href = '#/login'
                        }
                        break
                    case 'Forbidden':
                        storage.removeItem('token');
                        storage.removeItem('u');
                        if ((window.location.hash!=='#/registro')&&(window.location.href !== '#/login')){
                            window.location.href = '#/login'
                        }
                        mensaje = {title:'Error en sesion',message:'Acceso restringido, valide su rol.'}
                        break;
                    case 'Bad Request':
                        if (extensions.exception.data.message[0].messages[0].message === 'Email is already taken.'){
                            //ver cómo resetear password y redirigirlo ahí
                            mensaje = {title:'Error en registro',message:'El e-mail ya está registrado.'}
                        }
                        if (extensions.exception.data.message[0].messages[0].message === 'Identifier or password invalid.'){
                            //ver cómo resetear password y redirigirlo ahí
                            mensaje = {title:'Error en ingreso',message:'Usuario y/o contraseña inválidos'};
                        }
                        break;
                    default:
                        mensaje = {title: "Error en consulta",message: message}
                        break;
                }
                if (extensions.code==='23505'){
                    let msjTemp = extensions.exception.detail;
                    msjTemp = msjTemp.replace('Key','Los valores');
                    msjTemp = msjTemp.replace('already exists','ya existen, no pueden duplicarse.');
                    mensaje = {title:'Datos duplicados',message:msjTemp};
                }
            });
        }
        return mensaje;
    }
    return { translate }   
}

export default useTranslateGQLError;