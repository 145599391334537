/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import {IonImg} from '@ionic/react'
import {IMJ_API_KEY} from '../../utils/Constanst'

type MarkerRutasProps = { key: number; id: number; lat: number; lng: number }
const MarkerRutas = (props: MarkerRutasProps) => {
    return (<div style={{ width: 50, height: 50, position: 'absolute', transform: 'translate(-50%, -80%)' }}>
        <IonImg src='/assets/markers/bus.png' style={{ width: 50, height: 50 }} />
    </div>)
}

type MarkerDraggableProps = { key: number; lat: number; lng: number }
const MarkerDraggable = (props: MarkerDraggableProps) => {
    return (<div style={{ width: 50, height: 50, position: 'absolute', transform: 'translate(-50%, -80%)' }}>
        <IonImg src='/assets/markers/bus.png' style={{ width: 50, height: 50 }} />
    </div>)
}

export default function MapaRutas(props) {
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [apiLoaded, setApiLoaded] = useState(false);
    const [polyLine, setPolyLine] = useState<any>(null);
    const [color, setColor] = useState('#' + Math.random().toString(16).substr(-6))
    const [centerMap, setCenterMap] = useState(props.mapCenter);
    const [draggable, setDraggable] = useState(true);
    const [dragedMarker,setDragedMarker] = useState({key:0,lat:0,lng:0});

    useEffect(() => {
        if (props.locations.length > 0 && apiLoaded) {
            if (maps) {
                creaRuta();
            }
        }
    }, [props.locations, maps])

    useEffect(() => {
        if (props.mapCenter) {
            setCenterMap(props.mapCenter);
        }
    });

    const creaRuta = () => {
        if (polyLine) {
            polyLine.setMap(null);
        }
        let poly = new maps!.Polyline({
            path: props.locations,
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 2
        })
        setPolyLine(poly)
        poly.setMap(map);
    }
    

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        setMap(map);
        setMaps(maps);
        setApiLoaded(true);
    };

    const _onClick = ({ x, y, lat, lng, event }) => {
        //Si la tecla alt está oprimida se está agregando un marcador
        if (event.altKey) {
            props.OnloadPunto(lat, lng);
        }
    }

    const onChildMouseDown = (key,marker) => {
        // set map no draggable 
        setDraggable(false);
    }

    const onChildMouseUp = (key,marker) => {
        //Si existe key>0 se está moviendo un marker
        if (dragedMarker && dragedMarker.key>0){
            setDraggable(true);
            props.EditPunto(dragedMarker.key,dragedMarker.lat,dragedMarker.lng);
            setDragedMarker(null);
        }else{
            //si key>0 se hizo click en un marcador, borrar.
            if (key>0){
                props.OnLoadId(key);
                setDraggable(true);
            }
        }
    }

    const onChildMouseMove = (key, marker, newCoords) => {
        setDragedMarker({key:key,lat:newCoords.lat,lng:newCoords.lng});
    }

    const onChaneFunction = () => {

    }


    return (
        <div id="map_canvas" style={{ height: "60vh", width: "100%" }}>
            <GoogleMapReact
                key={'mapa'}
                bootstrapURLKeys={{ key: IMJ_API_KEY, libraries: ["places", "drawing"] }}
                center={centerMap}
                defaultCenter={{
                    lat: 19.43411066622587,
                    lng: -99.1326554135682,
                }}
                defaultZoom={12}
                draggable={draggable}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                options={{
                    mapTypeControl: false,
                    streetViewControl: true,
                    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }], },]
                }}
                resetBoundsOnResize={true}
                onClick={_onClick}
                onChildMouseDown={onChildMouseDown}
                onChildMouseUp={onChildMouseUp}
                onChildMouseMove={onChildMouseMove}
                onChange={onChaneFunction}
                // on
            >
                {props.locations && props.locations.map(punto => {
                    return (<MarkerRutas
                        key={punto.id}
                        id={punto.id}
                        lat={punto.lat}
                        lng={punto.lng}
                    />
                    )
                })}
                {!draggable && <MarkerDraggable {...dragedMarker}/>}
            </GoogleMapReact>
        </div>
    )
}
