import React, { useContext, useState } from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonLabel, IonRow } from '@ionic/react';
import { Button } from 'primereact/button';
import {formatMoney} from '../../../utils/Utils';
import styles from './dialogPauta.module.css';
import { InputTextComponent, InputTextareaComponent } from '../..';
import { ShoppingCartContext } from '../../../context/ShoppingCartContext';

export const DetalleRutas = (props) => {
    const [rutas,setRutas] = useState(props.rutas);
    const {  
        setFecha_fin_rutas, 
        setFecha_inicio_rutas, 
        setCant,
        setDescuento } = useContext(ShoppingCartContext);

    const deleteRutasShopping = (product) =>{
        var array = [...rutas]; 
        var index = array.indexOf(product)
        if (index !== -1) {
            array.splice(index, 1);
            setRutas( array );
        }
    }


    const CabeceraRutas = ()=>{
        return(
            <IonRow style={{textAlign:"center", fontSize:"12", height: "80%"}} >
                <IonCard style={{width:"100%",textAlign:"center"}}> 
                    <IonRow> 
                    <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color: "black",textAlign:"center", width:"100%"}}>
                        <h2>Ruta</h2>
                    </IonCol>
                    <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color: "black",textAlign:"center", width:"100%"}} >
                        <h2>Tipo de exhibición</h2>
                    </IonCol>
                    <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color: "black",textAlign:"center", width:"100%"}}>
                        <h2>Cantidad</h2>
                    </IonCol>
                    <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width: "100%"}}>
                        <h2>Precio unitario por mes</h2>
                    </IonCol>
                    <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{color:"black",textAlign:"center", width: "100%"}}>
                        <h2>Fecha inicio</h2>
                    </IonCol>
                    <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{color:"black",textAlign:"center", width: "100%"}}>
                        <h2>Fecha fin</h2>
                    </IonCol>
                    <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width: "100%"}}>
                        <h2>Descuento</h2>
                    </IonCol>
                    <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{color:"black",textAlign:"center", width: "100%"}}>
                        <h2>Descripción</h2>
                    </IonCol>
                    <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{color:"black",textAlign:"center", width: "100%"}}>
                        <h2>Opciones</h2>
                    </IonCol>
                </IonRow>    
            </IonCard>
        </IonRow>
        )
    }

    const CuerpoRutas = ()=>{
        return (
            <IonRow style={{ textAlign: "center" }} >
            {rutas && rutas.map((prod) => (
                <IonCard style={{ width: "100%", textAlign: "center" }} key={Math.random()}>
                    <IonRow>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{ textAlign: "center" }}>
                            <h2>{prod.nombre}</h2>
                        </IonCol>
                        <IonCol size="10" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{ width: "100%" }} >
                            <InputTextComponent
                                name={''}
                                required={false}
                                value={prod.tipoexhib + ''}
                                style={{ width: "100%", color: "black", textAlign: "center", border: "none", fontSize: "8" }}
                                disabled={true}
                                onChange={null}
                                type={'text'}
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{ textAlign: "center" }}>
                            <InputTextComponent
                                type={"number"}
                                value={prod.cantidad}
                                onChange={e => {
                                    prod.cantidad = e;
                                    setCant(e);
                                }
                                }
                                required={null}
                                disabled={null}
                                name={''}
                                style={{ width: "80%", borderRadius: "25px" }}
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol>
                            <InputTextComponent
                                type={"text"}
                                name={''}
                                style={{ border: "0px", color: "black", textAlign: "center", width: "75%" }}
                                value={'$' + formatMoney(prod.preciourbano)}
                                disabled={true}
                                onChange={null}
                                required={null}
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{ textAlign: "center" }}>
                            <InputTextComponent
                                type={"date"}
                                name={''}
                                disabled={false}
                                value={prod.fecha_inicio}
                                required={true}
                                onChange={e => {
                                    prod.fecha_inicio = e;
                                    setFecha_inicio_rutas(e);
                                }}
                                style={{ width: "80%", borderRadius: "25px" }}
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{ textAlign: "center" }}>
                            <InputTextComponent
                                type={"date"}
                                value={prod.fecha_fin}
                                required={true}
                                onChange={e => {
                                    prod.fecha_fin = e;
                                    setFecha_fin_rutas(e);
                                }}
                                disabled={null}
                                name={null}
                                style={{ width: "80%", borderRadius: "25px" }}
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" style={{ textAlign: "center" }}>
                            <InputTextComponent
                                value={prod.descuento}
                                onChange={e => {
                                    prod.descuento = e;
                                    setDescuento(e);
                                }
                                }
                                onBlur={null}
                                accept={null}
                                className={null}
                                label={null}
                                onKeyPress={null}
                                placeholder={null}
                                disabled={null}
                                name={null}
                                required={null}
                                type={'text'}
                                style={{ width: "80%", borderRadius: "25px" }}
                            />

                            <IonLabel><b> % </b></IonLabel>
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="2" sizeXl="2" style={{ textAlign: "center" }}>
                            <InputTextareaComponent
                                rows={4}
                                style={{ width: "100%", borderRadius: "25px", color: "black", resize: "none" }}
                                value={prod.descripcion}
                                disabled={null}
                                name={null}
                                required={null}
                                onChange={null}
                                tabIndex={null}  
                            />
                        </IonCol>
                        <IonCol size="12" size-Xs="10" sizeMd="10" sizeLg="1" sizeXl="1" >
                            <br></br>
                            <span>
                                <Button
                                    type="button"
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-raised p-button-rounded"
                                    onClick={() => { deleteRutasShopping(prod);}}
                                />
                            </span>
                        </IonCol>
                    </IonRow>
                    <br></br>
                    <br></br>
                    <br></br>
                </IonCard>
            ))}
        </IonRow>
        )
    }

    return (
        <IonCard>
            <IonCardHeader style={{textAlign:"center",display:((Array.isArray(rutas) && rutas.length ===0) ? "none" : "block")}}>
                <IonCardTitle className={styles.headerMedios}><h1>Rutas</h1></IonCardTitle>
            </IonCardHeader>
            <IonCardContent style={{display:(rutas.length ===0 ? "none" : "inline")}}>
                <CabeceraRutas />
                <CuerpoRutas />
            </IonCardContent>
        </IonCard>
    )
}
