import React, { useState } from "react";
import {IonRow, IonCol, IonGrid, IonPopover, IonHeader, IonCardContent, IonFooter, IonSpinner, IonCard, IonCardHeader} from "@ionic/react";
import moment from 'moment';
import {Dropdown} from 'primereact/dropdown'
import Resumen from './Resumen'
const Excel = require('exceljs');

const FormResumenAnual=(props)=>{
    const año=moment().format('YYYY');
    const [anioSel, setAnioSel]=useState(null)
    const opciones=[]
    for(var i=5; i>=0; i--){
        var resta=moment(año).subtract(i, "year").format('YYYY')
        var z={
            id:i,
            value:resta
        }
        opciones.push(z)
    }
    
    const onSelectAnio=(anio)=>{
        if(anio.value){
            setAnioSel(anio.value)
        }
        else{
            setAnioSel(null)
        }
    }
    const corteAnual=()=>{
        if(anioSel!==null && props.campanias.urbanoscampanias && props.unidadesUrbanos.unidadesUrbanos){
            return(
                <Resumen 
                    anio={anioSel} 
                    urbanoscampanias={props.campanias.urbanoscampanias}
                    unidadesurbanos={props.unidadesUrbanos.unidadesUrbanos}
                />
            )
        }
        else{
            return(
                <div>
                    
                </div>
            )
        }
    }
    return(
            <div>
                <h2>Resumen Anual</h2>
                <IonCardContent>
                    <Dropdown
                        name="año"
                        tabIndex={2}
                        showClear={true}
                        style={{width:'80%',borderRadius: "25px"}}
                        placeholder='Selecciona el año de corte'
                        required={true}
                        optionLabel='value'
                        options={opciones}
                        value={anioSel}
                        onChange={onSelectAnio}
                        />
                    <br/>
                    {corteAnual()}
                </IonCardContent>
            </div>
    )
}
export default FormResumenAnual