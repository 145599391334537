import React, { useState } from "react";
import {
	IonPage,
	IonHeader,
	IonContent,
	IonRow,
	IonCol
} from "@ionic/react";
import { renderBoolean, renderCurrency } from "../../utils/dataTableAux";
import useNotification from "../../hooks/useNotification";
import {
	MToolBar,
	Toolbar,
	ChartHYM,
	ChartNSE,
	ChartUsers,
	ChartGrupoEdad,
	DTableV2
} from "../../components";
import useDialog from "../../hooks/useDialog";
import { useMutation, useLazyQuery} from "@apollo/client";
import useAuthentication from "../../hooks/useAuthentication";
import NumberFormat from 'react-number-format';
import {Button} from "primereact/button";
import {useJSReportPDF} from '../../hooks';
import { LISTA_INDOOR, UPDATE_ESTADO } from "./queries";
import FormABCIndoor from './FormABCIndoor';

const Abcindoor = (props) =>{
    const [titleHeader, settitleHeader] = useState("Titulo");
	const [refresh, setRefresh] = useState(false);
	const { usuarioid, rol } = useAuthentication();
	const [expandedRows, setExpandedRows] = useState();
    const { showDialog, FormDialog } = useDialog();
    

    const {
		notification,
		showSuccess,
		showInfo,
		showError
	} = useNotification();

    const onSuccess = () => {
		setRefresh(true);
		showSuccess("Operación exitosa", "Se realizó la operación exitosamente");
	}

	const onDiscard = () => {
		showInfo('Acción cancelada', 'Se abortó correctamente la operación')
	}

    const onError=()=>{
        showError("Error", 'Error en la operación')
    }

    const cambiarEstatus=(e)=>{
        if(e.target.name==='activo'){
            CambiarEstatusActivo(parseInt(e.target.id),e.target.value)
        }
    }

    const CambiarEstatusActivo =(id,activo) =>{
		if(id){
			updateMedio({
				variables:{
					input:{
						where:{
							id:id
						},data:{
						    activo:activo
						}
					}
				}
			})
		}
	}

    const [updateMedio] = useMutation(UPDATE_ESTADO, {
		onCompleted(data) {
			setRefresh(true);
			showSuccess('Se actualizó el registro exitosamente');
		}
	});

    ////////////////columnas
    const columnas=[
        {
            header: "Clave",
            field:"clave",
            sortable: true,
            filter:true,
            filterMatchMode:"contains"
        },
        {
            header: "Tipo",
            field: "tipo.nombre",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header:"Tipo de Medio",
            field: "tipo.tipomedio.nombre",
            sortable:true,
            filter: true, 
            filterMatchMode: "contains"
        },
        {
            header:"Proveedor",
            field:"proveedor.nombre_comercial",
            sortable: true,
            filter: true,
            filterMatchMode: "contains"
        },
        {
            header: "Activo",
            field: "activo",
            sortable: true,
            body:renderBoolean,
            customChange: cambiarEstatus
        },
        {
            header: "Clave Proveedor",
            field: "claveproveedor"
        },
        {
            header: "Nivel",
            field: "nivel"
        },
        {
            header: "Base",
            field: "base"
        },
        {
            header: "Altura",
            field: "altura"
        },
        {
            header: "Comentarios",
            field: "comentarios"
        },
        {
            header: "Costo",
            field: "costo"
        },
        {
            header: "Precio",
            field: "precioventa"
        },
        {
            header: "Indoor de Piso",
            field: "piso",
            body: renderBoolean
        }
        
    ]

    const addMedio=()=>{
        let data= {Createdby: usuarioid(), Modifiedby:usuarioid(), activo: true}
        console.log(data)
        showDialog(data, "crear");
		settitleHeader('Alta de Sitios Indoor');
    }

    const editMedio=(row)=>{
        showDialog(row, "editar");
        settitleHeader('Edición de Sitios Indoor');
        console.log(row)
    }

    
    return(
        <IonPage>
            <IonHeader>
				<Toolbar
					title={props.title}
					setMenuEnabled={props.setMenuEnabled}
					menuEnabled={props.menuEnabled}>
				</Toolbar>
			</IonHeader>
            <IonContent style={{overflow:'scroll'}}>
                {notification}
                <MToolBar addClick={addMedio}/>
                <DTableV2
                    filtro={{
                        'tipo.tipomedio.nombre':'InDoor',
                    }}
                    ordenarPor={'created_at:desc'}
                    autoLayout={true}
                    style={{ margin: "10px" }}
                    columnas={columnas}
                    paginable={true}
                    query={LISTA_INDOOR}
                    canAdd={false}
                    canView={false}
                    canEdit={true}
                    onEdit={editMedio}
                    canDelete={true}
                    refresh={refresh}
                    onEndRefresh={() => setRefresh(false)}
                    responsive={false}
                    element="indoors"
                />
                <FormDialog
                    header={titleHeader}
                    onSuccess={onSuccess}
                    onDiscard={onDiscard}
                    onError={onError}
                >
                    <FormABCIndoor />
                </FormDialog>
            </IonContent>
        </IonPage>
    )
}
export default Abcindoor;