import { IonCol, IonGrid, IonRow, IonText, IonToggle } from '@ionic/react';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react'
import { TiposMedio } from '../../../../components/mapa/Controls';
import InputRange, { Range } from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './PopUpCirculos.css';
import { formatMoney } from '../../../../utils/Utils';
import { Iluminacion, NichosFiltro, VistasFiltro } from '../ControlesPopUp/ControlesPopUp';
import { InputTextComponent } from '../../../../components';

const x:Range ={min:30000,max:500000};
const classNamesInput={
    activeTrack: 'input-range__track input-range__track--active',
    disabledInputRange: 'input-range input-range--disabled',
    inputRange: 'input-range',
    labelContainer: 'input-range__label-container inputlabel',
    maxLabel: 'input-range__label input-range__label--max',
    minLabel: 'input-range__label input-range__label--min',
    slider: 'input-range__slider',
    sliderContainer: 'input-range__slider-container',
    track: 'input-range__track input-range__track--background',
    valueLabel: 'input-range__label input-range__label--value',
  };

const PopUpCirculos = (props) => {
    const [etiqueta, setEtiqueta] = useState<string>(props.circulo.etiqueta);
    const [rango,setRango] = useState(x); 
    useEffect(()=>{
        setRango(props.circulo.rprecios);
    },[props])
    
    return (
        <div style={{fontSize: '12px', width:'100%'}}>
            <div style={{ marginBottom: '10px',width:'100%' }}>

                <IonRow style={{marginTop:'70px'}}>
                    <IonCol size="3" className='xc'>
                        Etiqueta de Pauta:
                    </IonCol>
                    <IonCol size="9">
                        <InputTextComponent
                            value={etiqueta}
                            style={{ width:'100%', marginRight: '5px' }}
                            onChange={(e) => {
                                console.log(props.circulo.etiqueta)
                                props.circulo.etiqueta = e;
                                setEtiqueta(e);
                            }}
                            disabled={null}
                            name={null}
                            required={null}
                            type={'text'}
                            accept={null}
                            className={null}
                            label={null}
                            onBlur={null}
                            onKeyPress={null}
                            placeholder={null}
                        />
                    </IonCol>
                </IonRow>
            </div>

            <div>Mostrar rutas<span>
                <IonToggle checked={props.circulo ? props.circulo.showRutas : false}
                    style={{ verticalAlign: 'middle', marginLeft: '5px' }}
                    onIonChange={(e) => {
                        props.circulo.setCheckedRutas(e['detail'].checked);
                        props.circulo.buscarRutas();
                        //stateRefprops.circulo.current = props.circulo;
                    }}
                />
                </span>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <p>Tipos de sitios</p>
                <TiposMedio
                    seleccion={props.circulo ? props.circulo.showSitios : []}
                    onMediosSelected={(nmedios) => {
                        props.circulo.showSitios = nmedios;
                    }}
                    style={{
                        backgroundColor: '#FFFFFF',
                        opacity: '90%',
                        maxWidth: '100%',
                        minWidth: '150px',
                        fontSize: '14px',
                        zIndex:9000
                    }} />
            </div>
            <div style={{ marginBottom: '10px' }}>
                <IonRow>
                    <IonCol size='3' >
                        <div className="xc ion-align-items-center">
                            <IonText>Con iluminación</IonText>
                        </div>
                    </IonCol>
                    
                    <IonCol size='9'>
                        <Iluminacion value={props.circulo ? props.circulo.iluminacion : ''} 
                            onChange={(e)=>{
                                props.circulo.iluminacion = e.value
                            }}/>
                    </IonCol>
                </IonRow>
            </div>
            <div style={{ marginBottom: '25px' }}>
                <IonRow>
                    <IonCol size='3' >
                        <div className="xc">
                            <IonText>Nicho</IonText>
                        </div>
                    </IonCol>
                    
                    <IonCol size='9'>
                        <NichosFiltro nicho={props.circulo ? props.circulo.nicho : false} 
                            onSelectNicho={(e)=>props.circulo.nicho = e}/>
                    </IonCol>
                </IonRow>
            </div>
            <div style={{ marginBottom: '25px' }}>
                <IonRow>
                    <IonCol size='3' >
                        <div className="xc">
                            <IonText>Vista</IonText>
                        </div>
                    </IonCol>
                    
                    <IonCol size='9'>
                        <VistasFiltro vista={props.circulo ? props.circulo.vistas : []} 
                            onSelectVista={(e)=>props.circulo.vistas = e}/>
                    </IonCol>
                </IonRow>
            </div>
            <div style={{ marginBottom: '30px' }}>
                <IonRow>
                    <IonCol size='3'>Rango de precios</IonCol>
                    <IonCol size='9'>
                        <InputRange
                            classNames = {classNamesInput}
                            formatLabel={value => '$ '+formatMoney(value,2,'.',',')}
                            draggableTrack
                            maxValue={300000}
                            minValue={3900}
                            step={20000}
                            value={rango}
                            onChange={(value)=>{
                                if (((value as Range).min>=0)&&((value as Range).max>=0)){
                                    let rptemp = {
                                        min:(value as Range).min,
                                        max:(value as Range).max
                                    }
                                    setRango(rptemp);
                                }
                            }}
                            onChangeComplete={(value) =>{ 
                                if (((value as Range).min>=0)&&((value as Range).max>=0)){
                                    let rptemp = {
                                        min:(value as Range).min,
                                        max:(value as Range).max
                                    }
                                    setRango(rptemp);
                                    props.circulo.rprecios.min = rptemp.min;
                                    props.circulo.rprecios.max = rptemp.max;
                                }
                            }} />
                    </IonCol>
                </IonRow>
            </div>
            <div style={{ marginBottom: '30px' }}>
                <IonGrid>
                    <IonRow className='ion-justify-content-center'>
                        <IonCol size='2' style={{marginRight:'10px'}}>
                            <Button style={{backgroundColor:'black', borderRadius: '25px', borderColor: 'black'}} 
                            onClick={()=>{
                                props.circulo.buscarRutasYMedios().then(()=>{
                                    //Cuando se termina la búsqueda se manda el círculo a un callback en sitios
                                    //para construir el objeto de shoppingcart y actualizar
                                    props.onFinish(props.circulo);
                                });
                            }}>Buscar</Button>
                        </IonCol>
                        <IonCol size="2">
                            <div className='ion-text-start'>
                                <Button className="ion-float-right p-button-raised p-button-rounded p-button-danger"
                                    icon="pi pi-trash"
                                    tooltip="Borrar círculo"
                                    title='Borrar Círculo'
                                    onClick={(e) => {
                                        console.log('onclick removeCicle', props.circulo);
                                        props.circulo.removeCircle();
                                        props.circulo.showModal = false;
                                    }} />
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                
                    
            </div>
        </div>
    )
}

export default PopUpCirculos;
