/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { IonRow, IonCol, IonGrid } from "@ionic/react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import {ColorPicker} from 'primereact/colorpicker';
import useAuthentication from "../../hooks/useAuthentication";
import { useLazyQuery, useMutation } from "@apollo/client";
import {CLIENTES_QUERY,CREATE_CLIENTE,UPDATE_CLIENTE, BUSCAR_USUARIO} from "./queries";
import  {Imgfile64Cliente, InputTextComponent}  from '../../components';
import {CREAR_USUARIO, ACTUALIZAR_USUARIO} from "../registrarUsuario/queries";
// import {generatePasswordRand} from "../../utils/Utils.js"

export default function FormClientes(props){

	const {usuarioid} = useAuthentication(); 

    const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
			nombre: props.datos.nombre,
            correo: props.datos.correo,
            empresa: props.datos.empresa,
            rfc: props.datos.rfc,
            tipo:props.datos.tipo,
			idbitrix: props.datos.idbitrix,
			color:props.datos.color,
			logo:props.datos.logo,
		}
	});
	
	const [colorVista, setcolorVista] = useState("");
	const [ImgUrl, setImgUrl] = useState("");
	const [fields,setValueFields] = useState(props.datos);
    const getFieldsUpdate = () => {
		let fieldtemp = { ...fields };
		if(fieldtemp.idbitrix !== undefined){
			var comversion = parseInt(fieldtemp.idbitrix);
			fieldtemp.idbitrix=comversion;
		}
		fieldtemp.activo=(fieldtemp.activo=== null ?true : fieldtemp.activo);
        delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		return fieldtemp;
	};

	//Campos para la creación de usuario
	//En local, role es 10. En producción, cambiar a 9
	const [nombreUser, setNombreUser] = useState("");
	const [correoUser, setCorreoUser] = useState("");
	const [passUser, setpassUser] = useState("");
	const [tempidUser, setTempidUsers] = useState(0);
	const [tempidCliente, setTempidCliente] = useState(0);
	const [idUsuario, setIdUsuario] = useState(0);


	let passwordrand
	//Generador random de contraseñas
	const generatePasswordRand=(length,type)=>{
		let characters
		switch(type){
			case 'num':
				characters = "0123456789";
				break;
			case 'alf':
				characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
				break;
			case 'rand':
				//FOR ↓
				break;
			default:
				characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
				break;
		}
		var pass = "";
		for (let i=0; i < length; i++){
			if(type === 'rand'){
				pass += String.fromCharCode((Math.floor((Math.random() * 100)) % 94) + 33);
			}else{
				pass += characters.charAt(Math.floor(Math.random()*characters.length));   
			}
		}
		passwordrand=pass;
		return passwordrand;
	}

    const [updateCliente] = useMutation(UPDATE_CLIENTE, {
		variables: {
			input: {
				where: { id: fields.id },
				data: getFieldsUpdate()	
			}
		},
		onCompleted(data){
            props.onSuccess();
		}
	});

    const [createCliente] = useMutation(CREATE_CLIENTE, {
		variables: {
			input: {
				data: getFieldsUpdate()
			}
		},
		refetchQueries: [{ query: CLIENTES_QUERY }],
		onCompleted(data) {
			
			setTempidCliente(parseInt(data.createCliente.cliente.id));
			props.onSuccess();
		}
	});


	const [buscarUsuario]=useLazyQuery(BUSCAR_USUARIO,{
			onCompleted(data){
				if(data.users.length===0 && passUser!==""){
					CrearUser({variables:{
						input:{
						data:{
						blocked: false,
						createdby: usuarioid(),
						email: fields.correo,
						modifiedby: usuarioid(),
						nombre:fields.nombre,
						password:passUser,
						role: 9,
						username: fields.correo
						}
						}
						}});
				}
				if(data.users.length===0 && passUser==="")
				{
					generatePasswordRand(6, 'rand');
					CrearUser({variables:{
						input:{
						data:{
						blocked: false,
						createdby: usuarioid(),
						email: fields.correo,
						modifiedby: usuarioid(),
						nombre:fields.nombre,
						password:passwordrand,
						role: 9,
						username: fields.correo
						}
						}
						}});
				}
				if(data.users.length!==0 && passUser!==""){
					const idu=(parseInt(data.users[0].id));
					ActualizarUsuario( 
						{variables:{
						    input:{
						        where: { id: idu },
						        data:{
									password:passUser
								}
						    }
						}})
				}
				if(data.users.length!==0 && passUser===""){
					updateCliente()
				}
			},
			onError(error) {
				console.log('error', error)
			}
	});

	//Actualiza pass
	const [ActualizarUsuario] =useMutation(ACTUALIZAR_USUARIO,{
        onCompleted(data){
			ActualizarClienteUser()
            props.onSuccess();
        }
    });

	//Se asigna el id de usuario al cliente
	const ActualizarClienteUser=()=>{
		updateClienteUser({
			variables:{
				input:{
					where:{
						id:tempidCliente
					},
					data:{
						user:idUsuario
					}
				}
			}
		})
	}

	const [updateClienteUser] = useMutation(UPDATE_CLIENTE, {
		onCompleted(data){
            props.onSuccess();
		}, onError(error){
			console.log(error)
		}
	});

	const usuarioCliente = (correo)=>{
			buscarUsuario({
				variables:{
					where:{
						email:correo
					}
				}
			}) 
		
	}
	const [CrearUser] = useMutation(CREAR_USUARIO,{
		onCompleted:(data) =>{
			setIdUsuario(parseInt(data.createUser.user.id));
			let a=parseInt(data.createUser.user.id)
			setIdUsuario(a)
			ActualizarClienteUser()
		}
	});

	const onSubmit = data => { 
        if (props.operacion==='editar'){
			setTempidCliente(props.datos.id)
			usuarioCliente(fields.correo)
		 }
  
    }

    useEffect(() => {
		register({ name: "nombre" });
	}, [register]);
    
    const handleChange = (e, name = '') =>{
		setValueFields({...fields,[name]:e})
	}

	const establecer_color = (color) =>{
		setcolorVista(color);
		fields.color=color;
	}

	const comversioImagen =async (event) =>{
		const file =  event.target.files[0];
		const imagen = await Imgfile64Cliente(file);
		establecerUrlLogo(imagen);
		fields.logo=imagen;

	}

	const establecerUrlLogo = (url)=>{
		setImgUrl(url);
	}

	const mostrarImagen =()=>{
		if(ImgUrl !== ""){
			return(
				<img src={ImgUrl} style={{display:"block",margin:"auto"}} width="150"  height="150" alt="No hay imagen"></img>
			);
		}else if(fields.logo !== undefined && fields.logo !== "" && fields.logo !== null && ImgUrl === ""){
			return(
				<img src={fields.logo} style={{display:"block",margin:"auto"}}
				width="150" height="150" alt="Imagen de Cliente"></img>
			);
		}else{
			return(
				<div>
					<h5 style={{textAlign:'center'}}>Sin imagen</h5>
				</div>	
			);
		}
	}
	
	const botones = ()=>{
		if(props.operacion === 'editar' || props.operacion === 'crear'){
			return (
				<div >
					<IonRow >
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6" >
							<div style={{ marginRight: "40px"}}></div>
							<Button
								label="Grabar"
								icon="pi pi-check"
								type="submit"
								className="p-button-success ion-float-right"
								style={{ marginLeft: "20px"}}
							/>
						</IonCol>
						<IonCol size="6" size-Xs="6" sizeMd="6" sizeLg="6" sizeXl="6">
							<Button
								label="Salir  "
								icon="pi pi-times"
								className="p-button-danger ion-float-left"
								float-right="true"
								onClick={props.onDiscard}
								style={{ marginLeft: "20px"}}
							/>
							<div style={{ marginRight: "40px"}}></div>
						</IonCol>	
					</IonRow>	
				</div>
			)
		}else{
			return (<div></div>)
		}
	}
	
	
	return (
		<form onSubmit={handleSubmit(onSubmit)} >
			<IonGrid >
				<IonRow >
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label ">
							<InputTextComponent
								// tabIndex={3}
								name="nombre"
								value={(fields.nombre === null ? "" : fields.nombre)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'nombre');
									setValue("nombre", e);
									setNombreUser(e);
								}}
								accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
								onBlur={null}
							/>
							<label htmlFor="in">Nombre</label>
						</span>
						</IonCol>
						
						<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
							<span className="p-float-label ">
								<InputTextComponent
									// tabIndex={4}
									name="empresa"
									value={(fields.empresa === null ? "" : fields.empresa)}
									style={{ width: "100%",color:'black'}}
									disabled={props.operacion === "detalles" ? true:false}
									required
									onChange={e => {
										handleChange(e, 'empresa');
										setValue("empresa", e);
									}}
									accept={null}
									className={null}
									label={null}
									onKeyPress={null}
									placeholder={null}
									type={null}
									onBlur={null}
								/>
								<label htmlFor="in">Empresa</label>
							</span>
						</IonCol>

						<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								// tabIndex={5}
								name="rfc"
								value={(fields.rfc === null ? "" : fields.rfc)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'rfc');
									setValue("rfc", e);
								}}
								accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
								onBlur={null}
							/>
							<label htmlFor="in">RFC</label>
						</span>
					</IonCol>		
				</IonRow>
				
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label">
							<InputTextComponent
								name={'correo'}
								value={(fields.correo === null ? "" : fields.correo)}
								type={"email"}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								required
								onChange={e => {
									handleChange(e, 'correo');
									setValue("correo", e);
									setCorreoUser(e);
								}}
								accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								onBlur={null}
							/>
							<label htmlFor="in">Correo</label>
						</span>
					</IonCol>

					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4" style={{textAlign:'center'}}>
						<br></br>
						<span className="p-float-label ">
								<InputTextComponent
									name="password"
									type="password"
									value={(passUser === null ? "" : passUser)}
									style={{ width: "100%",color:'black'}}
									onChange={e => {
										setpassUser(e);
										
									}}
									accept={null}
									className={null}
									label={null}
									onKeyPress={null}
									placeholder={null}
									disabled={null}
									onBlur={null}
									required={null}
								/>
								<label htmlFor="in">Password</label>
						</span>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<br></br>
						<span className="p-float-label ">
							<InputTextComponent
								name="idbitrix"
								value={(fields.idbitrix === null ? "" : fields.idbitrix)}
								type="number"
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								
								onChange={e => {
									handleChange(e, 'idbitrix');
									setValue("idbitrix",e);
								}}
								accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
								onBlur={null}
							/>
							<label htmlFor="in">Id bitrix</label>
						</span>
					</IonCol>
				</IonRow>
				<br></br>
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4">
						<span className="p-float-label ">
							<InputTextComponent
								name="tipo"
								value={(fields.tipo === null ? "" : fields.tipo)}
								style={{ width: "100%",color:'black'}}
								disabled={props.operacion === "detalles" ? true:false}
								
								onChange={e => {
									handleChange(e, 'tipo');
									setValue("tipo", e);
								}}
								onBlur={null}
								accept={null}
								className={null}
								label={null}
								onKeyPress={null}
								placeholder={null}
								required={null}
								type={null}
							/>
							<label htmlFor="in">Tipo</label>
						</span>
					</IonCol>
				</IonRow>
				<br></br>
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="4" sizeXl="4" style={{textAlign:'center'}}>
						<label htmlFor="in">Color</label>
						<br></br>
						<ColorPicker  value={(fields.color === null ? colorVista : fields.color) } 
							onChange={(e) => establecer_color(e.value)}
							inline={true}
						></ColorPicker>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="1" sizeXl="1" >
						<label style={{textAlign:'left'}}>Logo</label>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="7" sizeXl="7" >
						<input 
							tabIndex={2}
							name="logo" 
							style={{width:'100%',border:"1px solid black"}}
							type="file"
							accept="image/x-png,image/gif,image/jpeg"
							disabled={props.operacion === "detalles" ? true:false}
							onChange={event => comversioImagen(event)}
						/>
						{mostrarImagen()}
					</IonCol> 
				</IonRow>
				
				<br/><br/>
				<div>{botones()}</div>
				<IonRow>
					
				</IonRow>
			</IonGrid>
		</form>
	);

}