/* eslint-disable use-isnan */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {useState} from "react";
import {IonRow, IonCol, IonGrid} from "@ionic/react";
import {InputText} from "primereact/inputtext";
import {useForm} from "react-hook-form";
import {InputSwitch} from 'primereact/inputswitch';
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
	MEDIOS_QUERY,
	CREATE_MEDIO, 
	UPDATE_MEDIO,
	listaUbicaciones_Query,
	listaVistas_Query,
	BUSCAR_SITIO_POR_ID
} from "../../queries/queries";
import {Botones} from '../';
import { ProveedoresLkUp, ListaTipos } from "../LookUpsABC";
import {ImageUpload, InputTextComponent, InputTextareaComponent} from "../../../../components";
import { InputNumber } from 'primereact/inputnumber';
import './FormAbcmedios.css';
import {
	GOOGLE_PLACES_ZERO_RESULTS,
	GOOGLE_PLACES_ZERO_RESULTS_RESPONSE,
	INVALID_CATEGORY_LOCALITY,
	INVALID_VALUE_POI_7_ELEVEN,
	INVALID_VALUE_TELEFONO_PUBLICO,
	INVALID_VALUE_TELEFONO_PUBLICO_ACENTOS,
} from "../../../../utils/Constanst";
import { useNotification } from "../../../../hooks";
import {INVALID_VALUE_POI_OXXO} from '../../../../utils/Constanst';
import {CargarListaUbicaciones, CargarListaVistas, MostrarOcultarMapa} from '../';

export default function FormAbcmedios(props) {
	const { handleSubmit, setValue } = useForm({
		defaultValues: {
			clave: props.datos.clave,
			direccion: props.datos.direccion,
			medida: props.datos.medida,
			claveproveedor: props.datos.claveproveedor,
			disponible: props.datos.disponible,
			activo: props.datos.activo,
			vialidad: props.datos.vialidad,
			colonia: props.datos.colonia,
			largo: props.datos.largo,
			ancho: props.datos.ancho,
			niveldeimpacto: props.datos.niveldeimpacto,
			iluminacion: props.datos.iluminacion,
			referencias: props.datos.referencias,
			comentarios: props.datos.comentarios,
			costo: props.datos.costo,
			precioventa: props.datos.precioventa,
			Vista_corta: props.datos.Vista_corta,
			Vista_media: props.datos.Vista_media,
			Vista_larga: props.datos.Vista_larga,
			imagenmapaubicacion: props.datos.imagenmapaubicacion,
			material: props.datos.material,
			latitud: props.datos.latitud,
			longitud: props.datos.longitud,
			tipoacabado: props.datos.tipoacabado,
			ubicacion: props.datos.id,
			vista: props.datos.id,
			tipos: props.datos.id,
			proveedor: props.datos.id,
			especiales: props.datos.especiales
		}
	});

	

	const {notification: notificationParam, showWarn, showSuccess} = useNotification();
	
	const [location, setLocation] = useState({
		id: 0,
		name: 'Guadalajara, Jalisco',
		lat: props.datos.latitud,
		lng: props.datos.longitud
	});
	const [fields, setValueFields] = useState(props.datos);
	const [selectProveedor, setSelectProveedor] = useState<string>(fields.proveedor === null ? "" : fields.proveedor);
	const [selectubicaciones, setSelectUbicaciones] = useState<string>(fields.ubicacion === null ? "" : fields.ubicacion);
	const [selectVistas, setSelectVistas] = useState<string>(fields.vista === null ? "" : fields.vista);
	const [selectTipos, setSelectTipos] = useState<string>(fields.tipos === null ? "" : fields.tipos);
	const re = / /gi; 
	// const [selectNicho, setSelectNicho] = useState(fields.nicho === null ?  "" : fields.nicho);
	//const [selectNicho, setSelectNicho] = useState(fields.nicho === null ?  "" : fields.nicho.replace(re, ''));
	const [selectNicho, setSelectNicho] = useState(fields.nicho === null || fields.nicho === undefined?  "" : fields.nicho.replace(re, ''));

	//Vistas
	const [ImgCorta, setImgCorta] = useState(fields.Vista_corta);
	const [ImgMedia, setImgMedia] = useState(fields.Vista_media);
	const [ImgLarga, setImgLarga] = useState(fields.Vista_larga);

	const [latitud, setLatitud] = useState(fields.latitud);
	const [longitud, setLongitud] = useState(fields.longitud);

	const [idUbicacion, setidUbicacion] = useState(props.datos.ubicacion === null || props.datos.ubicacion === undefined ? 1 : parseInt(props.datos.ubicacion.id));
	const [idVista, setidVista] = useState(props.datos.vista === null || props.datos.vista === undefined ? 1 : parseInt(props.datos.vista.id));
	const [idTipos, setidTipos] = useState(props.datos.tipos === null || props.datos.tipos === undefined ? 4 : parseInt(props.datos.tipos.id));
	const [idProveedor, setidProveedor] = useState(props.datos.proveedor === null || props.datos.proveedor === undefined ? 2 : parseInt(props.datos.proveedor.id));
	const [disponibleBoolean, setdisponibleBoolean] = useState(fields.disponible);

	const [activoBoolean, setactivoBoolean] = useState(fields.activo);
	const [iluminacionBoolean, setiluminacionBoolean] = useState(fields.iluminacion);
	const [textareaReferencias, settextareaReferencias] = useState(fields.referencias ? fields.referencias : '');
	const [textareaComentarios, settextareaComentarios] = useState(fields.comentarios)
	const [direccion, setDireccion] = useState(fields.direccion);
	const [especiales, setEspeciales]=useState(fields.especiales)
	// const [direccionBuscar, setdireccionBuscar] = useState(fields.direccion);


	const [mapReference, setMapReference] = useState();

	const { loading: loading_listaUbicaciones, data: data_listaUbicaciones } = useQuery(listaUbicaciones_Query);

	const getFieldsUpdate = () => {
		
		let referenceTemp = '';
		if (textareaReferencias.length > 255) {
			referenceTemp = textareaReferencias.substring(0,255);
		} else {
			referenceTemp = textareaReferencias;
		}
		let fieldtemp = { ...fields };
		delete fieldtemp.id;
		delete fieldtemp.__typename;
		delete fieldtemp.created_at;
		delete fieldtemp.updated_at;
		delete fieldtemp.Vista_larga;
		delete fieldtemp.Vista_corta;
		delete fieldtemp.Vista_media;
		fieldtemp.vista = (idVista === NaN ? 1 : idVista);
		fieldtemp.ubicacion = (isNaN(idUbicacion) ? 1 : idUbicacion);
		fieldtemp.tipos = (isNaN(idTipos) ? 4 : idTipos);
		fieldtemp.proveedor = (isNaN(idProveedor) ? 2 : idProveedor);
		fieldtemp.precioventa = parseFloat(fieldtemp.precioventa);
		fieldtemp.costo = parseFloat(fieldtemp.costo);
		fieldtemp.direccion = direccion;
		fieldtemp.disponible = (disponibleBoolean === undefined ? false : disponibleBoolean);
		fieldtemp.activo = (activoBoolean === undefined ? false : activoBoolean);
		fieldtemp.iluminacion = (iluminacionBoolean === undefined ? false : iluminacionBoolean);
		fieldtemp.especiales = (especiales === undefined ? false : especiales);
		// fieldtemp.referencias = textareaReferencias;
		fieldtemp.referencias = referenceTemp;
		
		fieldtemp.latitud = parseFloat(latitud);
		fieldtemp.longitud = parseFloat(longitud);
		return fieldtemp;
	};

	const getImagesUpdate = () =>{
		let fieldtemp = { Vista_larga:'',Vista_media:'',Vista_corta:'' };
		fieldtemp.Vista_larga = (ImgLarga === null ? "" : ImgLarga);
		fieldtemp.Vista_corta = (ImgCorta === null ? "" : ImgCorta);
		fieldtemp.Vista_media = (ImgMedia === null ? "" : ImgMedia);
		return fieldtemp;
	}

	const [UpdateMedio] = useMutation(UPDATE_MEDIO, {
		variables: {
			input: {
				where: { id: parseInt(fields.id) },
				data: getFieldsUpdate()
			}
		},
		onCompleted(data) {
			//Se graban las fotos una por una porque el payload es muy grande y marca error en strapi
			UpdateMedioFotos({
				variables:{
					input:{
						where: { id: parseInt(data.updateMedio.medio.id) },
						data:{
							Vista_larga: (ImgLarga === null ? "" : ImgLarga)
						}
					}
				}
			});
			UpdateMedioFotos({
				variables:{
					input:{
						where: { id: parseInt(data.updateMedio.medio.id) },
						data:{
							Vista_media: (ImgMedia === null ? "" : ImgMedia)
						}
					}
				}
			});
			UpdateMedioFotos({
				variables:{
					input:{
						where: { id: parseInt(data.updateMedio.medio.id) },
						data:{
							Vista_corta: (ImgCorta === null ? "" : ImgCorta)
						}
					}
				}
			});
			props.onSuccess();
		}
	});

	const [UpdateMedioFotos] = useMutation(UPDATE_MEDIO);

	const [createMedio] = useMutation(CREATE_MEDIO, {
		variables: {
			input: {
				data: getFieldsUpdate()
			}
		},
		refetchQueries: [{ query: MEDIOS_QUERY }],
		onCompleted(data) {
			UpdateMedioFotos({variables:{
				input: {
					where: { id: parseInt(data.createMedio.medio.id) },
					data: getImagesUpdate()
				}
			}})
			showSuccess("Registro exitoso", "Registro guardado con éxito")
			//props.onSuccess();
		}
	});

	const { loading: loading_listaVistas, data: data_listaVistas } = useQuery(listaVistas_Query);

	const onSelectTipos = (tipoSelected) => {
		if (tipoSelected) {
			setidTipos(tipoSelected.id);
			//selectTipos(tipoSelected);
		} else {
			setidTipos(null);
			//selectTipos(tipoSelected);
		}
	}

	const onselectUbicacion = (selectid) => {
		if (selectid.value) {
			setidUbicacion(parseInt(selectid.value.id));
			setSelectUbicaciones(selectid.value);
		} else {
			setidUbicacion(null);
			setSelectUbicaciones(null);
		}

	}

	const onselectVista = (selectid) => {
		if (selectid.value) {
			setSelectVistas(selectid.value);
			setidVista(parseInt(selectid.value.id));
		} else {
			setSelectVistas(null);
			setidVista(null);
		}
	}

	const onselectProveedor = (selectid) => {
		if (selectid) {
			setidProveedor(parseInt(selectid.id));
			setSelectProveedor(selectid);
		} else {
			setidProveedor(null);
			setSelectProveedor(null);
		}
	}

	const onSubmit = data => {
		if (props.operacion === 'editar') {
			UpdateMedio();
		} else if (props.operacion === 'crear') {
			console.log("clave: ", fields.clave)
			buscaID({
				variables:{
					where:{
						clave:fields.clave
					}
				}
			})
		}
	}

	
	const [buscaID]=useLazyQuery(BUSCAR_SITIO_POR_ID, {
		onCompleted(data){
			console.log(data.medios);
			if(data.medios.length!==0){
				showWarn("Advertencia", "Registro duplicado o clave ya en uso")
			}
			else{
				createMedio();
			}
		}, onError(error){
			console.log("ERROR: ", error)
		}

	})

	// const handleChange = (e) => {
	// 	setValueFields({ ...fields, [e.target.name]: e.target.value })
	// }

	const handleChange = (e, name = '') => {
		setValueFields({ ...fields, [name ? name : '']: e })
	}


	const getvalueTextareaComentarios = (value) => {
		settextareaComentarios(value);
		fields.comentarios = value;
	}

	const getvalueTextareaReferncias = (value) => {
		settextareaReferencias(value);
		fields.referencias = value;
	}

	const validardisponivilidad = (value) => {
		fields.disponible = value;
		setdisponibleBoolean(value);
	}

	const validarActivo = (value) => {
		fields.activo = value;
		setactivoBoolean(value);
	}

	const validarIluminacion = (value) => {
		fields.iluminacion = value;
		setiluminacionBoolean(value);
	}

	const validarEspeciales =(value)=>{
		fields.especiales=value;
		setEspeciales(value);
	}

	const asignaImagenMapa = (imagen: any) => {
		fields.imagenmapaubicacion = imagen;
	}

	const buscarEnMapa = (address) => {
		let geocoder = new google.maps.Geocoder();
		geocoder.geocode({ 'address': address }, function handleResults(results, status) {

			if (status === google.maps.GeocoderStatus.OK) {
				setLatitud(results[0].geometry.location.lat);
				setLongitud(results[0].geometry.location.lng);
			}
		})
	}

	const getDireccion = (value) => {
		//buscarEnMapa(value);
		document.getElementById("direccionMp").onblur = function () { buscarEnMapa(value) };

	}

	/**
	 * Funcion que ordena la informacion obtenida por 
	 * la api de Google.Places
	 * @param dataResponse 
	 */
	const orderReferences = (dataResponse: any[]) => {
		console.log('dataREsponse', dataResponse);
		const nameArrayResponse = [];
		// Se itera la respuesta obtenida y se separan los nombre de los sitios en un arreglo
		dataResponse.forEach(d => {
			if (d.name.toLowerCase().includes(INVALID_VALUE_POI_OXXO) ||
			 	d.name.toLowerCase().includes(INVALID_VALUE_POI_7_ELEVEN) ||
				d.name.toLowerCase().includes(INVALID_VALUE_TELEFONO_PUBLICO) ||
				d.name.toLowerCase().includes(INVALID_VALUE_TELEFONO_PUBLICO_ACENTOS)){
				console.log('No puedo agregar este', d.name);
				return;
			}

			if (d.types.includes(INVALID_CATEGORY_LOCALITY)) {
				console.log('Categoria invalida', d.name);
				return;
			}
			nameArrayResponse.push(d.name)
		});
		// Se setean en la funcion ya establecida anteriormente
		getvalueTextareaReferncias(nameArrayResponse.toString().substring(0,255));
	}

	/**
	 * Callback encargado de leer la respuesta que nos retorna
	 * y llamar a la funcion que creara los markers desde 
	 * Google.Maps.Places ademas de agregar otros atributos y categorizar la respuesta
	 * @param {any[]} results 
	 * @param {string} status 
	 */
	const callback = (results, status, pagination) => {
		if (status === 'OK') {
			orderReferences(results);
		} else if (status === GOOGLE_PLACES_ZERO_RESULTS) {
			zeroResultGoogleSearch();
		}
	};

	/**
	 * Evento que se lanza cuando el resultado de la busqueda
	 * de puntos de interes de google arroja zero resultados
	 */
	const zeroResultGoogleSearch = () => {
		getvalueTextareaReferncias(GOOGLE_PLACES_ZERO_RESULTS_RESPONSE);
		showWarn(GOOGLE_PLACES_ZERO_RESULTS_RESPONSE);
		// Intenta lanzar una alerta
	}

	/**
	 * Evento que ejecuta la busqueda y el callback del evento de google
	 */
	const eventSearchPOI = () => {
		try {
			const distancia = 100; // Distancia en metros
			const request = {
				location: location,
				radius: distancia,
				query: '*', // en teoria si lo mando con un * deberia traer todo tipo de refrencia
			};

			const service = new window.google.maps.places.PlacesService(mapReference);
			service.textSearch(request, (results, status, pagination) => {
				callback(results, status, pagination)
			});
		} catch (error) {
			
		}
	}

	/**
	 * Funcion encargada de validar y mandar llamar a los
	 * eventos que buscaran los puntos de referencias
	 */
	const searchReference = () => {
		// console.log('searchReference', latitud + ' - ' + longitud);
		if (latitud && longitud) {
			eventSearchPOI();
		} else {
			zeroResultGoogleSearch();
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<IonGrid style={{marginTop:'10px'}}>
				{notificationParam}
				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								// id={0}
								name="clave"
								value={(fields.clave === null ? "" : fields.clave)}
								style={{ width: "100%", borderRadius: '25px'}}
								disabled={props.operacion === "detalles" ? true : false}
								required
								onChange={e => {
									handleChange(e, 'clave');
									setValue("clave", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Clave</label>
						</span>
					</div>
					
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							{/* <InputTextComponent
								id={'direccionMp'}
								name={"direccion"}
								value={direccion}
								style={{ width: "100%" }}
								required={true}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setDireccion(e);
									getDireccion(e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/> */}
							<InputText
								id="direccionMp"
								name="direccion"
								value={direccion}
								style={{ width: "100%", borderRadius: '25px' }}
								required
								tabIndex={2}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setDireccion(e.target["value"]);
									getDireccion(e.target["value"]);
								}}

							/>
							<label htmlFor="in">Direccion</label>
						</span>
					</div>
				</div>
				
				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name={"claveproveedor"}
								value={(fields.claveproveedor === null ? "" : fields.claveproveedor)}
								style={{ width: "100%", borderRadius: '25px' }}
								required={true}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'claveproveedor');
									setValue("claveproveedor", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Clave de provedor</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="vialidad"
								value={(fields.vialidad === null ? "" : fields.vialidad)}
								style={{ width: "100%", borderRadius: '25px' }}
								required={true}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'vialidad');
									setValue("vialidad", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Vialidad</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="colonia"
								value={(fields.colonia === null ? "" : fields.colonia)}
								style={{ width: "100%", borderRadius: '25px' }}
								required
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'colonia');
									setValue("colonia", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Colonia</label>
						</span>
					</div>
				</div>

				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="tipoacabado"
								value={(fields.tipoacabado === null ? "" : fields.tipoacabado)}
								style={{ width: "100%",  borderRadius: '25px' }}
								required={true}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'tipoacabado');
									setValue("tipoacabado", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={'text'}
							/>
							<label htmlFor="in">Tipo de acabado</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="niveldeimpacto"
								value={(fields.niveldeimpacto === null ? "" : fields.niveldeimpacto)}
								style={{ width: "100%", borderRadius: '25px'  }}
								required
								accept={null}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'niveldeimpacto');
									setValue("niveldeimpacto", e);
								}}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Nivel de impacto</label>
						</span>
					</div>
					
				</div>

				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="medida"
								value={(fields.medida === null ? "" : fields.medida)}
								style={{ width: "100%", borderRadius: '25px' }}
								required={true}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'medida');
									setValue("medida", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Medida</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputNumber
								id='ancho'
								name="ancho"
								mode="decimal"
								locale="en-US"
								minFractionDigits={2}
								//maxFractionDigits={3}
								value={(fields.ancho === null ? "" : fields.ancho)}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								tabIndex={3}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setValueFields({ ...fields, ancho: e.value })
									setValue("ancho", e.value);;
								}}
							/>
							<label htmlFor="ancho">Ancho</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputNumber
								id='largo'
								name="largo"
								mode="decimal"
								locale="en-US"
								minFractionDigits={2}
								//maxFractionDigits={3}
								value={(fields.largo === null ? "" : fields.largo)}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								tabIndex={3}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setValueFields({ ...fields, largo: e.value })
									setValue("largo", e.value);
								}}
							/>
							<label htmlFor="largo">Largo</label>
						</span>
					</div>
				</div>

				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="material"
								value={(fields.material === null ? "" : fields.material)}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								// tabIndex={10}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'material');
									setValue("material", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
								type={null}
							/>
							<label htmlFor="in">Material</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="costo"
								value={(fields.costo === null ? "" : fields.costo)}
								style={{ width: "100%", borderRadius: '25px'}}
								required={true}
								type="number"
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'costo');
									setValue("costo", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
							/>
							<label htmlFor="in">Costo</label>
						</span>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<span className="p-float-label">
							<InputTextComponent
								name="precioventa"
								value={(fields.precioventa === null ? "" : fields.precioventa)}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								// tabIndex={12}
								type="number"
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									handleChange(e, 'precioventa');
									setValue("precioventa", e);
								}}
								accept={null}
								className={null}
								label={null}
								onBlur={null}
								onKeyPress={null}
								placeholder={null}
							/>
							<label htmlFor="in">Precio venta</label>
						</span>
					</div>
				</div>

				<div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in" >Tipo de sitio </label>
						<ListaTipos tipo={selectTipos} onSelectTipos={onSelectTipos} />
					</div>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in" >Tipo de Vista</label>
						<CargarListaVistas
							loading_listaVistas={loading_listaVistas}
							selectVistas={selectVistas}
							dataListaVistas={data_listaVistas}
							onSelectVista={onselectVista}
						/>
					</div>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in" >Proveedores</label>
						<ProveedoresLkUp proveedor={selectProveedor} onSelectProveedor={onselectProveedor} />
					</div>
				</div>
				{/* <div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						
					</div>
					<div className={'form-abc-medios-col-item'}>
						
					</div>
					<div className={'form-abc-medios-col-item'}>
						
					</div>
				</div> */}

				<div className={'input-ubicacion-abc-medios'}>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in" >Ubicacion</label>
						<CargarListaUbicaciones 
							data_listaUbicaciones={data_listaUbicaciones}
							loading_listaUbicaciones={loading_listaUbicaciones}
							onselectUbicacion={onselectUbicacion}
							selectubicaciones={selectubicaciones}
						/>
					</div>
				</div>

				{/* LATITUTA-LONGITUD */}
				<div className={'container-lat-lng-abcmedios'}>
					<div>
						<span className="p-float-label">
							<InputNumber
								name="latitud"
								value={latitud}
								locale="en-US"
								mode="decimal"
								minFractionDigits={2}
								maxFractionDigits={8}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								tabIndex={11}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setValueFields({ ...fields, latitud: e.value });
									setValue("latitud", e.value);
									let tempLocation = { ...location };
									tempLocation.lat = e.value;
									setLocation(tempLocation);
									setLatitud(e.value);
								}}
							/>
							<label htmlFor="in">Latitud</label>
						</span>
					</div>
					<div>
						<span className="p-float-label">
							<InputNumber
								name="longitud"
								value={longitud}
								locale="en-US"
								mode="decimal"
								minFractionDigits={2}
								maxFractionDigits={8}
								style={{ width: "100%", borderRadius: '25px'}}
								required
								tabIndex={11}
								disabled={props.operacion === "detalles" ? true : false}
								onChange={e => {
									setValueFields({ ...fields, longitud: e.value });
									setValue("longitud", e.value);
									let tempLocation = { ...location };
									tempLocation.lng = e.value;
									setLocation(tempLocation);
									setLongitud(e.value);
								}}
							/>
							<label htmlFor="in">Longitud</label>
						</span>
					</div>
					<div>
						<div
							onClick={() => {
								if ((latitud !== undefined && latitud !== null) && (longitud !== undefined && longitud !== null)) {
									searchReference();
								}
							}}
							className={(latitud !== undefined && latitud !== null) && (longitud !== undefined && longitud !== null) ? 
								'button-form-abc-medios' : 'button-form-abc-medios-disable'}>
							Buscar Rereferencias
						</div>
						{/* <Button
							label={'Buscar Rereferencias'}
							onClick={searchReference}
							disabled={(latitud !== undefined && latitud !== null) && (longitud !== undefined && longitud !== null) ? false : true}
						/> */}
					</div>
				</div>

				
				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						<label htmlFor="in">Referencias</label>
						{/* <IonTextarea
							required
							tabIndex={16}
							rows={4}
							disabled={props.operacion === "detalles" ? true : false}
							style={{ width: "100%", border: " 1px solid gray" }}
							value={textareaReferencias} onIonChange={e => getvalueTextareaReferncias(e.detail.value!)}
						></IonTextarea> */}
						<InputTextareaComponent
							required={true}
							tabIndex={16}
							rows={4}
							disabled={props.operacion === "detalles" ? true : false}
							style={{ width: "100%", border: " 1px solid gray", borderRadius: '25px'  }}
							value={textareaReferencias} onChange={e => getvalueTextareaReferncias(e)}
							name={null}
						></InputTextareaComponent>
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="6" sizeLg="6" sizeXl="6">
						<br></br>
						<label htmlFor="in">Comentarios</label>
						<InputTextareaComponent
							tabIndex={17}
							style={{ width: "100%", border: " 1px solid gray", borderRadius: '25px'  }}
							rows={4}
							disabled={props.operacion === "detalles" ? true : false}
							value={textareaComentarios} onChange={e => getvalueTextareaComentarios(e)}
							name={null}
							required={null}
						></InputTextareaComponent>
					</IonCol>
				</IonRow>
				<br></br>
				<div className={'center'}>
					<label htmlFor="in">Vista corta</label>
					<ImageUpload imagen={ImgCorta} tipo='Vista corta' onSave={setImgCorta} width={680} height={388}/>
				</div>
				<div className={'center'}>
				<br></br>
						<label htmlFor="in">Vista media</label>
						<ImageUpload imagen={ImgMedia} tipo='Vista media' onSave={setImgMedia} width={680} height={388} />
				</div>

				<div className={'center'}>
					<br></br>
					<label htmlFor="in">Vista larga</label>
					<ImageUpload imagen={ImgLarga} tipo='Vista larga' onSave={setImgLarga} width={680} height={388}/>
				</div>

				<br></br>

				{/* <div className={'form-abc-medios-row'}>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in">Disponible</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={disponibleBoolean} onChange={(e) => validardisponivilidad(e.value)} />
					</div>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in">Activo</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={activoBoolean} onChange={(e) => validarActivo(e.value)} />
					</div>
					<div className={'form-abc-medios-col-item'}>
						<label htmlFor="in">Iluminacion</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={iluminacionBoolean} onChange={(e) => validarIluminacion(e.value)} />
					</div>
				</div> */}

				<IonRow>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Disponible</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={disponibleBoolean} onChange={(e) => validardisponivilidad(e.value)} />
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Activo</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={activoBoolean} onChange={(e) => validarActivo(e.value)} />

					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Iluminacion</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={iluminacionBoolean} onChange={(e) => validarIluminacion(e.value)} />
					</IonCol>
					<IonCol size="12" size-Xs="12" sizeMd="4" sizeLg="4" sizeXl="4" style={{ textAlign: 'center' }}>
						<label htmlFor="in">Implementaciones especiales</label>
						<br></br>
						<InputSwitch disabled={props.operacion === "detalles" ? true : false} checked={especiales} onChange={(e) => validarEspeciales(e.value)} />
					</IonCol>
				</IonRow>
			
				<div>
					<div>
						<MostrarOcultarMapa
							operacion={props.operacion}
							setMapReference={setMapReference}
							asignaImagenMapa={asignaImagenMapa}
							location={location}
							latitud={latitud}
							longitud={longitud}
							setLatitud={setLatitud}
							setLongitud={setLongitud}
							setValue={setValue}
							imagenmapaubicacion={fields.imagenmapaubicacion}
						/>
					</div>
				</div>

				<IonRow>
					<img alt='' src=""></img>
				</IonRow>
				<br /><br />
				<Botones onDiscard={props.onDiscard} />
			</IonGrid>

		</form>
	);
}
