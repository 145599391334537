/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {IonText} from '@ionic/react';
import {Chips} from 'primereact/chips';
import {ZOOM_MAP_POI, CATEGORIES, IMJ_COLORS} from '../../utils/Constanst';
import jsonPOI from '../../utils/POI_DATA.json';
import MultiSelect from 'react-multi-select-component';
import './mapa.styles.css';

/**
 * 
 * @param {Object} props
 * @param {() => void} props.onPoints
 * @param {string} props.centro
 * @param {number} props.distancia
 * @param {number} props.zoom
 * @param {React.Dispatch<React.SetStateAction<number>>} props.setZoom
 * @param {string[]} props.values
 * @param {(title : string ,detail : string , milliseconds: string) => void} props.alertEvent
 * @param {any} props.mapReference
 * @param {React.Dispatch<React.SetStateAction<string[]>>} props.setValues
 * @param {any} props.setLoading
 * @returns 
 */
const GoogleMapsPlaces = ({
    onPoints,
    centro,
    distancia,
    mapReference,
    values,
    setValues,
    zoom,
    setZoom,
    alertEvent,
    setLoading,
}) => {
    const OverrideString = {
        "selectSomeItems": "Seleccionar...",
        "allItemsAreSelected": "Todas las rutas están seleccionadas.",
        "selectAll": "Todas",
        "search": "Buscar",
        "clearSearch": "Limpiar búsqueda"
    };
    const [flagEvent, setFlagEvent] = useState(false);
    const [clearFlag, setClearFlag] = useState(true);
    const [nuevosValores, setNuevosValores] = useState([]);
    const [POITypes, setPOITypes] = useState([]);
    const [flagPOI, setFlagPOI] = useState(true);
    const [classChip, setClassChip] = useState('');
    /**
     * Funcion que convierte un archivo .json a un objeto de tipo JSON
     * @returns {JSON}
     */
    const loadData = () => JSON.parse(JSON.stringify(jsonPOI));

    // const [categoryBusqueda,setCategoryBusqueda] = useState([]);
    const [categoriasSelect, setCategoriasSelect] = useState([]);
    const [categoriasValue, setCategoriasValue] = useState([]);

    /**
     * Funcion encargada de agregar los elementos seleccionados
     * al arreglo que contiene dichas selecciones
     * @param {any[]} selectedList 
     */
    const onSelect = (selectedList) => {
        setLoading(true);
        setFlagEvent(true);
        setCategoriasValue(selectedList);
    }

    /**
     * Funcion que retorna un texto personalizado para el
     * reenderiado de los valores seleccionados
     * @param {*} selected 
     * @param {*} _options 
     * @returns 
     */
    const customValueRenderer = (selected, _options) => {
        return selected.length
          ? selected.map(({ label }) => "✔️ " + label)
          : "Seleccionar Categoria";
    };

    const generateOrderColorFromIMJColors = (index) => {
        return IMJ_COLORS[index];
    };

    /**
     * Efecto encargado de cargar el archivo .json
     * y lo comvierte a un objeto JSON
     */
    useEffect(() => {
        const loadCategorys = () => {
            let categoriasTemp = [];
            
            CATEGORIES.forEach(c => {
                let newOject = {
                    label: c.translate,
                    value: {nombre: c.value, translate: c.translate, activo: false}
                }
                categoriasTemp.push(newOject);
            });
            // Ordenado de categorias
            categoriasTemp = categoriasTemp.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
            setCategoriasSelect(categoriasTemp);
        }
        loadCategorys();
    }, []);

    /**
     * Efecto encargado de hacer las peticiones a Google.Maps.Places
     * y obtener los resultados de la busqueda. 
     */    
    useEffect(() => {
        if (values.length === 0) {
            setClassChip('chips');
        } else {
            setClassChip('half-chips');
        }
        // Valida que contenga algo la variable
        if (Object.keys(POITypes).length === 0 && flagPOI) {
            setFlagPOI(false);
            const jsonParse = loadData();
            const arrayTemp = [];
            for(var i in jsonParse)
                arrayTemp.push(jsonParse[i]);

            setPOITypes(arrayTemp);
        }

        /**
         * Funcion encargada de crear los componentes markers
         * ademasd de categorizar y ordenars los resultados
         * @param {any[]} results
         * @param {number} index
         */
        const createMarker = (results, index) => {
            const responseArray = [];
            
            let indice = index;
            if (indice === IMJ_COLORS.length) {
                indice = 0;
            }
            const color = generateOrderColorFromIMJColors(indice);
            results.forEach(r => {
                let newObjet = r;
                let typePrimary = newObjet.types; // Categoria mas acertada para la busqueda
                const categoryArray = POITypes.filter(item => typePrimary.includes(item.value));
                let categoryTranslate = '';
                if (categoryArray.length > 0) {
                    categoryTranslate =  categoryArray[0].translate || categoryArray[0].translate === undefined
                    ? categoryArray[0].translate : '';
                }
                newObjet.color = color;
                const category = categoryArray[0]!== undefined ? categoryArray[0].category : 'Default';
                newObjet.category = category;
                newObjet.categoryTranslate = categoryTranslate;
                responseArray.push(newObjet);
            });
            const tempArrays = nuevosValores;
            responseArray.forEach(v => {
                tempArrays.push(v);
            });
            let response = [];
            if (categoriasValue.length > 0) {
                tempArrays.forEach(t => {
                    categoriasValue.forEach(c => {
                        if (c.value.nombre === t.category) {
                            response.push(t);
                        }
                    });
                });
            } else {
                response = tempArrays;
            }
            onPoints(response);
        };

        /**
         * Callback encargado de leer la respuesta que nos retorna
         * y llamar a la funcion que creara los markers desde 
         * Google.Maps.Places ademas de agregar otros atributos y categorizar la respuesta
         * @param {any[]} results 
         * @param {string} status 
         */
        const callback = (results, status, pagination, index) => {
            if (status === 'OK') {
                createMarker(results, index);
                if (pagination.hasNextPage) {
                    pagination.nextPage();
                } else {
                    setLoading(false);
                }
            }
        };

        /**
         * Evento encargado de separar los valores que se buscaran
         * y hacer las peticiones a google.maps.places
         * @param {string[]} placeParam 
         */
        const event = placeParam => {
            // setCategoriasValue([]);
            const locationsArray = centro.split(',');
            const location = new window.google.maps.LatLng(locationsArray[0], locationsArray[1]);
            placeParam.forEach((p, index) => {
                const request = {
                    location: location,
                    radius: distancia/3.7,
                    query: p,
                };
                const service = new window.google.maps.places.PlacesService(mapReference);
                service.textSearch(request, (results, status, pagination) => {
                    callback(results, status, pagination, index)
                });
            });
            setNuevosValores([]);  
        };
        if ((values.length > 0 ) && flagEvent) {
            // setCategoriasValue([]);
            setFlagEvent(false);
            setClearFlag(true);
            event(values);
        } 
        
        if (values.length === 0 && clearFlag) {
            setClearFlag(false);
            onPoints([]);
        }
    });
    
    return (
        <div className="card p-fluid" style={{borderRadius:'25px'}}>
            <div className={"row space-between width"}>
                <div style={{flex: 1}}>
                    <IonText>Buscar Puntos de Interés</IonText>
                    <Chips
                        className={'foursqare'}
                        style={{backgroundColor:'white',borderRadius:'25px !important', height: '38px'}}
                        value={values}
                        onChange={(e) => {
                            if (zoom >= ZOOM_MAP_POI) {
                                setCategoriasValue([]);
                                setLoading(true);
                                setFlagEvent(true);
                                setValues(e.value)
                            } else {
                                alertEvent('NO ES POSIBLE POR EL ZOOM');
                                setZoom(ZOOM_MAP_POI);
                            }
                    }}></Chips>
                </div>
                
                {values.length > 0 ? (
                    <div style={{marginTop: '-5px'}}>
                        <IonText style={{marginLeft: '15px'}}>Categorias Punto De Interes</IonText>
                        <MultiSelect
                            className={'multi-select'}
                            value={categoriasValue}
                            options={categoriasSelect}
                            onChange={onSelect}
                            style={{"border-bottom": "1px solid blue", "border-radius": "0px", "width": "650px" }}
                            labelledBy={"Selector de categoria"}
                            valueRenderer={customValueRenderer}
                            selectAllLabel='Todas'
                            isLoading={false}
                            overrideStrings={OverrideString}
                        />
                    </div>   
                ) : (null)}
            </div>
        </div>    
    )
}

export default GoogleMapsPlaces;
