import {gql}  from '@apollo/client';

export const UBICACIONES_QUERY = gql `
    query listaUbicaciones($sort: String, $limit: Int, $start: Int, $where: JSON){
        ubicacions(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            nombre
            activo
            estado
            estatus
            tipoexhib
            preciourbano
            costourbano
            lat
            lng
            Createdby{
                id
                email
            }
            Modifiedby{
                id
                email
            }
            created_at
            updated_at
        }
    }
`;

export const CREATE_UBICACION = gql `
    mutation crearUbicacion($input:createUbicacionInput){
        createUbicacion(input:$input){
            ubicacion{
                id
                nombre
                activo
                estado
                estatus
                tipoexhib
                preciourbano
                costourbano
                lat
                lng
                Createdby{
                    id
                    email
                }
                Modifiedby{
                    id
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;


export const UPDATE_UBICACION = gql `
    mutation UpdateUbicacion($input:updateUbicacionInput){
        updateUbicacion(input:$input){
            ubicacion{
                id
                nombre
                activo
                estado
                estatus
                tipoexhib
                preciourbano
                costourbano
                lat
                lng
                Createdby{
                    id
                    email
                }
                Modifiedby{
                    id
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const UPDATE_ESTATUS = gql `
    mutation UpdateUbicacion($input:updateUbicacionInput){
        updateUbicacion(input:$input){
            ubicacion{
                id
                nombre
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const DELETE_UBICACION = gql `
    mutation Delete_ubicacion($input:deleteUbicacionInput){
        deleteUbicacion(input:$input){
            ubicacion{
                id
                nombre
                Createdby{
                    email
                }
                Modifiedby{
                    email
                }
                created_at
                updated_at
            }
        }
    }
`;

export const GET_IMAGE_UBICACION_QUERY = gql `
query getImageUbicacionById ($id:ID!){
    ubicacion(id:$id){
        imagenexhib
    }
} 
`;
