import {gql} from '@apollo/client';

export const  MEDIOS_QUERY = gql `
  query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            tipos{
                id
                nombre
            }
            proveedor{
                id
                nombre
            }
            claveproveedor
            latitud
            longitud
            direccion
            ubicacion{
                id
                nombre
            }
            nicho
            referencias
            medida
            material
            tipoacabado
            iluminacion
            disponible
            vialidad
            colonia
            activo
            bloqueado
            vista{
                id
                nombre
            }
            comentarios
            costo
            precioventa
            material
            male_users
            female_users
            a13_17_users
            a18_25_users
            a26_40_users
            a41_55_users
            a55_users
            income_a_users
            income_b_users
            income_c_users
            income_d_users
            income_e_users
            total_hits
            total_users
            reach
            frequency
            universo
            created_at
            updated_at
            especiales
        }
        mediosConnection(where: $where){
            aggregate{
                count
            }
        }
    } 
`;

export const  GET_IMAGE_MEDIOS_QUERY = gql `
    query getImageMedioById ($id:ID!){
        medio(id:$id){
            Vista_media
            imagenmapaubicacion
        }
    } 
`;

export const  LISTA_RUTAS_QUERY = gql`
    query listarRutas($sort: String, $limit: Int, $start: Int, $where: JSON){
        rutas(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            ubicacion{
                id
                nombre
            }
            nombre
            descripcion
            inicio
            fin
            activo
            clave
            createdby{
                email
            }
            created_at
            updated_at
            modifiedby{
                email
            }
            puntos{
                lat
                lng
            }
        }
        rutasConnection(where: $where){
            aggregate{
                count
            }
        }
    }
`;

export const listaUbicaciones_Query = gql `
    query listaUbicaciones{
        ubicacions{
            id
            nombre
        }
    }
`;


export const listaVistas_Query = gql`
    query listaVistas{
        vistas{
            id
            nombre
        }
    }
`;

export const listaTipos_Query = gql `
    query listaTipos{
        tipos{
            id
            nombre
        }
    }
`;

export const listaProveedores_Query = gql`
    query listaProveedor{
        proveedors{
            id
            nombre
        }
    }
`;

export const BUSCAR_UBICACION_BY_ID_QUERY = gql `
    query BuscarUbicacionPorId($id:ID!){
        ubicacion(id:$id){
                nombre    
        }
    }
`;

export const BUSCAR_TIPO_BY_ID_QUERY = gql `
    query BuscarTipoPorId($id:ID!){
        tipo(id:$id){
            nombre
        }
    }
`;

export const BUSCAR_VISTA_BY_ID_QUERY = gql `
    query buscarVistaPorId($id:ID!){
        vista(id:$id){
            nombre
        }
    }
`;

export const BUSCAR_POR_ID_MEDIO = gql  `
    query BuscarMedioPorId ($id:ID!){
        medio(id:$id){
            Vista_corta
            Vista_media
            Vista_larga
            imagenmapaubicacion
        }
    }
`;

export const  MEDIOS_QUERY_UPDATE = gql `
  query listaMedios($sort: String, $limit: Int, $start: Int, $where: JSON){
        medios(sort: $sort, limit: $limit, start: $start, where: $where){
            id
            clave
            direccion
            medida
            disponible
            activo
            vialidad
            colonia
            niveldeimpacto
            iluminacion
            nicho
            referencias
            comentarios
            precioventa
            material
            tipoacabado
            latitud
            longitud
            Vista_larga
            Vista_corta
            Vista_media
            imagenmapaubicacion
            ubicacion{
              	id
                nombre
            }
            vista{
                id
                nombre
            }
            tipos{
                id
                nombre
            }
            Createdby{
                id
                
              
            }
            Modifiedby{
                id
            

            }
           
        }
    } 
`;

export const LISTAR_PUNTOS_RUTA_QUERY = gql `
    query ListarPuntosRuta($id:ID!){
        ruta(id:$id){
            id
            puntos{
                id
                lat
                lng
            }
        }
    }
`;